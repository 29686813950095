import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import moment from "../../functions/moment";
import StartEndDate from "./startEndDate";
import { userInstance } from "../../config/axios";
import { filterAppointmentObj } from "../../functions/index";

const Appointmentsummary = () => {
  const [userList, setUserList] = useState([]);
  const [dateRange, setDateRange] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [datePopup, setDatePopup] = useState();
  const [totoalSummary, settotoalSummary] = useState({
    totalLabour: 0,
    totalSale: 0,
  });
  const [appsummaryDate, setappsummaryDate] = useState();
  const [dataList, setDataList] = useState([]);
  const [specificUser, setspecificUser] = useState("allUsers");
  const [specificDate, setspecificDate] = useState("today");

  const initialfilterObj = {
    location: "alllocation",
    searchlocation: "",
    teammember: "allUsers",
    searchteammember: "",
    locationId: "",
    teammemberId: "",
    locationStatus: false,
    teamStatus: false,
    datetype: "today",
    rangeStartDate: new Date(),
    rangeEndDate: new Date(),
  };
  const [filterObj, setFilterObj] = useState(initialfilterObj);
  const handleDateRange = (start, end) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj.rangeStartDate = start;
    copyfilterObj.rangeEndDate = end;
    setFilterObj(copyfilterObj);
    setDatePopup(false);
    callfilterAppointment(copyfilterObj);
  };
  const handleDatePopup = () => {
    setDatePopup(!datePopup);
  };
  const handleDateOnchange = (value, type) => {
    let copycustomDateRange = { ...dateRange };
    if (type === "start" && new Date(value) >= dateRange.end) {
      copycustomDateRange.end = new Date(value);
    }
    copycustomDateRange[type] = new Date(value);

    setDateRange(copycustomDateRange);

    // setDateRange({ ...dateRange, [type]: new Date(value) });
  };

  const calculateDateToDisplay = (value) => {
    if (specificDate === "today") {
      return "Today";
    }
    if (specificDate === "yesterday") {
      return "Yesterday";
    }
    if (specificDate === "tomorrow") {
      return "Tomorrow";
    }

    return value;
  };

  const handleOnchange = (e) => {
    const { name, value } = e || {};
    let copyfilterObj = { ...filterObj, [name]: value };
    setspecificDate(value);
    setFilterObj(copyfilterObj);
  };

  const callfilterAppointment = async (data) => {
    const payload = filterAppointmentObj(data);
    setappsummaryDate(payload?.summaryDate);

    const response = await userInstance().post(
      "/appointment/filterAppointmentSummary",
      {
        filterObj: data,
        payload,
      },
    );
    console.log("this is the response", response);
    const { code, summaryResult, alltotalCost, alltotalLabour } = response.data;
    let copySummary = { ...totoalSummary };
    copySummary.totalLabour = alltotalLabour;
    copySummary.totalSale = alltotalCost;
    settotoalSummary(copySummary);
    if (code === 200) {
      setDataList(summaryResult);
    }
  };
  const filterAppointment = async () => {
    const payload = filterAppointmentObj(filterObj);
    setappsummaryDate(payload?.summaryDate);

    const response = await userInstance().post(
      "/appointment/filterAppointmentSummary",
      {
        filterObj,
        payload,
      },
    );
    console.log("this is the response", response);
    const { code, summaryResult, alltotalCost, alltotalLabour } = response.data;
    let copySummary = { ...totoalSummary };
    copySummary.totalLabour = alltotalLabour;
    copySummary.totalSale = alltotalCost;
    settotoalSummary(copySummary);
    if (code === 200) {
      setDataList(summaryResult);
    }
  };

  const handleDropDown = async (e) => {
    const { name } = e.target;
    let { value } = e.target;
    console.log("this is the name", name);
    console.log("this is the value", value);
    if (value === "allUsers") {
      console.log("Do Something Different");
      let copyfilterObj = { ...filterObj };
      copyfilterObj.teammember = "allUsers";
      copyfilterObj.teammemberId = "";
      copyfilterObj.teamStatus = false;
      setFilterObj(copyfilterObj);
      setspecificUser("allUsers");
      return;
    }
    let findUser = userList.find((el) => el._id === value);
    let fullName = `${findUser.firstname} ${findUser.lastname}`;
    let copyfilterObj = { ...filterObj };
    copyfilterObj[name] = fullName;

    copyfilterObj.teammemberId = findUser._id;
    copyfilterObj.teamStatus = false;
    console.log("this is the find User id", findUser._id);

    setFilterObj(copyfilterObj);
    setspecificUser(findUser._id);
  };

  const getStaffList = async () => {
    try {
      const response = await userInstance().get(
        `/staff/getStaffList?limit=${10}&skip=${0}`,
      );
      console.log("Response 12", response);
      const list = response.data.staff;
      const fIndex = list.findIndex((el) =>
        ["premium", "basic", "standard"].includes(el.accessLevel),
      );
      if (fIndex >= 0) list[fIndex].accessLevel = "owner";
      list.sort((a, b) => a.lastname.localeCompare(b.lastname));
      console.log("this is the count", response.data.count);
      setUserList(list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (specificDate !== "daterange") {
      filterAppointment();
    }
  }, [specificUser, specificDate]);

  useEffect(() => {
    getStaffList();
  }, []);

  // console.log('this is the user list array', userList);
  return (
    <div className="appointment-summary">
      <Form.Group controlId="formBasicLocation">
        <Form.Label>Users</Form.Label>
        <div className="select-box">
          {/* <i className="fas fa-sort-down" /> */}
          <Form.Control
            as="select"
            value={specificUser}
            name="teammember"
            onChange={handleDropDown}
            className="xero-select"
          >
            <option key="allUsers" value="allUsers">
              All Users
            </option>

            {userList.map((option) => (
              <option key={option._id} value={option._id}>
                {option.firstname} {option.lastname}
              </option>
            ))}
          </Form.Control>
        </div>
      </Form.Group>

      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Date Range</Form.Label>
        <div className="select-box">
          <Form.Control
            as="select"
            value={filterObj.datetype}
            onChange={(e) => handleOnchange(e.target)}
            name="datetype"
          >
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="tomorrow">Tomorrow</option>
            <option value="week">This Week</option>
            <option value="month">This Month</option>
            <option value="year">This Year</option>
            <option value="lastweek">Last Week</option>
            <option value="lastyear">Last Year</option>
            <option value="nextweek">Next Week</option>
            <option value="nextyear">Next Year</option>
            <option value="weekdate">Week to Date</option>
            <option value="monthdate">Month to Date</option>
            <option value="yeardate">Year to Date</option>
            <option value="daterange">Date Range</option>
          </Form.Control>
        </div>
      </Form.Group>

      {filterObj.datetype === "daterange" ? (
        <div className="start-date-section">
          <Form.Group className="search-box">
            <div className="custom-datepicker" onClick={handleDatePopup}>
              {`${moment(filterObj.rangeStartDate).format("ddd DD MMM yyyy")}`}
            </div>
          </Form.Group>
          <span>to</span>
          <Form.Group className="search-box">
            <div className="custom-datepicker">
              {" "}
              {`${moment(filterObj.rangeEndDate).format("ddd DD MMM yyyy")}`}
            </div>
          </Form.Group>
        </div>
      ) : (
        ""
      )}

      <div className="appointment-summary-section">
        <p>Scheduled Maintenance Summary</p>
        <p>{calculateDateToDisplay(appsummaryDate)}</p>
        <hr />
        <div className="summary-table">
          <div className="summary-table-header">
            <h6>User</h6>
            <h6>Scheduled Maintenance No</h6>
            <h6>Chemical Costs</h6>
            {/* <h6>Total Sales</h6> */}
          </div>
          {dataList.length > 0 ? (
            dataList.map((el, index) => {
              const { firstname, lastname, appcount, totalCost, totalLabour } =
                el || {};
              return (
                <div className="summary-table-body">
                  <p>
                    {firstname} {lastname}
                  </p>
                  <p>{appcount}</p>
                  {/* <p>{`$${(totalLabour || 0).toFixed(2)}`}</p> */}
                  <p>{`$${(totalCost || 0).toFixed(2)}`}</p>
                </div>
              );
            })
          ) : (
            <div>
              <p>No Records Found</p>
            </div>
          )}
          <hr />
          <div className="summary-table-body total-section">
            <p>Period Total:</p>
            <p />
            {/* <p>{`$${totoalSummary.totalLabour.toFixed(2)}`}</p> */}
            <p>{`$${totoalSummary.totalSale.toFixed(2)}`}</p>
          </div>
        </div>
      </div>

      <StartEndDate
        datePopup={datePopup}
        setDatePopup={setDatePopup}
        handleDatePopup={handleDatePopup}
        handleDateOnchange={handleDateOnchange}
        dateRange={dateRange}
        handleDateRange={handleDateRange}
      />
    </div>
  );
};
export default Appointmentsummary;
