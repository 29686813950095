import React from "react";
import PropTypes from "prop-types";

const SelectField = (props) => {
  const { data, label, selectedValue, handleChangeSelect } = props;

  return (
    <div className="d-flex flex-column" style={{ width: "100%" }}>
      <label
        htmlFor="chatbot-settings-selectfield"
        style={{ fontSize: "14px", margin: 0 }}
      >
        {label}
      </label>
      <select
        id="chatbot-settings-selectfield"
        className="form-select"
        aria-label="Default select example"
        value={selectedValue}
        onChange={(event) => handleChangeSelect(event.target.value)}
        style={{
          fontSize: "14px",
          borderRadius: "4px",
          border: "1px solid #ccc",
          width: "100%",
          paddingLeft: "8px",
          paddingRight: "8px",
        }}
      >
        {data.map((item) => (
          <option key={item.id} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

SelectField.propTypes = {
  data: PropTypes.arrayOf.isRequired,
  label: PropTypes.string.isRequired,
  selectedValue: PropTypes.string.isRequired,
  handleChangeSelect: PropTypes.func.isRequired,
};

export default SelectField;
