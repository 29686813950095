import React from "react";
import PropTypes from "prop-types";

const PredictiveOrderingDetail = ({ searchDataPredective }) => {
  const calcualteStockOnhand = (staffStockData) => {
    const stockvalue = staffStockData.reduce(
      (acc, obj) => parseFloat(acc) + parseFloat(obj?.stockValue),
      0,
    );
    const stockUsed = staffStockData.reduce(
      (acc, obj) => parseFloat(acc) + parseFloat(obj?.stockUsed) || 0,
      0,
    );
    return { stockvalue, stockUsed };
  };
  return (
    <div className="pool-setup pool-set-up predictive-ordering-detail">
      <div className="target-heading">
        <h6>Predictive Ordering Detail</h6>
      </div>
      {searchDataPredective && searchDataPredective.data.length > 0 ? (
        searchDataPredective.data.map((el) => (
          <>
            <div className="search-result-box" key={el._id}>
              <div className="inner-accordion ">
                <h6 className="blue-heading-predective-details">
                  {el.categoryName}
                </h6>
                <div className="ordering-detail-table">
                  <div className="ordering-detail-header">
                    <h6>Predictive Ordering Summary</h6>
                    <h6>Stock on Hand</h6>
                    <h6>Supply Unit</h6>
                    <h6>ForeCast Quantity Needed</h6>
                    <h6>Quantity Order Amount</h6>
                    <h6>Cost</h6>
                  </div>
                  {el.product &&
                    el.product.length > 0 &&
                    el.product.map((ele) => {
                      const stocksData = calcualteStockOnhand(
                        ele.staffStockData,
                      );
                      const forecastValue = Math.ceil(
                        ele.foreCastQuantity * ele?.inventoryTurnOver,
                      );
                      return (
                        <>
                          <div className="ordering-detail-body" key={ele.s_uId}>
                            <div className="ordering-detail-list">
                              <p>{ele.name.split("$")[0]}</p>
                              <p>{stocksData.stockvalue}</p>
                              <p>{ele.supplyUnit || 0}</p>
                              <p>{forecastValue || 0}</p>
                              <p>{ele.stockUsed || 0}</p>
                              <p>{ele.stockUsed || 0}</p>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  <div className="footer-btn view-btn">
                    <button
                      type="button"
                      className="appointment-btn btn-edit btn btn-primary"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="appointment-btn btn-save btn btn-primary"
                    >
                      Proceed to Payment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))
      ) : (
        <>
          <div className="search-result-box">
            <h6 className="blue-heading-predective-details text-center">
              No Records Found
            </h6>
          </div>
        </>
      )}
    </div>
  );
};

PredictiveOrderingDetail.propTypes = {
  searchDataPredective: PropTypes.func.isRequired,
};
export default PredictiveOrderingDetail;
