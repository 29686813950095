import React from "react";
import { Form, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { calculateCapacity } from "../../functions/newEvent";

const CalculatorTop = (props) => {
  const {
    poolFinish,
    handlePoolFinish,
    serviceAnalysis,
    handleServiceAnalysis,
    waterPoolStatus,
    waterLossStatus,
    waterTestOptions,
    handleWaterTestOptions,
    showUserPool,
    userPoolArray = [],
    handleUserSelectedPool,
    selectedUserPool,
    valueType,
  } = props;
  return (
    <>
      {serviceAnalysis === "Pool Water Volume Verification via Salinity" ||
      serviceAnalysis === "Water Loss Calculator" ||
      serviceAnalysis === "Inverter Heater – Heating Time Required" ? null : (
        <>
          {showUserPool && (
            <Form.Group>
              <Form.Row>
                <Col column="xs" md={7} xs={5}>
                  <Form.Label>Selected Pool</Form.Label>
                </Col>
                <Col>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      custom
                      value={selectedUserPool}
                      onChange={handleUserSelectedPool}
                    >
                      {userPoolArray?.map((el, index) => (
                        <option value={el?.poolId} key={Math.random()}>
                          Pool {index + 1} -{" "}
                          {calculateCapacity(
                            el?.poolDepth,
                            Number(el?.poolWidth),
                            Number(el?.poolHeight),
                            Number(el?.diameter),
                            Number(el?.deepEnd),
                            Number(el?.shallowEnd),
                            !valueType,
                          )}{" "}
                          {valueType ? "litres" : "gallons"}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </Col>
              </Form.Row>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Row>
              <Col column="xs" md={7} xs={5}>
                <Form.Label>Pool Finish</Form.Label>
              </Col>
              <Col>
                <div className="select-box">
                  <Form.Control
                    as="select"
                    custom
                    value={poolFinish}
                    onChange={handlePoolFinish}
                  >
                    <option value="Concrete Pool - Outdoor">
                      Concrete Pool - Outdoor
                    </option>
                    <option value="Fibreglass Pool - Outdoor">
                      Fibreglass Pool - Outdoor
                    </option>

                    <option value="Vinyl Liner Pool - Outdoor">
                      Vinyl Liner Pool - Outdoor
                    </option>

                    <option value="Concrete Pool - Indoor">
                      Concrete Pool - Indoor
                    </option>
                    <option value="Fibreglass Pool - Indoor">
                      Fibreglass Pool - Indoor
                    </option>

                    <option value="Vinyl Liner Pool - Indoor">
                      Vinyl Liner Pool - Indoor
                    </option>
                  </Form.Control>
                </div>
              </Col>
            </Form.Row>
          </Form.Group>
        </>
      )}
      <Form.Group>
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>Service Analysis</Form.Label>
          </Col>
          <Col>
            <div className="select-box">
              <Form.Control
                // className={`${waterPoolStatus ? 'waterPoolStatus' : ''}`}
                as="select"
                custom
                value={serviceAnalysis}
                onChange={handleServiceAnalysis}
              >
                <option value="Water Test">Water Testing</option>
                <option value="Green Pool">Green Pool</option>
                {poolFinish.includes("Fibreglass") ||
                poolFinish.includes("Vinyl") ? null : (
                  <option value="Black-Spot Algae">Black-Spot Algae</option>
                )}

                <option value="Drain Pool">Drain Pool</option>

                <option value="Sand Filter Media Replacement">
                  Sand Filter Media Replacement
                </option>
                <option value="Pool Water Volume Verification via Salinity">
                  Pool Water Volume Verification via Salinity
                </option>
                <option value="Water Loss Calculator">
                  Water Loss Calculator
                </option>
                <option value="Inverter Heater – Heating Time Required">
                  Inverter Heater – Heating Time Required
                </option>
              </Form.Control>
            </div>
          </Col>
        </Form.Row>
      </Form.Group>
      {serviceAnalysis === "Water Test" ? (
        <Form.Group>
          <Form.Row>
            <Col column="xs" md={7} xs={5}>
              <Form.Label>Water Test Options</Form.Label>
            </Col>
            <Col>
              <div className="select-box">
                <Form.Control
                  as="select"
                  custom
                  value={waterTestOptions}
                  onChange={handleWaterTestOptions}
                >
                  <option value="Water Test Only">Water Test Only</option>
                  <option value="Water Test + Clarifier">
                    Water Test + Clarifier
                  </option>
                  <option value="Water Test + Algaecide">
                    Water Test + Algaecide
                  </option>
                  <option value="Water Test + Flock">Water Test + Flock</option>
                  <option value="Water Test + Clarifier & Algaecide">
                    Water Test + Clarifier & Algaecide
                  </option>
                  <option value="Water Test + Flock & Algaecide">
                    Water Test + Flock & Algaecide
                  </option>
                </Form.Control>
              </div>
            </Col>
          </Form.Row>
        </Form.Group>
      ) : null}
    </>
  );
};
CalculatorTop.propTypes = {
  poolFinish: PropTypes.string.isRequired,
  handlePoolFinish: PropTypes.func.isRequired,
  serviceAnalysis: PropTypes.string.isRequired,
  handleServiceAnalysis: PropTypes.func.isRequired,
  waterPoolStatus: PropTypes.bool.isRequired,
  waterLossStatus: PropTypes.bool.isRequired,
  waterTestOptions: PropTypes.string.isRequired,
  handleWaterTestOptions: PropTypes.func.isRequired,
  showUserPool: PropTypes.bool.isRequired,
  userPoolArray: PropTypes.arrayOf.isRequired,
  selectedUserPool: PropTypes.string.isRequired,
  handleUserSelectedPool: PropTypes.arrayOf.isRequired,
  valueType: PropTypes.bool.isRequired,
};

export default CalculatorTop;
