import React, { useRef, useEffect } from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

const DeleteInvoicePopup = ({ setInvoicePopup, handleInvoicePopuncheck }) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className ===
            "duration-picker time-picker-modal chemical-popup"
          ) {
            setInvoicePopup(false);
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="duration-picker time-picker-modal chemical-popup">
      <div ref={wrapperRef}>
        <div className="time-keeper-popup duration-popup">
          <h6>Are you sure you want to void this invoice?</h6>

          <div className="event-popup-btn footer-btn view-btn">
            <Button
              className="appointment-btn btn-edit"
              type="button"
              onClick={() => setInvoicePopup(false)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="appointment-btn btn-save"
              onClick={() => handleInvoicePopuncheck()}
            >
              OK
            </Button>
          </div>
          {/* <div className="time-keeper-btn">
            <Button onClick={() => handleInvoicePopuncheck()}>OK</Button>
          </div> */}
        </div>
      </div>
    </div>
  );
};
DeleteInvoicePopup.propTypes = {
  setInvoicePopup: PropTypes.isRequired,
  handleInvoicePopuncheck: PropTypes.isRequired,
};
export default DeleteInvoicePopup;
