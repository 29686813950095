/* eslint-disable no-use-before-define */
import React, { useState } from "react";
import { Button, Form, Accordion, Card, Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import whiteedit from "../../assets/home/white-edit.png";
import whitedelete from "../../assets/home/white-delete.png";
import AdminWrapper from "../admin/adminwrapper/wrapper";
import "./poolsetup.css";
import UserPoolPopup from "./addUserPool";
import showmore from "../../assets/home/showmoreblue.png";
import { calculateCapacity } from "../../functions/newEvent";

const UserPool = ({
  state,
  handleUserPoolPopUp,
  handleEditUserPool,
  handleDeletePopUp,
  isUsImperial,
}) => {
  const [userPoolAccord, setUserPoolAccord] = useState([1]);
  const [adduserPool, setAddUserPool] = useState(false);
  const [save, setSave] = useState(false);
  // console.log('test', state);

  // const calculateCapacity = (poolDepth, poolWidth, poolHeight, diameter, deepEnd, shallowEnd) => {
  //   let poolCapacity = 0;
  //   console.log('cominginthissusiou', {
  //     poolDepth, poolWidth, poolHeight, diameter, deepEnd, shallowEnd,
  //   });
  //   let poolMsg = '';
  //   if (poolDepth === 'Average Depth - Rectangular Pool') {
  //     const calValue = !isUsImperial ? 1250 : 5.5 * 7.48;
  //     poolCapacity = poolWidth * poolHeight * calValue;
  //     // console.log('asdfasd', poolCapacity);
  //   } else if (poolDepth === 'Average Depth - Circular pool') {
  //     const calValue = !isUsImperial ? 1250 * 0.78 : 5.5 * 7.48;
  //     poolCapacity = diameter * diameter * calValue;
  //   } else if (poolDepth === 'Specific Depths - Rectangular Pool') {
  //     const calValue = !isUsImperial ? 1000 : 5.5 * 7.48;
  //     const shallowCalculation = (shallowEnd - deepEnd) / 2 + deepEnd;
  //     // console.log({ shallowCalculation, calValue });
  //     poolCapacity = poolWidth * poolHeight * shallowCalculation * calValue;
  //     // console.log({ poolCapacity });
  //   } else if (poolDepth === 'Specific Depths - Circular Pool') {
  //     const calValue = !isUsImperial ? 780 : 5.5 * 7.48;
  //     const shallowCalculation = (shallowEnd - deepEnd) / 2 + deepEnd;
  //     poolCapacity = diameter * diameter * shallowCalculation * calValue;
  //   }

  //   poolCapacity = !isUsImperial
  //     ? Math.floor(parseInt(poolCapacity, 10) / 1000) * 1000
  //     : Math.round(parseInt(poolCapacity, 10) / 100) * 100;
  //   return poolCapacity;
  // };
  const handleAddPool = () => {
    let addMyPool = userPoolAccord.length + 1;
    setUserPoolAccord([...userPoolAccord, addMyPool]);
  };
  const handleRemovePool = () => {
    const copy = [...userPoolAccord];
    copy.pop();
    setUserPoolAccord(copy);
  };

  const handleUserPool = () => {
    setAddUserPool(!adduserPool);
  };
  return (
    <AdminWrapper>
      <div className=" staff-container user-pool-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-titles-mob">
                <h3>My Pool Size & Chlorination</h3>
                <div className="client-option service-edit-icon">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <img src={showmore} alt="show" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="action" onClick={handleUserPoolPopUp}>
                        <span> Add Additional Pool</span>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {state?.length > 0 ? (
                <div id="table-scroll" className="staff-table table-scroll">
                  <div className="mob-table">
                    {Array.isArray(state) &&
                      state.map((el, index) => (
                        <Accordion
                          className="mob-accordion"
                          key={el?.poolNo}
                          defaultActiveKey="0"
                        >
                          <Card>
                            <Card.Header>
                              <div className="staff-main-heading">
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  eventKey={index.toString()}
                                >
                                  {/* <i className="fas fa-chevron-circle-down" /> */}
                                  {/* <i className="fas  far fa-chevron-circle-up" /> */}
                                  <span>My Pool No. {index + 1}</span>
                                </Accordion.Toggle>
                                {/* <div className="client-option service-edit-icon">
                             jj
                           </div> */}
                              </div>
                            </Card.Header>
                            <Accordion.Collapse eventKey={index.toString()}>
                              <Card.Body>
                                <ul className="staff-list">
                                  <li className="staff-list-item">
                                    <div className="staff-box">
                                      <h3>Pool Depth</h3>
                                      <h5>{el.poolDepth}</h5>
                                    </div>
                                  </li>
                                  <li className="staff-list-item">
                                    <div className="staff-box">
                                      <h3>Pool Size</h3>
                                      <h5>
                                        {el.poolDepth?.includes("Circular")
                                          ? el.diameter
                                          : `${el.poolWidth} x ${el.poolHeight}`}
                                        {el.poolDepth?.includes("Specific")
                                          ? ` | ${el.shallowEnd} - ${el.deepEnd}`
                                          : null}
                                        {el.poolCapacity
                                          ? ` | ${calculateCapacity(el?.poolDepth, Number(el?.poolWidth), Number(el?.poolHeight), Number(el?.diameter), Number(el?.deepEnd), Number(el?.shallowEnd), isUsImperial).toLocaleString()} ${isUsImperial ? "gallons" : "litres"}`
                                          : null}
                                        {/* changes by abhishek */}

                                        {/* {calculateCapacity(el?.poolDepth, Number(el?.poolWidth), Number(el?.poolHeight), Number(el?.diameter), Number(el?.deepEnd), Number(el?.shallowEnd))}

                                   {console.log('dekho yar', el?.poolDepth, el?.poolDepth, el?.poolWidth, el?.poolHeight, el?.diameter, el?.deepEnd, el?.shallowEnd)} */}
                                        {/* {calculateCapacity()} */}
                                      </h5>
                                    </div>
                                  </li>
                                  <li className="staff-list-item">
                                    <div className="staff-box">
                                      <h3>Pool Finish</h3>
                                      <h5>{el.poolFinish}</h5>
                                    </div>
                                  </li>
                                  <li className="staff-list-item">
                                    <div className="staff-box">
                                      <h3>Chlorination</h3>
                                      <h5>
                                        {el.chlorinationType ===
                                        "Calcium Hardness Titration Test"
                                          ? "Mineral Pool with Chlorinator + Calcium Hardness Titration Test"
                                          : el.chlorinationType}
                                      </h5>
                                    </div>
                                  </li>
                                  <li className="staff-list-item">
                                    <div className="staff-box">
                                      <h3>Chlorinator Model</h3>
                                      <h5>{el.selectedModel}</h5>
                                    </div>
                                  </li>
                                  <li className="staff-list-item">
                                    <div className="staff-box">
                                      <h3>Actions</h3>
                                      <div className="action-btn">
                                        <Button
                                          className="edit-btn"
                                          onClick={() =>
                                            handleEditUserPool(el.poolId)
                                          }
                                        >
                                          <img src={whiteedit} alt="edit-img" />
                                        </Button>
                                        <Button
                                          className="delete-btn"
                                          onClick={() =>
                                            handleDeletePopUp(el.poolId)
                                          }
                                        >
                                          <img
                                            src={whitedelete}
                                            alt="edit-img"
                                          />
                                        </Button>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      ))}
                  </div>
                </div>
              ) : (
                <div className="invoices-table pool-chemistry-box">
                  <div className="placehoder-text">No Pool Added</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

UserPool.propTypes = {
  state: PropTypes.arrayOf.isRequired,
  handleUserPoolPopUp: PropTypes.func.isRequired,
  handleEditUserPool: PropTypes.func.isRequired,
  handleDeletePopUp: PropTypes.func.isRequired,
  isUsImperial: PropTypes.bool.isRequired,
};

export default UserPool;
