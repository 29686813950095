/* eslint-disable no-unneeded-ternary */
/* eslint-disable import/newline-after-import */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-quotes */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AdminWrapper from "../admin/adminwrapper/wrapper";
import "./subscription.css";
import Loader from "../loader/loader";
import StripePaymentPopup from "../SubscribePayment/stripePaymentPopup";
import { queryString } from "../../functions";
import { appTokenInstance, withoutAuth } from "../../config/axios";

function PaymentSubscription() {
  const history = useHistory();
  const initialClientData = {
    customerType: "Householder",
    duration: "",
    companyName: "",
    firstname: "",
    lastname: "",
    mobileNo: "",
    landlineNo: "",
    email: "",
    streetAddress: "",
    suburb: "",
    postcode: "",
    state: "",
    country: "Australia",
    role: "subscriber",
    note: "",
    subscriptionStatus: "",
    numberUsers: 0,
    numberOfPools: 0,
    totalCost: 0,
    hide: false,
    associatedContacts: [],
    siteAddresses: [],
    agreeStatus: false,
    paymentStatus: "Up-to-Date-Last Payment $530.00 on 22 Mar,2019",
    showBookings: true,
    applyNewPayment: "No",
    status: "Active",
    rangeStartDate: new Date(),
    rangeEndDate: new Date(),
    gender: "male",
    invoiceSendType: "Send Invoices by SMS",
    billingCycle: "1 Year",
    costDropDown: "no cost",
  };

  const [clientData, setClientData] = useState(initialClientData);

  const [poolData, setPoolData] = useState({});
  const [openPaymentForm, setOpenPaymentForm] = useState(false);
  // payload state before edit Subscriber API
  const [smallLoader, setSmallLoader] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);

  const [tokenState, setTokenState] = useState({
    token: "",
    staffToken: "",
  });

  const handleClosePaymentForm = () => {
    setOpenPaymentForm(false);
  };
  const handleFormModal = () => {
    setOpenPaymentForm(false);
  };

  const receiveclientData = queryString();

  const retirevingPoolData = async () => {
    try {
      const response = await appTokenInstance(
        receiveclientData?.token,
        receiveclientData?.stafftoken,
      ).get("/poolmineral/getAllPoolMinerals");
      const mineralList = response?.data?.mineralList;
      if (mineralList) {
        setPoolData(mineralList);
        setClientData(receiveclientData);
        setTokenState({
          ...tokenState,
          token: receiveclientData?.token,
          stafftoken: receiveclientData?.stafftoken,
        });
        setOpenPaymentForm(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (receiveclientData?.firstname) {
      retirevingPoolData();
    }
  }, [receiveclientData?.firstname]);
  console.log("UpdateSubscriptionPopup=====>>>jjppp", receiveclientData);

  return (
    <>
      <div className=" client-container subcription-page">
        {openPaymentForm ? (
          <StripePaymentPopup
            openPaymentForm={openPaymentForm}
            clientData={clientData}
            handleAction={handleClosePaymentForm}
            smallLoader={smallLoader}
            returnPopUp="subscriptionPage"
            handleFormModal={handleFormModal}
            paymentLoader={paymentLoader}
            poolData={poolData}
            UpdateSubscriptionPopup={
              receiveclientData?.UpdateSubscriptionPopup === "true"
                ? true
                : false
            }
            totalCostForFIlteringProduct={
              receiveclientData?.totalCostForFIlteringProduct
            }
            updateSubscriptionTotalCostValue={
              Number(receiveclientData?.updateSubscriptionTotalCostValue) || 0
            }
            tokenState={tokenState}
            slectedItem={receiveclientData?.slectedItem}
            specificDate={
              receiveclientData?.specificDate === "null"
                ? null
                : receiveclientData?.specificDate
            }
          />
        ) : null}
      </div>
    </>
  );
}

export default PaymentSubscription;
