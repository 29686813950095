import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Button, Modal } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import { NotificationManager } from "react-notifications";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import moment from "../../functions/moment";
import { userInstance } from "../../config/axios";
import logo from "../../assets/home/logo-pool-2.png";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { errorMsgTime, successMsgTime } from "../../functions/intervalTime";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const PreviewInvoicePopup = ({
  showInvoice,
  handleInvoicePopup,
  invoiceData,
  chemicalsArray,
  handleClose,
  previewUrl,
  VoidInvoice,
  permission,
  userPermissions,
}) => {
  const { invoiceType, invoiceUrl } = invoiceData || {};
  const [message, setMsg] = useState("No name to appear in Invoice detail");
  const [typeInvoice, setTypeInvoice] = useState(invoiceType);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  return (
    <>
      <Modal
        size="md"
        show={showInvoice}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="search-modal new-search-popup"
      >
        <div className="staff-close">
          <i className="fa fa-times" onClick={handleClose} aria-hidden="true" />
        </div>
        <Modal.Body>
          <div className="appointment-container preview-invoice preview-popup">
            <div className="container">
              <div className="row">
                {invoiceUrl || previewUrl ? (
                  <div className="add-appointment">
                    <TransformWrapper>
                      <TransformComponent>
                        <Document
                          file={invoiceUrl || previewUrl}
                          onLoadError={(error) =>
                            console.log(
                              `Error while loading document! ${error.message}`,
                            )
                          }
                        >
                          <Page pageNumber={pageNumber} />
                        </Document>
                      </TransformComponent>
                    </TransformWrapper>
                  </div>
                ) : (
                  <p> PDF Loading... </p>
                )}
              </div>
              {invoiceData && invoiceType && invoiceUrl && (
                <div
                  className={`footer-btn view-btn ${userPermissions.includes("Delete Invoice") ? "" : "voidPermission"}`}
                >
                  <Button className="btn-delete" onClick={handleClose}>
                    Cancel
                  </Button>
                  {userPermissions.includes("Delete Invoice") && (
                    <Button
                      className="appointment-btn btn-save"
                      onClick={VoidInvoice}
                    >
                      Void Invoice
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
PreviewInvoicePopup.propTypes = {
  showInvoice: PropTypes.func.isRequired,
  handleInvoicePopup: PropTypes.bool.isRequired,
  invoiceData: PropTypes.func.isRequired,
  chemicalsArray: PropTypes.arrayOf.isRequired,
  handleClose: PropTypes.func.isRequired,
  previewUrl: PropTypes.string.isRequired,
  VoidInvoice: PropTypes.func.isRequired,
  permission: PropTypes.bool.isRequired,
  userPermissions: PropTypes.arrayOf.isRequired,
};
export default PreviewInvoicePopup;
