const errorMsgTime = 5000;
const successMsgTime = 5000;
const errorBorderTime = 1000;
const nameRegex = /^[A-Za-z\s]*$/;
const postCodeRegex = /^[0-9\b]{1,10}$/;

export {
  errorMsgTime,
  successMsgTime,
  errorBorderTime,
  postCodeRegex,
  nameRegex,
};
