/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React, { useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom";
import moment from "../../functions/moment";
import Layout from "../layout/index";
import { userInstance, withoutAuth } from "../../config/axios";
import {
  showErrMsg,
  validateDataWithKey,
  queryString,
} from "../../functions/index";
// import logo from '../../assets/home/login-logo.png';
import logo from "../../assets/home/calculator-logo2.png";
import "./updatepassword.css";
import { AuthDataContext } from "../../contexts/AuthDataContext";
import { socket } from "../../socket";
import { errorMsgTime, successMsgTime } from "../../functions/intervalTime";
import toastMessage from "../../functions/toastMessage";

function UpdatePassword() {
  const history = useHistory();
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const { userData, setUserData } = useContext(AuthDataContext);
  let { token } = queryString();
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const handleResetPassword = async (e) => {
    // e.preventDefault();
    try {
      const { isValid, emptyField } = validateDataWithKey({
        "current password": passwordData.currentPassword,
        "new password": passwordData.newPassword,
      });
      if (isValid) {
        const res = await withoutAuth().put("/user/resetPassword", {
          ...passwordData,
          token,
        });
        const { msg, usertoken, staffToken, Id, userdata } = res.data;

        if (res.status === 200) {
          const { email, firstLogin } = userdata;
          localStorage.setItem("s#@4f", usertoken);
          localStorage.setItem("f#@5e", staffToken);
          localStorage.setItem("loginStaff", Id);
          const firstday = new Date(
            moment().startOf("isoWeek").format("YYYY-MM-DD"),
          );
          const lastday = new Date(
            moment().add(1, "weeks").endOf("isoWeek").format("YYYY-MM-DD"),
          );
          const filterStart = localStorage.getItem("date") || firstday;
          const filterEnd = localStorage.getItem("enddate") || lastday;
          localStorage.setItem("date", new Date(filterStart).toISOString());
          localStorage.setItem("enddate", new Date(filterEnd).toISOString());
          setUserData({
            ...userData,
            auth: true,
            email,
            firstLogin,
          });
          socket.emit("join", userdata._id.toString());
          toastMessage("success", "Password is updated Successfully");
          window.location.href = "/calculator";
        } else {
          toastMessage("error", msg);
        }
      } else {
        toastMessage("error", `Please fill ${emptyField}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdatePassword = async (e) => {
    // e.preventDefault();
    try {
      const { isValid, emptyField } = validateDataWithKey({
        "current password": passwordData.currentPassword,
        "new password": passwordData.newPassword,
      });
      if (isValid) {
        if (passwordData.newPassword.length < 5) {
          toastMessage("error", "Password must be at least 5 characters");
        } else {
          const response = await userInstance().put("/staff/updatePassword", {
            ...passwordData,
          });
          // console.log(response);
          const { msg } = response.data;
          toastMessage("success", "Password is updated Successfully");
          localStorage.setItem("firstLogin", false);

          window.location.href = "/calculator";
        }
      } else {
        toastMessage("error", `Please fill ${emptyField}`);
      }
    } catch (error) {
      showErrMsg(error);
    }
  };

  const handleUpdateAndLogin = (e) => {
    e.preventDefault();
    // console.log('userData.firstLogin', userData);
    if (userData.firstLogin === true) {
      handleUpdatePassword();
    } else {
      handleResetPassword();
    }
  };
  const goToLogin = () => {
    localStorage.removeItem("s#@4f");
    localStorage.removeItem("f#@5e");
    localStorage.removeItem("loginStaff");
    localStorage.removeItem("staff_id");
    localStorage.removeItem("locationId");
    localStorage.removeItem("staff_name");
    window.location.href = "/login";
    // history.push('/login');
  };

  return (
    <div className="login-container update-password">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="login">
              <h3 className="login-title">Update Password</h3>
              <div className="login-box">
                <div className="logo-login">
                  <img src={logo} alt="logo" />
                </div>
                <div className="login-form">
                  <Form>
                    <div className="row">
                      <div className="col-md-12">
                        <Form.Group controlId="formBasicEmail" required="">
                          {/* <Form.Label>Current Password</Form.Label> */}
                          <Form.Label>Temporary Password Received</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            name="currentPassword"
                            autoComplete="on"
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <Form.Group controlId="formBasicPassword" required="">
                          <Form.Label>New Password</Form.Label>
                          <Form.Control
                            type={values.showPassword ? "text" : "password"}
                            placeholder=""
                            name="newPassword"
                            autoComplete="on"
                            onChange={handleChange}
                          />
                          <i
                            onClick={handleClickShowPassword}
                            className={
                              values.showPassword
                                ? "fa fa-eye"
                                : "fas fa-eye-slash "
                            }
                            aria-hidden="true"
                          />
                        </Form.Group>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-md-12">
                        <Form.Group className="checkbox" controlId="formBasicCheckbox">
                          <Form.Check type="checkbox" label="Keep me logged in" />
                        </Form.Group>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="login-popup-btn update-password new_update_pasd">
                          <Button
                            type="button"
                            className="btn btn-danger btn-edit"
                            onClick={() => goToLogin()}
                          >
                            {" "}
                            Cancel{" "}
                          </Button>
                          <Button type="submit" onClick={handleUpdateAndLogin}>
                            {" "}
                            Update & Login{" "}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdatePassword;
