import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import moment from '../../functions/moment';
// eslint-disable-next-line import/no-extraneous-dependencies
// import { useHistory } from 'react-router-dom';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import calendar from '../../assets/home/icon/icon-1.png';
import client from '../../assets/home/icon/icon-4.png';
import blocked from '../../assets/home/pocket-watch.png';
import reports from '../../assets/home/icon/icon-2.png';
import alert from '../../assets/home/icon/bell-icon.png';
import calculator from '../../assets/home/icon/icon-3.png';
import clientblue from '../../assets/home/icon-4.png';
import reportsblue from '../../assets/home/icon-2.png';
import alertblue from '../../assets/home/bell-icon-blue.png';
import calculatorblue from '../../assets/home/icon-3.png';
import calendarblue from '../../assets/home/icon-1.png';

const StickyFooter = ({
  TodayEvents,
  handleNotify,
  count,
  setNotify,
  notify,
  checkNotification,
  setCheckNotification,
}) => {
  const history = useHistory();
  const IndustryType = localStorage.getItem('IndustryType');
  const { userData } = useContext(AuthDataContext);
  const tokenId = localStorage.getItem('s#@4f');
  let staff_id = localStorage.getItem('staff_id');
  let view = localStorage.getItem('view');
  let calendarUrl = `/calendar?activedate=${moment(new Date()).format(
    'YYYY-MM-DD'
  )}&view=${view || 'timeGridWeek'}&staff_id=${staff_id || ''}&today=today`;
  const closeAlert = () => {
    setNotify(false);
    setCheckNotification(false);
  };
  const handleTodayEvent = () => {
    TodayEvents(new Date());
    closeAlert();
  };
  // const [state, setState] = useState({
  //   calendar: true,
  //   calculator: true,
  // });

  // useEffect(() => {
  //   const copy = { ...state };
  //   userData?.permissions?.forEach((el) => {
  //     if (copy[el.action.toLowerCase().trim()] === false) {
  //       copy[el.action.toLowerCase()] = true;
  //     }
  //   });
  //   // console.log('copy', copy);
  //   setState(copy);
  // }, [userData]);

  // console.log('calendar', state);
  return (
    <>
      <Helmet>
        <script src='inobounce.js' />
      </Helmet>
      <div className='sticky-footer'>
        <div className='sticky-content'>
          {/* {(!tokenId || state.calendar) && ( */}
          <>
            {history.location.pathname === '/calendar' ? (
              <div
                className={`calendar ${
                  history.location.pathname === '/calendar' && !notify
                    ? 'activeUrl'
                    : ''
                }`}
                onClick={handleTodayEvent}
              >
                <img src={calendar} alt='calendar' />
                <img src={calendarblue} alt='calendar' />
                <h6>Today</h6>
              </div>
            ) : (
              <div
                className={`calendar ${
                  history.location.pathname === '/calendarUrl' && !notify
                    ? 'activeUrl'
                    : ''
                }`}
                onClick={checkNotification ? closeAlert : null}
              >
                <Link to={calendarUrl}>
                  <img src={calendar} alt='calendar' />
                  <img src={calendarblue} alt='calendar' />
                  <h6>Today</h6>
                </Link>
              </div>
            )}
          </>
          {/* )} */}

          <div
            className={`calendar  blocked-time ${
              history.location.pathname === '/calculator' && !notify
                ? 'activeUrl'
                : ''
            }`}
            onClick={checkNotification ? closeAlert : null}
          >
            <Link to='/calculator'>
              <img src={calculator} alt='calendar' />
              <img src={calculatorblue} alt='calendar' />
              <h6>Calculator</h6>
            </Link>
          </div>

          <div
            className={`calendar ${
              history.location.pathname === '/alert' || notify
                ? 'activeUrl'
                : ''
            }`}
            onClick={handleNotify}
          >
            <img src={alert} alt='calendar' />
            <img src={alertblue} alt='calendar' />
            {count ? <span>{parseInt(count, 10) > 0 ? count : ''}</span> : ''}
            <h6>Reminders </h6>
          </div>
          <div
            className={`calendar ${
              history.location.pathname === '/reports' && !notify
                ? 'activeUrl'
                : ''
            }`}
            onClick={checkNotification ? closeAlert : null}
          >
            <Link to='/reports'>
              <img src={reports} alt='calendar' />
              <img src={reportsblue} alt='calendar' />
              <h6>Reports</h6>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
StickyFooter.defaultProps = {
  count: PropTypes.number,
};
StickyFooter.propTypes = {
  TodayEvents: PropTypes.func.isRequired,
  handleNotify: PropTypes.func.isRequired,
  count: PropTypes.number,
  setNotify: PropTypes.func.isRequired,
  checkNotification: PropTypes.bool.isRequired,
  setCheckNotification: PropTypes.func.isRequired,
  notify: PropTypes.bool.isRequired,
};
export default StickyFooter;
