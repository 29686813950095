import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from 'react-bootstrap';

const CancelConfirmation = ({
  openPaymentForm,
  handleAction,
  cancelSubscription,
}) => {
  console.log('sdd===>');
  return (
    <div>
      <div className='staff-modal1 BillSummary1 sucessPopup1'>
        <Modal show={openPaymentForm} className='billSummary1 sucessPopup'>
          <div
            className='back-popup crossBtn crossBtn-Popup'
            onClick={handleAction}
          >
            {/* <i className="las la-times" />  */}
          </div>
          <Modal.Body>
            <div className='d-flex justify-content-center h-100 align-items-center'>
              <div className='text-center'>
                <h1 className='text-danger text-center font-bold mb-3'>
                  Are you sure ?
                </h1>
                <p className='text-dark mb-2'>
                  Do you realy want to delete this subscription? This process
                  cannot be undone
                </p>
                <div className='d-flex align-items-center justify-content-between mt-4 view-btn staff-form-btn'>
                  <button
                    type='submit'
                    className='btn btn-edit w-100 btn btn-primary'
                    // onClick={handleAction}
                    onClick={cancelSubscription}
                  >
                    Close
                  </button>
                  {/* <button
                    type="submit"
                    className="btn btn-save"
                    onClick={cancelSubscription}
                  >
                    Cancel
                  </button> */}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default CancelConfirmation;

CancelConfirmation.propTypes = {
  openPaymentForm: PropTypes.string.isRequired,
  handleAction: PropTypes.string.isRequired,
  cancelSubscription: PropTypes.string.isRequired,
};
