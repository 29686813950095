import React, { useContext, useEffect, useState } from "react";
import TimeKeeper from "react-timekeeper";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "../../functions/moment";
import { AuthDataContext } from "../../contexts/AuthDataContext";
import { getTimeZone } from "../../functions/timeDate";

function TimePicker({
  timepickerShow,
  handleTimePicker,
  bookingData,
  setBookingData,
  timeData,
  setTime,
}) {
  const { userData } = useContext(AuthDataContext);
  const [timeType, setType] = useState("hour");
  const handleTimepickerSet = () => {
    setBookingData({ ...bookingData, startTime: timeData });
    handleTimePicker();
  };

  const handleTime = (selectedTime) => {
    console.log("selectedTime", selectedTime);
    const time = moment(selectedTime.formatted24, ["hh:mm"]).format("H:mm");
    updateCss(
      selectedTime.formattedSimple.split(":")[timeType === "hour" ? 0 : 1],
    );
    setTime(time);
  };

  const updateCss = (time) => {
    const elements = document.getElementsByClassName("css-1ddlhy");
    Array.from(elements).forEach((el) => {
      let color = "black";
      if (el.innerText === time.toString()) {
        color = "white";
      }
      el.style.color = color;
    });
  };

  const updateColor = () => {
    const time = moment(bookingData.startTime, ["HH:mm"]).format("h:mm");
    updateCss(parseInt(time.split(":")[0], 10));
  };

  const handlePickChange = (type) => {
    setTimeout(() => {
      setType(type);
      const time = moment(timeData, ["HH:mm"]).format("h:mm");
      console.log("before time", time);
      updateCss(time.split(":")[type === "hour" ? 0 : 1]);
    }, 250);
  };

  useEffect(() => {
    setTime(bookingData.startTime);
    updateColor();
  }, []);

  return (
    <Modal
      size="md"
      show={timepickerShow}
      onHide={handleTimePicker}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="time-picker-modal"
    >
      <Modal.Body>
        <div className="time-keeper-popup">
          <div className="time-keeper">
            <TimeKeeper
              time={timeData}
              onChange={(data) => handleTime(data)}
              forceCoarseMinutes
              onPickChange={handlePickChange}
            />
          </div>
          <div className="time-keeper-btn">
            <Button onClick={handleTimepickerSet}>Save/Update</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
TimePicker.propTypes = {
  timepickerShow: PropTypes.bool.isRequired,
  handleTimePicker: PropTypes.func.isRequired,
  bookingData: PropTypes.func.isRequired,
  setBookingData: PropTypes.func.isRequired,
  timeData: PropTypes.isRequired,
  setTime: PropTypes.func.isRequired,
};
export default TimePicker;
