/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from "react";
import { Button, Form, Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import DrainageCost from "./drainageCost-Calculation";
import { coverBlankField } from "../../functions";

const AcidWashCalculation = ({
  state,
  handleRequest,
  handleRevertViewUpdate,
  handleChange,
  drainState,
  drainPoolSize,
  handleNewRevertDefaultUpdate,
  change,
  isUsImperial,
  checkBlankFields,
}) => {
  let poolSize = drainPoolSize;

  return (
    <>
      <div className="pool-chemistry-box green-pool-calculation acid-wash">
        <div className="main-title">
          <h6>Drain, Pressure Wash, Chlorine & Acid Wash Calculations</h6>
        </div>
        <div className="acid-wash-inner">
          <div className="pricing-setup">
            <h6>
              Setup pricing for Drain, Pressure Wash, Chlorine & Acid Wash
            </h6>
            <p>
              The blue numbers in the table below will change the costs for
              Draining, Pressure Washing, Chlorine & Acid Washing that display
              in Calculator. Labour costs and chemical quantities are based on a
              pool size of {isUsImperial ? "10,000 gallons" : "40,000 litres"}
              .
              <br />
              Changes to dosages, hourly rates or allocated visit times in Table
              One will affect cost calculations, Updated pricing for Draining,
              Pressure Washing, Chlorine & Acid Washing can be viewed in the 2nd
              table after clicking the Save/Update button at the bottom of Table
              One.
              <br />
              Simply change the pool volume at the top of the 2nd table to view
              the cost impact for changes made to update pricing used by
              Calculator
            </p>
          </div>

          <div className="green-pool-table">
            <h5>
              Drain, Pressure Wash, Chlorine & Acid Wash Calculation Table
            </h5>
            <div className="main-table">
              <div className="table-border">
                <div className="pool-table">
                  <p>
                    Pool Size:{" "}
                    {isUsImperial ? "10,000 gallons" : "40,000 litres"}
                  </p>

                  <p>Dosages & Times</p>
                </div>
                <div className="dosage-part">
                  {Array.isArray(state) ? (
                    <>
                      {state.map((el, index) => (
                        <>
                          {el?.name !== "Pool Size" ? (
                            <>
                              {el.calciName === "chlorineWashQuantity" ||
                              el?.calciName === "acidWashQuantity" ? (
                                <>
                                  <div className="pool-table-content">
                                    <div className="left-pool-table second-dosage">
                                      <p>{el.name}</p>
                                    </div>
                                    <div className="right-pool-table target-level-input">
                                      <p>
                                        <Form>
                                          <Form.Group controlId="formBasicInput">
                                            <Form.Control
                                              type="text"
                                              name="dosageAndTime"
                                              value={el?.dosageAndTime}
                                              onKeyDown={(event) => {
                                                if (
                                                  event.key === "+" ||
                                                  event.key === "-"
                                                ) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              onChange={(e) =>
                                                handleChange(e, index)
                                              }
                                              className={coverBlankField(
                                                el?.dosageAndTime,
                                                el?.calciName,
                                                checkBlankFields,
                                              )}
                                            />
                                          </Form.Group>
                                        </Form>
                                      </p>
                                      <span>
                                        {isUsImperial ? "fl oz" : "litres"}
                                      </span>
                                    </div>
                                  </div>
                                  <hr />
                                </>
                              ) : (
                                <>
                                  <div className="pool-table-content">
                                    <div className="left-pool-table second-dosage">
                                      <p>{el.name}</p>
                                    </div>
                                    <div className="right-pool-table target-level-input">
                                      <p>
                                        <Form>
                                          <Form.Group controlId="formBasicInput">
                                            {/* {el.unit === 'per hour'
                                  ? <span>$</span>
                                  : ''} */}
                                            <Form.Control
                                              type="text"
                                              name="timeReq"
                                              value={el.timeReq}
                                              onKeyDown={(event) => {
                                                if (
                                                  event.key === "+" ||
                                                  event.key === "-"
                                                ) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              onChange={(e) =>
                                                handleChange(e, index)
                                              }
                                              className={coverBlankField(
                                                el?.timeReq,
                                                el?.calciName,
                                                checkBlankFields,
                                              )}
                                            />
                                          </Form.Group>
                                        </Form>
                                      </p>
                                      <span>{el.timeReqUnit}</span>
                                    </div>
                                  </div>

                                  <hr />
                                </>
                              )}
                            </>
                          ) : null}
                        </>
                      ))}
                    </>
                  ) : null}
                </div>
              </div>
              <div className="view-btn">
                <div className="client-option service-edit-icon">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="btn btn-edi"
                    >
                      View Other Settings
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={(e) => handleRevertViewUpdate(e, state)}
                      >
                        <div className="action">Restore Original Settings</div>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => handleNewRevertDefaultUpdate(e, state)}
                      >
                        <div className="action">
                          Revert to Previous Saved Default
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <Button
                  type="submit"
                  className="btn btn-save"
                  onClick={handleRequest}
                >
                  Save as Default
                </Button>
              </div>
              <div className="note-info">
                <span>*</span> Chemical costs for calculations come from
                &quot;Pool Set-up {">"} Chemical Costs and Margins&quot; and
                draining cost from &quot;Pool Set-up {">"} Drainage Cost
                Set-up&quot;.
              </div>
            </div>
          </div>
        </div>
      </div>
      <DrainageCost
        handleChange={handleChange}
        handleRequest={handleRequest}
        drainState={drainState}
        state={state}
        poolSize={poolSize}
        isUsImperial={isUsImperial}
      />
    </>
  );
};
AcidWashCalculation.propTypes = {
  state: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired,
  handleRevertViewUpdate: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  drainState: PropTypes.func.isRequired,
  drainPoolSize: PropTypes.func.isRequired,
  handleNewRevertDefaultUpdate: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  isUsImperial: PropTypes.bool.isRequired,
  checkBlankFields: PropTypes.arrayOf.isRequired,
};
export default AcidWashCalculation;
