import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { IoCloudUploadOutline } from "react-icons/io5";
import { IoIosSave } from "react-icons/io";
import TextField from "../components/textField";
import Button from "../components/button";
import LoadingButton from "../components/loadingButton";
import toastMessage from "../../../functions/toastMessage";
import { chatPath } from "../../../config/keys";
import { userInstance } from "../../../config/axios";

const UploadFileModal = (props) => {
  const { show, handleClose, setUploadedFiles, getUploadedFiles, scope } =
    props;
  const [isUpLoading, setIsUploading] = useState(false);
  const [title, setTitle] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const uploadDataRef = useRef();

  const handleClickUploadBtn = (e) => {
    uploadDataRef.current.click();
  };

  const handleChangeTitle = (value) => {
    setTitle(value);
  };

  const handleUploadFile = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      toastMessage("error", "Please upload a file first!");
      return;
    }
    setTitle(file.name);
    setUploadedFile(file);
  };

  const handleSaveFile = async () => {
    if (uploadedFile === null) {
      toastMessage("error", "Please upload a file first!");
      return;
    }

    if (!(title.endsWith(".pdf") || title.endsWith("PDF"))) {
      toastMessage("error", "Please include the file extension.");
      return;
    }

    try {
      setIsUploading(true);
      const reader = new FileReader();

      // Read the file as an array buffer
      reader.onload = async (e) => {
        const fileBuffer = e.target.result;

        // Convert the ArrayBuffer to a Base64 string
        const base64FileBuffer = btoa(
          new Uint8Array(fileBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            "",
          ),
        );

        let response = await userInstance().post(
          `${chatPath}/chatbotSettings/uploadFile`,
          {
            file_name: title,
            file_type: uploadedFile.type,
            file_buffer_base64: base64FileBuffer,
            scope,
          },
        );
        const { code, msg } = response.data;
        setIsUploading(false);
        if (code === 200) {
          let fileData = response.data.file_data;
          setUploadedFiles((prev) => [...prev, fileData]); // Inline return without curly braces
          initialize();
          handleClose();
          toastMessage("success", msg);
        } else {
          toastMessage("error", msg);
        }
      };
      reader.readAsArrayBuffer(uploadedFile);
    } catch (error) {
      console.log("error", error);
      setIsUploading(false);
    }
  };

  const handleCloseModal = () => {
    initialize();
    handleClose();
  };

  const initialize = () => {
    setUploadedFile(null);
    setTitle("");
  };

  return (
    <>
      <Modal
        show={show}
        centered
        dialogClassName="chatbot-settings-modal-container modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            Upload File
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <TextField
              type="text"
              label="Title"
              placeholder="example.pdf"
              value={title}
              handleChangeValue={handleChangeTitle}
            />
          </div>
          <div className="d-flex align-self-center">
            <div>
              <input
                type="file"
                ref={uploadDataRef}
                hidden
                onChange={handleUploadFile}
                accept=".pdf"
              />
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={handleClickUploadBtn}
                style={{
                  padding: "4px 24px",
                  fontSize: "14px",
                  width: "165px",
                }}
                disabled={isUpLoading}
              >
                <div className="align-self-center">
                  <IoCloudUploadOutline
                    size={18}
                    style={{ marginRight: "16px" }}
                  />
                  <span>Upload Data</span>
                </div>
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2">
            <Button
              label="Cancel"
              handleClickBtn={handleCloseModal}
              disabled={isUpLoading}
            />
            <LoadingButton
              isLoading={isUpLoading}
              icon={<IoIosSave size={18} style={{ marginRight: "16px" }} />}
              label="Save"
              handleClickBtn={handleSaveFile}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

UploadFileModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setUploadedFiles: PropTypes.func.isRequired,
  getUploadedFiles: PropTypes.func.isRequired,
  scope: PropTypes.string.isRequired,
};

export default UploadFileModal;
