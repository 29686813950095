import { categoryObj, filterInitialArray } from "./green_state";
import { calculateEndingTimeArr, handleTime } from "../../functions/index";

export const glassBuyOrSellPriceCalculation = (
  glassCoarseQuantity,
  glassFineQuantity,
  coarseGlassBuyOrSellPrice,
  fineGlassBuyOrSellPrice,
  type,
) => {
  const result =
    parseFloat(glassCoarseQuantity) * parseFloat(coarseGlassBuyOrSellPrice) +
    parseFloat(glassFineQuantity) * parseFloat(fineGlassBuyOrSellPrice);
  return type === "Buy"
    ? (Math.ceil(result / 5) * 5).toFixed(2)
    : result.toFixed(2);
};

export const setUpOrHoursReqOrSandSellOrBuy = (
  costOrHourOrSand,
  labourCostOrSandPrice,
  type,
) => {
  const result =
    parseFloat(costOrHourOrSand) * parseFloat(labourCostOrSandPrice);
  return type && type === "Buy"
    ? (Math.ceil(result / 5) * 5).toFixed(2)
    : result.toFixed(2);
};
export const filterMediaobj = (
  subcategory,
  duration,
  type,
  renderCost,
  previousCost,
  cost,
  totalLabour,
  laborMargin,
  quantity,
  previousDuration,
  buyCostProduct,
  indexx,
) => {
  const filData = filterInitialArray[indexx];
  const updatedData = {
    ...filData,
    subcategory,
    duration,
    type,
    renderCost,
    previousCost,
    cost,
    totalLabour,
    laborMargin,
    quantity,
    previousDuration,
    buyCostProduct,
  };
  filterInitialArray[indexx] = updatedData;
};

const settingFilterDataIntoObject = (filterArray, levelData) => {
  console.log({ filterArray });
  const copyLevelData = [...levelData];
  const copyService = [];
  filterArray.forEach((ele) => {
    const copyInitial = { ...categoryObj };
    copyInitial.subcategory = ele.subcategory;
    copyInitial.duration = ele.duration;
    copyInitial.type = ele.type;
    copyInitial.renderCost = ele.renderCost;
    copyInitial.previousCost = ele.previousCost;
    copyInitial.cost = ele.cost;
    copyInitial.laborMargin = ele.laborMargin;
    copyInitial.totalLabour = ele.totalLabour;
    copyInitial.quantity = ele.quantity;
    copyInitial.previousDuration = ele.previousDuration;
    copyInitial.buyCostProduct = ele.buyCostProduct;
    copyService.push(copyInitial);
  });
  copyLevelData[0].service = copyService;
  return copyLevelData;
};

export const filterCalculation = (
  fData,
  filteringData,
  filterOptions,
  costsFiltering,
) => {
  let media = 0;
  let coarseAndFine = 0;
  let labMargin = 0;
  let msgKeyWord = "";
  let buyCostt = 0;
  let filterHour = filteringData.hoursRequired;
  let filtLabour = filteringData.labourCost;
  let filtSetUp = filteringData.setUpCost;
  if (fData) {
    let coarseQuant = fData.glassCoarseQuantity.quantity;
    let fineQuant = fData.glassFineQuantity.quantity;

    switch (filterOptions) {
      case "Replace with Glass (filters to 3 microns)":
        media =
          parseFloat(
            setUpOrHoursReqOrSandSellOrBuy(filterHour, filtLabour, "Hours"),
          ) +
          parseFloat(
            setUpOrHoursReqOrSandSellOrBuy(filtSetUp, filtLabour, "SetUp"),
          );

        coarseAndFine = glassBuyOrSellPriceCalculation(
          coarseQuant,
          fineQuant,
          costsFiltering.coarseGlassSellCost,
          costsFiltering.fineGlassSellCost,
          "Sell",
        );

        labMargin =
          parseFloat(coarseAndFine) -
          parseFloat(
            glassBuyOrSellPriceCalculation(
              coarseQuant,
              fineQuant,
              costsFiltering.coarseGlassBuyCost,
              costsFiltering.fineGlassBuyCost,
              "Buy",
            ),
          );
        buyCostt = parseFloat(
          glassBuyOrSellPriceCalculation(
            coarseQuant,
            fineQuant,
            costsFiltering.coarseGlassBuyCost,
            costsFiltering.fineGlassBuyCost,
            "Buy",
          ),
        );

        msgKeyWord = "Media Filter - Replace Glass Media";

        break;
      case "Replace with Sand (filters to 25 microns)":
        media =
          parseFloat(
            setUpOrHoursReqOrSandSellOrBuy(filterHour, filtLabour, "Hours"),
          ) +
          parseFloat(
            setUpOrHoursReqOrSandSellOrBuy(filtSetUp, filtLabour, "SetUp"),
          );

        coarseAndFine = setUpOrHoursReqOrSandSellOrBuy(
          fData.bagsSand,
          costsFiltering.sandMediaSellCost,
          "Sell",
        );

        labMargin =
          parseFloat(coarseAndFine) -
          parseFloat(
            setUpOrHoursReqOrSandSellOrBuy(
              fData.bagsSand,
              costsFiltering.sandMediaBuyCost,
              "Buy",
            ),
          );

        buyCostt = parseFloat(
          setUpOrHoursReqOrSandSellOrBuy(
            fData.bagsSand,
            costsFiltering.sandMediaBuyCost,
            "Buy",
          ),
        );

        msgKeyWord = "Media Filter - Replace Sand Media";
        break;
      default:
        break;
    }
  }
  return {
    media,
    coarseAndFine,
    msgKeyWord,
    labMargin,
    buyCostt,
  };
};

export const handleFilterCalculate = (objFilter) => {
  const {
    filterSelect,
    setFilterCost,
    poolFilterData,
    filterOptions,
    filteringData,
    costsFiltering,
    setFilterStatus,
    filterCost,
    levelData,
    serviceAnalysis,
    setLevelData,
    calciumReducer,
  } = objFilter;

  let isValid = false;
  let copyLevelData = [...levelData];
  console.log("sandFilterCopylevelData", copyLevelData);
  if (filterSelect) {
    isValid = true;
  }
  if (isValid) {
    let filtLabour = filteringData.labourCost;
    let index = 0;
    const fData = poolFilterData.filter(
      (el) => el.mediaFilterSize === filterSelect,
    )[index];
    let { media, coarseAndFine, msgKeyWord, labMargin, buyCostt } =
      filterCalculation(fData, filteringData, filterOptions, costsFiltering);

    let setupTime = handleTime("", filteringData.setUpCost, "");
    let hoursReq = handleTime("", filteringData.hoursRequired, "");

    media = Math.ceil(parseFloat(media) / 5) * 5;
    coarseAndFine = Math.ceil(parseFloat(coarseAndFine) / 10) * 10;

    const mediaMsg = `${fData.mediaFilterSize}" ${msgKeyWord}`;
    const prodMsg =
      filterOptions === "Replace with Sand (filters to 25 microns)"
        ? `add ${fData.bagsSand} bags Sand (20 kg)`
        : `add ${fData.glassCoarseQuantity.quantity} ${fData.glassCoarseQuantity.details} (15kg) & ${fData.glassFineQuantity.quantity} ${fData.glassFineQuantity.details} (15kg)`;
    const timeDuration = (setupTime + hoursReq).toString();

    filterInitialArray.forEach((el, indexx) => {
      if (el.category === "Replace") {
        filterMediaobj(
          mediaMsg,
          timeDuration,
          "service",
          media.toFixed(2),
          filtLabour,
          filtLabour,
          media.toFixed(2),
          "0",
          timeDuration,
          timeDuration,
          buyCostt.toFixed(2),
          indexx,
        );
      } else if (el.category === "fineAndCoarse") {
        filterMediaobj(
          prodMsg,
          "5",
          "product",
          coarseAndFine.toFixed(2),
          coarseAndFine,
          coarseAndFine,
          "0",
          labMargin.toFixed(2),
          "1",
          "0",
          buyCostt.toFixed(2),
          indexx,
        );
      }
    });

    copyLevelData = settingFilterDataIntoObject(
      filterInitialArray,
      levelData,
      setLevelData,
    );
    setFilterStatus(true);
    setFilterCost({ ...filterCost, media, coarseAndFine });
    // encryptPool();
  }
  return copyLevelData;
};
