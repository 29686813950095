/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "../admin.css";
import Helmet from "react-helmet";
import Headroom from "react-headroom";
// import { useLocation, useParams } from 'react-router-dom';
import Header from "../../header/header";
import { countries, states } from "../../country-state/country-state";
import HeaderDataProvider from "../../../contexts/HeaderContext";
import Footer from "../../footer/footer";
import Notification from "../../notification/notification";
import { GetNotification } from "../../../socket";
import { userInstance } from "../../../config/axios";
import Loader from "../../loader/loader";
import VideoViewPopUp from "../../videoTutorials/videoViewPopUp";
import VideoUploadPopUp from "../../videoTutorials/videoUploadPopUp";
import BillingPopup from "../../calendarProBilling/billingPopup";
import SubscriptionSelection from "../../calendarProBilling/subscriptionSelection";
import EditMyDetails from "../../calendarProBilling/editMyDetails";
import { AuthDataContext } from "../../../contexts/AuthDataContext";
import { nameRegex, postCodeRegex } from "../../../functions/intervalTime";
import {
  removeCodeFromNumber,
  showErrMsg,
  validateDataWithKey,
  validateCard,
} from "../../../functions";
import { chatPath, emailRegex } from "../../../config/keys";
import { notifyAlert } from "../../calculator/calculatorUtils";
import toastMessage from "../../../functions/toastMessage";
import PaymentDetails from "./paymentDetails";
import ChangeBillingDetails from "../../calendarProBilling/changeBillingDetails";
import ChatBot from "../../chatBot";
import VideoTutorialPopUp from "../../videoTutorials/videoTutorial";
// import VideoUploadList from '../../videoTutorials/videoUploadList';

const defaultChatbotInfos = {
  name: "HelpBot",
  avatar:
    "https://poolwater-pro.com/wp-content/uploads/2024/05/bot-e1716661303394.jpeg",
  greeting:
    "Hello!\nAsk me any questions about our\nPoolWater-Pro software, or get\nhelp to solve your pool issues.",
};

const AdminWrapper = ({
  children,
  TodayEvents,
  isNavClick,
  OpennotificationView,
  showTeam,
}) => {
  let lastScrollTop = 0;

  // const params = useParams();
  // const location = useLocation();

  // console.log({ params, location });

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const { userData, setUserData } = useContext(AuthDataContext);
  const cardInitialData = {
    cardType: "",
    cardNum: "",
    expYear: "",
    expMonth: "",
    cvv: "",
    expirydate: "",
  };

  let nameAction = localStorage.getItem("actionName") || "";

  const [isStickyheader, setIsStickyheader] = useState("");
  const [count, setCount] = useState();
  const [notify, setNotify] = useState();
  const [checkNotification, setCheckNotification] = useState(false);
  const [reminder, setReminder] = useState([]);
  const [todayReminder, setTodayReminder] = useState([]);
  const [upcomingReminder, setUpcomingReminder] = useState([]);
  const [noCompletedReminder, setNotCompletedReminder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatBot, setChatBot] = useState(false);
  const [videoTutorial, setVideoTutorial] = useState(false);

  const [videoData, setVideoData] = useState(null);
  const [viewVideo, setViewVideo] = useState(false);
  const [videoSource, setVideoSource] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedCountry, setCountry] = useState("+61");
  const [stateArray, setStateArray] = useState([]);
  const [user, setUser] = useState(
    JSON.parse(JSON.stringify(userData?.userInformation)),
  );
  const [userCopy, setuserCopy] = useState(
    JSON.parse(JSON.stringify(userData?.userInformation)),
  );
  const [errorState, setErrorState] = useState("");
  const [smallLoader, setSmallLoader] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [cardPayload, setCardPayload] = useState(cardInitialData);
  const [appType, setAppType] = useState(false);
  const [actionName, setActionName] = useState("");

  const [isDowngraded, setisisDowngraded] = useState({
    pools: false,
    users: false,
  });

  const [countryName, setCountryName] = useState(null);
  const [chatbotInfos, setChatbotInfos] = useState(defaultChatbotInfos);

  const getLocation = async () => {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      setCountryName(response.data.country_name);
    } catch (err) {
      console.log("Unable to fetch detailed location information.");
    }
  };

  const getAISettings = async () => {
    try {
      const response = await axios.get(
        `${chatPath}/chatbotSettings/getChatbotInfos?scope=webapp`,
      );
      if (response.status === 200) {
        setChatbotInfos(response.data.chatbotInfos);
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  useEffect(() => {
    if (localStorage.getItem("s#@4f11") && localStorage.getItem("f#@5e11")) {
      setAppType(true);
    }
    getLocation();
    getAISettings();
  }, []);

  const handleCountryStates = (compareValue) => {
    let compareField =
      compareValue === "United States" ? compareValue : "Australia";
    const fIndex = countries?.findIndex((el) => el.name === compareField);
    if (fIndex >= 0) {
      const copyStates = JSON.parse(JSON.stringify(states));
      const filteredArray = copyStates.filter(
        (el) => el.countryCode === countries[fIndex].isoCode,
      );
      const list = filteredArray.sort((a, b) => a.name.localeCompare(b.name));
      setCountry(`+${countries[fIndex].phonecode}`);
      setStateArray(filteredArray);
    }
  };

  // this function will edit subscriber/user details
  const handleEditSubscriber = async (e) => {
    e.preventDefault();
    const {
      firstname,
      lastname,
      email,
      mobileNo,
      gender,
      state,
      postcode,
      streetAddress,
      suburb,
    } = user;
    try {
      const { isValid, emptyField } = validateDataWithKey({
        "first name": firstname.trim(),
        "last name": lastname.trim(),
        mobileNo,
        email,
        streetAddress,
        suburb,
        postcode,
        state,
      });

      if (isValid) {
        setErrorState("");
        if (emailRegex.test(String(user.email).toLowerCase())) {
          let isMobileValid = true;
          let isLandlineValid = true;

          let errorMsg = "";

          const payload = { ...user, email: email.toLowerCase() };

          if (selectedCountry === "+61") {
            if (payload.mobileNo.charAt(0) === "0") {
              payload.mobileNo = payload.mobileNo.replace("0", "");
            }

            if (payload.mobileNo.charAt(0) !== "4") {
              isMobileValid = false;
              errorMsg = "Please start mobile number with 4";
              setErrorState("mobileno");
            }

            if (payload.mobileNo.trim().length !== 11) {
              setErrorState("mobileno");
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              errorMsg = "Enter 9 Digit Mobile Number";
            }
          }

          if (selectedCountry === "+1") {
            if (payload.mobileNo.charAt(0) === "0") {
              payload.mobileNo = payload.mobileNo.replace("0", "");
            }

            if (payload.mobileNo.trim().length !== 12) {
              setErrorState("mobileno");
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              errorMsg = "Enter 10 Digit Mobile Number";
            }
          }

          if (isMobileValid) {
            payload.mobileNo = `${selectedCountry} ${payload.mobileNo}`;
            const response = await userInstance().put(
              "/user/updateUserProfile",
              {
                ...payload,
              },
            );

            const { msg } = response.data;

            handleAction("Calculator-Pro Billing");
            setTimeout(() => {
              setUserData((prev) => ({
                ...prev,
                userInformation: { ...prev.userInformation, ...payload },
              }));
            }, 0);
            notifyAlert(msg, "success");
          } else {
            notifyAlert(errorMsg, "error");
          }
        } else {
          setErrorState("email");
          notifyAlert("Invalid email", "Error");
        }
      } else {
        setErrorState(emptyField?.split(" ").join("").toLowerCase());
        notifyAlert(`Please fill ${emptyField}`, "error");
      }
      setTimeout(() => {
        setErrorState("");
      }, 1000);
    } catch (err) {
      showErrMsg(err);
    }
  };

  // this function will update the Subscription (mainly concerned with Subscription Cost , duration , no of pools and users)
  const handleEditSubscription = async (e) => {
    e.preventDefault();
    if (isDowngraded.pools || isDowngraded.users) {
      console.log("nnn sndn pookk ===>", user);
      handleAction("Calculator-Pro Billing");
      updateDowngrade(user);
      toastMessage(
        "error",
        "Access level downgrades saved will take effect from the end of your current subscription period.",
      );
      setUser(JSON.parse(JSON.stringify(userData?.userInformation)));
      setisisDowngraded({ duration: false, pools: false, users: false });
    } else if (!user?.extraCost) {
      handleAction("Calculator-Pro Billing");
    } else {
      handleAction("Payment Form");
      setSmallLoader(true);
      setTimeout(() => {
        setSmallLoader(false);
      }, 2000);
    }
  };

  const handleEditSubscriptionAfterPayment = async (resultData) => {
    const {
      duration,
      numberOfPools,
      numberUsers,
      subscriptionStatus,
      startsubscriptionDate,
      endsubscriptionDate,
      totalCost,
    } = user;

    let payload = {
      duration,
      numberOfPools,
      numberUsers,
      subscriptionStatus,
      startsubscriptionDate,
      endsubscriptionDate,
      totalCost,
    };
    try {
      const response = await userInstance().put(
        "/subscribers/updateSubscriberAfterPayment",
        payload,
      );
      toastMessage("success", "Subscription Updated");
      setSmallLoader(false);
      let copyOldUser = user;
      delete copyOldUser.extraCost;
      setUser(copyOldUser);
      setuserCopy((prev) => ({ ...prev, ...payload }));
      setUserData((prev) => ({
        ...prev,
        userInformation: { ...prev.userInformation, ...payload },
      }));
      handleAction("Calculator-Pro Billing");
    } catch (err) {
      setSmallLoader(false);
      console.log("error", err);
    }
  };

  const handleToken = async (token) => {
    try {
      if (token) {
        setPaymentLoader(true);
        setSmallLoader(true);
        const amountData = user?.extraCost;
        const payload = {
          sourceId: token.token,
          amountMoney: {
            currency: "USD",
            amount: Math.ceil(amountData),
          },
        };
        const response = await userInstance().post(
          "/subscribers/subscriberPayment",
          payload,
        );
        if (response.data.code === 200) {
          setPaymentLoader(false);
          handleEditSubscriptionAfterPayment(response.data.resultData);
        } else {
          setPaymentLoader(false);
          setSmallLoader(false);
        }
      }
    } catch (error) {
      setPaymentLoader(false);
      setSmallLoader(false);
    }
  };

  const watchScroll = () => {
    let st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop && !isNavClick?.current) {
      setIsStickyheader("header-up");
    } else {
      setIsStickyheader("header-down");
    }
    lastScrollTop = st <= 0 ? 0 : st;
  };

  const getTotalCount = async () => {
    try {
      if (localStorage.getItem("f#@5e")) {
        const response = await userInstance().get(
          "/appointment/getNotification",
        );
        // console.log('asdfasd', response.data);
        const { code, todayList, oldList, unseenCount } = response.data;
        // console.log('resultresult=>', todayList, oldList);
        if (code === 200) {
          // console.log('this is the response unseenCount', unseenCount);
          setCount(unseenCount);
        }
      }
    } catch (err) {
      console.log("the error", err);
    }
  };

  const reminderData = async () => {
    try {
      if (localStorage.getItem("f#@5e")) {
        setLoading(true);
        const response = await userInstance().get("/appointment/getReminder");
        const {
          code,
          todayReminderList,
          upcomingReminderList,
          noCompletedReminderList,
        } = response?.data || {};
        setLoading(false);
        if (code === 200) {
          setTodayReminder(todayReminderList);
          setUpcomingReminder(upcomingReminderList);
          setNotCompletedReminder(noCompletedReminderList);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const handleNotify = async () => {
    reminderData();

    setNotify(true);
    setCheckNotification(true);
    const response = await userInstance().get(
      "/appointment/markasreadNotification",
    );

    const { code } = response.data;
    if (code === 200) {
      setCount(0);
    }
  };

  const handleAction = (inputData) => {
    localStorage.setItem("actionName", inputData);
    setActionName(inputData);
  };

  const handleChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    // console.log({ value, name });
    if (name === "firstname" || name === "lastname") {
      if (value && !nameRegex.test(value)) {
        return;
      }
    }

    if (name === "postcode") {
      if (value && !postCodeRegex.test(value)) {
        return;
      }
    }
    const copyUser = { ...user };
    if (name === "country") {
      // console.log(value);
      handleCountryStates(value);
      copyUser.state = "";
    }
    copyUser[name] = value;

    setUser(copyUser);
  };

  useEffect(() => {
    if (errorState) {
      let element = document.querySelector(".error-text");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [errorState]);

  useEffect(() => {
    if (window !== undefined && window.screen.width <= 768) {
      window.addEventListener("scroll", watchScroll);
    }
    getTotalCount();
    return () => {
      window.removeEventListener("scroll", watchScroll);
    };
  }, []);

  useEffect(() => {
    if (nameAction) {
      setActionName(nameAction);
    }
  }, [nameAction]);

  useEffect(() => {
    if (!actionName) return;
    if (userData?.userInformation) {
      const copyUserData = JSON.parse(
        JSON.stringify(userData?.userInformation),
      );
      const specific_country = copyUserData?.country;
      let specificmobile;
      let specificlandline;
      if (actionName === "Edit My Details") {
        if (copyUserData?.mobileNo) {
          const { updatedNo } = removeCodeFromNumber(copyUserData.mobileNo);
          specificmobile = updatedNo;
        }
        if (copyUserData?.landlineNo) {
          const { updatedNo } = removeCodeFromNumber(copyUserData.landlineNo);
          specificlandline = updatedNo;
        }
        handleCountryStates(specific_country);
      }

      setUser((prev) => ({
        ...prev,
        mobileNo: specificmobile,
        landlineNo: specificlandline,
      }));
    }
  }, [userData?.userInformation?.email, actionName]);

  const updateDowngrade = async (updateData) => {
    console.log("updpdpd dejjd=>");
    const {
      totalCost,
      userid,
      numberUsers,
      numberOfPools,
      endsubscriptionDate,
      startsubscriptionDate,
      duration,
    } = updateData || {};
    const payload = {
      totalCost,
      userid,
      numberUsers,
      numberOfPools,
      endsubscriptionDate,
      startsubscriptionDate,
      duration,
    };
    const response = await userInstance().put(
      "/subscribers/updatedowngrade",
      payload,
    );
    console.log("response ==>", response);
  };

  const handleCardOnChange = (e) => {
    const { name, value } = e.target;
    setCardPayload({ ...cardPayload, [name]: value });
  };

  const updateCardDetails = (e) => {
    e.preventDefault();
    const { cardType, cardNum, expYear, expMonth, cvv, expirydate } =
      cardPayload || {};
    const { isValid, emptyField } = validateDataWithKey({
      "Card Number": cardNum.trim(),
      "Expiry Date": expirydate.trim(),
      cvv: cvv.trim(),
    });
    if (isValid) {
      const { isValid: isValid2, errors } = validateCard(cardPayload);
      if (isValid2) {
        console.log("cardPayload=>", cardPayload);
      } else {
        setErrorState(emptyField?.split(" ").join("").toLowerCase());
        notifyAlert(errors, "error");
      }
      setErrorState("");
    } else {
      setErrorState(emptyField?.split(" ").join("").toLowerCase());
      notifyAlert(`Please fill ${emptyField}`, "error");
    }
  };

  const handleClose = () => {
    handleAction("");
  };

  const isPopup = localStorage.getItem("popup");
  useEffect(() => {
    if (isPopup) {
      setVideoTutorial(true);
      localStorage.removeItem("popup");
    }
  }, [isPopup]);

  return (
    <>
      <div
        className={`admin-wrapper ${isStickyheader} ${notify ? "notification-show" : ""} ${appType ? "hide-header-footer" : ""}`}
      >
        {/* <Loader /> */}
        <Headroom>
          <div className="header-view">
            <HeaderDataProvider>
              <Header
                chatBot={chatBot}
                showTeam={showTeam}
                setChatBot={setChatBot}
                setVideoTutorial={setVideoTutorial}
                setActionName={setActionName}
                handleActionName={handleAction}
              />
            </HeaderDataProvider>
          </div>
        </Headroom>
        <div className="admin-container">
          <div className="admin-content-view">
            <div className="admin-content">{children}</div>
          </div>
        </div>
        <div className="footer-wrapper">
          <Headroom>
            <Footer
              TodayEvents={TodayEvents}
              handleNotify={handleNotify}
              count={count}
              setNotify={setNotify}
              notify={notify}
              checkNotification={checkNotification}
              setCheckNotification={setCheckNotification}
            />
          </Headroom>
        </div>
        <>
          {notify && <div className="popup-section" />}
          <Notification
            reminder={reminder}
            todayReminderList={todayReminder}
            upcomingReminderList={upcomingReminder}
            noCompletedReminderList={noCompletedReminder}
            loading={loading}
            show={notify}
            setNotify={setNotify}
            OpennotificationView={OpennotificationView}
            setCheckNotification={setCheckNotification}
          />
        </>
      </div>
      {actionName === "Chatbot" ? (
        <div>
          <div className="chatbot-popup-overlap" onClick={handleClose} />
          <ChatBot
            handleClose={handleClose}
            countryName={countryName}
            chatbotInfos={chatbotInfos}
          />
        </div>
      ) : null}

      {videoTutorial ? (
        <VideoTutorialPopUp
          videoTutorial={videoTutorial}
          setVideoTutorial={setVideoTutorial}
          actionName={actionName}
          setLoading={setLoader}
          setVideoData={setVideoData}
          setViewVideo={setViewVideo}
          setVideoSource={setVideoSource}
        />
      ) : null}

      {viewVideo && actionName === "Video Tutorials" ? (
        <VideoViewPopUp
          videoData={videoData}
          setViewVideo={setViewVideo}
          videoSource={videoSource}
          loading={loader}
          setVideoTutorial={setVideoTutorial}
          setVideoSource={setVideoSource}
        />
      ) : null}
      {viewVideo && actionName === "Upload Videos" ? (
        <VideoUploadPopUp
          videoData={videoData}
          setViewVideo={setViewVideo}
          videoSource={videoSource}
          isLoading={loader}
          setVideoTutorial={setVideoTutorial}
          setVideoSource={setVideoSource}
        />
      ) : null}
      {actionName === "Calculator-Pro Billing" && (
        <BillingPopup actionName={actionName} handleAction={handleAction} />
      )}
      {actionName === "Edit My Details" && (
        <EditMyDetails
          handleAction={handleAction}
          selectedCountry={selectedCountry}
          countries={countries}
          stateArray={stateArray}
          handleChange={handleChange}
          user={user}
          handleEditSubscriber={handleEditSubscriber}
          error={errorState}
        />
      )}
      {actionName === "Update Subscription" && (
        <SubscriptionSelection
          handleAction={handleAction}
          user={user}
          setUser={setUser}
          userCopy={userCopy}
          handleEditSubscription={handleEditSubscription}
          error={errorState}
          setisisDowngraded={setisisDowngraded}
        />
      )}

      {actionName === "Payment Form" && (
        <PaymentDetails
          user={user}
          smallLoader={smallLoader}
          paymentLoader={paymentLoader}
          handleToken={handleToken}
          returnPopUp="Update Subscription"
          handleAction={handleAction}
        />
      )}
      {actionName === "Change Billing Details" && (
        <ChangeBillingDetails
          handleAction={handleAction}
          user={user}
          cardPayload={cardPayload}
          setCardPayload={setCardPayload}
          handleCardOnChange={handleCardOnChange}
          updateCardDetails={updateCardDetails}
        />
      )}
    </>
  );
};

AdminWrapper.defaultProps = {
  isNavClick: false,
  showTeam: false,
};

AdminWrapper.propTypes = {
  isNavClick: PropTypes.bool,
  showTeam: PropTypes.bool,
  children: PropTypes.node.isRequired,
  TodayEvents: PropTypes.func.isRequired,
  OpennotificationView: PropTypes.func.isRequired,
};
export default AdminWrapper;
