/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import "./calculator.css";
import PropTypes from "prop-types";
import {
  LoadExistValue,
  displayCacliPrices,
  displayDollar,
  displayNotTestedMsg,
} from "./calculatorUtils";

function CalculatorSalt(props) {
  const {
    chlorinationType,
    selectedModel,
    poolInputs,
    calVariable,
    getInGrams,
    getUnit,
    manualValue,
    waterTestOptions,
    taxApplicable,
    calciDisplayChemicals,
    showFullHardness,
    blankInputFields,
    showCalcium,
    titrationPopupOptions,
    greenSplitName,
  } = props;

  const upTax = taxApplicable?.chemicalsTax ? taxApplicable.chemicalsTax : 1;
  const stripExist = poolInputs?.BoxCount > 0;

  const {
    phMsg,
    stabiliserMsg,
    calciumHardnessMsg,
    saltMsg,
    saltMsgConvert,
    chlorineMsg,
    alkMsg,
    clarifierMsg,
    algaecideMsg,
    flockMsg,
  } = calVariable;

  const [poolService, setPoolService] = useState("100.00");
  const cost = poolService ? parseFloat(poolService) : 0;
  const updatedCalciumCost =
    calVariable?.costCalcium === "N/A" ? 0 : calVariable.costCalcium;
  const updatedQuantityCost =
    calVariable?.quantityCostCalcium === "N/A"
      ? 0
      : calVariable.quantityCostCalcium;

  const updatedSaltCost =
    calVariable?.costSalt === "N/A" ? 0 : calVariable.costSalt;
  const updatedSaltQuantityCost =
    calVariable?.quantityCostSalt === "N/A" || !calVariable.quan
      ? 0
      : calVariable.quantityCostSalt;

  const getTotal = () =>
    (
      LoadExistValue(calVariable.costChroline) / upTax +
      LoadExistValue(calVariable.costPh) / upTax +
      LoadExistValue(calVariable.costAlkalinity) / upTax +
      LoadExistValue(calVariable.costStabiliser) / upTax +
      LoadExistValue(updatedCalciumCost) / upTax +
      LoadExistValue(calVariable.costCopper / upTax) +
      LoadExistValue(calVariable.costPhophates) / upTax +
      LoadExistValue(calVariable.costCombinedChlorine) / upTax +
      LoadExistValue(calVariable.costFlock / upTax) +
      LoadExistValue(calVariable.costClarifierMain / upTax) +
      LoadExistValue(calVariable.costAlgaecide / upTax)
    ).toFixed(2);

  const getManuallyTotal = () =>
    (
      LoadExistValue(calVariable.costChroline) / upTax +
      LoadExistValue(calVariable.costPh) / upTax +
      LoadExistValue(calVariable.costAlkalinity) / upTax +
      LoadExistValue(calVariable.costStabiliser) / upTax +
      LoadExistValue(updatedCalciumCost) / upTax +
      LoadExistValue(calVariable.costCopper / upTax) +
      LoadExistValue(calVariable.costPhophates) / upTax +
      LoadExistValue(calVariable.costSaltConvert) / upTax +
      LoadExistValue(calVariable.costCombinedChlorine) / upTax +
      LoadExistValue(calVariable.costFlock / upTax) +
      LoadExistValue(calVariable.costClarifierMain / upTax) +
      LoadExistValue(calVariable.costAlgaecide / upTax)
    ).toFixed(2);

  const getManuallyTotalBase = () =>
    (
      LoadExistValue(calVariable.quantityCostForChlorine) +
      LoadExistValue(calVariable.quantityCostSaltConvert) +
      LoadExistValue(calVariable.quantityCostClarifierMain) +
      LoadExistValue(calVariable.quantityCostAlgaecide) +
      LoadExistValue(calVariable.quantityCostFlock) +
      LoadExistValue(calVariable.quantityCostPh) +
      LoadExistValue(calVariable.quantityCostAlk) +
      LoadExistValue(calVariable.quantityCostStabiliser) +
      LoadExistValue(updatedQuantityCost)
    ).toFixed(2);
  const getTotalBase = () =>
    (
      LoadExistValue(calVariable.quantityCostForChlorine) +
      LoadExistValue(calVariable.quantityCostClarifierMain) +
      LoadExistValue(calVariable.quantityCostAlgaecide) +
      LoadExistValue(calVariable.quantityCostFlock) +
      LoadExistValue(calVariable.quantityCostPh) +
      LoadExistValue(calVariable.quantityCostAlk) +
      LoadExistValue(calVariable.quantityCostStabiliser) +
      LoadExistValue(updatedQuantityCost)
    ).toFixed(2);
  const getPoolSizeTotal = () => {
    const buyCost =
      parseFloat(getTotalBase()) +
      LoadExistValue(calVariable.quantityCostPhosphates) +
      LoadExistValue(calVariable.quantityCostCopper) +
      LoadExistValue(calVariable.quantityCostCombined);
    const total = parseFloat(buyCost).toFixed(2);
    return total;
  };
  const getManuallyPoolSizeTotal = () => {
    const buyCost =
      parseFloat(getManuallyTotalBase()) +
      LoadExistValue(calVariable.quantityCostPhosphates) +
      LoadExistValue(calVariable.quantityCostCopper) +
      LoadExistValue(calVariable.quantityCostCombined);
    const total = parseFloat(buyCost).toFixed(2);
    return total;
  };

  const getPoolSizeTotalSecond = () => {
    const buyCost =
      parseFloat(getTotalBase()) +
      LoadExistValue(updatedSaltCost) +
      LoadExistValue(calVariable.quantityCostPhosphates) +
      LoadExistValue(calVariable.quantityCostCopper) +
      LoadExistValue(calVariable.quantityCostCombined);
    const total = parseFloat(buyCost).toFixed(2);
    return total;
  };

  const getPoolSizeTotalThird = () => {
    const buyCost = parseFloat(
      parseFloat(getTotalBase()) +
        LoadExistValue(updatedSaltCost) +
        LoadExistValue(calVariable.quantityCostSaltConvert) +
        LoadExistValue(calVariable.quantityCostPhosphates) +
        LoadExistValue(calVariable.quantityCostCopper) +
        LoadExistValue(calVariable.quantityCostCombined),
    );

    const total = parseFloat(buyCost).toFixed(2);
    return total;
  };

  const getConsumableMargin = () =>
    parseFloat(
      parseFloat(getTotal()) -
        parseFloat(
          parseFloat(getTotalBase()) +
            LoadExistValue(calVariable.quantityCostPhosphates) +
            LoadExistValue(calVariable.quantityCostCopper) +
            LoadExistValue(calVariable.quantityCostCombined),
        ).toFixed(2),
    );

  const getManuallyConsumableMargin = () =>
    parseFloat(
      parseFloat(getManuallyTotal()) -
        parseFloat(
          parseFloat(getManuallyTotalBase()) +
            LoadExistValue(calVariable.quantityCostPhosphates) +
            LoadExistValue(calVariable.quantityCostCopper) +
            LoadExistValue(calVariable.quantityCostCombined),
        ).toFixed(2),
    );

  const getConsumableMarginSecond = () =>
    parseFloat(
      parseFloat(getTotal()) +
        parseFloat(updatedSaltCost / upTax) -
        parseFloat(
          parseFloat(getTotalBase()) +
            LoadExistValue(updatedSaltQuantityCost) +
            LoadExistValue(calVariable.quantityCostPhosphates) +
            LoadExistValue(calVariable.quantityCostCopper) +
            LoadExistValue(calVariable.quantityCostCombined),
        ).toFixed(2),
    );
  const getConsumableMarginThird = () =>
    parseFloat(
      parseFloat(getTotal()) +
        parseFloat(updatedSaltCost / upTax) +
        parseFloat(calVariable.costSaltConvert / upTax),
    ) -
    parseFloat(
      parseFloat(getTotalBase()) +
        parseFloat(
          calVariable.quantityCostSaltConvert
            ? calVariable.quantityCostSaltConvert.toFixed(2)
            : 0,
        ) +
        LoadExistValue(updatedSaltQuantityCost) +
        LoadExistValue(calVariable.quantityCostPhosphates) +
        LoadExistValue(calVariable.quantityCostCopper) +
        LoadExistValue(calVariable.quantityCostCombined),
    ).toFixed(2);
  // const getValue = (chemical) => {
  //   const { unit, chemicalVal } = getInGrams(chemical);
  //   return { unit, chemicalVal };
  // };

  const getBuffer = () => {
    const inGrams =
      Math.ceil(Math.trunc(calVariable.buffer * 1000) / 100) * 100;
    const lenVal =
      calVariable.buffer.toString().split(".")[0].length >= 2 ? 4 : 3;
    const sodaAshVal =
      calVariable.buffer < 1
        ? inGrams === 1000
          ? calVariable.buffer.toString().substring(0, lenVal)
          : inGrams
        : calVariable.buffer.toString().substring(0, lenVal);
    const unit = getUnit(calVariable.buffer);
    return { chemicalVal: sodaAshVal, unit };
  };

  const getAcid = () => {
    const inMl =
      Math.ceil(Math.trunc(calVariable.hydrochloricAcidAlk * 1000) / 100) * 100;
    const hydrochloricAcidAlkVal =
      calVariable.hydrochloricAcidAlk < 1
        ? inMl === 1000
          ? calVariable.hydrochloricAcidAlk.toFixed(1)
          : inMl
        : calVariable.hydrochloricAcidAlk.toFixed(1);
    const unit =
      calVariable.hydrochloricAcidAlk < 1
        ? inMl === 1000
          ? "litres"
          : "ml"
        : "litres";
    return { chemicalVal: hydrochloricAcidAlkVal, unit };
  };

  const combinedMessage = calVariable?.combinedChlorineMsg?.includes("Zodiac")
    ? calVariable?.combinedChlorineMsg
    : calVariable?.combinedChlorineMsg;

  const saltType =
    chlorinationType === "Mineral Pool" ||
    chlorinationType === "Calcium Hardness Titration Test"
      ? "Mineral Salt"
      : "Salt";
  const calciumName =
    showFullHardness?.calcium ||
    (!showFullHardness?.calcium &&
      chlorinationType === "Mineral Pool" &&
      blankInputFields?.hardCalcium === "") ||
    (chlorinationType === "Mineral Pool" && showCalcium) ||
    calciumHardnessMsg === "Calcium Hardness not tested"
      ? "Calcium Hardness"
      : "Total Hardness";
  const isShowWaterLevel =
    greenSplitName === "Part" &&
    Number(poolInputs?.drainPercentWaterLevelLowered) > 0 &&
    poolInputs?.drainPercentWaterLevelLowered === calVariable?.maxDrain;

  return (
    <div className="content-container cs-container">
      <div className="cs-box">
        <div className="container">
          <div className="row">
            <div className="col-sm-10 sm-offset-1">
              <div className="cs-heading">
                <h3>
                  {`CALCULATION WITH NO ${selectedModel === "Aquatherepe Minerals" || selectedModel === "Aquatherepe Minerals - With ORP Probe" || selectedModel === "Magna Minerals" || selectedModel === "Magna Minerals - With ORP Probe" ? "MINERALS" : "SALT"} TODAY`}
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 sm-offset-1 desktop-view">
              <Table responsive className="pool-size">
                <tbody>
                  <tr>
                    <td>Pool Size : </td>
                    <td>
                      {poolInputs?.poolWidth}
                      <span>X</span>
                      {poolInputs?.poolHeight}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 sm-offset-1">
              <Table responsive className="total-cost">
                <tbody>
                  {/* <tr>
                    <td width="90%">Pool Service Cost </td>
                    <td width="10%">$</td>
                    <td width="10%">
                      <NumberFormat
                        type="text"
                        placeholder="00.00"
                        onChange={(e) => setPoolService(e.target.value)}
                        value={poolService}
                        isAllowed={limitPoolCost}
                      />

                    </td>
                  </tr> */}
                  <tr>
                    <td width="90%" className="bg-transparent">
                      Buy Cost Total
                    </td>
                    <td width="10%" className="bg-transparent">
                      $
                    </td>
                    {manualValue === "Convert Pool to Mineral Pool" ||
                    manualValue.includes(
                      "Aquatherepe Transform Maintenance",
                    ) ? (
                      <td width="10%" className="calc-pool-cost bg-transparent">
                        {getManuallyPoolSizeTotal()}
                      </td>
                    ) : (
                      <td width="10%" className="calc-pool-cost bg-transparent">
                        {getPoolSizeTotal()}
                      </td>
                    )}
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="quantity-table">
                <Table bordered>
                  <thead>
                    <tr>
                      <th>
                        QUANTITY
                        {/* {calVariable.granularChlorine
                          ? (
                            <span>
                              {(calVariable.granularChlorine * 5).toFixed(1)}
                              {' '}
                              liters Liquid Chlorine
                            </span>
                          ) : null} */}
                      </th>
                      <th>BUY COST</th>
                      {/* <th>EX GST</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {chlorineMsg !== "OK" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              chlorineMsg,
                              calVariable.costChroline,
                              "Chlorine",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(
                                calVariable.quantityCostForChlorine,
                              )}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostForChlorine,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costChroline)}</span>
                            <span>{displayCacliPrices(calVariable.costChroline, '', upTax)}</span>
                          </div>
                        </td> */}
                        {/* <td className="value">Chlorine</td> */}
                      </tr>
                    ) : null}
                    {calVariable.combinedChlorineMsg !== "OK" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              calVariable.combinedChlorineMsg
                                .replace(
                                  "AstralPool Oxy Boost",
                                  "Potassium Monopersulphate",
                                )
                                .replace(
                                  "Zodiac Oxy Fresh",
                                  "Potassium Monopersulphate",
                                ),
                              calVariable.costCombinedChlorine,
                              "Combined Chlorine",
                            )}
                            {/* {displayNotTestedMsg(stripExist, calVariable.combinedChlorineMsg.replace('Zodiac', 'Potassium Monopersulphate -'), calVariable.costCombinedChlorine, 'Combined Chlorine')} */}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(calVariable.quantityCostCombined)}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostCombined,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costCombinedChlorine)}</span>
                            <span>{displayCacliPrices(calVariable.costCombinedChlorine, '', upTax)}</span>
                          </div>
                        </td> */}
                        {/* <td className="value">Calcium Increaser</td> */}
                      </tr>
                    ) : null}
                    {phMsg !== "OK" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              phMsg,
                              calVariable.costPh,
                              "Ph",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(calVariable.quantityCostPh)}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostPh,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costPh)}</span>
                            <span>{displayCacliPrices(calVariable.costPh, '', upTax)}</span>
                          </div>
                        </td> */}
                        {/* <td className="value">Soda Ash</td> */}
                      </tr>
                    ) : null}

                    {alkMsg !== "OK" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              alkMsg,
                              calVariable.costAlkalinity,
                              "Alkalinity",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(calVariable.quantityCostAlk)}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostAlk,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costAlkalinity)}</span>
                            <span>{displayCacliPrices(calVariable.costAlkalinity, '', upTax)}</span>
                          </div>
                        </td> */}
                        {/* <td className="value">Acid</td> */}
                      </tr>
                    ) : null}
                    {calciumHardnessMsg !== "OK" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              calciumHardnessMsg,
                              calVariable.costCalcium,
                              calciumName,
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(calVariable.quantityCostCalcium)}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostCalcium,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costCalcium)}</span>
                            <span>{displayCacliPrices(calVariable.costCalcium, '', upTax)}</span>
                          </div>
                        </td> */}
                        {/* <td className="value">Calcium Increaser</td> */}
                      </tr>
                    ) : null}
                    {stabiliserMsg !== "OK" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              stabiliserMsg,
                              calVariable.costStabiliser,
                              "Stabiliser",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(
                                calVariable.quantityCostStabiliser,
                              )}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostStabiliser,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costStabiliser)}</span>
                            <span>{displayCacliPrices(calVariable.costStabiliser, '', upTax)}</span>
                          </div>
                        </td> */}
                        {/* <td className="value">Stabiliser</td> */}
                      </tr>
                    ) : null}
                    {calVariable.copperMsg !== "OK" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              calVariable.copperMsg,
                              calVariable.costCopper,
                              "Copper",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(calVariable.quantityCostCopper)}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostCopper,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costCopper)}</span>
                            <span>{displayCacliPrices(calVariable.costCopper, '', upTax)}</span>
                          </div>
                        </td> */}
                        {/* <td className="value">Calcium Increaser</td> */}
                      </tr>
                    ) : null}
                    {calVariable.phosphateMsg !== "OK" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              calVariable.phosphateMsg,
                              calVariable.costPhophates,
                              "Phosphate",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(
                                calVariable.quantityCostPhosphates,
                              )}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostPhosphates,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costPhophates)}</span>
                            <span>{displayCacliPrices(calVariable.costPhophates, '', upTax)}</span>
                          </div>
                        </td> */}
                        {/* <td className="value">Calcium Increaser</td> */}
                      </tr>
                    ) : null}
                    {waterTestOptions ===
                      "Water Test + Clarifier & Algaecide" ||
                    waterTestOptions === "Water Test + Clarifier" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              clarifierMsg,
                              calVariable.costClarifierMain,
                              "Clarifier",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(
                                calVariable.quantityCostClarifierMain,
                              )}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostClarifierMain,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costClarifierMain)}</span>
                            <span>{displayCacliPrices(calVariable.costClarifierMain, '', upTax)}</span>
                          </div>
                        </td> */}
                      </tr>
                    ) : null}
                    {waterTestOptions ===
                      "Water Test + Clarifier & Algaecide" ||
                    waterTestOptions === "Water Test + Algaecide" ||
                    waterTestOptions === "Water Test + Flock & Algaecide" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              algaecideMsg,
                              calVariable.costAlgaecide,
                              "Algaecide",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(calVariable.quantityCostAlgaecide)}
                            </span>
                            {/* {console.log(calVariable.quantityCostAlgaecide)} */}
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostAlgaecide,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costAlgaecide)}</span>
                            <span>{displayCacliPrices(calVariable.costAlgaecide, '', upTax)}</span>
                          </div>
                        </td> */}
                      </tr>
                    ) : null}
                    {waterTestOptions === "Water Test + Flock & Algaecide" ||
                    waterTestOptions === "Water Test + Flock" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              flockMsg,
                              calVariable.costFlock,
                              "Flock",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(calVariable.quantityCostFlock)}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostFlock,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costFlock)}</span>
                            <span>{displayCacliPrices(calVariable.costFlock, '', upTax)}</span>
                          </div>
                        </td> */}
                      </tr>
                    ) : null}
                    {manualValue === "Convert Pool to Mineral Pool" ||
                    manualValue.includes(
                      "Aquatherepe Transform Maintenance",
                    ) ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              saltMsgConvert,
                              calVariable.costSaltConvert,
                              "Convert Pool",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(
                                calVariable.quantityCostSaltConvert,
                              )}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostSaltConvert,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costSaltConvert)}</span>
                            <span>{displayCacliPrices(calVariable.costSaltConvert, '', upTax)}</span>
                          </div>
                        </td> */}
                      </tr>
                    ) : null}

                    {isShowWaterLevel ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {`Drain ${poolInputs?.drainPercentWaterLevelLowered}% of water`}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>{displayDollar("N/A")}</span>
                            <span>{displayCacliPrices("N/A", "")}</span>
                          </div>
                        </td>
                      </tr>
                    ) : null}

                    <tr>
                      <td>
                        <div className="sub-total">
                          <span>TOTAL</span>
                        </div>
                      </td>
                      <td className="bg-transparent">
                        <div className="buy-cost">
                          <span>$</span>

                          <>
                            {manualValue === "Convert Pool to Mineral Pool" ||
                            manualValue.includes(
                              "Aquatherepe Transform Maintenance",
                            ) ? (
                              <span>
                                {(
                                  parseFloat(getManuallyTotalBase()) +
                                  LoadExistValue(
                                    calVariable.quantityCostPhosphates,
                                  ) +
                                  LoadExistValue(
                                    calVariable.quantityCostCopper,
                                  ) +
                                  LoadExistValue(
                                    calVariable.quantityCostCombined,
                                  )
                                ).toFixed(2)}
                              </span>
                            ) : (
                              <span>
                                {(
                                  parseFloat(getTotalBase()) +
                                  LoadExistValue(
                                    calVariable.quantityCostPhosphates,
                                  ) +
                                  LoadExistValue(
                                    calVariable.quantityCostCopper,
                                  ) +
                                  LoadExistValue(
                                    calVariable.quantityCostCombined,
                                  )
                                ).toFixed(2)}
                              </span>
                            )}
                          </>
                        </div>
                      </td>
                      {/* <td>
                        <div className="buy-cost">
                          <span>$</span>

                          <>
                            {manualValue === 'Convert Pool to Mineral Pool' || manualValue.includes('Aquatherepe Transform Maintenance')
                              ? (
                                <span>
                                  {(parseFloat(getManuallyTotal())).toFixed(2)}
                                </span>

                              )
                              : (
                                <span>
                                  {getTotal()}
                                </span>
                              )}
                          </>

                        </div>
                      </td> */}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cs-box">
        <div className="container">
          <div className="row">
            <div className="col-sm-10 sm-offset-1">
              <div className="cs-heading">
                <h3>
                  {`CALCULATION WITH ADDING ${selectedModel === "Aquatherepe Minerals" || selectedModel === "Aquatherepe Minerals - With ORP Probe" || selectedModel === "Magna Minerals" || selectedModel === "Magna Minerals - With ORP Probe" ? "MINERALS" : "SALT"} TODAY`}
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 sm-offset-1 desktop-view">
              <Table responsive className="pool-size">
                <tbody>
                  <tr>
                    <td>Pool Size : </td>
                    <td>
                      {poolInputs?.poolWidth}
                      <span>X</span>
                      {poolInputs?.poolHeight}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 sm-offset-1">
              <Table responsive className="total-cost">
                <tbody>
                  {/* <tr>
                    <td width="90%">Pool Service Cost </td>
                    <td width="10%">$</td>
                    <td width="10%" className="calc-pool-cost">{poolService || '00.00'}</td>
                  </tr> */}

                  <tr>
                    <td width="90%" className="bg-transparent">
                      Buy Cost Total
                    </td>
                    <td width="10%" className="bg-transparent">
                      $
                    </td>
                    {manualValue === "Convert Pool to Mineral Pool" ||
                    manualValue.includes(
                      "Aquatherepe Transform Maintenance",
                    ) ? (
                      <td width="10%" className="calc-pool-cost bg-transparent">
                        {getManuallyPoolSizeTotal()}
                      </td>
                    ) : (
                      <td width="10%" className="calc-pool-cost bg-transparent">
                        {getPoolSizeTotalSecond()}
                      </td>
                    )}
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="quantity-table">
                <Table bordered>
                  <thead>
                    <tr>
                      <th>
                        QUANTITY
                        {/* {calVariable.granularChlorine
                          ? (
                            <span>
                              {(calVariable.granularChlorine * 5).toFixed(1)}
                              {' '}
                              liters Liquid Chlorine
                            </span>
                          ) : null} */}
                      </th>
                      <th>BUY COST</th>
                      {/* <th>EX GST</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {chlorineMsg !== "OK" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              chlorineMsg,
                              calVariable.costChroline,
                              "Chlorine",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(
                                calVariable.quantityCostForChlorine,
                              )}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostForChlorine,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costChroline)}</span>
                            <span>{displayCacliPrices(calVariable.costChroline, '', upTax)}</span>
                          </div>
                        </td> */}
                        {/* <td className="value">Chlorine</td> */}
                      </tr>
                    ) : null}
                    {calVariable.combinedChlorineMsg !== "OK" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              calVariable.combinedChlorineMsg
                                .replace(
                                  "AstralPool Oxy Boost",
                                  "Potassium Monopersulphate",
                                )
                                .replace(
                                  "Zodiac Oxy Fresh",
                                  "Potassium Monopersulphate",
                                ),
                              calVariable.costCombinedChlorine,
                              "Combined Chlorine",
                            )}
                            {/* {displayNotTestedMsg(stripExist, calVariable.combinedChlorineMsg.replace('Zodiac', 'Potassium Monopersulphate -'), calVariable.costCombinedChlorine, 'Combined Chlorine')} */}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(calVariable.quantityCostCombined)}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostCombined,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costCombinedChlorine)}</span>
                            <span>{displayCacliPrices(calVariable.costCombinedChlorine, '', upTax)}</span>
                          </div>
                        </td> */}
                        {/* <td className="value">Calcium Increaser</td> */}
                      </tr>
                    ) : null}
                    {phMsg !== "OK" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              phMsg,
                              calVariable.costPh,
                              "Ph",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(calVariable.quantityCostPh)}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostPh,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costPh)}</span>
                            <span>{displayCacliPrices(calVariable.costPh, '', upTax)}</span>
                          </div>
                        </td> */}
                        {/* <td className="value">Soda Ash</td> */}
                      </tr>
                    ) : null}

                    {alkMsg !== "OK" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              alkMsg,
                              calVariable.costAlkalinity,
                              "Alkalinity",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(calVariable.quantityCostAlk)}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostAlk,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costAlkalinity)}</span>
                            <span>{displayCacliPrices(calVariable.costAlkalinity, '', upTax)}</span>
                          </div>
                        </td> */}
                        {/* <td className="value">Acid</td> */}
                      </tr>
                    ) : null}
                    {calciumHardnessMsg !== "OK" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              calciumHardnessMsg,
                              calVariable.costCalcium,
                              calciumName,
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(calVariable.quantityCostCalcium)}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostCalcium,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costCalcium)}</span>
                            <span>{displayCacliPrices(calVariable.costCalcium, '', upTax)}</span>
                          </div>
                        </td> */}
                        {/* <td className="value">Calcium Increaser</td> */}
                      </tr>
                    ) : null}
                    {stabiliserMsg !== "OK" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              stabiliserMsg,
                              calVariable.costStabiliser,
                              "Stabiliser",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(
                                calVariable.quantityCostStabiliser,
                              )}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostStabiliser,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costStabiliser)}</span>
                            <span>{displayCacliPrices(calVariable.costStabiliser, '', upTax)}</span>
                          </div>
                        </td> */}
                        {/* <td className="value">Stabiliser</td> */}
                      </tr>
                    ) : null}
                    {calVariable.copperMsg !== "OK" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              calVariable.copperMsg,
                              calVariable.costCopper,
                              "Copper",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(calVariable.quantityCostCopper)}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostCopper,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costCopper)}</span>
                            <span>{displayCacliPrices(calVariable.costCopper, '', upTax)}</span>
                          </div>
                        </td> */}
                        {/* <td className="value">Calcium Increaser</td> */}
                      </tr>
                    ) : null}
                    {calVariable.phosphateMsg !== "OK" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              calVariable.phosphateMsg,
                              calVariable.costPhophates,
                              "Phosphate",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(
                                calVariable.quantityCostPhosphates,
                              )}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostPhosphates,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costPhophates)}</span>
                            <span>{displayCacliPrices(calVariable.costPhophates, '', upTax)}</span>
                          </div>
                        </td> */}
                        {/* <td className="value">Calcium Increaser</td> */}
                      </tr>
                    ) : null}
                    {(saltMsg !== "OK" &&
                      manualValue !== "Convert Pool to Mineral Pool") ||
                    (saltMsg !== "OK" &&
                      manualValue.includes(
                        "Aquatherepe Transform Maintenance",
                      )) ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              saltMsg,
                              calVariable.costSalt,
                              saltType,
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(calVariable.quantityCostSalt)}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostSalt,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costSalt)}</span>
                            <span>{displayCacliPrices(calVariable.costSalt, '', upTax)}</span>
                          </div>
                        </td> */}
                      </tr>
                    ) : null}
                    {waterTestOptions ===
                      "Water Test + Clarifier & Algaecide" ||
                    waterTestOptions === "Water Test + Clarifier" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              clarifierMsg,
                              calVariable.costClarifierMain,
                              "Clarifier",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(
                                calVariable.quantityCostClarifierMain,
                              )}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostClarifierMain,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costClarifierMain)}</span>
                            <span>{displayCacliPrices(calVariable.costClarifierMain, '', upTax)}</span>
                          </div>
                        </td> */}
                      </tr>
                    ) : null}
                    {waterTestOptions ===
                      "Water Test + Clarifier & Algaecide" ||
                    waterTestOptions === "Water Test + Algaecide" ||
                    waterTestOptions === "Water Test + Flock & Algaecide" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              algaecideMsg,
                              calVariable.costAlgaecide,
                              "Algaecide",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(calVariable.quantityCostAlgaecide)}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostAlgaecide,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costAlgaecide)}</span>
                            <span>{displayCacliPrices(calVariable.costAlgaecide, '', upTax)}</span>
                          </div>
                        </td> */}
                      </tr>
                    ) : null}
                    {waterTestOptions === "Water Test + Flock & Algaecide" ||
                    waterTestOptions === "Water Test + Flock" ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              flockMsg,
                              calVariable.costFlock,
                              "Flock",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(calVariable.quantityCostFlock)}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostFlock,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costFlock)}</span>
                            <span>{displayCacliPrices(calVariable.costFlock, '', upTax)}</span>
                          </div>
                        </td> */}
                      </tr>
                    ) : null}
                    {manualValue === "Convert Pool to Mineral Pool" ||
                    manualValue.includes(
                      "Aquatherepe Transform Maintenance",
                    ) ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {displayNotTestedMsg(
                              stripExist,
                              saltMsgConvert,
                              calVariable.costSaltConvert,
                              "Convert Pool",
                            )}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(
                                calVariable.quantityCostSaltConvert,
                              )}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostSaltConvert,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                          <div className="buy-cost">
                            <span>{displayDollar(calVariable.costSaltConvert)}</span>
                            <span>{displayCacliPrices(calVariable.costSaltConvert, '', upTax)}</span>
                          </div>
                        </td> */}
                      </tr>
                    ) : null}

                    {isShowWaterLevel ? (
                      <tr>
                        <td>
                          <div className="quantity">
                            {`Drain ${poolInputs?.drainPercentWaterLevelLowered}% of water`}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>{displayDollar("N/A")}</span>
                            <span>{displayCacliPrices("N/A", "")}</span>
                          </div>
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>
                        <div className="sub-total">
                          <span>TOTAL</span>
                        </div>
                      </td>
                      <td className="bg-transparent">
                        <div className="buy-cost">
                          <span>$</span>

                          <>
                            {manualValue === "Convert Pool to Mineral Pool" ||
                            manualValue.includes(
                              "Aquatherepe Transform Maintenance",
                            ) ? (
                              <span>
                                {(
                                  parseFloat(getManuallyTotalBase()) +
                                  LoadExistValue(
                                    calVariable.quantityCostPhosphates,
                                  ) +
                                  LoadExistValue(
                                    calVariable.quantityCostCopper,
                                  ) +
                                  LoadExistValue(
                                    calVariable.quantityCostCombined,
                                  ) +
                                  LoadExistValue(
                                    calVariable.quantityCostSaltConvert,
                                  )
                                ).toFixed(2)}
                              </span>
                            ) : (
                              <span>
                                {(
                                  parseFloat(getTotalBase()) +
                                  LoadExistValue(updatedSaltCost) +
                                  LoadExistValue(
                                    calVariable.quantityCostPhosphates,
                                  ) +
                                  LoadExistValue(
                                    calVariable.quantityCostCopper,
                                  ) +
                                  LoadExistValue(
                                    calVariable.quantityCostCombined,
                                  )
                                ).toFixed(2)}
                              </span>
                            )}
                          </>
                        </div>
                      </td>
                      {/* <td>
                        <div className="buy-cost">
                          <span>$</span>

                          <>
                            {manualValue === 'Convert Pool to Mineral Pool' || manualValue.includes('Aquatherepe Transform Maintenance')
                              ? (
                                <span>
                                  {(parseFloat(getManuallyTotal())).toFixed(2)}
                                </span>
                              )
                              : (
                                <span>
                                  {(parseFloat(getTotal())
                            + parseFloat(updatedSaltCost / upTax)).toFixed(2)}
                                </span>
                              )}
                          </>

                        </div>
                      </td> */}
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {chlorinationType === "Convert Salt Pool to Mineral Pool" ? (
        <div className="cs-box">
          <div className="container">
            <div className="row">
              <div className="col-sm-10 sm-offset-1">
                <div className="cs-heading">
                  <h3>CALCULATION WITH ADDING SALT & MINERALS TODAY</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-10 sm-offset-1">
                <Table responsive className="pool-size">
                  <tbody>
                    <tr>
                      <td>Pool Size : </td>
                      <td>
                        {poolInputs?.poolWidth}
                        <span>X</span>
                        {poolInputs?.poolHeight}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-10 sm-offset-1">
                <Table responsive className="total-cost">
                  <tbody>
                    {/* <tr>
                        <td width="90%">Pool Service Cost </td>
                        <td width="10%">$</td>
                        <td width="10%" className="calc-pool-cost">{poolService}</td>
                      </tr> */}

                    <tr>
                      <td width="90%" className="bg-transparent">
                        Buy Cost Total{" "}
                      </td>
                      <td width="10%" className="bg-transparent">
                        $
                      </td>
                      <td width="10%" className="calc-pool-cost bg-transparent">
                        {getPoolSizeTotalThird()}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="quantity-table">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>
                          QUANTITY
                          {/* {calVariable.granularChlorine
                              ? (
                                <span>
                                  {(calVariable.granularChlorine * 5).toFixed(1)}
                                  {' '}
                                  liters Liquid Chlorine
                                </span>
                              ) : null} */}
                        </th>
                        <th>BUY COST</th>
                        {/* <th>EX GST</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {chlorineMsg !== "OK" ? (
                        <tr>
                          <td>
                            <div className="quantity">
                              {displayNotTestedMsg(
                                stripExist,
                                chlorineMsg,
                                calVariable.costChroline,
                                "Chlorine",
                              )}
                            </div>
                          </td>
                          <td className="bg-transparent">
                            <div className="buy-cost">
                              <span>
                                {displayDollar(
                                  calVariable.quantityCostForChlorine,
                                )}
                              </span>
                              <span>
                                {displayCacliPrices(
                                  calVariable.quantityCostForChlorine,
                                  "",
                                )}
                              </span>
                            </div>
                          </td>
                          {/* <td>
                              <div className="buy-cost">
                                <span>{displayDollar(calVariable.costChroline)}</span>
                                <span>{displayCacliPrices(calVariable.costChroline, '', upTax)}</span>
                              </div>
                            </td> */}
                          {/* <td className="value">Chlorine</td> */}
                        </tr>
                      ) : null}
                      {calVariable.combinedChlorineMsg !== "OK" ? (
                        <tr>
                          <td>
                            <div className="quantity">
                              {displayNotTestedMsg(
                                stripExist,
                                calVariable.combinedChlorineMsg
                                  .replace(
                                    "AstralPool Oxy Boost",
                                    "Potassium Monopersulphate",
                                  )
                                  .replace(
                                    "Zodiac Oxy Fresh",
                                    "Potassium Monopersulphate",
                                  ),
                                calVariable.costCombinedChlorine,
                                "Combined Chlorine",
                              )}
                              {/* {displayNotTestedMsg(stripExist, calVariable.combinedChlorineMsg.replace('Zodiac', 'Potassium Monopersulphate -'), calVariable.costCombinedChlorine, 'Combined Chlorine')} */}
                            </div>
                          </td>
                          <td className="bg-transparent">
                            <div className="buy-cost">
                              <span>
                                {displayDollar(
                                  calVariable.quantityCostCombined,
                                )}
                              </span>
                              <span>
                                {displayCacliPrices(
                                  calVariable.quantityCostCombined,
                                  "",
                                )}
                              </span>
                            </div>
                          </td>
                          {/* <td>
                              <div className="buy-cost">
                                <span>{displayDollar(calVariable.costCombinedChlorine)}</span>
                                <span>{displayCacliPrices(calVariable.costCombinedChlorine, '', upTax)}</span>
                              </div>
                            </td> */}
                          {/* <td className="value">Calcium Increaser</td> */}
                        </tr>
                      ) : null}
                      {phMsg !== "OK" ? (
                        <tr>
                          <td>
                            <div className="quantity">
                              {displayNotTestedMsg(
                                stripExist,
                                phMsg,
                                calVariable.costPh,
                                "Ph",
                              )}
                            </div>
                          </td>
                          <td className="bg-transparent">
                            <div className="buy-cost">
                              <span>
                                {displayDollar(calVariable.quantityCostPh)}
                              </span>
                              <span>
                                {displayCacliPrices(
                                  calVariable.quantityCostPh,
                                  "",
                                )}
                              </span>
                            </div>
                          </td>
                          {/* <td>
                              <div className="buy-cost">
                                <span>{displayDollar(calVariable.costPh)}</span>
                                <span>{displayCacliPrices(calVariable.costPh, '', upTax)}</span>
                              </div>
                            </td> */}
                          {/* <td className="value">Soda Ash</td> */}
                        </tr>
                      ) : null}

                      {alkMsg !== "OK" ? (
                        <tr>
                          <td>
                            <div className="quantity">
                              {displayNotTestedMsg(
                                stripExist,
                                alkMsg,
                                calVariable.costAlkalinity,
                                "Alkalinity",
                              )}
                            </div>
                          </td>
                          <td className="bg-transparent">
                            <div className="buy-cost">
                              <span>
                                {displayDollar(calVariable.quantityCostAlk)}
                              </span>
                              <span>
                                {displayCacliPrices(
                                  calVariable.quantityCostAlk,
                                  "",
                                )}
                              </span>
                            </div>
                          </td>
                          {/* <td>
                              <div className="buy-cost">
                                <span>{displayDollar(calVariable.costAlkalinity)}</span>
                                <span>{displayCacliPrices(calVariable.costAlkalinity, '', upTax)}</span>
                              </div>
                            </td> */}
                          {/* <td className="value">Acid</td> */}
                        </tr>
                      ) : null}
                      {calciumHardnessMsg !== "OK" ? (
                        <tr>
                          <td>
                            <div className="quantity">
                              {displayNotTestedMsg(
                                stripExist,
                                calciumHardnessMsg,
                                calVariable.costCalcium,
                                calciumName,
                              )}
                            </div>
                          </td>
                          <td className="bg-transparent">
                            <div className="buy-cost">
                              <span>
                                {displayDollar(calVariable.quantityCostCalcium)}
                              </span>
                              <span>
                                {displayCacliPrices(
                                  calVariable.quantityCostCalcium,
                                  "",
                                )}
                              </span>
                            </div>
                          </td>
                          {/* <td>
                              <div className="buy-cost">
                                <span>{displayDollar(calVariable.costCalcium)}</span>
                                <span>{displayCacliPrices(calVariable.costCalcium, '', upTax)}</span>
                              </div>
                            </td> */}
                          {/* <td className="value">Calcium Increaser</td> */}
                        </tr>
                      ) : null}
                      {stabiliserMsg !== "OK" ? (
                        <tr>
                          <td>
                            <div className="quantity">
                              {displayNotTestedMsg(
                                stripExist,
                                stabiliserMsg,
                                calVariable.costStabiliser,
                                "Stabiliser",
                              )}
                            </div>
                          </td>
                          <td className="bg-transparent">
                            <div className="buy-cost">
                              <span>
                                {displayDollar(
                                  calVariable.quantityCostStabiliser,
                                )}
                              </span>
                              <span>
                                {displayCacliPrices(
                                  calVariable.quantityCostStabiliser,
                                  "",
                                )}
                              </span>
                            </div>
                          </td>
                          {/* <td>
                              <div className="buy-cost">
                                <span>{displayDollar(calVariable.costStabiliser)}</span>
                                <span>{displayCacliPrices(calVariable.costStabiliser, '', upTax)}</span>
                              </div>
                            </td> */}
                          {/* <td className="value">Stabiliser</td> */}
                        </tr>
                      ) : null}
                      {calVariable.copperMsg !== "OK" ? (
                        <tr>
                          <td>
                            <div className="quantity">
                              {displayNotTestedMsg(
                                stripExist,
                                calVariable.copperMsg,
                                calVariable.costCopper,
                                "Copper",
                              )}
                            </div>
                          </td>
                          <td className="bg-transparent">
                            <div className="buy-cost">
                              <span>
                                {displayDollar(calVariable.quantityCostCopper)}
                              </span>
                              <span>
                                {displayCacliPrices(
                                  calVariable.quantityCostCopper,
                                  "",
                                )}
                              </span>
                            </div>
                          </td>
                          {/* <td>
                              <div className="buy-cost">
                                <span>{displayDollar(calVariable.costCopper)}</span>
                                <span>{displayCacliPrices(calVariable.costCopper, '', upTax)}</span>
                              </div>
                            </td> */}
                          {/* <td className="value">Calcium Increaser</td> */}
                        </tr>
                      ) : null}
                      {calVariable.phosphateMsg !== "OK" ? (
                        <tr>
                          <td>
                            <div className="quantity">
                              {displayNotTestedMsg(
                                stripExist,
                                calVariable.phosphateMsg,
                                calVariable.costPhophates,
                                "Phosphate",
                              )}
                            </div>
                          </td>
                          <td className="bg-transparent">
                            <div className="buy-cost">
                              <span>
                                {displayDollar(
                                  calVariable.quantityCostPhosphates,
                                )}
                              </span>
                              <span>
                                {displayCacliPrices(
                                  calVariable.quantityCostPhosphates,
                                  "",
                                )}
                              </span>
                            </div>
                          </td>
                          {/* <td>
                              <div className="buy-cost">
                                <span>{displayDollar(calVariable.costPhophates)}</span>
                                <span>{displayCacliPrices(calVariable.costPhophates, '', upTax)}</span>
                              </div>
                            </td> */}
                          {/* <td className="value">Calcium Increaser</td> */}
                        </tr>
                      ) : null}
                      {saltMsg !== "OK" ? (
                        <tr>
                          <td>
                            <div className="quantity">
                              {displayNotTestedMsg(
                                stripExist,
                                saltMsg,
                                calVariable.costSalt,
                                saltType,
                              )}
                            </div>
                          </td>
                          <td className="bg-transparent">
                            <div className="buy-cost">
                              <span>
                                {displayDollar(calVariable.quantityCostSalt)}
                              </span>
                              <span>
                                {displayCacliPrices(
                                  calVariable.quantityCostSalt,
                                  "",
                                )}
                              </span>
                            </div>
                          </td>
                          {/* <td>
                              <div className="buy-cost">
                                <span>{displayDollar(calVariable.costSalt)}</span>
                                <span>{displayCacliPrices(calVariable.costSalt, '', upTax)}</span>
                              </div>
                            </td> */}
                        </tr>
                      ) : null}
                      {waterTestOptions ===
                        "Water Test + Clarifier & Algaecide" ||
                      waterTestOptions === "Water Test + Algaecide" ||
                      waterTestOptions === "Water Test + Flock & Algaecide" ? (
                        <tr>
                          <td>
                            <div className="quantity">
                              {displayNotTestedMsg(
                                stripExist,
                                algaecideMsg,
                                calVariable.costAlgaecide,
                                "Algaecide",
                              )}
                            </div>
                          </td>
                          <td className="bg-transparent">
                            <div className="buy-cost">
                              <span>
                                {displayDollar(
                                  calVariable.quantityCostAlgaecide,
                                )}
                              </span>
                              <span>
                                {displayCacliPrices(
                                  calVariable.quantityCostAlgaecide,
                                  "",
                                )}
                              </span>
                            </div>
                          </td>
                          {/* <td>
                              <div className="buy-cost">
                                <span>{displayDollar(calVariable.costAlgaecide)}</span>
                                <span>{displayCacliPrices(calVariable.costAlgaecide, '', upTax)}</span>
                              </div>
                            </td> */}
                        </tr>
                      ) : null}
                      {waterTestOptions === "Water Test + Flock & Algaecide" ||
                      waterTestOptions === "Water Test + Flock" ? (
                        <tr>
                          <td>
                            <div className="quantity">
                              {displayNotTestedMsg(
                                stripExist,
                                flockMsg,
                                calVariable.costFlock,
                                "Flock",
                              )}
                            </div>
                          </td>
                          <td className="bg-transparent">
                            <div className="buy-cost">
                              <span>
                                {displayDollar(calVariable.quantityCostFlock)}
                              </span>
                              <span>
                                {displayCacliPrices(
                                  calVariable.quantityCostFlock,
                                  "",
                                )}
                              </span>
                            </div>
                          </td>
                          {/* <td>
                              <div className="buy-cost">
                                <span>{displayDollar(calVariable.costFlock)}</span>
                                <span>{displayCacliPrices(calVariable.costFlock, '', upTax)}</span>
                              </div>
                            </td> */}
                        </tr>
                      ) : null}
                      <tr>
                        <td>
                          <div className="quantity">
                            {`Add ${parseInt(calVariable.convertPool, 10)} bags of Aquatherepe Transform (10 kg)`}
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>
                              {displayDollar(
                                calVariable.quantityCostSaltConvert,
                              )}
                            </span>
                            <span>
                              {displayCacliPrices(
                                calVariable.quantityCostSaltConvert,
                                "",
                              )}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                            <div className="buy-cost">
                              <span>{displayDollar(calVariable.costSaltConvert)}</span>
                              <span>{displayCacliPrices(calVariable.costSaltConvert, '', upTax)}</span>
                            </div>
                          </td> */}
                        {/* <td className="value">Drain Salt</td> */}
                      </tr>

                      {isShowWaterLevel ? (
                        <tr>
                          <td>
                            <div className="quantity">
                              {`Drain ${poolInputs?.drainPercentWaterLevelLowered}% of water`}
                            </div>
                          </td>
                          <td className="bg-transparent">
                            <div className="buy-cost">
                              <span>{displayDollar("N/A")}</span>
                              <span>{displayCacliPrices("N/A", "")}</span>
                            </div>
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <td>
                          <div className="sub-total">
                            <span>TOTAL</span>
                          </div>
                        </td>
                        <td className="bg-transparent">
                          <div className="buy-cost">
                            <span>$</span>
                            <span>
                              {(
                                parseFloat(getTotalBase()) +
                                LoadExistValue(
                                  calVariable.quantityCostSaltConvert,
                                ) +
                                LoadExistValue(updatedSaltCost) +
                                LoadExistValue(
                                  calVariable.quantityCostPhosphates,
                                ) +
                                LoadExistValue(calVariable.quantityCostCopper) +
                                LoadExistValue(calVariable.quantityCostCombined)
                              ).toFixed(2)}
                            </span>
                          </div>
                        </td>
                        {/* <td>
                            <div className="buy-cost">
                              <span>$</span>
                              <span>
                                {(parseFloat(getTotal())
                                + parseFloat(updatedSaltCost / upTax)
                                + parseFloat(calVariable.costSaltConvert / upTax)).toFixed(2)}
                              </span>
                            </div>
                          </td> */}
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

CalculatorSalt.propTypes = {
  calVariable: PropTypes.objectOf.isRequired,
  poolInputs: PropTypes.objectOf.isRequired,
  chlorinationType: PropTypes.string.isRequired,
  selectedModel: PropTypes.func.isRequired,
  getInGrams: PropTypes.func.isRequired,
  getUnit: PropTypes.func.isRequired,
  manualValue: PropTypes.string.isRequired,
  waterTestOptions: PropTypes.string.isRequired,
  taxApplicable: PropTypes.objectOf.isRequired,
  titrationPopupOptions: PropTypes.string.isRequired,
  showCalcium: PropTypes.bool.isRequired,
  greenSplitName: PropTypes.string.isRequired,
};

export default CalculatorSalt;
