import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { userInstance } from "../../config/axios";
import { errorMsgTime, successMsgTime } from "../../functions/intervalTime";
import useLocalStorage from "../../functions/hooks/useLocalStorage";

const InvoiceDetailsOption = () => {
  const [checkVal, setCheckVal] = useLocalStorage(
    "chemicalsToDisplayInvoice",
    false,
    false,
    true,
  );
  const handleOnClick = async (e) => {
    e.preventDefault();
    const response = await userInstance().post("/invoice/displayChemical", {
      chemicalsToDisplay: !checkVal,
    });
    const { code, msg } = response.data;
    console.log(response);
    if (code === 200) {
      setCheckVal(!checkVal);
      NotificationManager.success(msg, "Message", successMsgTime);
    } else {
      NotificationManager.error(msg, "Message", errorMsgTime);
    }
  };

  return (
    <div className="pool-setup mineral-pool drainage-cost-section invoice-details-option">
      <div className="target-heading">
        <h6>Invoice & Quotes Details Options</h6>
      </div>
      <div className="chemical-display-section">
        <div className="flex chemical-list" onClick={handleOnClick}>
          <div className="team-member">
            <h6>Show Chemical Quantities Added</h6>
          </div>
          <div className="open-date client-notes">
            <div className="basic">
              <Form.Check
                type="checkbox"
                checked={checkVal}
                onChange={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InvoiceDetailsOption;
