import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Button, Form, Dropdown } from "react-bootstrap";
import { useLocation, Link, useHistory } from "react-router-dom";
import moment from "../../functions/moment";
import arrow from "../../assets/home/arrowdown.png";
import sort from "../../assets/home/sort.png";
import showmore from "../../assets/home/showmoreblue.png";
import { userInstance, notTimeOutUserInstance } from "../../config/axios";
import AdminWrapper from "../admin/adminwrapper/wrapper";
import { getCalendarPath, getCalendarPath2 } from "../../functions/index";
import "./payment.css";
import { errorMsgTime, successMsgTime } from "../../functions/intervalTime";
import toastMessage from "../../functions/toastMessage";

const PaymentReceived = () => {
  const history = useHistory();
  const [status, setStatus] = useState("unpaid");
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [amount, setAmount] = useState(0);
  const [staffs, setStaffs] = useState("");
  const [selectedSaff, setSelectedStaff] = useState("");
  const [selectedSaffId, setselectedSaffId] = useState("");
  const [appointmentAmount, setAppointmentAmount] = useState("");
  const [checkBox, setCheckBox] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const { state } = useLocation();

  const {
    id: appointmentId,
    addedServices,
    gst,
    invoiceSubtotal,
    invoiceTotal,
    detail,
    chemicalsArray,
    pid,
  } = state || {};
  const { client } = detail || {};
  const { _id: clientId } = client || {};
  let { testResult } = chemicalsArray[0] || {};
  let modifiedResult = [];
  if (!testResult) {
    modifiedResult = [];
  } else {
    let { service } = testResult[0] || {};
    modifiedResult = service || [];
  }
  let addedServices1 = addedServices.concat(modifiedResult);
  const xeroPayload = {
    appointmentId,
    addedServices1,
    clientId,
    modifiedResult,
    gst,
    invoiceSubtotal,
    invoiceTotal,
  };

  const getStaffList = async () => {
    try {
      const response = await userInstance().get("/staff/getStaff");
      const { staff } = response.data;
      setStaffs(staff);
      const setDefaultStaff = staff.filter(
        (el) => el._id === localStorage.getItem("staff_id"),
      );
      setselectedSaffId(setDefaultStaff[0]._id);
      setSelectedStaff(
        `${setDefaultStaff[0].firstname} ${setDefaultStaff[0].lastname}`,
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getAppointmentDetail = async () => {
    try {
      const pendingData = state;
      // console.log('pendingData', pendingData);
      setAppointmentAmount(pendingData.invoiceTotal);
    } catch (error) {
      console.log(error);
      //     console.log(error);
    }
  };

  const setPaymentStatus = (info) => {
    // console.log('info', info, appointmentAmount);
    // console.log(parseFloat(appointmentAmount), parseFloat(info));
    setAmount(info);
    if (info) {
      if (
        parseFloat(info, 10) !== 0 &&
        parseFloat(appointmentAmount, 10) === parseFloat(info, 10)
      ) {
        setStatus("paid");
        setCheckBox(true);
      } else if (
        parseFloat(info, 10) !== 0 &&
        parseFloat(info) >= parseFloat(appointmentAmount)
      ) {
        setStatus("paid");
        setCheckBox(true);
      } else if (
        parseFloat(info) !== 0 &&
        parseFloat(appointmentAmount) > parseFloat(info)
      ) {
        setStatus("partpaid");
        setCheckBox(true);
      } else {
        setStatus("unpaid");
        setCheckBox(true);
      }
    } else {
      setStatus("unpaid");
      setCheckBox(true);
      setPaymentMethod(null);
    }
  };
  const handleSelectStaff = (e) => {
    const { value } = e.target || {};
    setselectedSaffId(value);
    const setDefaultStaff = staffs.filter((el) => el._id === value);
    setSelectedStaff(
      `${setDefaultStaff[0].firstname} ${setDefaultStaff[0].lastname}`,
    );
  };

  useEffect(() => {
    getStaffList();
    getAppointmentDetail();
  }, [state]);

  console.log("state", state);

  const savePaymnets = async () => {
    try {
      setDisabled(true);
      const pendingData = state;
      let { interval_type } = pendingData ? pendingData.appointmnetData[0] : {};
      const { repeats } = pendingData || {};
      // console.log('rpIndex', pendingData);
      const rpIndex = parseInt(localStorage.getItem("activeIndex"), 10);
      let uId = "";
      if (localStorage.getItem("activeIndex") !== "-1") {
        uId = repeats[rpIndex] ? repeats[rpIndex].uid : "";
      }
      if (
        (status === "paid" &&
          paymentMethod &&
          parseFloat(amount) <= parseFloat(appointmentAmount)) ||
        status === "unpaid" ||
        (status === "partpaid" && paymentMethod)
      ) {
        let activeEventDate = moment(pendingData.eventDate).format(
          "YYYY-MM-DD",
        );
        const payload = {
          id: pendingData.id,
          paymentMethod,
          selectedSaff,
          status,
          amount: parseFloat(amount, 10).toFixed(2),
          chemicalsAdded: pendingData.chemicalsAdded,
          gst: pendingData.gst,
          invoiceTotal: pendingData.invoiceTotal,
          invoiceSubtotal: pendingData.invoiceSubtotal,
          addedServices: state.addedServices,
          dateData: state.appointmnetData,
          totalData: state.summary,
          eventDate: new Date(activeEventDate).toISOString(),
          interval_type,
          chemicalsArray: pendingData.chemicalsArray,
          uid: uId || "",
          checklistArray: state.checklistArray,
          selectedSaffId: selectedSaffId || localStorage.getItem("staff_id"),
          pid,
        };
        const payload1 = {
          dataData: state.appointmnetData[0],
        };
        const response = await notTimeOutUserInstance().post(
          "/payment/savePayment",
          payload,
        );
        const { code, msg, Id, invoiceId, paymentId_ } = response.data;
        if (msg === "Success") {
          const formdata = new FormData();
          pendingData.image.forEach((el) => {
            formdata.append("file", el.data);
          });
          formdata.append("caption", JSON.stringify(pendingData.image));
          formdata.append("id", JSON.stringify(Id));
          await notTimeOutUserInstance().post(
            "/payment/saveAppointmentImages",
            formdata,
          );
          toastMessage("success", msg);

          if (amount === 0) {
            try {
              const xeroResponse = await notTimeOutUserInstance().post(
                "xero/createInvoice",
                { ...xeroPayload, invoiceId, paymentId_ },
              );
              const {
                data: { msg: xeroMessage },
              } = xeroResponse || {};
              if (xeroMessage !== "Not Connected to Xero") {
                toastMessage("success", xeroMessage);
              }
            } catch (error) {
              let errorMsg = "";
              if (error.response) {
                errorMsg = error.response;
                let message = error.response.data.msg;
                // NotificationManager.error(message, 'Message', errorMsgTime);
                console.error(errorMsg);
              } else if (error.request) {
                errorMsg = error.request;
                console.error(errorMsg);
              } else {
                errorMsg = error.message;
                console.error(errorMsg);
              }
            }
            // try {
            //   const xeroResponse = await notTimeOutUserInstance().post('quickBooks/createInvoice', { ...xeroPayload, invoiceId, paymentId_ });
            //   console.log('this is the response', xeroResponse);
            //   const { data: { msg: xeroMessage } } = xeroResponse || {};
            //   if (xeroMessage === 'Contact is Not Synchronised with Xero') {
            //     NotificationManager.success(xeroMessage, 'Message', successMsgTime);
            //   }
            // } catch (err) {
            //   console.log('Err', err);
            // }
          }
          history.push(getCalendarPath2(pendingData.eventDate));
          // history.push(getCalendarPath());
        }
      } else if (parseFloat(amount) > parseFloat(appointmentAmount)) {
        setDisabled(false);
        toastMessage(
          "error",
          "Payment amount can’t be higher than invoice total",
        );
      } else {
        setDisabled(false);
        toastMessage("error", "Please Choose one payment method");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleCross = () => {
    history.push(`${getCalendarPath()}`);
  };
  // const goToFinalizePage = () => {
  //   const queryParams = new URLSearchParams(window.location.search);
  //   const Id = queryParams.get('data');
  //   localStorage.removeItem('object');
  //   window.location.href = `/finlizevisitcost?data=${Id}`;
  // };
  const checkpaymentMethod = (value) => {
    if (status !== "unpaid") {
      setPaymentMethod(value);
    }
  };
  return (
    <AdminWrapper>
      <div className="appointment-container received-payments">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="back-option paymenticon">
                {/* <Link to={{
                  pathname: '/finlizevisitcost',
                  state: {
                    id: state.id,
                    addedServices: state.addedServices,
                    summary: state.summary,
                    dateBookingData: state.dateBookingData,
                    clientIdentity: state.clientIdentity,
                    checklistArray: state.checklistArray,
                    image: state.image,
                    repeats: state.repeats,
                    eventDate: state.eventDate,
                    customerDues: state.customerDues,
                    chemicalalist: state.chemicalsArray,
                  },
                }}
                >
                  {' '}
                  <img src={arrow} alt="back-btn" />
                  Back
                </Link> */}
                <Link
                  to={{
                    pathname: "/finlizevisitcost",
                    state: {
                      id: state.id,
                      addedServices: state.addedServices,
                      summary: state.summary,
                      dateBookingData: state.dateBookingData,
                      clientIdentity: state.clientIdentity,
                      checklistArray: state.checklistArray,
                      image: state.image,
                      repeats: state.repeats,
                      eventDate: state.eventDate,
                      customerDues: state.customerDues,
                      chemicalalist: state.chemicalsArray,
                    },
                  }}
                >
                  <i className="fa fa-times" aria-hidden="true" />
                </Link>
              </div>
              <div className="main-titles-mob">
                <h3>Payment Received</h3>
              </div>
              <div className="add-appointment">
                <div className="appointment-view appointment-service-modal">
                  <div className="payment-top-heading">
                    <p>Invoice Total : ${appointmentAmount}</p>
                    <h6>Pay</h6>
                    <input
                      type="number"
                      value={amount}
                      min={0}
                      onChange={(e) => setPaymentStatus(e.target.value)}
                    />
                  </div>
                  <div
                    className="added-chemicals"
                    onClick={(e) => checkpaymentMethod("Visa/Mastercard")}
                  >
                    <h6>Visa/Mastercard</h6>
                    <div className="open-date client-notes">
                      <div className="basic">
                        <Form.Check
                          type="checkbox"
                          value="Visa/Mastercard"
                          checked={paymentMethod === "Visa/Mastercard"}
                          onChange={(e) => setPaymentMethod(e.target.value)}
                          disabled={status === "unpaid"}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="added-chemicals"
                    onClick={(e) => checkpaymentMethod("EFT")}
                  >
                    <h6>EFT</h6>
                    <div className="open-date client-notes">
                      <div className="basic">
                        <Form.Check
                          type="checkbox"
                          value="EFT"
                          checked={paymentMethod === "EFT"}
                          onChange={(e) => setPaymentMethod(e.target.value)}
                          disabled={status === "unpaid"}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="added-chemicals" onClick={(e) => checkpaymentMethod('Cash')}> */}
                  <div
                    className="added-chemicals"
                    onClick={(e) => checkpaymentMethod("Cash")}
                  >
                    <h6>Cash</h6>
                    <div className="open-date client-notes">
                      <div className="basic">
                        <Form.Check
                          type="checkbox"
                          value="Cash"
                          checked={paymentMethod === "Cash"}
                          onChange={(e) => setPaymentMethod(e.target.value)}
                          disabled={status === "unpaid"}
                          // disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="added-chemicals"
                    onClick={(e) => checkpaymentMethod("Cheque")}
                  >
                    <h6>Cheque</h6>
                    <div className="open-date client-notes">
                      <div className="basic">
                        <Form.Check
                          type="checkbox"
                          value="Cheque"
                          checked={paymentMethod === "Cheque"}
                          onChange={(e) => setPaymentMethod(e.target.value)}
                          disabled={status === "unpaid"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-appointment">
                <div className="appointment-view appointment-service-modal">
                  <Form.Group controlId="formBasicHiddenEmployees">
                    <Form.Label>Payment Received By</Form.Label>
                    <div className="select-box">
                      <i className="fas fa-sort-down" />
                      <Form.Control
                        as="select"
                        name="pricingType"
                        value={selectedSaffId}
                        onChange={(e) => handleSelectStaff(e)}
                      >
                        {staffs &&
                          staffs.map((el) => (
                            <option value={el._id} key={el._id}>
                              {`${el.firstname} ${el.lastname}`}
                            </option>
                          ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="add-appointment">
                <div className="appointment-view appointment-service-modal">
                  <div className="added-chemicals">
                    <h6>
                      {status === "unpaid"
                        ? "Save as Unpaid"
                        : status === "paid"
                          ? "Save as paid"
                          : "Save As Part Paid"}
                    </h6>
                    <div className="open-date client-notes">
                      <div className="basic">
                        <Form.Check type="checkbox" checked={checkBox} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-btn view-btn mt-4">
                <Button
                  className="appointment-btn btn-save"
                  disabled={disabled}
                  onClick={() => savePaymnets()}
                >
                  Save/ Update
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};
export default PaymentReceived;
