import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import DateModal from "../appointment/dateModal";

const ZodiacCalciumPopUp = ({
  setZodiacStatus,
  handleZodiacCalcium,
  zodiacStatus,
  waterTestOptions,
}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        console.log(event.target, "event", ref.current);
        // ref.current;
        if (ref.current && !ref.current.contains(event.target)) {
          //   console.log('comingUnderThisSection', event.srcElement.className);
          if (
            event.srcElement.className === "duration-picker time-picker-modal"
          ) {
            setZodiacStatus(false);
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="duration-picker time-picker-modal update-source-popup">
      <div>
        <div
          className="time-keeper-popup duration-popup event-popup"
          ref={wrapperRef}
        >
          {waterTestOptions === "Water Test + Flock & Algaecide" ||
          waterTestOptions === "Water Test + Flock" ? (
            <div className="testing-method zodiacCalcium">
              <h6>Application Method</h6>
              <p>
                <span>1.</span> Soda Ash is being added to increase pH to 8.2.
              </p>
              <p>
                <span>2.</span> If pool filtration is via media filter, turn the
                multi-port valve to “Recirculate” or if a cartridge filter,
                remove the filter element.
              </p>
              <p>
                <span>3.</span> Add quantity of flock as specified by Calculator
                and run the filtration system continuously for two hours and
                then turn-off.
              </p>
              <p>
                <span>4.</span> If after 24-48 hours the pool isn’t clear, add
                flock as per the original dosage & repeat points 2 & 3 above.
              </p>

              <p>
                <span>5.</span>
                <div className="italic-text-section">
                  Once the pool is clear if a media filter, turn the multi-port
                  valve to “Waste” and vacuum the flock residual from the floor
                  of the pool.
                  <br />
                  If a cartridge filter also vacuum to waste and not through the
                  filter, as flock will clog the filter element.
                  <br />
                  <i>
                    <b>
                      If the cartridge filter set-up hasn’t been plumbed to
                      bypass the filter, either use a service pump to remove the
                      flock, or don’t flock the pool.{" "}
                    </b>
                  </i>
                </div>
              </p>
              <p>
                <span>6.</span>
                Add the quantity of Hydrochloric Acid or Dry Acid specified in
                Calculator and top-up the pool water to normal operating height.
              </p>
              <p>
                <span>7.</span>
                Return the filtration system to be running on auto.
              </p>
            </div>
          ) : (
            <div className="testing-method zodiacCalcium">
              <h6>Application Method</h6>
              <p>
                <span>1.</span> Soda Ash is being added to increase pH to 8.2.
              </p>
              <p>
                <span>2.</span> Backwash or clean filter prior to adding
                chemicals for this visit.
              </p>
              <p>
                <span>3.</span> Run filter continuously for 72 hours or until
                pool is clear.
              </p>
              <p>
                <span>4.</span> Backwash or clean filter every 24 hours during
                this treatment.
              </p>

              <p>
                <span>5.</span>
                On return visit add the quantity of Hydrochloric Acid or Dry
                Acid specified in Calculator.
              </p>
            </div>
          )}

          <div className="event-popup-btn footer-btn view-btn">
            <Button
              className="appointment-btn btn-edit"
              type="button"
              onClick={() => {
                setZodiacStatus(!zodiacStatus);
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="appointment-btn btn-save"
              onClick={handleZodiacCalcium}
            >
              OK
            </Button>
          </div>
        </div>
      </div>
      {/* <DateModal blockedtime={blockedtime} setBlockedtime={setBlockedtime} /> */}
    </div>
  );
};
ZodiacCalciumPopUp.propTypes = {
  setZodiacStatus: PropTypes.func.isRequired,
  handleZodiacCalcium: PropTypes.func.isRequired,
  zodiacStatus: PropTypes.bool.isRequired,
  waterTestOptions: PropTypes.string.isRequired,
};
export default ZodiacCalciumPopUp;
