/* eslint-disable max-len */
import React from "react";
import { Modal, Form, Col, Row, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { onKeyDownNumber } from "../../functions/index";

const MiniralPopup = ({
  limitNumber,
  miniralPopup,
  handleMiniralPopup,
  calciumAdjustment,
  handleCalciumModelData,
  handleAddCalciumModel,
  handleEditCalciumModel,
  calIndex,
  handleNumberChange,
  handleMineralOrp,
  error,
}) => (
  <Modal
    size="lg"
    show={miniralPopup}
    onHide={handleMiniralPopup}
    aria-labelledby="contained-modal-title-vcenter"
    top
    centered
    className="staff-modal miniral-popup"
  >
    <div className="staff-close">
      <i
        className="fa fa-times"
        onClick={handleMiniralPopup}
        aria-hidden="true"
      />
    </div>
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        Mineral Pool Settings
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="staff-form ">
        <Form>
          <div className="row">
            <div className="col-lg-6 col-12">
              <Form.Group controlId="formBasicInput-1" className="italic-text">
                <Form.Label>
                  {" "}
                  Mineral Brand Name{" "}
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="brandName"
                  value={calciumAdjustment.brandName}
                  onChange={handleCalciumModelData}
                  placeholder="Enter Mineral Brand Name"
                  className={`${error === "brandName" ? "error-text" : ""}`}
                  disabled
                />
              </Form.Group>
            </div>

            <div className="col-lg-6 col-12">
              <Form.Group controlId="formBasicInput-2" className="italic-text">
                <Form.Label>
                  Magnesium Percentage{" "}
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                {/* <Form.Control type="text" name="magnesium" value={calciumAdjustment.magnesium} onChange={handleCalciumModelData} /> */}
                <NumberFormat
                  type="text"
                  name="magnesium"
                  onChange={handleNumberChange}
                  value={calciumAdjustment.magnesium}
                  isAllowed={limitNumber}
                  placeholder="Enter Magnesium"
                  // className="form-control"
                  className={`form-control ${
                    error === "magnesium" ? "error-text" : ""
                  }`}
                  disabled
                />
                <span>%</span>
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-12">
              <Form.Group controlId="formBasicInput-6">
                <Form.Label>
                  Calcium Percentage{" "}
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                {/* <Form.Control type="text" name="calcium" value={calciumAdjustment.calcium} onChange={handleCalciumModelData} /> */}
                <NumberFormat
                  type="text"
                  name="calcium"
                  onChange={handleNumberChange}
                  value={calciumAdjustment.calcium}
                  isAllowed={limitNumber}
                  // className="form-control"
                  className={`form-control ${
                    error === "calcium" ? "error-text" : ""
                  }`}
                  disabled
                />
                <span>%</span>
              </Form.Group>
            </div>
            <div className="col-lg-6 col-12">
              <Form.Group controlId="formBasicInput-1">
                <Form.Label>Manufacturer Recommended Salt Level</Form.Label>
                <Form.Control
                  type="text"
                  name="saltLevel"
                  value={calciumAdjustment.saltLevel}
                  onChange={handleCalciumModelData}
                  className={`${error === "saltLevel" ? "error-text" : ""}`}
                  disabled
                />
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-12">
              <Form.Group controlId="formBasicHiddenEmployees">
                <Form.Label>Chlorination Method</Form.Label>
                <div className="select-box remove-dropdown disableDropdown">
                  {/* <i className="fas fa-sort-down" /> */}
                  <Form.Control as="select" name="startTime" disabled>
                    <option>Automatic Chlorinator</option>
                    <option>Manually Chlorinated</option>
                  </Form.Control>
                </div>
              </Form.Group>
            </div>
            <div className="col-lg-6 col-12">
              <Form.Group controlId="formBasicInput-6">
                <Form.Label>OK - Level</Form.Label>
                <span className="cholinator-text">-</span>
                {/* <Form.Control type="number" name="okNegative" /> */}
                <NumberFormat
                  type="text"
                  name="okNegative"
                  onChange={handleNumberChange}
                  value={calciumAdjustment.okNegative}
                  onKeyPress={(e) => onKeyDownNumber(e)}
                  isAllowed={limitNumber}
                  className={`form-control ${
                    error === "okNegative" ? "error-text" : ""
                  }`}
                />
                <span>%</span>
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-12">
              <Form.Group controlId="formBasicInput-7">
                <Form.Label>OK + Level</Form.Label>
                <span className="cholinator-text">+</span>
                {/* <Form.Control type="number" name="okPositive" /> */}
                <NumberFormat
                  type="text"
                  name="okPositive"
                  onChange={handleNumberChange}
                  value={calciumAdjustment.okPositive}
                  onKeyPress={(e) => onKeyDownNumber(e)}
                  isAllowed={limitNumber}
                  className={`form-control ${
                    error === "okPositive" ? "error-text" : ""
                  }`}
                />
                <span>%</span>
              </Form.Group>
            </div>

            <div className="col-lg-6 col-12">
              <Form.Group controlId="formBasicInput-7">
                <Form.Label>Drain Point</Form.Label>
                <span className="cholinator-text">+</span>
                <NumberFormat
                  type="text"
                  name="drainPoint"
                  onChange={handleNumberChange}
                  value={calciumAdjustment.drainPoint}
                  onKeyPress={(e) => onKeyDownNumber(e)}
                  isAllowed={limitNumber}
                  className={`form-control ${
                    error === "drainPoint" ? "error-text" : ""
                  }`}
                />

                {/* <Form.Control type="number" name="drainPoint" /> */}
                <span>%</span>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12">
              <Form.Group controlId="formBasicHiddenEmployees">
                <Form.Label>ORP Probe Installed</Form.Label>
                <div className="select-box remove-dropdown disableDropdown">
                  {/* <i className="fas fa-sort-down" /> */}
                  <Form.Control
                    as="select"
                    name="orp"
                    onChange={(e) => handleMineralOrp(e.target.value)}
                    value={calciumAdjustment.orp}
                    disabled
                  >
                    <option label="No" name="No" value="No">
                      No
                    </option>
                    <option label="Yes" name="Yes" value="Yes">
                      Yes
                    </option>
                  </Form.Control>
                </div>
              </Form.Group>
            </div>
            {calciumAdjustment.orp === "Yes" && (
              <>
                <div className="col-lg-6 col-12">
                  <Form.Group controlId="formBasicInput-1">
                    <Form.Label>
                      Manufacturer Recommended Stabliser Level
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="stabiliserLevel"
                      value={calciumAdjustment.stabiliserLevel}
                      onChange={handleCalciumModelData}
                      className={`${
                        error === "stabiliserLevel" ? "error-text" : ""
                      }`}
                    />
                  </Form.Group>
                </div>
              </>
            )}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="view-btn staff-form-btn">
                <Button onClick={handleMiniralPopup} className="btn btn-edit">
                  {" "}
                  Cancel{" "}
                </Button>
                <Button
                  type="Submit"
                  className="btn btn-save"
                  onClick={
                    calIndex !== null
                      ? handleEditCalciumModel
                      : handleAddCalciumModel
                  }
                >
                  {" "}
                  Save/Update{" "}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Modal.Body>
  </Modal>
);

MiniralPopup.propTypes = {
  miniralPopup: PropTypes.func.isRequired,
  handleMiniralPopup: PropTypes.func.isRequired,
  handleAddCalciumModel: PropTypes.func.isRequired,
  handleEditCalciumModel: PropTypes.func.isRequired,
  calIndex: PropTypes.func.isRequired,
  handleCalciumModelData: PropTypes.func.isRequired,
  calciumAdjustment: PropTypes.func.isRequired,
  limitNumber: PropTypes.func.isRequired,
  handleNumberChange: PropTypes.func.isRequired,
  handleMineralOrp: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
};

export default MiniralPopup;
