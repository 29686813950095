import React from "react";
import PropTypes from "prop-types";
import { Form, Col, Button } from "react-bootstrap";

const PoolReport = (props) => {
  const {
    handleFilterMediaOptions,
    filterOptions,
    handleFilterCheckChange,
    filterSelect,
    handleFilterCalculate,
    poolFilterData,
    handleReset,
  } = props;

  return (
    <div className="pool-report">
      <Form.Group controlId="exampleForm.SelectCustom">
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>Filter Media Option</Form.Label>
          </Col>
          <Col>
            <div className="select-box">
              <Form.Control
                as="select"
                custom
                onChange={handleFilterMediaOptions}
                value={filterOptions}
              >
                <option value="Replace with Glass (filters to 3 microns)">
                  Replace with Glass (filters to 3 microns)
                </option>
                <option value="Replace with Sand (filters to 25 microns)">
                  Replace with Sand (filters to 25 microns)
                </option>
              </Form.Control>
            </div>
          </Col>
        </Form.Row>
      </Form.Group>
      <Form.Group
        controlId="exampleForm.SelectCustom"
        className="green-pool-color"
      >
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>Select Filter Size</Form.Label>
          </Col>
          <Col>
            <div className="pool-color choose-color">
              {poolFilterData.map((el) => (
                <>
                  {el.mediaFilterSize === "Hours Required" ||
                  el.mediaFilterSize === "Set-up & Pack-up Time" ||
                  el.mediaFilterSize === "Labour Cost" ? null : (
                    <div
                      className="select-color"
                      key={el}
                      onClick={() =>
                        handleFilterCheckChange(el.mediaFilterSize)
                      }
                    >
                      <h6 className={`media${el.mediaFilterSize}`}>
                        {el.mediaFilterSize}
                        &quot; Media Filter
                      </h6>
                      <div className="open-date client-notes">
                        <div className="basic">
                          <Form.Check
                            type="checkbox"
                            name={el.mediaFilterSize}
                            //  onChange={handleFilterCheckChange}
                            checked={
                              filterSelect === el.mediaFilterSize ? true : ""
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}

              {/* <div className="select-color">
                <h6 className="media25">25&quot; Media Filter</h6>
                <div className="open-date client-notes">
                  <div className="basic">
                    <Form.Check
                      type="checkbox"
                      name="filter2"
                      onChange={handleFilterCheckChange}
                      checked={
                        filterSelect === 'filter2' ? true : ''
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="select-color">
                <h6 className="media27">27&quot; Media Filter</h6>
                <div className="open-date client-notes">
                  <div className="basic">
                    <Form.Check
                      type="checkbox"
                      name="filter3"
                      onChange={handleFilterCheckChange}
                      checked={
                        filterSelect === 'filter3' ? true : ''
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="select-color">
                <h6 className="media28">28&quot; Media Filter</h6>
                <div className="open-date client-notes">
                  <div className="basic">
                    <Form.Check
                      type="checkbox"
                      name="filter4"
                      onChange={handleFilterCheckChange}
                      checked={
                        filterSelect === 'filter4' ? true : ''
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="select-color">
                <h6 className="media30">30&quot; Media Filter</h6>
                <div className="open-date client-notes">
                  <div className="basic">
                    <Form.Check
                      type="checkbox"
                      name="filter5"
                      onChange={handleFilterCheckChange}
                      checked={
                        filterSelect === 'filter5' ? true : ''
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="select-color">
                <h6 className="media32">32&quot; Media Filter</h6>
                <div className="open-date client-notes">
                  <div className="basic">
                    <Form.Check
                      type="checkbox"
                      name="filter6"
                      onChange={handleFilterCheckChange}
                      checked={
                        filterSelect === 'filter6' ? true : ''
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="select-color">
                <h6 className="media36">36&quot; Media Filter</h6>
                <div className="open-date client-notes">
                  <div className="basic">
                    <Form.Check
                      type="checkbox"
                      name="filter7"
                      onChange={handleFilterCheckChange}
                      checked={
                        filterSelect === 'filter7' ? true : ''
                      }
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </Col>
        </Form.Row>
      </Form.Group>
      {/* <Form.Group>
        <Form.Row className="calc-btn-bottom">
          <Col>

            <div className="ml-auto calc-btn-reset">
              <div className="back-btn">
                <Button
                  type="button"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </div>
              <div className="calculate-btn">
                <Button
                  type="submit"
                  className="btn btn-primary btn-save"
                  onClick={handleFilterCalculate}
                >

                  Calculate
                </Button>
              </div>
            </div>
            {/* <div className="ml-auto">
              <div className="calculate-btn">
                <Button
                  type="submit"
                  className="btn btn-primary btn-save"
                  onClick={handleFilterCalculate}
                >
                  Calculate
                </Button>
              </div>
            </div>
          </Col>
        </Form.Row>
      </Form.Group>
     */}
    </div>
  );
};
PoolReport.propTypes = {
  filterOptions: PropTypes.string.isRequired,
  filterSelect: PropTypes.string.isRequired,
  handleFilterCheckChange: PropTypes.func.isRequired,
  handleFilterMediaOptions: PropTypes.func.isRequired,
  handleFilterCalculate: PropTypes.func.isRequired,
  poolFilterData: PropTypes.arrayOf.isRequired,
  handleReset: PropTypes.func.isRequired,
};
export default PoolReport;
