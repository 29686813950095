import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import { useLocation, Link, useHistory } from "react-router-dom";
import moment from "../../functions/moment";
import AdminWrapper from "../admin/adminwrapper/wrapper";
import sort from "../../assets/home/sort.png";
import upsort from "../../assets/home/up-sort.png";
import arrow from "../../assets/home/arrowdown.png";
import { userInstance } from "../../config/axios";
import Awaitingapprovals from "./awaitingSendapprovals";
import {
  addZero,
  getCalendarPath,
  queryString,
  currencyComma,
  makingAddress,
  averageGstCalculation,
  gstChemicalsCalculations,
  getCalendarPath2,
} from "../../functions/index";
import downsort from "../../assets/home/down-sort.png";
import showmore from "../../assets/home/showmore.png";
import PreviewInvoicePopup2 from "../invoices/previewInvoicePopup2";
import DateModal from "./dateModal";
import TimePicker from "./timepicker";
import SearchModal from "./searchmodal";
import ServiceModal from "./servicemodal";
import DurationPicker from "./durationPicker";
import HourPicker from "./hourPicker";
import PreviewInvoiceConfirm from "./previewInvoiceConfirm";
import { AuthDataContext } from "../../contexts/AuthDataContext";
import {
  displayCacliPrices,
  displayDollar,
} from "../calculator/calculatorUtils";
import toastMessage from "../../functions/toastMessage";
import { socket } from "../../socket";

import {
  convertDateTime,
  displayTime,
  getTimeZone,
  getTodayTime,
  mutateAppointmentServices,
  updateEndTime,
} from "../../functions/timeDate";

const FinalizeVisitcost = () => {
  const history = useHistory();
  const { state } = useLocation();
  const {
    userData: { industryId, userInformation, calendarSettings },
    clientAllData,
    setClientAllData,
  } = useContext(AuthDataContext);
  const {
    clientIdentity,
    checklistArray,
    image,
    summary: existSummary,
    chemicalalist,
    id: appId,
    visitMessage,
    pid,
  } = state || {};

  const { timeZone } = calendarSettings || {};
  // const chemicalalist = JSON.parse(localStorage.getItem('chemicalalist'));
  const timeFromDateFormat = "HH:mm";
  const dateFormat = "YYYY-MM-DDTHH:mm:ssZ";
  let setDefaultTime = "9:30";
  const initialBookingData = {
    frequency: "One-off appointment | Doesn't repeat",
    repeatEnd: "Ongoing",
    category: "Pool Vacuum & Clean",
    subcategory: "Pool Vacuum & Clean",
    // startTime: '9:30', chnages by sanjana
    startTime: setDefaultTime,
    duration: "5",
    staffId: "",
    staffName: "",
    clientId: "",
    cost: "",
    start: "",
    end: "",
    customCost: "default",
    type: "",
    buyCostProduct: "",
    laborMargin: "",
    quantity: "1",
    timeChange: false,
    customMisc: "",
    previousDuration: "",
    previousQuantity: "",
    pricingType: "fixedCost",
    previousCost: "",
    prevBuyCostProduct: "",
    renderCost: "",
    soldPer: "",
    unit: "",
    symbol: "",
    description: "",
    tax: "",
    s_uId: "",
    categoryId: "",
    supplier: "",
  };
  const otherBookingInitial = {
    category: "Pool Vacuum & Clean",
    subcategory: "Pool Vacuum & Clean",
    duration: "5",
    cost: "",
    start: "",
    end: "",
    customCost: "default",
    type: "",
    buyCostProduct: "",
    laborMargin: "",
    quantity: "1",
    customMisc: "",
    previousDuration: "",
    previousQuantity: "",
    pricingType: "fixedCost",
    soldPer: "",
    unit: "",
    symbol: "",
    tax: "",
    s_uId: "",
    categoryId: "",
  };

  const initialDatePopup = {
    interval_type: "weekly",
    interval_multiplier: "4",
  };
  const query = window.location.href.substring(1);
  let startDateTime = query.split("?startDateTime=");
  const initialdateBookingData = {
    date: moment(startDateTime[1]).format(dateFormat),
    createdOn: new Date(),
    interval_multiplier: null,
    occurence: null,
    interval_type: null,
    endsAt: null,
    endType: null,
    msg: "One-off appointment | Doesn't repeat",
    frequencyMsg: "",
    occurenceMsg: "",
    specificDate: moment().format(dateFormat),
    requiredday: null,
    appointmenttype: "oneteam_oneday",
    service: [],
  };
  const multiNotesState = [{ notes: null }];

  // console.log('appointmentData', state.dateBookingData);
  const [renderSort, setRenderSort] = useState(false);
  const [awating, setAwaiting] = useState();
  const [clients, setClients] = useState([]);
  const [servicess, setServicess] = useState([]);
  const [addedServices, setAddedServices] = useState(state.addedServices);
  // const [appointmnetData, setAppointmentData] = useState(state.dateData);
  const [appointmnetData, setAppointmentData] = useState(state.dateBookingData);
  const [detail, setDetail] = useState({});
  const [totalData, setTotalData] = useState(state.summary);
  const [chemicalsAdded, setChemicalsAdded] = useState(true);
  const [preview, setPreview] = useState(false);
  const [appointmentIndex, setappointmentIndex] = useState("");
  const [staffIndex, setStaffIndex] = useState();
  const [newLength, setnewlength] = useState();
  const [showService, setShowService] = useState(false);
  const [bookingData, setBookingData] = useState(initialBookingData);
  const [subcategoryTxt, setSearchTxt] = useState("");
  const [subcategories, setSubcategories] = useState(null);
  const [misc, setMisc] = useState(false);
  const [multiNotes, setMultiNotes] = useState(multiNotesState);
  const [showSearch, setShowSearch] = useState(false);
  const [showDate, setShowDate] = useState(false);
  // const [searchedClients, setClients] = useState(null);
  const [addedClients, setAddedClients] = useState([]);
  const [categories, setCategories] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [editData, setEditData] = useState({
    edit: false,
    index: "",
    type: "mark",
  });
  const [notes, setNotes] = useState("");
  const [hourPicker, setHourPicker] = useState(false);
  const [hourData, setHourData] = useState("");
  const [dateBookingData, setdateBookingData] = useState(state.dateBookingData);
  const [extras, setExtras] = useState({ value: "", msg: "" });
  const [timepickerShow, setTimePickerShow] = useState(false);
  const [clientTxt, setClientTxt] = useState("");
  const [durationPicker, setDurationPicker] = useState(false);
  const [timeData, setTime] = useState("9:30");
  const [durationData, setDuration] = useState("");
  const [repeatAppt, setRepeatAppt] = useState(false);
  const [repeatFrequency, setRepeatFrequency] = useState("");
  const [datePopupData, setDatePopupData] = useState(initialDatePopup);
  const timepickerRef = useRef(null);

  const [viewAppointmentPopup, setViewappointment] = useState();
  const [appointmentType, setAppointmentType] = useState("oneteam_oneday");
  const [multiServices, setMultiServices] = useState([]);
  const [multiAppointment, setmultiAppointment] = useState(false);
  const [activeAdd, setActiveAdd] = useState("");
  const [newAppointment, setNewAppointment] = useState([
    initialdateBookingData,
  ]);
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [chemicalsArray, setChemicalsArray] = useState([]);
  const [siteAddressCopy, setSiteAddressCopy] = useState({});
  const [confirmPreview, setConfirmPreview] = useState(false);
  const [saveUpdateStatus, setSaveUpdateStatus] = useState(false);
  const [errorValue, setErrorValue] = useState("");
  const [isloader, setIsloader] = useState(false);
  const [currentUserStaff, securrentUserStaff] = useState({});
  const [dbTimeZone, setDbTimeZone] = useState("");

  // const dbTimeZone = 'Etc/GMT-10';
  // const dbTimeZone = 'Asia/Kolkata';

  // 'Asia/Kolkata';
  const handleSaveDate = () => {
    updatedateBookingData();
    handleFrequency();
  };
  const updatedateBookingData = (isEndType) => {
    let { interval_type, interval_multiplier } = datePopupData;
    const originalIntervalType = interval_type;
    let frequencyMsg = "";
    if (parseInt(interval_multiplier, 10) > 1) {
      switch (interval_type) {
        case "monthly":
          interval_type = "months";
          break;
        case "weekly":
          interval_type = "weeks";
          break;
        case "daily":
          interval_type = "days";
          break;

        default:
          break;
      }
    }
    frequencyMsg =
      parseInt(interval_multiplier, 10) > 1
        ? `Repeats every ${interval_multiplier} ${interval_type}`
        : interval_type;
    const updateObj = {
      ...dateBookingData,
      interval_multiplier,
      interval_type: originalIntervalType,
      frequencyMsg,
    };
    if (isEndType) {
      updateObj.endType = "ongoing";
    }
    setdateBookingData(updateObj);
  };

  const handleFrequency = () => {
    setRepeatFrequency(!repeatFrequency);
    if (!repeatFrequency) {
      setTimeout(() => {
        timepickerRef.current.value = `${datePopupData.interval_multiplier}:00`;
      }, 350);
    }
  };
  const replaceComma = totalData.totalCost
    ? totalData.totalCost.replace(",", "")
    : 0;
  let invoiceSubTotal = parseFloat(replaceComma || totalData.totalCost, 10);
  let invoiceTotal = 0;
  let gst = 0;
  const [showInvoice, setShowInvoice] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [specificDropDown, setspecificDropDown] = useState(false);
  const handleClose = () => {
    setPreviewUrl(null);
    setShowInvoice(false);
  };

  const handleSpecificInvoice = async (id) => {
    try {
      setPreviewUrl();
      let dta = detail;
      dta.dateData = appointmnetData;
      dta.chemicalsArray = chemicalalist;
      const newDate = `${moment(new Date()).format("dddd")}, ${moment(
        new Date(),
      ).format("D MMMM YYYY")}`;
      const invoice = {
        templateId: id,
        appointment: [dta],
        invoiceNo: "N/A",
        createdAt: newDate,
        invoiceTotal: invoiceTotal.toFixed(2),
        amountApplied: 0,
        gst: gst.toFixed(2),
        balanceDue: invoiceTotal.toFixed(2),
        customer: detail.client,
        invoiceSubTotal: invoiceSubTotal.toFixed(2),
      };
      const payload = {
        invoice,
      };
      const response = await userInstance().post(
        "/template/previewSpecificFinalizePdf",
        payload,
      );
      console.log(response);
      const { code, msg, tempId } = response.data;
      console.log("this is the tempId", tempId);
      setPreviewUrl(tempId);
    } catch (error) {
      console.log(error);
      //   showErrMsg(error);
    }
  };

  useEffect(() => {
    getInvoiceDetail();
  }, []);

  const getInvoiceDetail = async () => {
    try {
      const Id = state.id;
      // console.log('Id', Id);
      const response = await userInstance().get(
        `/appointment/getAppointmentById?id=${Id}`,
      );
      const staffGet = await userInstance().get("/staff/getStaff");

      const { staff } = staffGet.data;

      const { appointmentdetail } = response.data;
      setDbTimeZone(appointmentdetail.timeZone);

      let currentUserStaff_ = staff.find(
        (el) => el._id === appointmentdetail.staffId,
      );
      securrentUserStaff(currentUserStaff_);
      setDetail(appointmentdetail);
      setClients([appointmentdetail.client]);
      const dat =
        appointmentdetail?.client?.siteAddresses[
          appointmentdetail?.clientSiteAddressIndex
        ];
      setSiteAddressCopy(dat);
      if (
        appointmentdetail.chemicalsArray &&
        appointmentdetail.chemicalsArray.length
      ) {
        setChemicalsArray(appointmentdetail.chemicalsArray);
        setChemicalsAdded(true);
      } else {
        setChemicalsAdded(true);
      }
      // setAppointmentData(appointmentdetail.dateData);
    } catch (error) {
      console.log(error);
      //   showErrMsg(error);
    }
  };
  const moveArr = (oldIndex, newIndex, sign) => {
    const servicesCopy = [...addedServices];
    const { duration, start, startTime } = addedServices[newIndex];

    let newNextStartTime;
    let currentNewStartTime;
    if (sign === "plus") {
      const currentEntryStart = new Date(servicesCopy[oldIndex].start);
      const updatedNextEntryStart = moment(
        new Date(
          currentEntryStart.setMinutes(
            currentEntryStart.getMinutes() + parseFloat(duration),
          ),
        ),
      ).format(dateFormat);
      newNextStartTime = moment(updatedNextEntryStart).format(
        timeFromDateFormat,
      );

      currentNewStartTime = servicesCopy[oldIndex].startTime;
    } else if (sign === "minus") {
      newNextStartTime = startTime;

      const updatedCurrentEntryStart = moment(
        new Date(
          new Date(start).setMinutes(
            new Date(start).getMinutes() +
              parseFloat(servicesCopy[oldIndex].duration),
          ),
        ),
      ).format(dateFormat);
      currentNewStartTime = moment(updatedCurrentEntryStart).format(
        timeFromDateFormat,
      );
    }
    const updatedOldIndex = {
      ...addedServices[oldIndex],
      startTime: newNextStartTime,
    };
    const updatedNewIndex = {
      ...addedServices[newIndex],
      startTime: currentNewStartTime,
    };
    servicesCopy[oldIndex] = { ...updatedOldIndex };
    servicesCopy[newIndex] = { ...updatedNewIndex };
    if (newIndex >= servicesCopy.length) {
      let k = newIndex - servicesCopy.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        servicesCopy.push(undefined);
      }
    }
    servicesCopy.splice(newIndex, 0, servicesCopy.splice(oldIndex, 1)[0]);
    calculateDurations(servicesCopy);
    // setServices([...servicesCopy]);
  };

  const calculateDurations = (info) => {
    info.forEach((el) => {
      let start = moment(el.date).toDate();
      const time = el.startTime.split(":");
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);

      el.start = moment(start).format(dateFormat);
      el.end = moment(
        new Date(
          start.setMinutes(start.getMinutes() + parseFloat(el.duration)),
        ),
      ).format(dateFormat);
    });
    let copyappointmnetData = [...appointmnetData];
    copyappointmnetData[0].service = info;
    setAppointmentData(copyappointmnetData);
    setAddedServices([...info]);
  };

  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msg = "";
    msg =
      hours && minutes
        ? `${hours}hr ${minutes} min`
        : hours && !minutes
          ? `${hours}hr`
          : `${minutes} min`;
    return msg;
  };

  const calculateGst = () => {
    // gst calcultion function and changes by faizan
    // <<Start>>
    const resultGst = averageGstCalculation(appointmnetData);
    let chemicalGst = 0;

    if (chemicalalist && chemicalsAdded && chemicalalist.length > 0) {
      chemicalGst = gstChemicalsCalculations(
        chemicalalist[0]?.testResult[0]?.service,
      );
    }

    // console.log('chemicalGst', chemicalGst);

    const { perfectGstPer, totalCost } = resultGst;
    const gstAmount = totalCost * (perfectGstPer / 100) + (chemicalGst || 0);
    gst = gstAmount;
    return gstAmount;
    // <<End>>

    // const gstPercent = 10;
    // const gstAmount = parseFloat(invoiceSubTotal, 10) * (gstPercent / 100);
    // gst = gstAmount;
    // return gstAmount;
  };

  const calculateInvoiceTotal = () => {
    // gst calcultion function and changes by faizan
    // <<Start>>
    const resultGst = averageGstCalculation(appointmnetData);
    let chemicalGst = 0;

    if (chemicalalist && chemicalsAdded && chemicalalist.length > 0) {
      chemicalGst = gstChemicalsCalculations(
        chemicalalist[0]?.testResult[0]?.service,
      );
    }
    const { perfectGstPer, totalCost } = resultGst;
    const gstAmount = totalCost * (perfectGstPer / 100) + (chemicalGst || 0);
    // const gstPercent = 10;
    // const gstAmount = parseFloat(invoiceSubTotal, 10) * (gstPercent / 100);
    const totalAmount = parseFloat(invoiceSubTotal, 10) + gstAmount;
    invoiceTotal = totalAmount;
    return totalAmount;
    // <<End>>
  };

  const addChemicalCost = (e) => {
    setChemicalsAdded(e.target.checked);
  };

  const handleShowService = (index) => {
    setappointmentIndex(index);
    setStaffIndex(0);
    setnewlength(addedServices.length);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    setShowService(!showService);
    setEditData({ edit: false, index: "", type: "mark" });
    const lastService = addedServices[addedServices.length - 1];
    console.log("handleShowService=>", addedServices);
    if (lastService) {
      // console.log('lastService', lastService);
      // const startTime = moment(lastService.end).format(timeFromDateFormat);
      const startTime = convertDateTime(lastService.end).format(
        timeFromDateFormat,
      );
      setBookingData({ ...bookingData, startTime, ...otherBookingInitial });
    } else {
      setBookingData({ ...bookingData, ...otherBookingInitial });
    }
    setSearchTxt("");
    setSubcategories(null);
    setMisc(false);
  };

  const getTimes = () => g();

  const g = () => {
    const x = {
      slotInterval: 5,
      openTime: "12:25 AM",
      closeTime: "23:30 PM",
    };

    // Format the time
    const startTime = moment(x.openTime, "h:mm A");

    // Format the end time and the next day to it
    const endTime = moment(x.closeTime, "h:mm A");

    // Times
    const allTimes = [];

    // Loop over the times - only pushes time with 30 minutes interval
    while (startTime <= endTime) {
      // Push times
      allTimes.push(startTime.format("h:mm A"));
      // Add interval of 30 minutes
      startTime.add(x.slotInterval, "minutes");
    }
    return allTimes;
  };

  const handleDateModal = (handeltype, index) => {
    const {
      date,
      occurence,
      interval_multiplier,
      interval_type,
      endType,
      occurenceMsg,
      specificDate,
      requiredday,
      createdOn,
      endsAt,
    } = dateBookingData;
    if (index || index === 0) {
      console.log("check Date", dateBookingData);
      setappointmentIndex(index);
      let oldStateData = [...newAppointment];
      console.log("newAppointment.........", dateBookingData);
      let startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0);
      let endDate = new Date(endsAt || startDate);
      endDate.setHours(0, 0, 0, 0);
      console.log("startDate", startDate);
      if (
        index === 0 &&
        handeltype === "add" &&
        (appointmentType === "oneteam_multiday" ||
          appointmentType === "multiteam_multiday")
      ) {
        // eslint-disable-next-line no-undef
        let nostep = requiredday || 2;
        for (let step = 0; step < nostep; step++) {
          startDate.setDate(startDate.getDate() + step);
          let dateBookingDataCopy = {
            ...dateBookingData,
            date: moment(startDate).format(dateFormat),
            createdOn: moment(createdOn).format(dateFormat),
            endsAt: moment(endDate).format(dateFormat),
          };
          oldStateData[step] = dateBookingDataCopy;
        }
        // console.log('data_arraydata_array', oldStateData);
      } else if (
        handeltype === "add" &&
        (appointmentType === "oneteam_multiday" ||
          appointmentType === "multiteam_multiday")
      ) {
        let count = 0;
        for (let step = index; step < newAppointment.length; step++) {
          startDate.setDate(startDate.getDate() + count);
          count += 1;
          let dateBookingDataCopy = {
            ...dateBookingData,
            date: moment(startDate).format(dateFormat),
            createdOn: moment(createdOn).format(dateFormat),
            endsAt: moment(endDate).format(dateFormat),
          };
          oldStateData[step] = dateBookingDataCopy;
        }
      } else {
        let dateBookingDataCopy = {
          ...dateBookingData,
          date: moment(startDate).format(dateFormat),
          createdOn: moment(createdOn).format(dateFormat),
          endsAt: moment(endDate).format(dateFormat),
        };
        oldStateData[index] = dateBookingDataCopy;
      }
      oldStateData[index].service = newAppointment[index].service;
      setNewAppointment(oldStateData);
    }
    if (
      handeltype === "add" &&
      (appointmentType === "oneteam_multiday" ||
        appointmentType === "multiteam_multiday")
    ) {
      setmultiAppointment(true);
      setdateBookingData({
        ...dateBookingData,
        requiredday: requiredday || 2,
        msg:
          appointmentType === "multiteam_multiday"
            ? `Additional Team Members Multi Visit | ${requiredday || 2} days | Doesn't repeat`
            : `One Team Member Multi Visit | ${requiredday || 2} days | Doesn't repeat`,
      });
      let nostep = requiredday || 2;
      for (let step = 0; step < nostep; step++) {
        setMultiNotes([...multiNotes, { notes: null }]);
      }
    }
    let msgIntervalType = "days";
    if (interval_multiplier) {
      let newDate = new Date(
        occurenceMsg === "On a Specific Date" ? specificDate : date,
      );
      // console.log('new date', newDate);
      newDate.setHours(12, 0, 0, 0);
      let interval = interval_multiplier;
      if (interval_type === "weekly") {
        interval *= 7;
        msgIntervalType = "weeks";
      } else if (interval_type === "monthly") {
        // newDate.setDate(newDate.getDate() + daysToAdd);
        newDate = new Date(
          moment(newDate, "DD-MM-YYYY")
            .add(occurence * parseInt(interval, 10), "months")
            .format("L"),
        );
        // console.log('new date after month', newDate);
        msgIntervalType = "months";
      }
      if (endType === "ongoing") {
        newDate.setFullYear(newDate.getFullYear() + 1);
        newDate.setDate(newDate.getDate() - 1);
      } else if (
        occurenceMsg !== "On a Specific Date" &&
        interval_type !== "monthly"
      ) {
        const daysToAdd = occurence * interval;
        // console.log('daysToAdd', daysToAdd);
        newDate.setDate(newDate.getDate() + daysToAdd);
        // console.log('date', newDate, 'old date', date);
      }
      const msg =
        endType === "ongoing"
          ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ongoing`
          : occurenceMsg !== "On a Specific Date"
            ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends after ${occurence} times on ${moment(newDate).format("dddd")}, ${moment(newDate).format("D MMMM YYYY")}`
            : `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends on ${moment(newDate).format("dddd")}, ${moment(newDate).format("D MMMM YYYY")}`;
      setdateBookingData({ ...dateBookingData, msg, endsAt: newDate });
    }
    // console.log('fvmdjm', { ...dateBookingData });
    setShowDate(!showDate);
    // console.log('handeltypehandeltype', handeltype, appointmentType);
  };

  const setStaff = (staffName, staffId) => {
    // console.log('staffId', staffId);
    const servicesListCopy = addedServices;
    servicesListCopy.forEach((el) => {
      el.staffId = staffId;
      el.staffName = staffName;
    });
    setAddedServices([...servicesListCopy]);
  };

  const getStaffList = async () => {
    try {
      const response = await userInstance().get("/staff/getStaff");
      const { staff } = response.data;
      setStaffList(staff);
      const setDefaultStaff = staff.filter(
        (el) => el._id === localStorage.getItem("staff_id"),
      );
      // console.log('staff==>', setDefaultStaff, localStorage.getItem('staff_id'));
      return {
        staffName: `${setDefaultStaff[0].firstname} ${setDefaultStaff[0].lastname}`,
        staffId: setDefaultStaff[0]._id,
      };
    } catch (error) {
      // showErrMsg(error);
      return null;
    }
  };

  const getCategories = async () => {
    try {
      const response = await userInstance().get("/service/getServices");
      const { services } = response.data;
      setCategories(services);
      const staffData = await getStaffList();
      const objToUpdate = {
        ...bookingData,
      };
      if (staffData) {
        objToUpdate.staffName = staffData.staffName;
        objToUpdate.staffId = staffData.staffId;
      }
      setBookingData(objToUpdate);
    } catch (error) {
      // showErrMsg(error);
    }
  };

  const handleBookingData = (e) => {
    const { name, value } = e.target;
    const obj = { ...bookingData, [name]: value };
    if (name === "staffName") {
      const staffId = staffList[e.target.selectedIndex]._id;
      obj[name] = value;
      obj[staffId] = staffId;
      if (
        appointmentType === "oneteam_oneday" ||
        appointmentType === "oneteam_multiday"
      ) {
        setStaff(value, staffId);
      }
    }
    if (name === "cost" && !bookingData.customCost) {
      const newval = addZero(value);
      obj[name] = newval;
    } else if (name === "startTime") {
      obj.timeChange = true;
    } else if (name === "duration") {
      obj.durationChange = true;
    } else if (name === "quantity") {
      obj.quantityChange = true;
    } else if (name === "pricingType") {
      obj.duration = bookingData.previousDuration;
      obj.quantity = "5";
      if (bookingData.customCost !== "default") {
        obj.cost = "";
      }
    } else if (name === "soldPer" && value !== "Add Custom Measure") {
      obj.unit = "";
      obj.symbol = "";
    }
    /// console.log('objobj', obj);
    setBookingData(obj);
  };

  const handleChangeDate = (type, value) => {
    setDatePopupData({ ...datePopupData, [type]: value });
  };

  const handleRepeat = () => {
    setRepeatAppt(!repeatAppt);
    if (repeatAppt) {
      const initialCopy = { ...initialdateBookingData };
      delete initialCopy.date;
      setdateBookingData({ ...initialCopy, date: dateBookingData.date });
      setDatePopupData({ ...initialDatePopup });
    } else {
      updatedateBookingData(true);
    }
  };
  const handleTimePicker = () => {
    setTime(bookingData.startTime);
    setTimePickerShow(!timepickerShow);
  };

  const handleDurationPicker = () => {
    setDuration(bookingData.duration);
    setDurationPicker(!durationPicker);
  };
  const handleHourPicker = () => {
    setHourData(bookingData.quantity);
    setHourPicker(!hourPicker);
  };

  const handleChangeDateData = (e) => {
    console.log("ee", e.target.value);
  };

  const handleChangeCustom = (value) => {
    const obj = { ...bookingData, customCost: value };
    if (value === "default") {
      obj.cost = bookingData.previousCost;
      if (bookingData.type === "product") {
        obj.buyCostProduct = bookingData.prevBuyCostProduct;
      }
    } else if (value === "yes" || value === "sellPrice") {
      obj.cost = "";
      obj.buyCostProduct = obj.prevBuyCostProduct;
    } else if (value === "buyCost") {
      obj.buyCostProduct = "";
      obj.cost = obj.previousCost;
    } else if (value === "buySell") {
      obj.cost = "";
      obj.buyCostProduct = "";
    }
    setBookingData(obj);
  };

  const pad = (str, max) => {
    str = str.toString();
    return str.length < max ? pad(`0${str}`, max) : str;
  };

  const convertHours = (mins) => {
    const hour = Math.floor(mins / 60);
    var mins = mins % 60;
    const converted = `${pad(hour, 2)}:${pad(mins, 2)}`;
    return converted;
  };

  const calculate_time_slot = (start_time, end_time, interval = "30") => {
    var i;
    let formatted_time;
    const time_slots = new Array();
    for (var i = start_time; i <= end_time; i += interval) {
      formatted_time = convertHours(i);
      time_slots.push(formatted_time);
    }
    return time_slots;
  };

  const getHourWithQuantity = (duration, multiplier) => {
    let hourPart = duration.includes("hr")
      ? parseFloat(duration.split("hr")[0].trim())
      : 0;
    let minutePart =
      duration.includes("hr") && duration.includes("min")
        ? parseFloat(duration.split("hr")[1].split("min")[0])
        : duration.includes("min")
          ? parseFloat(duration.split("min")[0])
          : 0;

    let hourWithQuantity = hourPart * multiplier;
    return { hourWithQuantity, hourPart, minutePart };
  };

  const calculateServiceLabour = (bookingDataCopy) => {
    const convertedDuration =
      bookingDataCopy.pricingType === "hourly"
        ? bookingDataCopy.quantity
        : bookingDataCopy.duration;

    let hour = convertToReadableDuration(
      addQuantityDuration(convertedDuration, parseFloat("1")),
    );
    let { hourWithQuantity, minutePart } = getHourWithQuantity(
      hour,
      bookingDataCopy.cost,
    );

    minutePart = (minutePart / 60) * parseFloat(bookingDataCopy.cost);
    let finalDuration = parseFloat(hourWithQuantity + minutePart);

    // eslint-disable-next-line no-mixed-operators
    finalDuration = !Number.isInteger(finalDuration / 60)
      ? Math.ceil(finalDuration / 5) * 5
      : finalDuration;

    return parseFloat(finalDuration).toFixed(2);
  };

  const addQuantityDuration = (duration, quantity) => {
    const updatedDuration = convertToReadableDuration(duration.toString());
    let { hourWithQuantity, hourPart, minutePart } = getHourWithQuantity(
      updatedDuration,
      quantity,
    );

    let multipleMinute = parseFloat(
      `0.${hourWithQuantity.toString().split(".")[1]}`,
    );

    const updatedMinute = hourPart ? multipleMinute * (60 / hourPart) : 0;
    minutePart *= quantity;

    let finalDuration =
      parseFloat(hourWithQuantity.toString().split(".")[0]) * 60 +
      updatedMinute +
      minutePart;
    finalDuration = !Number.isInteger(finalDuration / 60)
      ? Math.ceil(finalDuration / 5) * 5
      : finalDuration;
    return finalDuration.toString();
  };

  const calculateMarginLabour = (bookingDataCopy) => {
    const { cost, buyCostProduct, quantity, type, renderCost } =
      bookingDataCopy;
    let totalBuy = 0;
    let totalSellPrice = 0;
    let laborMargin = 0;
    let totalLabour = 0;

    if (type === "product") {
      totalBuy += parseFloat(buyCostProduct);
      totalSellPrice += parseFloat(renderCost);
      // console.log('totalSellPrice', totalSellPrice, 'total buy', totalBuy);
      const totalLabourProduct = (
        totalSellPrice -
        totalBuy * parseFloat(quantity)
      ).toFixed(2);
      laborMargin = totalLabourProduct.toString();
    }
    if (type === "service") {
      totalLabour =
        bookingDataCopy.pricingType === "hourly"
          ? calculateServiceLabour(bookingDataCopy)
          : cost;
    }
    return { totalLabour, laborMargin };
  };
  const renderCostCalculation = (data) => {
    let quot = data / 5;
    console.log("quot", quot);
    let str = quot.toString();
    let splitData = str.split(".");
    console.log("splitData", splitData);
    let value;
    if (splitData.length > 1 && splitData[1] !== "" && splitData[1] !== "0") {
      value = parseFloat(splitData[0]) + 1;
    } else {
      value = quot;
    }
    let result = value * 5;

    // console.log('ddddddddddd', result);
    return result;
  };

  // const validateService = () => {
  //   let isValid = true;
  //   let msg = '';
  //   let errValue = '';
  //   const {
  //     category, subcategory, staffName, type, customMisc, quantity,
  //   } = bookingData;
  //   let { cost, buyCostProduct } = bookingData;

  //   cost = cost.replace(/,/g, '').replace('$', '');
  //   buyCostProduct = buyCostProduct.replace(/,/g, '').replace('$', '');
  //   // console.log('cost', cost, 'buy cost product', buyCostProduct);

  //   if (!category) {
  //     isValid = false;
  //     msg = 'Please select category';
  //     errValue = 'category';
  //   } else if (!subcategory) {
  //     isValid = false;
  //     msg = 'Please select subcategory';
  //     errValue = 'subcategory';
  //   } else if (!staffName) {
  //     isValid = false;
  //     msg = 'Please select staff';
  //     errValue = 'staffName';
  //   } else if (type === 'product' && parseFloat(cost) < parseFloat(buyCostProduct)) {
  //     isValid = false;
  //     msg = 'Sell price must be greater than buy cost';
  //     errValue = 'sell price';
  //   } else if (!cost) {
  //     isValid = false;
  //     msg = 'Enter Sell Price (ex tax)';
  //     errValue = 'sell price';
  //   } else if (!buyCostProduct && type === 'product') {
  //     isValid = false;
  //     msg = 'Enter Buy Price (ex tax)';
  //     errValue = 'buyCostProduct';
  //   } else if (bookingData.category.toLowerCase().includes('misc') && !customMisc) {
  //     isValid = false;
  //     msg = 'Enter New Name';
  //     errValue = 'customMisc';
  //   } else if (!quantity) {
  //     isValid = false;
  //     msg = 'Enter quantity';
  //     errValue = 'quantity';
  //   }
  //   return { isValid, msg, errValue };
  // };
  const validateService = () => {
    let isValid = true;
    let errValue = "";
    let msg = "";
    const { category, subcategory, staffName, type, customMisc, quantity } =
      bookingData;
    let { cost, buyCostProduct } = bookingData;
    cost = cost.replace(/,/g, "").replace("$", "");
    buyCostProduct = buyCostProduct.replace(/,/g, "").replace("$", "");

    if (!category) {
      isValid = false;
      errValue = "category";
      msg = "Please select service or product";
    } else if (!subcategory) {
      isValid = false;
      errValue = "subcategory";
      msg = "Please select subcategory";
    } else if (
      bookingData.category.toLowerCase().includes("misc") &&
      !customMisc
    ) {
      isValid = false;
      errValue = "customMisc";
      msg = "Enter New Name";
    } else if (!quantity) {
      isValid = false;
      errValue = "quantity";
      msg = "Enter quantity";
    }
    return { isValid, msg, errValue };
  };

  function groupBy(collection, property) {
    let i = 0;
    let val;
    let index;
    let values = [];
    let result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  const renderOptions = () => {
    let val = 5;
    const arr = [];
    while (val <= 960) {
      const hours = Math.floor(val / 60);
      const minutes = val % 60;
      const msg =
        hours && minutes
          ? `${hours}hr ${minutes} min`
          : hours && !minutes
            ? `${hours}hr`
            : `${minutes} min`;
      arr.push({ value: val, data: msg });
      val += 5;
    }
    return arr;
  };

  useEffect(() => {
    getCategories();
  }, [dateBookingData]);

  const calculateSummary = (finalServices) => {
    let totalCost = 0;
    let totalDuration = 0;
    let totalLabourAmount = 0;
    let totalMargin = 0;
    finalServices.forEach((el) => {
      totalDuration += parseFloat(el.duration);
      totalCost += parseFloat(el.renderCost);
      totalLabourAmount += parseFloat(el.totalLabour);
      totalMargin += parseFloat(el.laborMargin);
    });
    let totalLabour = totalLabourAmount + totalMargin;
    setTotalData({
      ...totalData,
      totalCost: addZero(parseFloat(totalCost).toFixed(2)),
      totalDuration,
      totalLabour: addZero(parseFloat(totalLabour).toFixed(2)),
      multiTotal: totalCost,
      totalMargin: addZero(parseFloat(totalMargin).toFixed(2)),
      totalLabourOnly: addZero(parseFloat(totalLabourAmount).toFixed(2)),
    });
  };

  const calculateAmount = () => {
    let allTotalMargin = 0;
    if (chemicalalist && chemicalalist.length > 0) {
      let replaceCommatotalLabour = totalData.totalLabour.replace(",", "");
      allTotalMargin =
        parseFloat(replaceCommatotalLabour) +
        parseFloat(chemicalalist[0].labourMargin);
    }
    return { allTotalMargin };
  };
  useEffect(() => {
    calculateAmount();
  }, []);

  const handleEditService = (services, index) => {
    setStaffIndex(index);
    if (index === 0) {
      setnewlength(0);
    } else {
      setnewlength(addedServices.length);
    }
    const updateObj = { ...bookingData, ...services };
    setBookingData(updateObj);
    setShowService(!showService);
    setEditData({ edit: true, index, type: "mark" });
    if (parseFloat(services.duration) > 960) {
      setExtras({
        ...extras,
        value: services.duration,
        data: convertToReadableDuration(services.duration),
      });
    }
    const subcategory = services.subcategory.replace(
      services.subcategory.split("$")[1],
      services.renderCost.split(".")[1] === "00"
        ? addZero(services.renderCost)
        : addZero(services.cost),
    );
    setSearchTxt(subcategory);
  };

  const handleDeleteService = () => {
    const currentServices = [...addedServices];
    currentServices.splice(editData.index, 1);
    if (editData.index === addedServices.length - 1) {
      setBookingData({
        ...bookingData,
        startTime: addedServices[editData.index].startTime,
      });
      setAddedServices([...currentServices]);
    } else {
      recalculateServices(addedServices[editData.index]);
    }
    appointmnetData[0].service = currentServices;
    calculateSummary([...currentServices]);
    setShowService(!showService);
    setSaveUpdateStatus(true);
  };

  const recalculateServices = (data) => {
    const remainingServices = addedServices.filter(
      (_el, i) => i > editData.index,
    );
    const previousServices = addedServices.filter(
      (_el, i) => i < editData.index,
    );
    for (let i = 0; i < remainingServices.length; i++) {
      const currentEl = remainingServices[i];
      let end = "";
      if (i === 0) {
        end = new Date(data.start);
      } else {
        end = new Date(remainingServices[i - 1].end);
      }
      const startTime = moment(end).format(timeFromDateFormat);
      currentEl.startTime = startTime;
      currentEl.start = moment(end).format(dateFormat);
      currentEl.end = moment(
        new Date(
          end.setMinutes(end.getMinutes() + parseFloat(currentEl.duration)),
        ),
      ).format(dateFormat);
    }
    const arr = previousServices;
    const newArray = arr.concat(remainingServices);
    setAddedServices([...newArray]);
  };

  const handleSearchCategory = async (searchTxt) => {
    console.log(searchTxt);
    try {
      setSearchTxt(searchTxt);
      if (searchTxt) {
        const response = await userInstance().get(
          `/service/searchCategory?value=${searchTxt.replace("$", "").trim()}&industryId=${industryId._id}`,
        );
        const { services } = response.data;
        setCategories(services);
        if (services.length) {
          const subCat = [];
          services.forEach((ser) => {
            const reg = new RegExp(`${searchTxt.replace("$", "").trim()}`, "i");
            const matchedSubcategories = ser.subCategory.filter(
              (el) =>
                reg.test(el.cost) ||
                reg.test(el.name) ||
                reg.test(el.retailCost) ||
                reg.test(el.serviceDescription),
            );
            subCat.push(...matchedSubcategories);
          });
          if (subCat.length) {
            setSubcategories([...subCat]);
          } else {
            setSubcategories([]);
          }
        } else {
          setSubcategories([]);
        }
      }
    } catch (error) {
      console.log(error);
      // showErrMsg(error);
    }
  };

  const handleAddService = async () => {
    const {
      subcategory,
      type,
      duration,
      previousDuration,
      previousQuantity,
      pricingType,
      customMisc,
      description,
    } = bookingData;
    let { cost, buyCostProduct, renderCost, quantity, startTime } = bookingData;
    let oldStateCopy = [...newAppointment];
    quantity = quantity.replace(/,/g, "");
    cost = parseFloat(cost.replace(/,/g, "").replace("$", "")).toFixed(2);
    buyCostProduct = parseFloat(
      buyCostProduct.replace(/,/g, "").replace("$", ""),
    ).toFixed(2);
    renderCost =
      type === "service" && pricingType === "hourly"
        ? (parseFloat(cost) * parseFloat(quantity)) / 60
        : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);
    if (type === "product") {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = (renderCost / quantity).toFixed(2).toString();
    }
    if (type === "service" || pricingType === "hourly") {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
    }
    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      // let startTime = setDefaultTime; // changes by sanjana '9:30';
      let start = convertDateTime(dateBookingData.date);
      let time = bookingData.startTime.split(":");
      const bookingDataCopy = { ...bookingData };
      const lastAppServ = addedServices.length;
      const lastServiceArr = addedServices[lastAppServ - 1];
      const lastService = lastServiceArr;
      // const lastService = addedServices[0];
      if (lastService) {
        start = convertDateTime(lastService.end);
        time = start.format(timeFromDateFormat).split(":");
      }
      start.hour(time[0]);
      start.minute(time[1]);
      start.second(0);
      bookingDataCopy.cost = cost;
      bookingDataCopy.quantity = quantity;
      bookingDataCopy.renderCost = renderCost;
      bookingDataCopy.description = customMisc || description;

      if (bookingDataCopy.buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }
      const { laborMargin, totalLabour } =
        calculateMarginLabour(bookingDataCopy);
      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabour;

      let durationInMin = duration;
      if (type === "service") {
        durationInMin =
          parseFloat(quantity) > 1 || pricingType === "hourly"
            ? quantity
            : duration;
      }
      if (!previousDuration) {
        bookingDataCopy.previousDuration = durationInMin;
      }

      bookingDataCopy.duration = durationInMin;
      bookingDataCopy.start = start.format(dateFormat);
      startTime = start.format(timeFromDateFormat);
      bookingDataCopy.end = updateEndTime(start, bookingDataCopy.duration);

      bookingDataCopy.startTime = startTime;

      // // bookingDataCopy.duration = durationInMin;
      // // bookingDataCopy.start = moment(start).format(dateFormat);
      // // bookingDataCopy.end = moment(
      // //   new Date(start.setMinutes(start.getMinutes() + parseFloat(bookingDataCopy.duration))),
      // // ).format(dateFormat);
      // startTime = moment(bookingDataCopy.start).format(timeFromDateFormat);
      bookingDataCopy.startTime = startTime;
      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split("$")[1],
        renderCost.split(".")[1] === "00"
          ? addZero(renderCost)
          : addZero(renderCost),
      );
      bookingDataCopy.timeChange = false;
      if (!previousDuration) {
        bookingDataCopy.previousDuration = durationInMin;
      }
      if (!previousQuantity) {
        bookingDataCopy.previousQuantity = quantity;
      }

      if (!editData.edit) {
        finalServices = [...addedServices, bookingDataCopy];
      }
      handleShowService();
      calculateSummary([...finalServices]);
      setAddedServices(finalServices);
      appointmnetData[0].service = finalServices;
      setSaveUpdateStatus(true);
    } else {
      toastMessage("error", msg);
      setErrorValue(errValue);
    }
    setTimeout(() => {
      setErrorValue("");
    }, 1000);
  };
  const calculateNewDurations = (data) => {
    let multiArray = [];
    multiArray = data;
    for (let i = 0; i < multiArray.length; i++) {
      const currentEl = multiArray[i];
      let end = "";
      if (i === 0) {
        end = data.end;
      } else {
        end = multiArray[i - 1].end;
        const endDate = convertDateTime(end);
        const startTime = endDate.format(timeFromDateFormat);
        currentEl.startTime = startTime;
        currentEl.start = endDate.format(dateFormat);
        currentEl.end = updateEndTime(endDate, currentEl.duration);
      }
    }
    // console.log('multiArray ===>', multiArray);
  };
  const editService = () => {
    const {
      start,
      startTime,
      subcategory,
      type,
      previousDuration,
      previousQuantity,
      duration,
      pricingType,
    } = bookingData;
    let { cost, buyCostProduct, renderCost, quantity } = bookingData;
    console.log("booking data for edit", bookingData);
    quantity = quantity.replace(/,/g, "");
    cost = parseFloat(cost.replace(/,/g, "").replace("$", "")).toFixed(2);
    buyCostProduct = parseFloat(
      buyCostProduct.replace(/,/g, "").replace("$", ""),
    ).toFixed(2);
    renderCost =
      type === "service" && pricingType === "hourly"
        ? (parseFloat(cost) * parseFloat(quantity)) / 60
        : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);
    if (type === "product") {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = (renderCost / quantity).toFixed(2).toString();
    }
    if (type === "service" || pricingType === "hourly") {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
    }

    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      const bookingDataCopy = { ...bookingData };
      const startDate = convertDateTime(start);
      const time = startTime.split(":");

      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split("$")[1],
        renderCost.split(".")[1] === "00"
          ? addZero(renderCost)
          : addZero(renderCost),
      );
      bookingDataCopy.cost = cost;
      bookingDataCopy.renderCost = renderCost;
      bookingDataCopy.quantity = quantity;
      if (buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }
      startDate.hour(time[0]);
      startDate.minute(time[1]);
      startDate.second(0);

      bookingDataCopy.start = startDate.format(dateFormat);

      const { laborMargin, totalLabour } =
        calculateMarginLabour(bookingDataCopy);
      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabour;
      let durationInMin = 0;
      if (type === "service") {
        durationInMin =
          parseFloat(quantity) > 1 || pricingType === "hourly"
            ? quantity
            : duration;
        bookingDataCopy.duration = durationInMin;
      } else if (
        previousQuantity !== quantity ||
        previousDuration !== duration
      ) {
        bookingDataCopy.previousDuration = durationInMin;
        bookingDataCopy.previousQuantity = bookingDataCopy.quantity;
      }
      bookingDataCopy.end = updateEndTime(startDate, bookingDataCopy.duration);

      const currentServices = [...addedServices];
      currentServices[editData.index] = bookingDataCopy;
      finalServices = currentServices;
      calculateNewDurations(finalServices);
      appointmnetData[0].service = finalServices;
      calculateSummary([...finalServices]);
      setAddedServices(finalServices);
      setShowService(!showService);
      setSaveUpdateStatus(true);
    } else {
      toastMessage("error", msg);
      setErrorValue(errValue);
    }
    setTimeout(() => {
      setErrorValue("");
    }, 1000);
  };
  const getAddress = () => {
    if (currentUserStaff?.streetAddress) {
      let { streetAddress, suburb, state: state_ } = currentUserStaff;
      return makingAddress({ streetAddress, suburb, state: state_ });
    }
    return "";
  };

  const addSubcategoryToService = (value) => {
    if (value.toLowerCase().includes("misc")) {
      setMisc(true);
    }
    setBookingData({
      ...bookingData,
      category: value,
      subcategory: value,
    });
    setSearchTxt(value);
    setSubcategories(null);
  };
  const CrossDate = () => {
    setShowDate(!showDate);
  };
  const gotoCalendar = () => {
    if (clientIdentity) {
      history.push(`${getCalendarPath()}`);
    }
  };
  const gotoPreviousPage = () => {
    // console.log('clientId', state);
    if (clientIdentity) {
      history.push({
        pathname: "/calendar",
        state: {
          existCheckListArray: checklistArray,
          existImage: image,
          existClientId: clientIdentity,
          existingSummary: existSummary,
        },
      });
      // history.push(`${getCalendarPath()}&clientid=${clientIdentity}`);
      // localStorage.setItem('finaliseVistClientId', clientIdentity);
    }
  };
  const handlePreviewConfirm = () => {
    setConfirmPreview(true);
  };
  const [addappt, setAddAppt] = useState("defaultTemp");

  const [oldData, setOldData] = useState();

  useEffect(() => {
    const copy = state.dateBookingData.map((el) => ({ ...el }));
    setOldData(copy);
  }, []);
  const updatedAppointment = useMemo(
    () => mutateAppointmentServices(appointmnetData, timeZone, dbTimeZone),
    [appointmnetData],
  );
  console.log("updatedAppointment", updatedAppointment);
  const saveUpdateData = async () => {
    if (addedServices?.length === 0) {
      return toastMessage("error", "Please add maintenance required.");
    }
    setIsloader(true);
    const appData = {
      id: state.id,
      totalData,
      service: addedServices,
      from: "finalize",
      oldObjectData: oldData,
      industryId: industryId._id,
    };
    let activeEventDate = moment.utc(state?.eventDate).format("YYYY-MM-DD");
    let { interval_type } = appointmnetData ? appointmnetData[0] : {};
    const { repeats } = state || {};
    const rpIndex = parseInt(localStorage.getItem("activeIndex"), 10);
    let uId = "";

    if (localStorage.getItem("activeIndex") !== "-1") {
      uId = repeats[rpIndex] ? repeats[rpIndex].uid : "";
    } else {
      uId = repeats[0]?.uid;
    }
    let copyappointmnetData = [...appointmnetData];
    const updatedAppointmentNew =
      timeZone === dbTimeZone
        ? copyappointmnetData
        : mutateAppointmentServices(copyappointmnetData, dbTimeZone, "");
    // if (updatedAppointmentNew) return console.log('updatedAppointment>>>>>>>>>>>>>>>>', updatedAppointmentNew, addedServices);

    updatedAppointmentNew[0].summary = totalData;
    const payload = {
      id: state?.id,
      selectedSaff: localStorage.getItem("staff_id"),
      status: "",
      amount: 0,
      chemicalsAdded: !!chemicalalist,
      gst: state.gst,
      invoiceTotal: invoiceTotal.toFixed(2),
      invoiceSubtotal: invoiceSubTotal.toFixed(2),
      addedServices,
      dateData: updatedAppointmentNew,
      totalData,
      eventDate: new Date(activeEventDate).toISOString(),
      interval_type,
      chemicalsArray: state?.chemicalalist,
      uid: uId || "",
      checklistArray,
      selectedSaffId: localStorage.getItem("staff_id"),
      pid: state?.pid,
      updateServiceData: appData,
    };

    // console.log('state =================>', state);

    const response = await userInstance().post("/payment/savePayment", payload);
    const { code, Id } = response.data;
    if (code === 200) {
      const formdata = new FormData();
      state?.image.forEach((el) => {
        formdata.append("file", el.data);
      });
      formdata.append("caption", JSON.stringify(state?.image));
      formdata.append("id", JSON.stringify(Id));
      await userInstance().post("/payment/saveAppointmentImages", formdata);
      toastMessage("success", "Pool maintenance completed successfully.");
      setSaveUpdateStatus(false);
    } else {
      toastMessage("success", "Pool maintenance completed successfully.");
    }
    setIsloader(false);
    return history.push(getCalendarPath2(state.eventDate));
  };

  const handleWaterUpdate = async () => {
    try {
      if (appId) {
        let mainId = pid || appId;
        console.log({ pid, appId });
        const response = await userInstance().get(
          `/watertest/getWaterHistoryByAppointmentId?id=${mainId}&pid=${pid}&appId=${appId}`,
        );
        console.log("response", response);
        const { waterList, groupAppointments, msg } = response.data;
        const copyClientAllData = { ...clientAllData };
        copyClientAllData.waterTestHistory = waterList?.history;
        copyClientAllData.groupAppointments = groupAppointments;
        setClientAllData(copyClientAllData);
        localStorage.setItem("apptData", JSON.stringify(copyClientAllData));
        console.log("state", state.id, appId, clientIdentity);
        const typeSer = waterList?.history?.serviceAnalysis === "Water Test";

        const calState = {
          id: state.id,
          chemicalsAdded: !!chemicalalist,
          gst: gst.toFixed(2),
          invoiceTotal: invoiceTotal.toFixed(2),
          invoiceSubtotal: invoiceSubTotal.toFixed(2),
          addedServices,
          appointmnetData,
          dateBookingData,
          repeats: state.repeats,
          summary: totalData,
          eventDate: state.eventDate,
          chemicalsArray: chemicalalist,
          checklistArray: state.checklistArray,
          image: state.image,
          clientIdentity,
          editChemicals: typeSer,
          waterId: waterList?._id,
          visitMessage: "true",
          comeFrom: "finalise",
          pid,
        };

        if (typeSer) {
          delete calState.comeFrom;
        }

        if (
          localStorage.getItem("s#@4f11") &&
          localStorage.getItem("f#@5e11")
        ) {
          toastMessage("error", "sljflsjfkds");
          socket.emit("editFromFinalize", {
            calState,
            clientAllData: copyClientAllData,
            userId: userInformation?._id,
          });
          return;
        }

        history.push({
          pathname: "/calculator",
          state: calState,
        });
      }
      return;
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <AdminWrapper>
      {!awating && (
        <div className="appointment-container finalize-cost-page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="back-option">
                  <span>
                    {/* <img src={arrow} alt="back-btn" />
                    Back */}
                  </span>
                  <span onClick={() => gotoPreviousPage()}>
                    <i className="fa fa-times" aria-hidden="true" />
                  </span>
                </div>
                <div className="main-titles">
                  <h3>Finalise Maintenance Detail</h3>
                </div>
                <div className="add-appointment appointment-details">
                  <div className="row">
                    <div className="col-md-4" />
                    <div className="col-md-8">
                      <div className="appointment-view">
                        {appointmnetData &&
                          appointmnetData.map((el, k) => (
                            <>
                              <div
                                className="appointment-date"
                                role="presentation"
                              >
                                {/* <div className="appointment-date" role="presentation" onClick={() => handleDateModal('', k)}> */}
                                <div className="">
                                  {dateBookingData && (
                                    <h3>
                                      {`${moment.utc(dateBookingData[0].date).format("dddd")}, ${moment
                                        .utc(dateBookingData[0].date)
                                        .format("D MMMM YYYY")}`}
                                    </h3>
                                  )}
                                  <div className="client-details main-clients-details">
                                    <div className="client-left-details">
                                      <h6>
                                        {currentUserStaff?.firstname}{" "}
                                        {currentUserStaff?.lastname}{" "}
                                      </h6>
                                      <p>{getAddress()}</p>
                                      <p>
                                        <a
                                          className="mobile"
                                          href={`tel:${currentUserStaff?.mobileNo || ""}`}
                                        >
                                          {`${currentUserStaff?.mobileNo || ""}`}
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div className="sorting-sub-services appointment-sort">
                                  <div className="content-dropdown">
                                    {/* <img src={arrow} alt="arrow" /> */}
                                  </div>
                                </div>
                              </div>
                              <span className="sep" />
                              <div className="search-title service-head">
                                <h3> Maintenance Required</h3>
                                <div className="sorting-services">
                                  {el.service.length > 1 ? (
                                    <div className="sorting">
                                      <img
                                        src={sort}
                                        alt="sorting"
                                        onClick={() =>
                                          setRenderSort(!renderSort)
                                        }
                                      />
                                    </div>
                                  ) : null}
                                  <span
                                    className="plus-icon"
                                    onClick={() => handleShowService(0)}
                                    role="presentation"
                                  >
                                    <i className="fas fa-plus" />
                                  </span>
                                </div>
                              </div>
                              {el.service &&
                                el.service.map((ser, i) => (
                                  <div className="search-title">
                                    <div
                                      className="client-profile"
                                      onClick={() => handleEditService(ser, i)}
                                    >
                                      <h3>
                                        {`${
                                          ser.category
                                            .toLowerCase()
                                            .includes("misc")
                                            ? `${ser.customMisc}`
                                            : ser.subcategory
                                        }`}
                                      </h3>

                                      <p>
                                        <span>
                                          {displayTime(
                                            timeZone,
                                            ser.start,
                                            "h:mm a",
                                          )}
                                          {/* {ser.startTime} */}
                                          {/* {moment(ser.startTime, [timeFromDateFormat]).format('h:mm a')} */}
                                        </span>

                                        <span> | </span>
                                        <span>{` ${convertToReadableDuration(ser.duration)}`}</span>
                                        <span> | </span>
                                        <span>{` with ${ser.staffName}`}</span>
                                      </p>
                                    </div>
                                    <div className="sorting-sub-services appointment-sort">
                                      <div className="service-edit-icon">
                                        {renderSort ? (
                                          <div
                                            className={`sorting up-down ${renderSort && "active-sort"}`}
                                          >
                                            {i !== 0 ? (
                                              <img
                                                src={upsort}
                                                alt="sorting"
                                                onClick={() =>
                                                  moveArr(i, i - 1, "minus")
                                                }
                                              />
                                            ) : null}
                                            {i !== el.service.length - 1 ? (
                                              <img
                                                src={downsort}
                                                alt="sorting"
                                                onClick={() =>
                                                  moveArr(i, i + 1, "plus")
                                                }
                                              />
                                            ) : null}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div
                                        className="content-dropdown"
                                        onClick={() =>
                                          handleEditService(ser, i)
                                        }
                                      >
                                        <Dropdown name="startingDate">
                                          <Dropdown.Toggle
                                            variant="link"
                                            id="dropdown-basic"
                                          >
                                            <img src={arrow} alt="arrow" />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item>
                                              Option 1
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                              Option 2
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </>
                          ))}

                        <span className="sep" />
                        {chemicalalist &&
                          chemicalsAdded &&
                          chemicalalist?.length > 0 && (
                            <div className="added-chemicals-info">
                              <h6 onClick={handleWaterUpdate}>
                                CHEMICALS REQUIRED
                              </h6>
                              {chemicalalist[0]?.testResult[0]?.service.map(
                                (chem, index) => {
                                  invoiceSubTotal +=
                                    parseFloat(chem.cost) > 0 &&
                                    chem.type === "product" &&
                                    chem.status === "quantity"
                                      ? parseFloat(chem.renderCost)
                                      : 0;
                                  return (
                                    <>
                                      {(parseFloat(chem.cost) > 0 ||
                                        chem.cost === "N/A") &&
                                      chem.type === "product" &&
                                      chem.status === "quantity" ? (
                                        <div className="chemical-description">
                                          <div className="chemical-name">
                                            - {chem.subcategory}
                                          </div>
                                          <div className="chemical-rate">
                                            {displayDollar(chem.renderCost)}
                                            <span>
                                              {displayCacliPrices(
                                                chem.renderCost,
                                                "",
                                              )}
                                            </span>
                                          </div>
                                        </div>
                                      ) : null}
                                    </>
                                  );
                                },
                              )}
                            </div>
                          )}

                        <div className="visit-message">
                          <h6>{visitMessage || ""}</h6>
                        </div>
                        <div className="footer-btn view-btn single-btn">
                          <Button
                            className="appointment-btn btn-save"
                            onClick={saveUpdateData}
                          >
                            {isloader ? (
                              <Spinner animation="border" role="status">
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </Spinner>
                            ) : (
                              "Save/Update"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {dateBookingData && (
                    <DateModal
                      showDate={showDate}
                      setShowDate={setShowDate}
                      handleDateModal={handleDateModal}
                      handleBookingData={handleBookingData}
                      bookingData={bookingData}
                      setBookingData={setBookingData}
                      dateData={dateBookingData}
                      handleChangeDateData={handleChangeDateData}
                      setDateData={setdateBookingData}
                      repeatAppt={repeatAppt}
                      setRepeatAppt={setRepeatAppt}
                      handleFrequency={handleFrequency}
                      repeatFrequency={repeatFrequency}
                      setRepeatFrequency={setRepeatFrequency}
                      handleChangeDate={handleChangeDate}
                      datePopupData={datePopupData}
                      timepickerRef={timepickerRef}
                      handleSaveDate={handleSaveDate}
                      handleRepeat={handleRepeat}
                      appointmentType={appointmentType}
                      appointmentIndex={appointmentIndex}
                      CrossDate={CrossDate}
                    />
                  )}

                  <ServiceModal
                    showService={showService}
                    setShowService={setShowService}
                    handleShowService={handleShowService}
                    newLength={newLength}
                    categories={categories}
                    subcategories={subcategories}
                    handleBookingData={handleBookingData}
                    getTimes={getTimes}
                    bookingData={bookingData}
                    renderOptions={renderOptions}
                    staffList={staffList}
                    handleAddService={handleAddService}
                    editData={editData}
                    handleDeleteService={handleDeleteService}
                    handleSearchCategory={handleSearchCategory}
                    setSubcategories={setSubcategories}
                    addSubcategoryToService={addSubcategoryToService}
                    editService={editService}
                    setBookingData={setBookingData}
                    staffIndex={staffIndex}
                    misc={misc}
                    addedServices={addedServices}
                    servicesLength={addedServices.length}
                    extras={extras}
                    handleChangeCustom={handleChangeCustom}
                    timepickerShow={timepickerShow}
                    handleTimePicker={handleTimePicker}
                    subcategoryTxt={subcategoryTxt}
                    setSearchTxt={setSearchTxt}
                    durationPicker={durationPicker}
                    setDurationPicker={setDurationPicker}
                    convertToReadableDuration={convertToReadableDuration}
                    handleDurationPicker={handleDurationPicker}
                    setnewlength={setnewlength}
                    setHourPicker={setHourPicker}
                    hourPicker={hourPicker}
                    handleHourPicker={handleHourPicker}
                    appointmentType={appointmentType}
                    error={errorValue}
                  />
                  {timepickerShow ? (
                    <TimePicker
                      timepickerShow={timepickerShow}
                      handleTimePicker={handleTimePicker}
                      bookingData={bookingData}
                      setBookingData={setBookingData}
                      timeData={timeData}
                      setTime={setTime}
                    />
                  ) : null}
                  {durationPicker ? (
                    <DurationPicker
                      durationPicker={durationPicker}
                      setDurationPicker={setDurationPicker}
                      durationData={durationData}
                      handleDurationPicker={handleDurationPicker}
                      setDuration={setDuration}
                      setBookingData={setBookingData}
                      convertToReadableDuration={convertToReadableDuration}
                      bookingData={bookingData}
                      subcategory={null}
                      setSubCategory={null}
                    />
                  ) : null}
                  {hourPicker ? (
                    <HourPicker
                      setHourPicker={setHourPicker}
                      handleHourPicker={handleHourPicker}
                      hourPicker={hourPicker}
                      hourData={hourData}
                      handleDurationPicker={handleDurationPicker}
                      setHourData={setHourData}
                      setBookingData={setBookingData}
                      convertToReadableDuration={convertToReadableDuration}
                      bookingData={bookingData}
                      subcategory={null}
                      setSubCategory={null}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {awating && <Awaitingapprovals />}
      {showInvoice && (
        <PreviewInvoicePopup2
          // handleInvoicePopup={handleInvoicePopup}
          showInvoice={showInvoice}
          invoiceData={invoiceDetail}
          chemicalsArray={chemicalalist}
          previewUrl={previewUrl}
          handleClose={handleClose}
          specificDropDown={specificDropDown}
          handleSpecificInvoice={handleSpecificInvoice}
          addappt={addappt}
          setAddAppt={setAddAppt}
          setspecificDropDown={setspecificDropDown}
        />
      )}
      {confirmPreview && (
        <PreviewInvoiceConfirm
          confirmPreview={confirmPreview}
          setConfirmPreview={setConfirmPreview}
          addappt={addappt}
          setAddAppt={setAddAppt}
        />
      )}
    </AdminWrapper>
  );
};
export default FinalizeVisitcost;
