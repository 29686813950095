import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";

const PreviewInvoiceConfirm = ({
  setConfirmPreview,
  handleSelect,
  addappt,
  setAddAppt,
}) => {
  const history = useHistory();
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className === "duration-picker time-picker-modal"
          ) {
            setConfirmPreview(false);
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const handleChange = (value) => {
    setAddAppt(value);
  };
  const handleCancel = () => {
    setConfirmPreview(false);
  };
  return (
    <div className="duration-picker time-picker-modal">
      <div>
        <div
          className="time-keeper-popup duration-popup event-popup"
          ref={wrapperRef}
        >
          <div className="appointment-view">
            <div
              className="pending-water-test"
              onClick={() => handleChange("defaultTemp")}
            >
              <h3>Use Default Invoice Template</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    value="defaultTemp"
                    onChange={() => handleChange("defaultTemp")}
                    checked={addappt === "defaultTemp"}
                  />
                </div>
              </div>
            </div>
            <div
              className="pending-water-test"
              onClick={() => handleChange("specificTemp")}
            >
              <h3>Select Specific Template</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    value="specificTemp"
                    onChange={() => handleChange("specificTemp")}
                    checked={addappt === "specificTemp"}
                  />
                </div>
              </div>
            </div>
            <div className="event-popup-btn footer-btn view-btn">
              <Button
                className="appointment-btn btn-edit"
                type="button"
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="appointment-btn btn-save"
                onClick={() => handleSelect()}
              >
                Select
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
PreviewInvoiceConfirm.propTypes = {
  setConfirmPreview: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  addappt: PropTypes.func.isRequired,
  setAddAppt: PropTypes.func.isRequired,
};
export default PreviewInvoiceConfirm;
