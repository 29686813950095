// Settings Tabs
export const GENERAL_TAB = "GENERAL";
export const DATA_MANAGEMENT_TAB = "DATA_MANAGEMENT";

// Settings Options For Data Management
export const PUBLIC_SCOPE = "public";
export const MARKETING_SCOPE = "marketing";
export const WEB_APP_SCOPE = "webapp";

export const ModelList = [
  {
    id: "chatgpt-4o-latest",
    label: "ChatGPT-4o Latest",
    value: "chatgpt-4o-latest",
  },
  {
    id: "gpt-4o-2024-11-20",
    label: "GPT-4o 2024-11-20",
    value: "gpt-4o-2024-11-20",
  },
  {
    id: "gpt-4o-2024-05-13",
    label: "GPT-4o 2024-05-13",
    value: "gpt-4o-2024-05-13",
  },
  {
    id: "gpt-4o-mini",
    label: "GPT-4o Mini",
    value: "gpt-4o-mini",
  },
  {
    id: "gpt-4o-mini-2024-07-18",
    label: "GPT-4o Mini 2024-07-18",
    value: "gpt-4o-mini-2024-07-18",
  },
  {
    id: "gpt-4o-2024-08-06",
    label: "GPT-4o 2024-08-06",
    value: "gpt-4o-2024-08-06",
  },
  {
    id: "gpt-4o",
    label: "GPT-4o",
    value: "gpt-4o",
  },
];
export const DefaultGeneralSettings = {
  name: "HelpBot",
  avatar: "",
  openAIKey: "",
  aiSettings: [
    {
      aiModel: "gpt-4o",
      greeting: "Hi there! How are you today?",
      systemPrompt: "You are a marketing bot.",
      scope: "marketing",
    },
    {
      aiModel: "gpt-4o",
      greeting: "Hi there! How are you today?",
      systemPrompt: "You are a chatbot for web app.",
      scope: "webapp",
    },
  ],
};
