import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { Form, Button, Spinner } from "react-bootstrap";
import moment from "../../functions/moment";
import { userInstance, notTimeOutUserInstance } from "../../config/axios";
import { AuthDataContext } from "../../contexts/AuthDataContext";
import toastMessage from "../../functions/toastMessage";

const Cancel = ({
  closeCancel,
  setCancel,
  getAppointments,
  setRandom,
  appointmentData,
  eventDate,
  SelectedStaff,
  activeStaff,
  handleAppointmentDetails,
  groupAppointments,
}) => {
  let { dateData } = appointmentData || {};
  let { endType } = dateData ? dateData[0] : {};
  // console.log('appointmentData endType ===>', endType);
  const [oneAppointment, setOneAppoitment] = useState(true);
  const [allAppointment, setAllAppoitment] = useState(false);
  const [notifyCustomer, setNotifyCustomer] = useState(false);
  const [isloader, setisloader] = useState(false);

  const {
    userData: { industryId },
  } = useContext(AuthDataContext);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className === "duration-picker time-picker-modal"
          ) {
            setCancel(false);
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const HandleCancelType = (value, type) => {
    if (type === "only") {
      setOneAppoitment(value);
      setAllAppoitment(false);
    }
    if (type === "future") {
      setAllAppoitment(value);
      setOneAppoitment(false);
    }
  };
  const HandleCancelType1 = (value, type) => {
    if (type === "only") {
      setOneAppoitment(value);
      setAllAppoitment(false);
    }
    if (type === "future") {
      setAllAppoitment(value);
      setOneAppoitment(false);
    }
  };

  const handleCancel = async () => {
    try {
      const currentDate = new Date();
      if (oneAppointment === true) {
        const appointmentDate = eventDate;

        if (currentDate > appointmentDate) {
          toastMessage(
            "error",
            "Pool Maintenance entries from the past can’t be deleted, please reschedule.",
          );
          return;
        }
      }
      setisloader(true);

      let activeEventDate = moment(eventDate).format("YYYY-MM-DD");
      let { interval_type } = appointmentData
        ? appointmentData.dateData[0]
        : {};
      let { staffId } = activeStaff || {};
      const allService = appointmentData?.dateData[0]?.service;
      const repeatsArray = appointmentData?.repeats;
      const payload = {
        Id: appointmentData._id,
        oneAppointment,
        allAppointment,
        currentDate,
        notifyCustomer,
        eventDate: new Date(activeEventDate).toISOString(),
        evenFutureDate: appointmentData.repeats,
        interval_type,
        groupAppointments,
        allService,
        repeatsArray,
        industryId: industryId?._id,
      };
      // console.log('tetts cancc=>', appointmentData);
      const response = await notTimeOutUserInstance().post(
        "/appointment/cancelAppointment",
        payload,
      );
      const { code } = response.data;
      if (code === 200) {
        setTimeout(() => {
          toastMessage("success", response.data.msg);
          closeCancel();
          SelectedStaff(staffId);
          handleAppointmentDetails(false);
          setisloader(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      //     console.log(error);
    }
  };

  return (
    <div className="duration-picker time-picker-modal">
      <div>
        <div
          className="time-keeper-popup duration-popup event-popup"
          ref={wrapperRef}
        >
          <div className="appointment-view">
            <div
              className="pending-water-test"
              onClick={() => HandleCancelType1(true, "only")}
            >
              {endType ? (
                <h3>Cancel This Pool Maintenance Only</h3>
              ) : (
                <h3>Cancel Pool Maintenance</h3>
              )}
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    checked={oneAppointment}
                    onChange={(e) => HandleCancelType(e.target.checked, "only")}
                  />
                </div>
              </div>
            </div>
            {(endType || groupAppointments.length > 1) && (
              <div
                className="pending-water-test"
                onClick={() => HandleCancelType1(true, "future")}
              >
                <h3>Cancel All Pool Maintenance in this Set</h3>
                <div className="open-date client-notes">
                  <div className="basic">
                    <Form.Check
                      type="checkbox"
                      checked={allAppointment}
                      onChange={(e) =>
                        HandleCancelType(e.target.checked, "future")
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {/* <div className="pending-water-test" onClick={() => setNotifyCustomer(!notifyCustomer)}>
              <h3>Notify Customer of Cancellation</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check type="checkbox" checked={notifyCustomer} onChange={(e) => setNotifyCustomer(e.target.checked)} />
                </div>
              </div>
            </div> */}
            <div className="event-popup-btn footer-btn view-btn">
              <Button
                className="appointment-btn btn-edit"
                type="button"
                onClick={() => closeCancel()}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="appointment-btn btn-save"
                onClick={() => handleCancel()}
              >
                {isloader ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "Ok"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
Cancel.propTypes = {
  setCancel: PropTypes.func.isRequired,
  closeCancel: PropTypes.func.isRequired,
  getAppointments: PropTypes.func.isRequired,
  setRandom: PropTypes.func.isRequired,
  appointmentData: PropTypes.func.isRequired,
  eventDate: PropTypes.func.isRequired,
  SelectedStaff: PropTypes.func.isRequired,
  activeStaff: PropTypes.func.isRequired,
  handleAppointmentDetails: PropTypes.func.isRequired,
  groupAppointments: PropTypes.arrayOf.isRequired,
};
export default Cancel;
