/* eslint-disable no-undef */
import React from "react";
import { Form, Button, Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import { coverBlankField } from "../../functions";

const DrainageCost = ({
  handleChangeDrainageCost,
  handleRequest,
  handleRevertViewUpdate,
  state,
  handleNewRevertDefaultUpdate,
  change,
  isUsImperial,
  checkBlankFields,
}) => (
  <div className="pool-setup mineral-pool drainage-cost-section">
    <div className="target-heading">
      <h6>Drainage Time</h6>
    </div>
    <div className="pool-chemistry-box">
      <div className="row">
        <div className="col-7">
          <h6>Pool Size ({isUsImperial ? "gallons" : "litres"})</h6>
        </div>
        <div className="col-5">
          <Form>
            <Form.Group controlId="formBasicInput">
              <Form.Control
                type="number"
                onKeyDown={(event) => {
                  if (
                    event.key === "+" ||
                    event.key === "-" ||
                    event.key === "e"
                  ) {
                    event.preventDefault();
                  }
                }}
                name="poolsize"
                onChange={handleChangeDrainageCost}
                value={state?.poolsize}
                className={coverBlankField(
                  state?.poolsize,
                  "poolsize",
                  checkBlankFields,
                )}
              />
            </Form.Group>
          </Form>
        </div>
      </div>
      <div className="row">
        <div className="col-7">
          <h6>Drain Percentage</h6>
        </div>
        <div className="col-5">
          <Form>
            <Form.Group controlId="formBasicInput">
              <Form.Control
                type="text"
                onKeyDown={(event) => {
                  if (
                    event.key === "+" ||
                    event.key === "-" ||
                    event.key === "e"
                  ) {
                    event.preventDefault();
                  }
                }}
                name="drainagePercentage"
                onChange={handleChangeDrainageCost}
                value={state?.drainagePercentage}
                className={coverBlankField(
                  state?.drainagePercentage,
                  "drainagePercentage",
                  checkBlankFields,
                )}
              />
            </Form.Group>
          </Form>
        </div>
      </div>
      <div className="row">
        <div className="col-7">
          <h6>
            Set-up & Pack-up Time
            <span className="require-icon">*</span>
          </h6>
        </div>
        <div className="col-5">
          <div className="rate-symbol">
            {/* <span className="price-icon">$</span> */}
            <Form>
              <Form.Group controlId="formBasicInput" className="mins-box">
                <Form.Control
                  type="text"
                  onKeyDown={(event) => {
                    if (
                      event.key === "+" ||
                      event.key === "-" ||
                      event.key === "e"
                    ) {
                      event.preventDefault();
                    }
                  }}
                  name="setupCost"
                  onChange={handleChangeDrainageCost}
                  value={state?.setupCost}
                  className={coverBlankField(
                    state?.setupCost,
                    "setupCost",
                    checkBlankFields,
                  )}
                />
                <span>mins</span>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-7">
          <h6>
            Hourly Rate
            <span className="require-icon">*</span>
          </h6>
        </div>
        <div className="col-5">
          <div className="rate-symbol">
            <span className="price-icon">$</span>
            <Form>
              <Form.Group controlId="formBasicInput">
                <Form.Control
                  type="text"
                  onKeyDown={(event) => {
                    if (event.key === '+' || event.key === '-' || event.key === 'e') {
                      event.preventDefault();
                    }
                  }}
                  name="hourlyRate"
                  onChange={handleChangeDrainageCost}
                  value={state?.hourlyRate}
                  className={coverBlankField(state?.hourlyRate, 'hourlyRate', checkBlankFields)}
                />
              </Form.Group>
            </Form>
          </div>
        </div>
      </div> */}
      <div className="row">
        <div className="col-7">
          <h6>
            {isUsImperial ? "Gallons" : "Litres"} per hour pump removes water
            <span className="require-icon">*</span>
          </h6>
        </div>
        <div className="col-5">
          <Form>
            <Form.Group controlId="formBasicInput">
              <Form.Control
                type="text"
                onKeyDown={(event) => {
                  if (
                    event.key === "+" ||
                    event.key === "-" ||
                    event.key === "e"
                  ) {
                    event.preventDefault();
                  }
                }}
                name="litresPerHour"
                onChange={handleChangeDrainageCost}
                value={state?.litresPerHour}
                className={coverBlankField(
                  state?.litresPerHour,
                  "litresPerHour",
                  checkBlankFields,
                )}
              />
            </Form.Group>
          </Form>
        </div>
      </div>
      <div className="row">
        <div className="col-7">
          <h6>Hours to Drain</h6>
        </div>
        <div className="col-5">
          <Form>
            <Form.Group controlId="formBasicInput">
              <Form.Control
                type="text"
                name="hoursToDrain"
                onChange={handleChangeDrainageCost}
                value={state?.hoursToDrain}
                disabled
              />
            </Form.Group>
          </Form>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-8">
          <h5>Drain Cost</h5>
        </div>
        <div className="col-4">
          <h5 className="pl-2">{`$${state?.cost}`}</h5>
        </div>
      </div> */}
      <div className="view-btn">
        {/* <Button type="submit" className="btn btn-edit" onClick={(e) => handleRevertViewUpdate(e, state)}>Revert To Default</Button> */}
        <div className="client-option service-edit-icon">
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="btn btn-edi"
            >
              View Other Settings
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => handleRevertViewUpdate(e, state)}>
                <div className="action">Restore Original Settings</div>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={(e) => handleNewRevertDefaultUpdate(e, state)}
              >
                <div className="action">Revert to Previous Saved Default</div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Button type="submit" className="btn btn-save" onClick={handleRequest}>
          Save as Default
        </Button>
      </div>
    </div>
    <div className="bottom-text">
      <span className="require-icon">*</span>
      <p>
        {" "}
        Note that “Set-up & Pack-up Time” and “litres per hour pump removed
        water” are used in the <b> Calculator</b> for Drainage Times.{" "}
        {/* for Drainage Cost. */}
      </p>
    </div>
  </div>
);
DrainageCost.propTypes = {
  handleChangeDrainageCost: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired,
  state: PropTypes.isRequired,
  handleRevertViewUpdate: PropTypes.func.isRequired,
  handleNewRevertDefaultUpdate: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  isUsImperial: PropTypes.bool.isRequired,
  checkBlankFields: PropTypes.arrayOf.isRequired,
};

export default DrainageCost;
