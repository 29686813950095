import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { TimePicker } from "smart-webcomponents-react/timepicker";
import { useHistory } from "react-router-dom";
import moment from "../../functions/moment";
import "smart-webcomponents-react/source/styles/smart.default.css";
import toastMessage from "../../functions/toastMessage";
import VideoViewPopUp from "./videoViewPopUp";
import VideoUploadPopUp from "./videoUploadPopUp";
import video from "./video.css";
import { userInstance } from "../../config/axios";

// Rita

const VideoTutorialPopUp = ({
  setVideoTutorial,
  actionName,
  setLoading,
  setVideoData,
  setViewVideo,
  setVideoSource,
}) => {
  const history = useHistory();
  const defaultVideoArray = [
    { name: "Overview", class: "main-menu" },
    { name: "Calendar", class: "main-menu" },
    { name: "Calculator", class: "main-menu" },
    // { name: 'Using Calculator', class: 'submenu-item' },
    { name: "How to do a Water Test", class: "submenu-item" },
    { name: "Green Pool Treatment", class: "submenu-item" },
    { name: "Black-Spot Algae Treatment", class: "submenu-item" },
    { name: "Drain or Part-drain Pool", class: "submenu-item" },
    { name: "Sand Filter Media Replacement", class: "submenu-item" },
    { name: "Pool Water Volume via Salinity", class: "submenu-item" },
    { name: "Water Loss Calculator ", class: "submenu-item" },
    { name: "Inverter Heater - Heating Time Required ", class: "submenu-item" },

    // { name: 'Pool Chemistry Inter-relationship', class: 'submenu-item' },
    { name: "Permissions & Access Level", class: "main-menu" },
    { name: "Users", class: "main-menu" },
    // { name: 'Pool Set-up', class: 'main-menu' },
    { name: "My Pool Size & Chlorination", class: "submenu-item" },
    { name: "Chemistry Target Levels", class: "submenu-item" },
    { name: "Calculator Chemicals to Display", class: "submenu-item" },
    { name: "Chemical Costs", class: "submenu-item" },
    { name: "Chlorinator Model", class: "submenu-item" },
    { name: "Drainage Times", class: "submenu-item" },
    { name: "Mineral Pool Settings", class: "submenu-item" },
    { name: "Chemicals Used to Balance Water", class: "submenu-item" },
    { name: "Green Pool Calculations", class: "submenu-item" },
    { name: "Black-Spot Algae Calculations", class: "submenu-item" },
    {
      name: "Drain, Pressure Wash, Chlorine & Acid Wash Calculations",
      class: "submenu-item",
    },
    // { name: 'Sand Filter Media Replacement', class: 'submenu-item' },
    { name: "Calendar Settings & Unit of Measurement", class: "submenu-item" },
    { name: "Water Test History", class: "main-menu" },
    { name: "Inventory", class: "main-menu" },
    // { name: 'Reminders', class: 'main-menu' },
    // { name: 'Reports', class: 'main-menu' },
    // { name: 'My Account', class: 'main-menu' },
    // { name: 'My Profile', class: 'submenu-item' },
    // { name: 'Calculator-Pro Billing', class: 'submenu-item' },
    // { name: 'Help Centre', class: 'main-menu' },
    // { name: 'Chat Bot', class: 'submenu-item' },
    // { name: 'Getting Started', class: 'submenu-item' },
    // { name: 'Video Tutorials', class: 'submenu-item' },
  ];

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className === "duration-picker time-picker-modal"
          ) {
            setVideoTutorial(false);
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleViewVideo = async (dataObj) => {
    if (actionName === "Video Tutorials") {
      const pathname = dataObj?.name
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/-+$/, "");
      console.log("pathname", pathname);
      history.push(`/video-tutorials/${pathname}`);
      return;
    }
    setLoading(true);
    setVideoData(dataObj);
    setViewVideo(true);
    setVideoTutorial(false);
    try {
      const response = await userInstance().get("/user/getVideosByCategory?", {
        params: {
          category: dataObj?.name,
        },
      });
      setLoading(false);
      if (response?.data?.videoDetails?.videoUrl) {
        setVideoSource(response?.data?.videoDetails?.videoUrl);
      } else {
        setVideoSource("");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="duration-picker time-picker-modal">
        <div>
          <div
            className="time-keeper-popup duration-popup video-tutorial"
            ref={wrapperRef}
          >
            <div className="back" onClick={() => setVideoTutorial(false)}>
              <i className="las la-times" />
            </div>
            <h6>
              Click on any heading below to view video tutorials to explain
              detail for how the site works.
            </h6>
            <ul>
              {defaultVideoArray.map((el) => (
                <li className={el.class} onClick={() => handleViewVideo(el)}>
                  {el.name === "Calculator-Pro Billing"
                    ? "PoolWater-Pro Billing"
                    : el.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
VideoTutorialPopUp.propTypes = {
  setVideoTutorial: PropTypes.func.isRequired,
  actionName: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  setVideoData: PropTypes.func.isRequired,
  setViewVideo: PropTypes.func.isRequired,
  setVideoSource: PropTypes.func.isRequired,
};
export default VideoTutorialPopUp;
