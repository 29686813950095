import React from "react";
import { Button, Accordion, Card } from "react-bootstrap";
import PropTypes from "prop-types";
import sort from "../../assets/home/sort.png";
import whiteedit from "../../assets/home/white-edit.png";
import whitedelete from "../../assets/home/white-delete.png";
import upsort from "../../assets/home/up-sort.png";
import downsort from "../../assets/home/down-sort.png";
import arrow from "../../assets/home/left-arrow.svg";
import add from "../../assets/desktop-view/add.png";
import grayadd from "../../assets/desktop-view/gray-plus.svg";
import graysort from "../../assets/desktop-view/gray-sort.svg";

const ChlorinatorPage = ({
  renderSort,
  setRenderSort,
  handleChlorinator,
  state,
  handleSetEditChl,
  submitRemove,
  moveArr,
  handleSorting,
}) => (
  <>
    <div className="service-mbl pool-accordian mobile-view-chlorinator">
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <div className="service-container">
              <div className="service-title title-service">
                <h4>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    {/* <i className={isServiceAccordOpen ? 'fas far fa-chevron-down' : 'fas far fa-chevron-up'} /> */}{" "}
                    Chlorinator Models
                  </Accordion.Toggle>
                </h4>
                {/* <span className="sort-icon"><img src={sort} alt="sort" /></span> */}
                <div
                  className={`services-header ${renderSort && "active-sort"}`}
                >
                  <div
                    className="sorting"
                    onClick={() => setRenderSort(!renderSort)}
                  >
                    <img src={sort} alt="sorting" />
                  </div>
                  <span onClick={handleChlorinator}>
                    <i className="fa fa-plus" />
                  </span>
                </div>
              </div>
            </div>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <div className="mob-table">
              {Array.isArray(state) ? (
                <>
                  {state.map((el, index) => (
                    <Accordion className="mob-accordion outer-accordion">
                      <Card.Header>
                        <div className="heading-accordion">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                          >
                            <h5>
                              <i className="fas fa-chevron-circle-up" />
                              {el.name}
                            </h5>
                          </Accordion.Toggle>
                          {renderSort ? (
                            <div
                              className={`sorting up-down ${
                                renderSort && "active-sort"
                              }`}
                            >
                              {index !== 0 ? (
                                <img
                                  src={upsort}
                                  alt="sorting"
                                  onClick={() => moveArr(index, index - 1)}
                                />
                              ) : null}
                              {index !== state.length - 1 ? (
                                <img
                                  src={downsort}
                                  alt="sorting"
                                  onClick={() => moveArr(index, index + 1)}
                                />
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <div className="inner-accordion">
                            <Accordion defaultActiveKey="0">
                              <Card>
                                <ul className="grid-list">
                                  <li className="grid-list-item">
                                    <div className="grid-box">
                                      <h3>
                                        Chlorinator Model
                                        {/* <span> (as will display on invoices) </span> */}
                                      </h3>
                                      <h5>{el.name}</h5>
                                    </div>
                                  </li>
                                  <li className="grid-list-item">
                                    <div className="grid-box">
                                      <h3>Salt Level (ppm)</h3>
                                      <h5>{el.saltLevel}</h5>
                                    </div>
                                  </li>
                                  <li className="grid-list-item">
                                    <div className="grid-box">
                                      <h3> OK - Level</h3>
                                      <h5>{`-${el.okNegative}%`}</h5>
                                    </div>
                                  </li>
                                  <li className="grid-list-item">
                                    <div className="grid-box">
                                      <h3> OK + Level</h3>
                                      <h5>{`+${el.okPositive}%`}</h5>
                                    </div>
                                  </li>
                                  <li className="grid-list-item">
                                    <div className="grid-box">
                                      <h3> Drain Point</h3>
                                      <h5>{`+${el.drainPoint}%`}</h5>
                                    </div>
                                  </li>

                                  <li className="grid-list-item">
                                    {/* {el.orp === 'Yes' ? ( */}
                                    <div className="grid-box">
                                      <h3>ORP Probe Installed</h3>
                                      <h5>{el.orp ? el.orp : "No"}</h5>
                                    </div>
                                    {/* ) : ''} */}
                                  </li>
                                  {el.orp === "Yes" ? (
                                    <li className="grid-list-item">
                                      <div className="grid-box">
                                        <h3>Stabiliser Level</h3>
                                        <h5>{el.recmndStabiliserLvl}</h5>
                                      </div>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                  <li className="grid-list-item">
                                    <div className="grid-box">
                                      <h3>Actions</h3>
                                      <div className="action-btn">
                                        <Button
                                          className="edit-btn"
                                          onClick={() =>
                                            handleSetEditChl(el, index)
                                          }
                                        >
                                          <img src={whiteedit} alt="edit" />
                                        </Button>
                                        <Button
                                          className="delete-btn"
                                          onClick={() => submitRemove(index)}
                                        >
                                          <img src={whitedelete} alt="edit" />
                                        </Button>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </Card>
                            </Accordion>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Accordion>
                  ))}
                </>
              ) : null}
            </div>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      {renderSort ? (
        <div className="view-btn pool-setup-btn save-btn-setup">
          {/* <Button type="submit" className="btn btn-edit" onClick={handleRevertDefault}>Revert To Default</Button> */}
          <Button
            type="submit"
            className="btn btn-save"
            onClick={handleSorting}
          >
            Save/Update
          </Button>
        </div>
      ) : null}
    </div>
    <div className="web-servicesproducts">
      <div className="service-mbl pool-accordian web-view-chlorinator ">
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <div className="service-container">
                <div className="service-title title-service">
                  <h4>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      {/* <i className={isServiceAccordOpen ? 'fas far fa-chevron-down' : 'fas far fa-chevron-up'} /> */}{" "}
                      Chlorinator Models
                    </Accordion.Toggle>
                  </h4>
                  {/* <span className="sort-icon"><img src={sort} alt="sort" /></span> */}
                  <div
                    className={`services-header ${renderSort && "active-sort"}`}
                  >
                    <div
                      className="sorting"
                      onClick={() => setRenderSort(!renderSort)}
                    >
                      <img src={graysort} alt="sorting" />
                    </div>
                    <span>
                      <img
                        src={grayadd}
                        alt="sorting"
                        onClick={handleChlorinator}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <div className="mob-table">
                {Array.isArray(state) ? (
                  <>
                    {state.map((el, index) => (
                      <Accordion className="mob-accordion outer-accordion">
                        <Card.Header>
                          <div className="heading-accordion">
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey="0"
                            >
                              <h5>{el.name}</h5>
                              <img src={arrow} alt="arrow" />
                            </Accordion.Toggle>
                            {renderSort ? (
                              <div
                                className={`sorting up-down ${
                                  renderSort && "active-sort"
                                }`}
                              >
                                {index !== 0 ? (
                                  <img
                                    src={upsort}
                                    alt="sorting"
                                    onClick={() => moveArr(index, index - 1)}
                                  />
                                ) : null}
                                {index !== state.length - 1 ? (
                                  <img
                                    src={downsort}
                                    alt="sorting"
                                    onClick={() => moveArr(index, index + 1)}
                                  />
                                ) : null}
                              </div>
                            ) : null}
                          </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <div className="inner-accordion">
                              <Accordion defaultActiveKey="0">
                                <Card>
                                  <div className="header-section">
                                    <h6>Chlorinator Model</h6>
                                    <h6>Salt Level (ppm)</h6>
                                    <h6>OK - Level</h6>
                                    <h6>OK + Level</h6>
                                    <h6>Drain Point</h6>
                                    <h6>ORP Probe Installed</h6>
                                    {el.orp === "Yes" ? (
                                      <h6>Stabiliser Level</h6>
                                    ) : (
                                      ""
                                    )}
                                    <h6>Action</h6>
                                  </div>

                                  <div className="body-section-list header-section">
                                    <div className="services-body-list">
                                      <p>{el.name}</p>
                                    </div>
                                    <div className="services-body-list">
                                      <p>{el.saltLevel}</p>
                                    </div>
                                    <div className="services-body-list">
                                      <p> {`-${el.okNegative}%`}</p>
                                    </div>
                                    <div className="services-body-list">
                                      <p>{`+${el.okPositive}%`}</p>
                                    </div>
                                    <div className="services-body-list">
                                      <p> {`+${el.drainPoint}%`}</p>
                                    </div>
                                    <div className="services-body-list">
                                      <p> {el.orp ? el.orp : "No"}</p>
                                    </div>
                                    {el.orp === "Yes" ? (
                                      <div className="services-body-list">
                                        {el.orp === "Yes" ? (
                                          <>{el.recmndStabiliserLvl}</>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="services-body-list">
                                      <p>
                                        <div className="action-icon">
                                          <i
                                            className="las la-edit"
                                            onClick={() =>
                                              handleSetEditChl(el, index)
                                            }
                                          />
                                          <i
                                            className="las la-trash-alt"
                                            onClick={() => submitRemove(index)}
                                          />
                                        </div>
                                      </p>
                                    </div>
                                  </div>
                                  {/* <ul className="grid-list">
                                    <li className="grid-list-item">
                                      <div className="grid-box">
                                        <h3>
                                          Chlorinator Model

                                        </h3>
                                        <h5>{el.name}</h5>
                                      </div>
                                    </li>
                                    <li className="grid-list-item">
                                      <div className="grid-box">
                                        <h3>Salt Level (ppm)</h3>
                                        <h5>{el.saltLevel}</h5>
                                      </div>
                                    </li>
                                    <li className="grid-list-item">
                                      <div className="grid-box">
                                        <h3> OK - Level</h3>
                                        <h5>{`-${el.okNegative}%`}</h5>
                                      </div>
                                    </li>
                                    <li className="grid-list-item">
                                      <div className="grid-box">
                                        <h3> OK + Level</h3>
                                        <h5>{`+${el.okPositive}%`}</h5>
                                      </div>
                                    </li>
                                    <li className="grid-list-item">
                                      <div className="grid-box">
                                        <h3> Drain Point</h3>
                                        <h5>{`+${el.drainPoint}%`}</h5>
                                      </div>
                                    </li>

                                    <li className="grid-list-item">
                                      <div className="grid-box">
                                        <h3>ORP Probe Installed</h3>
                                        <h5>{el.orp ? el.orp : 'No' }</h5>
                                      </div>
                                    </li>
                                    {el.orp === 'Yes'
                                      ? (
                                        <li className="grid-list-item">
                                          <div className="grid-box">
                                            <h3>Stabiliser Level</h3>
                                            <h5>{el.recmndStabiliserLvl}</h5>
                                          </div>
                                        </li>
                                      )
                                      : ''}
                                    <li className="grid-list-item">
                                      <div className="grid-box">
                                        <h3>Actions</h3>
                                        <div className="action-btn">
                                          <Button
                                            className="edit-btn"
                                            onClick={() => handleSetEditChl(el, index)}
                                          >
                                            <img src={whiteedit} alt="edit" />
                                          </Button>
                                          <Button
                                            className="delete-btn"
                                            onClick={() => submitRemove(index)}
                                          >
                                            <img src={whitedelete} alt="edit" />
                                          </Button>
                                        </div>
                                      </div>
                                    </li>
                                  </ul> */}
                                </Card>
                              </Accordion>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Accordion>
                    ))}
                  </>
                ) : null}
              </div>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        {renderSort ? (
          <div className="view-btn pool-setup-btn save-btn-setup">
            {/* <Button type="submit" className="btn btn-edit" onClick={handleRevertDefault}>Revert To Default</Button> */}
            <Button
              type="submit"
              className="btn btn-save"
              onClick={handleSorting}
            >
              Save/Update
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  </>
);
ChlorinatorPage.propTypes = {
  renderSort: PropTypes.bool.isRequired,
  setRenderSort: PropTypes.bool.isRequired,
  handleChlorinator: PropTypes.func.isRequired,
  state: PropTypes.func.isRequired,
  handleSetEditChl: PropTypes.func.isRequired,
  submitRemove: PropTypes.func.isRequired,
  moveArr: PropTypes.func.isRequired,
  handleSorting: PropTypes.func.isRequired,
};

export default ChlorinatorPage;
