/* eslint-disable import/no-unresolved */
import React, { useState, useEffect, useRef, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import interactionPlugin from "@fullcalendar/interaction";
import { NotificationManager } from "react-notifications";
import Helmet from "react-helmet";
import moment from "../../functions/moment";
import "react-calendar/dist/Calendar.css";
import CalendarModal from "./calendarmodal";
import AdminWrapper from "../admin/adminwrapper/wrapper";
import { HeaderContext } from "../../contexts/HeaderContext";
import left1 from "../../assets/home/left1.png";
import left2 from "../../assets/home/left2.png";
import left3 from "../../assets/home/left3.png";
import right1 from "../../assets/home/right1.png";
import right2 from "../../assets/home/right2.png";
import right3 from "../../assets/home/right3.png";
import {
  queryString,
  getCalendarPath,
  updateServerDateTime,
  RescheduleUpdateMsg,
  timeIntervalDay,
  updateReschduleChemicals,
  updateServiceDateTime,
  updategrupeGrupeApp,
} from "../../functions/index";
import { newappointmentEventCreate } from "../../functions/newEvent";
import TeamMember from "./teamMembers";
import aroow from "../../assets/home/showmoreblue.png";
import "./calendar.css";
import Event from "./event";
import repeat from "../../assets/home/repeat.png";
import circlecheck from "../../assets/home/icon/check-mark.png";
import circleIcon from "../../assets/home/icon/circle.png";
import { userInstance, notTimeOutUserInstance } from "../../config/axios";
import ConfirmPopupApp from "../appointment/confirmPopupApp";
import LocationChangePopUp from "./locationChangePopUp";
import { AuthDataContext } from "../../contexts/AuthDataContext";
import { errorMsgTime, successMsgTime } from "../../functions/intervalTime";
import Viewappointment from "../appointment/view-appointment";
import toastMessage from "../../functions/toastMessage";
import "./webview.css";
import { socket } from "../../socket";
import {
  convertDateTime,
  displayTime,
  getTimeZone,
} from "../../functions/timeDate";

let lastScrollTop = 0;
let calender;
function StaffCalendar() {
  const history = useHistory();
  JSON.parse(localStorage.getItem("chemicalalist"));
  const [customView, setCustomView] = useState("timeGridWeek");
  const [color, setColor] = useState("#e2ca8e");
  const [secondcolor, setSecondColor] = useState("#f3ead5");
  const isNavClick = useRef(false);
  let {
    activedate,
    view,
    staff_id,
    chemicaltype,
    today,
    clientid,
    appId,
    appointmentId,
    data: resDataType,
    userId,
    token,
  } = queryString();
  const defaultcolor = localStorage.getItem("color");
  const defaultsecondcolor = localStorage.getItem("secondcolor");

  const duration = localStorage.getItem("duration");
  const slot = localStorage.getItem("slot");

  const queryParams = new URLSearchParams(window.location.search);
  const type = queryParams.get("data");
  const Id = queryParams.get("id");
  localStorage.setItem("view", view || customView);
  const [isSticky, setIsSticky] = useState("");
  const [forManagementEdit, setForManagementEdit] = useState(false);
  const {
    clientAllData,
    setClientAllData,
    userData: { industryId, calendarSettings, userInformation },
  } = useContext(AuthDataContext);
  const { timeDisplay, timeZone, week } = calendarSettings || {};
  const watchScroll = () => {
    let st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop && !isNavClick.current) {
      setIsSticky("shift-up");
    } else if (isNavClick.current) {
      setTimeout(() => {
        isNavClick.current = false;
      }, 1500);
      setIsSticky("shift-down");
    } else {
      setIsSticky("shift-down");
    }
    lastScrollTop = st <= 0 ? 0 : st;
  };
  useEffect(() => {
    if (window !== undefined && window.screen.width <= 768) {
      window.addEventListener("scroll", watchScroll);
    }
    return () => {
      window.removeEventListener("scroll", watchScroll);
    };
  }, []);
  const { setShowHeader } = useContext(HeaderContext);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarData, setCalendarData] = useState({
    currentDate: "",
    currentWeekNo: "",
    startDate: "",
    endDate: "",
    calcdatelabel: "",
  });
  const [state, setState] = useState({
    weekendsVisible: true,
    currentEvents: [],
    startDate: "",
    endDate: "",
  });

  const calendarComponentRef = useRef(null);
  const [events, setEvents] = useState([]);
  const [showTeam, setShowTeam] = useState(false);
  const [appointmentDetail, setAppointmentDetail] = useState(false);
  const [appointmentData, setAppointmentData] = useState([]);
  const [addedServices, setAddedServices] = useState([]);
  const [eventDate, setEventDate] = useState("");
  const [staffList, setstaffList] = useState([]);
  const initialState = {
    firstname: "",
    lastname: "",
    streetAddress: "",
    location: "",
    staffId: "",
  };
  const [activeStaff, setActiveStaff] = useState(initialState);
  const [eventPopup, setEventPopup] = useState(false);
  const [blockedtime, setBlockedtime] = useState();
  const [filterType, setFilterType] = useState("timeGridWeek");
  const [filterTime, setFilterTime] = useState("1hour");
  const [random, setRandom] = useState("");
  const stateRef = useRef(null);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const rescheduleInitialState = {
    dateData: "",
    date: "",
    setDefaultTime: "",
    repeats: "",
    info1: "",
    activedate: "",
  };
  const [rescheduleData, setRescheduleData] = useState(rescheduleInitialState);
  const [customerDues, setCustomerDues] = useState(0);
  const [appointmentCopy, setappointmentCopy] = useState([]);
  const [blockTimeCopy, setblockTimeCopy] = useState([]);
  // LocationChangePopup by Faizan
  const [locationPopUp, setLocationPopUp] = useState(false);
  const [locationName, setLocationName] = useState("");
  const [staffLocation, setStaffLocation] = useState([]);
  const [staffCollection, setStaffCollection] = useState([]);
  const { state: finaliseState } = useLocation();
  const [editStatus, setEditStatus] = useState(false);
  const [reschduleChemicalList, setReschduleChemicalList] = useState([]);
  const [notifyCustomer, setNotifyCustomer] = useState(true);
  const [appEndType, setAppEndType] = useState("");
  const [slotDuration, setSlotDuration] = useState("01:00");
  const [slotValue, setSlotValue] = useState("00:15:00");
  const [appType, setAppType] = useState(false);
  const [UserID, setUserId] = useState("");
  const [dbTimeZone, setDbTimeZone] = useState();

  clientid =
    finaliseState && finaliseState.existClientId
      ? finaliseState.existClientId
      : clientid;
  const [clType, setClType] = useState(false);
  const handleLocationPopUp = () => {
    setLocationPopUp(!locationPopUp);
  };
  useEffect(() => {
    if (localStorage.getItem("s#@4f11") && localStorage.getItem("f#@5e11")) {
      setAppType(true);
    }

    socket.on("clientAllData", (data) => {
      setAppointmentData(data?.clientAllData?.appointmentData);
      setAddedServices(data?.clientAllData?.addedServices);
      setCustomerDues(data?.clientAllData?.customerDues);
      setEventDate(data?.clientAllData?.eventDate);
      setActiveStaff(data?.clientAllData?.activeStaff);
      setUserId(data?.userId);
      setAppointmentDetail(true);
    });
  }, []);

  useEffect(() => {
    if (!(localStorage.getItem("s#@4f11") && localStorage.getItem("f#@5e11"))) {
      if ((clientid || chemicaltype === "open") && clientAllData?.activeStaff) {
        setAppointmentData(clientAllData.appointmentData);
        setAddedServices(clientAllData.addedServices);
        setCustomerDues(clientAllData.customerDues);
        setEventDate(clientAllData.eventDate);
        setActiveStaff(clientAllData.activeStaff);
        setAppointmentDetail(!appointmentDetail);
      }
    }
  }, []);

  const getStaffLocation = async () => {
    const response = await userInstance().get("/location/getStaffLocation");
    const { msg, location } = response.data || {};
    if (msg === "Success") {
      const filterAllLocation = location.filter(
        (el) => el.staffId && el.staffId.length > 0,
      );
      setStaffLocation(filterAllLocation);
      setLocationName(filterAllLocation[0]._id);
    }
  };

  const handleLocationPopUpChange = (value) => {
    setLocationName(value);
  };

  useEffect(() => {
    if (today === "today") {
      TodayEvents("");
    }
    getStaffLocation();
  }, []);

  const setCalendarColor = async (colors, secondcolors) => {
    localStorage.setItem("color", colors);
    localStorage.setItem("secondcolor", secondcolors);
    setColor(colors);
    setSecondColor(secondcolors);
    handleCloseClient();
    let startDate = localStorage.getItem("date");
    let endDate = localStorage.getItem("enddate");
    const filterStart =
      moment(new Date(startDate)).format("YYYY-MM-DD") ||
      moment(new Date()).format("YYYY-MM-DD");
    const filterEnd =
      moment(new Date(endDate)).format("YYYY-MM-DD") ||
      moment(new Date().setDate(new Date().getDate() + 7)).format("YYYY-MM-DD");
    const eventallData = newappointmentEventCreate(
      appointmentCopy,
      blockTimeCopy,
      secondcolors,
      colors,
      filterStart,
      filterEnd,
      timeZone,
    );
    setEvents(eventallData);
    await genarateEvents(eventallData);
  };
  const handleAppointmentDetails = (data) => {
    setAppointmentDetail(!appointmentDetail);
    clientid = data;

    localStorage.removeItem("apptData");

    setClientAllData({ ...clientAllData, activeStaff: null });
    if (finaliseState?.existClientId) {
      finaliseState.existClientId = "";
      clientid = "";
    }
    if (chemicaltype === "open") {
      history.push(getCalendarPath());
    }
  };
  useEffect(() => {
    stateRef.current = activeStaff;
  }, [activeStaff]);
  const handleShowCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleDateSelect = (selectInfo) => {
    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect();
  };
  const forOpeningVisitFromWater = async () => {
    if (chemicaltype === "open" || clientid) {
      // setAppointmentDetail(!appointmentDetail);
      // const dataEvent = localStorage.getItem('clickInfoEvent');
      // const clickInfo = {
      //   event: JSON.parse(dataEvent),
      // };
      // await handleEventClick(clickInfo);
    } else if (chemicaltype === "opennotification") {
      setAppointmentDetail(!appointmentDetail);
      const response = await userInstance().get(
        `/appointment/getAppointmentById?id=${appId}`,
      );
      const { appointmentdetail, msg, totalBalanceDue } = response.data;
      if (msg === "Success") {
        // console.log('appointmentdetail ==>', appointmentdetail.repeats[0].date);
        setEventDate(appointmentdetail.repeats[0].date);
        setCustomerDues(parseFloat(totalBalanceDue).toFixed(2));
        localStorage.setItem("activeIndex", 0);
        setAddedServices(appointmentdetail.dateData[0].service);
        setAppointmentData(appointmentdetail);
      }
    }
  };
  const handleEventClick = async (clickInfo) => {
    console.log("event click");
    setAppointmentDetail(false);
    const appPid = clickInfo.event.extendedProps.appPid || "";
    // const eventDate2 = clickInfo.event._instance
    //   ? clickInfo.event._instance.range.start : clickInfo.event.start ? clickInfo.event.start : new Date();
    const eventDate2 = clickInfo.event._instance
      ? clickInfo.event.startStr
      : clickInfo.event.start
        ? clickInfo.event.start
        : new Date();
    // console.log('eventDate2=>', eventDate2);
    localStorage.setItem("clickInfoEvent", JSON.stringify(clickInfo.event));
    setEventDate(eventDate2.toString());

    let eventdateTime = moment(eventDate2.split("T")[0]).format("YYYY-MM-DD");
    // eventdateTime.setHours(0, 0, 0, 0);

    localStorage.setItem("activedate", eventDate2);
    localStorage.setItem("prebackDate", eventDate2.toString());

    // console.log({ eventdateTime, eventDate2 });

    if (
      clickInfo.event.extendedProps &&
      (clickInfo.event.extendedProps.type === "personaltype" ||
        clickInfo.event.extendedProps.type === "blocktype")
    ) {
      let checkapptype =
        clickInfo.event.extendedProps.type === "personaltype"
          ? "personalappointment"
          : "blockedtime";
      localStorage.setItem("checkapptype", checkapptype);
      let blockUrls = `/addappointment?startDateTime=${clickInfo.event.startStr}&employee_id=${clickInfo.event.extendedProps.staffId}&blocktime=open&blocktype=edit&block_id=${clickInfo.event.id}`;
      history.push(blockUrls);
    } else {
      if (chemicaltype !== "open") {
        localStorage.removeItem("chemicaltype");
        localStorage.removeItem("chemicalalist");
      }
      // setAppointmentDetail(!appointmentDetail);
      setForManagementEdit(true);

      const response = await userInstance().get(
        `/appointment/getAppointmentById?id=${clickInfo.event.id}&pid=${appPid}`,
      );
      const { appointmentdetail, msg, totalBalanceDue, groupAppointments } =
        response.data;

      if (msg === "Success") {
        setCustomerDues(parseFloat(totalBalanceDue).toFixed(2));
        const repeatsIndex = appointmentdetail.repeats.findIndex(
          (el) =>
            new Date(el.date).toLocaleDateString() ===
            new Date(eventdateTime).toLocaleDateString(),
        );

        localStorage.setItem("activeIndex", repeatsIndex);
        localStorage.setItem("visitIndex", appointmentdetail.visitIndex);
        setAddedServices(appointmentdetail.dateData[0].service);
        setAppointmentData(appointmentdetail);
        const copyClientAllData = { ...clientAllData };
        copyClientAllData.appointmentData = appointmentdetail;
        copyClientAllData.addedServices = appointmentdetail.dateData[0].service;
        copyClientAllData.customerDues = parseFloat(totalBalanceDue).toFixed(2);
        copyClientAllData.eventDate = eventDate2.toString();
        copyClientAllData.activeStaff = activeStaff;
        copyClientAllData.clientAllDetails = appointmentdetail.client;
        // console.log('setgroupAppointments ========>', groupAppointments);
        copyClientAllData.groupAppointments = groupAppointments;
        localStorage.setItem("apptData", JSON.stringify(copyClientAllData));
        setClientAllData(copyClientAllData);
        setAppointmentDetail(true);
      }
    }
  };
  const handleEvents = (event) => {
    setState({
      ...state,
      currentEvents: event,
    });
  };
  const [stEvents, setStEvents] = useState([]);
  useEffect(() => {
    let idDiv = editStatus ? eventDate : appointmentId;
    if (editStatus && events.length) {
      setStEvents(events);
    } else if (events.length && idDiv) {
      setStEvents(events);
    }

    if (stEvents.length) {
      let element = document.getElementById(idDiv);

      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [events, stEvents, editStatus]);

  const renderEventContent = (eventInfo) => (
    <>
      {eventInfo.event.extendedProps.type === "blocktype" ? (
        <div
          id={eventInfo.event.startStr}
          className={`block-type appt-block ${eventInfo.event.extendedProps.type ? "blocktype-event" : ""}`}
        >
          <p
            className={`blocktype-section ${
              customView === "timeGridWeek" ? "week-day-select" : ""
            }`}
            style={{ color: eventInfo.event.textColor }}
          >
            {eventInfo.timeText}{" "}
            <span
              className="appt-icons"
              style={{ backgroundColor: eventInfo.event.backgroundColor }}
            >
              {eventInfo.event.extendedProps.reapated === "reapated" ? (
                <img
                  src={repeat}
                  alt="repeat"
                  style={{ backgroundColor: eventInfo.event.backgroundColor }}
                />
              ) : (
                ""
              )}
            </span>
          </p>
          <b>{eventInfo.event.title}</b>
        </div>
      ) : eventInfo.event.extendedProps.type === "personaltype" ? (
        <div
          id={eventInfo.event.startStr}
          className="personaltype-type appt-block"
        >
          <p
            className={`blocktype-section ${
              customView === "timeGridWeek" ? "week-day-select" : ""
            }`}
            style={{ color: eventInfo.event.textColor }}
          >
            {eventInfo.timeText}{" "}
            <span
              className="appt-icons"
              style={{ backgroundColor: eventInfo.event.backgroundColor }}
            >
              {eventInfo.event.extendedProps.reapated === "reapated" ? (
                <img
                  src={repeat}
                  alt="repeat"
                  style={{ backgroundColor: eventInfo.event.backgroundColor }}
                />
              ) : (
                ""
              )}
            </span>
          </p>
          <b>{eventInfo.event.title}</b>
        </div>
      ) : (
        <div
          id={eventInfo.event.startStr}
          className={`appt-block ${eventInfo.event.startStr} ${eventInfo.event.extendedProps.status === "Completed" ? "completed-block" : "appt-block"}`}
        >
          {parseInt(eventInfo.event.extendedProps.time, 10) <= 30 ? (
            <p
              className={`${
                customView === "timeGridWeek" ? "week-day-select" : ""
              }`}
            >
              {eventInfo.timeText}{" "}
              <span
                className="appt-icons"
                style={{ backgroundColor: eventInfo.event.backgroundColor }}
              >
                {eventInfo.event.extendedProps.reapated === "reapated" ? (
                  <img src={repeat} alt="repeat" />
                ) : (
                  ""
                )}{" "}
                {eventInfo.event.extendedProps.status === "Completed" &&
                eventInfo.event.extendedProps.invoiceStatus === "Approved" &&
                eventInfo.event.extendedProps.paymentStatus !== "paid" ? (
                  <img src={circlecheck} alt="circlecheck" />
                ) : eventInfo.event.extendedProps.status === "Completed" &&
                  eventInfo.event.extendedProps.invoiceStatus === "Approved" &&
                  eventInfo.event.extendedProps.paymentStatus === "paid" ? (
                  <img src={circleIcon} alt="circleIcon" />
                ) : eventInfo.event.extendedProps.status === "Completed" ? (
                  <i className="" aria-hidden="true" />
                ) : (
                  ""
                )}
              </span>
            </p>
          ) : (
            <p
              className={`${
                customView === "timeGridWeek" ? "week-day-select" : ""
              }`}
            >
              {eventInfo.timeText}{" "}
              <span
                className="appt-icons"
                style={{ backgroundColor: eventInfo.event.backgroundColor }}
              >
                {eventInfo.event.extendedProps.reapated === "reapated" ? (
                  <img src={repeat} alt="repeat" />
                ) : (
                  ""
                )}{" "}
                {eventInfo.event.extendedProps.status === "Completed" &&
                eventInfo.event.extendedProps.invoiceStatus === "Approved" &&
                eventInfo.event.extendedProps.paymentStatus !== "paid" ? (
                  <img src={circlecheck} alt="circlecheck" />
                ) : eventInfo.event.extendedProps.status === "Completed" &&
                  eventInfo.event.extendedProps.invoiceStatus === "Approved" &&
                  eventInfo.event.extendedProps.paymentStatus === "paid" ? (
                  <img src={circleIcon} alt="circleIcon" />
                ) : eventInfo.event.extendedProps.status === "Completed" ? (
                  <i className="" aria-hidden="true" />
                ) : (
                  ""
                )}
              </span>
            </p>
          )}
          {eventInfo.event.extendedProps.reapated === "reapated" &&
          parseInt(eventInfo.event.extendedProps.time, 10) >= 30 ? (
            <p
              className={`${
                customView === "timeGridWeek" ? "repeated-week-day-select" : ""
              }`}
            >
              <b>{/* {eventInfo.event.extendedProps.staffName} */}</b>
            </p>
          ) : (
            <>
              {parseInt(eventInfo.event.extendedProps.time, 10) >= 30 &&
              eventInfo.event.extendedProps.status === "Completed" ? (
                <p
                  className={`${
                    customView === "timeGridWeek"
                      ? "repeated-week-day-select"
                      : ""
                  }`}
                >
                  <b>{/* {eventInfo.event.extendedProps.staffName} */}</b>
                </p>
              ) : parseInt(eventInfo.event.extendedProps.time, 10) >= 30 ? (
                <b>{eventInfo.event.extendedProps.staffName}</b>
              ) : (
                ""
              )}
            </>
          )}
          {customView === "timeGridWeek" ? (
            <>
              {parseInt(eventInfo.event.extendedProps.time, 10) <= 45 ? (
                ""
              ) : (
                <p>{eventInfo.event.title}</p>
              )}
              {parseInt(eventInfo.event.extendedProps.time, 10) <= 45 ? (
                ""
              ) : (
                <i>{eventInfo.event.extendedProps.subtitle || ""}</i>
              )}
            </>
          ) : (
            <>
              {parseInt(eventInfo.event.extendedProps.time, 10) <= 45 ? (
                ""
              ) : (
                <p
                  className={`${
                    customView === "timeGridThreeDay" ? "day3-view" : ""
                  }`}
                >
                  {eventInfo.event.extendedProps.servicename}
                </p>
              )}
            </>
          )}
          {parseInt(eventInfo.event.extendedProps.time, 10) <= 30 ? (
            ""
          ) : (
            <i>
              {/* $
                {eventInfo.event.extendedProps.cost} */}
            </i>
          )}
        </div>
      )}
    </>
  );
  const initiateCalendar = async () => {
    const calendarApi = calendarComponentRef.current.getApi() || {};
    // const date = calendarApi.getDate();

    const date2 = calendarApi.getDate();
    const date = date2.toLocaleString("en-US", {
      timeZone,
    });
    const currentDate = `${months[new Date(date).getMonth()]} ${new Date(
      date,
    ).getFullYear()}`;
    const currentWeekNo = moment(date).format("w");
    let startDate;
    let endDate;
    let calcdatelabel;
    ({ startDate, endDate, calcdatelabel } = await initiateWeek());
    setCalendarData({
      ...calendarData,
      currentDate,
      currentWeekNo,
      startDate,
      endDate,
      calcdatelabel,
    });
  };

  // const initiateCalendarV2 = async (calanderRef) => {
  //   if (calendarComponentRef && calendarComponentRef.current) {
  //     const calendarApi = calendarComponentRef.current.getApi();
  //     const date = calendarApi.getDate();
  //     const currentDate = `${months[new Date(date).getMonth()]} ${new Date(
  //       date,
  //     ).getFullYear()}`;
  //     const currentWeekNo = moment(date).format('w');
  //     const { startDate, endDate, calcdatelabel } = await initiateWeek(
  //       calanderRef,
  //     );
  //     setCalendarData({
  //       ...calendarData,
  //       currentDate,
  //       currentWeekNo,
  //       startDate,
  //       endDate,
  //       calcdatelabel,
  //     });
  //   }
  // };

  const loadAppComponentData = async () => {
    try {
      const firstday = new Date(
        moment().startOf("isoWeek").format("YYYY-MM-DD"),
      );
      const lastday = new Date(
        moment().add(1, "weeks").endOf("isoWeek").format("YYYY-MM-DD"),
      );
      const filterStart = localStorage.getItem("date") || firstday;
      const filterEnd = localStorage.getItem("enddate") || lastday;
      localStorage.setItem("date", new Date(filterStart).toISOString());
      localStorage.setItem("enddate", new Date(filterEnd).toISOString());
      localStorage.setItem("color", defaultcolor || color);
      localStorage.setItem("secondcolor", defaultsecondcolor || secondcolor);
      let startDate = localStorage.getItem("date");
      let endDate = localStorage.getItem("enddate");
      let view2 = localStorage.getItem("view");
      const result = await notTimeOutUserInstance().get(
        `/staff/newgetStaffAppointments?id=${staff_id}&startDate=${startDate}&endDate=${endDate}`,
      );
      setFilterType(view2);
      let { staff, alleventData, msg } = result.data;
      if (msg === "Success") {
        let { firstname, lastname, _id, appointments, blocktimes, shift } =
          alleventData || {};
        setappointmentCopy(appointments);
        setblockTimeCopy(blocktimes);
        if (type && Id) {
          let calendarUrl = `/calendar?activedate=${
            startDate
              ? moment(new Date(startDate)).format("YYYY-MM-DD")
              : moment(new Date()).format("YYYY-MM-DD")
          }&view=${view2 || "timeGridWeek"}&staff_id=${_id || ""}&data=${type}&id=${Id}`;
          history.push(calendarUrl);
        } else {
          let calendarUrl = `/calendar?activedate=${
            startDate
              ? moment(new Date(startDate)).format("YYYY-MM-DD")
              : moment(new Date()).format("YYYY-MM-DD")
          }&view=${view2 || "timeGridWeek"}&staff_id=${_id || ""}`;
          if (clientid && !clientAllData?.activeStaff)
            history.push(calendarUrl);
        }
        localStorage.setItem("staff_id", _id);
        localStorage.setItem("staff_name", `${firstname} ${lastname}`);
        // eslint-disable-next-line no-shadow
        const { locationName } = alleventData.location || {};
        const locationId = alleventData.location
          ? alleventData.location._id
          : "";
        localStorage.setItem("locationId", locationId);
        staff?.sort((a, b) =>
          a.lastname.trim().localeCompare(b.lastname.trim()),
        );
        setstaffList(staff);
        setActiveStaff({
          ...activeStaff,
          firstname,
          lastname,
          streetAddress: locationName,
          location: "",
          staffId: _id,
          shift,
        });
        setappointmentCopy(appointments);
        setblockTimeCopy(blocktimes);
        const eventallData = newappointmentEventCreate(
          appointments,
          blocktimes,
          secondcolor,
          color,
          startDate,
          endDate,
          timeZone,
        );
        if (eventallData) {
          forOpeningVisitFromWater();
        }
        setEvents(eventallData);
        await genarateEvents(eventallData);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const initiateWeek = async (calanderRef) => {
    const calendarApi = calendarComponentRef.current.getApi();
    // const startDate = calendarApi.view.activeStart;
    // const endDate = calendarApi.view.activeEnd;

    //  time zone start
    const startDate2 = calendarApi.view.activeStart;
    const endDate2 = calendarApi.view.activeEnd;

    const startDate = startDate2.toLocaleString("en-US", {
      timeZone,
    });
    const endDate = endDate2.toLocaleString("en-US", {
      timeZone,
    });
    // time zone end

    const activeEndDate = new Date(
      new Date(endDate).setDate(new Date(endDate).getDate() + 7),
    );
    const filterStart =
      moment(new Date(startDate)).format("YYYY-MM-DD") ||
      moment(new Date()).format("YYYY-MM-DD");
    const filterEnd =
      moment(new Date(activeEndDate)).format("YYYY-MM-DD") ||
      moment(new Date().setDate(new Date().getDate() + 7)).format("YYYY-MM-DD");
    localStorage.setItem("date", new Date(filterStart).toISOString());
    localStorage.setItem("enddate", new Date(filterEnd).toISOString());
    let calcdatelabel;
    if (
      new Date(startDate).getMonth() !==
        new Date(new Date(endDate) - 1).getMonth() &&
      new Date(startDate).getFullYear() ===
        new Date(new Date(endDate) - 1).getFullYear()
    ) {
      calcdatelabel = `${moment(startDate).format("MMM")} - ${moment(
        new Date(new Date(endDate) - 1),
      ).format("MMM")}  ${new Date(startDate).getFullYear()} `;
    } else if (
      new Date(startDate).getFullYear() !==
      new Date(new Date(endDate) - 1).getFullYear()
    ) {
      calcdatelabel = `${moment(startDate).format("MMM")} ${new Date(
        startDate,
      ).getFullYear()} - ${moment(new Date(new Date(endDate) - 1)).format(
        "MMM",
      )}  ${new Date(endDate).getFullYear()} `;
    } else {
      calcdatelabel = `${moment(startDate).format("MMMM")} ${new Date(
        startDate,
      ).getFullYear()} `;
    }
    return {
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      calcdatelabel,
    };
  };

  const renderWeeks = (data) => {
    const { date } = data;
    const dt = new Date(date);
    const dt2 = new Date();
    let myTime = dt.toLocaleString("en-US", {
      timeZone,
    });
    let myTime2 = dt2.toLocaleString("en-US", {
      timeZone,
    });

    // console.log({ myTime, myTime2, date });
    // console.log('one time=>', moment(new Date(myTime)).format('ddd'));
    // console.log('two time=>', moment(new Date(myTime2)).format('YYYY-MM-DD'));

    const test =
      moment(new Date(myTime)).format("YYYY-MM-DD") ===
      moment(new Date(myTime2)).format("YYYY-MM-DD")
        ? "selected"
        : "";

    const jsx = (
      <div
        className={`grid-box ${
          new Date(myTime).toLocaleDateString() ===
          new Date().toLocaleDateString()
            ? "selected"
            : ""
        }`}
      >
        <span>
          {filterType === "timeGridWeek"
            ? moment(new Date(myTime)).format("ddd")
            : moment(myTime).format("dddd")}
        </span>
        <h5>{moment(myTime).format("D")}</h5>
      </div>
    );

    // const jsx = (
    //   <div
    //     className={`grid-box ${new Date(date).toLocaleDateString()
    //         === new Date().toLocaleDateString()
    //       ? 'selected'
    //       : ''
    //     }`}
    //   >
    //     <span>
    //       {filterType === 'timeGridWeek'
    //         ? date.toString().substring(0, 3)
    //         : moment(date).format('dddd')}
    //     </span>
    //     <h5>{moment(date).format('D')}</h5>
    //   </div>
    // );
    return jsx;
  };

  const updateWeek = async (forward) => {
    let staff_Id = localStorage.getItem("staff_id");
    const currentStartDate = calendarData.startDate;
    const currentEndDate = calendarData.endDate;

    // const currentStartDate2 = calendarData.startDate;
    // const currentEndDate2 = calendarData.endDate;

    // const currentStartDate = currentStartDate2.toLocaleString('en-US', {
    //   timeZone,
    // });
    // const currentEndDate = currentEndDate2.toLocaleString('en-US', {
    //   timeZone,
    // });

    if (!forward) {
      if (view === "timeGridDay") {
        currentStartDate.setDate(currentStartDate.getDate() - 1);
      } else if (view === "timeGridThreeDay") {
        currentStartDate.setDate(currentStartDate.getDate() - 3);
      } else {
        currentStartDate.setDate(currentStartDate.getDate() - 7);
      }
    }
    const dateToGo = forward ? currentEndDate : currentStartDate;
    const calendarApi = calendarComponentRef.current.getApi();
    const date = moment(dateToGo).format("YYYY-MM-DD");
    calendarApi.gotoDate(date);
    await initiateCalendar();
    await getStaffAppointment(staff_Id);
    await SelectedStaff(staff_id);
  };
  const updateMonth = async (forward) => {
    let staff_Id = localStorage.getItem("staff_id");
    const currentStartDate = calendarData.startDate;
    const currentEndDate = calendarData.endDate;
    if (!forward) {
      if (view === "timeGridDay" || view === "timeGridThreeDay") {
        currentStartDate.setDate(currentStartDate.getDate() - 7);
      } else {
        currentStartDate.setMonth(currentStartDate.getMonth() - 1);
      }
    } else if (view === "timeGridDay" || view === "timeGridThreeDay") {
      currentEndDate.setDate(currentStartDate.getDate() + 7);
    } else {
      currentEndDate.setMonth(currentEndDate.getMonth() + 1);
    }
    const dateToGo = forward ? currentEndDate : currentStartDate;
    const calendarApi = calendarComponentRef.current.getApi();
    const date = moment(dateToGo).format("YYYY-MM-DD");
    calendarApi.gotoDate(date);
    await initiateCalendar();
    await getStaffAppointment(staff_Id);
    // await SelectedStaff(staff_id);
  };

  const updateYear = async (forward) => {
    let staff_Id = localStorage.getItem("staff_id");
    let currentStartDate = calendarData.startDate;
    let currentEndDate = calendarData.endDate;
    if (!forward) {
      if (view === "timeGridDay" || view === "timeGridThreeDay") {
        currentStartDate.setMonth(currentStartDate.getMonth() - 1);
      } else {
        currentStartDate.setFullYear(currentStartDate.getFullYear() - 1);
      }
    } else if (view === "timeGridDay" || view === "timeGridThreeDay") {
      currentEndDate.setMonth(currentStartDate.getMonth() + 1);
    } else {
      currentEndDate.setFullYear(currentEndDate.getFullYear() + 1);
    }
    const dateToGo = forward ? currentEndDate : currentStartDate;
    const calendarApi = calendarComponentRef.current.getApi();
    const date = moment(dateToGo).format("YYYY-MM-DD");
    calendarApi.gotoDate(date);
    await initiateCalendar();
    await getStaffAppointment(staff_Id);
    // await SelectedStaff(staff_id);
  };
  const handleCalendarChange = async (e) => {
    const calendarApi = calendarComponentRef.current.getApi();
    const date = moment(e).format("YYYY-MM-DD");
    calendarApi.gotoDate(date);
    await initiateCalendar();
    handleShowCalendar();
    await loadAppComponentData();
  };
  const [eventDateTime, setEventDateTime] = useState("");
  const handleDateClick = (e) => {
    const startDate = e.dateStr;
    const date = getTimeZone(calendarSettings?.timeZone, startDate);
    // console.log('---->aaaaaaa', date);

    // console.log('jj', startDate);

    // const eventDate2 = e.event._instance
    //   ? e.event.startStr : e.event.start ? e.event.start : new Date();

    if (type === "Rebook") {
      let { staffId } = stateRef.current || {};
      window.location.href = `/addappointment?startDateTime=${startDate}&employee_id=${staffId}&appId=${Id}`;
    } else if (type === "Reschedule") {
      RescheduleAppoitment(Id, startDate);
    } else {
      setEventPopup(!eventPopup);
      setEventDateTime(e.dateStr);
    }
  };
  const [singleMsg, setSingleMsg] = useState("");
  const [openSingleReschedule, setOpenSingleReschedule] = useState(false);
  const RescheduleAppoitment = async (info1, info2) => {
    // console.log('info----->', { info1, info2 });
    // const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
    // const timeFromDateFormat = 'HH:mm';
    // const date = moment(info2).format(dateFormat);

    const response = await notTimeOutUserInstance().get(
      `/appointment/getAppointmentById?id=${info1}`,
    );

    const { msg } = response?.data?.appointmentdetail?.dateData[0];
    if (msg === "One-off appointment | Doesn't repeat") {
      setSingleMsg(msg);
    }
    // console.log('response', response);
    const activeDate = localStorage.getItem("activedate");
    const activeIndex = localStorage.getItem("activeIndex");
    const { appointmentdetail } = JSON.parse(JSON.stringify(response.data));
    const {
      dateData,
      repeats,
      chemicalsArray,
      timeZone: dbTimeZoneName,
    } = appointmentdetail || {};
    const timeZoneVal = dbTimeZoneName || timeZone;
    setDbTimeZone(timeZoneVal);

    // console.log('appointmentdetail =>', appointmentdetail);

    const date = info2;
    // console.log('timeZoneVal, date=>', timeZoneVal, date);
    let defulttime = displayTime(timeZoneVal, date, "HH:mm"); // moment(info2).format(timeFromDateFormat).split(':');
    let setDefaultTime = defulttime || "9:30";

    // console.log('copyResss==========>', rescheduleData);
    let copyRescheduleData = JSON.parse(JSON.stringify(rescheduleData));
    copyRescheduleData.dateData = dateData;
    copyRescheduleData.date = date;
    copyRescheduleData.setDefaultTime = setDefaultTime;
    copyRescheduleData.repeats = repeats;
    copyRescheduleData.info1 = info1;
    copyRescheduleData.activedate = activeDate;
    // console.log('------------>', { copyRescheduleData });
    // return;
    setReschduleChemicalList(chemicalsArray);
    setRescheduleData(copyRescheduleData);
    setSingleMsg(dateData[0].endType);
    setAppEndType(dateData[0].interval_type);
    if (dateData[0].interval_type) {
      const repeatsarrsize = repeats.length - 1;
      if (activeIndex > 0) {
        if (repeatsarrsize === parseInt(activeIndex, 10)) {
          setSingleMsg("");
          setOpenConfirmBox(true);
        } else {
          setOpenConfirmBox(true);
        }
      } else if (repeats.length === 1) {
        console.log("res1");
        await UpdateRescheduleAppoitment(
          dateData,
          date,
          setDefaultTime,
          repeats,
          info1,
          activeDate,
          "repeats",
        );
      } else {
        setOpenConfirmBox(true);
      }
    } else {
      setOpenConfirmBox(true);
    }
  };
  const UpdateRescheduleAppoitment = async (
    appDateData,
    date,
    setDefaultTime,
    repeats,
    info1,
    // eslint-disable-next-line no-shadow
    activedate,
    // eslint-disable-next-line no-shadow
    type,
    rescheduleType,
    groupAppointmentsArray,
  ) => {
    // console.log('data------>', {
    //   appDateData,
    //   date,
    //   setDefaultTime,
    //   repeats,
    //   info1,
    //   activedate,
    //   type,
    //   rescheduleType,
    //   groupAppointmentsArray,
    // });
    // console.log('rescheduleType', { rescheduleType, date });
    const dateData = JSON.parse(JSON.stringify(appDateData));
    let newDateArray = JSON.parse(JSON.stringify(dateData));
    const oldStaffId = dateData[0].service[0].staffId;

    // const newDateTimeISO = moment(date).format();
    // const newDateTimeWithOffset = moment(date).utcOffset('+00:00').format();

    const newDateTimeISO = date.split("T")[0];
    newDateArray[0].date = newDateTimeISO;
    newDateArray[0].endsAt = newDateTimeISO;
    const timeZoneName = dbTimeZone || timeZone;
    // Update service with new date and time
    let newService = updateServerDateTime(
      dateData[0].service,
      date,
      setDefaultTime,
      timeZoneName,
    );
    // console.log('newService=>', newService);
    newDateArray[0].service = newService;
    const lastServTime = newService[newService.length - 1];
    const newChemicalList = updateReschduleChemicals(
      reschduleChemicalList,
      lastServTime,
    );

    const activeIndex = localStorage.getItem("activeIndex");
    // console.log('activeIndex=>', activeIndex);
    const upcomingArr = repeats.slice(activeIndex);
    // console.log('upcomingArr=>', upcomingArr);
    let oldRepeated = repeats.length - upcomingArr.length;
    let oldLastdate = "";
    if (oldRepeated === 0) {
      oldLastdate = repeats[oldRepeated].date;
    } else {
      oldLastdate = repeats[oldRepeated - 1].date;
    }
    newDateArray[0].occurenceMsg = `After ${upcomingArr.length} times`;
    newDateArray[0].occurence = upcomingArr.length;
    const msgIntervalType =
      newDateArray[0].interval_type === "weekly"
        ? "weeks"
        : newDateArray[0].interval_type === "monthly"
          ? "months"
          : "days";
    const { eventEndDate } = timeIntervalDay(
      dateData[0].occurence,
      dateData[0].interval_multiplier,
      dateData[0].interval_type,
      dateData[0].endType,
      date,
      dateData[0].specificDate,
    );
    // console.log('eventEndDate======>', eventEndDate);
    let {
      newmsg,
      oldmsg,
      oldoccurenceMsg,
      oldendType,
      oldinterval_multitplier,
      oldinterval_type,
      oldfrequencyMsg,
      oldoccurence,
      // StartDates,
    } = RescheduleUpdateMsg(
      newDateArray,
      msgIntervalType,
      eventEndDate,
      oldLastdate,
      oldRepeated,
      activeIndex,
      rescheduleType,
      repeats,
    );
    newDateArray[0].msg = newmsg;
    const rescheduleDate = newDateTimeISO; // moment(date).format('YYYY-MM-DD');
    const actDate = new Date(
      convertDateTime(activedate).format("YYYY-MM-DD"),
    ).toISOString(); // moment(activedate).format('YYYY-MM-DD');

    if (oldRepeated === 1) {
      oldmsg = "One-off appointment | Doesn't repeat";
      oldoccurenceMsg = null;
      oldendType = null;
      oldinterval_multitplier = null;
      oldinterval_type = null;
      oldfrequencyMsg = "";
    }
    let updategrupeApp = "";
    if (rescheduleType === "upcomming") {
      if (
        groupAppointmentsArray.length > 1 &&
        (groupAppointmentsArray[0].appointment_calc_type ===
          "Black-Spot Algae" ||
          groupAppointmentsArray[0].appointment_calc_type === "Green Pool" ||
          groupAppointmentsArray[0].appointment_calc_type === "Drain Pool" ||
          groupAppointmentsArray[0].appointment_calc_type === "Water Testing" ||
          groupAppointmentsArray[0].appointment_calc_type ===
            "Sand Filter Media Replacement")
      ) {
        updategrupeApp = updategrupeGrupeApp(groupAppointmentsArray, date);
      }
      const payload = {
        idd: info1,
        startTimee: setDefaultTime,
        startDate: date,
        createdOn: new Date(),
        specificDate: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
        dataData: newDateArray,
        newService,
        date: actDate,
        type: dateData[0].interval_type,
        repeatstype: type,
        rescheduleType,
        upcomingArr,
        oldmsg,
        oldoccurence,
        oldoccurenceMsg,
        oldendType,
        oldinterval_multitplier,
        oldinterval_type,
        oldfrequencyMsg,
        activeIndex,
        StartDates: rescheduleDate, // new Date(rescheduleDate).toISOString(),
        oldStaffId,
        newSatffID: localStorage.getItem("staff_id"),
        newStaffName: localStorage.getItem("staff_name"),
        newChemicalList,
        notifyCustomer,
        updategrupeApp,
        industryId: industryId?._id,
      };
      // console.log('payload1--------->', { payload });
      // return;
      const result = await notTimeOutUserInstance().post(
        "/appointment/rescheduleAppointment",
        payload,
      );
    } else {
      newDateArray[0].frequencyMsg = "";
      newDateArray[0].interval_multiplier = null;
      newDateArray[0].interval_type = null;
      newDateArray[0].msg = "One-off appointment | Doesn't repeat";
      newDateArray[0].occurence = null;
      newDateArray[0].endType = null;
      newDateArray[0].occurenceMsg = "";
      let newdate = new Date(date).setHours(0, 0, 0, 0);
      let newrepeats = repeats[0];
      newrepeats.date = moment(newdate).format("YYYY-MM-DDTHH:mm:ssZ");
      const payload = {
        idd: info1,
        startTimee: setDefaultTime,
        startDate: date,
        createdOn: new Date(),
        specificDate: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
        dataData: newDateArray,
        newService,
        date: actDate,
        type: dateData[0].interval_type,
        repeatstype: type,
        rescheduleType,
        upcomingArr,
        oldmsg,
        oldoccurence,
        oldoccurenceMsg,
        oldendType,
        oldinterval_multitplier,
        oldinterval_type,
        oldfrequencyMsg,
        activeIndex,
        StartDates: rescheduleDate,
        oldStaffId,
        newSatffID: localStorage.getItem("staff_id"),
        newStaffName: localStorage.getItem("staff_name"),
        newChemicalList,
        notifyCustomer,
        industryId: industryId?._id,
      };
      // console.log('payload2--------->', { payload });
      const result = await notTimeOutUserInstance().post(
        "/appointment/rescheduleAppointment",
        payload,
      );
    }

    setRescheduleData(rescheduleInitialState);
    setOpenConfirmBox(false);
    history.push(getCalendarPath());
    await getStaffAppointment(localStorage.getItem("staff_id"));
    // NotificationManager.success('Pool Maintenance rescheduled successfully', 'Message', successMsgTime);
    toastMessage("success", "Pool Maintenance rescheduled successfully");
  };
  const handleHeader = () => {
    setShowHeader(true);
  };
  const handleHideHeader = () => {
    setShowHeader(!showTeam);
  };

  const handleShowTeam = () => {
    setShowTeam(!showTeam);
    // handleLocationSelect('');
  };
  const handleCloseClient = () => {
    setShowTeam(false);
  };
  const genarateEvents = async (eventData, viewtype) => {
    var calendarEl = document.getElementsByClassName(
      "fc fc-media-screen fc-direction-ltr",
    )[0];
    calender =
      (calendarEl,
      {
        initialView: viewtype || "timeGridWeek",
        timeZone: timeZone || "local",
        dayCellClassNames: "testclass",
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        height: "auto",
        slotLabelFormat: {
          hour: "numeric",
          minute: "2-digit",
          meridiem: "short",
          hour12: timeDisplay !== "HH:mm A",
        },
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "timeGridWeek,timeGridDay",
        },
        views: {
          timeGridThreeDay: {
            type: "timeGrid",
            duration: { days: 3 },
            buttonText: "3 day",
          },
        },
        editable: true,
        selectable: true,
        dayMaxEvents: true,
        allDaySlot: false,
        slotDuration: "00:15:00",
        slotLabelInterval: "01:00",
        slotMaxTime: "24:00:00",
        weekends: state.weekendsVisible,
        events: eventData,
        eventBackgroundColor: "#fff",
        longPressDelay: 1000,
        dayHeaderContent: (data) => renderWeeks(data),
        firstDay: 1,
        select: handleDateSelect,
        eventContent: renderEventContent, // custom render function
        eventClick: handleEventClick,
        eventsSet: handleEvents,
        dateClick: handleDateClick,
        dropable: true,
        dragScroll: true,
      });
    await initiateCalendar();
  };
  const getStaffAppointment = async (id, typeEdit) => {
    let startDate = localStorage.getItem("date");
    let endDate = localStorage.getItem("enddate");
    const response = await userInstance().get(
      `/staff/appointmentByStaff?id=${id}&startDate=${startDate}&endDate=${endDate}`,
    );
    const { code, appointments, blocktimes } = response.data;
    setappointmentCopy(appointments);
    setblockTimeCopy(blocktimes);
    const eventallData = newappointmentEventCreate(
      appointments,
      blocktimes,
      secondcolor,
      color,
      startDate,
      endDate,
      timeZone,
    );
    setEvents(eventallData);
    await genarateEvents(eventallData);

    if (typeEdit) {
      setEditStatus(true);
    }
  };

  const SelectedStaff = async (id) => {
    localStorage.setItem("staff_id", id);
    let startDate = localStorage.getItem("date");
    let endDate = localStorage.getItem("enddate");
    const filterStart =
      moment(new Date(startDate)).format("YYYY-MM-DD") ||
      moment(new Date()).format("YYYY-MM-DD");
    const filterEnd =
      moment(new Date(endDate)).format("YYYY-MM-DD") ||
      moment(new Date().setDate(new Date().getDate() + 7)).format("YYYY-MM-DD");
    setAppointmentDetail(false);
    const fsdate = new Date(filterStart).toISOString();
    const fenddate = new Date(filterEnd).toISOString();
    const response = await notTimeOutUserInstance().get(
      `/staff/getAppointmentsByStaff?id=${id}&startDate=${fsdate}&endDate=${fenddate}`,
    );

    let { code, staff } = response.data;
    if (code === 200) {
      let {
        appointments,
        firstname,
        lastname,
        // streetAddress,
        location,
        _id,
        blocktimes,
        shift,
      } = staff || {};
      history.push(getCalendarPath());
      // eslint-disable-next-line no-shadow
      const { locationName } = staff.location || {};
      if (firstname || lastname) {
        localStorage.setItem("staff_name", `${firstname} ${lastname}`);
      }
      setActiveStaff({
        firstname,
        lastname,
        streetAddress: locationName,
        location: "",
        staffId: _id,
        shift,
      });
      setappointmentCopy(appointments);
      setblockTimeCopy(blocktimes);
      const eventallData = newappointmentEventCreate(
        appointments,
        blocktimes,
        secondcolor,
        color,
        filterStart,
        filterEnd,
        timeZone,
      );

      setEvents(eventallData);
      await genarateEvents(eventallData);
      handleCloseClient();
      setEditStatus(true);
    }
  };
  const handleCustomeView = async (types, active) => {
    let newDate = localStorage.getItem("date");
    localStorage.setItem("view", types);
    let staff_Id = localStorage.getItem("staff_id");
    let date = localStorage.getItem("date");
    let calendarUrl = `/calendar?activedate=${
      date
        ? moment(new Date(date)).format("YYYY-MM-DD")
        : moment(new Date()).format("YYYY-MM-DD")
    }&view=${types || "timeGridWeek"}&staff_id=${staff_Id || ""}`;
    history.push(calendarUrl);
    calendarComponentRef.current.getApi().changeView(types, new Date());
    await genarateEvents(events, types);
    setFilterType(active);
    setCustomView(types);
    handleCloseClient();
  };

  const handleTimeSlot = async (types, value, value2) => {
    setFilterTime(types);
    setSlotDuration(value);
    setSlotValue(value2);
    localStorage.setItem("duration", value);
    localStorage.setItem("slot", value2);
    handleCloseClient();
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === "popup-section") {
            setShowTeam(false);
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);
  useEffect(() => {
    const LoadWithData = async () => {
      await loadAppComponentData();
    };
    LoadWithData();
  }, []);
  useEffect(() => {
    if (window !== undefined && window.screen.width <= 768) {
      window.addEventListener("scroll", watchScroll);
    }
    return () => {
      window.removeEventListener("scroll", watchScroll);
    };
  }, []);
  const TodayEvents = (date) => {
    isNavClick.current = true;
    const filterStart = new Date(
      moment().startOf("isoWeek").format("YYYY-MM-DD"),
    );
    const filterEnd = new Date(
      moment().add(1, "weeks").endOf("isoWeek").format("YYYY-MM-DD"),
    );

    // const curr = new Date();
    // const first = curr.getDate() - curr.getDay();
    // const last = first + 15;
    // const firstday = moment(new Date(curr.setDate(first + 1))).format('YYYY-MM-DD');
    // const lastday = moment(curr.setDate(last)).format('YYYY-MM-DD');
    // const filterStart = firstday;
    // const filterEnd = lastday;

    localStorage.setItem("date", new Date(filterStart).toISOString());
    localStorage.setItem("enddate", new Date(filterEnd).toISOString());
    getStaffAppointment(localStorage.getItem("staff_id"));
    calendarComponentRef.current
      .getApi()
      .changeView(localStorage.getItem("view"), new Date());
    setTimeout(() => {
      var elmnt = document.getElementsByClassName(
        "fc-timegrid-now-indicator-line",
      );
      elmnt[0].scrollIntoView({ behavior: "smooth", block: "center" });
    }, 200);
  };

  useEffect(() => {}, [isNavClick]);

  const handleRescheduledConfirm = async (rescheduleType) => {
    const {
      // eslint-disable-next-line no-shadow
      dateData,
      date,
      setDefaultTime,
      repeats,
      info1,
      activedate,
    } = rescheduleData || {};
    const rep = appEndType ? "repeats" : "norepeats";
    console.log("res2");

    await UpdateRescheduleAppoitment(
      dateData,
      date,
      setDefaultTime,
      repeats,
      info1,
      activedate,
      rep,
      rescheduleType,
      clientAllData.groupAppointments,
    );
  };
  const OpennotificationView = async (id) => {
    setAppointmentDetail(!appointmentDetail);
    const response = await userInstance().get(
      `/appointment/getAppointmentById?id=${id}`,
    );
    const { appointmentdetail, msg, totalBalanceDue } = response.data;
    if (msg === "Success") {
      setEventDate(appointmentdetail.repeats[0].date);
      setCustomerDues(parseFloat(totalBalanceDue).toFixed(2));
      localStorage.setItem("activeIndex", 0);
      setAddedServices(appointmentdetail.dateData[0].service);
      setAppointmentData(appointmentdetail);
    }
  };

  useEffect(() => {
    if (duration && slot) {
      setSlotDuration(duration);
      setSlotValue(slot);
      if (duration === "00:15") {
        setFilterTime("15min");
      }
      if (duration === "00:30") {
        setFilterTime("30min");
      }

      if (duration === "01:00") {
        setFilterTime("1hour");
      }
    }
  }, [duration, slot]);

  return (
    <>
      <Helmet>
        <html
          className={`calendar-main-page ${showTeam ? "calendar-fixed" : ""} ${appType ? "hide-header-footer" : ""}`}
          lang="en"
        />
      </Helmet>
      <AdminWrapper
        showTeam={showTeam}
        TodayEvents={TodayEvents}
        isNavClick={isNavClick}
        OpennotificationView={OpennotificationView}
      >
        <div
          className={`calendar-container new-cal ${isSticky} ${
            showTeam ? "open-client-popup" : "close-client-popup"
          }`}
          id="dds"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="demo-app calendar-inner">
                  <div className="demo-app-main main-calendar">
                    <div className="calender-date-section">
                      <div className="calendar-date">
                        <div className="calendar-date-client">
                          <div className="calendar-filter-prev">
                            <div className="calendar-filter-icons">
                              <span>
                                <img
                                  src={left3}
                                  alt="arrow"
                                  onClick={() => updateYear(null)}
                                />
                              </span>
                              <span>
                                <img
                                  src={left2}
                                  alt="arrow"
                                  onClick={() => updateMonth(null)}
                                />
                              </span>
                              <span>
                                <img
                                  src={left1}
                                  alt="arrow"
                                  onClick={() => updateWeek(null)}
                                />
                              </span>
                            </div>
                          </div>
                          <div
                            className="date"
                            onClick={handleShowCalendar}
                            role="presentation"
                          >
                            {/* {calendarData.currentDate} */}
                            {calendarData.calcdatelabel}
                            <br />{" "}
                            <span>{`Week ${calendarData.currentWeekNo}`}</span>
                          </div>

                          <div className="calendar-filter-next">
                            <div className="calendar-filter-icons">
                              <span>
                                <img
                                  src={right1}
                                  alt="arrow"
                                  onClick={() => updateWeek(true)}
                                />
                              </span>
                              <span>
                                <img
                                  src={right2}
                                  alt="arrow"
                                  onClick={() => updateMonth(true)}
                                />
                              </span>
                              <span>
                                <img
                                  src={right3}
                                  alt="arrow"
                                  onClick={() => updateYear(true)}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="team-member">
                          <p>
                            {activeStaff.firstname
                              ? `User: ${activeStaff.firstname} ${activeStaff.lastname}`
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="calender-option">
                        <img src={aroow} alt="arrow" onClick={handleShowTeam} />
                      </div>
                    </div>
                    <div
                      className={`calendar-view-page ${timeDisplay === "h:mm a" ? "calendar-update-view-page" : " "}`}
                    >
                      <FullCalendar
                        timeZone={timeZone || "local"}
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                          momentTimezonePlugin,
                        ]}
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "timeGridWeek,timeGridDay",
                        }}
                        views={{
                          timeGridThreeDay: {
                            type: "timeGrid",
                            duration: { days: 3 },
                            buttonText: "3 day",
                          },
                        }}
                        initialView={view || "timeGridWeek"}
                        editable
                        selectable
                        selectMirror
                        nowIndicator
                        dayMaxEvents
                        allDaySlot={false}
                        slotDuration={slotValue}
                        slotLabelInterval={slotDuration}
                        slotLabelFormat={{
                          hour: "numeric",
                          minute: "2-digit",
                          meridiem: "short",
                          hour12: timeDisplay !== "HH:mm A",
                        }}
                        dropable
                        weekends={state.weekendsVisible}
                        dragScroll
                        select={handleDateSelect}
                        eventContent={renderEventContent} // custom render function
                        eventClick={handleEventClick}
                        eventsSet={handleEvents}
                        dateClick={handleDateClick}
                        // called after events are initialized/added/changed/removed
                        events={events}
                        eventBackgroundColor="#fff"
                        height="auto"
                        longPressDelay={1000}
                        dayHeaderContent={(data) => renderWeeks(data)}
                        dayCellClassNames="testclass"
                        ref={calendarComponentRef}
                        firstDay={week || 1}
                        initialDate={
                          activedate || new Date(getTimeZone(timeZone))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TeamMember
            wrapperRef={wrapperRef}
            handleCloseClient={handleCloseClient}
            showTeam={showTeam}
            setCalendarColor={setCalendarColor}
            staffList={staffList}
            activeStaff={activeStaff}
            SelectedStaff={SelectedStaff}
            handleCustomeView={handleCustomeView}
            filterType={filterType}
            color={color}
            filterTime={filterTime}
            secondcolor={secondcolor}
            handleLocationPopUp={handleLocationPopUp}
            handleTimeSlot={handleTimeSlot}
          />
        </div>
      </AdminWrapper>
      <CalendarModal
        showCalendar={showCalendar}
        setShowCalendar={setShowCalendar}
        handleCalendarChange={handleCalendarChange}
      />
      {appointmentDetail && (
        <Viewappointment
          appointmentDetail={appointmentDetail}
          setAppointmentDetail={setAppointmentDetail}
          handleAppointmentDetails={handleAppointmentDetails}
          appointmentData={appointmentData}
          addedServices={addedServices}
          setAddedServices={setAddedServices}
          comingClientId={clientid}
          // getAppointments={handleEventClick}
          setRandom={setRandom}
          eventDate={eventDate}
          SelectedStaff={getStaffAppointment}
          activeStaff={activeStaff}
          customerDues={customerDues}
          setCustomerDues={setCustomerDues}
          clientid={clientid}
          finaliseState={finaliseState}
          forManagementEdit={forManagementEdit}
          setForManagementEdit={setForManagementEdit}
          setEventDate={setEventDate}
          chemicaltype={chemicaltype}
        />
      )}
      {eventPopup && (
        <Event
          eventDateTime={eventDateTime}
          eventPopup={eventPopup}
          setEventPopup={setEventPopup}
          blockedtime={blockedtime}
          setBlockedtime={setBlockedtime}
          activeStaff={activeStaff}
        />
      )}
      {openConfirmBox && (
        <ConfirmPopupApp
          openConfirmBox={openConfirmBox}
          setOpenConfirmBox={setOpenConfirmBox}
          handleEditAppointment={handleRescheduledConfirm}
          singleMsg={singleMsg}
          notifyCustomer={notifyCustomer}
          setNotifyCustomer={setNotifyCustomer}
          groupAppointments={clientAllData.groupAppointments || []}
        />
      )}
      {locationPopUp && (
        <LocationChangePopUp
          locationPopUp={locationPopUp}
          setLocationPopUp={setLocationPopUp}
          staffLocation={staffLocation}
          handleLocationPopUpChange={handleLocationPopUpChange}
          locationName={locationName}
          // handleLocationSelect={handleLocationSelect}
          handleLocationPopUp={handleLocationPopUp}
        />
      )}
    </>
  );
}

export default StaffCalendar;
