import React from "react";
import PropTypes from "prop-types";
import { FiPlus } from "react-icons/fi";

const AddButton = (props) => {
  const { handleClickBtn } = props;
  return (
    <button
      type="button"
      className="btn btn-outline-secondary"
      style={{
        padding: "4px 24px",
        borderRadius: "6px",
        fontSize: "14px",
        minWidth: "fit-content",
      }}
      onClick={handleClickBtn}
    >
      <FiPlus size={16} className="mr-2 p-0" />
      <span>Add</span>
    </button>
  );
};

AddButton.propTypes = {
  handleClickBtn: PropTypes.func.isRequired,
};

export default AddButton;
