/* eslint-disable react/no-unescaped-entities */
import React, { useCallback } from "react";
import { Form, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import {
  LoadExistValue,
  limitInputValues,
  displayCacliPrices,
} from "./calculatorUtils";

const CalculatorReadings = (props) => {
  const {
    result,
    calVariable,
    poolInputs,
    handleInputs,
    chlorinationType,
    manualValue,
    waterTestOptions,
    greenSplitName = "",
    allFields,
    validationData,
    limitInp,
    valueType,
    calciDisplayChemicals,
    showFullHardness,
    blankInputFields,
    showCalcium,
    titrationPopupOptions,
    allOk,
    handleSwimAlert,
  } = props;

  const showMessage =
    !calciDisplayChemicals?.salt &&
    calVariable?.calciumHardnessMsg.includes("%");

  const {
    salt: disSalt,
    phosphates: disPhos,
    copper: disCopper,
    calciumHardness: disCalcium,
    totalChlorine: disTotalChlorine,
  } = calciDisplayChemicals;

  const isShowWaterLevel =
    greenSplitName === "Part" &&
    Number(poolInputs?.drainPercentWaterLevelLowered) > 0 &&
    poolInputs?.drainPercentWaterLevelLowered === calVariable?.maxDrain;

  const TotalAmount = calVariable?.totalCost;
  // const sixPadStrip =  poolInputs.Box,""
  const showingReadings = (cost, msg, checkString) => {
    let newCost = cost;
    if (newCost === "N/A") {
      if (
        showCalcium &&
        msg &&
        (msg.includes(checkString) || msg.includes("Drain same %"))
      ) {
        newCost = 0;
      }
      if (!result) {
        newCost = 0;
      }
    }
    return newCost;
  };

  return (
    <>
      <Form.Group className="ideal-reading">
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label className="empty" />
          </Col>
          <Col>
            <div className="custom-btn">
              <Button>Ideal</Button>
            </div>
          </Col>
          <Col>
            <div className="custom-btn">
              <Button>Reading</Button>
            </div>
          </Col>
          {result ? (
            <Col>
              <div className="custom-btn">
                <Button className="costbtn">Cost</Button>
              </div>
            </Col>
          ) : null}
        </Form.Row>
      </Form.Group>

      <Form.Group className={`${showMessage ? "show-message-grp" : ""}`}>
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label className="target full-width">Free Chlorine</Form.Label>
          </Col>
          <Col>
            <Form.Label className="target">
              {calVariable?.chlorine} ppm
            </Form.Label>
          </Col>
          <Col>
            {calVariable?.costChroline === "N/A" ? (
              <input
                type="text"
                value="N/A"
                className="form-control"
                disabled
              />
            ) : (
              <NumberFormat
                type="text"
                name="chlorine"
                value={
                  !poolInputs?.chlorine && result ? "0" : poolInputs?.chlorine
                }
                onChange={handleInputs}
                disabled={result}
                className={`${
                  validationData?.chlorine && !poolInputs?.chlorine
                    ? "error-text"
                    : ""
                } form-control`}
                isAllowed={(inputObj) =>
                  limitInputValues(inputObj, limitInp?.chlorine)
                }
              />
            )}
          </Col>
          {result ? (
            <Col>
              <div className="cost">
                {displayCacliPrices(calVariable?.costChroline, "$")}
              </div>
            </Col>
          ) : null}
          {result && calVariable?.chlorineMsg ? (
            <div
              className={`showdata ${
                calVariable?.costChroline === "N/A" ? "drainBg" : ""
              }`}
            >
              {calVariable?.chlorineMsg}
            </div>
          ) : null}
        </Form.Row>
      </Form.Group>

      {calciDisplayChemicals?.totalChlorine && (
        <Form.Group className={`${showMessage ? "show-message-grp" : ""}`}>
          <Form.Row>
            <Col column="xs" md={7} xs={5}>
              <Form.Label className="target full-width">
                {`${result ? "Combined" : "Total"} Chlorine`}
              </Form.Label>
            </Col>
            <Col>
              <Form.Label className="target">
                {poolInputs?.chlorine
                  ? greenSplitName === "Drain" && poolInputs?.chlorine === "0"
                    ? calVariable?.chlorine
                    : poolInputs?.chlorine
                  : calVariable?.chlorine}{" "}
                ppm
              </Form.Label>
            </Col>
            <Col>
              {result &&
              showingReadings(
                calVariable?.costCombinedChlorine,
                calVariable?.combinedChlorineMsg,
                "High Chlorine",
              ) === "N/A" ? (
                <input
                  type="text"
                  value="N/A"
                  className="form-control"
                  disabled
                />
              ) : (
                <NumberFormat
                  type="text"
                  name="combinedChlorine"
                  value={
                    result
                      ? parseFloat(poolInputs?.combinedChlorine) > 0
                        ? poolInputs?.newCombinedChlr
                        : "0"
                      : poolInputs?.combinedChlorine
                  }
                  onChange={handleInputs}
                  disabled={result}
                  className={`${
                    validationData?.combinedChlorine &&
                    !poolInputs?.combinedChlorine
                      ? "error-text"
                      : ""
                  } form-control`}
                  isAllowed={(inputObj) =>
                    limitInputValues(inputObj, limitInp?.combinedChlorine)
                  }
                />
              )}
            </Col>
            {result ? (
              <Col>
                <div className="cost">
                  {displayCacliPrices(calVariable?.costCombinedChlorine, "$")}
                </div>
              </Col>
            ) : null}

            {result && calVariable?.combinedChlorineMsg ? (
              <>
                <div
                  className={`showdata ${
                    calVariable?.combinedChlorineMsg
                      ?.toLowerCase()
                      .includes("%") ||
                    calVariable?.costCombinedChlorine === "N/A"
                      ? "drainBg"
                      : ""
                  }`}
                >
                  {calVariable?.combinedChlorineMsg}
                </div>
                {/* {showMessage && (
              <p className="show-message">Requires Salt reading to display Calcium & drain %</p>
              )} */}
              </>
            ) : null}
          </Form.Row>
        </Form.Group>
      )}

      <Form.Group className={`${showMessage ? "show-message-grp" : ""}`}>
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label className="target full-width">pH</Form.Label>
          </Col>
          <Col>
            <Form.Label className="target">{calVariable?.ph}</Form.Label>
          </Col>
          <Col>
            {calVariable?.costPh === "N/A" ? (
              <input
                type="text"
                value="N/A"
                className="form-control"
                disabled
              />
            ) : (
              <NumberFormat
                type="text"
                name="ph"
                value={
                  !poolInputs?.ph && result ? calVariable?.ph : poolInputs?.ph
                }
                onChange={handleInputs}
                disabled={result}
                className={`${
                  validationData?.ph && !poolInputs?.ph ? "error-text" : ""
                } form-control`}
                isAllowed={(inputObj) =>
                  limitInputValues(inputObj, limitInp?.ph)
                }
              />
            )}
          </Col>
          {result ? (
            <Col>
              <div className="cost">
                {displayCacliPrices(calVariable?.costPh, "$")}
              </div>
            </Col>
          ) : null}
          {result && calVariable?.phMsg ? (
            <>
              <div
                className={`showdata ${
                  calVariable?.costPh === "N/A" ? "drainBg" : ""
                }`}
              >
                {calVariable?.phMsg}
              </div>
              {/* {showMessage && (
              <p className="show-message">Requires Salt reading to display Calcium & drain %</p>
              )} */}
            </>
          ) : null}
        </Form.Row>
      </Form.Group>

      <Form.Group className={`${showMessage ? "show-message-grp" : ""}`}>
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label className="target full-width">
              Total Alkalinity (TA)
            </Form.Label>
          </Col>
          <Col>
            <Form.Label className="target">
              {calVariable?.alkalinity} ppm
            </Form.Label>
          </Col>
          <Col>
            {calVariable?.costAlkalinity === "N/A" ? (
              <input
                type="text"
                value="N/A"
                className="form-control"
                disabled
              />
            ) : (
              <NumberFormat
                type="text"
                name="alkalinity"
                value={
                  !poolInputs?.alkalinity && result
                    ? "0"
                    : poolInputs?.alkalinity
                }
                onChange={handleInputs}
                disabled={result}
                className={`${
                  validationData?.alkalinity && !poolInputs?.alkalinity
                    ? "error-text"
                    : ""
                } form-control`}
                isAllowed={(inputObj) =>
                  limitInputValues(inputObj, limitInp?.alkalinity)
                }
              />
            )}
          </Col>
          {result ? (
            <Col>
              <div className="cost">
                {displayCacliPrices(calVariable?.costAlkalinity, "$")}
              </div>
            </Col>
          ) : null}
          {result && calVariable?.alkMsg ? (
            <>
              <div
                className={`showdata ${
                  calVariable?.costAlkalinity === "N/A" ? "drainBg" : ""
                }`}
              >
                {calVariable?.alkMsg}
              </div>
              {/* {showMessage && (
              <p className="show-message">Requires Salt reading to display Calcium & drain %</p>
              )} */}
            </>
          ) : null}
        </Form.Row>
      </Form.Group>

      <>
        {disCalcium || result ? (
          <Form.Group className={`${showMessage ? "show-message-grp" : ""}`}>
            <Form.Row>
              <Col column="xs" md={7} xs={5}>
                <Form.Label className="target full-width">
                  {result &&
                  (showFullHardness?.calcium ||
                    (!showFullHardness?.calcium &&
                      chlorinationType === "Mineral Pool" &&
                      blankInputFields?.hardCalcium === "") ||
                    (chlorinationType === "Mineral Pool" && showCalcium) ||
                    calVariable?.calciumHardnessMsg ===
                      "Calcium Hardness not tested")
                    ? "Calcium Hardness"
                    : "Total Hardness"}
                </Form.Label>
              </Col>
              <Col>
                <Form.Label className="target">
                  {calVariable?.calciumHardness} ppm
                </Form.Label>
              </Col>
              <Col>
                {showingReadings(
                  calVariable?.costCalcium,
                  calVariable?.calciumHardnessMsg,
                  "High Calcium -",
                ) === "N/A" ? (
                  <input
                    type="text"
                    value="N/A"
                    className="form-control"
                    disabled
                  />
                ) : (
                  <NumberFormat
                    type="text"
                    name="calciumHardness"
                    value={
                      !poolInputs?.calciumHardness && result
                        ? "0"
                        : poolInputs?.calciumHardness
                    }
                    onChange={handleInputs}
                    disabled={result}
                    className={`${
                      validationData?.calciumHardness &&
                      !poolInputs?.calciumHardness
                        ? "error-text"
                        : ""
                    } form-control`}
                    isAllowed={(inputObj) =>
                      limitInputValues(inputObj, limitInp?.calciumHardness)
                    }
                  />
                )}
              </Col>
              {result ? (
                <Col>
                  <div className="cost">
                    {displayCacliPrices(calVariable?.costCalcium, "$")}
                  </div>
                </Col>
              ) : null}
              {result && calVariable?.calciumHardnessMsg ? (
                <>
                  <div
                    className={`showdata ${
                      calVariable?.calciumHardnessMsg
                        ?.toLowerCase()
                        .includes("%") ||
                      calVariable?.calciumHardnessMsg ===
                        "Requires Salt reading for Calcium to display" ||
                      calVariable?.costCalcium === "N/A"
                        ? "drainBg"
                        : ""
                    }`}
                  >
                    {calVariable?.calciumHardnessMsg}
                  </div>
                  {/* {showMessage && (
              <p className="show-message">Requires Salt reading to display Calcium & drain %</p>
              )} */}
                </>
              ) : null}
            </Form.Row>
          </Form.Group>
        ) : null}
        {/* {console.log('clll', showFullHardness?.calcium)} */}
        {showFullHardness?.calcium && !result ? (
          <Form.Group className={`${showMessage ? "show-message-grp" : ""}`}>
            <Form.Row>
              <Col column="xs" md={7} xs={5}>
                <Form.Label className="target full-width">
                  Calcium Hardness
                </Form.Label>
              </Col>
              <Col>
                <Form.Label className="target">
                  {calVariable?.hardCalcium} ppm
                </Form.Label>
              </Col>
              <Col>
                {calVariable?.costCalcium === "N/A" ? (
                  <input
                    type="text"
                    value="N/A"
                    className="form-control"
                    disabled
                  />
                ) : (
                  <NumberFormat
                    type="text"
                    name="hardCalcium"
                    value={
                      !poolInputs?.hardCalcium && result
                        ? "0"
                        : poolInputs?.hardCalcium
                    }
                    onChange={handleInputs}
                    disabled={result}
                    className={`${
                      validationData?.hardCalcium && !poolInputs?.hardCalcium
                        ? "error-text"
                        : ""
                    } form-control`}
                    isAllowed={(inputObj) =>
                      limitInputValues(inputObj, limitInp?.hardCalcium)
                    }
                  />
                )}
              </Col>
              {result ? (
                <Col>
                  <div className="cost">
                    {displayCacliPrices(calVariable?.costCalcium, "$")}
                  </div>
                </Col>
              ) : null}
              {result && calVariable?.calciumHardnessMsg ? (
                <>
                  <div
                    className={`showdata ${
                      calVariable?.calciumHardnessMsg
                        ?.toLowerCase()
                        .includes("%") ||
                      calVariable?.calciumHardnessMsg ===
                        "Requires Salt reading for Calcium to display" ||
                      calVariable?.costCalcium === "N/A"
                        ? "drainBg"
                        : ""
                    }`}
                  >
                    {calVariable?.calciumHardnessMsg}
                  </div>
                  {/* {showMessage && (
              <p className="show-message">Requires Salt reading to display Calcium & drain %</p>
              )} */}
                </>
              ) : null}
            </Form.Row>
          </Form.Group>
        ) : null}
        {showFullHardness?.magnesium && !result ? (
          <Form.Group className={`${showMessage ? "show-message-grp" : ""}`}>
            <Form.Row>
              <Col column="xs" md={7} xs={5}>
                <Form.Label className="target full-width">
                  Magnesium Hardness
                </Form.Label>
              </Col>
              <Col>
                <Form.Label className="target">
                  {calVariable?.hardMagnesium} ppm
                </Form.Label>
              </Col>
              <Col>
                {calVariable?.costCalcium === "N/A" ? (
                  <input
                    type="text"
                    value="N/A"
                    className="form-control"
                    disabled
                  />
                ) : (
                  <NumberFormat
                    type="text"
                    name="hardMagnesium"
                    value={
                      !poolInputs?.hardMagnesium && result
                        ? "0"
                        : poolInputs?.hardMagnesium
                    }
                    onChange={handleInputs}
                    disabled={result}
                    className={`${
                      validationData?.hardMagnesium &&
                      !poolInputs?.hardMagnesium
                        ? "error-text"
                        : ""
                    } form-control`}
                    isAllowed={(inputObj) =>
                      limitInputValues(inputObj, limitInp?.hardMagnesium)
                    }
                  />
                )}
              </Col>
              {result ? (
                <Col>
                  <div className="cost">
                    {displayCacliPrices(calVariable?.costCalcium, "$")}
                  </div>
                </Col>
              ) : null}
              {result && calVariable?.calciumHardnessMsg ? (
                <>
                  <div
                    className={`showdata ${
                      calVariable?.calciumHardnessMsg
                        ?.toLowerCase()
                        .includes("%") ||
                      calVariable?.calciumHardnessMsg ===
                        "Requires Salt reading for Calcium to display" ||
                      calVariable?.costCalcium === "N/A"
                        ? "drainBg"
                        : ""
                    }`}
                  >
                    {calVariable?.calciumHardnessMsg}
                  </div>
                  {/* {showMessage && (
              <p className="show-message">Requires Salt reading to display Calcium & drain %</p>
              )} */}
                </>
              ) : null}
            </Form.Row>
          </Form.Group>
        ) : null}
      </>

      <Form.Group className={`${showMessage ? "show-message-grp" : ""}`}>
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label className="target full-width">
              {valueType ? "Stabiliser (CYA)" : "Cyanuric Acid (CYA)"}
            </Form.Label>
          </Col>
          <Col>
            <Form.Label className="target">
              {calVariable?.stabiliser} ppm
            </Form.Label>
          </Col>
          <Col>
            {showingReadings(
              calVariable?.costStabiliser,
              calVariable?.stabiliserMsg,
              "High Stabiliser -",
            ) === "N/A" ? (
              <input
                type="text"
                value="N/A"
                className="form-control"
                disabled
              />
            ) : (
              <NumberFormat
                type="text"
                name="stabiliser"
                value={
                  !poolInputs?.stabiliser && result
                    ? "0"
                    : poolInputs?.stabiliser
                }
                onChange={handleInputs}
                disabled={result}
                className={`${
                  validationData?.stabiliser && !poolInputs?.stabiliser
                    ? "error-text"
                    : ""
                } form-control`}
                isAllowed={(inputObj) =>
                  limitInputValues(inputObj, limitInp?.stabiliser)
                }
              />
            )}
          </Col>
          {result ? (
            <Col>
              <div className="cost">
                {displayCacliPrices(calVariable?.costStabiliser, "$")}
              </div>
            </Col>
          ) : null}
          {result && calVariable?.stabiliserMsg ? (
            <>
              <div
                className={`showdata ${
                  calVariable?.stabiliserMsg?.toLowerCase().includes("%") ||
                  calVariable?.costStabiliser === "N/A"
                    ? "drainBg"
                    : ""
                }`}
              >
                {calVariable?.stabiliserMsg}
              </div>
              {/* {showMessage && (
              <p className="show-message">Requires Salt reading to display Calcium & drain %</p>
              )} */}
            </>
          ) : null}
        </Form.Row>
      </Form.Group>
      {calciDisplayChemicals?.copper ? (
        <>
          {showFullHardness?.fullhardness ? null : (
            <Form.Group className={`${showMessage ? "show-message-grp" : ""}`}>
              <Form.Row>
                <Col column="xs" md={7} xs={5}>
                  <Form.Label className="target full-width">Copper</Form.Label>
                </Col>
                <Col>
                  <Form.Label className="target">
                    {calVariable?.copper} ppb
                  </Form.Label>
                </Col>
                <Col>
                  {showingReadings(
                    calVariable?.costCopper,
                    calVariable?.copperMsg,
                    "High Copper -",
                  ) === "N/A" ? (
                    <input
                      type="text"
                      value="N/A"
                      className="form-control"
                      disabled
                    />
                  ) : (
                    <NumberFormat
                      type="text"
                      name="copper"
                      value={
                        !poolInputs?.copper && result ? "0" : poolInputs?.copper
                      }
                      onChange={handleInputs}
                      disabled={result}
                      className={`${
                        validationData?.copper && !poolInputs?.copper
                          ? "error-text"
                          : ""
                      } form-control`}
                      isAllowed={(inputObj) =>
                        limitInputValues(inputObj, limitInp?.copper)
                      }
                    />
                  )}
                </Col>
                {result ? (
                  <Col>
                    <div className="cost">
                      {displayCacliPrices(calVariable?.costCopper, "$")}
                    </div>
                  </Col>
                ) : null}
                {result && calVariable?.copperMsg ? (
                  <>
                    <div
                      className={`showdata ${
                        calVariable?.copperMsg?.toLowerCase().includes("%") ||
                        calVariable?.costCopper === "N/A"
                          ? "drainBg"
                          : ""
                      }`}
                    >
                      {calVariable?.copperMsg}
                    </div>
                    {/* {showMessage && (
              <p className="show-message">Requires Salt reading to display Calcium & drain %</p>
              )} */}
                  </>
                ) : null}
              </Form.Row>
            </Form.Group>
          )}
        </>
      ) : null}

      {calciDisplayChemicals?.phosphates ? (
        <Form.Group className={`${showMessage ? "show-message-grp" : ""}`}>
          <Form.Row>
            <Col column="xs" md={7} xs={5}>
              <Form.Label className="target full-width">Phosphates</Form.Label>
            </Col>
            <Col>
              <Form.Label className="target">
                {calVariable?.phosphate} ppb
              </Form.Label>
            </Col>
            <Col>
              {calVariable?.costPhophates === "N/A" ? (
                <input
                  type="text"
                  value="N/A"
                  className="form-control"
                  disabled
                />
              ) : (
                <NumberFormat
                  type="text"
                  name="phosphates"
                  value={
                    !poolInputs?.phosphates && result
                      ? "0"
                      : poolInputs?.phosphates
                  }
                  onChange={handleInputs}
                  disabled={result}
                  className={`${
                    validationData?.phosphates && !poolInputs?.phosphates
                      ? "error-text"
                      : ""
                  } form-control`}
                  isAllowed={(inputObj) =>
                    limitInputValues(inputObj, limitInp?.phosphates)
                  }
                />
              )}
            </Col>
            {result ? (
              <Col>
                <div className="cost">
                  {displayCacliPrices(calVariable?.costPhophates, "$")}
                </div>
              </Col>
            ) : null}
            {result && calVariable?.phosphateMsg ? (
              <>
                <div
                  className={`showdata ${
                    calVariable?.phosphateMsg?.toLowerCase().includes("%") ||
                    calVariable?.costPhophates === "N/A"
                      ? "drainBg"
                      : ""
                  }`}
                >
                  {calVariable?.phosphateMsg}
                </div>
                {/* {showMessage && (
              <p className="show-message">Requires Salt reading to display Calcium & drain %</p>
              )} */}
              </>
            ) : null}
          </Form.Row>
        </Form.Group>
      ) : null}
      {chlorinationType !== "Manually Chlorinated" ? (
        <>
          <Form.Group className={`${showMessage ? "show-message-grp" : ""}`}>
            {(disSalt || result) && (
              <Form.Row>
                <Col column="xs" md={7} xs={5}>
                  <Form.Label className="target full-width">
                    {/* {chlorinationType === 'Salt Pool' ? 'Salt' : 'Magnesium Chloride'} */}
                    Salt
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Label className="target ppm-label">
                    {`${calVariable?.salt} ppm`}
                  </Form.Label>
                </Col>
                <Col>
                  {showingReadings(
                    calVariable?.costSalt,
                    calVariable?.saltMsg,
                    "High Salt -",
                  ) === "N/A" ? (
                    <input
                      type="text"
                      value="N/A"
                      className="form-control"
                      disabled
                    />
                  ) : (
                    <NumberFormat
                      type="text"
                      name="salt"
                      value={
                        !poolInputs?.salt && result ? "0" : poolInputs?.salt
                      }
                      onChange={handleInputs}
                      disabled={result}
                      className={`${
                        validationData?.salt && !poolInputs?.salt
                          ? "error-text"
                          : ""
                      } form-control`}
                      isAllowed={(inputObj) =>
                        limitInputValues(inputObj, limitInp?.salt)
                      }
                    />
                  )}
                </Col>
                {result ? (
                  <Col>
                    <div className="cost">
                      {/* {calVariable?.costSalt.toFixed(2)} */}
                      {displayCacliPrices(calVariable?.costSalt, "$")}
                    </div>
                  </Col>
                ) : null}
                {result && calVariable?.saltMsg ? (
                  <>
                    <div
                      className={`showdata ${
                        calVariable?.saltMsg?.toLowerCase().includes("%") ||
                        calVariable?.costSalt === "N/A"
                          ? "drainBg"
                          : ""
                      }`}
                    >
                      <span>{calVariable?.saltMsg}</span>
                    </div>
                  </>
                ) : null}
              </Form.Row>
            )}

            {result && calVariable?.saltMsgConvert ? (
              <Form.Row>
                <Col>
                  <div className="any">
                    <div className="cost-of-salt">
                      <Form.Label className="target full-width">
                        Aquatherepe Transform
                      </Form.Label>
                    </div>

                    <div className="cost-of-input">
                      <Form.Control
                        type="text"
                        name="salt"
                        value="4000"
                        disabled
                      />
                    </div>
                    <div className="cost-result">
                      {displayCacliPrices(calVariable?.costSaltConvert, "$")}
                    </div>
                  </div>
                </Col>
                {/* <Col>
                    <div className="cost-result">
                      $
                      {calVariable?.costSaltConvert.toFixed(2)}
                    </div>
                  </Col> */}
              </Form.Row>
            ) : null}

            {result && calVariable?.saltMsgConvert ? (
              <Form.Row>
                <div
                  className={`showdata ${
                    calVariable?.saltMsgConvert?.toLowerCase().includes("%") ||
                    calVariable?.costSaltConvert === "N/A"
                      ? "drainBg"
                      : ""
                  }`}
                >
                  {calVariable?.saltMsgConvert}
                  {/* <span>{saltMsgConvert}</span> */}
                </div>
                {/* {showMessage && (
                  <p className="show-message">Requires Salt reading to display Calcium & drain %</p>
                  )} */}
              </Form.Row>
            ) : null}
          </Form.Group>
        </>
      ) : null}
      {result &&
      (manualValue === "Convert Pool to Mineral Pool" ||
        manualValue.includes("Aquatherepe Transform Maintenance")) ? (
        <Form.Row>
          <Col>
            <div className="any">
              <div className="cost-of-salt">
                <Form.Label className="target full-width">
                  Aquatherepe Transform
                </Form.Label>
              </div>

              <div className="cost-of-input">
                <Form.Control type="text" name="salt" value="N/A" disabled />
              </div>
              <div className="cost-result">
                {displayCacliPrices(calVariable?.costSaltConvert, "$")}
              </div>
            </div>
          </Col>
          <div className="showdata">
            {calVariable?.saltMsgConvert}
            {/* <span>{saltMsgConvert}</span> */}
          </div>
          <div className="salt-msg ">
            <span>*</span> {calVariable?.secondSaltMsgConvert}
          </div>
          {/* <Col>
                    <div className="cost-result">
                      $
                      {calVariable?.costSaltConvert.toFixed(2)}
                    </div>
                  </Col> */}
        </Form.Row>
      ) : null}

      {result &&
      (waterTestOptions === "Water Test + Clarifier & Algaecide" ||
        waterTestOptions === "Water Test + Clarifier") ? (
        <>
          <Form.Group className={`${showMessage ? "show-message-grp" : ""}`}>
            <Form.Row>
              <Col column="xs" md={7} xs={5}>
                <Form.Label className="target full-width">Clarifier</Form.Label>
              </Col>
              <Col>
                <Form.Label className="target">0 ppb</Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  name="clarifier"
                  value="N/A"
                  disabled
                />
              </Col>
              {result ? (
                <Col>
                  <div className="cost">
                    {displayCacliPrices(calVariable?.costClarifierMain, "$")}
                  </div>
                </Col>
              ) : null}
              {result && calVariable?.clarifierMsg ? (
                <>
                  <div
                    className={`showdata ${
                      calVariable?.costFlock === "N/A" ? "drainBg" : ""
                    }`}
                  >
                    {calVariable?.clarifierMsg}
                  </div>
                  {/* {showMessage && (
                    <p className="show-message">Requires Salt reading to display Calcium & drain %</p>
                    )} */}
                </>
              ) : null}
            </Form.Row>
          </Form.Group>
        </>
      ) : null}
      {result &&
      (waterTestOptions === "Water Test + Clarifier & Algaecide" ||
        waterTestOptions === "Water Test + Algaecide" ||
        waterTestOptions === "Water Test + Flock & Algaecide") ? (
        <>
          <Form.Group className={`${showMessage ? "show-message-grp" : ""}`}>
            <Form.Row>
              <Col column="xs" md={7} xs={5}>
                <Form.Label className="target full-width">Algaecide</Form.Label>
              </Col>
              <Col>
                <Form.Label className="target">4000 </Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  name="algaecide"
                  value="N/A"
                  disabled
                />
              </Col>
              {result ? (
                <Col>
                  <div className="cost">
                    {displayCacliPrices(calVariable?.costAlgaecide, "$")}
                  </div>
                </Col>
              ) : null}
              {result && calVariable?.algaecideMsg ? (
                <>
                  <div
                    className={`showdata ${
                      calVariable?.costAlgaecide === "N/A" ? "drainBg" : ""
                    }`}
                  >
                    {calVariable?.algaecideMsg}
                  </div>
                  {/* {showMessage && (
                    <p className="show-message">Requires Salt reading to display Calcium & drain %</p>
                    )} */}
                </>
              ) : null}
            </Form.Row>
          </Form.Group>
        </>
      ) : null}

      {result &&
      (waterTestOptions === "Water Test + Flock & Algaecide" ||
        waterTestOptions === "Water Test + Flock") ? (
        <>
          <Form.Group className={`${showMessage ? "show-message-grp" : ""}`}>
            <Form.Row>
              <Col column="xs" md={7} xs={5}>
                <Form.Label className="target full-width">Flock</Form.Label>
              </Col>
              <Col>
                <Form.Label className="target">4000 </Form.Label>
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  name="algaecide"
                  value="N/A"
                  disabled
                />
              </Col>
              {result ? (
                <Col>
                  <div className="cost">
                    {displayCacliPrices(calVariable?.costFlock, "$")}
                  </div>
                </Col>
              ) : null}
              {result && calVariable?.flockMsg ? (
                <>
                  <div
                    className={`showdata ${
                      calVariable?.costFlock === "N/A" ? "drainBg" : ""
                    }`}
                  >
                    {calVariable?.flockMsg}
                  </div>
                  {/* {showMessage && (
                    <p className="show-message">Requires Salt reading to display Calcium & drain %</p>
                    )} */}
                </>
              ) : null}
            </Form.Row>
          </Form.Group>
        </>
      ) : null}

      {result ? (
        <Form.Group>
          <Form.Row>
            <Col>
              {isShowWaterLevel ? (
                <div className="new-result">
                  <h4 className="cost">
                    {`Drain ${poolInputs?.drainPercentWaterLevelLowered}% of water`}{" "}
                    N/A
                  </h4>
                </div>
              ) : null}
            </Col>
          </Form.Row>
        </Form.Group>
      ) : null}

      {result ? (
        <Form.Group className="page-two-total-cost">
          <Form.Row>
            <Col className="d-flex">
              <div className="show-result">
                <div
                  className={
                    window.location.pathname === "/calculator"
                      ? "total-result-bottom total-result1 d-flex justify-content-between  "
                      : "total-result-bottom total-result1 d-flex justify-content-end"
                  }
                >
                  {window.location.pathname === "/calculator" && (
                    <div
                      className="total-result1 d-flex align-items-center"
                      onClick={() => handleSwimAlert()}
                    >
                      {allOk ? (
                        <h4 className="safe-to-swim text-left ">
                          SAFE TO SWIM
                        </h4>
                      ) : (
                        <h4 className="dont-swim text-left">DON'T SWIM</h4>
                      )}
                    </div>
                  )}{" "}
                  <h4 className="me-3">Total</h4>
                </div>
                <div className="total-amount">
                  <p>${(TotalAmount || 0).toFixed(2)}</p>
                </div>
              </div>
            </Col>
          </Form.Row>
        </Form.Group>
      ) : null}
    </>
  );
};

CalculatorReadings.propTypes = {
  result: PropTypes.bool.isRequired,
  calVariable: PropTypes.objectOf.isRequired,
  poolInputs: PropTypes.func.isRequired,
  handleInputs: PropTypes.func.isRequired,
  chlorinationType: PropTypes.string.isRequired,
  manualValue: PropTypes.string.isRequired,
  waterTestOptions: PropTypes.string.isRequired,
  greenSplitName: PropTypes.string.isRequired,
  validationData: PropTypes.objectOf.isRequired,
  allFields: PropTypes.bool.isRequired,
  limitInp: PropTypes.func.isRequired,
  valueType: PropTypes.bool.isRequired,
  calciDisplayChemicals: PropTypes.func.isRequired,
  showFullHardness: PropTypes.objectOf.isRequired,
  blankInputFields: PropTypes.objectOf.isRequired,
  showCalcium: PropTypes.bool.isRequired,
  titrationPopupOptions: PropTypes.string.isRequired,
  allOk: PropTypes.bool.isRequired,
  handleSwimAlert: PropTypes.func.isRequired,
};

export default CalculatorReadings;
