import * as React from "react";
import { Alert, Modal } from "react-bootstrap";
import { PropTypes } from "prop-types";
import SquareCheckout from "./squareCheckout";

const MyPaymentForm = ({
  openPaymentForm,
  handleToken,
  handleClosePaymentForm,
  paymentResult,
  disableButton,
  amountDisplay,
}) => (
  <>
    <Modal
      size="lg"
      show={openPaymentForm}
      onHide={handleClosePaymentForm}
      aria-labelledby="contained-modal-title-vcenter"
      top
      className="staff-modal"
      data-bs-focus="false"
    >
      <div className="staff-close" onClick={handleClosePaymentForm}>
        <i className="fa fa-times" aria-hidden="true" />
      </div>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Pay Now</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SquareCheckout
          handleToken={handleToken}
          disableButton={disableButton}
          amountDisplay={amountDisplay}
        />
        {paymentResult && (
          <Alert
            variant={
              paymentResult === "Payment Successful" ? "success" : "danger"
            }
          >
            {paymentResult}
          </Alert>
        )}
      </Modal.Body>
    </Modal>
  </>
);
MyPaymentForm.propTypes = {
  openPaymentForm: PropTypes.bool.isRequired,
  handleToken: PropTypes.func.isRequired,
  handleClosePaymentForm: PropTypes.func.isRequired,
  paymentResult: PropTypes.bool.isRequired,
  disableButton: PropTypes.bool.isRequired,
  amountDisplay: PropTypes.isRequired,
};
export default MyPaymentForm;
