import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import showmore from "../../assets/home/showmoreblue.png";
import AppointmentDetail from "./appointmentDetail";
import Appointmentsummary from "./appointmwntSummary";
import CustomerPaymentDetails from "./CustomerPaymentDetails";
import CustomerPaymentDetailsTwo from "./customerPaymentDetailsTwo";

const CustomerPayments = () => {
  const [view, setView] = useState("Customer Payment Summary");
  const renderView = () => {
    let componentToRender = null;
    switch (view) {
      case "Customer Payment Summary":
        componentToRender = <CustomerPaymentDetails />;
        break;
      case "Customer Payment Detail":
        componentToRender = <CustomerPaymentDetailsTwo />;
        break;

      default:
        break;
    }
    return componentToRender;
  };

  const updateView = (key) => {
    setView(key);
  };
  return (
    <div className="reports-appointment pool-set-up search-invoice">
      <div className="target-heading client-profile">
        <>
          <div className="">
            <h3>{view}</h3>
          </div>
          <div className="content-dropdown">
            <div className="client-option service-edit-icon">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={showmore} alt="show" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <div
                      className="action"
                      onClick={() => updateView("Customer Payment Summary")}
                    >
                      <span>Customer Payment Summary</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div
                      className="action"
                      onClick={() => updateView("Customer Payment Detail")}
                    >
                      <span>Customer Payment Detail</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </>
      </div>
      {renderView()}
    </div>
  );
};
export default CustomerPayments;
