import moment from 'moment';
import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

const CurrentSubscription = ({ subscriptionDetails }) => {
  return (
    <div className='target-heading new-client-section'>
      <Accordion className='w-100'>
        <Card.Body>
          <ul className='subscription_billing staff-list top-client-details '>
            <li className='staff-list-item'>
              <div className='billing-box'>
                <h3>Main Contact</h3>
                <h5>{`${subscriptionDetails?.firstname} ${subscriptionDetails?.lastname}`}</h5>
              </div>
            </li>
            <li className='staff-list-item'>
              <div className='billing-box'>
                <h3>Mobile</h3>
                <h5>{subscriptionDetails?.mobileNo}</h5>
              </div>
            </li>
            <li className='staff-list-item'>
              <div className='billing-box'>
                <h3>Email</h3>
                <h5>{subscriptionDetails?.email}</h5>
              </div>
            </li>
            <li className='staff-list-item'>
              <div className='billing-box'>
                <h3>Billing Street Address</h3>
                <h5>{subscriptionDetails?.streetAddress}</h5>
              </div>
            </li>
            <li className='staff-list-item'>
              <div className='billing-box'>
                <h3>Billing Suburb</h3>
                <h5>{subscriptionDetails?.suburb}</h5>
              </div>
            </li>
            <li className='staff-list-item'>
              <div className='billing-box'>
                <h3>Billing Contact Type</h3>
                <h5>House Hoder</h5>
              </div>
            </li>
            <li className='staff-list-item'>
              <div className='billing-box'>
                <h3>Country</h3>
                <h5>{subscriptionDetails?.country} </h5>
              </div>
            </li>
            <li className='staff-list-item'>
              <div className='billing-box'>
                <h3>State</h3>
                <h5>{subscriptionDetails?.state}</h5>
              </div>
            </li>
            <li className='staff-list-item'>
              <div className='billing-box'>
                <h3>Subscription Duration</h3>
                <h5> {subscriptionDetails?.duration
                        ? `${subscriptionDetails?.duration} Months`
                        : "10 Day Trial"}</h5>
              </div>
            </li>
            <li className='staff-list-item'>
              <div className='billing-box'>
                <h3>Start Date</h3>
                <h5>
                  {' '}
                  {moment(subscriptionDetails?.startsubscriptionDate).format(
                    'DD MMM YYYY'
                  )}
                </h5>
              </div>
            </li>
            <li className='staff-list-item'>
              <div className='billing-box'>
                <h3>End Date</h3>
                <h5>
                  {' '}
                  {moment(subscriptionDetails?.endsubscriptionDate).format(
                    'DD MMM YYYY'
                  )}
                </h5>
              </div>
            </li>
            <li className='staff-list-item'>
              <div className='billing-box'>
                <h3>No of Pools</h3>
                <h5>{subscriptionDetails?.numberOfPools}</h5>
              </div>
            </li>
            <li className='staff-list-item'>
              <div className='billing-box'>
                <h3>No of Users</h3>
                <h5>{subscriptionDetails?.numberUsers}</h5>
              </div>
            </li>
            <li className='staff-list-item'>
              <div className='billing-box'>
                <h3>Total Cost</h3>
                <h5>{`${subscriptionDetails.totalCost===0? "Trial": 
                  subscriptionDetails.country === 'Australia' ? 'AUD$' : '$'
                }${subscriptionDetails.totalCost===0? " ":subscriptionDetails.totalCost}`}</h5>
              </div>
            </li>
          </ul>
        </Card.Body>
      </Accordion>
    </div>
  );
};

export default CurrentSubscription;

CurrentSubscription.propTypes = {
  subscriptionDetails: PropTypes.func.isRequired,
};
