import React from "react";
import PropTypes from "prop-types";

const TextArea = (props) => {
  const { label, placeholder, row, value, handleChangeValue } = props;

  return (
    <div className="d-flex flex-column" style={{ width: "100%" }}>
      <label
        htmlFor="chatbot-settings-textarea"
        style={{ fontSize: "14px", margin: 0 }}
      >
        {label}
      </label>
      <textarea
        id="chatbot-settings-textarea"
        placeholder={placeholder}
        style={{
          fontSize: "14px",
          borderRadius: "4px",
          border: "1px solid #ccc",
          width: "100%",
          paddingLeft: "8px",
          paddingRight: "8px",
          resize: "none",
        }}
        rows={row}
        value={value}
        onChange={(e) => handleChangeValue(e.target.value)}
      />
    </div>
  );
};

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  row: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  handleChangeValue: PropTypes.func.isRequired,
};

export default TextArea;
