import React from "react";
import PropTypes from "prop-types";

const TextField = (props) => {
  const { label, placeholder, type, value, handleChangeValue } = props;

  return (
    <div className="d-flex flex-column" style={{ width: "100%" }}>
      <label
        htmlFor="chatbot-settings-textfield"
        style={{ fontSize: "14px", margin: 0 }}
      >
        {label}
      </label>
      <input
        type={type}
        id="chatbot-settings-textfield"
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleChangeValue(e.target.value)}
        style={{
          fontSize: "14px",
          borderRadius: "8px",
          border: "1px solid #ccc",
          width: "100%",
          paddingLeft: "8px",
          paddingRight: "8px",
        }}
      />
    </div>
  );
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChangeValue: PropTypes.func.isRequired,
};

export default TextField;
