/* eslint-disable max-len */
import React, { useContext, useState, useEffect } from "react";
import { Modal, Form, Col, Row, Button, Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import NotificationManager from "react-notifications/lib/NotificationManager";
import flag from "../../assets/admin/aus-flag.png";
import us from "../../assets/admin/us-flag.png";
import { AuthDataContext } from "../../contexts/AuthDataContext";
import {
  currencyComma,
  makeAddressOrderDetail,
  validateDataWithKey,
} from "../../functions";
import { notifyAlert } from "../calculator/calculatorUtils";
import { successMsgTime } from "../../functions/intervalTime";
import toastMessage from "../../functions/toastMessage";
import { countries, states } from "../country-state/country-state";

const OrderDetailsPopup = ({
  show,
  HandleOpenClose,
  orderSummary,
  displayPredictiveChemicals,
  setShowThankyou,
  handleThankyou,
  setShowPayment,
  setUserFormInfo,
}) => {
  const initialOrderData = {
    userName: "",
    propertyAddress: "",
    deliveryAddress: "",
    deliveryAddressOptions: "propertyAddress",
    mobileNo: "",
    streetAddress: "",
    suburb: "",
    postcode: "",
    state: "",
    country: "Australia",
    subTotal: "",
    freight: "15.00",
    totalAmount: "",
  };

  const initialPaymentDetails = {
    cardType: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  };

  const [orderData, setOrderData] = useState(initialOrderData);
  const [paymentDetails, setPaymentDetails] = useState(initialPaymentDetails);
  const [selectedCountry, setCountry] = useState("+61");
  const [error, setError] = useState("");
  const { userData } = useContext(AuthDataContext);

  const [stateArray, setStateArray] = useState([]);
  const [mobileFormat, setMobileFormat] = useState("### ### ###");

  useEffect(() => {
    if (userData?.userInformation) {
      const {
        firstname,
        lastname,
        streetAddress,
        suburb,
        postcode,
        state,
        mobileNo,
        country,
      } = userData?.userInformation || {};
      let address = "";
      let userName = `${firstname || ""} ${lastname || ""}`;
      // if (streetAddress) {
      address = makeAddressOrderDetail({
        streetAddress,
        suburb,
        state,
        postcode,
        country,
      });
      const textToReplace = mobileNo?.includes("+61") ? "+61" : "+1";

      let updateMobileNo = mobileNo
        ? `${mobileNo.replace(textToReplace, "")}`
        : "";

      const overAllAmount =
        Number(orderSummary?.total) + Number(orderData?.freight);
      // }
      setOrderData({
        ...orderData,
        userName,
        mobileNo: updateMobileNo,
        propertyAddress: address,
        subTotal: orderSummary?.total,
        total: overAllAmount.toFixed(2),
      });
      handleCountryStates(country);
    }
    const countryCode = userData?.userInformation?.mobileNo.slice(0, 3);
    console.log("countryCode", countryCode);
    if (countryCode?.trim() === "+61") {
      setMobileFormat("### ### ###");
    }
    if (countryCode?.trim() === "+1") {
      setMobileFormat("### ### ####");
    }

    console.log("this is country===>", countryCode);
  }, [userData?.userInformation?.firstname]);

  const handleChangeOrderForm = (e) => {
    const { value, name } = e.target;
    const copyOrderData = { ...orderData };
    if (name === "postcode" && value.length > 10) {
      return;
    }

    if (name === "country") {
      if (value === "Australia") {
        setMobileFormat("### ### ###");
      }
      if (value === "United States") {
        setMobileFormat("### ### ####");
      }
      handleCountryStates(value);
      copyOrderData.state = "";
    }

    if (name === "deliveryAddressOptions" && value === "differentAddress") {
      const countryName = ["Australia", "United States"].includes(
        userData?.userInformation?.country,
      )
        ? userData?.userInformation?.country
        : "Australia";
      handleCountryStates(countryName);
    }

    copyOrderData[name] = value;
    setOrderData({ ...copyOrderData });
  };

  const handlePaymentDataChange = (e) => {
    const { value, name } = e.target;
    setPaymentDetails({ ...paymentDetails, [name]: value });
  };

  const handleCountry = (countryCode) => {
    const copyOrderData = { ...orderData };
    const textToReplace = copyOrderData?.mobileNo?.includes("+61")
      ? "+61"
      : "+1";
    if (copyOrderData.mobileNo) {
      copyOrderData.mobileNo = `${copyOrderData.mobileNo.replace(
        textToReplace,
        "",
      )}`;
    }
    setOrderData({ ...copyOrderData });
    setCountry(countryCode);
  };

  useEffect(() => {
    if (error) {
      let element = document.querySelector(".error-text");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [error]);

  const handleProcessPayment = () => {
    // console.log('helooo');

    const { streetAddress, suburb, postcode, state, country } =
      userData?.userInformation || {};

    // console.log({ orderData });
    let { userName, mobileNo, propertyAddress, deliveryAddressOptions } =
      orderData;

    let validationObj = {
      "your name": userName,
      mobileNo,
      "property address": propertyAddress,
    };

    // console.log({ deliveryAddressOptions });

    if (deliveryAddressOptions === "differentAddress") {
      validationObj = {
        ...validationObj,
        state: orderData?.state,
        suburb: orderData?.suburb,
        streetAddress: orderData?.streetAddress,
        postcode: orderData?.postcode,
      };
    }

    // console.log({ validationObj });

    const { isValid, emptyField } = validateDataWithKey(validationObj);

    // console.log({ isValid, emptyField });

    if (!isValid) {
      toastMessage("error", `Please Fill ${emptyField}`);
      setError(`${emptyField}`);
      return;
    }

    if (selectedCountry === "+61") {
      if (mobileNo?.trim()?.charAt(0) === "0") {
        mobileNo = mobileNo.replace("0", "");
      }
      if (mobileNo?.trim()?.charAt(0) !== "4") {
        toastMessage("error", "Please start mobile number with 4");
        setError("mobileNo");
        setTimeout(() => {
          setError("");
        }, 1000);
        return;
      }
      if (mobileNo.trim().length !== 11) {
        toastMessage("error", "Enter 9 Digit Mobile Number");
        setError("mobileNo");
        return;
      }
    }
    if (selectedCountry === "+1") {
      if (mobileNo.charAt(0) === "0") {
        mobileNo = mobileNo.replace("0", "");
        return;
      }

      if (mobileNo.trim().length !== 12) {
        toastMessage("error", "Enter 10 Digit Mobile Number");
        setError("mobileNo");
        return;
      }
    }
    setError("");
    let copyOrderData = { ...orderData };
    if (copyOrderData?.deliveryAddressOptions === "propertyAddress") {
      copyOrderData = {
        ...copyOrderData,
        streetAddress,
        suburb,
        postcode,
        state,
        country,
      };
    }

    if (
      !(
        copyOrderData?.mobileNo?.includes("+61") ||
        copyOrderData?.mobileNo?.includes("+1")
      )
    ) {
      copyOrderData.mobileNo = `${selectedCountry}${copyOrderData.mobileNo}`;
    }

    let address = makeAddressOrderDetail({
      streetAddress: copyOrderData.streetAddress,
      suburb: copyOrderData.suburb,
      state: copyOrderData.state,
      postcode: copyOrderData.postcode,
      country: copyOrderData.country,
    });
    copyOrderData.deliveryAddress = address;
    setShowPayment(true);

    setUserFormInfo(orderData);
  };
  const closeOrderDetail = () => {
    HandleOpenClose();
    // handleThankyou();
  };

  // country & State changes
  const handleCountryStates = (compareValue) => {
    const fIndex = countries.findIndex((el) => el.name === compareValue);
    if (fIndex >= 0) {
      const copyStates = JSON.parse(JSON.stringify(states));
      const filteredArray = copyStates.filter(
        (el) => el.countryCode === countries[fIndex].isoCode,
      );
      const list = filteredArray.sort((a, b) => a.name.localeCompare(b.name));
      setCountry(`+${countries[fIndex].phonecode}`);
      setStateArray(filteredArray);
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={HandleOpenClose}
        aria-labelledby="contained-modal-title-vcenter"
        top
        className="staff-modal addClient-modal"
      >
        <div className="staff-close">
          <i
            className="fa fa-times"
            onClick={() => closeOrderDetail()}
            aria-hidden="true"
          />
        </div>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Order Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="modal-form staff-form">
                <Form>
                  <Form.Group
                    controlId="formInput1"
                    // className="italic-text"
                  >
                    <Form.Label column sm="3">
                      Your Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      className={`${error === "your name" ? "error-text" : ""}`}
                      name="userName"
                      // onChange={handleChangeOrderForm}
                      value={orderData?.userName}
                    />
                  </Form.Group>

                  <Form.Group controlId="formInput1">
                    <Form.Label column sm="3">
                      Property Address
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      className={`${
                        error === "property address" ? "error-text" : ""
                      }`}
                      name="propertyAddress"
                      // onChange={handleChangeOrderForm}
                      value={orderData?.propertyAddress}
                    />
                  </Form.Group>

                  <Form.Group controlId="formInput1">
                    <Form.Label column sm="3">
                      Delivery Address
                    </Form.Label>
                    <div className="select-box">
                      {/* <i className="fas fa-sort-down" /> */}
                      <Form.Control
                        as="select"
                        name="deliveryAddressOptions"
                        onChange={handleChangeOrderForm}
                        value={orderData?.deliveryAddressOptions}
                      >
                        {/* <option value="Select">Select</option> */}
                        <option value="propertyAddress">
                          Same as Property Address
                        </option>
                        <option value="differentAddress">
                          Use Different Delivery Address
                        </option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                  <Form.Group
                    controlId="formBasicInput-3"
                    className="number italic-text"
                  >
                    <Form.Label>
                      Your Mobile No.
                      {/* <span className="required-field">*</span> */}
                    </Form.Label>
                    <div className="number-field">
                      {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
                      <div className="number-box">
                        <Dropdown id="mobile">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic1"
                          >
                            <div className="flag-icon">
                              <img
                                src={selectedCountry === "+61" ? flag : us}
                                alt="flag"
                              />
                            </div>
                            {selectedCountry === "+61" ? (
                              <span>Australia {selectedCountry}</span>
                            ) : selectedCountry === "+1" ? (
                              <span>United States {selectedCountry}</span>
                            ) : (
                              ""
                            )}
                            {/* <span>
                              <i className="fas fa-caret-down" />
                            </span> */}
                            {/* <div className="country-code">{selectedCountry}</div> */}
                          </Dropdown.Toggle>
                        </Dropdown>
                      </div>
                      <NumberFormat
                        format={mobileFormat}
                        name="mobileNo"
                        placeholder="Enter Mobile Number"
                        className={`form-control mobile-num ${
                          error === "mobileNo" ? "error-text" : ""
                        }`}
                        autocomplete="off"
                        onChange={handleChangeOrderForm}
                        value={orderData?.mobileNo}
                      />
                    </div>
                  </Form.Group>
                  {orderData?.deliveryAddressOptions === "differentAddress" ? (
                    <>
                      <Form.Group
                        controlId="formBasicInput-9"
                        className="italic-text"
                      >
                        <Form.Label>Country</Form.Label>
                        <div className="select-box">
                          <Form.Control
                            as="select"
                            name="country"
                            onChange={handleChangeOrderForm}
                            value={orderData?.country}
                            className={`${
                              error === "country" ? "error-text" : ""
                            }`}
                          >
                            {countries?.length > 0 &&
                              countries.map((el) => (
                                <option value={el.name}>{el.name}</option>
                              ))}
                          </Form.Control>
                        </div>
                      </Form.Group>
                      <Form.Group
                        controlId="formBasicInput-9"
                        className="italic-text"
                      >
                        <Form.Label>
                          State
                          {/* <span className="required-field">*</span> */}
                        </Form.Label>
                        <div className="select-box">
                          <Form.Control
                            as="select"
                            onChange={handleChangeOrderForm}
                            name="state"
                            value={orderData?.state}
                            className={`${error === "state" ? "error-text" : ""}`}
                          >
                            <option value="">Select</option>
                            {stateArray?.length > 0 &&
                              stateArray.map((el) => (
                                <option value={el.name}>
                                  {el.isoCode} - {el.name}
                                </option>
                              ))}
                          </Form.Control>
                        </div>
                      </Form.Group>
                      <Form.Group controlId="formInput1">
                        <Form.Label column sm="3">
                          City/Town/Suburb
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter City/Town/Suburb"
                          name="suburb"
                          value={orderData?.suburb}
                          onChange={handleChangeOrderForm}
                          className={`${
                            error === "suburb" ? "error-text" : ""
                          }`}
                        />
                      </Form.Group>
                      <Form.Group controlId="formInput1">
                        <Form.Label column sm="3">
                          Street Address
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Street Address"
                          name="streetAddress"
                          value={orderData?.streetAddress}
                          onChange={handleChangeOrderForm}
                          className={`${
                            error === "streetAddress" ? "error-text" : ""
                          }`}
                        />
                      </Form.Group>
                      <Form.Group controlId="formInput1">
                        <Form.Label column sm="3">
                          Postal/ ZipCode
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder=""
                          name="postcode"
                          value={orderData?.postcode}
                          onChange={handleChangeOrderForm}
                          onKeyDown={(event) => {
                            if (
                              event.key === "+" ||
                              event.key === "-" ||
                              event.key === "e" ||
                              event.key === "."
                            ) {
                              event.preventDefault();
                            }
                          }}
                          className={`${
                            error === "postcode" ? "error-text" : ""
                          }`}
                        />
                      </Form.Group>
                    </>
                  ) : null}
                  <div className="payment-details">
                    {/* <h6>Payment Details</h6>
                    <Form.Group controlId="formInput1">
                      <Form.Label column sm="3">
                        Card Type
                      </Form.Label>
                      <div className="select-box">
                        <i className="fas fa-sort-down" />
                        <Form.Control
                          as="select"
                          name="cardType"
                          value={paymentDetails?.cardType}
                          onChange={handlePaymentDataChange}
                        >
                          <option value="Select">Select</option>
                        </Form.Control>
                      </div>
                    </Form.Group>
                    <Form.Group controlId="formInput1">
                      <Form.Label column sm="3">
                        Card Number
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                        name="cardNumber"
                        value={paymentDetails?.cardNumber}
                        onChange={handlePaymentDataChange}
                      />
                    </Form.Group>
                    <div className="card-cvv-details desktop-view">
                      <Form.Group controlId="formInput1" className="half">
                        <Form.Label column sm="3">
                          Expiry Date
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="MM/YYYY"
                          name="expiryDate"
                          value={paymentDetails?.expiryDate}
                          onChange={handlePaymentDataChange}
                        />
                      </Form.Group>
                      <Form.Group controlId="formInput1" className="half-right">
                        <Form.Label column sm="3">
                          CVV
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="cvv"
                          value={paymentDetails?.cvv}
                          onChange={handlePaymentDataChange}
                        />
                      </Form.Group>
                    </div> */}
                    <div className="amount-details">
                      <h6>
                        Order Sub Total: ${currencyComma(orderData?.subTotal)}
                      </h6>
                      <h6>Freight: ${currencyComma(orderData?.freight)}</h6>
                      <h6>Order Total: ${currencyComma(orderData?.total)}</h6>
                    </div>
                  </div>
                  <div className="view-btn desktop-show">
                    <Button
                      type="button"
                      className="btn btn-edit"
                      onClick={HandleOpenClose}
                    >
                      {" "}
                      Cancel{" "}
                    </Button>
                    <Button
                      type="button"
                      className="btn btn-save"
                      onClick={handleProcessPayment}
                    >
                      {" "}
                      Pay{" "}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

OrderDetailsPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  HandleOpenClose: PropTypes.func.isRequired,
  orderSummary: PropTypes.objectOf.isRequired,
  displayPredictiveChemicals: PropTypes.arrayOf.isRequired,
  setShowThankyou: PropTypes.func.isRequired,
  handleThankyou: PropTypes.func.isRequired,
  setShowPayment: PropTypes.func.isRequired,
  setUserFormInfo: PropTypes.func.isRequired,
  // selectedCountry: PropTypes.string.isRequired,
  // handleCountry: PropTypes.func.isRequired,
};

export default OrderDetailsPopup;
