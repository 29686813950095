import React, { useRef, useEffect } from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import "./delete.css";

const DeletePopUp = ({
  // eslint-disable-next-line react/prop-types
  setDeletePopUp,
  handlePopUpChange,
  PopUpText,
  paraText,
  warning,
  popupItalicText,
  type = "",
}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className ===
            "duration-picker time-picker-modal chemical-popup"
          ) {
            setDeletePopUp(false);
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="duration-picker time-picker-modal chemical-popup delete-popup delete-user-popup">
      <div>
        <div
          className={`time-keeper-popup duration-popup event-popup ${paraText.includes("Processing") ? "processing-popup" : ""}`}
        >
          <h6 className={`${popupItalicText ? "img-upload-text" : ""}`}>
            {PopUpText}
          </h6>
          {popupItalicText && <p className="note-text">{popupItalicText}</p>}
          {paraText && (
            <p
              className={`${paraText.includes("Processing") ? "processing-text" : ""}`}
            >
              {paraText}
            </p>
          )}
          {warning && (
            <p className="warning-text">
              <span>* </span> {warning}
            </p>
          )}
          <div
            className={`event-popup-btn footer-btn view-btn delete-user-btn ${paraText.includes("Processing") ? "processing-btn" : "hhhhhhhhhhhhh"}`}
          >
            <Button
              className="appointment-btn btn-edit"
              type="button"
              onClick={() => handlePopUpChange("No")}
            >
              Cancel
            </Button>
            {!paraText.includes("Processing") && (
              <Button
                type="button"
                className={
                  type === "subscription"
                    ? "appointment-btn-red"
                    : "appointment-btn btn-save"
                }
                onClick={() => handlePopUpChange("Yes")}
              >
                {type === "subscription" ? "Delete" : "Yes"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
DeletePopUp.defaultProps = {
  popupItalicText: "",
};
DeletePopUp.propTypes = {
  setDeletePopUp: PropTypes.bool.isRequired,
  PopUpText: PropTypes.isRequired,
  handlePopUpChange: PropTypes.isRequired,
  paraText: PropTypes.string.isRequired,
  warning: PropTypes.string.isRequired,
  popupItalicText: PropTypes.string,
};
export default DeletePopUp;
