const ProductSold = [
  "Item",
  "Ounce",
  "Pound",
  "Stone",
  "Ton",
  "Fluid Ounce",
  "Pint",
  "Quart",
  "Shot",
  "Inch",
  "Foot",
  "Yard",
  "Cubic Inch",
  "Cubic Foot",
  "Cu Yard",
  "Cup",
  "Sq Inch",
  "Square Foot",
  "Square Yard",
  "Square Mile",
  "Acre",
  "Gram",
  "Kilogram",
  "Tonne",
  "Millilitre",
  "Litre",
  "Millimetre",
  "Centimetre",
  "Metre",
  "Kilometre",
  "Cubic Millimetre",
  "Cubic Centimetre",
  "Cubic Metre",
  "Square Millimetre",
  "Square Centimetre",
  "Square Metre",
  "Square Kilometre",
  "Hectare",
  "Bag",
  "Bottle",
  "Box",
  "Can",
  "Dozen",
  "Jar",
  "Packet",
  "Pair",
  "Pieces",
  "Roll",
  "Set",
  "Add Custom Measure",
];
export default ProductSold;
