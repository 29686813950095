/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button, Accordion, Card, Form, Dropdown } from "react-bootstrap";
import ReactExport from "react-export-excel";
import PropTypes from "prop-types";
import moment from "moment";
import { queryString } from "../../functions/index";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import search from "../../assets/home/search.png";
import showmore from "../../assets/home/showmoreblue.png";
import whiteedit from "../../assets/home/white-edit.png";
import whitedelete from "../../assets/home/white-delete.png";
import greyplus from "../../assets/home/plus.png";
import "../prduct&services/product&services.css";
import crosss from "../../assets/home/black-icon.png";
import download from "../../assets/home/download.png";
import arrow from "../../assets/home/arrowdown.png";
import DeletePopUp from "../DeletePopUp/index";
import searchgray from "../../assets/home/searchgray.png";

function SubscriptionTable(props) {
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;
  const {
    clientList,
    handleEditClient,
    removeClient,
    showHide,
    searchClient,
    handleFormModal,
    handleContactModal,
    handleEditContact,
    handleDeleteContact,
    handleEditAddress,
    handleDeleteAddress,
    handleVisibilityAddr,
    clientTxt,
    backToAppointment,
    newstate,
    setnewState,
    setClientIdentity,
    handleResendLogin,
    setSkip,
    skip,
    setHideSearch,
    isHideSearch,
    handleSiteAddressModal,
    showAll,
    hideClickedAssociated,
    hideClicked,
    setClientNewData,
    clientNewData,
  } = props;

  let {
    clientid,
    name,
    startDateTime,
    employee_id,
    manageClient,
    typee,
    back,
    page,
    skipValuee,
  } = queryString();
  const history = useHistory();

  const [accordinData, setAccordinData] = useState({
    client: [],
    associatedContacts: [],
    siteAddresses: [],
    contacts: [],
    addresses: [],
    addrContacts: [],
  });
  const [check1, setCheck1] = useState(false);
  const [indexCheck, setindexCheck] = useState(0);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [delData, setDelData] = useState();
  const [popUpText, setPopUpText] = useState();

  const paraText = "Are you sure to do this?";

  function toggleClient(clientIdent) {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    const changeState = clientNewData.slice();
    const index = changeState.indexOf(clientIdent);

    if (index >= 0) {
      if (name) {
        if (typee === "edit" && back) {
          history.push(
            `addappointment?startDateTime=${startDateTime}&employee_id=${employee_id}&&cust=add`,
          );
        } else if (name && typeof manageClient === "undefined") {
          backToAppointment();
        } else if (typee === "add") {
          history.push(
            `addappointment?startDateTime=${startDateTime}&employee_id=${employee_id}&typo=add`,
          );
        }
      } else {
        changeState.splice(index, 1);
        setClientNewData(changeState);
        setClientIdentity(false);
        setnewState(!newstate);
      }
    } else {
      changeState.push(clientIdent);

      if (skipValuee) {
        setSkip(skipValuee);
        history.replace({ pathname: "subscriptions" });
      }

      setClientNewData(changeState);
      setClientIdentity(true);
      setnewState(!newstate);
    }
  }

  useEffect(() => {
    if (clientid) {
      toggleClient(clientid);
    }
  }, [clientid]);

  function nth(n) {
    return (
      n + ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || `${n}th`
    );
  }
  const [show, setShow] = useState(true);

  const handleSiteAccordion = (id, type) => {
    const selectedAccordin = accordinData[type];

    if (selectedAccordin && selectedAccordin.includes(id)) {
      const data = selectedAccordin.filter((el) => el !== id);

      setAccordinData({ ...accordinData, [type]: [...data] });
    } else {
      setAccordinData({ ...accordinData, [type]: [...selectedAccordin, id] });
    }
    if (type === "client") {
      setShow(!show);
    }
  };

  const camelCase = (str) =>
    str.substring(0, 1).toUpperCase() + str.substring(1);

  const handleQuotes = (id, firstName) => {
    let routeType = "client";
    history.push(
      `/booking?client=${id}&routeType=${routeType}&name=${firstName}`,
    );
  };
  // const PaymentHistory = (clientId, country) => {
  //   console.log('PaymentHistory', clientId, country);
  //   let Id = clientId || '';
  //   const routeType = 'client';
  //   history.push(`/payment?client=${Id}`);
  // };

  const PaymentHistory = (clientId, country) => {
    console.log("PaymentHistory", clientId, country);
    let Id = clientId || "";
    const routeType = "client";
    let url = `/payment?client=${Id}`;

    if (country) {
      url += `&country=${country}`;
    }

    history.push(url);
  };

  const filterColumns = (data) => {
    if (clientList && clientList.length) {
      const columns = Object.keys(data[0]);
      const filterColsByKey = columns.filter(
        (c) =>
          c !== "appointments" &&
          c !== "associatedContacts" &&
          c !== "siteAddresses" &&
          c !== "associatedContacts" &&
          c !== "calendarSettings" &&
          c !== "location" &&
          c !== "staffId" &&
          c !== "staff" &&
          c !== "siteAddresses" &&
          c !== "updatedAt" &&
          c !== "createdAt" &&
          c !== "_id" &&
          c !== "__v" &&
          c !== "showBookings" &&
          c !== "role" &&
          c !== "quickBooksConnect" &&
          c !== "isDelete" &&
          c !== "industryId" &&
          c !== "firstLogin" &&
          c !== "createdBy" &&
          c !== "costDropDown" &&
          c !== "chemicalsToDisplay",
      );
      return filterColsByKey; // OR return columns
    }
    return [];
  };

  const handlePopUpChange = (val) => {
    if (val === "Yes") {
      switch (popUpText) {
        case "Confirm Delete Subscriber?":
          removeClient(delData);
          break;

        case "Delete Contact?": {
          const { _id, i, contactIndex, type, addrIndex } = delData;
          handleDeleteContact(_id, i, contactIndex, type, addrIndex);
          break;
        }

        case "Delete Site Address?": {
          const { _id, i, addrIndex } = delData;
          handleDeleteAddress(_id, i, addrIndex);
          break;
        }

        default:
          break;
      }
    }

    setDeletePopUp(false);
  };
  const submitRemove = (id) => {
    setPopUpText("Confirm Delete Subscriber?");
    setDeletePopUp(true);
    setDelData(id);
  };

  const submitRemoveContact = (_id, i, contactIndex, type, addrIndex) => {
    setPopUpText("Delete Contact?");
    setDeletePopUp(true);
    setDelData({
      _id,
      i,
      contactIndex,
      type,
      addrIndex,
    });
  };

  const submitRemoveAddress = (_id, i, addrIndex) => {
    setPopUpText("Delete Site Address?");
    setDeletePopUp(true);
    setDelData({ _id, i, addrIndex });
  };

  const handleWaterTest = (clientId, clientName) => {
    history.push(
      `/watertesthistory?data=${clientId}&name=${clientName}&routeType=client`,
    );
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }
  const handleCheck = (idx) => {
    setindexCheck(idx);
    setCheck1(true);
  };

  // new Work
  const handleBack = () => {
    history.push("/predictiveOrdering");
  };

  const handleUserForThisSubscriber = (id) => {
    history.push(`/users?id=${id}&skipValue=${skip}`);
  };
  let contactCount = 1;

  const associatedDisplay = (valueContacts, contIndex) =>
    `fas ${
      valueContacts?.includes(contIndex.toString())
        ? "far fa-chevron-circle-down"
        : "far fa-chevron-circle-up"
    }`;

  const getContactNameType = (custType, contcount) =>
    custType === "Householder"
      ? `${nth(contcount)} Contact`
      : `Associated Billing Contact ${contcount}`;

  console.log("clientList==> sdcdsf", clientList);
  return (
    <>
      {newstate && (
        <>
          {page === "predictiveOrdering" ? (
            <div className="back-option calc-hisoption" onClick={handleBack}>
              <span />

              <i className="fa fa-times" aria-hidden="true" />
            </div>
          ) : null}
          <div className="main-titles-mob">
            <h3>Subscriber List</h3>
            <div className="content-dropdown" />

            <div className="client-option service-edit-icon">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={showmore} alt="show" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="action" onClick={handleFormModal}>
                    <span>
                      <img src={greyplus} alt="edit" />
                    </span>
                    <span> New Subscriber</span>
                  </div>
                  <div className="action" onClick={() => setHideSearch(true)}>
                    <span>
                      <img src={searchgray} alt="edit" />
                    </span>
                    <span>Show Hidden data in Search</span>
                  </div>
                  <div className="action">
                    <span>
                      <img src={download} alt="edit" />
                    </span>
                    <ExcelFile
                      filename="subscriber-list"
                      element={<Button className="btn">Download in XL</Button>}
                    >
                      <ExcelSheet data={clientList} name="Test">
                        {filterColumns(clientList).map((col) => (
                          <ExcelColumn label={camelCase(col)} value={col} />
                        ))}
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="client-search-box">
            <Form inline>
              <Form.Group
                controlId="formBasicSearch"
                className=" search-box search-text"
              >
                <Form.Control
                  placeholder="Search by name, mobile, phone or address"
                  onChange={(e) => searchClient(e.target.value)}
                  onKeyDown={onKeyDown}
                  value={clientTxt}
                />
                <img src={search} alt="search" />
                {clientTxt ? (
                  <div className="remove-text" onClick={() => searchClient("")}>
                    <img src={crosss} className="cross-img" alt="cross-img" />
                  </div>
                ) : null}
              </Form.Group>
              {isHideSearch ? (
                <span className="hidden-data">
                  Hidden Data Shown in Search
                  <img
                    src={crosss}
                    className="cross-img"
                    alt="cross-img"
                    onClick={() => {
                      setHideSearch(false);
                      searchClient("");
                    }}
                  />
                </span>
              ) : null}
            </Form>
          </div>
        </>
      )}
      <div className="staff-table client-table" />
      {clientList ? (
        <>
          {clientList.length === 0 ? (
            <div className="placehoder-text">No Subscribers Found</div>
          ) : (
            <>
              {clientList.map((client, i) => {
                let jsxData = (
                  <>
                    {newstate && (
                      <div className="client-main-contact" key={client._id}>
                        <div
                          className="client-name-contact"
                          onClick={() => handleCheck(i)}
                        >
                          <div className="">
                            <h3 className="client-company-name">
                              {client.companyName}
                            </h3>
                            <h4 className="client-name">
                              {" "}
                              {`${client.firstname} ${client.lastname}`}
                            </h4>
                            <p className="client-address">{`${client.streetAddress}`}</p>
                          </div>
                          <div className="open-date client-notes">
                            <div className="arrow-img">
                              <img
                                src={arrow}
                                alt="arrow"
                                role="presentation"
                                onClick={() => toggleClient(client._id)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {newstate === false &&
                      clientNewData.includes(client._id) && (
                        <div className="cover-client-page">
                          <div className="client-back-btn">
                            <div className="staff-close">
                              <i
                                className="fa fa-times"
                                onClick={() => toggleClient(client._id)}
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                          <Accordion
                            className="mob-accordion new-client-section"
                            key={client._id}
                          >
                            <Card>
                              <Card.Header>
                                <div className="client-heading-option first-heading">
                                  <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    eventKey={client._id}
                                  >
                                    <h5 style={{ marginLeft: "30px" }}>
                                      {`${
                                        client.companyName
                                          ? client.companyName
                                          : ""
                                      }`}
                                    </h5>
                                  </Accordion.Toggle>

                                  <div className="client-option service-edit-icon">
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                      >
                                        <img src={showmore} alt="show" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item>
                                          <div
                                            className="action"
                                            onClick={() =>
                                              handleResendLogin(client.staffId)
                                            }
                                          >
                                            <span>
                                              {" "}
                                              <i
                                                className="fa fa-info-circle"
                                                aria-hidden="true"
                                              />
                                            </span>
                                            <span> Resend Login Details</span>
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <div
                                            className="action"
                                            onClick={() =>
                                              handleContactModal(i, client?._id)
                                            }
                                          >
                                            <span>
                                              <img src={greyplus} alt="edit" />
                                            </span>
                                            {client?.customerType !==
                                            "Householder" ? (
                                              <span>
                                                {" "}
                                                Associated Billing Contact
                                              </span>
                                            ) : (
                                              <span> Additional Contact</span>
                                            )}
                                          </div>
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item>
                                          {client?.customerType
                                            !== 'Householder' && (
                                            <div
                                              className="action"
                                              onClick={() => handleSiteAddressModal(
                                                i,
                                                client?._id,
                                              )}
                                            >
                                              <span>
                                                <span>
                                                  <img
                                                    src={greyplus}
                                                    alt="edit"
                                                  />
                                                </span>
                                              </span>
                                              <span>Site Address</span>
                                            </div>
                                          )}
                                        </Dropdown.Item> */}
                                        <Dropdown.Item>
                                          {client?.customerType !==
                                          "Householder" ? (
                                            <>
                                              <div
                                                className="action"
                                                onClick={() =>
                                                  showAll(
                                                    i,
                                                    "associatedContacts",
                                                  )
                                                }
                                              >
                                                <span>
                                                  <i
                                                    className="fa fa-eye"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                                <span>
                                                  {`${
                                                    hideClickedAssociated ===
                                                      true &&
                                                    hideClickedAssociated
                                                      ? "Hide"
                                                      : hideClickedAssociated ===
                                                          false
                                                        ? "Show Hidden"
                                                        : "Show Hidden"
                                                  } Associated Billing Contacts`}
                                                </span>
                                              </div>
                                              {/* <div className="action">
                                              <span>
                                                <img src={searchgray} alt="edit" />
                                              </span>
                                              <span>Search Hidden Associated Billing Contacts</span>
                                            </div> */}
                                            </>
                                          ) : null}
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item>
                                          <div
                                            className="action"
                                            onClick={() => showAll(
                                              i,
                                              client?.customerType
                                                  !== 'Householder'
                                                ? 'siteAddresses'
                                                : 'siteContacts',
                                            )}
                                          >
                                            <span>
                                              <i
                                                className="fa fa-eye"
                                                aria-hidden="true"
                                              />
                                            </span>

                                            <span>
                                              {`${
                                                hideClicked === true
                                                && hideClicked
                                                  ? 'Hide'
                                                  : hideClicked === false
                                                    ? 'Show Hidden'
                                                    : 'Show Hidden'
                                              } ${
                                                client?.customerType
                                                !== 'Householder'
                                                  ? 'Site Addresses'
                                                  : 'Contacts'
                                              }`}
                                            </span>
                                          </div>
                                        
                                        </Dropdown.Item> */}
                                        {client?.customerType !==
                                        "Householder" ? (
                                          <>
                                            {/* <div className="action">
                                              <span>
                                                <img src={searchgray} alt="edit" />
                                              </span>
                                              <span>Search Hidden Site Addresses</span>
                                            </div> */}
                                            {/* <div className="action" onClick={() => handleEditClient(client)}>
                                            <span>
                                              <img src={greyedit} alt="edit" />
                                            </span>
                                            <span> Edit </span>
                                          </div> */}
                                          </>
                                        ) : null}
                                        <Dropdown.Item>
                                          <div
                                            className="action"
                                            onClick={() =>
                                              handleUserForThisSubscriber(
                                                client._id,
                                              )
                                            }
                                          >
                                            <span>
                                              <i
                                                className="fa fa-eye"
                                                aria-hidden="true"
                                              />
                                            </span>
                                            <span>
                                              {" "}
                                              Users for this Subscriber{" "}
                                            </span>
                                          </div>
                                        </Dropdown.Item>

                                        <Dropdown.Item>
                                          <div className="action">
                                            <span>
                                              {" "}
                                              Direct Debit/Card Details{" "}
                                            </span>
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <div
                                            className="action"
                                            onClick={() =>
                                              PaymentHistory(
                                                client?._id,
                                                client?.country,
                                              )
                                            }
                                          >
                                            <span>
                                              <i
                                                className="fa fa-eye"
                                                aria-hidden="true"
                                              />
                                            </span>
                                            <span> Invoices & Payments </span>
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <div
                                            className="action"
                                            onClick={() =>
                                              handleEditClient(client)
                                            }
                                          >
                                            <span> Edit </span>
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <div
                                            className="action"
                                            onClick={() =>
                                              submitRemove(client._id)
                                            }
                                          >
                                            <span> Delete </span>
                                          </div>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                              </Card.Header>
                              {show ? (
                                <Accordion eventKey={client?._id}>
                                  <Card.Body>
                                    <ul className="staff-list top-client-details">
                                      <li className="staff-list-item">
                                        <div className="staff-box">
                                          <h3>Main Contact</h3>
                                          <h5>{`${client?.firstname} ${client?.lastname}`}</h5>
                                        </div>
                                      </li>
                                      <li className="staff-list-item">
                                        <div className="staff-box">
                                          <h3>Mobile</h3>
                                          <h5>
                                            <a
                                              className="mobile"
                                              href={`tel:${client?.mobileNo}`}
                                            >
                                              {`${client?.mobileNo}`}
                                            </a>
                                          </h5>
                                        </div>
                                      </li>
                                      <li className="staff-list-item">
                                        <div className="staff-box">
                                          <h3>Email</h3>
                                          <h5>
                                            <a
                                              className="email"
                                              href={`mailto:${client?.email}`}
                                            >
                                              {client?.email}
                                            </a>
                                          </h5>
                                        </div>
                                      </li>

                                      {/* {client?.customerType !== 'Householder' ? (
                                    <li className="staff-list-item">
                                      <div className="staff-box">
                                        <h3>Company Name</h3>
                                        <h5>{`${client?.companyName}`}</h5>
                                      </div>
                                    </li>
                                  ) : (
                                    ''
                                  )} */}
                                      <li className="staff-list-item">
                                        <div className="staff-box">
                                          <h3>Billing Street Address</h3>
                                          <h5>{`${client?.streetAddress}`}</h5>
                                        </div>
                                      </li>
                                      <li className="staff-list-item">
                                        <div className="staff-box">
                                          <h3>Billing Suburb</h3>
                                          <h5>
                                            {client?.suburb !== undefined
                                              ? client?.suburb
                                              : ""}
                                          </h5>
                                        </div>
                                      </li>
                                      {/* <li className="staff-list-item">
                                        <div className="staff-box">
                                          <h3>Billing Contact Type</h3>
                             
                                          <h5>{`${client?.customerType}`}</h5>
                                        </div>
                                      </li> */}

                                      <li className="staff-list-item">
                                        <div className="staff-box">
                                          <h3>Country</h3>

                                          <h5>{`${client?.country}`}</h5>
                                        </div>
                                      </li>
                                      <li className="staff-list-item">
                                        <div className="staff-box">
                                          <h3>State</h3>

                                          <h5>{`${client?.state}`}</h5>
                                        </div>
                                      </li>
                                      <li className="staff-list-item">
                                        <div className="staff-box">
                                          <h3>Gender</h3>

                                          <h5>{`${client?.gender ? client?.gender : "Not defined"}`}</h5>
                                        </div>
                                      </li>
                                      <li className="staff-list-item">
                                        <div className="staff-box">
                                          <h3>Note</h3>
                                          <h5>{`${client?.note ? client?.note : "Not defined"}`}</h5>
                                        </div>
                                      </li>
                                      <li className="staff-list-item">
                                        <div className="staff-box">
                                          <h3> Subscription Duration</h3>
                                          <h5>
                                            {client?.duration
                                              ? `${client?.duration} Months`
                                              : "10 Day Trial"}
                                          </h5>
                                        </div>
                                      </li>
                                      <li className="staff-list-item">
                                        <div className="staff-box">
                                          <h3>Start Date</h3>
                                          <h5>
                                            {moment(
                                              client?.startsubscriptionDate,
                                            ).format("D MMM YYYY")}
                                          </h5>
                                        </div>
                                      </li>
                                      <li className="staff-list-item">
                                        <div className="staff-box">
                                          <h3>End Date</h3>
                                          <h5>
                                            {moment(
                                              client?.endsubscriptionDate,
                                            ).format("D MMM YYYY")}
                                          </h5>
                                        </div>
                                      </li>
                                      <li className="staff-list-item">
                                        <div className="staff-box">
                                          <h3> No. of Pools</h3>
                                          <h5>
                                            {`${client?.numberOfPools || 0}`}
                                          </h5>
                                        </div>
                                      </li>
                                      <li className="staff-list-item">
                                        <div className="staff-box">
                                          <h3> No. of Users</h3>
                                          <h5>
                                            {`${client?.numberUsers || 0}`}
                                          </h5>
                                        </div>
                                      </li>
                                      <li className="staff-list-item">
                                        <div className="staff-box">
                                          <h3> Total Cost</h3>
                                          <h5>
                                            {client?.country === "Australia"
                                              ? "AUD"
                                              : "US"}
                                            $
                                            {(client?.totalCost || 0).toFixed(
                                              2,
                                            )}
                                          </h5>
                                        </div>
                                      </li>
                                    </ul>
                                    {client?.associatedContacts?.length > 0 &&
                                      client.associatedContacts.map(
                                        (el, contactIndex) => {
                                          let contactsId = "";
                                          if (
                                            el?.hide === false ||
                                            el?.tempShow === true
                                          ) {
                                            contactCount += 1;
                                            contactsId =
                                              el?.name +
                                              el?.mobileNo +
                                              el?.email +
                                              contactIndex;
                                            return (
                                              <Accordion
                                                className="mob-accordion inner-item"
                                                key={contactIndex.toString()}
                                              >
                                                <Card>
                                                  <Card.Header>
                                                    <div className="client-heading-option main-inner-heading first-heading">
                                                      <Accordion.Toggle
                                                        as={Button}
                                                        variant="link"
                                                        eventKey={contactIndex.toString()}
                                                        onClick={() =>
                                                          handleSiteAccordion(
                                                            contactIndex.toString(),
                                                            "associatedContacts",
                                                          )
                                                        }
                                                      >
                                                        <h5>
                                                          <i
                                                            className={associatedDisplay(
                                                              accordinData?.associatedContacts,
                                                              contactIndex,
                                                            )}
                                                          />
                                                          {getContactNameType(
                                                            client?.customerType,
                                                            contactCount,
                                                          )}
                                                        </h5>
                                                      </Accordion.Toggle>
                                                    </div>
                                                  </Card.Header>

                                                  <div key={contactsId}>
                                                    <Accordion.Collapse
                                                      eventKey={contactIndex.toString()}
                                                    >
                                                      <Card.Body>
                                                        <div className="">
                                                          <ul className="staff-list ass-contact">
                                                            <li className="staff-list-item">
                                                              <div className="staff-box">
                                                                <h3>
                                                                  {" "}
                                                                  Contact Name
                                                                </h3>
                                                                <h5>
                                                                  {el?.name}{" "}
                                                                  {el?.lastName ||
                                                                    ""}
                                                                </h5>
                                                              </div>
                                                            </li>
                                                            <li className="staff-list-item">
                                                              <div className="staff-box">
                                                                <h3>
                                                                  Mobile No
                                                                </h3>
                                                                <h5>
                                                                  {el?.mobileNo}
                                                                </h5>
                                                              </div>
                                                            </li>
                                                            <li className="staff-list-item">
                                                              <div className="staff-box">
                                                                <h3>
                                                                  Relationship
                                                                  to Main
                                                                  Contact
                                                                </h3>
                                                                <h5>
                                                                  Secretary
                                                                </h5>
                                                              </div>
                                                            </li>
                                                            {/* <li className="staff-list-item">
                                                        <div className="staff-box">
                                                          <h3>Landline No</h3>
                                                          <h5>{el?.landlineNo}</h5>
                                                        </div>
                                                      </li>
                                                      <li className="staff-list-item">
                                                        <div className="staff-box">
                                                          <h3>Email</h3>
                                                          <h5>{el?.email}</h5>
                                                        </div>
                                                      </li> */}
                                                            <li className="staff-list-item">
                                                              <div className="staff-box">
                                                                <h3>Actions</h3>
                                                                <div className="action-btn">
                                                                  <Button className="edit-btn">
                                                                    <img
                                                                      src={
                                                                        whiteedit
                                                                      }
                                                                      alt="edit"
                                                                      onClick={() =>
                                                                        handleEditContact(
                                                                          el,
                                                                          client?._id,
                                                                          i,
                                                                          contactIndex,
                                                                        )
                                                                      }
                                                                    />
                                                                  </Button>
                                                                  <Button className="eye-btn">
                                                                    <i
                                                                      className={
                                                                        el?.hide
                                                                          ? "fas fa-eye-slash"
                                                                          : "fa fa-eye"
                                                                      }
                                                                      onClick={() =>
                                                                        handleVisibilityAddr(
                                                                          i,
                                                                          client?._id,
                                                                          contactIndex,
                                                                          "associatedContacts",
                                                                        )
                                                                      }
                                                                    />
                                                                  </Button>
                                                                  <Button className="delete-btn">
                                                                    <img
                                                                      src={
                                                                        whitedelete
                                                                      }
                                                                      alt="edit"
                                                                      onClick={() =>
                                                                        submitRemoveContact(
                                                                          client?._id,
                                                                          i,
                                                                          contactIndex,
                                                                          "contact",
                                                                        )
                                                                      }
                                                                    />
                                                                  </Button>
                                                                </div>
                                                              </div>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </Card.Body>
                                                    </Accordion.Collapse>
                                                  </div>
                                                </Card>
                                              </Accordion>
                                            );
                                          }
                                          return "";
                                        },
                                      )}
                                    {client?.siteAddresses?.length &&
                                    client?.siteAddresses?.filter(
                                      (addr) =>
                                        addr?.hide === false ||
                                        addr?.tempShow === true,
                                    ).length ? (
                                      <>
                                        {client?.siteAddresses?.map(
                                          (el, addrIndex) => {
                                            let addressesId = "";
                                            if (
                                              !el?.hide ||
                                              el?.tempShow === true
                                            ) {
                                              addressesId =
                                                el?.householderName +
                                                el?.address +
                                                el?.mobileNo;
                                              return (
                                                <Accordion
                                                  className="mob-accordion inner-item"
                                                  key={addressesId}
                                                >
                                                  <Card>
                                                    <Card.Header>
                                                      <div className="client-heading-option first-heading site-with-address">
                                                        <Accordion.Toggle
                                                          as={Button}
                                                          variant="link"
                                                          eventKey={addressesId}
                                                          onClick={() =>
                                                            handleSiteAccordion(
                                                              addressesId,
                                                              "addresses",
                                                            )
                                                          }
                                                        >
                                                          <h5>
                                                            <i
                                                              className={
                                                                accordinData.addresses.includes(
                                                                  addressesId,
                                                                )
                                                                  ? "fas far fa-chevron-circle-down"
                                                                  : "fas far fa-chevron-circle-up"
                                                              }
                                                            />

                                                            {addrIndex === 0 ? (
                                                              <div>
                                                                Site Address
                                                              </div>
                                                            ) : (
                                                              <div>
                                                                Site Address{" "}
                                                                {addrIndex + 1}
                                                              </div>
                                                            )}
                                                          </h5>
                                                          <h6>{el?.address}</h6>
                                                        </Accordion.Toggle>

                                                        {/* <div
                                                    className="client-option service-edit-icon"
                                                    onClick={() => handleContactModal(i, client?._id, addrIndex)}
                                                  >
                                                    <i className="fa fa-plus" aria-hidden="true" />
                                                  </div> */}
                                                        {/* <div
                                                    className={`client-option service-edit-icon ${accordinData.addresses.includes(addressesId) ? 'show-associated' : 'hide-img'}`}
                                                    onClick={() => handleContactModal(i, client?._id, addrIndex)}
                                                  >
                                                    <i className="fa fa-plus" aria-hidden="true" />
                                                    <div className="client-option service-edit-icon">
                                                      <Dropdown>
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                          <img src={showmore} alt="show" />
                                                        </Dropdown.Toggle>
                                                      </Dropdown>
                                                    </div>
                                                  </div> */}
                                                      </div>
                                                    </Card.Header>
                                                    <Accordion.Collapse
                                                      eventKey={addressesId}
                                                    >
                                                      <Card.Body>
                                                        <div
                                                          className="occupier-contact"
                                                          key={addressesId}
                                                        >
                                                          <Card>
                                                            <Card.Body>
                                                              <ul className="staff-list occupier-list">
                                                                <li className="staff-list-item">
                                                                  <div className="staff-box">
                                                                    <h3>
                                                                      Occupier
                                                                    </h3>
                                                                    <h5>
                                                                      {
                                                                        el?.householderName
                                                                      }
                                                                    </h5>
                                                                  </div>
                                                                </li>
                                                                <li className="staff-list-item">
                                                                  <div className="staff-box">
                                                                    <h3>
                                                                      Mobile No.
                                                                    </h3>
                                                                    <h5>
                                                                      {
                                                                        el?.mobileNo
                                                                      }
                                                                    </h5>
                                                                  </div>
                                                                </li>
                                                                <li className="staff-list-item">
                                                                  <div className="staff-box">
                                                                    <h3>
                                                                      Actions
                                                                    </h3>
                                                                    <div className="action-btn">
                                                                      <Button className="edit-btn">
                                                                        <img
                                                                          src={
                                                                            whiteedit
                                                                          }
                                                                          alt="edit"
                                                                          onClick={() =>
                                                                            handleEditAddress(
                                                                              el,
                                                                              client?._id,
                                                                              i,
                                                                              addrIndex,
                                                                            )
                                                                          }
                                                                        />
                                                                      </Button>
                                                                      <Button className="eye-btn">
                                                                        <i
                                                                          className={
                                                                            el?.hide
                                                                              ? "fas fa-eye-slash"
                                                                              : "fa fa-eye"
                                                                          }
                                                                          aria-hidden="true"
                                                                          onClick={() =>
                                                                            handleVisibilityAddr(
                                                                              i,
                                                                              client?._id,
                                                                              addrIndex,
                                                                              "siteAddresses",
                                                                            )
                                                                          }
                                                                        />
                                                                      </Button>
                                                                      <Button className="delete-btn">
                                                                        <img
                                                                          src={
                                                                            whitedelete
                                                                          }
                                                                          alt="edit"
                                                                          onClick={() =>
                                                                            submitRemoveAddress(
                                                                              client?._id,
                                                                              i,
                                                                              addrIndex,
                                                                            )
                                                                          }
                                                                        />
                                                                      </Button>
                                                                    </div>
                                                                  </div>
                                                                </li>
                                                              </ul>
                                                            </Card.Body>
                                                          </Card>
                                                        </div>
                                                      </Card.Body>
                                                    </Accordion.Collapse>
                                                  </Card>
                                                </Accordion>
                                              );
                                            }

                                            return "";
                                          },
                                        )}
                                      </>
                                    ) : null}
                                  </Card.Body>
                                </Accordion>
                              ) : null}
                            </Card>
                          </Accordion>
                        </div>
                      )}
                  </>
                );
                if (showHide === false && client.hide === true) {
                  jsxData = null;
                }
                return jsxData;
              })}
              {deletePopUp && (
                <DeletePopUp
                  PopUpText={popUpText}
                  handlePopUpChange={handlePopUpChange}
                  setDeletePopUp={setDeletePopUp}
                  paraText={paraText}
                  type="subscription"
                />
              )}
            </>
          )}
        </>
      ) : null}
    </>
  );
}

SubscriptionTable.propTypes = {
  clientList: PropTypes.func.isRequired,
  handleEditClient: PropTypes.func.isRequired,
  removeClient: PropTypes.func.isRequired,
  showHide: PropTypes.func.isRequired,
  searchClient: PropTypes.func.isRequired,
  handleFormModal: PropTypes.func.isRequired,
  handleContactModal: PropTypes.func.isRequired,
  handleEditContact: PropTypes.func.isRequired,
  handleDeleteContact: PropTypes.func.isRequired,
  handleEditAddress: PropTypes.func.isRequired,
  handleDeleteAddress: PropTypes.func.isRequired,
  handleVisibilityAddr: PropTypes.func.isRequired,
  clientTxt: PropTypes.func.isRequired,
  backToAppointment: PropTypes.func.isRequired,
  setnewState: PropTypes.func.isRequired,
  newstate: PropTypes.bool.isRequired,
  setClientIdentity: PropTypes.func.isRequired,
  handleResendLogin: PropTypes.func.isRequired,
  skip: PropTypes.bool.isRequired,
  setSkip: PropTypes.func.isRequired,
  setHideSearch: PropTypes.func.isRequired,
  isHideSearch: PropTypes.func.isRequired,
  handleSiteAddressModal: PropTypes.func.isRequired,
  showAll: PropTypes.func.isRequired,
  hideClickedAssociated: PropTypes.func.isRequired,
  hideClicked: PropTypes.func.isRequired,
  setClientNewData: PropTypes.func.isRequired,
  clientNewData: PropTypes.arrayOf.isRequired,
};

export default SubscriptionTable;
