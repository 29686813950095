/* eslint-disable import/no-unresolved */
import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const CalendarPopup = (props) => {
  const {
    showCalendar,
    setShowCalendar,
    handleCalendarChange,
    handleCalendar,
  } = props;
  return (
    <Modal
      size="md"
      show={showCalendar}
      onHide={() => setShowCalendar(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="startdate-modal calendar-modal search-modal time-popup custom-time-popup"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="search-popup shift-container">
          <div className="staff-close">
            <i
              className="fa fa-times"
              onClick={() => setShowCalendar(false)}
              aria-hidden="true"
            />
          </div>
          <div className="time-calender">
            <Calendar onChange={handleCalendar} value={new Date()} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
CalendarPopup.propTypes = {
  showCalendar: PropTypes.func.isRequired,
  setShowCalendar: PropTypes.func.isRequired,
  handleCalendarChange: PropTypes.func.isRequired,
  handleCalendar: PropTypes.func.isRequired,
};
export default CalendarPopup;
