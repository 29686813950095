import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "./default.css";
import img403 from "../../assets/home/notautherized.png";
import { AuthDataContext } from "../../contexts/AuthDataContext";

function NotAuthorized() {
  const { userData } = useContext(AuthDataContext);

  const getRoute = () => {
    const filteredData = userData?.permissions?.filter(
      (el) => el.route && el.route !== "/a",
    );
    if (filteredData?.length > 0) {
      const fIndex = filteredData?.findIndex(
        (elem) => elem.route === "/calculator",
      );
      if (fIndex >= 0) return filteredData[fIndex].route;
      return filteredData[0].route;
    }
    return "/";
  };

  return (
    <div className="default-container">
      <div className="default-content">
        <img src={img403} alt="not-Authorized" />
        <h3>Sorry... You Are Not Authorized: </h3>
        <p>
          You don&lsquo;t have permissions to access this page. If you&lsquo;d
          like access, ask your account owner to update the permission settings.
        </p>
        <div className="default-btn">
          <Link to={getRoute()}>Back To Home</Link>
        </div>
      </div>
    </div>
  );
}

export default NotAuthorized;
