/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Dropdown, Form, Button } from "react-bootstrap";
import NumberFormat from "react-number-format";
import flag from "../../assets/admin/aus-flag.png";
import us from "../../assets/admin/us-flag.png";
import showmore from "../../assets/home/showmoreblue.png";
import target from "../../assets/home/icon/target.png";
import "./index.css";

import { ocrPath } from "../../config/keys";

const ChangeBillingDetails = ({
  handleAction,
  handleChange,
  selectedCountry,
  handleCountry,
  countries,
  stateArray,
  user,
  handleEditSubscriber,
  error,
  handleCardOnChange,
  updateCardDetails,
}) => {
  // console.log('user =>', user);
  const { cardDetails } = user || {};
  // console.log('cardDetails =>', cardDetails);
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          handleAction("");
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="duration-picker time-picker-modal chat-bot-page billing-popup change-billing-popup">
      <div>
        <div
          className="time-keeper-popup duration-popup newContent_popup"
          ref={wrapperRef}
        >
          <div className="inner-content">
            <div className="back-popup" onClick={() => handleAction()}>
              <i className="las la-times" />
            </div>
            <div className="maincontent">
              <div className="main-titles-mob">
                <h3>PoolWater-Pro Billing</h3>
                <div className="content-dropdown">
                  <div className="client-option service-edit-icon">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={showmore} alt="show" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <div
                            className="action"
                            onClick={() =>
                              handleAction("Calculator-Pro Billing")
                            }
                          >
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Current Subscription</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div
                            className="action"
                            onClick={() => handleAction("Edit My Details")}
                          >
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Edit My Details</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div
                            className="action"
                            onClick={() =>
                              handleAction("Change Billing Details")
                            }
                          >
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Change Billing Details</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div
                            className="action"
                            onClick={() => handleAction("Update Subscription")}
                          >
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Update Subscription</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div
                            className="action"
                            // onClick={() => setAction('Update Subscription')}
                          >
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Cancel Subscription</span>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="target-heading">
                <h6>Change Billing Details</h6>
              </div>
              <div className="edit-my-details-content billing-content">
                <div className="staff-form">
                  <div className="card-details">
                    <h6>Card on File</h6>
                    <ul>
                      <li>
                        <h3>Card Type</h3>
                        <h5>{cardDetails?.cardBrand || ""}</h5>
                      </li>
                      <li>
                        <h3>Card Number</h3>
                        <h5>**** **** {cardDetails?.last4 || ""}</h5>
                      </li>
                      <div className="remove-card-btn">
                        <span>Remove Card X</span>
                      </div>
                    </ul>
                  </div>
                  <Form>
                    <h6>Update Card Details</h6>
                    <div className="row">
                      <div className="col-md-6">
                        <Form.Group controlId="formBasicLocation">
                          <Form.Label>Card Type</Form.Label>
                          <div className="select-box">
                            <Form.Control
                              as="select"
                              name="cardType"
                              onChange={handleCardOnChange}
                              placeholder="Card Type"
                              className={`${
                                error === "cardType" ? "error-text" : ""
                              }`}
                            >
                              <option value="Visa">Visa</option>
                            </Form.Control>
                          </div>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group
                          controlId="formBasicInput-1"
                          className="italic-text"
                        >
                          <Form.Label>Card Number</Form.Label>
                          <Form.Control
                            type="number"
                            name="cardNum"
                            placeholder="Card Number"
                            onChange={handleCardOnChange}
                            className={`${
                              error === "cardNum" ? "error-text" : ""
                            }`}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group
                          controlId="formBasicInput-2"
                          className="italic-text"
                        >
                          <Form.Label>Expiry Date</Form.Label>
                          <Form.Control
                            type="text"
                            name="expirydate"
                            onChange={handleCardOnChange}
                            placeholder="MM/YY"
                            className={`${
                              error === "expirydate" ? "error-text" : ""
                            }`}
                          />
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group
                          controlId="formBasicInput-2"
                          className="italic-text"
                        >
                          <Form.Label>CVV</Form.Label>
                          <Form.Control
                            type="number"
                            name="cvv"
                            onChange={handleCardOnChange}
                            placeholder="CVV"
                            className={`${
                              error === "lastname" ? "error-text" : ""
                            }`}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="view-btn">
                          <Button
                            type="button"
                            className="btn btn-edit"
                            onClick={handleAction}
                          >
                            {" "}
                            Cancel{" "}
                          </Button>
                          <Button
                            type="Submit"
                            className="btn btn-save"
                            onClick={updateCardDetails}
                          >
                            {" "}
                            Save/Update{" "}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
ChangeBillingDetails.propTypes = {
  handleAction: PropTypes.func.isRequired,
  selectedCountry: PropTypes.string.isRequired,
  handleCountry: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf.isRequired,
  handleChange: PropTypes.func.isRequired,
  stateArray: PropTypes.arrayOf.isRequired,
  user: PropTypes.arrayOf.isRequired,
  handleEditSubscriber: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  handleCardOnChange: PropTypes.func.isRequired,
  updateCardDetails: PropTypes.func.isRequired,
};
export default ChangeBillingDetails;
