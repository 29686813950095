import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";
import DateModal from "../appointment/dateModal";

const ChemicalsAddedPopUp = ({
  setChemicalAddSelected,
  chemicalAddSelected,
  handleChemicalPopUpChange,
  chemicalOptionsSelected,
  handleChemicalsSelectPop,
  handleCancelChemicalsPopUp,
}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className === "duration-picker time-picker-modal"
          ) {
            handleCancelChemicalsPopUp();
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="duration-picker time-picker-modal update-source-popup">
      <div>
        <div
          className="time-keeper-popup duration-popup event-popup"
          ref={wrapperRef}
        >
          <div className="appointment-view">
            <div
              className="pending-water-test"
              onClick={() => handleChemicalPopUpChange("chemicalsAdded")}
            >
              <h3>Chemicals Added as Calculated</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="chemicalsAdded"
                    checked={
                      chemicalOptionsSelected === "chemicalsAdded" ? true : ""
                    }
                    onChange={(e) => handleChemicalPopUpChange(e.target.name)}
                  />
                </div>
              </div>
            </div>
            <div
              className="pending-water-test"
              onClick={() => handleChemicalPopUpChange("editQuantity")}
            >
              <h3>Edit Chemical Quantity</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="editQuantity"
                    checked={
                      chemicalOptionsSelected === "editQuantity" ? true : ""
                    }
                    onChange={(e) => handleChemicalPopUpChange(e.target.name)}
                  />
                </div>
              </div>
            </div>

            <div className="event-popup-btn footer-btn view-btn">
              <Button
                className="appointment-btn btn-edit"
                type="button"
                onClick={() => handleCancelChemicalsPopUp()}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="appointment-btn btn-save"
                onClick={() => handleChemicalsSelectPop()}
              >
                Select
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
ChemicalsAddedPopUp.propTypes = {
  setChemicalAddSelected: PropTypes.func.isRequired,
  handleChemicalPopUpChange: PropTypes.func.isRequired,
  chemicalOptionsSelected: PropTypes.string.isRequired,
  chemicalAddSelected: PropTypes.bool.isRequired,
  handleChemicalsSelectPop: PropTypes.func.isRequired,
  handleCancelChemicalsPopUp: PropTypes.func.isRequired,
};
export default ChemicalsAddedPopUp;
