// New Changes for Objects
// const categories = ['Green Pool Labour Cost', 'chlorine', 'combined', 'hydrochloricAcidPh', 'sodaAsh', 'buffer', 'hydrochloricAcidAlk', 'calciumRaiser', 'stabiliser', 'copper', 'phosphatesRemover', 'salt', 'clarifier', 'flock', 'algaecide', 'drain', 'saltMsgConvert', 'secondSaltMsgConvert'];

// const greenFirstVisit = ['Green Pool Labour Cost', 'chlorine', 'clarifier', 'flock'];
// const greenSecondVisit = ['Green Pool Labour Cost', 'chlorine'];
// const SandFirstVisit = ['MediaFilter', 'CoarseFineGlassSand'];

// const ArrayGreen = [];

const categoryObj = {
  buyCostProduct: "0.00",
  startTime: "9:30",
  cost: "",
  duration: "0",
  previousDuration: "",
  previousCost: "",
  laborMargin: "",
  renderCost: "",
  staffName: "Puneet Sharan",
  category: "",
  subcategory: "",
  totalLabour: "",
  type: "product",
  customMisc: "",
  pricingType: "hourly",
  quantity: "0.00",
  status: "quantity",
  endTime: 0,
  start: "",
  end: "",
  tax: "",
  greenType: "green",
  show: "hide",
  edittype: true,
  previousSubcategory: "",
  originalQuantity: "",
  chemicalUnits: "",
  chemicalDisplayName: "",
  calciName: "",
};

// categories.forEach((el) => {
//   let obj = { ...categoryObj };
//   obj.category = el;
//   ArrayGreen.push(obj);
// });

const filterInitialArray = [
  {
    buyCostProduct: "0.00",
    startTime: "9:30",
    cost: "",
    previousCost: "",
    duration: "0",
    previousDuration: "",
    laborMargin: "",
    renderCost: "",
    staffName: "Puneet Sharan",
    category: "Replace",
    subcategory: "",
    totalLabour: "",
    type: "product",
    customMisc: "",
    pricingType: "hourly",
    quantity: "0.00",
    status: "quantity",
    endTime: 0,
    start: "",
    end: "",
    greenType: "green",
    show: "hide",
    uniqueType: "mediaFilter",
    edittype: true,
    previousSubcategory: "",
    originalQuantity: "",
    chemicalUnits: "",
    chemicalDisplayName: "",
    calciName: "",
  },
  {
    buyCostProduct: "0.00",
    startTime: "9:30",
    cost: "",
    previousCost: "",
    duration: "0",
    previousDuration: "",
    laborMargin: "",
    renderCost: "",
    staffName: "Puneet Sharan",
    category: "fineAndCoarse",
    subcategory: "",
    totalLabour: "",
    type: "product",
    customMisc: "",
    pricingType: "hourly",
    quantity: "0.00",
    status: "quantity",
    endTime: 0,
    start: "",
    end: "",
    greenType: "green",
    show: "hide",
    uniqueType: "bagsGlass",
    edittype: true,
    previousSubcategory: "",
    originalQuantity: "",
    chemicalUnits: "",
    chemicalDisplayName: "",
    calciName: "",
  },
];

const fourthVisit = [
  {
    buyCostProduct: "0.00",
    startTime: "9:30",
    cost: "",
    previousCost: "",
    duration: "0",
    previousDuration: "",
    laborMargin: "",
    renderCost: "",
    staffName: "Puneet Sharan",
    category: "Green Pool Labour Cost",
    subcategory: "",
    totalLabour: "",
    type: "product",
    customMisc: "",
    pricingType: "hourly",
    quantity: "0.00",
    status: "quantity",
    endTime: 0,
    start: "",
    end: "",
    greenType: "green",
    show: "hide",
    uniqueType: "mediaFilter",
    edittype: true,
    previousSubcategory: "",
    originalQuantity: "",
    chemicalUnits: "",
    chemicalDisplayName: "",
    calciName: "",
  },
  {
    buyCostProduct: "0.00",
    startTime: "9:30",
    cost: "",
    previousCost: "",
    duration: "0",
    previousDuration: "",
    laborMargin: "",
    renderCost: "",
    staffName: "Puneet Sharan",
    category: "hydrochloricAcidPh",
    subcategory: "",
    totalLabour: "",
    type: "product",
    customMisc: "",
    pricingType: "hourly",
    quantity: "0.00",
    status: "quantity",
    endTime: 0,
    start: "",
    end: "",
    greenType: "green",
    show: "hide",
    uniqueType: "bagsGlass",
    edittype: true,
    previousSubcategory: "",
    originalQuantity: "",
    chemicalUnits: "",
    chemicalDisplayName: "",
    calciName: "",
  },
];

const initialValidationData = {
  poolWidth: false,
  poolHeight: false,
  shalowEnd: false,
  deepEnd: false,
  diameter: false,
  originalSaltLvl: false,
  bagsSaltAdded: false,
  currentSaltLevel: false,
};

const initialSoureInputs = {
  chlorine: "0",
  ph: "6.8",
  alkalinity: "0",
  stabiliser: "0",
  calciumHardness: "0",
  salt: "0",
  copper: "0",
  phosphates: "0",
  combinedChlorine: "0",
  newCombinedChlr: "0",
};

const defaultChemicalState = {
  chlorine: "",
  ph: "",
  alkalinity: "",
  stabiliser: "",
  calciumHardness: "",
  salt: "",
  copper: "",
  phosphates: "",
  combinedChlorine: "",
  newCombinedChlr: "",
};

const initialInputs = {
  poolWidth: "",
  poolHeight: "",
  selectedService: "",
  selectedPoolFinish: "",
  selectedChlorination: "",
  selectedChlorinatorModel: "",
  chlorine: "",
  ph: "",
  alkalinity: "",
  stabiliser: "",
  calciumHardness: "",
  salt: "",
  poolCapacity: 0,
  poolWaterCapacity: 0,
  drainPercentWaterLevelLowered: 0,
  shallowEnd: 0,
  deepEnd: 0,
  diameter: 0,
  copper: "",
  phosphates: "",
  combinedChlorine: "",
  newCombinedChlr: "",
  initialSalt: "",
  originalSaltLvl: "",
  bagsSaltAdded: "",
  currentSaltLevel: "",
  increaseDecreaseAmount: "",
  waterLevelLowered: "",
  amountOfWaterLost: "",
  waterLossPerDay: "",
  waterLossPerHour: "",
  waterLossPerMinute: "",
  poolImageType: "",
  chlorineHEX: "",
  combinedChlorineHEX: "",
  phHEX: "",
  alkalinityHEX: "",
  calciumHardnessHEX: "",
  stabiliserHEX: "",
  hardCalcium: "",
  ambientAirTemp: "",
  currentPoolTemp: "",
  requiredPoolTemp: "",
  increaseInPoolTemp: "",
  tempRise: "",
  timeToHeat: "",
};
const initialCal = {
  baseCost: 4.08,
  quantityCost: "",
  sellPrice: "",
  profile: "",
  roundUpCost: "",
  chlorine: 0,
  ph: 0,
  alkalinity: 0,
  stabiliser: 0,
  calciumHardness: 0,
  salt: 0,
  granularChlorine: 0,
  sodiumThiosulphate: 0,
  sodaAsh: 0,
  hydrochloricAcidPh: 0,
  buffer: 0,
  hydrochloricAcidAlk: 0,
  dryAcid: 0,
  addStabiliser: 0,
  drainWater: 0,
  drainWaterHardness: 0,
  calciumRaiser: "",
  addSalt: "",
  drainWaterSalt: 0,
  costChroline: 0,
  costPh: 0,
  costAlkalinity: 0,
  costStabiliser: 0,
  costCalcium: 0,
  costSalt: 0,
  costDryPhAcid: 0,
  costDryAlkAcid: 0,
  totalCost: 0,
  quantityCostPh: 0,
  quantityCostAlk: 0,
  quantityCostStabiliser: 0,
  quantityCostCalcium: 0,
  quantityCostSalt: 0,
  quantityCostPhDryAcid: 0,
  quantityCostAlkDryAcid: 0,
  profitChrolone: 0,
  profitPh: 0,
  profitAlkalinity: 0,
  profitStabiliser: 0,
  profitCalciumHardness: 0,
  profitSalt: 0,
  profitPhDryAcid: 0,
  profitAlkDryAcid: 0,
  inString: 0,
  maxDrain: 0,
  convertPool: "",
  costSaltConvert: 0,
  withDrainage: "",
  balancing: "",
  diffStabliser: "",
  diffChlorine: "",
  diffCalcium: "",
  diffSalt: "",
  diffCopper: "",
  poolMsg: "",
  phosphate: 0,
  phosphateRemover: "",
  costPhophates: 0,
  quantityCostPhosphates: 0,
  phosphateMsg: "",
  phfibre: 0,
  phliner: 0,
  phConcreteTilted: 0,
  stabiliserMsg: "",
  calciumHardnessMsg: "",
  baseGranular: "",
  baseSodiumThi: "",
  basePhSodaAsh: "",
  basePhAcid: "",
  baseAlkBuffer: "",
  baseCalciumRaiser: "",
  baseStabiliser: "",
  basePhosphates: "",
  baseSalt: "",
  basePotassium: "",
  baseDryAcid: "",
  baseZodiacCalcium: "",
  baseBlackSpotAlgae: "",
  liquidChlorine: "",
  baseConvert: "",
  baseMagna: "",
  baseAqua: "",
  sellSalt: "",
  sellGranular: "",
  sellSodiumThi: "",
  sellPhAcid: "",
  sellPhSodaAsh: "",
  sellAlkBuffer: "",
  sellCalciumRaiser: "",
  sellStabiliser: "",
  sellPhosphates: "",
  sellConvert: "",
  sellMagna: "",
  sellAqua: "",
  sellPotassium: "",
  sellDryAcid: "",
  sellZodiacCalcium: "",
  isDrainage: "",
  combinedChlorine: "",
  costCombinedChlorine: 0,
  combinedChlorineMsg: "",
  zodiacOxy: "",
  quantityCostCombined: "",
  phContNegative: "",
  phContPositive: "",
  phFibreNegative: "",
  phFibrePositive: "",
  phVinylNegative: "",
  phVinylPositive: "",
  alkNegative: "",
  alkPositive: "",
  stabNegative: "",
  stabPositive: "",
  stabOrpNegative: "",
  stabOrpPositive: "",
  calcNegative: "",
  calcPositive: "",
  chlorNegative: "",
  chlorPositive: "",
  recmndStabiliserLvl: "",
  stabiliserOrp: "",
  stabiliserCya: "",
  negativeSalt: "",
  positiveSalt: "",
  setupCost: "",
  hourlyRate: "",
  litresPerHour: "",
  drainingHours: "",
  drainingCost: "",
  drainingPoolSize: "",
  drainingHourlyRate: "",
  magnaMineral: "",
  aquaMineral: "",
  baseLiquid: "",
  sellLiquid: "",
  manualAquaValue: "",
  algaecide: "1000",
  algaecideQuantity: "",
  clarifierMaintainence: "100",
  clarifierMaintainenceQuantity: "",
  clarifierGreenPool: "1000",
  clarifierGreenPoolQuantity: "",
  flock: "2.0",
  flockQuantity: "",
  baseAlgaecide: "",
  baseClarifier: "",
  baseClarifierGreen: "",
  baseFlock: "",
  sellAlgaecide: "",
  sellClarifier: "",
  sellClarifierGreen: "",
  sellFlock: "",
  sellBlackSpotAlgae: "",
  quantityCostAlgaecide: "",
  sellPriceAlgaecide: "",
  costAlgaecide: "",
  quantityCostClarifierMain: "",
  sellPriceClarifierMain: "",
  costClarifierMain: "",
  quantityCostClarifierGreen: "",
  sellPriceClarifierGreen: "",
  costClarifierGreen: "",
  quantityCostFlock: "",
  sellPriceFlock: "",
  costFlock: "",
  greenQuantity: "",
  greenCost: "",
  greenLabourCost: "",
  greenVaccumCost: "",
  drainWaterChorine: 0,
  drainPercent: "",
  combinedChlorineDrainingHours: "",
  copperDrainingHours: "",
  saltDrainingHours: "",
  calciumDrainingHours: "",
  stabiliserDrainingHours: "",
  poolSetUpdrainagePercentage: "",
  copper: 0,
  copperQuantity: "",
  quantityCostCopper: "",
  costCopper: "",
  baseCopper: "",
  sellCopper: "",
  copperMsg: "",
  copperNegative: "",
  copperPositive: "",
  drainPointstabiliserCya: "",
  drainPointcalciumHardness: "",
  drainPointcalciumHardnessReduce: "",
  drainPointstabiliserOrp: "",
  drainPointcopper: "",
  drainPointcombinedChlorine: "",
  drainWaterCopper: 0,
  costDrainPercentWaterLevelLowered: 0,
  drainPercentWaterLevelLoweredHours: "",
  chlorineMsg: "",
  phMsg: "",
  alkMsg: "",
  saltMsgConvert: "",
  secondSaltMsgConvert: "",
  clarifierMsg: "",
  algaecideMsg: "",
  flockMsg: "",
  calciumHardnessReduce: "",
  combNegative: "",
  combPositive: "",

  // calciumNewChange
  hardContNegative: "",
  hardContPositive: "",
  hardFibreNegative: "",
  hardFibrePositive: "",
  hardVinylNegative: "",
  hardVinylPositive: "",
  calciumHardnessVinyl: "",
  calciumHardnessFibre: "",
  calciumHardnessConcrete: "",
  drainPointcalciumHardnessVinyl: "",
  drainPointcalciumHardnessFibre: "",
  drainPointcalciumHardnessConcrete: "",

  // calciumReduceNewChange
  calcreduNegative: "",
  calcreduContNegative: "",
  calcreduFibreNegative: "",
  calcreduVinylNegative: "",
  calcreduPositive: "",
  calcreduContPositive: "",
  calcreduFibrePositive: "",
  calcreduVinylPositive: "",
  calciumHardnessReduceConcrete: "",
  calciumHardnessReduceFibre: "",
  calciumHardnessReduceVinyl: "",
  drainPointcalciumHardnessReduceConcrete: "",
  drainPointcalciumHardnessReduceFibre: "",
  drainPointcalciumHardnessReduceVinyl: "",

  // TitrationChange
  hardCalcium: 260,
  hardMagnesium: 600,
};
const initialglbobj = {
  avgRectangular: {
    poolSize: "",
  },
  avgCircular: {
    poolSize: "",
  },
  spcRectangular: {
    poolSize: "",
  },
  spcCircular: {
    poolSize: "",
  },
  glbVariable: {
    gldiameter: "",
    glwidth: "",
    glheight: "",
    gldeepEnd: "",
    glshallowEnd: "",
  },
};
const initialAlkMsgsSet = { aerate: false, retest: false, ok: false };

const inpImage = {
  FCL: "chlorine",
  TCL: "combinedChlorine",
  pH: "ph",
  ALK: "alkalinity",
  HARD: "calciumHardness",
  CYA: "stabiliser",
  COPPER: "copper",
  PHOS: "phosphates",
  SALT: "salt",
  "Mg HARD": "hardMagnesium",
  "Ca HARD": "hardCalcium",
};

const inpStripImage = {
  FCL: "chlorine",
  TCL: "combinedChlorine",
  pH: "ph",
  ALK: "alkalinity",
  HARD: "calciumHardness",
  CYA: "stabiliser",
  Box1: "Box1",
  Box2: "Box2",
  Box3: "Box3",
  Box4: "Box4",
  Box5: "Box5",
  Box6: "Box6",
  BoxCount: "BoxCount",
  FCL_HEX: "chlorineHEX",
  TCL_HEX: "combinedChlorineHEX",
  pH_HEX: "phHEX",
  ALK_HEX: "alkalinityHEX",
  HARD_HEX: "calciumHardnessHEX",
  CYA_HEX: "stabiliserHEX",
  // GapColor: 'GapColor',
};

const inpStripImage4 = {
  FCL: "chlorine",
  pH: "ph",
  ALK: "alkalinity",
  CYA: "stabiliser",
  Box1: "Box3",
  Box2: "Box4",
  Box3: "Box5",
  Box4: "Box6",
  BoxCount: "BoxCount",
  FCL_HEX: "chlorineHEX",
  pH_HEX: "phHEX",
  ALK_HEX: "alkalinityHEX",
  CYA_HEX: "stabiliserHEX",
  // GapColor: 'GapColor',
};

const alkalinityArray = [
  { value: "0", color: "#ba8f49" },
  { value: "1", color: "#b98f49" },
  { value: "2", color: "#b88f49" },
  { value: "3", color: "#b78f49" },
  { value: "4", color: "#b68f49" },
  { value: "5", color: "#b58f49" },
  { value: "6", color: "#b48f49" },
  { value: "7", color: "#b38f49" },
  { value: "8", color: "#b28f49" },
  { value: "9", color: "#b18f49" },
  { value: "10", color: "#b08f49" },
  { value: "11", color: "#ae8f49" },
  { value: "12", color: "#ad8f49" },
  { value: "13", color: "#ac8f49" },
  { value: "14", color: "#ab8f49" },
  { value: "15", color: "#aa8f49" },
  { value: "16", color: "#a98f49" },
  { value: "17", color: "#a88f49" },
  { value: "18", color: "#a78f49" },
  { value: "19", color: "#a68f49" },
  { value: "20", color: "#a58f48" },
  { value: "21", color: "#a48f48" },
  { value: "22", color: "#a38f48" },
  { value: "23", color: "#a28f48" },
  { value: "24", color: "#a18f48" },
  { value: "25", color: "#a08f48" },
  { value: "26", color: "#9f8f48" },
  { value: "27", color: "#9e8f48" },
  { value: "28", color: "#9d8f48" },
  { value: "29", color: "#9c8f48" },
  { value: "30", color: "#9a8f48" },
  { value: "31", color: "#998f48" },
  { value: "32", color: "#988f48" },
  { value: "33", color: "#978f48" },
  { value: "34", color: "#968f48" },
  { value: "35", color: "#958f48" },
  { value: "36", color: "#948f48" },
  { value: "37", color: "#938f48" },
  { value: "38", color: "#928f48" },
  { value: "39", color: "#918f48" },
  { value: "40", color: "#908f48" },
  { value: "41", color: "#8f8e48" },
  { value: "42", color: "#8e8e48" },
  { value: "43", color: "#8d8d47" },
  { value: "44", color: "#8c8c47" },
  { value: "45", color: "#8b8c47" },
  { value: "46", color: "#8a8b46" },
  { value: "47", color: "#898b46" },
  { value: "48", color: "#888a46" },
  { value: "49", color: "#878946" },
  { value: "50", color: "#868946" },
  { value: "51", color: "#848845" },
  { value: "52", color: "#838845" },
  { value: "53", color: "#828745" },
  { value: "54", color: "#818644" },
  { value: "55", color: "#808644" },
  { value: "56", color: "#7f8544" },
  { value: "57", color: "#7e8444" },
  { value: "58", color: "#7d8444" },
  { value: "59", color: "#7c8343" },
  { value: "60", color: "#7b8243" },
  { value: "61", color: "#7a8243" },
  { value: "62", color: "#798142" },
  { value: "63", color: "#788142" },
  { value: "64", color: "#778042" },
  { value: "65", color: "#767f42" },
  { value: "66", color: "#757f42" },
  { value: "67", color: "#747e41" },
  { value: "68", color: "#737e41" },
  { value: "69", color: "#727d41" },
  { value: "70", color: "#707c40" },
  { value: "71", color: "#6f7c40" },
  { value: "72", color: "#6e7b40" },
  { value: "73", color: "#6d7a40" },
  { value: "74", color: "#6c7a40" },
  { value: "75", color: "#6b793f" },
  { value: "76", color: "#6a783f" },
  { value: "77", color: "#69783f" },
  { value: "78", color: "#68773e" },
  { value: "79", color: "#67773e" },
  { value: "80", color: "#66763e" },
  { value: "81", color: "#66763f" },
  { value: "82", color: "#657640" },
  { value: "83", color: "#657641" },
  { value: "84", color: "#657642" },
  { value: "85", color: "#647644" },
  { value: "86", color: "#647645" },
  { value: "87", color: "#647646" },
  { value: "88", color: "#647647" },
  { value: "89", color: "#637648" },
  { value: "90", color: "#637649" },
  { value: "91", color: "#63764a" },
  { value: "92", color: "#62764b" },
  { value: "93", color: "#62764c" },
  { value: "94", color: "#62764d" },
  { value: "95", color: "#62764e" },
  { value: "96", color: "#617650" },
  { value: "97", color: "#617651" },
  { value: "98", color: "#617652" },
  { value: "99", color: "#607653" },
  { value: "100", color: "#607654" },
  { value: "101", color: "#607655" },
  { value: "102", color: "#5f7656" },
  { value: "103", color: "#5f7657" },
  { value: "104", color: "#5f7658" },
  { value: "105", color: "#5e765a" },
  { value: "106", color: "#5e765b" },
  { value: "107", color: "#5e765c" },
  { value: "108", color: "#5e765d" },
  { value: "109", color: "#5d765e" },
  { value: "110", color: "#5d765f" },
  { value: "111", color: "#5d7660" },
  { value: "112", color: "#5c7661" },
  { value: "113", color: "#5c7662" },
  { value: "114", color: "#5c7663" },
  { value: "115", color: "#5c7664" },
  { value: "116", color: "#5b7666" },
  { value: "117", color: "#5b7667" },
  { value: "118", color: "#5b7668" },
  { value: "119", color: "#5a7669" },
  { value: "120", color: "#5a766a" },
  { value: "121", color: "#59766a" },
  { value: "122", color: "#58766a" },
  { value: "123", color: "#58756a" },
  { value: "124", color: "#57756a" },
  { value: "125", color: "#56756a" },
  { value: "126", color: "#55756a" },
  { value: "127", color: "#54746a" },
  { value: "128", color: "#547469" },
  { value: "129", color: "#537469" },
  { value: "130", color: "#527469" },
  { value: "131", color: "#517469" },
  { value: "132", color: "#507369" },
  { value: "133", color: "#507369" },
  { value: "134", color: "#4f7369" },
  { value: "135", color: "#4e7369" },
  { value: "136", color: "#4d7369" },
  { value: "137", color: "#4c7269" },
  { value: "138", color: "#4c7269" },
  { value: "139", color: "#4b7269" },
  { value: "140", color: "#4a7269" },
  { value: "141", color: "#497169" },
  { value: "142", color: "#487169" },
  { value: "143", color: "#487168" },
  { value: "144", color: "#477168" },
  { value: "145", color: "#467168" },
  { value: "146", color: "#457068" },
  { value: "147", color: "#447068" },
  { value: "148", color: "#447068" },
  { value: "149", color: "#437068" },
  { value: "150", color: "#427068" },
  { value: "151", color: "#416f68" },
  { value: "152", color: "#406f68" },
  { value: "153", color: "#406f68" },
  { value: "154", color: "#3f6f68" },
  { value: "155", color: "#3e6e68" },
  { value: "156", color: "#3d6e68" },
  { value: "157", color: "#3c6e68" },
  { value: "158", color: "#3c6e67" },
  { value: "159", color: "#3b6e67" },
  { value: "160", color: "#3a6d67" },
  { value: "161", color: "#396d67" },
  { value: "162", color: "#386d67" },
  { value: "163", color: "#386d67" },
  { value: "164", color: "#376c67" },
  { value: "165", color: "#366c67" },
  { value: "166", color: "#356c67" },
  { value: "167", color: "#346c67" },
  { value: "168", color: "#346c67" },
  { value: "169", color: "#336b67" },
  { value: "170", color: "#326b67" },
  { value: "171", color: "#316b67" },
  { value: "172", color: "#306b67" },
  { value: "173", color: "#306b66" },
  { value: "174", color: "#2f6a66" },
  { value: "175", color: "#2e6a66" },
  { value: "176", color: "#2d6a66" },
  { value: "177", color: "#2c6a66" },
  { value: "178", color: "#2c6966" },
  { value: "179", color: "#2b6966" },
  { value: "180", color: "#2a6966" },
  { value: "181", color: "#2a6966" },
  { value: "182", color: "#2a6966" },
  { value: "183", color: "#2a6967" },
  { value: "184", color: "#2a6967" },
  { value: "185", color: "#2a6867" },
  { value: "186", color: "#2a6867" },
  { value: "187", color: "#2a6868" },
  { value: "188", color: "#2a6868" },
  { value: "189", color: "#2a6868" },
  { value: "190", color: "#2a6868" },
  { value: "191", color: "#2a6868" },
  { value: "192", color: "#2a6869" },
  { value: "193", color: "#2a6769" },
  { value: "194", color: "#2a6769" },
  { value: "195", color: "#2a6769" },
  { value: "196", color: "#2b6769" },
  { value: "197", color: "#2b676a" },
  { value: "198", color: "#2b676a" },
  { value: "199", color: "#2b676a" },
  { value: "200", color: "#2b676a" },
  { value: "201", color: "#2b676b" },
  { value: "202", color: "#2b666b" },
  { value: "203", color: "#2b666b" },
  { value: "204", color: "#2b666b" },
  { value: "205", color: "#2b666b" },
  { value: "206", color: "#2b666c" },
  { value: "207", color: "#2b666c" },
  { value: "208", color: "#2b666c" },
  { value: "209", color: "#2b666c" },
  { value: "210", color: "#2b666c" },
  { value: "211", color: "#2b656d" },
  { value: "212", color: "#2b656d" },
  { value: "213", color: "#2b656d" },
  { value: "214", color: "#2b656d" },
  { value: "215", color: "#2b656e" },
  { value: "216", color: "#2b656e" },
  { value: "217", color: "#2b656e" },
  { value: "218", color: "#2b656e" },
  { value: "219", color: "#2b646e" },
  { value: "220", color: "#2b646f" },
  { value: "221", color: "#2b646f" },
  { value: "222", color: "#2b646f" },
  { value: "223", color: "#2b646f" },
  { value: "224", color: "#2b6470" },
  { value: "225", color: "#2c6470" },
  { value: "226", color: "#2c6470" },
  { value: "227", color: "#2c6470" },
  { value: "228", color: "#2c6370" },
  { value: "229", color: "#2c6371" },
  { value: "230", color: "#2c6371" },
  { value: "231", color: "#2c6371" },
  { value: "232", color: "#2c6371" },
  { value: "233", color: "#2c6371" },
  { value: "234", color: "#2c6372" },
  { value: "235", color: "#2c6372" },
  { value: "236", color: "#2c6272" },
  { value: "237", color: "#2c6272" },
  { value: "238", color: "#2c6273" },
  { value: "239", color: "#2c6273" },
  { value: "240", color: "#2c6273" },
  { value: "241", color: "#2c6273" },
  { value: "242", color: "#2c6273" },
  { value: "243", color: "#2c6273" },
  { value: "244", color: "#2c6173" },
  { value: "245", color: "#2c6173" },
  { value: "246", color: "#2b6173" },
  { value: "247", color: "#2b6173" },
  { value: "248", color: "#2b6173" },
  { value: "249", color: "#2b6172" },
  { value: "250", color: "#2b6072" },
  { value: "251", color: "#2b6072" },
  { value: "252", color: "#2b6072" },
  { value: "253", color: "#2b6072" },
  { value: "254", color: "#2b6072" },
  { value: "255", color: "#2b6072" },
  { value: "256", color: "#2b5f72" },
  { value: "257", color: "#2b5f72" },
  { value: "258", color: "#2a5f72" },
  { value: "259", color: "#2a5f72" },
  { value: "260", color: "#2a5f72" },
  { value: "261", color: "#2a5f72" },
  { value: "262", color: "#2a5e72" },
  { value: "263", color: "#2a5e72" },
  { value: "264", color: "#2a5e72" },
  { value: "265", color: "#2a5e71" },
  { value: "266", color: "#2a5e71" },
  { value: "267", color: "#2a5e71" },
  { value: "268", color: "#2a5d71" },
  { value: "269", color: "#2a5d71" },
  { value: "270", color: "#295d71" },
  { value: "271", color: "#295d71" },
  { value: "272", color: "#295d71" },
  { value: "273", color: "#295d71" },
  { value: "274", color: "#295c71" },
  { value: "275", color: "#295c71" },
  { value: "276", color: "#295c71" },
  { value: "277", color: "#295c71" },
  { value: "278", color: "#295c71" },
  { value: "279", color: "#295c71" },
  { value: "280", color: "#295b71" },
  { value: "281", color: "#295b70" },
  { value: "282", color: "#285b70" },
  { value: "283", color: "#285b70" },
  { value: "284", color: "#285b70" },
  { value: "285", color: "#285b70" },
  { value: "286", color: "#285a70" },
  { value: "287", color: "#285a70" },
  { value: "288", color: "#285a70" },
  { value: "289", color: "#285a70" },
  { value: "290", color: "#285a70" },
  { value: "291", color: "#285a70" },
  { value: "292", color: "#285970" },
  { value: "293", color: "#285970" },
  { value: "294", color: "#275970" },
  { value: "295", color: "#275970" },
  { value: "296", color: "#275970" },
  { value: "297", color: "#27596f" },
  { value: "298", color: "#27586f" },
  { value: "299", color: "#27586f" },
  { value: "300", color: "#27586f" },
  { value: "301", color: "#27586f" },
  { value: "302", color: "#27586f" },
  { value: "303", color: "#27586f" },
  { value: "304", color: "#27576f" },
  { value: "305", color: "#26576f" },
  { value: "306", color: "#26576f" },
  { value: "307", color: "#26576f" },
  { value: "308", color: "#26576f" },
  { value: "309", color: "#26576f" },
  { value: "310", color: "#26566f" },
  { value: "311", color: "#26566f" },
  { value: "312", color: "#26566f" },
  { value: "313", color: "#26566f" },
  { value: "314", color: "#26566e" },
  { value: "315", color: "#26566e" },
  { value: "316", color: "#26556e" },
  { value: "317", color: "#25556e" },
  { value: "318", color: "#25556e" },
  { value: "319", color: "#25556e" },
  { value: "320", color: "#25556e" },
  { value: "321", color: "#25556e" },
  { value: "322", color: "#25546e" },
  { value: "323", color: "#25546e" },
  { value: "324", color: "#25546e" },
  { value: "325", color: "#25546e" },
  { value: "326", color: "#25546e" },
  { value: "327", color: "#25546e" },
  { value: "328", color: "#25536e" },
  { value: "329", color: "#24536e" },
  { value: "330", color: "#24536d" },
  { value: "331", color: "#24536d" },
  { value: "332", color: "#24536d" },
  { value: "333", color: "#24536d" },
  { value: "334", color: "#24526d" },
  { value: "335", color: "#24526d" },
  { value: "336", color: "#24526d" },
  { value: "337", color: "#24526d" },
  { value: "338", color: "#24526d" },
  { value: "339", color: "#24526d" },
  { value: "340", color: "#24516d" },
  { value: "341", color: "#23516d" },
  { value: "342", color: "#23516d" },
  { value: "343", color: "#23516d" },
  { value: "344", color: "#23516d" },
  { value: "345", color: "#23516d" },
  { value: "346", color: "#23506c" },
  { value: "347", color: "#23506c" },
  { value: "348", color: "#23506c" },
  { value: "349", color: "#23506c" },
  { value: "350", color: "#23506c" },
  { value: "351", color: "#23506c" },
  { value: "352", color: "#234f6c" },
  { value: "353", color: "#224f6c" },
  { value: "354", color: "#224f6c" },
  { value: "355", color: "#224f6c" },
  { value: "356", color: "#224f6c" },
  { value: "357", color: "#224f6c" },
  { value: "358", color: "#224e6c" },
  { value: "359", color: "#224e6c" },
  { value: "360", color: "#224e6c" },
  { value: "361", color: "#224e6c" },
  { value: "362", color: "#224e6b" },
  { value: "363", color: "#224e6b" },
  { value: "364", color: "#224d6b" },
  { value: "365", color: "#214d6b" },
  { value: "366", color: "#214d6b" },
  { value: "367", color: "#214d6b" },
  { value: "368", color: "#214d6b" },
  { value: "369", color: "#214d6b" },
  { value: "370", color: "#214c6b" },
  { value: "371", color: "#214c6b" },
  { value: "372", color: "#214c6b" },
  { value: "373", color: "#214c6b" },
  { value: "374", color: "#214c6b" },
  { value: "375", color: "#214c6b" },
  { value: "376", color: "#204c6b" },
  { value: "377", color: "#204b6b" },
  { value: "378", color: "#204b6b" },
  { value: "379", color: "#204b6a" },
  { value: "380", color: "#204b6a" },
  { value: "381", color: "#204b6a" },
  { value: "382", color: "#204b6a" },
  { value: "383", color: "#204a6a" },
  { value: "384", color: "#204a6a" },
  { value: "385", color: "#204a6a" },
  { value: "386", color: "#204a6a" },
  { value: "387", color: "#204a6a" },
  { value: "388", color: "#1f4a6a" },
  { value: "389", color: "#1f496a" },
  { value: "390", color: "#1f496a" },
  { value: "391", color: "#1f496a" },
  { value: "392", color: "#1f496a" },
  { value: "393", color: "#1f496a" },
  { value: "394", color: "#1f496a" },
  { value: "395", color: "#1f4869" },
  { value: "396", color: "#1f4869" },
  { value: "397", color: "#1f4869" },
  { value: "398", color: "#1f4869" },
  { value: "399", color: "#1f4869" },
  { value: "400", color: "#1e4869" },
  { value: "401", color: "#1e4769" },
  { value: "402", color: "#1e4769" },
  { value: "403", color: "#1e4769" },
  { value: "404", color: "#1e4769" },
  { value: "405", color: "#1e4769" },
  { value: "406", color: "#1e4769" },
  { value: "407", color: "#1e4669" },
  { value: "408", color: "#1e4669" },
  { value: "409", color: "#1e4669" },
  { value: "410", color: "#1e4669" },
  { value: "411", color: "#1e4668" },
  { value: "412", color: "#1d4668" },
  { value: "413", color: "#1d4568" },
  { value: "414", color: "#1d4568" },
  { value: "415", color: "#1d4568" },
  { value: "416", color: "#1d4568" },
  { value: "417", color: "#1d4568" },
  { value: "418", color: "#1d4568" },
  { value: "419", color: "#1d4468" },
  { value: "420", color: "#1d4468" },
  { value: "421", color: "#1d4468" },
  { value: "422", color: "#1d4468" },
  { value: "423", color: "#1d4468" },
  { value: "424", color: "#1c4468" },
  { value: "425", color: "#1c4368" },
  { value: "426", color: "#1c4368" },
  { value: "427", color: "#1c4367" },
  { value: "428", color: "#1c4367" },
  { value: "429", color: "#1c4367" },
  { value: "430", color: "#1c4367" },
  { value: "431", color: "#1c4267" },
  { value: "432", color: "#1c4267" },
  { value: "433", color: "#1c4267" },
  { value: "434", color: "#1c4267" },
  { value: "435", color: "#1c4267" },
  { value: "436", color: "#1b4267" },
  { value: "437", color: "#1b4167" },
  { value: "438", color: "#1b4167" },
  { value: "439", color: "#1b4167" },
  { value: "440", color: "#1b4167" },
  { value: "441", color: "#1b4167" },
  { value: "442", color: "#1b4167" },
  { value: "443", color: "#1b4067" },
  { value: "444", color: "#1b4066" },
  { value: "445", color: "#1b4066" },
  { value: "446", color: "#1b4066" },
  { value: "447", color: "#1a4066" },
  { value: "448", color: "#1a4066" },
  { value: "449", color: "#1a3f66" },
  { value: "450", color: "#1a3f66" },
  { value: "451", color: "#1a3f66" },
  { value: "452", color: "#1a3f66" },
  { value: "453", color: "#1a3f66" },
  { value: "454", color: "#1a3f66" },
  { value: "455", color: "#1a3e66" },
  { value: "456", color: "#1a3e66" },
  { value: "457", color: "#1a3e66" },
  { value: "458", color: "#1a3e66" },
  { value: "459", color: "#193e66" },
  { value: "460", color: "#193e65" },
  { value: "461", color: "#193d65" },
  { value: "462", color: "#193d65" },
  { value: "463", color: "#193d65" },
  { value: "464", color: "#193d65" },
  { value: "465", color: "#193d65" },
  { value: "466", color: "#193d65" },
  { value: "467", color: "#193c65" },
  { value: "468", color: "#193c65" },
  { value: "469", color: "#193c65" },
  { value: "470", color: "#193c65" },
  { value: "471", color: "#183c65" },
  { value: "472", color: "#183c65" },
  { value: "473", color: "#183b65" },
  { value: "474", color: "#183b65" },
  { value: "475", color: "#183b65" },
  { value: "476", color: "#183b64" },
  { value: "477", color: "#183b64" },
  { value: "478", color: "#183b64" },
  { value: "479", color: "#183a64" },
  { value: "480", color: "#183a64" },
  { value: "481", color: "#183a64" },
  { value: "482", color: "#183a64" },
  { value: "483", color: "#173a64" },
  { value: "484", color: "#173a64" },
  { value: "485", color: "#173964" },
  { value: "486", color: "#173964" },
  { value: "487", color: "#173964" },
  { value: "488", color: "#173964" },
  { value: "489", color: "#173964" },
  { value: "490", color: "#173964" },
  { value: "491", color: "#173864" },
  { value: "492", color: "#173863" },
  { value: "493", color: "#173863" },
  { value: "494", color: "#173863" },
  { value: "495", color: "#163863" },
  { value: "496", color: "#163863" },
  { value: "497", color: "#163763" },
  { value: "498", color: "#163763" },
  { value: "499", color: "#163763" },
  { value: "500", color: "#163763" },
];

const stabiliserArray = [
  { value: "0", color: "#b88549" },
  { value: "1", color: "#b88448" },
  { value: "2", color: "#b78348" },
  { value: "3", color: "#b78247" },
  { value: "4", color: "#b78247" },
  { value: "5", color: "#b78146" },
  { value: "6", color: "#b68046" },
  { value: "7", color: "#b67f45" },
  { value: "8", color: "#b67e44" },
  { value: "9", color: "#b67d44" },
  { value: "10", color: "#b57d43" },
  { value: "11", color: "#b57c43" },
  { value: "12", color: "#b57b42" },
  { value: "13", color: "#b57a41" },
  { value: "14", color: "#b47941" },
  { value: "15", color: "#b47840" },
  { value: "16", color: "#b47840" },
  { value: "17", color: "#b4773f" },
  { value: "18", color: "#b3763f" },
  { value: "19", color: "#b3753e" },
  { value: "20", color: "#b3743d" },
  { value: "21", color: "#b3733d" },
  { value: "22", color: "#b2733c" },
  { value: "23", color: "#b2723c" },
  { value: "24", color: "#b2713b" },
  { value: "25", color: "#b2703a" },
  { value: "26", color: "#b16f3a" },
  { value: "27", color: "#b16e39" },
  { value: "28", color: "#b16d39" },
  { value: "29", color: "#b06d38" },
  { value: "30", color: "#b06c38" },
  { value: "31", color: "#b06b37" },
  { value: "32", color: "#b06a36" },
  { value: "33", color: "#af6936" },
  { value: "34", color: "#af6835" },
  { value: "35", color: "#af6835" },
  { value: "36", color: "#af6734" },
  { value: "37", color: "#ae6634" },
  { value: "38", color: "#ae6533" },
  { value: "39", color: "#ae6432" },
  { value: "40", color: "#ae6332" },
  { value: "41", color: "#ad6331" },
  { value: "42", color: "#ad6231" },
  { value: "43", color: "#ad6130" },
  { value: "44", color: "#ad602f" },
  { value: "45", color: "#ac5f2f" },
  { value: "46", color: "#ac5e2e" },
  { value: "47", color: "#ac5e2e" },
  { value: "48", color: "#ac5d2d" },
  { value: "49", color: "#ab5c2d" },
  { value: "50", color: "#ab5b2c" },
  { value: "51", color: "#ab5a2d" },
  { value: "52", color: "#aa592d" },
  { value: "53", color: "#aa592e" },
  { value: "54", color: "#aa582e" },
  { value: "55", color: "#aa572f" },
  { value: "56", color: "#a95630" },
  { value: "57", color: "#a95630" },
  { value: "58", color: "#a95531" },
  { value: "59", color: "#a85431" },
  { value: "60", color: "#a85332" },
  { value: "61", color: "#a85333" },
  { value: "62", color: "#a85233" },
  { value: "63", color: "#a75134" },
  { value: "64", color: "#a75034" },
  { value: "65", color: "#a75035" },
  { value: "66", color: "#a74f36" },
  { value: "67", color: "#a64e36" },
  { value: "68", color: "#a64d37" },
  { value: "69", color: "#a64d37" },
  { value: "70", color: "#a54c38" },
  { value: "71", color: "#a54b39" },
  { value: "72", color: "#a54a39" },
  { value: "73", color: "#a54a3a" },
  { value: "74", color: "#a4493a" },
  { value: "75", color: "#a4483b" },
  { value: "76", color: "#a4473c" },
  { value: "77", color: "#a3463c" },
  { value: "78", color: "#a3463d" },
  { value: "79", color: "#a3453d" },
  { value: "80", color: "#a3443e" },
  { value: "81", color: "#a2433f" },
  { value: "82", color: "#a2433f" },
  { value: "83", color: "#a24240" },
  { value: "84", color: "#a14140" },
  { value: "85", color: "#a14041" },
  { value: "86", color: "#a14042" },
  { value: "87", color: "#a13f42" },
  { value: "88", color: "#a03e43" },
  { value: "89", color: "#a03d43" },
  { value: "90", color: "#a03d44" },
  { value: "91", color: "#a03c45" },
  { value: "92", color: "#9f3b45" },
  { value: "93", color: "#9f3a46" },
  { value: "94", color: "#9f3a46" },
  { value: "95", color: "#9e3947" },
  { value: "96", color: "#9e3848" },
  { value: "97", color: "#9e3748" },
  { value: "98", color: "#9e3749" },
  { value: "99", color: "#9d3649" },
  { value: "100", color: "#9d354a" },
  { value: "101", color: "#9c344a" },
  { value: "102", color: "#9c334a" },
  { value: "103", color: "#9b324b" },
  { value: "104", color: "#9a314b" },
  { value: "105", color: "#9a304b" },
  { value: "106", color: "#992f4b" },
  { value: "107", color: "#982e4b" },
  { value: "108", color: "#972d4b" },
  { value: "109", color: "#972c4c" },
  { value: "110", color: "#962b4c" },
  { value: "111", color: "#952a4c" },
  { value: "112", color: "#95294c" },
  { value: "113", color: "#94284c" },
  { value: "114", color: "#93274d" },
  { value: "115", color: "#92264d" },
  { value: "116", color: "#92254d" },
  { value: "117", color: "#91244d" },
  { value: "118", color: "#90234d" },
  { value: "119", color: "#90224d" },
  { value: "120", color: "#8f214e" },
  { value: "121", color: "#8e204e" },
  { value: "122", color: "#8e1f4e" },
  { value: "123", color: "#8d1e4e" },
  { value: "124", color: "#8c1d4e" },
  { value: "125", color: "#8c1c4e" },
  { value: "126", color: "#8b1c4f" },
  { value: "127", color: "#8a1b4f" },
  { value: "128", color: "#891a4f" },
  { value: "129", color: "#89194f" },
  { value: "130", color: "#88184f" },
  { value: "131", color: "#871750" },
  { value: "132", color: "#871650" },
  { value: "133", color: "#861550" },
  { value: "134", color: "#851450" },
  { value: "135", color: "#841350" },
  { value: "136", color: "#841250" },
  { value: "137", color: "#831151" },
  { value: "138", color: "#821051" },
  { value: "139", color: "#820f51" },
  { value: "140", color: "#810e51" },
  { value: "141", color: "#800d51" },
  { value: "142", color: "#800c52" },
  { value: "143", color: "#7f0b52" },
  { value: "144", color: "#7e0a52" },
  { value: "145", color: "#7e0952" },
  { value: "146", color: "#7d0852" },
  { value: "147", color: "#7c0752" },
  { value: "148", color: "#7b0653" },
  { value: "149", color: "#7b0553" },
  { value: "150", color: "#7a0453" },
  { value: "151", color: "#7a0453" },
  { value: "152", color: "#7a0453" },
  { value: "153", color: "#7a0454" },
  { value: "154", color: "#7a0454" },
  { value: "155", color: "#790454" },
  { value: "156", color: "#790454" },
  { value: "157", color: "#790454" },
  { value: "158", color: "#790454" },
  { value: "159", color: "#790455" },
  { value: "160", color: "#790455" },
  { value: "161", color: "#790555" },
  { value: "162", color: "#790555" },
  { value: "163", color: "#790555" },
  { value: "164", color: "#780556" },
  { value: "165", color: "#780556" },
  { value: "166", color: "#780556" },
  { value: "167", color: "#780556" },
  { value: "168", color: "#780556" },
  { value: "169", color: "#780556" },
  { value: "170", color: "#780557" },
  { value: "171", color: "#780557" },
  { value: "172", color: "#780557" },
  { value: "173", color: "#770557" },
  { value: "174", color: "#770557" },
  { value: "175", color: "#770558" },
  { value: "176", color: "#770558" },
  { value: "177", color: "#770558" },
  { value: "178", color: "#770558" },
  { value: "179", color: "#770558" },
  { value: "180", color: "#770558" },
  { value: "181", color: "#760559" },
  { value: "182", color: "#760559" },
  { value: "183", color: "#760659" },
  { value: "184", color: "#760659" },
  { value: "185", color: "#760659" },
  { value: "186", color: "#760659" },
  { value: "187", color: "#76065a" },
  { value: "188", color: "#76065a" },
  { value: "189", color: "#76065a" },
  { value: "190", color: "#75065a" },
  { value: "191", color: "#75065a" },
  { value: "192", color: "#75065b" },
  { value: "193", color: "#75065b" },
  { value: "194", color: "#75065b" },
  { value: "195", color: "#75065b" },
  { value: "196", color: "#75065b" },
  { value: "197", color: "#75065b" },
  { value: "198", color: "#75065c" },
  { value: "199", color: "#74065c" },
  { value: "200", color: "#74065c" },
  { value: "201", color: "#74065c" },
  { value: "202", color: "#74065c" },
  { value: "203", color: "#74065d" },
  { value: "204", color: "#74075d" },
  { value: "205", color: "#74075d" },
  { value: "206", color: "#74075d" },
  { value: "207", color: "#74075d" },
  { value: "208", color: "#73075d" },
  { value: "209", color: "#73075e" },
  { value: "210", color: "#73075e" },
  { value: "211", color: "#73075e" },
  { value: "212", color: "#73075e" },
  { value: "213", color: "#73075e" },
  { value: "214", color: "#73075f" },
  { value: "215", color: "#73075f" },
  { value: "216", color: "#73075f" },
  { value: "217", color: "#72075f" },
  { value: "218", color: "#72075f" },
  { value: "219", color: "#72075f" },
  { value: "220", color: "#720760" },
  { value: "221", color: "#720760" },
  { value: "222", color: "#720760" },
  { value: "223", color: "#720760" },
  { value: "224", color: "#720760" },
  { value: "225", color: "#720860" },
  { value: "226", color: "#710861" },
  { value: "227", color: "#710861" },
  { value: "228", color: "#710861" },
  { value: "229", color: "#710861" },
  { value: "230", color: "#710861" },
  { value: "231", color: "#710862" },
  { value: "232", color: "#710862" },
  { value: "233", color: "#710862" },
  { value: "234", color: "#700862" },
  { value: "235", color: "#700862" },
  { value: "236", color: "#700862" },
  { value: "237", color: "#700863" },
  { value: "238", color: "#700863" },
  { value: "239", color: "#700863" },
  { value: "240", color: "#700863" },
  { value: "241", color: "#700863" },
  { value: "242", color: "#700864" },
  { value: "243", color: "#6f0864" },
  { value: "244", color: "#6f0864" },
  { value: "245", color: "#6f0864" },
  { value: "246", color: "#6f0864" },
  { value: "247", color: "#6f0964" },
  { value: "248", color: "#6f0965" },
  { value: "249", color: "#6f0965" },
  { value: "250", color: "#6f0965" },
  { value: "251", color: "#6f0965" },
  { value: "252", color: "#6e0965" },
  { value: "253", color: "#6e0966" },
  { value: "254", color: "#6e0966" },
  { value: "255", color: "#6e0966" },
  { value: "256", color: "#6e0966" },
  { value: "257", color: "#6e0966" },
  { value: "258", color: "#6e0966" },
  { value: "259", color: "#6e0967" },
  { value: "260", color: "#6e0967" },
  { value: "261", color: "#6d0967" },
  { value: "262", color: "#6d0967" },
  { value: "263", color: "#6d0967" },
  { value: "264", color: "#6d0968" },
  { value: "265", color: "#6d0968" },
  { value: "266", color: "#6d0968" },
  { value: "267", color: "#6d0968" },
  { value: "268", color: "#6d0a68" },
  { value: "269", color: "#6d0a68" },
  { value: "270", color: "#6c0a69" },
  { value: "271", color: "#6c0a69" },
  { value: "272", color: "#6c0a69" },
  { value: "273", color: "#6c0a69" },
  { value: "274", color: "#6c0a69" },
  { value: "275", color: "#6c0a6a" },
  { value: "276", color: "#6c0a6a" },
  { value: "277", color: "#6c0a6a" },
  { value: "278", color: "#6b0a6a" },
  { value: "279", color: "#6b0a6a" },
  { value: "280", color: "#6b0a6a" },
  { value: "281", color: "#6b0a6b" },
  { value: "282", color: "#6b0a6b" },
  { value: "283", color: "#6b0a6b" },
  { value: "284", color: "#6b0a6b" },
  { value: "285", color: "#6b0a6b" },
  { value: "286", color: "#6b0a6b" },
  { value: "287", color: "#6a0a6c" },
  { value: "288", color: "#6a0a6c" },
  { value: "289", color: "#6a0a6c" },
  { value: "290", color: "#6a0b6c" },
  { value: "291", color: "#6a0b6c" },
  { value: "292", color: "#6a0b6d" },
  { value: "293", color: "#6a0b6d" },
  { value: "294", color: "#6a0b6d" },
  { value: "295", color: "#6a0b6d" },
  { value: "296", color: "#690b6d" },
  { value: "297", color: "#690b6d" },
  { value: "298", color: "#690b6e" },
  { value: "299", color: "#690b6e" },
  { value: "300", color: "#690b6e" },
  { value: "301", color: "#690b6e" },
  { value: "302", color: "#690b6e" },
  { value: "303", color: "#690b6e" },
  { value: "304", color: "#680b6d" },
  { value: "305", color: "#680b6d" },
  { value: "306", color: "#680b6d" },
  { value: "307", color: "#680b6d" },
  { value: "308", color: "#680b6d" },
  { value: "309", color: "#680b6d" },
  { value: "310", color: "#670b6c" },
  { value: "311", color: "#670b6c" },
  { value: "312", color: "#670b6c" },
  { value: "313", color: "#670b6c" },
  { value: "314", color: "#670b6c" },
  { value: "315", color: "#670b6c" },
  { value: "316", color: "#660b6b" },
  { value: "317", color: "#660b6b" },
  { value: "318", color: "#660b6b" },
  { value: "319", color: "#660b6b" },
  { value: "320", color: "#660b6b" },
  { value: "321", color: "#660b6b" },
  { value: "322", color: "#650b6a" },
  { value: "323", color: "#650b6a" },
  { value: "324", color: "#650b6a" },
  { value: "325", color: "#650a6a" },
  { value: "326", color: "#650a6a" },
  { value: "327", color: "#650a6a" },
  { value: "328", color: "#640a69" },
  { value: "329", color: "#640a69" },
  { value: "330", color: "#640a69" },
  { value: "331", color: "#640a69" },
  { value: "332", color: "#640a69" },
  { value: "333", color: "#640a69" },
  { value: "334", color: "#630a68" },
  { value: "335", color: "#630a68" },
  { value: "336", color: "#630a68" },
  { value: "337", color: "#630a68" },
  { value: "338", color: "#630a68" },
  { value: "339", color: "#630a68" },
  { value: "340", color: "#620a67" },
  { value: "341", color: "#620a67" },
  { value: "342", color: "#620a67" },
  { value: "343", color: "#620a67" },
  { value: "344", color: "#620a67" },
  { value: "345", color: "#620a67" },
  { value: "346", color: "#610a66" },
  { value: "347", color: "#610a66" },
  { value: "348", color: "#610a66" },
  { value: "349", color: "#610a66" },
  { value: "350", color: "#610a66" },
  { value: "351", color: "#610a66" },
  { value: "352", color: "#600a65" },
  { value: "353", color: "#600a65" },
  { value: "354", color: "#600a65" },
  { value: "355", color: "#600a65" },
  { value: "356", color: "#600a65" },
  { value: "357", color: "#600a65" },
  { value: "358", color: "#5f0a64" },
  { value: "359", color: "#5f0a64" },
  { value: "360", color: "#5f0a64" },
  { value: "361", color: "#5f0a64" },
  { value: "362", color: "#5f0a64" },
  { value: "363", color: "#5f0a64" },
  { value: "364", color: "#5e0a63" },
  { value: "365", color: "#5e0a63" },
  { value: "366", color: "#5e0a63" },
  { value: "367", color: "#5e0a63" },
  { value: "368", color: "#5e0a63" },
  { value: "369", color: "#5e0a63" },
  { value: "370", color: "#5d0a62" },
  { value: "371", color: "#5d0a62" },
  { value: "372", color: "#5d0a62" },
  { value: "373", color: "#5d0a62" },
  { value: "374", color: "#5d0a62" },
  { value: "375", color: "#5d0a62" },
  { value: "376", color: "#5c0961" },
  { value: "377", color: "#5c0961" },
  { value: "378", color: "#5c0961" },
  { value: "379", color: "#5c0961" },
  { value: "380", color: "#5c0961" },
  { value: "381", color: "#5c0961" },
  { value: "382", color: "#5b0960" },
  { value: "383", color: "#5b0960" },
  { value: "384", color: "#5b0960" },
  { value: "385", color: "#5b0960" },
  { value: "386", color: "#5b0960" },
  { value: "387", color: "#5b0960" },
  { value: "388", color: "#5a095f" },
  { value: "389", color: "#5a095f" },
  { value: "390", color: "#5a095f" },
  { value: "391", color: "#5a095f" },
  { value: "392", color: "#5a095f" },
  { value: "393", color: "#5a095f" },
  { value: "394", color: "#59095e" },
  { value: "395", color: "#59095e" },
  { value: "396", color: "#59095e" },
  { value: "397", color: "#59095e" },
  { value: "398", color: "#59095e" },
  { value: "399", color: "#59095e" },
  { value: "400", color: "#58095e" },
  { value: "401", color: "#58095d" },
  { value: "402", color: "#58095d" },
  { value: "403", color: "#58095d" },
  { value: "404", color: "#58095d" },
  { value: "405", color: "#58095d" },
  { value: "406", color: "#58095d" },
  { value: "407", color: "#57095c" },
  { value: "408", color: "#57095c" },
  { value: "409", color: "#57095c" },
  { value: "410", color: "#57095c" },
  { value: "411", color: "#57095c" },
  { value: "412", color: "#57095c" },
  { value: "413", color: "#56095b" },
  { value: "414", color: "#56095b" },
  { value: "415", color: "#56095b" },
  { value: "416", color: "#56095b" },
  { value: "417", color: "#56095b" },
  { value: "418", color: "#56095b" },
  { value: "419", color: "#55095a" },
  { value: "420", color: "#55095a" },
  { value: "421", color: "#55095a" },
  { value: "422", color: "#55095a" },
  { value: "423", color: "#55095a" },
  { value: "424", color: "#55095a" },
  { value: "425", color: "#540859" },
  { value: "426", color: "#540859" },
  { value: "427", color: "#540859" },
  { value: "428", color: "#540859" },
  { value: "429", color: "#540859" },
  { value: "430", color: "#540859" },
  { value: "431", color: "#530858" },
  { value: "432", color: "#530858" },
  { value: "433", color: "#530858" },
  { value: "434", color: "#530858" },
  { value: "435", color: "#530858" },
  { value: "436", color: "#530858" },
  { value: "437", color: "#520857" },
  { value: "438", color: "#520857" },
  { value: "439", color: "#520857" },
  { value: "440", color: "#520857" },
  { value: "441", color: "#520857" },
  { value: "442", color: "#520857" },
  { value: "443", color: "#510856" },
  { value: "444", color: "#510856" },
  { value: "445", color: "#510856" },
  { value: "446", color: "#510856" },
  { value: "447", color: "#510856" },
  { value: "448", color: "#510856" },
  { value: "449", color: "#500855" },
  { value: "450", color: "#500855" },
  { value: "451", color: "#500855" },
  { value: "452", color: "#500855" },
  { value: "453", color: "#500855" },
  { value: "454", color: "#500855" },
  { value: "455", color: "#4f0854" },
  { value: "456", color: "#4f0854" },
  { value: "457", color: "#4f0854" },
  { value: "458", color: "#4f0854" },
  { value: "459", color: "#4f0854" },
  { value: "460", color: "#4f0854" },
  { value: "461", color: "#4e0853" },
  { value: "462", color: "#4e0853" },
  { value: "463", color: "#4e0853" },
  { value: "464", color: "#4e0853" },
  { value: "465", color: "#4e0853" },
  { value: "466", color: "#4e0853" },
  { value: "467", color: "#4d0852" },
  { value: "468", color: "#4d0852" },
  { value: "469", color: "#4d0852" },
  { value: "470", color: "#4d0852" },
  { value: "471", color: "#4d0852" },
  { value: "472", color: "#4d0852" },
  { value: "473", color: "#4c0851" },
  { value: "474", color: "#4c0851" },
  { value: "475", color: "#4c0851" },
  { value: "476", color: "#4c0751" },
  { value: "477", color: "#4c0751" },
  { value: "478", color: "#4c0751" },
  { value: "479", color: "#4b0750" },
  { value: "480", color: "#4b0750" },
  { value: "481", color: "#4b0750" },
  { value: "482", color: "#4b0750" },
  { value: "483", color: "#4b0750" },
  { value: "484", color: "#4b0750" },
  { value: "485", color: "#4a074f" },
  { value: "486", color: "#4a074f" },
  { value: "487", color: "#4a074f" },
  { value: "488", color: "#4a074f" },
  { value: "489", color: "#4a074f" },
  { value: "490", color: "#4a074f" },
  { value: "491", color: "#49074e" },
  { value: "492", color: "#49074e" },
  { value: "493", color: "#49074e" },
  { value: "494", color: "#49074e" },
  { value: "495", color: "#49074e" },
  { value: "496", color: "#49074e" },
  { value: "497", color: "#48074d" },
  { value: "498", color: "#48074d" },
  { value: "499", color: "#48074d" },
  { value: "500", color: "#48074d" },
];

const chlorineArray = [
  { value: "0.0", color: "#bec6bc" },
  { value: "0.1", color: "#bcc3ba" },
  { value: "0.2", color: "#b9c0b8" },
  { value: "0.3", color: "#b7bcb5" },
  { value: "0.4", color: "#b4b9b3" },
  { value: "0.5", color: "#b2b6b1" },
  { value: "0.6", color: "#b2b4b3" },
  { value: "0.7", color: "#b2b3b5" },
  { value: "0.8", color: "#b1b1b7" },
  { value: "0.9", color: "#b1b0b9" },
  { value: "1.0", color: "#b1aebb" },
  { value: "1.1", color: "#b0acbb" },
  { value: "1.2", color: "#afa9bb" },
  { value: "1.3", color: "#ada7bb" },
  { value: "1.4", color: "#aca4ba" },
  { value: "1.5", color: "#aba2ba" },
  { value: "1.6", color: "#aa9fba" },
  { value: "1.7", color: "#a99dba" },
  { value: "1.8", color: "#a79aba" },
  { value: "1.9", color: "#a698ba" },
  { value: "2.0", color: "#a596ba" },
  { value: "2.1", color: "#a493b9" },
  { value: "2.2", color: "#a391b9" },
  { value: "2.3", color: "#a18eb9" },
  { value: "2.4", color: "#a08cb9" },
  { value: "2.5", color: "#9f89b9" },
  { value: "2.6", color: "#9e87b9" },
  { value: "2.7", color: "#9d84b8" },
  { value: "2.8", color: "#9b82b8" },
  { value: "2.9", color: "#9a7fb8" },
  { value: "3.0", color: "#997db8" },
  { value: "3.1", color: "#987bb7" },
  { value: "3.2", color: "#9678b6" },
  { value: "3.3", color: "#9576b4" },
  { value: "3.4", color: "#9374b3" },
  { value: "3.5", color: "#9272b2" },
  { value: "3.6", color: "#9170b0" },
  { value: "3.7", color: "#8f6daf" },
  { value: "3.8", color: "#8e6bae" },
  { value: "3.9", color: "#8c69ad" },
  { value: "4.0", color: "#8b66ac" },
  { value: "4.1", color: "#8a64aa" },
  { value: "4.2", color: "#8862a9" },
  { value: "4.3", color: "#8760a8" },
  { value: "4.4", color: "#855ea6" },
  { value: "4.5", color: "#845ba5" },
  { value: "4.6", color: "#8359a4" },
  { value: "4.7", color: "#8157a3" },
  { value: "4.8", color: "#8054a2" },
  { value: "4.9", color: "#7e52a0" },
  { value: "5.0", color: "#7d509f" },
  { value: "5.1", color: "#7c4f9e" },
  { value: "5.2", color: "#7c4e9d" },
  { value: "5.3", color: "#7b4d9d" },
  { value: "5.4", color: "#7b4d9c" },
  { value: "5.5", color: "#7a4c9b" },
  { value: "5.6", color: "#7a4b9a" },
  { value: "5.7", color: "#794a9a" },
  { value: "5.8", color: "#794999" },
  { value: "5.9", color: "#784898" },
  { value: "6.0", color: "#784797" },
  { value: "6.1", color: "#774797" },
  { value: "6.2", color: "#774696" },
  { value: "6.3", color: "#764595" },
  { value: "6.4", color: "#754494" },
  { value: "6.5", color: "#754394" },
  { value: "6.6", color: "#744293" },
  { value: "6.7", color: "#744192" },
  { value: "6.8", color: "#734191" },
  { value: "6.9", color: "#734091" },
  { value: "7.0", color: "#723f90" },
  { value: "7.1", color: "#723e8f" },
  { value: "7.2", color: "#713d8e" },
  { value: "7.3", color: "#713c8e" },
  { value: "7.4", color: "#703b8d" },
  { value: "7.5", color: "#703a8c" },
  { value: "7.6", color: "#6f3a8b" },
  { value: "7.7", color: "#6e398a" },
  { value: "7.8", color: "#6e388a" },
  { value: "7.9", color: "#6d3789" },
  { value: "8.0", color: "#6d3688" },
  { value: "8.1", color: "#6c3587" },
  { value: "8.2", color: "#6c3487" },
  { value: "8.3", color: "#6b3486" },
  { value: "8.4", color: "#6b3385" },
  { value: "8.5", color: "#6a3284" },
  { value: "8.6", color: "#6a3184" },
  { value: "8.7", color: "#693083" },
  { value: "8.8", color: "#682f82" },
  { value: "8.9", color: "#682e81" },
  { value: "9.0", color: "#672e81" },
  { value: "9.1", color: "#672d80" },
  { value: "9.2", color: "#662c7f" },
  { value: "9.3", color: "#662b7e" },
  { value: "9.4", color: "#652a7e" },
  { value: "9.5", color: "#65297d" },
  { value: "9.6", color: "#64287c" },
  { value: "9.7", color: "#64287b" },
  { value: "9.8", color: "#63277b" },
  { value: "9.9", color: "#63267a" },
  { value: "10.0", color: "#622579" },
  { value: "10.1", color: "#622579" },
  { value: "10.2", color: "#612578" },
  { value: "10.3", color: "#612478" },
  { value: "10.4", color: "#612478" },
  { value: "10.5", color: "#602478" },
  { value: "10.6", color: "#602477" },
  { value: "10.7", color: "#602377" },
  { value: "10.8", color: "#602377" },
  { value: "10.9", color: "#5f2377" },
  { value: "11.0", color: "#5f2376" },
  { value: "11.1", color: "#5f2276" },
  { value: "11.2", color: "#5e2276" },
  { value: "11.3", color: "#5e2275" },
  { value: "11.4", color: "#5e2275" },
  { value: "11.5", color: "#5e2175" },
  { value: "11.6", color: "#5d2175" },
  { value: "11.7", color: "#5d2174" },
  { value: "11.8", color: "#5d2174" },
  { value: "11.9", color: "#5c2074" },
  { value: "12.0", color: "#5c2074" },
  { value: "12.1", color: "#5c2073" },
  { value: "12.2", color: "#5b2073" },
  { value: "12.3", color: "#5b1f73" },
  { value: "12.4", color: "#5b1f73" },
  { value: "12.5", color: "#5a1f72" },
  { value: "12.6", color: "#5a1f72" },
  { value: "12.7", color: "#5a1f72" },
  { value: "12.8", color: "#5a1e71" },
  { value: "12.9", color: "#591e71" },
  { value: "13.0", color: "#591e71" },
  { value: "13.1", color: "#591e71" },
  { value: "13.2", color: "#581d70" },
  { value: "13.3", color: "#581d70" },
  { value: "13.4", color: "#581d70" },
  { value: "13.5", color: "#581d70" },
  { value: "13.6", color: "#571c6f" },
  { value: "13.7", color: "#571c6f" },
  { value: "13.8", color: "#571c6f" },
  { value: "13.9", color: "#561c6e" },
  { value: "14.0", color: "#561b6e" },
  { value: "14.1", color: "#561b6e" },
  { value: "14.2", color: "#551b6e" },
  { value: "14.3", color: "#551b6d" },
  { value: "14.4", color: "#551a6d" },
  { value: "14.5", color: "#541a6d" },
  { value: "14.6", color: "#541a6d" },
  { value: "14.7", color: "#541a6c" },
  { value: "14.8", color: "#54196c" },
  { value: "14.9", color: "#53196c" },
  { value: "15.0", color: "#53196c" },
  { value: "15.1", color: "#53196b" },
  { value: "15.2", color: "#52196b" },
  { value: "15.3", color: "#52186b" },
  { value: "15.4", color: "#52186a" },
  { value: "15.5", color: "#52186a" },
  { value: "15.6", color: "#51186a" },
  { value: "15.7", color: "#51176a" },
  { value: "15.8", color: "#511769" },
  { value: "15.9", color: "#501769" },
  { value: "16.0", color: "#501769" },
  { value: "16.1", color: "#501669" },
  { value: "16.2", color: "#4f1668" },
  { value: "16.3", color: "#4f1668" },
  { value: "16.4", color: "#4f1668" },
  { value: "16.5", color: "#4e1567" },
  { value: "16.6", color: "#4e1567" },
  { value: "16.7", color: "#4e1567" },
  { value: "16.8", color: "#4e1567" },
  { value: "16.9", color: "#4d1466" },
  { value: "17.0", color: "#4d1466" },
  { value: "17.1", color: "#4d1466" },
  { value: "17.2", color: "#4c1466" },
  { value: "17.3", color: "#4c1365" },
  { value: "17.4", color: "#4c1365" },
  { value: "17.5", color: "#4c1365" },
  { value: "17.6", color: "#4b1364" },
  { value: "17.7", color: "#4b1364" },
  { value: "17.8", color: "#4b1264" },
  { value: "17.9", color: "#4a1264" },
  { value: "18.0", color: "#4a1263" },
  { value: "18.1", color: "#4a1263" },
  { value: "18.2", color: "#491163" },
  { value: "18.3", color: "#491163" },
  { value: "18.4", color: "#491162" },
  { value: "18.5", color: "#481162" },
  { value: "18.6", color: "#481062" },
  { value: "18.7", color: "#481062" },
  { value: "18.8", color: "#481061" },
  { value: "18.9", color: "#471061" },
  { value: "19.0", color: "#470f61" },
  { value: "19.1", color: "#470f60" },
  { value: "19.2", color: "#460f60" },
  { value: "19.3", color: "#460f60" },
  { value: "19.4", color: "#460e60" },
  { value: "19.5", color: "#460e5f" },
  { value: "19.6", color: "#450e5f" },
  { value: "19.7", color: "#450e5f" },
  { value: "19.8", color: "#450d5f" },
  { value: "19.9", color: "#440d5e" },
  { value: "20.0", color: "#440d5e" },
  { value: "20.1", color: "#440d5e" },
  { value: "20.2", color: "#440d5e" },
  { value: "20.3", color: "#440d5e" },
  { value: "20.4", color: "#440d5d" },
  { value: "20.5", color: "#430d5d" },
  { value: "20.6", color: "#430d5d" },
  { value: "20.7", color: "#430d5d" },
  { value: "20.8", color: "#430d5d" },
  { value: "20.9", color: "#430d5d" },
  { value: "21.0", color: "#430d5d" },
  { value: "21.1", color: "#430d5c" },
  { value: "21.2", color: "#430d5c" },
  { value: "21.3", color: "#430d5c" },
  { value: "21.4", color: "#430d5c" },
  { value: "21.5", color: "#420d5c" },
  { value: "21.6", color: "#420d5c" },
  { value: "21.7", color: "#420d5c" },
  { value: "21.8", color: "#420d5b" },
  { value: "21.9", color: "#420d5b" },
  { value: "22.0", color: "#420d5b" },
  { value: "22.1", color: "#420d5b" },
  { value: "22.2", color: "#420d5b" },
  { value: "22.3", color: "#420d5b" },
  { value: "22.4", color: "#410d5b" },
  { value: "22.5", color: "#410c5a" },
  { value: "22.6", color: "#410c5a" },
  { value: "22.7", color: "#410c5a" },
  { value: "22.8", color: "#410c5a" },
  { value: "22.9", color: "#410c5a" },
  { value: "23.0", color: "#410c5a" },
  { value: "23.1", color: "#410c5a" },
  { value: "23.2", color: "#410c5a" },
  { value: "23.3", color: "#400c59" },
  { value: "23.4", color: "#400c59" },
  { value: "23.5", color: "#400c59" },
  { value: "23.6", color: "#400c59" },
  { value: "23.7", color: "#400c59" },
  { value: "23.8", color: "#400c59" },
  { value: "23.9", color: "#400c59" },
  { value: "24.0", color: "#400c58" },
  { value: "24.1", color: "#400c58" },
  { value: "24.2", color: "#400c58" },
  { value: "24.3", color: "#3f0c58" },
  { value: "24.4", color: "#3f0c58" },
  { value: "24.5", color: "#3f0c58" },
  { value: "24.6", color: "#3f0c58" },
  { value: "24.7", color: "#3f0c57" },
  { value: "24.8", color: "#3f0c57" },
  { value: "24.9", color: "#3f0c57" },
  { value: "25.0", color: "#3f0c57" },
  { value: "25.1", color: "#3f0c57" },
  { value: "25.2", color: "#3e0c57" },
  { value: "25.3", color: "#3e0c57" },
  { value: "25.4", color: "#3e0c56" },
  { value: "25.5", color: "#3e0c56" },
  { value: "25.6", color: "#3e0c56" },
  { value: "25.7", color: "#3e0c56" },
  { value: "25.8", color: "#3e0c56" },
  { value: "25.9", color: "#3e0c56" },
  { value: "26.0", color: "#3e0c56" },
  { value: "26.1", color: "#3d0c55" },
  { value: "26.2", color: "#3d0c55" },
  { value: "26.3", color: "#3d0c55" },
  { value: "26.4", color: "#3d0c55" },
  { value: "26.5", color: "#3d0c55" },
  { value: "26.6", color: "#3d0c55" },
  { value: "26.7", color: "#3d0c55" },
  { value: "26.8", color: "#3d0c54" },
  { value: "26.9", color: "#3d0c54" },
  { value: "27.0", color: "#3d0c54" },
  { value: "27.1", color: "#3c0c54" },
  { value: "27.2", color: "#3c0c54" },
  { value: "27.3", color: "#3c0c54" },
  { value: "27.4", color: "#3c0c54" },
  { value: "27.5", color: "#3c0c54" },
  { value: "27.6", color: "#3c0b53" },
  { value: "27.7", color: "#3c0b53" },
  { value: "27.8", color: "#3c0b53" },
  { value: "27.9", color: "#3c0b53" },
  { value: "28.0", color: "#3b0b53" },
  { value: "28.1", color: "#3b0b53" },
  { value: "28.2", color: "#3b0b53" },
  { value: "28.3", color: "#3b0b52" },
  { value: "28.4", color: "#3b0b52" },
  { value: "28.5", color: "#3b0b52" },
  { value: "28.6", color: "#3b0b52" },
  { value: "28.7", color: "#3b0b52" },
  { value: "28.8", color: "#3b0b52" },
  { value: "28.9", color: "#3b0b52" },
  { value: "29.0", color: "#3a0b51" },
  { value: "29.1", color: "#3a0b51" },
  { value: "29.2", color: "#3a0b51" },
  { value: "29.3", color: "#3a0b51" },
  { value: "29.4", color: "#3a0b51" },
  { value: "29.5", color: "#3a0b51" },
  { value: "29.6", color: "#3a0b51" },
  { value: "29.7", color: "#3a0b50" },
  { value: "29.8", color: "#3a0b50" },
  { value: "29.9", color: "#390b50" },
  { value: "30.0", color: "#390b50" },
  { value: "30.1", color: "#390b50" },
  { value: "30.2", color: "#390b50" },
  { value: "30.3", color: "#390b50" },
  { value: "30.4", color: "#390b4f" },
  { value: "30.5", color: "#390b4f" },
  { value: "30.6", color: "#390b4f" },
  { value: "30.7", color: "#390b4f" },
  { value: "30.8", color: "#380b4f" },
  { value: "30.9", color: "#380b4f" },
  { value: "31.0", color: "#380b4f" },
  { value: "31.1", color: "#380b4e" },
  { value: "31.2", color: "#380b4e" },
  { value: "31.3", color: "#380b4e" },
  { value: "31.4", color: "#380b4e" },
  { value: "31.5", color: "#380b4e" },
  { value: "31.6", color: "#380b4e" },
  { value: "31.7", color: "#380b4e" },
  { value: "31.8", color: "#370b4d" },
  { value: "31.9", color: "#370b4d" },
  { value: "32.0", color: "#370b4d" },
  { value: "32.1", color: "#370b4d" },
  { value: "32.2", color: "#370b4d" },
  { value: "32.3", color: "#370b4d" },
  { value: "32.4", color: "#370b4d" },
  { value: "32.5", color: "#370a4c" },
  { value: "32.6", color: "#370a4c" },
  { value: "32.7", color: "#360a4c" },
  { value: "32.8", color: "#360a4c" },
  { value: "32.9", color: "#360a4c" },
  { value: "33.0", color: "#360a4c" },
  { value: "33.1", color: "#360a4c" },
  { value: "33.2", color: "#360a4c" },
  { value: "33.3", color: "#360a4b" },
  { value: "33.4", color: "#360a4b" },
  { value: "33.5", color: "#360a4b" },
  { value: "33.6", color: "#350a4b" },
  { value: "33.7", color: "#350a4b" },
  { value: "33.8", color: "#350a4b" },
  { value: "33.9", color: "#350a4b" },
  { value: "34.0", color: "#350a4a" },
  { value: "34.1", color: "#350a4a" },
  { value: "34.2", color: "#350a4a" },
  { value: "34.3", color: "#350a4a" },
  { value: "34.4", color: "#350a4a" },
  { value: "34.5", color: "#350a4a" },
  { value: "34.6", color: "#340a4a" },
  { value: "34.7", color: "#340a49" },
  { value: "34.8", color: "#340a49" },
  { value: "34.9", color: "#340a49" },
  { value: "35.0", color: "#340a49" },
  { value: "35.1", color: "#340a49" },
  { value: "35.2", color: "#340a49" },
  { value: "35.3", color: "#340a49" },
  { value: "35.4", color: "#340a48" },
  { value: "35.5", color: "#330a48" },
  { value: "35.6", color: "#330a48" },
  { value: "35.7", color: "#330a48" },
  { value: "35.8", color: "#330a48" },
  { value: "35.9", color: "#330a48" },
  { value: "36.0", color: "#330a48" },
  { value: "36.1", color: "#330a47" },
  { value: "36.2", color: "#330a47" },
  { value: "36.3", color: "#330a47" },
  { value: "36.4", color: "#330a47" },
  { value: "36.5", color: "#320a47" },
  { value: "36.6", color: "#320a47" },
  { value: "36.7", color: "#320a47" },
  { value: "36.8", color: "#320a46" },
  { value: "36.9", color: "#320a46" },
  { value: "37.0", color: "#320a46" },
  { value: "37.1", color: "#320a46" },
  { value: "37.2", color: "#320a46" },
  { value: "37.3", color: "#320a46" },
  { value: "37.4", color: "#310a46" },
  { value: "37.5", color: "#310a46" },
  { value: "37.6", color: "#310945" },
  { value: "37.7", color: "#310945" },
  { value: "37.8", color: "#310945" },
  { value: "37.9", color: "#310945" },
  { value: "38.0", color: "#310945" },
  { value: "38.1", color: "#310945" },
  { value: "38.2", color: "#310945" },
  { value: "38.3", color: "#300944" },
  { value: "38.4", color: "#300944" },
  { value: "38.5", color: "#300944" },
  { value: "38.6", color: "#300944" },
  { value: "38.7", color: "#300944" },
  { value: "38.8", color: "#300944" },
  { value: "38.9", color: "#300944" },
  { value: "39.0", color: "#300943" },
  { value: "39.1", color: "#300943" },
  { value: "39.2", color: "#300943" },
  { value: "39.3", color: "#2f0943" },
  { value: "39.4", color: "#2f0943" },
  { value: "39.5", color: "#2f0943" },
  { value: "39.6", color: "#2f0943" },
  { value: "39.7", color: "#2f0942" },
  { value: "39.8", color: "#2f0942" },
  { value: "39.9", color: "#2f0942" },
  { value: "40.0", color: "#2f0942" },
  { value: "40.1", color: "#2f0942" },
  { value: "40.2", color: "#2e0942" },
  { value: "40.3", color: "#2e0942" },
  { value: "40.4", color: "#2e0941" },
  { value: "40.5", color: "#2e0941" },
  { value: "40.6", color: "#2e0941" },
  { value: "40.7", color: "#2e0941" },
  { value: "40.8", color: "#2e0941" },
  { value: "40.9", color: "#2e0941" },
  { value: "41.0", color: "#2e0941" },
  { value: "41.1", color: "#2d0940" },
  { value: "41.2", color: "#2d0940" },
  { value: "41.3", color: "#2d0940" },
  { value: "41.4", color: "#2d0940" },
  { value: "41.5", color: "#2d0940" },
  { value: "41.6", color: "#2d0940" },
  { value: "41.7", color: "#2d0940" },
  { value: "41.8", color: "#2d093f" },
  { value: "41.9", color: "#2d093f" },
  { value: "42.0", color: "#2d093f" },
  { value: "42.1", color: "#2c093f" },
  { value: "42.2", color: "#2c093f" },
  { value: "42.3", color: "#2c093f" },
  { value: "42.4", color: "#2c093f" },
  { value: "42.5", color: "#2c083e" },
  { value: "42.6", color: "#2c083e" },
  { value: "42.7", color: "#2c083e" },
  { value: "42.8", color: "#2c083e" },
  { value: "42.9", color: "#2c083e" },
  { value: "43.0", color: "#2b083e" },
  { value: "43.1", color: "#2b083e" },
  { value: "43.2", color: "#2b083e" },
  { value: "43.3", color: "#2b083d" },
  { value: "43.4", color: "#2b083d" },
  { value: "43.5", color: "#2b083d" },
  { value: "43.6", color: "#2b083d" },
  { value: "43.7", color: "#2b083d" },
  { value: "43.8", color: "#2b083d" },
  { value: "43.9", color: "#2b083d" },
  { value: "44.0", color: "#2a083c" },
  { value: "44.1", color: "#2a083c" },
  { value: "44.2", color: "#2a083c" },
  { value: "44.3", color: "#2a083c" },
  { value: "44.4", color: "#2a083c" },
  { value: "44.5", color: "#2a083c" },
  { value: "44.6", color: "#2a083c" },
  { value: "44.7", color: "#2a083b" },
  { value: "44.8", color: "#2a083b" },
  { value: "44.9", color: "#29083b" },
  { value: "45.0", color: "#29083b" },
  { value: "45.1", color: "#29083b" },
  { value: "45.2", color: "#29083b" },
  { value: "45.3", color: "#29083b" },
  { value: "45.4", color: "#29083a" },
  { value: "45.5", color: "#29083a" },
  { value: "45.6", color: "#29083a" },
  { value: "45.7", color: "#29083a" },
  { value: "45.8", color: "#28083a" },
  { value: "45.9", color: "#28083a" },
  { value: "46.0", color: "#28083a" },
  { value: "46.1", color: "#280839" },
  { value: "46.2", color: "#280839" },
  { value: "46.3", color: "#280839" },
  { value: "46.4", color: "#280839" },
  { value: "46.5", color: "#280839" },
  { value: "46.6", color: "#280839" },
  { value: "46.7", color: "#280839" },
  { value: "46.8", color: "#270838" },
  { value: "46.9", color: "#270838" },
  { value: "47.0", color: "#270838" },
  { value: "47.1", color: "#270838" },
  { value: "47.2", color: "#270838" },
  { value: "47.3", color: "#270838" },
  { value: "47.4", color: "#270838" },
  { value: "47.5", color: "#270837" },
  { value: "47.6", color: "#270737" },
  { value: "47.7", color: "#260737" },
  { value: "47.8", color: "#260737" },
  { value: "47.9", color: "#260737" },
  { value: "48.0", color: "#260737" },
  { value: "48.1", color: "#260737" },
  { value: "48.2", color: "#260737" },
  { value: "48.3", color: "#260736" },
  { value: "48.4", color: "#260736" },
  { value: "48.5", color: "#260736" },
  { value: "48.6", color: "#250736" },
  { value: "48.7", color: "#250736" },
  { value: "48.8", color: "#250736" },
  { value: "48.9", color: "#250736" },
  { value: "49.0", color: "#250735" },
  { value: "49.1", color: "#250735" },
  { value: "49.2", color: "#250735" },
  { value: "49.3", color: "#250735" },
  { value: "49.4", color: "#250735" },
  { value: "49.5", color: "#250735" },
  { value: "49.6", color: "#240735" },
  { value: "49.7", color: "#240734" },
  { value: "49.8", color: "#240734" },
  { value: "49.9", color: "#240734" },
  { value: "50.0", color: "#240734" },
];

const combinedChlorineArray = [
  { value: "0.0", color: "#cfcf97" },
  { value: "0.1", color: "#cccf98" },
  { value: "0.2", color: "#c9ce9a" },
  { value: "0.3", color: "#c6ce9b" },
  { value: "0.4", color: "#c3cd9d" },
  { value: "0.5", color: "#c0cd9e" },
  { value: "0.6", color: "#becc9e" },
  { value: "0.7", color: "#bdcc9e" },
  { value: "0.8", color: "#bbcb9f" },
  { value: "0.9", color: "#bacb9f" },
  { value: "1.0", color: "#b8ca9f" },
  { value: "1.1", color: "#b6ca9f" },
  { value: "1.2", color: "#b5c99f" },
  { value: "1.3", color: "#b3c99f" },
  { value: "1.4", color: "#b1c89e" },
  { value: "1.5", color: "#b0c89e" },
  { value: "1.6", color: "#aec79e" },
  { value: "1.7", color: "#acc79e" },
  { value: "1.8", color: "#aac69e" },
  { value: "1.9", color: "#a9c69e" },
  { value: "2.0", color: "#a7c69e" },
  { value: "2.1", color: "#a5c59d" },
  { value: "2.2", color: "#a4c59d" },
  { value: "2.3", color: "#a2c49d" },
  { value: "2.4", color: "#a0c49d" },
  { value: "2.5", color: "#9ec39d" },
  { value: "2.6", color: "#9dc39d" },
  { value: "2.7", color: "#9bc29c" },
  { value: "2.8", color: "#99c29c" },
  { value: "2.9", color: "#98c19c" },
  { value: "3.0", color: "#96c19c" },
  { value: "3.1", color: "#94c19c" },
  { value: "3.2", color: "#93c09c" },
  { value: "3.3", color: "#91c09c" },
  { value: "3.4", color: "#90bf9c" },
  { value: "3.5", color: "#8ebf9c" },
  { value: "3.6", color: "#8dbe9c" },
  { value: "3.7", color: "#8bbe9c" },
  { value: "3.8", color: "#8abd9c" },
  { value: "3.9", color: "#88bd9c" },
  { value: "4.0", color: "#86bc9c" },
  { value: "4.1", color: "#85bc9c" },
  { value: "4.2", color: "#83bc9c" },
  { value: "4.3", color: "#82bb9c" },
  { value: "4.4", color: "#80bb9c" },
  { value: "4.5", color: "#7fba9c" },
  { value: "4.6", color: "#7dba9c" },
  { value: "4.7", color: "#7cb99c" },
  { value: "4.8", color: "#7ab99c" },
  { value: "4.9", color: "#79b89c" },
  { value: "5.0", color: "#77b89c" },
  { value: "5.1", color: "#76b89c" },
  { value: "5.2", color: "#76b89c" },
  { value: "5.3", color: "#75b89c" },
  { value: "5.4", color: "#74b89c" },
  { value: "5.5", color: "#74b79c" },
  { value: "5.6", color: "#73b79c" },
  { value: "5.7", color: "#72b79c" },
  { value: "5.8", color: "#72b79c" },
  { value: "5.9", color: "#71b79b" },
  { value: "6.0", color: "#70b79b" },
  { value: "6.1", color: "#70b79b" },
  { value: "6.2", color: "#6fb79b" },
  { value: "6.3", color: "#6eb69b" },
  { value: "6.4", color: "#6eb69b" },
  { value: "6.5", color: "#6db69b" },
  { value: "6.6", color: "#6cb69b" },
  { value: "6.7", color: "#6cb69b" },
  { value: "6.8", color: "#6bb69b" },
  { value: "6.9", color: "#6ab69b" },
  { value: "7.0", color: "#6ab69b" },
  { value: "7.1", color: "#69b59b" },
  { value: "7.2", color: "#68b59b" },
  { value: "7.3", color: "#68b59b" },
  { value: "7.4", color: "#67b59b" },
  { value: "7.5", color: "#66b59a" },
  { value: "7.6", color: "#66b59a" },
  { value: "7.7", color: "#65b59a" },
  { value: "7.8", color: "#65b59a" },
  { value: "7.9", color: "#64b59a" },
  { value: "8.0", color: "#63b49a" },
  { value: "8.1", color: "#63b49a" },
  { value: "8.2", color: "#62b49a" },
  { value: "8.3", color: "#61b49a" },
  { value: "8.4", color: "#61b49a" },
  { value: "8.5", color: "#60b49a" },
  { value: "8.6", color: "#5fb49a" },
  { value: "8.7", color: "#5fb49a" },
  { value: "8.8", color: "#5eb39a" },
  { value: "8.9", color: "#5db39a" },
  { value: "9.0", color: "#5db39a" },
  { value: "9.1", color: "#5cb39a" },
  { value: "9.2", color: "#5bb399" },
  { value: "9.3", color: "#5bb399" },
  { value: "9.4", color: "#5ab399" },
  { value: "9.5", color: "#59b399" },
  { value: "9.6", color: "#59b299" },
  { value: "9.7", color: "#58b299" },
  { value: "9.8", color: "#57b299" },
  { value: "9.9", color: "#57b299" },
  { value: "10.0", color: "#56b299" },
  { value: "10.1", color: "#56b299" },
  { value: "10.2", color: "#56b198" },
  { value: "10.3", color: "#55b198" },
  { value: "10.4", color: "#55b198" },
  { value: "10.5", color: "#55b097" },
  { value: "10.6", color: "#55b097" },
  { value: "10.7", color: "#55b097" },
  { value: "10.8", color: "#54af97" },
  { value: "10.9", color: "#54af96" },
  { value: "11.0", color: "#54af96" },
  { value: "11.1", color: "#54ae96" },
  { value: "11.2", color: "#54ae95" },
  { value: "11.3", color: "#53ae95" },
  { value: "11.4", color: "#53ad95" },
  { value: "11.5", color: "#53ad94" },
  { value: "11.6", color: "#53ad94" },
  { value: "11.7", color: "#53ad94" },
  { value: "11.8", color: "#52ac93" },
  { value: "11.9", color: "#52ac93" },
  { value: "12.0", color: "#52ac93" },
  { value: "12.1", color: "#52ab92" },
  { value: "12.2", color: "#52ab92" },
  { value: "12.3", color: "#51ab92" },
  { value: "12.4", color: "#51aa92" },
  { value: "12.5", color: "#51aa91" },
  { value: "12.6", color: "#51aa91" },
  { value: "12.7", color: "#51a991" },
  { value: "12.8", color: "#50a990" },
  { value: "12.9", color: "#50a990" },
  { value: "13.0", color: "#50a890" },
  { value: "13.1", color: "#50a88f" },
  { value: "13.2", color: "#50a88f" },
  { value: "13.3", color: "#4fa78f" },
  { value: "13.4", color: "#4fa78e" },
  { value: "13.5", color: "#4fa78e" },
  { value: "13.6", color: "#4fa68e" },
  { value: "13.7", color: "#4fa68e" },
  { value: "13.8", color: "#4ea68d" },
  { value: "13.9", color: "#4ea58d" },
  { value: "14.0", color: "#4ea58d" },
  { value: "14.1", color: "#4ea58c" },
  { value: "14.2", color: "#4da48c" },
  { value: "14.3", color: "#4da48c" },
  { value: "14.4", color: "#4da48b" },
  { value: "14.5", color: "#4da38b" },
  { value: "14.6", color: "#4da38b" },
  { value: "14.7", color: "#4ca38a" },
  { value: "14.8", color: "#4ca38a" },
  { value: "14.9", color: "#4ca28a" },
  { value: "15.0", color: "#4ca28a" },
  { value: "15.1", color: "#4ca289" },
  { value: "15.2", color: "#4ba189" },
  { value: "15.3", color: "#4ba189" },
  { value: "15.4", color: "#4ba188" },
  { value: "15.5", color: "#4ba088" },
  { value: "15.6", color: "#4ba088" },
  { value: "15.7", color: "#4aa087" },
  { value: "15.8", color: "#4a9f87" },
  { value: "15.9", color: "#4a9f87" },
  { value: "16.0", color: "#4a9f86" },
  { value: "16.1", color: "#4a9e86" },
  { value: "16.2", color: "#499e86" },
  { value: "16.3", color: "#499e85" },
  { value: "16.4", color: "#499d85" },
  { value: "16.5", color: "#499d85" },
  { value: "16.6", color: "#499d85" },
  { value: "16.7", color: "#489c84" },
  { value: "16.8", color: "#489c84" },
  { value: "16.9", color: "#489c84" },
  { value: "17.0", color: "#489b83" },
  { value: "17.1", color: "#489b83" },
  { value: "17.2", color: "#479b83" },
  { value: "17.3", color: "#479a82" },
  { value: "17.4", color: "#479a82" },
  { value: "17.5", color: "#479a82" },
  { value: "17.6", color: "#479981" },
  { value: "17.7", color: "#469981" },
  { value: "17.8", color: "#469981" },
  { value: "17.9", color: "#469981" },
  { value: "18.0", color: "#469880" },
  { value: "18.1", color: "#469880" },
  { value: "18.2", color: "#459880" },
  { value: "18.3", color: "#45977f" },
  { value: "18.4", color: "#45977f" },
  { value: "18.5", color: "#45977f" },
  { value: "18.6", color: "#45967e" },
  { value: "18.7", color: "#44967e" },
  { value: "18.8", color: "#44967e" },
  { value: "18.9", color: "#44957d" },
  { value: "19.0", color: "#44957d" },
  { value: "19.1", color: "#44957d" },
  { value: "19.2", color: "#43947c" },
  { value: "19.3", color: "#43947c" },
  { value: "19.4", color: "#43947c" },
  { value: "19.5", color: "#43937c" },
  { value: "19.6", color: "#43937b" },
  { value: "19.7", color: "#42937b" },
  { value: "19.8", color: "#42927b" },
  { value: "19.9", color: "#42927a" },
  { value: "20.0", color: "#42927a" },
  { value: "20.1", color: "#42917a" },
  { value: "20.2", color: "#419179" },
  { value: "20.3", color: "#419179" },
  { value: "20.4", color: "#419079" },
  { value: "20.5", color: "#419078" },
  { value: "20.6", color: "#419078" },
  { value: "20.7", color: "#408f78" },
  { value: "20.8", color: "#408f78" },
  { value: "20.9", color: "#408f77" },
  { value: "21.0", color: "#408f77" },
  { value: "21.1", color: "#408e77" },
  { value: "21.2", color: "#3f8e76" },
  { value: "21.3", color: "#3f8e76" },
  { value: "21.4", color: "#3f8d76" },
  { value: "21.5", color: "#3f8d75" },
  { value: "21.6", color: "#3f8d75" },
  { value: "21.7", color: "#3e8c75" },
  { value: "21.8", color: "#3e8c74" },
  { value: "21.9", color: "#3e8c74" },
  { value: "22.0", color: "#3e8b74" },
  { value: "22.1", color: "#3d8b73" },
  { value: "22.2", color: "#3d8b73" },
  { value: "22.3", color: "#3d8a73" },
  { value: "22.4", color: "#3d8a73" },
  { value: "22.5", color: "#3d8a72" },
  { value: "22.6", color: "#3c8972" },
  { value: "22.7", color: "#3c8972" },
  { value: "22.8", color: "#3c8971" },
  { value: "22.9", color: "#3c8871" },
  { value: "23.0", color: "#3c8871" },
  { value: "23.1", color: "#3b8870" },
  { value: "23.2", color: "#3b8770" },
  { value: "23.3", color: "#3b8770" },
  { value: "23.4", color: "#3b876f" },
  { value: "23.5", color: "#3b866f" },
  { value: "23.6", color: "#3a866f" },
  { value: "23.7", color: "#3a866f" },
  { value: "23.8", color: "#3a856e" },
  { value: "23.9", color: "#3a856e" },
  { value: "24.0", color: "#3a856e" },
  { value: "24.1", color: "#39856d" },
  { value: "24.2", color: "#39846d" },
  { value: "24.3", color: "#39846d" },
  { value: "24.4", color: "#39846c" },
  { value: "24.5", color: "#39836c" },
  { value: "24.6", color: "#38836c" },
  { value: "24.7", color: "#38836b" },
  { value: "24.8", color: "#38826b" },
  { value: "24.9", color: "#38826b" },
  { value: "25.0", color: "#38826a" },
  { value: "25.1", color: "#37816a" },
  { value: "25.2", color: "#37816a" },
  { value: "25.3", color: "#37816a" },
  { value: "25.4", color: "#378069" },
  { value: "25.5", color: "#378069" },
  { value: "25.6", color: "#368069" },
  { value: "25.7", color: "#367f68" },
  { value: "25.8", color: "#367f68" },
  { value: "25.9", color: "#367f68" },
  { value: "26.0", color: "#367e67" },
  { value: "26.1", color: "#357e67" },
  { value: "26.2", color: "#357e67" },
  { value: "26.3", color: "#357d66" },
  { value: "26.4", color: "#357d66" },
  { value: "26.5", color: "#357d66" },
  { value: "26.6", color: "#347c66" },
  { value: "26.7", color: "#347c65" },
  { value: "26.8", color: "#347c65" },
  { value: "26.9", color: "#347b65" },
  { value: "27.0", color: "#347b64" },
  { value: "27.1", color: "#337b64" },
  { value: "27.2", color: "#337b64" },
  { value: "27.3", color: "#337a63" },
  { value: "27.4", color: "#337a63" },
  { value: "27.5", color: "#337a63" },
  { value: "27.6", color: "#327962" },
  { value: "27.7", color: "#327962" },
  { value: "27.8", color: "#327962" },
  { value: "27.9", color: "#327862" },
  { value: "28.0", color: "#327861" },
  { value: "28.1", color: "#317861" },
  { value: "28.2", color: "#317761" },
  { value: "28.3", color: "#317760" },
  { value: "28.4", color: "#317760" },
  { value: "28.5", color: "#317660" },
  { value: "28.6", color: "#30765f" },
  { value: "28.7", color: "#30765f" },
  { value: "28.8", color: "#30755f" },
  { value: "28.9", color: "#30755e" },
  { value: "29.0", color: "#30755e" },
  { value: "29.1", color: "#2f745e" },
  { value: "29.2", color: "#2f745d" },
  { value: "29.3", color: "#2f745d" },
  { value: "29.4", color: "#2f735d" },
  { value: "29.5", color: "#2f735d" },
  { value: "29.6", color: "#2e735c" },
  { value: "29.7", color: "#2e725c" },
  { value: "29.8", color: "#2e725c" },
  { value: "29.9", color: "#2e725b" },
  { value: "30.0", color: "#2e725b" },
  { value: "30.1", color: "#2d715b" },
  { value: "30.2", color: "#2d715a" },
  { value: "30.3", color: "#2d715a" },
  { value: "30.4", color: "#2d705a" },
  { value: "30.5", color: "#2c7059" },
  { value: "30.6", color: "#2c7059" },
  { value: "30.7", color: "#2c6f59" },
  { value: "30.8", color: "#2c6f59" },
  { value: "30.9", color: "#2c6f58" },
  { value: "31.0", color: "#2b6e58" },
  { value: "31.1", color: "#2b6e58" },
  { value: "31.2", color: "#2b6e57" },
  { value: "31.3", color: "#2b6d57" },
  { value: "31.4", color: "#2b6d57" },
  { value: "31.5", color: "#2a6d56" },
  { value: "31.6", color: "#2a6c56" },
  { value: "31.7", color: "#2a6c56" },
  { value: "31.8", color: "#2a6c55" },
  { value: "31.9", color: "#2a6b55" },
  { value: "32.0", color: "#296b55" },
  { value: "32.1", color: "#296b54" },
  { value: "32.2", color: "#296a54" },
  { value: "32.3", color: "#296a54" },
  { value: "32.4", color: "#296a54" },
  { value: "32.5", color: "#286953" },
  { value: "32.6", color: "#286953" },
  { value: "32.7", color: "#286953" },
  { value: "32.8", color: "#286852" },
  { value: "32.9", color: "#286852" },
  { value: "33.0", color: "#276852" },
  { value: "33.1", color: "#276851" },
  { value: "33.2", color: "#276751" },
  { value: "33.3", color: "#276751" },
  { value: "33.4", color: "#276750" },
  { value: "33.5", color: "#266650" },
  { value: "33.6", color: "#266650" },
  { value: "33.7", color: "#266650" },
  { value: "33.8", color: "#26654f" },
  { value: "33.9", color: "#26654f" },
  { value: "34.0", color: "#25654f" },
  { value: "34.1", color: "#25644e" },
  { value: "34.2", color: "#25644e" },
  { value: "34.3", color: "#25644e" },
  { value: "34.4", color: "#25634d" },
  { value: "34.5", color: "#24634d" },
  { value: "34.6", color: "#24634d" },
  { value: "34.7", color: "#24624c" },
  { value: "34.8", color: "#24624c" },
  { value: "34.9", color: "#24624c" },
  { value: "35.0", color: "#23614c" },
  { value: "35.1", color: "#23614b" },
  { value: "35.2", color: "#23614b" },
  { value: "35.3", color: "#23604b" },
  { value: "35.4", color: "#23604a" },
  { value: "35.5", color: "#22604a" },
  { value: "35.6", color: "#225f4a" },
  { value: "35.7", color: "#225f49" },
  { value: "35.8", color: "#225f49" },
  { value: "35.9", color: "#225e49" },
  { value: "36.0", color: "#215e48" },
  { value: "36.1", color: "#215e48" },
  { value: "36.2", color: "#215e48" },
  { value: "36.3", color: "#215d47" },
  { value: "36.4", color: "#215d47" },
  { value: "36.5", color: "#205d47" },
  { value: "36.6", color: "#205c47" },
  { value: "36.7", color: "#205c46" },
  { value: "36.8", color: "#205c46" },
  { value: "36.9", color: "#205b46" },
  { value: "37.0", color: "#1f5b45" },
  { value: "37.1", color: "#1f5b45" },
  { value: "37.2", color: "#1f5a45" },
  { value: "37.3", color: "#1f5a44" },
  { value: "37.4", color: "#1f5a44" },
  { value: "37.5", color: "#1e5944" },
  { value: "37.6", color: "#1e5943" },
  { value: "37.7", color: "#1e5943" },
  { value: "37.8", color: "#1e5843" },
  { value: "37.9", color: "#1e5843" },
  { value: "38.0", color: "#1d5842" },
  { value: "38.1", color: "#1d5742" },
  { value: "38.2", color: "#1d5742" },
  { value: "38.3", color: "#1d5741" },
  { value: "38.4", color: "#1c5641" },
  { value: "38.5", color: "#1c5641" },
  { value: "38.6", color: "#1c5640" },
  { value: "38.7", color: "#1c5540" },
  { value: "38.8", color: "#1c5540" },
  { value: "38.9", color: "#1b553f" },
  { value: "39.0", color: "#1b543f" },
  { value: "39.1", color: "#1b543f" },
  { value: "39.2", color: "#1b543e" },
  { value: "39.3", color: "#1b543e" },
  { value: "39.4", color: "#1a533e" },
  { value: "39.5", color: "#1a533e" },
  { value: "39.6", color: "#1a533d" },
  { value: "39.7", color: "#1a523d" },
  { value: "39.8", color: "#1a523d" },
  { value: "39.9", color: "#19523c" },
  { value: "40.0", color: "#19513c" },
  { value: "40.1", color: "#19513c" },
  { value: "40.2", color: "#19513b" },
  { value: "40.3", color: "#19503b" },
  { value: "40.4", color: "#18503b" },
  { value: "40.5", color: "#18503a" },
  { value: "40.6", color: "#184f3a" },
  { value: "40.7", color: "#184f3a" },
  { value: "40.8", color: "#184f3a" },
  { value: "40.9", color: "#174e39" },
  { value: "41.0", color: "#174e39" },
  { value: "41.1", color: "#174e39" },
  { value: "41.2", color: "#174d38" },
  { value: "41.3", color: "#174d38" },
  { value: "41.4", color: "#164d38" },
  { value: "41.5", color: "#164c37" },
  { value: "41.6", color: "#164c37" },
  { value: "41.7", color: "#164c37" },
  { value: "41.8", color: "#164b36" },
  { value: "41.9", color: "#154b36" },
  { value: "42.0", color: "#154b36" },
  { value: "42.1", color: "#154a35" },
  { value: "42.2", color: "#154a35" },
  { value: "42.3", color: "#154a35" },
  { value: "42.4", color: "#144a35" },
  { value: "42.5", color: "#144934" },
  { value: "42.6", color: "#144934" },
  { value: "42.7", color: "#144934" },
  { value: "42.8", color: "#144833" },
  { value: "42.9", color: "#134833" },
  { value: "43.0", color: "#134833" },
  { value: "43.1", color: "#134732" },
  { value: "43.2", color: "#134732" },
  { value: "43.3", color: "#134732" },
  { value: "43.4", color: "#124631" },
  { value: "43.5", color: "#124631" },
  { value: "43.6", color: "#124631" },
  { value: "43.7", color: "#124531" },
  { value: "43.8", color: "#124530" },
  { value: "43.9", color: "#114530" },
  { value: "44.0", color: "#114430" },
  { value: "44.1", color: "#11442f" },
  { value: "44.2", color: "#11442f" },
  { value: "44.3", color: "#11432f" },
  { value: "44.4", color: "#10432e" },
  { value: "44.5", color: "#10432e" },
  { value: "44.6", color: "#10422e" },
  { value: "44.7", color: "#10422d" },
  { value: "44.8", color: "#10422d" },
  { value: "44.9", color: "#0f412d" },
  { value: "45.0", color: "#0f412c" },
  { value: "45.1", color: "#0f412c" },
  { value: "45.2", color: "#0f402c" },
  { value: "45.3", color: "#0f402c" },
  { value: "45.4", color: "#0e402b" },
  { value: "45.5", color: "#0e402b" },
  { value: "45.6", color: "#0e3f2b" },
  { value: "45.7", color: "#0e3f2a" },
  { value: "45.8", color: "#0e3f2a" },
  { value: "45.9", color: "#0d3e2a" },
  { value: "46.0", color: "#0d3e29" },
  { value: "46.1", color: "#0d3e29" },
  { value: "46.2", color: "#0d3d29" },
  { value: "46.3", color: "#0c3d28" },
  { value: "46.4", color: "#0c3d28" },
  { value: "46.5", color: "#0c3c28" },
  { value: "46.6", color: "#0c3c28" },
  { value: "46.7", color: "#0c3c27" },
  { value: "46.8", color: "#0b3b27" },
  { value: "46.9", color: "#0b3b27" },
  { value: "47.0", color: "#0b3b26" },
  { value: "47.1", color: "#0b3a26" },
  { value: "47.2", color: "#0b3a26" },
  { value: "47.3", color: "#0a3a25" },
  { value: "47.4", color: "#0a3925" },
  { value: "47.5", color: "#0a3925" },
  { value: "47.6", color: "#0a3924" },
  { value: "47.7", color: "#0a3824" },
  { value: "47.8", color: "#093824" },
  { value: "47.9", color: "#093824" },
  { value: "48.0", color: "#093723" },
  { value: "48.1", color: "#093723" },
  { value: "48.2", color: "#093723" },
  { value: "48.3", color: "#083622" },
  { value: "48.4", color: "#083622" },
  { value: "48.5", color: "#083622" },
  { value: "48.6", color: "#083621" },
  { value: "48.7", color: "#083521" },
  { value: "48.8", color: "#073521" },
  { value: "48.9", color: "#073520" },
  { value: "49.0", color: "#073420" },
  { value: "49.1", color: "#073420" },
  { value: "49.2", color: "#07341f" },
  { value: "49.3", color: "#06331f" },
  { value: "49.4", color: "#06331f" },
  { value: "49.5", color: "#06331f" },
  { value: "49.6", color: "#06321e" },
  { value: "49.7", color: "#06321e" },
  { value: "49.8", color: "#05321e" },
  { value: "49.9", color: "#05311d" },
  { value: "50.0", color: "#05311d" },
];

const phArray = [
  { value: "0.0", color: "#f5d088" },
  { value: "0.1", color: "#f4cf87" },
  { value: "0.2", color: "#f3cd86" },
  { value: "0.3", color: "#f2cc85" },
  { value: "0.4", color: "#f1cb84" },
  { value: "0.5", color: "#f0c982" },
  { value: "0.6", color: "#f0c881" },
  { value: "0.7", color: "#efc680" },
  { value: "0.8", color: "#eec57f" },
  { value: "0.9", color: "#edc47e" },
  { value: "1.0", color: "#ecc27d" },
  { value: "1.1", color: "#ebc17c" },
  { value: "1.2", color: "#eac07b" },
  { value: "1.3", color: "#e9be7a" },
  { value: "1.4", color: "#e8bd78" },
  { value: "1.5", color: "#e7bb77" },
  { value: "1.6", color: "#e7ba76" },
  { value: "1.7", color: "#e6b975" },
  { value: "1.8", color: "#e5b774" },
  { value: "1.9", color: "#e4b673" },
  { value: "2.0", color: "#e3b572" },
  { value: "2.1", color: "#e2b371" },
  { value: "2.2", color: "#e1b270" },
  { value: "2.3", color: "#e0b06e" },
  { value: "2.4", color: "#dfaf6d" },
  { value: "2.5", color: "#deae6c" },
  { value: "2.6", color: "#deac6b" },
  { value: "2.7", color: "#ddab6a" },
  { value: "2.8", color: "#dcaa69" },
  { value: "2.9", color: "#dba868" },
  { value: "3.0", color: "#daa767" },
  { value: "3.1", color: "#d9a666" },
  { value: "3.2", color: "#d8a464" },
  { value: "3.3", color: "#d7a363" },
  { value: "3.4", color: "#d6a162" },
  { value: "3.5", color: "#d5a061" },
  { value: "3.6", color: "#d49f60" },
  { value: "3.7", color: "#d49d5f" },
  { value: "3.8", color: "#d39c5e" },
  { value: "3.9", color: "#d29b5d" },
  { value: "4.0", color: "#d1995b" },
  { value: "4.1", color: "#d0985a" },
  { value: "4.2", color: "#cf9659" },
  { value: "4.3", color: "#ce9558" },
  { value: "4.4", color: "#cd9457" },
  { value: "4.5", color: "#cc9256" },
  { value: "4.6", color: "#cb9155" },
  { value: "4.7", color: "#cb9054" },
  { value: "4.8", color: "#ca8e53" },
  { value: "4.9", color: "#c98d51" },
  { value: "5.0", color: "#c88b50" },
  { value: "5.1", color: "#c78a4f" },
  { value: "5.2", color: "#c6894e" },
  { value: "5.3", color: "#c5874d" },
  { value: "5.4", color: "#c4864c" },
  { value: "5.5", color: "#c3854b" },
  { value: "5.6", color: "#c2834a" },
  { value: "5.7", color: "#c28249" },
  { value: "5.8", color: "#c18047" },
  { value: "5.9", color: "#c07f46" },
  { value: "6.0", color: "#bf7e45" },
  { value: "6.1", color: "#be7c44" },
  { value: "6.2", color: "#bd7b43" },
  { value: "6.3", color: "#bf7540" },
  { value: "6.4", color: "#c06f3d" },
  { value: "6.5", color: "#c26839" },
  { value: "6.6", color: "#c36236" },
  { value: "6.7", color: "#c55c33" },
  { value: "6.8", color: "#c55c33" },
  { value: "6.9", color: "#c2542e" },
  { value: "7.0", color: "#be4c2a" },
  { value: "7.1", color: "#ba4425" },
  { value: "7.2", color: "#b73c20" },
  { value: "7.3", color: "#b63c27" },
  { value: "7.4", color: "#b53b2e" },
  { value: "7.5", color: "#b53b35" },
  { value: "7.6", color: "#b43a3c" },
  { value: "7.7", color: "#b33a43" },
  { value: "7.8", color: "#b33a43" },
  { value: "7.9", color: "#b43142" },
  { value: "8.0", color: "#b42840" },
  { value: "8.1", color: "#b41e3e" },
  { value: "8.2", color: "#b5153d" },
  { value: "8.3", color: "#b60c3c" },
  { value: "8.4", color: "#b6033a" },
  { value: "8.5", color: "#b40339" },
  { value: "8.6", color: "#b30339" },
  { value: "8.7", color: "#b10338" },
  { value: "8.8", color: "#b00337" },
  { value: "8.9", color: "#ae0336" },
  { value: "9.0", color: "#ad0336" },
  { value: "9.1", color: "#ab0335" },
  { value: "9.2", color: "#a90334" },
  { value: "9.3", color: "#a80333" },
  { value: "9.4", color: "#a60333" },
  { value: "9.5", color: "#a50332" },
  { value: "9.6", color: "#a30331" },
  { value: "9.7", color: "#a20330" },
  { value: "9.8", color: "#a00230" },
  { value: "9.9", color: "#9f022f" },
  { value: "10.0", color: "#9d022e" },
  { value: "10.1", color: "#9b022d" },
  { value: "10.2", color: "#9a022d" },
  { value: "10.3", color: "#98022c" },
  { value: "10.4", color: "#97022b" },
  { value: "10.5", color: "#95022a" },
  { value: "10.6", color: "#94022a" },
  { value: "10.7", color: "#920229" },
  { value: "10.8", color: "#900228" },
  { value: "10.9", color: "#8f0227" },
  { value: "11.0", color: "#8d0227" },
  { value: "11.1", color: "#8c0226" },
  { value: "11.2", color: "#8a0225" },
  { value: "11.3", color: "#890224" },
  { value: "11.4", color: "#870224" },
  { value: "11.5", color: "#860223" },
  { value: "11.6", color: "#840222" },
  { value: "11.7", color: "#820221" },
  { value: "11.8", color: "#810221" },
  { value: "11.9", color: "#7f0220" },
  { value: "12.0", color: "#7e021f" },
  { value: "12.1", color: "#7c021e" },
  { value: "12.2", color: "#7b021e" },
  { value: "12.3", color: "#79021d" },
  { value: "12.4", color: "#77021c" },
  { value: "12.5", color: "#76021b" },
  { value: "12.6", color: "#74011b" },
  { value: "12.7", color: "#73011a" },
  { value: "12.8", color: "#710119" },
  { value: "12.9", color: "#700118" },
  { value: "13.0", color: "#6e0118" },
  { value: "13.1", color: "#6d0117" },
  { value: "13.2", color: "#6b0116" },
  { value: "13.3", color: "#690115" },
  { value: "13.4", color: "#680115" },
  { value: "13.5", color: "#660114" },
  { value: "13.6", color: "#650113" },
  { value: "13.7", color: "#630112" },
  { value: "13.8", color: "#620112" },
  { value: "13.9", color: "#600111" },
  { value: "14.0", color: "#600111" },
];

const calciumHardnessArray = [
  { value: "0", color: "#3c609e" },
  { value: "10", color: "#3e609e" },
  { value: "20", color: "#41609f" },
  { value: "30", color: "#445f9f" },
  { value: "40", color: "#465f9f" },
  { value: "50", color: "#485fa0" },
  { value: "60", color: "#4b5fa0" },
  { value: "70", color: "#4e5fa0" },
  { value: "80", color: "#505ea0" },
  { value: "90", color: "#525ea1" },
  { value: "100", color: "#555ea1" },
  { value: "110", color: "#555da0" },
  { value: "120", color: "#565b9f" },
  { value: "130", color: "#565a9e" },
  { value: "140", color: "#56599d" },
  { value: "150", color: "#57589c" },
  { value: "160", color: "#57569b" },
  { value: "170", color: "#57559a" },
  { value: "180", color: "#585498" },
  { value: "190", color: "#585397" },
  { value: "200", color: "#585196" },
  { value: "210", color: "#595095" },
  { value: "220", color: "#594f94" },
  { value: "230", color: "#594e93" },
  { value: "240", color: "#5a4c92" },
  { value: "250", color: "#5a4b91" },
  { value: "260", color: "#5b4a90" },
  { value: "270", color: "#5c4a8f" },
  { value: "280", color: "#5d498f" },
  { value: "290", color: "#5d498e" },
  { value: "300", color: "#5e488d" },
  { value: "310", color: "#5f478c" },
  { value: "320", color: "#60478c" },
  { value: "330", color: "#61468b" },
  { value: "340", color: "#62468a" },
  { value: "350", color: "#624589" },
  { value: "360", color: "#634489" },
  { value: "370", color: "#644488" },
  { value: "380", color: "#654387" },
  { value: "390", color: "#664386" },
  { value: "400", color: "#674286" },
  { value: "410", color: "#674185" },
  { value: "420", color: "#684184" },
  { value: "430", color: "#694083" },
  { value: "440", color: "#6a4083" },
  { value: "450", color: "#6b3f82" },
  { value: "460", color: "#6c3e81" },
  { value: "470", color: "#6c3e80" },
  { value: "480", color: "#6d3d80" },
  { value: "490", color: "#6e3d7f" },
  { value: "500", color: "#6f3c7e" },
  { value: "510", color: "#703c7e" },
  { value: "520", color: "#703c7f" },
  { value: "530", color: "#713c7f" },
  { value: "540", color: "#723c7f" },
  { value: "550", color: "#723c7f" },
  { value: "560", color: "#733d80" },
  { value: "570", color: "#733d80" },
  { value: "580", color: "#743d80" },
  { value: "590", color: "#753d80" },
  { value: "600", color: "#753d81" },
  { value: "610", color: "#763d81" },
  { value: "620", color: "#773d81" },
  { value: "630", color: "#773d81" },
  { value: "640", color: "#783d82" },
  { value: "650", color: "#793e82" },
  { value: "660", color: "#793e82" },
  { value: "670", color: "#7a3e82" },
  { value: "680", color: "#7b3e83" },
  { value: "690", color: "#7b3e83" },
  { value: "700", color: "#7c3e83" },
  { value: "710", color: "#7c3e83" },
  { value: "720", color: "#7d3e84" },
  { value: "730", color: "#7e3e84" },
  { value: "740", color: "#7e3e84" },
  { value: "750", color: "#7f3e84" },
  { value: "760", color: "#803f85" },
  { value: "770", color: "#803f85" },
  { value: "780", color: "#813f85" },
  { value: "790", color: "#823f86" },
  { value: "800", color: "#823f86" },
  { value: "810", color: "#833f86" },
  { value: "820", color: "#833f86" },
  { value: "830", color: "#843f87" },
  { value: "840", color: "#853f87" },
  { value: "850", color: "#854087" },
  { value: "860", color: "#864087" },
  { value: "870", color: "#874088" },
  { value: "880", color: "#874088" },
  { value: "890", color: "#884088" },
  { value: "900", color: "#894088" },
  { value: "910", color: "#894089" },
  { value: "920", color: "#8a4089" },
  { value: "930", color: "#8b4089" },
  { value: "940", color: "#8b4089" },
  { value: "950", color: "#8c408a" },
  { value: "960", color: "#8c418a" },
  { value: "970", color: "#8d418a" },
  { value: "980", color: "#8e418a" },
  { value: "990", color: "#8e418b" },
  { value: "1000", color: "#8f418b" },
  { value: "1010", color: "#8f418b" },
  { value: "1020", color: "#90428c" },
  { value: "1030", color: "#90428c" },
  { value: "1040", color: "#90428c" },
  { value: "1050", color: "#91438d" },
  { value: "1060", color: "#91438d" },
  { value: "1070", color: "#91438e" },
  { value: "1080", color: "#92438e" },
  { value: "1090", color: "#92448e" },
  { value: "1100", color: "#93448f" },
  { value: "1110", color: "#93448f" },
  { value: "1120", color: "#93458f" },
  { value: "1130", color: "#944590" },
  { value: "1140", color: "#944590" },
  { value: "1150", color: "#944690" },
  { value: "1160", color: "#954691" },
  { value: "1170", color: "#954691" },
  { value: "1180", color: "#954691" },
  { value: "1190", color: "#964792" },
  { value: "1200", color: "#964792" },
  { value: "1210", color: "#964793" },
  { value: "1220", color: "#974893" },
  { value: "1230", color: "#974893" },
  { value: "1240", color: "#974894" },
  { value: "1250", color: "#984994" },
  { value: "1260", color: "#984994" },
  { value: "1270", color: "#994995" },
  { value: "1280", color: "#994a95" },
  { value: "1290", color: "#994a95" },
  { value: "1300", color: "#9a4a96" },
  { value: "1310", color: "#9a4a96" },
  { value: "1320", color: "#9a4b97" },
  { value: "1330", color: "#9b4b97" },
  { value: "1340", color: "#9b4b97" },
  { value: "1350", color: "#9b4c98" },
  { value: "1360", color: "#9c4c98" },
  { value: "1370", color: "#9c4c98" },
  { value: "1380", color: "#9c4d99" },
  { value: "1390", color: "#9d4d99" },
  { value: "1400", color: "#9d4d99" },
  { value: "1410", color: "#9d4d9a" },
  { value: "1420", color: "#9e4e9a" },
  { value: "1430", color: "#9e4e9a" },
  { value: "1440", color: "#9e4e9b" },
  { value: "1450", color: "#9f4f9b" },
  { value: "1460", color: "#9f4f9c" },
  { value: "1470", color: "#a04f9c" },
  { value: "1480", color: "#a0509c" },
  { value: "1490", color: "#a0509d" },
  { value: "1500", color: "#a1509d" },
  { value: "1510", color: "#a1519d" },
  { value: "1520", color: "#a1519e" },
  { value: "1530", color: "#a2519e" },
  { value: "1540", color: "#a2519e" },
  { value: "1550", color: "#a2529f" },
  { value: "1560", color: "#a3529f" },
  { value: "1570", color: "#a352a0" },
  { value: "1580", color: "#a353a0" },
  { value: "1590", color: "#a453a0" },
  { value: "1600", color: "#a453a1" },
  { value: "1610", color: "#a454a1" },
  { value: "1620", color: "#a554a1" },
  { value: "1630", color: "#a554a2" },
  { value: "1640", color: "#a654a2" },
  { value: "1650", color: "#a655a2" },
  { value: "1660", color: "#a655a3" },
  { value: "1670", color: "#a755a3" },
  { value: "1680", color: "#a756a3" },
  { value: "1690", color: "#a756a4" },
  { value: "1700", color: "#a856a4" },
  { value: "1710", color: "#a857a5" },
  { value: "1720", color: "#a857a5" },
  { value: "1730", color: "#a957a5" },
  { value: "1740", color: "#a957a6" },
  { value: "1750", color: "#a958a6" },
  { value: "1760", color: "#aa58a6" },
  { value: "1770", color: "#aa58a7" },
  { value: "1780", color: "#aa59a7" },
  { value: "1790", color: "#ab59a7" },
  { value: "1800", color: "#ab59a8" },
  { value: "1810", color: "#ac5aa8" },
  { value: "1820", color: "#ac5aa9" },
  { value: "1830", color: "#ac5aa9" },
  { value: "1840", color: "#ad5ba9" },
  { value: "1850", color: "#ad5baa" },
  { value: "1860", color: "#ad5baa" },
  { value: "1870", color: "#ae5baa" },
  { value: "1880", color: "#ae5cab" },
  { value: "1890", color: "#ae5cab" },
  { value: "1900", color: "#af5cab" },
  { value: "1910", color: "#af5dac" },
  { value: "1920", color: "#af5dac" },
  { value: "1930", color: "#b05dac" },
  { value: "1940", color: "#b05ead" },
  { value: "1950", color: "#b05ead" },
  { value: "1960", color: "#b15eae" },
  { value: "1970", color: "#b15eae" },
  { value: "1980", color: "#b15fae" },
  { value: "1990", color: "#b25faf" },
  { value: "2000", color: "#b25faf" },
  { value: "2010", color: "#b360af" },
  { value: "2020", color: "#b360b0" },
  { value: "2030", color: "#b360b0" },
  { value: "2040", color: "#b461b0" },
  { value: "2050", color: "#b461b1" },
  { value: "2060", color: "#b461b1" },
  { value: "2070", color: "#b562b2" },
  { value: "2080", color: "#b562b2" },
  { value: "2090", color: "#b562b2" },
  { value: "2100", color: "#b662b3" },
  { value: "2110", color: "#b663b3" },
  { value: "2120", color: "#b663b3" },
  { value: "2130", color: "#b763b4" },
  { value: "2140", color: "#b764b4" },
  { value: "2150", color: "#b764b4" },
  { value: "2160", color: "#b864b5" },
  { value: "2170", color: "#b865b5" },
  { value: "2180", color: "#b965b5" },
  { value: "2190", color: "#b965b6" },
  { value: "2200", color: "#b965b6" },
  { value: "2210", color: "#ba66b7" },
  { value: "2220", color: "#ba66b7" },
  { value: "2230", color: "#ba66b7" },
  { value: "2240", color: "#bb67b8" },
  { value: "2250", color: "#bb67b8" },
  { value: "2260", color: "#bb67b8" },
  { value: "2270", color: "#bc68b9" },
  { value: "2280", color: "#bc68b9" },
  { value: "2290", color: "#bc68b9" },
  { value: "2300", color: "#bd69ba" },
  { value: "2310", color: "#bd69ba" },
  { value: "2320", color: "#bd69bb" },
  { value: "2330", color: "#be69bb" },
  { value: "2340", color: "#be6abb" },
  { value: "2350", color: "#bf6abc" },
  { value: "2360", color: "#bf6abc" },
  { value: "2370", color: "#bf6bbc" },
  { value: "2380", color: "#c06bbd" },
  { value: "2390", color: "#c06bbd" },
  { value: "2400", color: "#c06cbd" },
  { value: "2410", color: "#c16cbe" },
  { value: "2420", color: "#c16cbe" },
  { value: "2430", color: "#c16cbe" },
  { value: "2440", color: "#c26dbf" },
  { value: "2450", color: "#c26dbf" },
  { value: "2460", color: "#c26dc0" },
  { value: "2470", color: "#c36ec0" },
  { value: "2480", color: "#c36ec0" },
  { value: "2490", color: "#c36ec1" },
  { value: "2500", color: "#c46fc1" },
  { value: "2510", color: "#c46fc1" },
  { value: "2520", color: "#c56fc2" },
  { value: "2530", color: "#c570c2" },
  { value: "2540", color: "#c570c2" },
  { value: "2550", color: "#c670c3" },
  { value: "2560", color: "#c670c3" },
  { value: "2570", color: "#c671c4" },
  { value: "2580", color: "#c771c4" },
  { value: "2590", color: "#c771c4" },
  { value: "2600", color: "#c772c5" },
  { value: "2610", color: "#c872c5" },
  { value: "2620", color: "#c872c5" },
  { value: "2630", color: "#c873c6" },
  { value: "2640", color: "#c973c6" },
  { value: "2650", color: "#c973c6" },
  { value: "2660", color: "#c973c7" },
  { value: "2670", color: "#ca74c7" },
  { value: "2680", color: "#ca74c7" },
  { value: "2690", color: "#ca74c8" },
  { value: "2700", color: "#cb75c8" },
  { value: "2710", color: "#cb75c9" },
  { value: "2720", color: "#cc75c9" },
  { value: "2730", color: "#cc76c9" },
  { value: "2740", color: "#cc76ca" },
  { value: "2750", color: "#cd76ca" },
  { value: "2760", color: "#cd77ca" },
  { value: "2770", color: "#cd77cb" },
  { value: "2780", color: "#ce77cb" },
  { value: "2790", color: "#ce77cb" },
  { value: "2800", color: "#ce78cc" },
  { value: "2810", color: "#cf78cc" },
  { value: "2820", color: "#cf78cd" },
  { value: "2830", color: "#cf79cd" },
  { value: "2840", color: "#d079cd" },
  { value: "2850", color: "#d079ce" },
  { value: "2860", color: "#d07ace" },
  { value: "2870", color: "#d17ace" },
  { value: "2880", color: "#d17acf" },
  { value: "2890", color: "#d27acf" },
  { value: "2900", color: "#d27bcf" },
  { value: "2910", color: "#d27bd0" },
  { value: "2920", color: "#d37bd0" },
  { value: "2930", color: "#d37cd0" },
  { value: "2940", color: "#d37cd1" },
  { value: "2950", color: "#d47cd1" },
  { value: "2960", color: "#d47dd2" },
  { value: "2970", color: "#d47dd2" },
  { value: "2980", color: "#d57dd2" },
  { value: "2990", color: "#d57dd3" },
  { value: "3000", color: "#d57ed3" },
  { value: "3010", color: "#d67ed3" },
  { value: "3020", color: "#d67ed4" },
  { value: "3030", color: "#d67fd4" },
  { value: "3040", color: "#d77fd4" },
  { value: "3050", color: "#d77fd5" },
  { value: "3060", color: "#d880d5" },
  { value: "3070", color: "#d880d6" },
  { value: "3080", color: "#d880d6" },
  { value: "3090", color: "#d981d6" },
  { value: "3100", color: "#d981d7" },
  { value: "3110", color: "#d981d7" },
  { value: "3120", color: "#da81d7" },
  { value: "3130", color: "#da82d8" },
  { value: "3140", color: "#da82d8" },
  { value: "3150", color: "#db82d8" },
  { value: "3160", color: "#db83d9" },
  { value: "3170", color: "#db83d9" },
  { value: "3180", color: "#dc83d9" },
  { value: "3190", color: "#dc84da" },
  { value: "3200", color: "#dc84da" },
  { value: "3210", color: "#dd84db" },
  { value: "3220", color: "#dd84db" },
  { value: "3230", color: "#dd85db" },
  { value: "3240", color: "#de85dc" },
  { value: "3250", color: "#de85dc" },
  { value: "3260", color: "#df86dc" },
  { value: "3270", color: "#df86dd" },
  { value: "3280", color: "#df86dd" },
  { value: "3290", color: "#e087dd" },
  { value: "3300", color: "#e087de" },
  { value: "3310", color: "#e087de" },
  { value: "3320", color: "#e188df" },
  { value: "3330", color: "#e188df" },
  { value: "3340", color: "#e188df" },
  { value: "3350", color: "#e288e0" },
  { value: "3360", color: "#e289e0" },
  { value: "3370", color: "#e289e0" },
  { value: "3380", color: "#e389e1" },
  { value: "3390", color: "#e38ae1" },
  { value: "3400", color: "#e38ae1" },
  { value: "3410", color: "#e48ae2" },
  { value: "3420", color: "#e48be2" },
  { value: "3430", color: "#e58be2" },
  { value: "3440", color: "#e58be3" },
  { value: "3450", color: "#e58be3" },
  { value: "3460", color: "#e68ce4" },
  { value: "3470", color: "#e68ce4" },
  { value: "3480", color: "#e68ce4" },
  { value: "3490", color: "#e78de5" },
  { value: "3500", color: "#e78de5" },
];

export {
  filterInitialArray,
  initialAlkMsgsSet,
  categoryObj,
  inpImage,
  inpStripImage,
  inpStripImage4,
  initialInputs,
  initialSoureInputs,
  defaultChemicalState,
  initialCal,
  initialglbobj,
  initialValidationData,
  fourthVisit,
  phArray,
  calciumHardnessArray,
  combinedChlorineArray,
  chlorineArray,
  stabiliserArray,
  alkalinityArray,
};
