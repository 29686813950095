import React from "react";
import Table from "react-bootstrap/Table";
import AdminWrapper from "../adminwrapper/wrapper";

const AdminDashboard = () => (
  <AdminWrapper>
    <div className="dashboard">
      <div className="container">
        <h2 className="admin-title">Dashboard</h2>
        <div className="row">
          <div className="col-sm-6 col-md-6  col-lg-6">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-muted mb-2">Total Appointments</h6>
                    <span className="font-weight-700 h4 mb-0">12,125</span>
                  </div>

                  <div className="col-auto">
                    <span className="fa fa-calendar text-muted" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6  col-lg-6">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-muted mb-2">Total Staff</h6>

                    <span className="font-weight-700 h4 mb-0">275</span>
                  </div>

                  <div className="col-auto">
                    <span className="fa fa-user text-muted" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-6 col-md-6  col-lg-6">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-muted mb-2">Total Revenue</h6>

                    <span className="font-weight-700 h4 mb-0">$1,569</span>
                  </div>

                  <div className="col-auto">
                    <span className="fa fa-chart-bar text-muted" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-md-6  col-lg-6">
            <div className="card">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="text-muted mb-2">Total Customers</h6>
                    <span className="font-weight-700 h4 mb-0">4515</span>
                  </div>

                  <div className="col-auto">
                    <span className="fa fa-users text-muted" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-12  col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="partner-header">
                  <h2>Booked Appointment</h2>
                </div>
                <div className="partner-table">
                  <Table responsive="lg">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th className="text-left">Price</th>
                        <th className="text-left">Quantity</th>
                        <th className="text-left">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Blow Dry</td>
                        <td>$25</td>
                        <td>1</td>
                        <td>$25</td>
                      </tr>
                      <tr>
                        <td>Hair Cutting</td>
                        <td>$55</td>
                        <td>2</td>
                        <td>$110</td>
                      </tr>
                      <tr>
                        <td>Nail Polishing</td>
                        <td>$15</td>
                        <td>2</td>
                        <td>$0</td>
                      </tr>
                      <tr>
                        <td>Blow Dry</td>
                        <td>$25</td>
                        <td>1</td>
                        <td>$25</td>
                      </tr>
                      <tr>
                        <td>Hair Cutting</td>
                        <td>$55</td>
                        <td>2</td>
                        <td>$110</td>
                      </tr>
                      <tr>
                        <td>Nail Polishing</td>
                        <td>$15</td>
                        <td>2</td>
                        <td>$0</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AdminWrapper>
);

export default AdminDashboard;
