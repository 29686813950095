/* eslint-disable no-constant-condition */
import React from "react";
import { Button, Form, Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import { coverBlankField } from "../../functions";

const ChemistryTarget = ({
  handleChange,
  state,
  handleRequest,
  handleRevertViewUpdate,
  handleNewRevertDefaultUpdate,
  change,
  checkBlankFields,
  errorValue,
}) => {
  const getDisable = (name) => {
    if (name === "N/A") return true;
    return false;
  };
  return (
    <div className="pool-setup">
      <div className="target-heading">
        <h6>Chemistry Target Levels </h6>
      </div>
      <div className="pool-chemistry-box chemistry-target">
        <div className="pool-analysis">
          <div className="left-pool-analysis">
            <h6> Chemistry Target Levels</h6>
          </div>
          <div className="right-pool-analysis">
            <h6>OK - Level</h6>
            <h6>Target Level</h6>
            <h6>OK + Level</h6>
            <h6>Drain Point</h6>
          </div>
        </div>
        {Array.isArray(state) ? (
          <>
            {state.map((chemical, i) => {
              let isPlus =
                chemical?.calciName?.includes("stab") ||
                chemical?.calciName?.includes("hard") ||
                chemical?.calciName?.includes("calcredu");
              return (
                <div className="pool-analysis" key={chemical.name}>
                  <div className="left-pool-analysis">
                    <h5>
                      {chemical.renderName} <span>{chemical.rendarSpan}</span>
                    </h5>
                  </div>
                  <div className="right-pool-analysis target-level-input">
                    <Form>
                      <Form.Group
                        controlId="formBasicInput"
                        className={
                          chemical.name === "copper" ? "chemicalclass" : ""
                        }
                      >
                        {chemical.okNegative === "N/A" || isPlus ? (
                          <Form.Control
                            type="text"
                            name="okNegative"
                            value={chemical.okNegative}
                            onChange={(e) => handleChange(e, i)}
                            disabled={chemical.name === "copper"}
                            className={coverBlankField(
                              chemical.okNegative,
                              chemical?.name,
                              checkBlankFields,
                            )}
                          />
                        ) : (
                          <Form.Control
                            type="text"
                            name="okNegative"
                            value={chemical.okNegative}
                            onChange={(e) => handleChange(e, i)}
                            className={coverBlankField(
                              chemical.okNegative,
                              chemical?.name,
                              checkBlankFields,
                            )}
                          />
                        )}
                      </Form.Group>
                    </Form>
                    <Form>
                      <Form.Group
                        controlId="formBasicInput"
                        className={
                          chemical.name === "copper" ? "chemicalclass" : ""
                        }
                      >
                        <Form.Control
                          type="text"
                          name="target"
                          value={chemical.target}
                          onChange={(e) => handleChange(e, i)}
                          disabled={chemical.name === "copper"}
                          className={coverBlankField(
                            chemical.target,
                            chemical?.name,
                            checkBlankFields,
                          )}
                        />
                      </Form.Group>
                    </Form>
                    <Form>
                      {/* <Form.Group controlId="formBasicInput">
                      {chemical.okPositive === 'N/A' || chemical.renderName === 'Salt' || 'Stabiliser(CYA)(with Orp probe)' || 'Stabiliser(CYA)' || 'Calcium Hardness'
                        ? <Form.Control type="text" name="okPositive" value={chemical.okPositive} onChange={(e) => handleChange(e, i)} />
                        : <Form.Control type="text" name="okPositive" value={chemical.okPositive} onChange={(e) => handleChange(e, i)} />}
                    </Form.Group> */}

                      <Form.Group
                        controlId="formBasicInput"
                        className="input-with-icons poolsetup-input"
                      >
                        {chemical.okPositive === "N/A" || isPlus ? (
                          <>
                            <span>+</span>
                            <Form.Control
                              type="text"
                              name="okPositive"
                              style={{ width: "70px" }}
                              value={
                                chemical.okPositive
                                  ? parseFloat(chemical?.okPositive)
                                  : ""
                              }
                              onChange={(e) => handleChange(e, i)}
                              className={coverBlankField(
                                chemical.okPositive,
                                chemical?.name,
                                checkBlankFields,
                              )}
                            />
                            <span>%</span>
                          </>
                        ) : (
                          <Form.Control
                            type="text"
                            name="okPositive"
                            style={{ width: "70px" }}
                            value={
                              chemical.okPositive
                                ? parseFloat(chemical.okPositive)
                                : ""
                            }
                            onChange={(e) => handleChange(e, i)}
                            className={coverBlankField(
                              chemical.okPositive,
                              chemical?.name,
                              checkBlankFields,
                            )}
                          />
                        )}
                      </Form.Group>
                    </Form>
                    <Form>
                      <Form.Group
                        controlId="formBasicInput"
                        className={`input-with-icons poolsetup-input ${getDisable(chemical.drainPoint) ? "chemicalclass" : ""}`}
                      >
                        {chemical.drainPoint === "N/A" ? (
                          <Form.Control
                            type="text"
                            name="drainPoint"
                            value={chemical.drainPoint}
                            onChange={(e) => handleChange(e, i)}
                            disabled={getDisable(chemical.drainPoint)}
                            style={{ width: "70px" }}
                          />
                        ) : (
                          <>
                            {(chemical.renderName === "Salt" || isPlus) && (
                              <span>+</span>
                            )}
                            <Form.Control
                              type="text"
                              name="drainPoint"
                              value={
                                chemical.drainPoint
                                  ? parseFloat(chemical.drainPoint)
                                  : ""
                              }
                              onChange={(e) => handleChange(e, i)}
                              style={{ width: "70px" }}
                              className={coverBlankField(
                                chemical.drainPoint,
                                chemical?.name,
                                checkBlankFields,
                              )}
                            />
                            {(chemical.renderName === "Salt" || isPlus) && (
                              <span>%</span>
                            )}
                          </>
                        )}
                      </Form.Group>
                    </Form>
                  </div>
                </div>
              );
            })}
          </>
        ) : null}
        <div className="view-btn">
          {/* <Button type="submit" className="btn btn-edit" onClick={(e) => handleRevertViewUpdate(e, state)}>Revert To Default</Button> */}
          <div className="client-option service-edit-icon">
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="btn btn-edi"
              >
                View Other Settings
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={(e) => handleRevertViewUpdate(e, state)}
                >
                  <div className="action">Restore Original Settings</div>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) => handleNewRevertDefaultUpdate(e, state)}
                >
                  <div className="action">Revert to Previous Saved Default</div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Button
            type="submit"
            className="btn btn-save"
            onClick={handleRequest}
          >
            Save as Default
          </Button>
        </div>
      </div>
    </div>
  );
};
ChemistryTarget.propTypes = {
  handleChange: PropTypes.func.isRequired,
  state: PropTypes.isRequired,
  handleRequest: PropTypes.isRequired,
  handleRevertViewUpdate: PropTypes.func.isRequired,
  handleNewRevertDefaultUpdate: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  checkBlankFields: PropTypes.arrayOf.isRequired,
  errorValue: PropTypes.string.isRequired,
};

export default ChemistryTarget;
