/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React from "react";
import { Button, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import reload from "../../assets/home/refresh-icon.png";
import { convertQuantUnit } from "../../functions/index";

const CostCalculation = ({
  state,
  poolSize,
  handleChange,
  handleRequest,
  newArrayStateGreen,
  type,
  granCost,
  granBuyCost,
  isUsImperial,
}) => {
  let secondArrayDosage = "";

  let unitValue = isUsImperial ? "lb" : "kg";

  let chemType = type.split(" ")[0];
  let chemColor = type.includes("Green") ? "green" : "black";
  let secondDosage = "";
  let hoursLabour = "";
  let hoursLabourCost = "";
  if (newArrayStateGreen[0]?.greenName === type) {
    secondArrayDosage = newArrayStateGreen.filter(
      (el) => el.chemicalName === "Second Chlorine Dosage",
    );
    secondDosage = secondArrayDosage[0].dosageAndTime;
    // hoursLabour = newArrayStateGreen.filter((el) => el.chemicalName === 'Labour Cost');
    // hoursLabourCost = hoursLabour[0].dosageAndTime;
  }

  let result1 = [];
  let result2 = [];
  // let result3 = [];
  // let result4 = [];
  // let result5 = [];
  let result6 = [];
  let result7 = [];

  function forCalculating(elgreen, elchemical, bool, stopValue) {
    let result = "";
    let actualOutput = "";
    let pooSizeForCalci = poolSize || 0;

    Array.isArray(state)
      ? newArrayStateGreen.map((el, i) => {
          if (
            el.greenName === elgreen &&
            el.chemicalName === elchemical &&
            bool === "A"
          ) {
            // console.log('bool', bool);
            result = (
              (parseFloat(el.dosageAndTime) / 40000) *
              parseFloat(pooSizeForCalci)
            ).toFixed(2);
            if (!stopValue) result6.push(result);
            // console.log('result6', result6);
          } else if (
            el.greenName === elgreen &&
            el.chemicalName === elchemical &&
            bool === "B"
          ) {
            result = (
              ((parseFloat(el.dosageAndTime) / 40000) *
                parseFloat(pooSizeForCalci) *
                secondDosage) /
              100
            ).toFixed(2);
            if (!stopValue) result7.push(result);
          } else if (
            el.greenName === elgreen &&
            el.chemicalName === elchemical &&
            bool === "C"
          ) {
            result = (
              (parseFloat(el.dosageAndTime) / 40000) *
              parseFloat(pooSizeForCalci) *
              parseFloat(granBuyCost)
            ).toFixed(2);
            result1.push(result);
          } else if (
            el.greenName === elgreen &&
            el.chemicalName === elchemical &&
            bool === "D"
          ) {
            result = (
              (
                ((parseFloat(el.dosageAndTime) / 40000) *
                  parseFloat(pooSizeForCalci) *
                  secondDosage) /
                100
              ).toFixed(2) * granBuyCost
            ).toFixed(2);
            result2.push(result);
          }
        })
      : null;
    return result;
  }

  // let secondDosage = secondArrayDosage[0].dosageAndTime;
  return (
    <>
      {/* {console.log(result6)} */}
      {chemColor === "green" ? (
        <div className="search-pool-size">
          <h6>Pool Size</h6>
          <Form.Group controlId="formBasicEmail">
            {Array.isArray(state) ? (
              <>
                {state.map((el, index) =>
                  el.chemicalName === "Pool Size" ? (
                    <>
                      <Form.Control
                        type="number"
                        name="dosageAndTime"
                        value={el.dosageAndTime}
                        onChange={(e) => handleChange(e, index)}
                      />
                      <span>{isUsImperial ? "gallons" : el.unit}</span>
                    </>
                  ) : null,
                )}
              </>
            ) : null}
          </Form.Group>
          <Button
            type="submit"
            className="btn btn-primary reload-btn"
            onClick={handleRequest}
          >
            <img src={reload} alt="search" />
          </Button>
        </div>
      ) : null}

      <div className="pool-chemistry-box green-pool-calculation">
        {/* <div className="main-title">
      <h6>Green Pool Calculations</h6>
    </div> */}

        <div className="green-pool-table pool-cost-table">
          <h5>{`${chemColor === "green" ? "Green Pool" : "Black-Spot"} Cost Calculation `}</h5>
          <p className="granular">(Granular Chlorine)</p>
          <div className="pool-color">
            <div className="right-pool-table second-dosage">
              <h6
                className={`${chemColor}-one ${chemColor === "black" ? "blackCalci1" : ""}`}
              >
                {chemType}
                <span>1</span>
              </h6>
              <h6
                className={`${chemColor}-two ${chemColor === "black" ? "blackCalci2" : ""}`}
              >
                {chemType}
                <span>2</span>
              </h6>
              <h6
                className={`${chemColor}-three ${chemColor === "black" ? "blackCalci3" : ""}`}
              >
                {chemType}
                <span>3</span>
              </h6>
              <h6
                className={`${chemColor}-four ${chemColor === "black" ? "blackCalci4" : ""}`}
              >
                {chemType}
                <span>4</span>
              </h6>
            </div>
          </div>
          <div className="main-table">
            <div className="dosage-part">
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Granular Chlorine</p>
                  <p>Dosage - 1st Visit</p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state) ? (
                    <>
                      {state.map((el) =>
                        el.greenName !== "" &&
                        el.chemicalName ===
                          "Initial Granular Chlorine Dosage" ? (
                          <p>
                            {/* {console.log('coming')} */}{" "}
                            {
                              convertQuantUnit(
                                forCalculating(
                                  el.greenName,
                                  el.chemicalName,
                                  "A",
                                ),
                                unitValue,
                              ).chemValue
                            }
                            <span>
                              {
                                convertQuantUnit(
                                  forCalculating(
                                    el.greenName,
                                    el.chemicalName,
                                    "A",
                                    "stop",
                                  ),
                                  unitValue,
                                ).chemUnit
                              }
                            </span>
                          </p>
                        ) : null,
                      )}
                    </>
                  ) : null}
                </div>
              </div>
              <hr />
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Granular Chlorine</p>
                  <p>Dosage - 2nd Visit</p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state) ? (
                    <>
                      {state.map((el) =>
                        el.greenName !== "" &&
                        el.chemicalName ===
                          "Initial Granular Chlorine Dosage" ? (
                          <p>
                            {" "}
                            {
                              convertQuantUnit(
                                forCalculating(
                                  el.greenName,
                                  el.chemicalName,
                                  "B",
                                ),
                                unitValue,
                              ).chemValue
                            }
                            <span>
                              {
                                convertQuantUnit(
                                  forCalculating(
                                    el.greenName,
                                    el.chemicalName,
                                    "B",
                                    "stop",
                                  ),
                                  unitValue,
                                ).chemUnit
                              }
                            </span>
                          </p>
                        ) : null,
                      )}
                    </>
                  ) : null}
                </div>
              </div>
              <hr />
              {/* {console.log(result6, result7) } */}
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Chlorine Cost</p>
                  <p>- Initial Dosage</p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state) ? (
                    <>
                      {state.map((el) =>
                        el.greenName !== "" &&
                        el.chemicalName ===
                          "Initial Granular Chlorine Dosage" ? (
                          <p>
                            {" "}
                            <span>$</span>
                            {forCalculating(el.greenName, el.chemicalName, "C")}
                          </p>
                        ) : null,
                      )}
                    </>
                  ) : null}
                </div>
              </div>
              <hr />
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Chlorine Cost</p>
                  <p>- Second Dosage</p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state) ? (
                    <>
                      {state.map((el) =>
                        el.greenName !== "" &&
                        el.chemicalName ===
                          "Initial Granular Chlorine Dosage" ? (
                          <p>
                            {" "}
                            <span>$</span>
                            {forCalculating(el.greenName, el.chemicalName, "D")}
                          </p>
                        ) : null,
                      )}
                    </>
                  ) : null}
                </div>
              </div>
              <hr />
              {/* <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Labour Cost</p>
                  <p>- First Visit</p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state)
                    ? (
                      <>
                        {state.map((el) => (el.greenName === '' && el.chemicalName === 'Hours Required for 1st & 2nd Visit'
                          ? (
                            <>
                              <>
                                {result1.map((elt) => (
                                  <p>
                                    {' '}
                                    <span>$</span>
                                    {forCalculating(el.greenName, el.chemicalName, 'E')}
                                  </p>
                                ))}
                              </>

                            </>
                          ) : null))}
                      </>
                    ) : null}
                </div>
              </div>
              <hr />
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Labour Cost</p>
                  <p>- Second Visit</p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state)
                    ? (
                      <>
                        {state.map((el) => (el.greenName === '' && el.chemicalName === 'Hours Required for 1st & 2nd Visit'
                          ? (
                            <>
                              <>
                                {result1.map((elt) => (
                                  <p>
                                    {' '}
                                    <span>$</span>
                                    {forCalculating(el.greenName, el.chemicalName, 'F')}
                                  </p>
                                ))}

                              </>

                            </>
                          ) : null))}
                      </>
                    ) : null}
                </div>
              </div>
              <hr />
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Vaccuming  Cost</p>
                  <p>- Final Visit</p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state)
                    ? (
                      <>
                        {state.map((el) => (

                          el.greenName === '' && el.chemicalName === 'Vaccum Cost for Final Visit'
                            ? (
                              <>
                                <>
                                  {result1.map((elt) => (
                                    <p>
                                      {' '}
                                      <span>$</span>
                                      {forCalculating(el.greenName, el.chemicalName, 'G')}
                                    </p>
                                  ))}

                                </>

                              </>
                            ) : null))}
                      </>
                    ) : null}
                </div>
              </div>
              <hr /> */}
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage bottom-cost-table">
                  <p>Treatment Total</p>
                </div>
                <div className="right-pool-table bottom-cost-table">
                  <>
                    {result1.map((elt, index) => (
                      <p>
                        {" "}
                        <span>$</span>
                        {(
                          parseFloat(result1[index]) +
                          parseFloat(result2[index])
                        ).toFixed(2)}
                      </p>
                    ))}
                  </>
                </div>
              </div>
              <hr />
              {/* <div className="pool-table-content">
                <div className="left-pool-table second-dosage bottom-cost-table-new">

                  <p>Labour & Margin</p>
                </div>
                <div className="right-pool-table bottom-cost-table-new">
                  <>
                    {result1.map((elt, index) => (
                      <p>
                        {' '}
                        <span>$</span>
                        {((parseFloat(result3[0]) + parseFloat(result4[0]) + parseFloat(result5[0])) + (parseFloat(result6[index]) * (granCost - granBuyCost)) + (parseFloat(result7[index]) * (granCost - granBuyCost))).toFixed(2)}
                      </p>
                    ))}

                  </>

                </div>
              </div> */}
              {/* <hr /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
CostCalculation.propTypes = {
  state: PropTypes.func.isRequired,
  poolSize: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired,
  newArrayStateGreen: PropTypes.func.isRequired,
  type: PropTypes.func.isRequired,
  granBuyCost: PropTypes.func.isRequired,
  granCost: PropTypes.func.isRequired,
  isUsImperial: PropTypes.bool.isRequired,
};
export default CostCalculation;
