import React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import moment from "../../functions/moment";
import "./invoice.css";
import arrow from "../../assets/home/arrowdown.png";

const InvoicesList = ({
  handleInvoice,
  allInvoices,
  handlePageClick,
  pageCount,
  country,
}) => (
  <div className="pool-setup">
    <div className="target-heading">
      <h6>Saved Payments</h6>
      {/* <h6> Invoices Awaiting Send Approval</h6> */}
    </div>
    <div className="invoices-table pool-chemistry-box">
      {allInvoices !== null ? (
        <>
          <div className="table-section desktop-comp-">
            <div className="invoices-table-left">
              <div className="invoices-head">
                <h6>Date</h6>
                <h6>Transaction No.</h6>
                <h6>Amount </h6>
                <h6>Transaction ID</h6>
                <h6>Status</h6>
              </div>
            </div>
          </div>
          <div className="table-section desktop-comp-">
            <div className="invoices-table-left">
              <div className="invoice-body">
                <p>{moment(allInvoices?.createdAt).format("D MMM")}</p>
                <p>{allInvoices?.receiptNumber}</p>
                {/* <p>{(allInvoices?.amountMoney?.amount + allInvoices?.amountMoney?.currency) || ''}</p>
                 */}
                <p>{allInvoices?.amountMoney?.amount + country || ""}</p>
                <p>{allInvoices?.id}</p>
                <p>{allInvoices?.status}</p>
              </div>
            </div>
            <div className="invoices-table-right d-none">
              <img src={arrow} alt="arrow" />
            </div>
          </div>
        </>
      ) : (
        <p className="no-invoice">No Payments Found</p>
      )}

      {allInvoices && allInvoices.length > 0 ? (
        <div className="user-pagination">
          <ReactPaginate
            previousLabel="← Previous"
            nextLabel="Next →"
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName="pagination-chk"
            previousLinkClassName="pagination__link"
            nextLinkClassName="pagination__link"
            disabledClassName="pagination__link--disabled"
            activeClassName="pagination__link--active"
            breakLabel="..."
            marginPagesDisplayed={-2}
            pageRangeDisplayed={0}
          />
        </div>
      ) : null}
    </div>
  </div>
);
InvoicesList.propTypes = {
  handleInvoice: PropTypes.func.isRequired,
  allInvoices: PropTypes.func.isRequired,
  handlePageClick: PropTypes.func.isRequired,
  pageCount: PropTypes.func.isRequired,
  country: PropTypes.func.isRequired,
};
export default InvoicesList;
