import React from "react";
import { Form, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";

const DrainPool = (props) => {
  const {
    greenTreatment,
    handleGreenPoolTreatments,
    greenSplitName,
    poolInputs,
    result,
    handleInputs,
    poolFinish,
    valueType,
  } = props;
  return (
    <>
      <Form.Group controlId="exampleForm.SelectCustom">
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>Drain Pool Options</Form.Label>
          </Col>
          <Col>
            <div className="select-box">
              <Form.Control
                as="select"
                custom
                value={greenTreatment}
                onChange={handleGreenPoolTreatments}
              >
                {poolFinish === "Vinyl Liner Pool - Indoor" ||
                poolFinish === "Vinyl Liner Pool - Outdoor" ? (
                  <option value="Part -Drain Pool">Part-Drain Pool</option>
                ) : (
                  <>
                    <option value="Drain Only">Drain Only </option>
                    <option value="Part -Drain Pool"> Part-Drain Pool</option>
                    <option value="Drain + Pressure Wash">
                      Drain + Pressure Wash{" "}
                    </option>
                    <option value="Drain + Pressure Wash & Chlorine Wash">
                      {" "}
                      Drain + Pressure Wash & Chlorine Wash
                    </option>
                    <option value="Drain + Pressure Wash & Acid Wash">
                      {" "}
                      Drain + Pressure Wash & Acid Wash
                    </option>
                    <option value="Drain + Pressure Wash + Chlorine & Acid Wash">
                      {" "}
                      Drain + Pressure Wash + Chlorine & Acid Wash
                    </option>
                  </>
                )}
              </Form.Control>
            </div>
          </Col>
        </Form.Row>
      </Form.Group>

      {greenSplitName === "Part" && (
        <Form.Group controlId="exampleForm.SelectCustom">
          <Form.Row>
            <Col column="xs" md={7} xs={5}>
              <Form.Label>Water Level Lowered by</Form.Label>
            </Col>
            <Col>
              <Form.Group controlId="formBasicInput-6" className="custom-input">
                <span className="cholinator-text">-</span>
                {/* <Form.Control type="number" name="okNegative" /> */}
                <Form.Control
                  type="text"
                  name="waterLevelLowered"
                  className="form-control spec-depth"
                  maxLength="4"
                  value={poolInputs.waterLevelLowered}
                  disabled={result}
                  onChange={handleInputs}
                />
                <span className="mm">{valueType ? "mm" : "inches"}</span>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form.Group>
      )}
    </>
  );
};

DrainPool.propTypes = {
  greenTreatment: PropTypes.string.isRequired,
  handleGreenPoolTreatments: PropTypes.func.isRequired,
  greenSplitName: PropTypes.string.isRequired,
  poolInputs: PropTypes.func.isRequired,
  result: PropTypes.bool.isRequired,
  handleInputs: PropTypes.func.isRequired,
  poolFinish: PropTypes.string.isRequired,
  valueType: PropTypes.bool.isRequired,
};

export default DrainPool;
