/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState } from "react";
import { Button, Form, Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import CostCalculation from "./greenPool-cost";
import GreemPoolLiquid from "./greenPool-liquid";
import BlackSpotAlgaeTop from "./blackSpotAlgaeTop";
import { coverBlankField } from "../../functions";

const BlackSpotAlgae = ({
  state,
  handleRequest,
  handleRevertViewUpdate,
  handleChange,
  poolChlorineSize,
  newArrayStateGreen,
  handleNewRevertDefaultUpdate,
  change,
  chemCost,
  isUsImperial,
  checkBlankFields,
}) => {
  let poolSize = poolChlorineSize;

  return (
    <>
      <div className="pool-chemistry-box green-pool-calculation green-pool-cal">
        <div className="main-title">
          <h6>Black-Spot Algae Calculations</h6>
        </div>
        <div className="pricing-setup">
          <h6>Black-Spot Algae Set-up</h6>
          <p>
            The blue numbers in the table below will change the costs for
            Black-Spot in Calculator. Dosages and times in table on are based on
            a {isUsImperial ? "10,000 gallons" : "40,000 litre"} pool.
          </p>
          <p>
            Changes to dosages, hourly rate or allocated visit times in table
            One will affect cost calculations.
          </p>
          <p>
            Updated pricing for Black-Spot 1, 2, 3 & 4 can be viewed in the 2nd
            and 3rd table after clicking the Save/ Update button at the bottom
            of the table one.
          </p>
          <p>
            {/* Simply change the pool volume at the top of the table two to view the cost impact for changes made to update both Granular or liquid pricing chlorine pricing */}
            Simply change the &quot;Pool Size&quot; at the top of the table
            &quot;Black-Spot Cost Calculation&quot; and click the reload button
            to view the cost impact for changes made after updating Granular or
            Liquid Chlorine Pricing.
          </p>
        </div>

        <div className="green-pool-table">
          <h5>Green Pool Calculation Table</h5>
          <div className="main-table">
            <div className="pool-table">
              <p>
                Pool Size: {isUsImperial ? "10,000 gallons" : "40,000 litres"}
              </p>

              <p>Dosages & Times</p>
            </div>
            {Array.isArray(state) ? (
              <>
                {state.map((el, index, array) => {
                  if (
                    el.greenName !== "" &&
                    el.chemicalName === "Initial Black-Spot Remover Dosage"
                  ) {
                    return (
                      <>
                        <div className="pool-table-content">
                          <div
                            className={
                              el.greenName === "Black-Spot Algae 1 (minimum)"
                                ? "left-pool-table black-one"
                                : el.greenName === "Black-Spot Algae 2 (light)"
                                  ? "left-pool-table black-one black-two"
                                  : el.greenName ===
                                      "Black-Spot Algae 3 (medium)"
                                    ? "left-pool-table black-one black-three"
                                    : el.greenName ===
                                        "Black-Spot Algae 4 (heavy)"
                                      ? "left-pool-table black-one black-four"
                                      : null
                            }
                          >
                            <p>{el.greenName}</p>
                            <p>{el.chemicalName}</p>
                          </div>
                          <div className="right-pool-table target-level-input">
                            <Form>
                              <Form.Group controlId="formBasicInput">
                                <Form.Control
                                  type="text"
                                  name="dosageAndTime"
                                  value={el?.dosageAndTime}
                                  onChange={(e) => handleChange(e, index)}
                                  className={coverBlankField(
                                    el?.dosageAndTime,
                                    el?.greenName,
                                    checkBlankFields,
                                  )}
                                />
                              </Form.Group>
                            </Form>{" "}
                            <span>
                              {isUsImperial
                                ? el.unit === "kg"
                                  ? "lb"
                                  : "fl oz"
                                : el.unit}
                            </span>
                          </div>
                        </div>
                        {index === 3 && <hr />}
                      </>
                    );
                  }
                  if (
                    el.greenName !== "" &&
                    el.chemicalName === "Initial Granular Chlorine Dosage"
                  ) {
                    return (
                      <>
                        <div className="pool-table-content">
                          <div
                            className={
                              el.greenName === "Black-Spot Algae 1 (minimum)"
                                ? "left-pool-table black-one"
                                : el.greenName === "Black-Spot Algae 2 (light)"
                                  ? "left-pool-table black-one black-two"
                                  : el.greenName ===
                                      "Black-Spot Algae 3 (medium)"
                                    ? "left-pool-table black-one black-three"
                                    : el.greenName ===
                                        "Black-Spot Algae 4 (heavy)"
                                      ? "left-pool-table black-one black-four"
                                      : null
                            }
                          >
                            <p>{el.greenName}</p>
                            <p>{el.chemicalName}</p>
                          </div>
                          <div className="right-pool-table target-level-input">
                            <Form>
                              <Form.Group controlId="formBasicInput">
                                <Form.Control
                                  type="text"
                                  name="dosageAndTime"
                                  value={el.dosageAndTime}
                                  onChange={(e) => handleChange(e, index)}
                                  className={coverBlankField(
                                    el?.dosageAndTime,
                                    el?.greenName,
                                    checkBlankFields,
                                  )}
                                />
                              </Form.Group>
                            </Form>{" "}
                            <span>
                              {isUsImperial
                                ? el.unit === "kg"
                                  ? "lb"
                                  : "fl oz"
                                : el.unit}
                            </span>
                          </div>
                        </div>
                        {index === 7 && <hr />}
                      </>
                    );
                  }
                  if (
                    el.greenName !== "" &&
                    el.chemicalName === "Initial Liquid Chlorine Dosage"
                  ) {
                    return (
                      <>
                        <div className="pool-table-content">
                          <div
                            className={
                              el.greenName === "Black-Spot Algae 1 (minimum)"
                                ? "left-pool-table black-one"
                                : el.greenName === "Black-Spot Algae 2 (light)"
                                  ? "left-pool-table black-one black-two"
                                  : el.greenName ===
                                      "Black-Spot Algae 3 (medium)"
                                    ? "left-pool-table black-one black-three"
                                    : el.greenName ===
                                        "Black-Spot Algae 4 (heavy)"
                                      ? "left-pool-table black-one black-four"
                                      : null
                            }
                          >
                            <p>{el.greenName}</p>
                            <p>{el.chemicalName}</p>
                          </div>
                          <div className="right-pool-table target-level-input">
                            <Form>
                              <Form.Group controlId="formBasicInput">
                                <Form.Control
                                  type="text"
                                  name="dosageAndTime"
                                  value={el.dosageAndTime}
                                  onChange={(e) => handleChange(e, index)}
                                  className={coverBlankField(
                                    el?.dosageAndTime,
                                    el?.greenName,
                                    checkBlankFields,
                                  )}
                                />
                              </Form.Group>
                            </Form>{" "}
                            <span>
                              {isUsImperial
                                ? el.unit === "kg"
                                  ? "lb"
                                  : "fl oz"
                                : el.unit}
                            </span>
                          </div>
                        </div>
                        {index === 11 && <hr />}
                      </>
                    );
                  }
                  if (
                    el.greenName === "" &&
                    el.chemicalLine === "" &&
                    el.chemicalName !== "Pool Size"
                  ) {
                    return (
                      <>
                        <div className="pool-table-content">
                          <div className="left-pool-table second-dosage">
                            <p>{el.chemicalName}</p>
                          </div>
                          <div className="right-pool-table target-level-input high-value">
                            <Form>
                              <Form.Group
                                controlId="formBasicInput"
                                style={{
                                  display: "flex",
                                  alignContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#0077c0",
                                    marginRight: "-3px",
                                  }}
                                >
                                  $
                                </span>
                                <Form.Control
                                  type="text"
                                  name="dosageAndTime"
                                  value={el.dosageAndTime}
                                  onChange={(e) => handleChange(e, index)}
                                  className={coverBlankField(
                                    el?.dosageAndTime,
                                    el?.greenName,
                                    checkBlankFields,
                                  )}
                                />
                              </Form.Group>
                            </Form>{" "}
                            <span>{el.unit}</span>
                          </div>
                        </div>
                        <hr />
                      </>
                    );
                  }
                  if (
                    el.chemicalLine !== "" &&
                    el.greenName === "" &&
                    el.chemicalName !== "Pool Size"
                  ) {
                    return (
                      <>
                        <div className="pool-table-content">
                          <div className="left-pool-table second-dosage">
                            <p>{el.chemicalName}</p>
                            <p>{el.chemicalLine}</p>
                          </div>
                          <div className="right-pool-table target-level-input">
                            <Form>
                              <Form.Group controlId="formBasicInput">
                                <Form.Control
                                  type="text"
                                  name="dosageAndTime"
                                  value={el.dosageAndTime}
                                  onChange={(e) => handleChange(e, index)}
                                  className={coverBlankField(
                                    el?.dosageAndTime,
                                    el?.chemicalName,
                                    checkBlankFields,
                                  )}
                                />
                              </Form.Group>
                            </Form>{" "}
                            <span>{el.unit}</span>
                          </div>
                        </div>
                        <hr />
                      </>
                    );
                  }
                })}
              </>
            ) : null}

            <div className="view-btn">
              {/* <Button type="submit" className="btn btn-edit" onClick={(e) => handleRevertViewUpdate(e, state)}>Revert To Default</Button> */}
              <div className="client-option service-edit-icon">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="btn btn-edi"
                  >
                    View Other Settings
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={(e) => handleRevertViewUpdate(e, state)}
                    >
                      <div className="action">Restore Original Settings</div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => handleNewRevertDefaultUpdate(e, state)}
                    >
                      <div className="action">
                        Revert to Previous Saved Default
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <Button
                type="submit"
                className="btn btn-save"
                onClick={handleRequest}
              >
                Save as Default
              </Button>
            </div>
            <div className="note-info">
              <span>*</span> Chemical costs for Black-Spot calculations come
              from prices set at &quot;Pool Set-up {">"} Chemical Costs &quot;.
            </div>
          </div>
        </div>
      </div>
      {console.log("BlackSpotAlgaeTop", chemCost)}
      <BlackSpotAlgaeTop
        type="Black-Spot Algae 1 (minimum)"
        state={state}
        granCost={chemCost?.blackSell}
        granBuyCost={chemCost?.blackBuy}
        poolSize={poolSize}
        handleChange={handleChange}
        handleRequest={handleRequest}
        newArrayStateGreen={newArrayStateGreen}
        isUsImperial={isUsImperial}
      />
      <CostCalculation
        type="Black-Spot Algae 1 (minimum)"
        state={state}
        granCost={chemCost?.granSell}
        granBuyCost={chemCost?.granBuy}
        poolSize={poolSize}
        handleChange={handleChange}
        handleRequest={handleRequest}
        newArrayStateGreen={newArrayStateGreen}
        isUsImperial={isUsImperial}
      />
      <GreemPoolLiquid
        type="Black-Spot Algae 1 (minimum)"
        state={state}
        granCost={chemCost?.liqSell}
        granBuyCost={chemCost?.liqBuy}
        poolSize={poolSize}
        newArrayStateGreen={newArrayStateGreen}
        isUsImperial={isUsImperial}
      />
    </>
  );
};
BlackSpotAlgae.propTypes = {
  state: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired,
  handleRevertViewUpdate: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  newArrayStateGreen: PropTypes.func.isRequired,
  poolChlorineSize: PropTypes.func.isRequired,
  handleNewRevertDefaultUpdate: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  chemCost: PropTypes.func.isRequired,
  isUsImperial: PropTypes.bool.isRequired,
  checkBlankFields: PropTypes.arrayOf.isRequired,
};

export default BlackSpotAlgae;
