import React from "react";
import PropTypes from "prop-types";
import AdminWrapper from "../admin/adminwrapper/wrapper";

const CalcPredictiveSummary = ({
  servicesList,
  handleCloseOrderDetails,
  displayPredictiveChemicals,
  handlePayment,
}) => {
  const calcualteStockOnhand = (staffStockData) => {
    const stockvalue = staffStockData.reduce(
      (acc, obj) => parseFloat(acc) + parseFloat(obj?.stockValue),
      0,
    );
    const stockUsed = staffStockData.reduce(
      (acc, obj) => parseFloat(acc) + parseFloat(obj?.stockUsed) || 0,
      0,
    );
    return { stockvalue, stockUsed };
  };
  console.log({ displayPredictiveChemicals });
  return (
    <AdminWrapper>
      <div className="container">
        <div className="pool-setup pool-set-up predictive-ordering-detail">
          <div className="target-heading">
            <h6>Predictive Ordering Detail</h6>
          </div>
          <div className="search-result-box">
            <div className="inner-accordion ">
              <h6 className="blue-heading-predective-details">
                Pool Chemicals
              </h6>
              <div className="ordering-detail-table">
                <div className="ordering-detail-header">
                  <h6>Predictive Ordering Summary</h6>
                  <h6>Stock on Hand</h6>
                  <h6>Supply Unit</h6>
                  <h6>ForeCast Quantity Needed</h6>
                  <h6>Quantity Order Amount</h6>
                  <h6>Cost</h6>
                </div>
                <div className="ordering-detail-body">
                  {displayPredictiveChemicals?.length > 0 &&
                    displayPredictiveChemicals.map((el) => (
                      <div className="ordering-detail-list">
                        <p>{el?.renderName}</p>
                        <p>{el?.currentStockItems || 0}</p>
                        <p>{el?.supplyUnit || 0}</p>
                        <p>vcb</p>
                        <p>cbcv</p>
                        <p>cbvc</p>
                      </div>
                    ))}
                </div>
                <div className="footer-btn view-btn">
                  <button
                    type="button"
                    className="appointment-btn btn-edit btn btn-primary"
                    onClick={handleCloseOrderDetails}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="appointment-btn btn-save btn btn-primary"
                    onClick={handlePayment}
                  >
                    Proceed to Payment
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="search-result-box">
            <h6 className="blue-heading-predective-details text-center">
              No Records Found
            </h6>
          </div> */}
        </div>
      </div>
    </AdminWrapper>
  );
};

CalcPredictiveSummary.propTypes = {
  servicesList: PropTypes.arrayOf.isRequired,
  handleCloseOrderDetails: PropTypes.func.isRequired,
  displayPredictiveChemicals: PropTypes.arrayOf.isRequired,
  handlePayment: PropTypes.func.isRequired,
};
export default CalcPredictiveSummary;
