import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";

const ClientDetailsUpdatePopUp = ({
  clientPopUpStatus,
  setClientPopUpStatus,
  handleUpdateClientDetails,
  clientSelectStatus,
  setClientSelectStatus,
}) => {
  const [checkOpt, setCheckOpt] = useState("update");

  const handleChange = (value) => {
    setCheckOpt(value);
  };
  return (
    <div className="duration-picker time-picker-modal update-source-popup">
      <div>
        <div className="time-keeper-popup duration-popup event-popup">
          <div className="appointment-view">
            <div
              className="pending-water-test"
              onClick={() => handleChange("update")}
            >
              <h3>Update Pool Set-up Records</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="waterSource"
                    checked={checkOpt === "update"}
                  />
                </div>
              </div>
            </div>
            <div
              className="pending-water-test"
              onClick={() => handleChange("dontsave")}
            >
              <h3>Don&apos;t Save Changes</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="waterSource"
                    checked={checkOpt === "dontsave"}
                  />
                </div>
              </div>
            </div>
            <div className="event-popup-btn footer-btn view-btn">
              <Button
                className="appointment-btn btn-edit"
                type="button"
                onClick={() => {
                  setClientPopUpStatus(!clientPopUpStatus);
                }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="appointment-btn btn-save"
                onClick={() => handleUpdateClientDetails(checkOpt)}
              >
                Select
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* <DateModal blockedtime={blockedtime} setBlockedtime={setBlockedtime} /> */}
    </div>
  );
};
ClientDetailsUpdatePopUp.propTypes = {
  clientPopUpStatus: PropTypes.bool.isRequired,
  setClientPopUpStatus: PropTypes.func.isRequired,
  handleUpdateClientDetails: PropTypes.func.isRequired,
  clientSelectStatus: PropTypes.bool.isRequired,
  setClientSelectStatus: PropTypes.bool.isRequired,
};
export default ClientDetailsUpdatePopUp;
