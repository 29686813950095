import React, { useState, useEffect } from "react";
import { Button, Accordion, Card } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import moment from "../../functions/moment";
import whiteedit from "../../assets/home/white-edit.png";
import AddProductForm from "./addProductForm";
import DeletePopUp from "../DeletePopUp";
import { userInstance } from "../../config/axios";
import { successMsgTime } from "../../functions/intervalTime";
import NoData from "../NoData/noData";
import toastMessage from "../../functions/toastMessage";

const AddProduct = () => {
  const [addProduct, setAddProduct] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [editData, setEditData] = useState(null);
  const [products, setProducts] = useState([]);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const paraText = "Are you sure to do this?";
  const [popUpText, setPopUpText] = useState("");
  const [delData, setDelData] = useState({});
  const getProducts = async () => {
    const res = await userInstance().get("/inventory/product-add?type=Add");
    if (res.data.code === 200) {
      setProducts(res.data.results);
    }
  };
  useEffect(() => {
    getProducts();
  }, []);

  const handleEditProduct = (el) => {
    setEditData({
      currentStockItems: `${el.currentStockItems}`,
      renderName: el?.renderName,
      cost: el?.cost,
      _id: el?._id,
    });
    setStartDate(new Date(el?.productDate));
    handleAddProductPopup();
  };

  const handleAddProductPopup = (clearData) => {
    if (clearData) {
      setEditData(null);
      setStartDate(new Date());
    }
    setAddProduct(!addProduct);
  };

  const handleDeleteOrder = (_id) => {
    setPopUpText("Delete Product?");
    setDeletePopUp(true);
    setDelData({
      _id,
    });
  };

  const handlePopUpChange = (val) => {
    const { _id } = delData;
    if (val === "Yes") {
      handleDeleteOrderApi(_id);
    } else {
      setDeletePopUp(false);
    }
  };

  const handleDeleteOrderApi = async (_id) => {
    try {
      const res = await userInstance().delete(
        `/inventory/product-add/${_id}?deleteType=Remove`,
      );
      const { code, msg } = res?.data || {};
      if (code === 201) {
        getProducts();
        toastMessage("success", msg);
        setDeletePopUp(false);
      }
    } catch (error) {
      console.log("error in delete order->>>", error);
    }
  };

  return (
    <div className="product-service-container inventory-list add-product-page">
      <div className="service-container">
        <div className="service-title">
          <h4>Services</h4>
          <span>
            <i className="fa fa-plus" />
          </span>
        </div>
      </div>
      <div className="table-data">
        <div className="service-mbl">
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <div className="service-container">
                  <div className="service-title title-service">
                    <h4>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Add Product
                      </Accordion.Toggle>
                    </h4>
                    <div
                      className="services-header"
                      onClick={() => handleAddProductPopup()}
                    >
                      <span>
                        <i className="fa fa-plus" />
                      </span>
                    </div>
                  </div>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <div className="mob-table">
                  {products.length > 0 ? (
                    <>
                      {products.map((el) => (
                        <Accordion className="mob-accordion outer-accordion">
                          <Card.Header className="sub-category">
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey="1"
                            >
                              <div className="product-service-list">
                                <i className="fas far fa-chevron-circle-up" />
                                <span>{el?.renderName || ""}</span>
                              </div>
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <ul className="grid-list product-inventory-grid">
                              <li className="grid-list-item">
                                <div className="grid-box">
                                  <h3>
                                    Name
                                    {/* <span> (as will display on invoices) </span> */}
                                  </h3>
                                  <div className="grid-content">
                                    {el?.name || ""}
                                  </div>
                                </div>
                              </li>
                              <li className="grid-list-item">
                                <div className="grid-box">
                                  <h3 className="">Date</h3>
                                  <div className="grid-content">
                                    {moment(el?.productDate).format(
                                      "D MMM, yyyy",
                                    )}
                                  </div>
                                </div>
                              </li>
                              <li className="grid-list-item">
                                <div className="grid-box">
                                  <h3 className="">Quantity</h3>
                                  <div className="grid-content">
                                    {el?.currentStockItems || 0}
                                  </div>
                                </div>
                              </li>
                              <li className="grid-list-item">
                                <div className="grid-box">
                                  <h3>Price</h3>
                                  <div className="grid-content">
                                    ${parseFloat(el?.cost).toFixed(2)}
                                  </div>
                                </div>
                              </li>
                              <li className="grid-list-item actions">
                                <div className="grid-box">
                                  <h3>Actions</h3>
                                  <div className="action-btn">
                                    <Button className="edit-btn">
                                      <img
                                        src={whiteedit}
                                        alt="edit"
                                        onClick={() => handleEditProduct(el)}
                                      />
                                    </Button>
                                  </div>
                                  <div
                                    className="action-btn"
                                    onClick={() => handleDeleteOrder(el._id)}
                                  >
                                    <Button className="delete-btn">
                                      <i className="far fa-trash-alt" />
                                    </Button>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </Accordion.Collapse>
                        </Accordion>
                      ))}
                    </>
                  ) : (
                    <NoData />
                  )}
                </div>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
      {addProduct && (
        <AddProductForm
          show={addProduct}
          HandleOpenClose={handleAddProductPopup}
          startDate={startDate}
          setStartDate={setStartDate}
          editData={editData}
          getProducts={getProducts}
        />
      )}
      {deletePopUp && (
        <DeletePopUp
          handlePopUpChange={handlePopUpChange}
          paraText={paraText}
          PopUpText={popUpText}
        />
      )}
    </div>
  );
};
export default AddProduct;
