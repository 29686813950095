import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Button, Dropdown } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Document, Page, pdfjs } from "react-pdf";
import { userInstance } from "../../config/axios";
import EditInvoice from "../invoices/editInvoice";
import showmore from "../../assets/home/showmoreblue.png";
import toastMessage from "../../functions/toastMessage";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const PreviewInvoice = ({
  invoiceData,
  mergeInvoices,
  mergeAppointment,
  setPreview,
  customerAllDue,
  addappt,
  setAddAppt,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [editInvoice, setEditInvoice] = useState(false);
  const {
    invoiceNo,
    createdAt,
    appointment,
    invoiceTotal,
    balanceDue,
    amountApplied,
    customer,
    gst,
    invoiceType,
    chemicalsAdded,
    invoiceSubTotal,
    paymentId,
  } = invoiceData;
  const [message, setMsg] = useState("No name to appear in Invoice detail");
  const [typeInvoice, setTypeInvoice] = useState(invoiceType);
  const [appointmentCopy, setAppointmentCopy] = useState(appointment);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [allTemplates, setAllTemplates] = useState([]);
  const [selectTemplate, setSelectTemplate] = useState(null);
  const [loading, setLoading] = useState(false);
  const approvedInvoice = async () => {
    try {
      // console.log('invoiceData.....', invoiceData);
      const payload = {
        id: invoiceData._id,
        message,
        typeInvoice,
        url: previewUrl,
      };
      const response = await userInstance().post(
        "/invoice/approveInvoice",
        payload,
      );
      const { code, msg } = response.data;
      if (code === 200) {
        window.location.href = "/invoice";
        toastMessage("success", msg);
      }
    } catch (error) {
      console.log(error);
      //     console.log(error);
    }
  };

  // const handleTypeInvoice = (data, data2) => {
  //   setTypeInvoice(data);
  //   if (data2) {
  //     const old = [...appointmentCopy];
  //     if (data === 'detailed') {
  //       old[0].dateData[0].service = JSON.parse(localStorage.getItem('detailed'));
  //       setAppointmentCopy(old);
  //       localStorage.removeItem('detailed');
  //     } else {
  //       localStorage.setItem('detailed', JSON.stringify(old[0].dateData[0].service));
  //       var res = old[0].dateData[0].service.reduce((acc, obj) => {
  //         var existItem = acc.find((item) => item.subcategory === obj.subcategory);
  //         if (existItem) {
  //           let existCost = parseFloat(existItem.cost);
  //           let duration = parseFloat(existItem.duration);
  //           existCost += parseFloat(obj.cost);
  //           duration += parseFloat(obj.duration);
  //           existItem.cost = existCost;
  //           existItem.duration = duration;
  //           return acc;
  //         }
  //         acc.push(obj);
  //         return acc;
  //       }, []);
  //       old[0].dateData[0].service = res;
  //       setAppointmentCopy(old);
  //     }
  //   }
  // };

  const getAllTemplate = async () => {
    try {
      const response = await userInstance().get(
        "/template/getAllTemplate?type=Invoice",
      );
      const { code, msg, temp } = response.data;
      if (msg === "Success") {
        setAllTemplates(temp);
        const defaultTemp = temp.filter((el, i) => el.default);
        if (defaultTemp.length > 0) {
          setSelectTemplate(defaultTemp[0]._id);
          handlePreviewPopup(defaultTemp[0]._id, "detailed", true);
        } else {
          setSelectTemplate(temp[0]._id);
          handlePreviewPopup(temp[0]._id, "detailed", true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTemplate();
  }, []);

  const handlePreviewPopup = async (info, data, data2) => {
    try {
      if (data2) {
        setLoading(true);
        setTypeInvoice(data);
        setSelectTemplate(info);
        let response;
        if (!mergeInvoices) {
          response = await userInstance().get(
            `/template/previewTemplate?id=${info}&invoiceid=${invoiceData._id}&type=${data}`,
          );
        } else {
          const payload = {
            mergeAppointment,
            id: info,
          };
          response = await userInstance().post(
            "/template/previewMergeTemplate",
            payload,
          );
        }
        console.log(response);
        const { code, msg, tempId } = response.data;
        setPreviewUrl(tempId);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      //     console.log(error);
    }
  };
  const backToInvoices = () => {
    setPreview(false);
  };
  const handleChange = (value) => {
    if (value === "defaultTemp") {
      getAllTemplate();
    }
    setAddAppt(value);
  };
  return (
    <>
      {!editInvoice ? (
        <div className="appointment-container preview-invoice preview-invoice-section">
          {/* <div className="container"> */}
          <div className="row">
            <div className="col-md-12">
              <div className="back-option" onClick={() => backToInvoices()}>
                <span />
                <i className="fa fa-times" aria-hidden="true" />
              </div>
              <div className="add-appointment">
                <div className="content-dropdown">
                  <h4>Preview Invoice</h4>
                  <div className="client-option service-edit-icon">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={showmore} alt="show" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {addappt === "specificTemp" ? (
                          <Dropdown.Item
                            onClick={() => handleChange("defaultTemp")}
                          >
                            <div className="action">
                              <span>Default Invoice Template</span>
                            </div>
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            onClick={() => handleChange("specificTemp")}
                          >
                            <div className="action">
                              <span>Select Specific Template</span>
                            </div>
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="invoice-type">
                  <h6>Invoice Type</h6>
                  {!mergeInvoices ? (
                    <p>
                      Individual Invoice
                      <span> (One Appointment Only)</span>
                    </p>
                  ) : (
                    <p>
                      Merged Invioce
                      <span> (with multiple appointments likned)</span>
                    </p>
                  )}

                  <div className="detailed-box">
                    <p>Detailed Invioce </p>
                    <div className="detailed-invoice">
                      <p>
                        <span>
                          (separate lines on the invoice for each service or
                          product)
                        </span>
                      </p>
                      <div className="open-date client-notes">
                        <div className="basic">
                          <Form.Check
                            type="checkbox"
                            value="detailed"
                            checked={typeInvoice === "detailed"}
                            onChange={(e) =>
                              handlePreviewPopup(
                                selectTemplate,
                                e.target.value,
                                e.target.checked,
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="detailed-box">
                    <p>Combined Invioce </p>
                    <div className="detailed-invoice">
                      <p>
                        <span>
                          (multiple Services with the same name combined into
                          one line on the invoice including their indivisual
                          costs & times)
                        </span>
                      </p>
                      <div className="open-date client-notes">
                        <div className="basic">
                          <Form.Check
                            type="checkbox"
                            value="combined"
                            checked={typeInvoice === "combined"}
                            onChange={(e) =>
                              handlePreviewPopup(
                                selectTemplate,
                                e.target.value,
                                e.target.checked,
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <span className="sep" />
                  <Form.Group controlId="formBasicHiddenEmployees">
                    <Form.Label>Team Member</Form.Label>
                    <div className="select-box">
                      {/* <i className="fas fa-sort-down" /> */}
                      <Form.Control
                        as="select"
                        name="staffName"
                        value={message}
                        onChange={(e) => setMsg(e.target.value)}
                      >
                        <option>No name to appear in Invoice detail</option>
                        <option>
                          First name only to appear in Invoice detail
                        </option>
                        <option>
                          First & last name to appear in Invoice detail
                        </option>
                        <option>
                          Initials only to appear in Invoice detail
                        </option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                  {addappt !== "defaultTemp" && (
                    <Form.Group controlId="formBasicHiddenEmployees">
                      <Form.Label>Select Invoice</Form.Label>
                      <div className="select-box">
                        <Form.Control
                          as="select"
                          name="staffName"
                          value={selectTemplate}
                          onChange={(e) =>
                            handlePreviewPopup(
                              e.target.value,
                              typeInvoice,
                              true,
                            )
                          }
                        >
                          {allTemplates &&
                            allTemplates.map((temp) => (
                              <option value={temp._id}>
                                {temp.templateName}
                              </option>
                            ))}
                        </Form.Control>
                      </div>
                    </Form.Group>
                  )}
                </div>
                {!loading && previewUrl ? (
                  <>
                    <TransformWrapper>
                      <TransformComponent>
                        <Document
                          file={previewUrl}
                          onLoadError={(error) =>
                            console.log(
                              `Error while loading document! ${error.message}`,
                            )
                          }
                        >
                          <Page pageNumber={pageNumber} />
                        </Document>
                      </TransformComponent>
                    </TransformWrapper>
                    <span className="sep" />
                    <div
                      className={`footer-btn view-btn ${mergeInvoices ? "merge-btn" : ""}`}
                    >
                      {mergeInvoices && (
                        <Button className="btn-delete">Demerge Invoice</Button>
                      )}
                      <Button
                        className="btn-delete"
                        onClick={() => setEditInvoice(true)}
                      >
                        Edit Invoice
                      </Button>
                      <Button
                        className="appointment-btn btn-save"
                        onClick={() => approvedInvoice()}
                      >
                        Approve & Send
                      </Button>
                    </div>
                  </>
                ) : (
                  <p> Loading Pdf...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        // </div>

        <EditInvoice
          invoiceData={invoiceData}
          setEditInvoice={setEditInvoice}
          customerAllDue={customerAllDue}
          searchType="previewedit"
        />
      )}
    </>
  );
};
PreviewInvoice.propTypes = {
  invoiceData: PropTypes.func.isRequired,
  mergeInvoices: PropTypes.bool.isRequired,
  mergeAppointment: PropTypes.arrayOf.isRequired,
  setPreview: PropTypes.func.isRequired,
  customerAllDue: PropTypes.string.isRequired,
  addappt: PropTypes.string.isRequired,
  setAddAppt: PropTypes.func.isRequired,
};
export default PreviewInvoice;
