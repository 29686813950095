/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { AuthDataContext } from "../../contexts/AuthDataContext";
import { queryString } from "../../functions";

const LoginRoute = ({ component: Component, ...rest }) => {
  const { userData } = useContext(AuthDataContext);
  let { userId, token: userToken, authToken: staffToken } = queryString();

  return (
    <Route
      {...rest}
      render={(props) => {
        let path = "";
        if (!localStorage.getItem("s#@4f") && !userData?.auth) {
          path = <Component {...props} {...rest} />;
        } else if (
          props.location.pathname === "/update-password" &&
          userData?.firstLogin === true
        ) {
          path = <Component {...props} {...rest} />;
        } else if (
          localStorage.getItem("s#@4f") &&
          userData?.firstLogin === true
        ) {
          path = <Redirect to="/update-password" />;
        } else if (localStorage.getItem("s#@4f") && userData?.auth) {
          path = userToken ? (
            <Redirect to="/calendar" />
          ) : (
            <Redirect to="/calculator" />
          );
        }
        return path;
      }}
    />
  );
};
LoginRoute.defaultProps = {
  location: PropTypes.func,
};
LoginRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.func,
};

export default LoginRoute;
