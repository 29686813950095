import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";

// mineral changes
const CalciumTitrationPopup = ({
  setSoourceReading,
  titrationPopup,
  setTitrationPopup,
  titrationPopupOptions,
  setTitrationPopupOptions,
  handleTitrationPopupChange,
  handleSelectedCalciumOption,
  handleCancleTitrationPopup,
}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className === "duration-picker time-picker-modal"
          ) {
            setSoourceReading(false);
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="duration-picker time-picker-modal update-source-popup calcium-popup">
      <div>
        <div
          className="time-keeper-popup duration-popup event-popup"
          ref={wrapperRef}
        >
          <div className="appointment-view">
            <div
              className="pending-water-test"
              onClick={() => handleTitrationPopupChange("dontShowCalcium")}
            >
              <h3>Don’t Show Calcium Hardness </h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="dontShowTitration"
                    checked={
                      titrationPopupOptions === "dontShowCalcium" ? true : ""
                    }
                  />
                </div>
              </div>
            </div>
            <div
              className="pending-water-test"
              onClick={() => handleTitrationPopupChange("showCalcium")}
            >
              <h3>Display Calcium Hardness </h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="displayTitration"
                    checked={
                      titrationPopupOptions === "showCalcium" ? true : ""
                    }
                  />
                </div>
              </div>
            </div>
            <p className="warning-text remove-spacing-text">
              * Note if traditional Salt (Sodium Chloride) has been added at any
              time to this mineral pool, Calcium Hardness readings in Calculator
              will not be correct.
            </p>
            <p className="warning-text">
              A Calcium Hardness Titration Test or Spin Disc Magnesium Hardness
              Test that shows Calcium Hardness separately will need to be
              undertaken and readings input or uploaded into Calculator.
            </p>
            <div className="event-popup-btn footer-btn view-btn">
              <Button
                className="appointment-btn btn-edit"
                type="button"
                onClick={() => handleCancleTitrationPopup()}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="appointment-btn btn-save"
                onClick={() => handleSelectedCalciumOption()}
              >
                Select
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
CalciumTitrationPopup.propTypes = {
  titrationPopup: PropTypes.bool.isRequired,
  setTitrationPopup: PropTypes.func.isRequired,
  setSoourceReading: PropTypes.func.isRequired,
  handleTitrationPopupChange: PropTypes.func.isRequired,
  handleSelectedCalciumOption: PropTypes.func.isRequired,
  setTitrationPopupOptions: PropTypes.string.isRequired,
  titrationPopupOptions: PropTypes.string.isRequired,
  handleCancleTitrationPopup: PropTypes.func.isRequired,
};
export default CalciumTitrationPopup;
