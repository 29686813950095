import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import showmore from "../../assets/home/showmoreblue.png";
import AdminWrapper from "../admin/adminwrapper/wrapper";
import "./predective.css";
import AddProduct from "./addProduct";
import EditOrderQunatity from "./editOrderQunatity";
import show from "../../assets/home/showmore.png";
import { PredictiveOrderTotala, currencyComma } from "../../functions/index";
import { getUnit } from "../calculator/waterTestCalciUtils";
import greydelete from "../../assets/home/grey-delete.png";
import { AuthDataContext } from "../../contexts/AuthDataContext";

const PredectiveOrdering = ({
  handleCloseOrderDetails,
  displayPredictiveChemicals,
  handlePayment,
  handleAddProduct,
  handleEditOrder,
  predictivePopUpSelected,
  handleRemovePredictiveProducts,
  handleEditPredictiveProducts,
  orderSummary,
  setOrderSummary,
  taxData,
}) => {
  const { userData } = useContext(AuthDataContext);
  // console.log('displayPredictiveChemicals==>', displayPredictiveChemicals);

  // const calcualteStockOnhand = (staffStockData) => {
  //   const stockvalue = staffStockData.reduce(
  //     (acc, obj) => parseFloat(acc) + parseFloat(obj?.stockValue),
  //     0,
  //   );
  //   const stockUsed = staffStockData.reduce(
  //     (acc, obj) => parseFloat(acc) + parseFloat(obj?.stockUsed) || 0,
  //     0,
  //   );
  //   return { stockvalue, stockUsed };
  // };

  const prodUnit = (quantity, unit) => {
    let prUnit = "";
    if (Number(quantity) >= 0) {
      if (
        unit.includes("kg") ||
        unit.includes("bag") ||
        unit.includes("gram")
      ) {
        prUnit = "kg";
      } else {
        prUnit = "litres";
        if (quantity <= 1) {
          prUnit = "litre";
        }
      }
    }
    return prUnit;
  };
  const mutatedDisplayName = (name) => {
    if (name?.includes("(10 kg)")) {
      name = name.replace(" (10 kg)", "");
    } else if (name?.includes("(20 kg)")) {
      name = name.replace(" (20 kg)", "");
    }
    return name;
  };
  useEffect(() => {
    const summary = PredictiveOrderTotala(
      displayPredictiveChemicals,
      userData?.role,
      taxData?.chemicalsTax,
    );
    setOrderSummary({
      gst: summary?.gst,
      subtotal: summary?.subtotal,
      total: summary?.total,
    });
  }, [displayPredictiveChemicals]);

  return (
    <AdminWrapper>
      <div className="container">
        <div className="pool-setup pool-set-up predictive-ordering-page">
          <div className="target-heading">
            <h6>{predictivePopUpSelected}</h6>
            <div className="content-dropdown">
              <div className="client-option service-edit-icon">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={showmore} alt="show" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <div className="action" onClick={handleAddProduct}>
                        <span>Add Additional Product</span>
                      </div>
                    </Dropdown.Item>
                    {/* <Dropdown.Item>
                      <div className="action" onClick={handleEditOrder}>
                        <span>Edit Order Quantity</span>
                      </div>
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="predective-table">
            <div className="predective-header">
              <h6>
                <span>Product Name</span>{" "}
              </h6>
              <h6>Stock on Hand</h6>
              <h6>Order Quantity</h6>
              <h6>Cost Summary</h6>
            </div>
            <div className="predective-body">
              {displayPredictiveChemicals?.length > 0 &&
                displayPredictiveChemicals.map((el) => (
                  <div className="main-predective-content">
                    <div className="left-predective-content">
                      <div className="predective-body-content">
                        <p>{el?.renderName}</p>
                        <p>
                          {el?.currentStockItems?.toFixed(2) || 0}{" "}
                          {prodUnit(
                            el?.currentStockItems || 0,
                            el?.unit || el?.unitType,
                          )}
                        </p>
                        <p>
                          {el?.predictedOrderQuantity}{" "}
                          {prodUnit(el?.predictedOrderQuantity, el?.prodUnit)}
                        </p>
                        <p>${currencyComma(el?.overAllCost || 0)}</p>
                      </div>
                    </div>
                    <div className="right-predective-content">
                      <div className="content-dropdown">
                        <div className="client-option service-edit-icon">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              <img src={show} alt="show" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {/* <Dropdown.Item> */}
                              <div
                                className="action"
                                onClick={() =>
                                  handleEditPredictiveProducts(el.renderName)
                                }
                              >
                                <span>
                                  <i className="las la-edit" />
                                </span>

                                <span> Edit</span>
                              </div>
                              {/* </Dropdown.Item> */}
                              {/* <Dropdown.Item> */}
                              <div
                                className="action"
                                onClick={() =>
                                  handleRemovePredictiveProducts(el?.renderName)
                                }
                              >
                                <span>
                                  <i className="las la-trash" />
                                </span>
                                <span> Delete</span>
                              </div>
                              {/* </Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="predective-body-content predective-sub-total desktop-screen">
                <div className="predective-sub">
                  <h6>Total: </h6>
                  <h6>${currencyComma(orderSummary?.total)}</h6>
                </div>
                <div className="predective-sub">
                  <h6>GST included in Total: </h6>
                  <h6>${currencyComma(orderSummary?.gst)}</h6>
                </div>
                {/* <div className="predective-sub">
                  <h6>Total: </h6>
                  <h6>
                    $
                    {currencyComma(orderSummary?.total)}
                  </h6>
                </div> */}
              </div>
            </div>

            <div className="view-btn">
              <Button
                type="Submit"
                className="btn btn-edit"
                onClick={handleCloseOrderDetails}
              >
                {" "}
                Previous{" "}
              </Button>
              <Button
                type="Submit"
                className="btn btn-save"
                onClick={handlePayment}
              >
                {" "}
                Confirm & Pay{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

PredectiveOrdering.propTypes = {
  handleCloseOrderDetails: PropTypes.func.isRequired,
  displayPredictiveChemicals: PropTypes.arrayOf.isRequired,
  handlePayment: PropTypes.func.isRequired,
  handleAddProduct: PropTypes.func.isRequired,
  handleEditOrder: PropTypes.func.isRequired,
  predictivePopUpSelected: PropTypes.string.isRequired,
  handleRemovePredictiveProducts: PropTypes.func.isRequired,
  handleEditPredictiveProducts: PropTypes.func.isRequired,
  setOrderSummary: PropTypes.func.isRequired,
  orderSummary: PropTypes.objectOf.isRequired,
  taxData: PropTypes.objectOf.isRequired,
};
export default PredectiveOrdering;
