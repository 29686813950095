/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

const RepeatModal = (props) => {
  const {
    updateRepeatStaff,
    repeatStaff,
    endRepeat,
    updateCurrentShift,
    updateAllShift,
  } = props;

  return (
    <>
      <Modal
        size="lg"
        show={repeatStaff}
        onHide={updateRepeatStaff}
        aria-labelledby="contained-modal-title-vcenter"
        top
        className="repeat-modal"
      >
        <div className="staff-close">
          <i
            className="fa fa-times"
            onClick={updateRepeatStaff}
            aria-hidden="true"
          />
        </div>
        <Modal.Header />
        <Modal.Title id="contained-modal-title-vcenter">
          Reapeating Shift
        </Modal.Title>
        <Modal.Body>
          <div className="repeat-shift-container">
            <p>
              {`You have edited a shift that repeats weekly. Updating upcoming shifts will
              overwrite schedule up to ${new Date(endRepeat).toLocaleDateString()}.`}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="view-btn">
            <Button
              type="submit"
              className="btn btn-edit"
              onClick={updateAllShift}
            >
              Update Upcoming Shift
            </Button>
            <Button
              type="submit"
              className="btn btn-save"
              onClick={updateCurrentShift}
            >
              Update This Shift Only
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
RepeatModal.propTypes = {
  repeatStaff: PropTypes.func.isRequired,
  updateRepeatStaff: PropTypes.func.isRequired,
  endRepeat: PropTypes.func.isRequired,
  updateCurrentShift: PropTypes.func.isRequired,
  updateAllShift: PropTypes.func.isRequired,
};
export default RepeatModal;
