import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import arrow from "../../assets/home/arrowdown.png";
import orderBlue from "../../assets/home/icon/orderIconBlue.png";
import orderBlack from "../../assets/home/icon/orderIconBlack.png";
import { userInstance } from "../../config/axios";
import { currencyComma } from "../../functions/index";

const SearchResult = ({
  updateView,
  searchData,
  setProductList,
  singleProductId,
  handleClose,
}) => {
  const [isClick, setIsClick] = useState(false);
  const [heading, setHeading] = useState([]);
  const [newSearchData, setNewSearchData] = useState(searchData.data);
  // const header = [
  //   { date: searchData?.payload?.sortProductOption?.createdAt ? 'Created' : 'Updated' },
  //   { sell: searchData?.payload?.sortProductOption?.cost ? 'Sell' : 'Buy' },
  //   { sell: searchData?.payload?.sortProductOption?.cost ? 'Buy' : 'Sell' },
  //   { date: searchData?.payload?.sortProductOption?.createdAt ? 'Updated' : 'Created' },
  // ];
  const [dynamicHeading, setDynamicHeading] = useState({
    dynamicDate: "",
    dynamicCost: "",
  });
  // const [dynamicDateHead, setDynamicDateHead] = useState('');
  // const [dynamicCostHead, setDynamicCostHead] = useState('');
  useEffect(() => {
    const dynamicDate = searchData.payload.sortProductOption.createdAt
      ? "Created"
      : "Updated";
    const dynamicCost = searchData.payload.sortProductOption.cost
      ? "Buy"
      : "Sell";
    setDynamicHeading({
      dynamicDateHead: dynamicDate,
      dynamicCostHead: dynamicCost,
    });
    // setDynamicDateHead(dynamicDate);
    // setDynamicCostHead(dynamicCost);
    // setHeading([header[0], header[1]]);
    if (singleProductId) {
      const data = searchData.data.filter((el) => el._id !== singleProductId);
      setNewSearchData(data);
    }
  }, []);

  const handleClickArrow = async (data) => {
    const res = await userInstance().get(
      `/inventory/getSepecifInventory/?inventoryStockId=${data._id}&getForeCast=true`,
    );
    const { data: result } = res.data;
    setProductList(result);
    // updateView('Inventory Search Detail');
    handleClose();
  };
  let calculateStck = (data) => {
    let matchData = 0;
    if (searchData?.payload?.memberName === "")
      return data?.staffStockData?.reduce(
        (preVal, CurVal) => preVal + CurVal?.stockValue,
        0,
      );
    data?.staffStockData?.forEach((element) => {
      if (element?.firstname === searchData?.payload?.memberName)
        matchData += element?.stockValue;
    });
    return matchData;
  };

  const handleReverse = () => {
    searchData?.data?.reverse();
    let copyList = [...searchData?.data];
    if (dynamicHeading.dynamicDateHead === "Updated") {
      if (!isClick) {
        copyList.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt));
        setNewSearchData(copyList);
        setIsClick(true);
      } else {
        copyList.sort((a, b) => a.updatedAt.localeCompare(b.updatedAt));
        setNewSearchData(copyList);
        setIsClick(false);
      }
    }
    if (dynamicHeading.dynamicDateHead === "Created") {
      if (!isClick) {
        copyList.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
        setNewSearchData(copyList);
        setIsClick(true);
      } else {
        copyList.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
        setNewSearchData(copyList);
        setIsClick(false);
      }
    }
    // header?.reverse();
    // setHeading([header[0], header[1]]);
    // setIsClick(!isClick);
  };
  return (
    <div className="pool-setup pool-set-up ">
      <div className="target-heading">
        <h6>Inventory Search Result</h6>
        <img
          src={!isClick ? orderBlack : orderBlue}
          alt=""
          className="order-image"
          onClick={handleReverse}
        />
      </div>
      <div className="search-result-box">
        <div className="search-result-head">
          <h6>
            Product <br /> Name
          </h6>
          <h6>
            Date <br /> {dynamicHeading.dynamicDateHead}
          </h6>
          <h6>
            {/* {dynamicHeading.dynamicCostHead} */}
            Buy <br /> Cost
          </h6>
          <h6>
            Stock <br /> Level
          </h6>
        </div>
        {newSearchData.length > 0 ? (
          newSearchData.map((el, i) => (
            <div className="search-result-body">
              <div className="main-search-result">
                <div className="search-result-content">
                  <p>{el?.name?.split("$")[0]}</p>
                  <p>
                    {new Date(
                      dynamicHeading.dynamicDateHead === "Updated"
                        ? el?.updatedAt
                        : el?.createdAt,
                    )
                      ?.toString()
                      ?.substring(4, 15)}
                  </p>
                  <p>
                    $
                    {dynamicHeading.dynamicCostHead === "Buy"
                      ? el?.cost
                      : el?.cost}
                  </p>
                  <p>{el?.currentStockItems}</p>
                </div>
                <div
                  className="inventory-result-icon"
                  onClick={() => handleClickArrow(el)}
                >
                  <img src={arrow} alt="arrow" />
                </div>
              </div>
              <p className="supplier-text">
                {/* Supplier:
              {' '}
              {el?.supplier}
              {' '}
              / */}{" "}
                {dynamicHeading.dynamicCostHead === "Buy" ? "Buy" : "Buy"} Cost:
                $
                {dynamicHeading.dynamicCostHead === "Buy"
                  ? currencyComma(el?.cost)
                  : currencyComma(el?.cost)}
              </p>
            </div>
          ))
        ) : (
          <div className="search-result-body no-data-found">
            <h5> No data found</h5>
          </div>
        )}
      </div>
    </div>
  );
};
SearchResult.propTypes = {
  searchData: PropTypes.arrayOf.isRequired,
  updateView: PropTypes.func.isRequired,
  setProductList: PropTypes.func.isRequired,
  singleProductId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};
export default SearchResult;
