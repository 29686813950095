/* eslint-disable no-restricted-globals */
import React from "react";
import { Modal, Dropdown, Button, Form } from "react-bootstrap";
import { PropTypes } from "prop-types";
import "./index.css";
// import { render } from '@testing-library/react';

const EditChemicalQuantity = ({
  editQuantityStatus,
  handleChemicalQuantityPopup,
  chemicalArray,
  handleQuantityChange,
  handleSaveUpdate,
  handleReverQuantitytUpdate,
  totalBuyCost,
  totalSellCost,
  consumableMargin,
  levelData,
  checkingDrainStatus,
  pId,
  handleRemove,
}) => (
  <Modal
    size="md"
    show={editQuantityStatus}
    onHide={handleChemicalQuantityPopup}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="staff-modal view-appointment-popup edit-chemical-popup edit-chem-popup"
  >
    <div className="staff-close">
      <i
        className="fa fa-times"
        onClick={handleChemicalQuantityPopup}
        aria-hidden="true"
      />
    </div>
    <Modal.Header>Edit Chemical Quantity</Modal.Header>
    <Modal.Body>
      <div className="chemical-table">
        <div className="chemical-table-head">
          <h6>Quantity</h6>
          <h6>Buy cost</h6>
          {/* <h6>Ex Gst</h6> */}
          <h6>
            <span className="hide-text">hide text</span>
          </h6>
        </div>

        {chemicalArray &&
          chemicalArray[pId]?.service?.map((el, index) => {
            let showOf = "";
            if (el?.subcategory?.includes(" of ")) {
              showOf = "of";
            }

            return (
              <div key={el?.category} className="chemical-table-body">
                {el?.subcategory !== "" &&
                  el.type !== "service" &&
                  el?.renderCost !== "N/A" && (
                    <>
                      <h6>
                        Add
                        <input
                          type="text"
                          value={el?.quantity}
                          name="quantity"
                          onChange={(e) => handleQuantityChange(e, index, pId)}
                        />
                        {el?.chemicalUnits} {showOf} {el?.chemicalDisplayName}
                      </h6>
                      <h6>
                        $<span>{el?.buyCostProduct}</span>
                      </h6>
                      {/* <h6>
                  $
                  <span>{el.renderCost}</span>
                </h6> */}
                      <h6 className="edit-chemical-action">
                        <i
                          className="fa fa-times"
                          onClick={() => handleRemove(index, pId)}
                        />
                      </h6>
                    </>
                  )}
              </div>
            );
          })}

        <div className="chemical-table-body subtotal">
          <h6>Sub total</h6>
          <h6>
            $<span>{totalBuyCost(chemicalArray[pId]?.service, "multi")}</span>
          </h6>
          {/* <h6>
            $
            <span>{totalSellCost(chemicalArray[pId].service)}</span>
          </h6> */}
          <h6>
            <span className="hide-text">hide text</span>{" "}
          </h6>
        </div>
      </div>
      {/* <div className="bottom-text">
        <p>{`Consumable Margin: $${consumableMargin(chemicalArray[pId].service)}`}</p>
      </div> */}
      <div className="view-btn">
        <Button
          type="Submit"
          className="btn btn-edit"
          onClick={handleReverQuantitytUpdate}
        >
          {" "}
          Revert to Original{" "}
        </Button>
        <Button
          type="Submit"
          className="btn btn-save"
          onClick={handleSaveUpdate}
        >
          {" "}
          Save/Update{" "}
        </Button>
      </div>
    </Modal.Body>
  </Modal>
);
EditChemicalQuantity.propTypes = {
  editQuantityStatus: PropTypes.func.isRequired,
  handleChemicalQuantityPopup: PropTypes.func.isRequired,
  chemicalArray: PropTypes.isRequired,
  handleQuantityChange: PropTypes.func.isRequired,
  handleReverQuantitytUpdate: PropTypes.func.isRequired,
  handleSaveUpdate: PropTypes.func.isRequired,
  totalBuyCost: PropTypes.func.isRequired,
  totalSellCost: PropTypes.func.isRequired,
  consumableMargin: PropTypes.func.isRequired,
  levelData: PropTypes.func.isRequired,
  checkingDrainStatus: PropTypes.func.isRequired,
  pId: PropTypes.number.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

export default EditChemicalQuantity;
