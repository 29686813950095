const targetLevelState = [
  {
    okNegative: "",
    target: "",
    okPositive: "",
    drainPoint: "",
    name: "chlorine",
    renderName: "Free Chlorine",
    calciName: "chlor",
  },
  {
    okNegative: "",
    target: "",
    okPositive: "",
    drainPoint: "",
    name: "combinedChlorine",
    renderName: "Combined Chlorine",
    calciName: "comb",
  },
  {
    okNegative: "",
    target: "",
    okPositive: "",
    drainPoint: "",
    name: "phConTil",
    renderName: "pH (concrete or tiled pool)",
    calciName: "phCont",
  },
  {
    okNegative: "",
    target: "",
    okPositive: "",
    drainPoint: "",
    name: "phFibre",
    renderName: "pH (fibreglass)",
    calciName: "phFibre",
  },
  {
    okNegative: "",
    target: "",
    okPositive: "",
    drainPoint: "",
    name: "phVinyl",
    renderName: "pH (vinyl liner)",
    calciName: "phVinyl",
  },
  {
    okNegative: "",
    target: "",
    okPositive: "",
    drainPoint: "",
    name: "alkalinity",
    renderName: "Alkalinity (TA)",
    calciName: "alk",
  },
  {
    okNegative: "",
    target: "",
    okPositive: "",
    drainPoint: "",
    name: "stabiliserOrp",
    renderName: "Stabiliser (CYA) (with Orp probe)",
    calciName: "stabOrp",
  },
  {
    okNegative: "",
    target: "",
    okPositive: "",
    drainPoint: "",
    name: "stabiliserCya",
    renderName: "Stabiliser (CYA)",
    calciName: "stab",
  },
  {
    okNegative: "",
    target: "",
    okPositive: "",
    drainPoint: "",
    name: "calciumHardnessConcrete",
    renderName: "Total Hardness (concrete or tiled pool)",
    calciName: "hardCont",
  },
  {
    okNegative: "",
    target: "",
    okPositive: "",
    drainPoint: "",
    name: "calciumHardnessFibre",
    renderName: "Total Hardness (fibreglass)",
    calciName: "hardFibre",
  },
  {
    okNegative: "",
    target: "",
    okPositive: "",
    drainPoint: "",
    name: "calciumHardnessVinyl",
    renderName: "Total Hardness (vinyl liner)",
    calciName: "hardVinyl",
  },

  {
    okNegative: "",
    target: "",
    okPositive: "",
    drainPoint: "",
    name: "copper",
    renderName: "Copper",
    calciName: "copper",
  },
  {
    okNegative: "",
    target: "",
    okPositive: "",
    drainPoint: "",
    name: "phosphate",
    renderName: "Phosphates",
    calciName: "phos",
  },
  {
    okNegative: "",
    target: "",
    okPositive: "",
    drainPoint: "",
    name: "calciumHardnessReduceConcrete",
    renderName: "Total Hardness - Reduce Chemically (concrete or tiled pool)",
    calciName: "calcreduCont",
  },
  {
    okNegative: "",
    target: "",
    okPositive: "",
    drainPoint: "",
    name: "calciumHardnessReduce",
    renderName: "Total Hardness - Reduce Chemically (fibreglass)",
    calciName: "calcreduFibre",
  },
  {
    okNegative: "",
    target: "",
    okPositive: "",
    drainPoint: "",
    name: "calciumHardnessReduceVinyl",
    renderName: "Total Hardness - Reduce Chemically (vinyl liner)",
    calciName: "calcreduVinyl",
  },
];

const chemicalCostState = [
  {
    unit: "per litre",
    buycost: "",
    sellprice: "",
    name: "liquidChlorine",
    renderName: "Liquid Chlorine",
  },
  {
    unit: "per kg",
    buycost: "",
    sellprice: "",
    name: "granularChlorine",
    renderName: "Granular Chlorine",
  },
  {
    unit: "per kg",
    buycost: "",
    sellprice: "",
    name: "sodiumThiosulphate",
    renderName: "Sodium Thiosulphate",
  },
  {
    unit: "per kg",
    buycost: "",
    sellprice: "",
    name: "potassiumMonopersulphate",
    renderName: "Potassium Monopersulphate",
  },
  {
    unit: "per kg",
    buycost: "",
    sellprice: "",
    name: "sodaAsh",
    renderName: "Soda Ash",
  },
  {
    unit: "per litre",
    buycost: "",
    sellprice: "",
    name: "hydrochloricAcid",
    renderName: "Hydrochloric Acid",
  },
  {
    unit: "per kg",
    buycost: "",
    sellprice: "",
    name: "buffer",
    renderName: "Buffer",
  },
  {
    unit: "per kg",
    buycost: "",
    sellprice: "",
    name: "stabiliser",
    renderName: "Stabiliser",
  },
  {
    unit: "per litre",
    buycost: "",
    sellprice: "",
    name: "liquidStabiliser",
    renderName: "Liquid Stabiliser",
  },
  {
    unit: "per kg",
    buycost: "",
    sellprice: "",
    name: "calciumRaiser",
    renderName: "Calcium Raiser",
  },
  {
    unit: "per litre",
    buycost: "",
    sellprice: "",
    name: "phosphatesRemover",
    renderName: "Phosphate Remover",
  },
  {
    unit: "per bag",
    buycost: "",
    sellprice: "",
    name: "salt",
    renderName: "Salt (20 kg)",
  },
  {
    unit: "per bag",
    buycost: "",
    sellprice: "",
    name: "convertSaltPoolToMineralPool",
    renderName: "Convert Salt Pool to Mineral Pool",
  },
  {
    unit: "per bag",
    buycost: "",
    sellprice: "",
    name: "magnaMinerals",
    renderName: "Magna Minerals (10 kg)",
  },
  {
    unit: "per bag",
    buycost: "",
    sellprice: "",
    name: "aquatherepeMinerals",
    renderName: "Aquatherepe Minerals (10 kg)",
  },
  {
    unit: "per bag",
    buycost: "",
    sellprice: "",
    name: "aquatherepeTransform",
    renderName: "Aquatherepe Transform (10 kg)",
  },
  {
    unit: "per litre",
    buycost: "",
    sellprice: "",
    name: "algacide",
    renderName: "Zodiac Pool Long Life Algaecide",
  },
  {
    unit: "per litre",
    buycost: "",
    sellprice: "",
    name: "clarifier",
    renderName: "Clarifier",
  },
  {
    unit: "per kg",
    buycost: "",
    sellprice: "",
    name: "flock",
    renderName: "Flock",
  },
  {
    unit: "5 litres",
    buycost: "",
    sellprice: "",
    name: "bulkHydrochloricAcid5",
    renderName: "Bulk Hydrochloric Acid",
  },
  {
    unit: "20 litres",
    buycost: "",
    sellprice: "",
    name: "bulkHydrochloricAcid20",
    renderName: "Bulk Hydrochloric Acid",
  },
  {
    unit: "15 litres",
    buycost: "",
    sellprice: "",
    name: "bulkLiquidChlorine",
    renderName: "Bulk Liquid Chlorine",
  },
  {
    unit: "per bag",
    buycost: "",
    sellprice: "",
    name: "fineGlassMedia",
    renderName: "Fine Glass Media (15 kg)",
  },
  {
    unit: "per bag",
    buycost: "",
    sellprice: "",
    name: "coarseGlassMedia",
    renderName: "Coarse Glass Media (15 kg)",
  },
  {
    unit: "per bag",
    buycost: "",
    sellprice: "",
    name: "sandMedia",
    renderName: "Sand Media (20 kg)",
  },
  {
    unit: "per litre",
    buycost: "",
    sellprice: "",
    name: "zodiacMetalReducer",
    renderName: "Zodiac Metal Reducer",
  },
  {
    unit: "",
    buycost: "",
    sellprice: "",
    name: "dryAcid",
    renderName: "Dry Acid",
  },
  {
    unit: "",
    buycost: "",
    sellprice: "",
    name: "zodiacCalcium",
    renderName: "Zodiac Calcium Down",
  },
  {
    unit: "",
    buycost: "",
    sellprice: "",
    name: "blackSpotAlgae",
    renderName: "Black-Spot Algae Remover",
  },
];

const balanceWaterState = [
  {
    name: "Free Chlorine",
    increaseName: "Granular Chlorine",
    decreaseName: "Sodium Thiosulphate",
    selected: "",
  },
  {
    name: "Combined Chlorine",
    increaseName: "Zodiac Oxy Fresh",
    decreaseName: "N/A",
    selected: "",
  },
  {
    name: "ph",
    increaseName: "Soda Ash",
    decreaseName: "Hydrochloric Acid",
    selected: "",
  },
  {
    name: "Total Alkalinity (TA)",
    increaseName: "Buffer",
    decreaseName: "Hydrochloric Acid",
    selected: "",
  },
  {
    name: "Total Hardness",
    increaseName: "Calcium Increaser",
    decreaseName: "N/A",
    selected: "",
  },
  {
    name: "Stabiliser (CYA)",
    increaseName: "Stabiliser",
    decreaseName: "N/A",
    selected: "",
  },
  {
    name: "Phosphates",
    increaseName: "Zodiac Phosphates Remover",
    decreaseName: "N/A",
    selected: "",
  },
  {
    name: "Copper",
    increaseName: "Zodiac Metal Reducer",
    decreaseName: "N/A",
    selected: "",
  },
  {
    name: "Algaecide",
    increaseName: "Zodiac Pool Power Algaecide",
    decreaseName: "N/A",
    selected: "",
  },
  {
    name: "Clarifier",
    increaseName: "Zodiac Clarifier",
    decreaseName: "N/A",
    selected: "",
  },
  {
    name: "Flock",
    increaseName: "Zodiac Liquid Floculant",
    decreaseName: "N/A",
    selected: "",
  },
];

const greenPoolState = [
  {
    greenName: "Green 1",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Green 2",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Green 3",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Green 4",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Green 1",
    chemicalName: "Initial Liquid Chlorine Dosage",
    dosageAndTime: "",
    unit: "litres",
    chemicalLine: "",
  },
  {
    greenName: "Green 2",
    chemicalName: "Initial Liquid Chlorine Dosage",
    dosageAndTime: "",
    unit: "litres",
    chemicalLine: "",
  },
  {
    greenName: "Green 3",
    chemicalName: "Initial Liquid Chlorine Dosage",
    dosageAndTime: "",
    unit: "litres",
    chemicalLine: "",
  },
  {
    greenName: "Green 4",
    chemicalName: "Initial Liquid Chlorine Dosage",
    dosageAndTime: "",
    unit: "litres",
    chemicalLine: "",
  },
  {
    greenName: "",
    chemicalName: "Second Chlorine Dosage",
    dosageAndTime: "",
    unit: "%",
    chemicalLine: "(as percentage of initial Dosage)",
  },
  // {
  //   greenName: '',
  //   chemicalName: 'Labour Cost',
  //   dosageAndTime: '',
  //   unit: 'per hour',
  //   chemicalLine: '',

  // },
  {
    greenName: "",
    chemicalName: "Hours Required for 1st & 2nd Step",
    dosageAndTime: "",
    unit: "hours",
    chemicalLine: "(regardless of pool size)",
  },
  {
    greenName: "",
    chemicalName: "Vacuum for Final Step",
    dosageAndTime: "",
    unit: "hours",
    chemicalLine: "(variable proportionate to pool size)",
  },
];

const blackSpotAlgaeState = [
  {
    greenName: "Black-Spot Algae 1 (minimum)",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 2 (light)",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 3 (medium)",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 4 (heavy)",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 1 (minimum)",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 2 (light)",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 3 (medium)",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 4 (heavy)",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 1 (minimum)",
    chemicalName: "Initial Liquid Chlorine Dosage",
    dosageAndTime: "",
    unit: "litres",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 2 (light)",
    chemicalName: "Initial Liquid Chlorine Dosage",
    dosageAndTime: "",
    unit: "litres",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 3 (medium)",
    chemicalName: "Initial Liquid Chlorine Dosage",
    dosageAndTime: "",
    unit: "litres",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 4 (heavy)",
    chemicalName: "Initial Liquid Chlorine Dosage",
    dosageAndTime: "",
    unit: "litres",
    chemicalLine: "",
  },
  {
    greenName: "",
    chemicalName: "Second Chlorine Dosage",
    dosageAndTime: "",
    unit: "%",
    chemicalLine: "(as percentage of initial Dosage)",
  },
  // {
  //   greenName: '',
  //   chemicalName: 'Labour Cost',
  //   dosageAndTime: '',
  //   unit: 'per hour',
  //   chemicalLine: '',

  // },
  {
    greenName: "",
    chemicalName: "Hours Required for 1st & 2nd Step",
    dosageAndTime: "",
    unit: "hours",
    chemicalLine: "(regardless of pool size)",
  },
  {
    greenName: "",
    chemicalName: "Vacuum for Final Step",
    dosageAndTime: "",
    unit: "hours",
    chemicalLine: "(variable proportionate to pool size)",
  },
];

const drainAcidState = [
  {
    name: "Acid Wash - Quantity Needed",
    dosageAndTime: "",
    unit: "litres",
    timeReq: "",
    reqName: "",
    timeReqUnit: "",
    calciName: "acidWashQuantity",
  },
  {
    name: "Chlorine Wash - Quantity Needed",
    dosageAndTime: "",
    unit: "litres",
    timeReq: "",
    reqName: "",
    timeReqUnit: "",
    calciName: "chlorineWashQuantity",
  },
  {
    name: "Pressure Wash Time Required",
    dosageAndTime: "",
    unit: "per hour",
    timeReq: "",
    timereqName: "",
    timeReqUnit: "",
    calciName: "pressureWashHourly",
  },
  {
    name: "Acid Wash Time Required",
    dosageAndTime: "",
    unit: "per hour",
    timeReq: "",
    timereqName: "",
    timeReqUnit: "",
    calciName: "acidWashHourly",
  },
  {
    name: "Chlorine Wash Time Required",
    dosageAndTime: "",
    unit: "per hour",
    timeReq: "",
    timereqName: "",
    timeReqUnit: "",
    calciName: "chlorineWashHourly",
  },
  {
    name: "Removing Debris Time Required",
    dosageAndTime: "",
    unit: "per hour",
    timeReq: "",
    timereqName: "",
    timeReqUnit: "hours",
    calciName: "removeDebris",
  },
  {
    name: "Pool Size",
    dosageAndTime: "",
    unit: "litres",
    timeReq: "",
    timereqName: "",
    timeReqUnit: "",
    calciName: "acidPoolSize",
  },
];

const drainageCostState = {
  poolsize: "",
  drainagePercentage: "",
  setupCost: "",
  hourlyRate: "",
  litresPerHour: "",
  hoursToDrain: "",
  cost: "",
};

const chlorinatorModelState = {
  name: "",
  saltLevel: "",
  okNegative: "",
  okPositive: "",
  drainPoint: "",
  orp: "No",
  recmndStabiliserLvl: "",
};
const calciumAdjustmentState = {
  brandName: "",
  magnesium: "",
  calcium: "",
  saltLevel: "",
  startTime: "AutoMatic Chlorinator",
  okNegative: "",
  okPositive: "",
  drainPoint: "",
  endTime: "Automatic Chlorinator",
  orp: "No",
  stabiliserLevel: "",
};

const sandFilterMediaState = [
  {
    mediaFilterSize: "",
    bagsGlass: "",
    glassCoarseQuantity: "",
    glassFineQuantity: "",
    bagsSand: "",
    sandQuantity: "",
    unit: "",
  },
  {
    mediaFilterSize: "",
    bagsGlass: "",
    glassCoarseQuantity: "",
    glassFineQuantity: "",
    bagsSand: "",
    sandQuantity: "",
    unit: "",
  },
  {
    mediaFilterSize: "",
    bagsGlass: "",
    glassCoarseQuantity: "",
    glassFineQuantity: "",
    bagsSand: "",
    sandQuantity: "",
    unit: "",
  },
  {
    mediaFilterSize: "",
    bagsGlass: "",
    glassCoarseQuantity: "",
    glassFineQuantity: "",
    bagsSand: "",
    sandQuantity: "",
    unit: "",
  },
  {
    mediaFilterSize: "",
    bagsGlass: "",
    glassCoarseQuantity: "",
    glassFineQuantity: "",
    bagsSand: "",
    sandQuantity: "",
    unit: "",
  },
  {
    mediaFilterSize: "",
    bagsGlass: "",
    glassCoarseQuantity: "",
    glassFineQuantity: "",
    bagsSand: "",
    sandQuantity: "",
    unit: "",
  },
  {
    mediaFilterSize: "",
    bagsGlass: "",
    glassCoarseQuantity: "",
    glassFineQuantity: "",
    bagsSand: "",
    sandQuantity: "",
    unit: "",
  },
  {
    mediaFilterSize: "",
    bagsGlass: "",
    glassCoarseQuantity: "",
    glassFineQuantity: "",
    bagsSand: "",
    sandQuantity: "",
    unit: "",
  },
  {
    mediaFilterSize: "",
    bagsGlass: "",
    glassCoarseQuantity: "",
    glassFineQuantity: "",
    bagsSand: "",
    sandQuantity: "",
    unit: "",
  },
  {
    mediaFilterSize: "",
    bagsGlass: "",
    glassCoarseQuantity: "",
    glassFineQuantity: "",
    bagsSand: "",
    sandQuantity: "",
    unit: "",
  },
];

const secondVisitCostState = {
  duration: "",
  cost: "",
  durationChange: "",
  customCost: "",
  pricingType: "",
};

const chemicalDisplayState = {
  freeChlorine: true,
  totalChlorine: true,
  pH: true,
  totalAlkalinity: true,
  calciumHardness: false,
  stabiliser: true,
  copper: false,
  phosphates: false,
  salt: true,
};

const taxApplicableState = {
  chemicalsTax: "",
  labourTax: "",
};

const userPoolState = {
  poolDepth: "Average Depth - Rectangular Pool",
  poolWidth: "",
  poolHeight: "",
  diameter: "",
  shallowEnd: "",
  deepEnd: "",
  poolFinish: "Concrete Pool - Outdoor",
  chlorinationType: "Salt Pool",
  chlorineModel: [],
  selectedModel: "",
  poolId: "",
  poolCapacity: 0,
  poolMsg: "",
};
const targetLevelDefaultState = [
  {
    okNegative: "2",
    target: "3",
    okPositive: "5.99",
    drainPoint: "N/A",
    name: "chlorine",
    renderName: "Free Chlorine",
    calciName: "chlor",
  },
  {
    okNegative: "0",
    target: "0",
    okPositive: "0.19",
    drainPoint: "6",
    name: "combinedChlorine",
    renderName: "Combined Chlorine",
    calciName: "comb",
  },
  {
    okNegative: "7.3",
    target: "7.5",
    okPositive: "7.7",
    drainPoint: "N/A",
    name: "phConcreteTilted",
    renderName: "pH (concrete or tiled pool)",
    calciName: "phCont",
  },
  {
    okNegative: "7.1",
    target: "7.2",
    okPositive: "7.3",
    drainPoint: "N/A",
    name: "phfibre",
    renderName: "pH (fibreglass)",
    calciName: "phFibre",
  },
  {
    okNegative: "7.3",
    target: "7.5",
    okPositive: "7.7",
    drainPoint: "N/A",
    name: "phliner",
    renderName: "pH (vinyl liner)",
    calciName: "phVinyl",
  },
  {
    okNegative: "100",
    target: "110",
    okPositive: "120",
    drainPoint: "N/A",
    name: "alkalinity",
    renderName: "Alkalinity (TA)",
    calciName: "alk",
  },
  {
    okNegative: "18",
    target: "20",
    okPositive: "+49.99%",
    drainPoint: "+50%",
    name: "stabiliserOrp",
    renderName: "Stabiliser (CYA) (with Orp probe)",
    calciName: "stabOrp",
  },
  {
    okNegative: "35",
    target: "40",
    okPositive: "+49.99%",
    drainPoint: "+50%",
    name: "stabiliserCya",
    renderName: "Stabiliser (CYA)",
    calciName: "stab",
  },
  {
    okNegative: "250",
    target: "260",
    okPositive: "+49.99%",
    drainPoint: "+50%",
    name: "calciumHardnessConcrete",
    renderName: "Total Hardness (concrete or tiled pool)",
    calciName: "hardCont",
  },
  {
    okNegative: "250",
    target: "260",
    okPositive: "+49.99%",
    drainPoint: "+50%",
    name: "calciumHardnessFibre",
    renderName: "Total Hardness (fibreglass)",
    calciName: "hardFibre",
  },
  {
    okNegative: "250",
    target: "260",
    okPositive: "+49.99%",
    drainPoint: "+50%",
    name: "calciumHardnessVinyl",
    renderName: "Total Hardness (vinyl liner)",
    calciName: "hardVinyl",
  },
  {
    okNegative: "0",
    target: "0",
    okPositive: "0.9",
    drainPoint: "0.6",
    name: "copper",
    renderName: "Copper",
    calciName: "copper",
  },
  {
    okNegative: "0",
    target: "0",
    okPositive: "149.99",
    drainPoint: "N/A",
    name: "phosphate",
    renderName: "Phosphates",
    calciName: "phos",
  },
  {
    okNegative: "250",
    target: "260",
    okPositive: "300",
    drainPoint: "N/A",
    name: "calciumHardnessReduceConcrete",
    renderName: "Total Hardness - Reduce Chemically (concrete or tiled pool)",
    calciName: "calcreduCont",
  },
  {
    okNegative: "250",
    target: "260",
    okPositive: "300",
    drainPoint: "N/A",
    name: "calciumHardnessReduceFibre",
    renderName: "Total Hardness - Reduce Chemically (fibreglass)",
    calciName: "calcreduFibre",
  },
  {
    okNegative: "250",
    target: "260",
    okPositive: "300",
    drainPoint: "N/A",
    name: "calciumHardnessReduceVinyl",
    renderName: "Total Hardness - Reduce Chemically (vinyl liner)",
    calciName: "calcreduVinyl",
  },
];
const chemicalCostDefaultState = [
  {
    unit: "per litre",
    buycost: "1.07",
    sellprice: "3.21",
    name: "liquidChlorine",
    renderName: "Liquid Chlorine",
    calciName: "Liquid",
  },
  {
    unit: "per kg",
    buycost: "4.08",
    sellprice: "12.24",
    name: "granularChlorine",
    renderName: "Granular Chlorine",
    calciName: "Granular",
  },
  {
    unit: "per kg",
    buycost: "26",
    sellprice: "78",
    name: "sodiumThiosulphate",
    renderName: "Sodium Thiosulphate",
    calciName: "SodiumThi",
  },
  {
    unit: "per kg",
    buycost: "10.052",
    sellprice: "45",
    name: "potassiumMonopersulphate",
    renderName: "Potassium Monopersulphate - Oxy Fresh",
    calciName: "Potassium",
  },
  {
    unit: "per kg",
    buycost: "30",
    sellprice: "45",
    name: "potassiumMonopersulphateOxyBoost",
    renderName: "Potassium Monopersulphate - Oxy Boost",
    calciName: "PotassiumOxyBoost",
  },
  {
    unit: "per kg",
    buycost: "1.59",
    sellprice: "29.415",
    name: "sodaAsh",
    renderName: "Soda Ash",
    calciName: "PhSodaAsh",
  },
  {
    unit: "per litre",
    buycost: "2.33",
    sellprice: "12.583",
    name: "hydrochloricAcid",
    renderName: "Hydrochloric Acid",
    calciName: "PhAcid",
  },
  {
    unit: "per kg",
    buycost: "1.48",
    sellprice: "21.70",
    name: "buffer",
    renderName: "Buffer",
    calciName: "AlkBuffer",
  },
  {
    unit: "per kg",
    buycost: "3.79",
    sellprice: "25.772",
    name: "stabiliser",
    renderName: "Stabiliser",
    calciName: "Stabiliser",
  },
  {
    unit: "per litre",
    buycost: "13.98",
    sellprice: "83.88",
    name: "liquidStabiliser",
    renderName: "Liquid Stabiliser",
    calciName: "liquidStab",
  },
  {
    unit: "per kg",
    buycost: "1.48",
    sellprice: "16.10",
    name: "calciumRaiser",
    renderName: "Calcium Raiser",
    calciName: "CalciumRaiser",
  },
  {
    unit: "per litre",
    buycost: "14.74",
    sellprice: "81.07",
    name: "phosphatesRemover",
    renderName: "Phosphate Remover",
    calciName: "Phosphates",
  },
  {
    unit: "per bag",
    buycost: "7.95",
    sellprice: "30",
    name: "salt",
    renderName: "Salt (20 kg)",
    calciName: "Salt",
  },
  {
    unit: "per bag",
    buycost: "32.45",
    sellprice: "65",
    name: "magnaMinerals",
    renderName: "Magna Minerals (10 kg)",
    calciName: "Magna",
  },
  {
    unit: "per bag",
    buycost: "24.53",
    sellprice: "65",
    name: "aquatherepeMinerals",
    renderName: "Aquatherepe Minerals (10 kg)",
    calciName: "Aqua",
  },
  {
    unit: "per bag",
    buycost: "50.69",
    sellprice: "130",
    name: "aquatherepeTransform",
    renderName: "Aquatherepe Transform (10 kg)",
    calciName: "Convert",
  },
  {
    unit: "per litre",
    buycost: "4.60",
    sellprice: "45.40",
    name: "algacide",
    renderName: "Zodiac Pool Long Life Algaecide",
    calciName: "Algaecide",
  },
  {
    unit: "per litre",
    buycost: "12",
    sellprice: "48",
    name: "clarifier",
    renderName: "Clarifier",
    calciName: "Clarifier",
  },
  {
    unit: "per litre",
    buycost: "12",
    sellprice: "25.00",
    name: "clarifierGreen",
    renderName: "Clarifier (green pool)",
    calciName: "ClarifierGreen",
  },
  {
    unit: "per kg",
    buycost: "10",
    sellprice: "30",
    name: "flock",
    renderName: "Flock",
    calciName: "Flock",
  },
  {
    unit: "5 litres",
    buycost: "11.65",
    sellprice: "25.00",
    name: "bulkHydrochloricAcid5",
    renderName: "Bulk Hydrochloric Acid",
    calciName: "",
  },
  {
    unit: "20 litres",
    buycost: "46.55",
    sellprice: "95.00",
    name: "bulkHydrochloricAcid20",
    renderName: "Bulk Hydrochloric Acid",
    calciName: "",
  },
  {
    unit: "15 litres",
    buycost: "15.95",
    sellprice: "35.00",
    name: "bulkLiquidChlorine",
    renderName: "Bulk Liquid Chlorine",
    calciName: "",
  },
  {
    unit: "per bag",
    buycost: "11.00",
    sellprice: "33.00",
    name: "fineGlassMedia",
    renderName: "Fine Glass Media (15 kg)",
    calciName: "",
  },
  {
    unit: "per bag",
    buycost: "11.00",
    sellprice: "33.00",
    name: "coarseGlassMedia",
    renderName: "Coarse Glass Media (15 kg)",
    calciName: "",
  },
  {
    unit: "per bag",
    buycost: "14.96",
    sellprice: "41.00",
    name: "sandMedia",
    renderName: "Sand Media (20 kg)",
    calciName: "",
  },
  {
    unit: "per litre",
    buycost: "17",
    sellprice: "85",
    name: "zodiacMetalReducer",
    renderName: "Zodiac Metal Reducer",
    calciName: "Copper",
  },
  {
    unit: "per kg",
    buycost: "7.00",
    sellprice: "37.85",
    name: "dryAcid",
    renderName: "Dry Acid",
    calciName: "DryAcid",
  },
  {
    unit: "per litre",
    buycost: "19.68",
    sellprice: "59.04",
    name: "zodiacCalcium",
    renderName: "Zodiac Calcium Down",
    calciName: "ZodiacCalcium",
  },
  {
    unit: "per kg",
    buycost: "28.00",
    sellprice: "56.00",
    name: "blackSpotAlgae",
    renderName: "Black-Spot Algae Remover",
    calciName: "BlackSpotAlgae",
  },
];
const drainageCostDefaultState = {
  poolsize: "40000",
  drainagePercentage: "95",
  setupCost: "100",
  hourlyRate: "100",
  litresPerHour: "10000",
  hoursToDrain: "3.80",
  cost: "480.00",
};

const chemicalBalanceWaterDefaultState = [
  {
    name: "Free Chlorine",
    increaseName: "Granular Chlorine",
    decreaseName: "Sodium Thiosulphate",
    selected: "Granular Chlorine",
  },
  {
    name: "Combined Chlorine",
    increaseName: "Zodiac Oxy Fresh",
    decreaseName: "N/A",
    selected: "Zodiac Oxy Fresh",
  },
  {
    name: "pH",
    increaseName: "Soda Ash",
    decreaseName: "Hydrochloric Acid",
    selected: "Hydrochloric Acid",
  },
  {
    name: "Total Alkalinity (TA)",
    increaseName: "Buffer",
    decreaseName: "Hydrochloric Acid",
    selected: "Hydrochloric Acid",
  },
  {
    name: "Total Hardness",
    increaseName: "Calcium Increaser",
    decreaseName: "Reduce by Part-Draining",
    selected: "Reduce by Part-Draining",
  },
  {
    name: "Stabiliser (CYA)",
    increaseName: "Granular Stabiliser",
    decreaseName: "N/A",
    selected: "Granular Stabiliser",
  },
  {
    name: "Phosphates",
    increaseName: "Zodiac Phosphates Remover",
    decreaseName: "N/A",
    selected: "Zodiac Phosphates Remover",
  },
  {
    name: "Copper",
    increaseName: "Zodiac Metal Reducer",
    decreaseName: "N/A",
    selected: "Zodiac Metal Reducer",
  },
  {
    name: "Algaecide",
    increaseName: "Zodiac Pool Power Algaecide",
    decreaseName: "N/A",
    selected: "Zodiac Pool Power Algaecide",
  },
  {
    name: "Clarifier",
    increaseName: "Zodiac Clarifier",
    decreaseName: "N/A",
    selected: "Zodiac Clarifier",
  },
  {
    name: "Flock",
    increaseName: "Zodiac Liquid Floculant",
    decreaseName: "N/A",
    selected: "Zodiac Liquid Floculant",
  },
];

// const drainAcidDefaultState = {
//   // acidWash: '40',
//   // chlorineWash: '30',
//   // pressureWash: '120',
//   // presTimeReq: '2.5',
//   // acidHourly: '150',
//   // aciTimeReq: '1.5',
//   // chlorineHourly: '120',
//   // chlTimeReq: '1.5',
//   // debrisHourly: '100',
//   // debTimeReq: '1.0',
// };

// const drainAcidDefaultState = [
//   {
//     name: 'Acid Wash - Quantity Needed',
//     dosageAndTime: '40',
//     unit: 'litres',
//     timeReq: '',
//     reqName: '',
//     timeReqUnit: '',
//   },
//   {
//     name: 'Chlorine Wash - Quantity Needed',
//     dosageAndTime: '30',
//     unit: 'litres',
//     timeReq: '',
//     reqName: '',
//     timeReqUnit: '',
//   },
//   {
//     name: 'Pressure Wash Time Required',
//     dosageAndTime: '120',
//     unit: 'per hour',
//     timeReq: '2.5',
//     timereqName: '',
//     timeReqUnit: 'hours',
//   },
//   {
//     name: 'Acid Wash Time Required',
//     dosageAndTime: '150',
//     unit: 'per hour',
//     timeReq: '1.5',
//     timereqName: '',
//     timeReqUnit: 'hours',
//   },
//   {
//     name: 'Chlorine Wash Time Required',
//     dosageAndTime: '120',
//     unit: 'per hour',
//     timeReq: '1.5',
//     timereqName: '',
//     timeReqUnit: 'hours',
//   },
//   {
//     name: 'Removing Debris Time Required',
//     dosageAndTime: '100',
//     unit: 'per hour',
//     timeReq: '1.0',
//     timereqName: '',
//     timeReqUnit: 'hours',
//   },
//   {
//     name: 'Pool Size',
//     dosageAndTime: '40000',
//     unit: 'litres',
//     timeReq: '',
//     timereqName: '',
//     timeReqUnit: '',
//   },

// ];

const drainAcidDefaultState = [
  {
    name: "Acid Wash - Quantity Needed",
    dosageAndTime: "40",
    unit: "litres",
    timeReq: "",
    reqName: "",
    timeReqUnit: "",
    calciName: "acidWashQuantity",
  },
  {
    name: "Chlorine Wash - Quantity Needed",
    dosageAndTime: "30",
    unit: "litres",
    timeReq: "",
    reqName: "",
    timeReqUnit: "",
    calciName: "chlorineWashQuantity",
  },
  {
    name: "Pressure Wash Time Required",
    dosageAndTime: "120",
    unit: "per hour",
    timeReq: "2.5",
    timereqName: "- Time Required",
    timeReqUnit: "hours",
    calciName: "pressureWashHourly",
  },
  {
    name: "Acid Wash Time Required",
    dosageAndTime: "150",
    unit: "per hour",
    timeReq: "1.5",
    timereqName: "- Time Required",
    timeReqUnit: "hours",
    calciName: "acidWashHourly",
  },
  {
    name: "Chlorine Wash Time Required",
    dosageAndTime: "120",
    unit: "per hour",
    timeReq: "1.5",
    timereqName: "- Time Required",
    timeReqUnit: "hours",
    calciName: "chlorineWashHourly",
  },
  {
    name: "Removing Debris Time Required",
    dosageAndTime: "100",
    unit: "per hour",
    timeReq: "1.0",
    timereqName: "- Time Required",
    timeReqUnit: "hours",
    calciName: "removeDebris",
  },
  {
    name: "Pool Size",
    dosageAndTime: "10000",
    unit: "litres",
    timeReq: "",
    timereqName: "",
    timeReqUnit: "",
    calciName: "acidPoolSize",
  },
];

const greenPoolCalculationDefaultState = [
  {
    greenName: "Green 1",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "5.50",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Green 2",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "6.00",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Green 3",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "7.00",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Green 4",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "9.00",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Green 1",
    chemicalName: "Initial Liquid Chlorine Dosage",
    dosageAndTime: "27.50",
    unit: "litres",
    chemicalLine: "",
  },
  {
    greenName: "Green 2",
    chemicalName: "Initial Liquid Chlorine Dosage",
    dosageAndTime: "30.00",
    unit: "litres",
    chemicalLine: "",
  },
  {
    greenName: "Green 3",
    chemicalName: "Initial Liquid Chlorine Dosage",
    dosageAndTime: "35.00",
    unit: "litres",
    chemicalLine: "",
  },
  {
    greenName: "Green 4",
    chemicalName: "Initial Liquid Chlorine Dosage",
    dosageAndTime: "45.00",
    unit: "litres",
    chemicalLine: "",
  },
  {
    greenName: "",
    chemicalName: "Second Chlorine Dosage",
    dosageAndTime: "75",
    unit: "%",
    chemicalLine: "(as percentage of initial Dosage)",
  },
  // {
  //   greenName: '',
  //   chemicalName: 'Labour Cost',
  //   dosageAndTime: '100.00',
  //   unit: 'per hour',
  //   chemicalLine: '',
  // },
  {
    greenName: "",
    chemicalName: "Hours Required for 1st & 2nd Step",
    dosageAndTime: "1.5",
    unit: "hours",
    chemicalLine: "(regardless of pool size)",
  },
  {
    greenName: "",
    chemicalName: "Vacuum  for Final Step",
    dosageAndTime: "1.5",
    unit: "hours",
    chemicalLine: "(variable proportionate to pool size)",
  },
  {
    greenName: "",
    chemicalName: "Pool Size",
    dosageAndTime: "40000",
    unit: "litres",
    chemicalLine: "(variable cost proportionate to pool size)",
  },
];

const blackSpotAlgaeDefaultState = [
  {
    greenName: "Black-Spot Algae 1 (minimum)",
    chemicalName: "Initial Black-Spot Remover Dosage",
    dosageAndTime: "2.0",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 2 (light)",
    chemicalName: "Initial Black-Spot Remover Dosage",
    dosageAndTime: "2.3",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 3 (medium)",
    chemicalName: "Initial Black-Spot Remover Dosage",
    dosageAndTime: "2.5",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 4 (heavy)",
    chemicalName: "Initial Black-Spot Remover Dosage",
    dosageAndTime: "3.0",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 1 (minimum)",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "5.5",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 2 (light)",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "6.0",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 3 (medium)",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "7.0",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 4 (heavy)",
    chemicalName: "Initial Granular Chlorine Dosage",
    dosageAndTime: "10",
    unit: "kg",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 1 (minimum)",
    chemicalName: "Initial Liquid Chlorine Dosage",
    dosageAndTime: "27.5",
    unit: "litres",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 2 (light)",
    chemicalName: "Initial Liquid Chlorine Dosage",
    dosageAndTime: "30.0",
    unit: "litres",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 3 (medium)",
    chemicalName: "Initial Liquid Chlorine Dosage",
    dosageAndTime: "35.0",
    unit: "litres",
    chemicalLine: "",
  },
  {
    greenName: "Black-Spot Algae 4 (heavy)",
    chemicalName: "Initial Liquid Chlorine Dosage",
    dosageAndTime: "45.0",
    unit: "litres",
    chemicalLine: "",
  },
  {
    greenName: "",
    chemicalName: "Second Chlorine Dosage",
    dosageAndTime: "75",
    unit: "%",
    chemicalLine: "(as percentage of initial Dosage)",
  },
  // {
  //   greenName: '',
  //   chemicalName: 'Labour Cost',
  //   dosageAndTime: '100.00',
  //   unit: 'per hour',
  //   chemicalLine: '',

  // },
  {
    greenName: "",
    chemicalName: "Hours Required for 1st & 2nd Step",
    dosageAndTime: "1.5",
    unit: "hours",
    chemicalLine: "(regardless of pool size)",
  },
  {
    greenName: "",
    chemicalName: "Vacuum for Final Step",
    dosageAndTime: "1.5",
    unit: "hours",
    chemicalLine: "(variable cost proportionate to pool size)",
  },
  {
    greenName: "",
    chemicalName: "Pool Size",
    dosageAndTime: "40000",
    unit: "litres",
    chemicalLine: "(variable cost proportionate to pool size)",
  },
];

const sandFilterMediaDefaultState = [
  {
    mediaFilterSize: "24",
    bagsGlass: "7",
    glassCoarseQuantity: {
      quantity: "2",
      details: "bags Coarse Glass",
    },
    glassFineQuantity: {
      quantity: "5",
      details: "bags Fine Glass",
    },
    bagsSand: "6",
    sandQuantity: "120",
    unit: "kg",
  },
  {
    mediaFilterSize: "25",
    bagsGlass: "8",
    glassCoarseQuantity: {
      quantity: "3",
      details: "bags Coarse Glass",
    },
    glassFineQuantity: {
      quantity: "5",
      details: "bags Fine Glass",
    },
    bagsSand: "7",
    sandQuantity: "140",
    unit: "kg",
  },
  {
    mediaFilterSize: "27",
    bagsGlass: "9",
    glassCoarseQuantity: {
      quantity: "3",
      details: "bags Coarse Glass",
    },
    glassFineQuantity: {
      quantity: "6",
      details: "bags Fine Glass",
    },
    bagsSand: "8",
    sandQuantity: "160",
    unit: "kg",
  },
  {
    mediaFilterSize: "28",
    bagsGlass: "10",
    glassCoarseQuantity: {
      quantity: "3",
      details: "bags Coarse Glass",
    },
    glassFineQuantity: {
      quantity: "7",
      details: "bags Fine Glass",
    },
    bagsSand: "9",
    sandQuantity: "180",
    unit: "kg",
  },
  {
    mediaFilterSize: "30",
    bagsGlass: "11",
    glassCoarseQuantity: {
      quantity: "4",
      details: "bags Coarse Glass",
    },
    glassFineQuantity: {
      quantity: "7",
      details: "bags Fine Glass",
    },
    bagsSand: "10",
    sandQuantity: "200",
    unit: "kg",
  },
  {
    mediaFilterSize: "32",
    bagsGlass: "18",
    glassCoarseQuantity: {
      quantity: "6",
      details: "bags Coarse Glass",
    },
    glassFineQuantity: {
      quantity: "12",
      details: "bags Fine Glass",
    },
    bagsSand: "16",
    sandQuantity: "320",
    unit: "kg",
  },
  {
    mediaFilterSize: "36",
    bagsGlass: "23",
    glassCoarseQuantity: {
      quantity: "7",
      details: "bags Coarse Glass",
    },
    glassFineQuantity: {
      quantity: "16",
      details: "bags Fine Glass",
    },
    bagsSand: "20",
    sandQuantity: "400",
    unit: "kg",
  },
  // {
  //   mediaFilterSize: 'Labour Cost',
  //   bagsGlass: '',
  //   glassCoarseQuantity: '',
  //   glassFineQuantity: '',
  //   bagsSand: '',
  //   sandQuantity: '150.00',
  //   unit: 'per hour',
  // },
  {
    mediaFilterSize: "Set-up & Pack-up Time",
    bagsGlass: "",
    glassCoarseQuantity: "",
    glassFineQuantity: "",
    bagsSand: "",
    sandQuantity: "0.9",
    unit: "hours",
  },
  {
    mediaFilterSize: "Hours Required",
    bagsGlass: "",
    glassCoarseQuantity: "",
    glassFineQuantity: "",
    bagsSand: "",
    sandQuantity: "2.5",
    unit: "hours",
  },
];

const defaultSecondVisitState = {
  duration: "60",
  cost: "100.00",
  durationChange: "",
  customCost: "default",
  pricingType: "fixedCost",
};

const defaultChemicalDisplayState = {
  freeChlorine: true,
  totalChlorine: true,
  pH: true,
  totalAlkalinity: true,
  calciumHardness: true,
  stabiliser: true,
  copper: true,
  phosphates: true,
  salt: true,
};

const defaultTaxApplicableState = {
  chemicalsTax: "10",
  labourTax: "10",
};

const defaultUserPoolState = {
  poolDepth: "Average Depth - Rectangular Pool",
  poolWidth: "",
  poolHeight: "",
  diameter: "",
  shallowEnd: "",
  deepEnd: "",
  poolFinish: "Concrete Pool - Outdoor",
  chlorinationType: "Salt Pool",
  chlorineModel: [],
  selectedModel: "",
  poolId: "",
  poolCapacity: 0,
  poolMsg: "",
};

const poolDepthArray = [
  "Average Depth - Rectangular Pool",
  "Average Depth - Circular pool",
  "Specific Depths - Rectangular Pool",
  "Specific Depths - Circular Pool",
];

const poolFinishArray = [
  "Concrete Pool - Outdoor",
  "Fibreglass Pool - Outdoor",
  "Vinyl Liner Pool - Outdoor",
  "Concrete Pool - Indoor",
  "Fibreglass Pool - Indoor",
  "Vinyl Liner Pool - Indoor",
];
const chlorinationArray = [
  { name: "Salt Pool", value: "Salt Pool" },
  { name: "Mineral Pool with Chlorinator", value: "Mineral Pool" },
  //  { name: 'Mineral Pool with Chlorinator + Calcium Hardness Titration Test', value: 'Calcium Hardness Titration Test' }, { name: 'Convert Salt Pool to Mineral Pool', value: 'Convert Salt Pool to Mineral Pool' },
  { name: "Manually Chlorinated", value: "Manually Chlorinated" },
];
const poolTypeArray = [
  "Non-Mineral Pool",
  "Aquatherepe Transform",
  // 'Convert Pool to Mineral Pool',
  // "Aquatherepe Transform Maintenance",
];
const convertPoolChlorineArray = [
  "Aquatherepe Transform",
  "Aquatherepe Transform - With ORP Probe",
];

export {
  targetLevelState,
  chemicalCostState,
  drainageCostState,
  balanceWaterState,
  chlorinatorModelState,
  calciumAdjustmentState,
  sandFilterMediaState,
  targetLevelDefaultState,
  chemicalCostDefaultState,
  drainageCostDefaultState,
  chemicalBalanceWaterDefaultState,
  drainAcidState,
  drainAcidDefaultState,
  greenPoolCalculationDefaultState,
  greenPoolState,
  blackSpotAlgaeState,
  blackSpotAlgaeDefaultState,
  sandFilterMediaDefaultState,
  defaultSecondVisitState,
  secondVisitCostState,
  chemicalDisplayState,
  defaultChemicalDisplayState,
  taxApplicableState,
  defaultTaxApplicableState,
  defaultUserPoolState,
  userPoolState,
  poolFinishArray,
  chlorinationArray,
  poolTypeArray,
  convertPoolChlorineArray,
  poolDepthArray,
};
