import React from "react";
import { PropTypes, bool } from "prop-types";
import {
  SquarePaymentsForm,
  CreditCardInput,
} from "react-square-web-payments-sdk";

const SquareCheckout = ({
  handleToken,
  disableButton,
  amountDisplay,
  paymentLoader,
}) => {
  const cssForButtton = {
    backgroundColor: "#1aa01d",
    fontSize: "15px",
    fontWeight: 600,
    borderRadius: "10px",
    paddingTop: 0,
    paddingBottom: 0,
    height: "38px",
  };

  if (disableButton) cssForButtton.pointerEvents = "none";

  return (
    <div className="staff-form">
      <SquarePaymentsForm
        // applicationId="sandbox-sq0idb-oYeu027nYUL7QSs5ZNQDxA"
        applicationId="sq0idp-EgJ14cVa3kPylu6_jQSC2Q"
        // locationId="LXD1PHSA0X03H"
        locationId="LGW98AKAE5CA0"
        cardTokenizeResponseReceived={(token) => handleToken(token)}
        createPaymentRequest={() => ({
          countryCode: "AU",
          currencyCode: "AUD",
          // total: {
          //   amount: '500',
          //   label: 'Total',
          // },
        })}
      >
        <CreditCardInput
          text={`${`Pay $${amountDisplay ? Number(amountDisplay)?.toFixed(2) : "0.00"}`} `}
          includeInputLabels
          style={{
            input: {
              fontSize: "14px",
            },
          }}
          overrideStyles={cssForButtton}
        />
      </SquarePaymentsForm>
    </div>
  );
};
SquareCheckout.defaultProps = {
  paymentLoader: false,
};
SquareCheckout.propTypes = {
  handleToken: PropTypes.func.isRequired,
  disableButton: PropTypes.bool.isRequired,
  amountDisplay: PropTypes.number.isRequired,
  paymentLoader: bool,
};
export default SquareCheckout;
