/* eslint-disable no-undef */
import React from "react";
import { Form, Button, Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";

const TaxApplicable = ({
  handleChangeTaxValues,
  handleRequest,
  state,
  error,
  taxStatus,
  checkBlankFields,
}) => (
  <div className="pool-setup mineral-pool drainage-cost-section tax-applicable-page">
    <div className="target-heading">
      <h6>Tax Applicable</h6>
    </div>
    <div className="pool-chemistry-box">
      <div className="row">
        <div className="col-12">
          <Form>
            <Form.Group controlId="formBasicInput" className="italic-text">
              <Form.Label>Chemicals</Form.Label>
              <Form.Control
                type="texkt"
                name="chemicalsTax"
                onChange={handleChangeTaxValues}
                value={state?.chemicalsTax}
                placeholder="Enter Tax (if no tax, enter as 0)"
                className={`${
                  checkBlankFields.includes("chemicalsTax") &&
                  !state?.chemicalsTax
                    ? "error-text"
                    : ""
                }`}
              />
              <span className="price-icon">%</span>
            </Form.Group>
          </Form>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Form>
            <Form.Group controlId="formBasicInput" className="italic-text">
              <Form.Label> Labour</Form.Label>
              <Form.Control
                type="text"
                name="labourTax"
                onChange={handleChangeTaxValues}
                value={state?.labourTax}
                placeholder="Enter Tax (if no tax, enter as 0)"
                className={`${
                  checkBlankFields.includes("labourTax") && !state?.labourTax
                    ? "error-text"
                    : ""
                }`}
              />
              <span className="price-icon">%</span>
            </Form.Group>
          </Form>
        </div>
      </div>

      <div className="view-btn justify-content-end">
        <Button type="submit" className="btn btn-save" onClick={handleRequest}>
          Save/Update
        </Button>
      </div>
    </div>
  </div>
);
TaxApplicable.propTypes = {
  handleChangeTaxValues: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired,
  state: PropTypes.isRequired,
  error: PropTypes.string.isRequired,
  taxStatus: PropTypes.bool.isRequired,
  checkBlankFields: PropTypes.arrayOf.isRequired,
};

export default TaxApplicable;
