import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { PropTypes } from "prop-types";
import showimg from "../../assets/home/banner.jpg";
import cross from "../../assets/home/black-icon.png";

const AppointmentCheckList = ({
  checkList,
  handleCheckList,
  handleMarkasComplete,
  chemicalsAdded,
  handleFileInputChange,
  image,
  removePhotos,
  AddCaptions,
  checklistArray,
  handleChecked,
  error,
}) => (
  <Modal
    size="md"
    show={checkList}
    onHide={handleCheckList}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="staff-modal view-appointment-popup checklist-popup"
  >
    <div className="staff-close">
      <i className="fa fa-times" onClick={handleCheckList} aria-hidden="true" />
    </div>
    <Modal.Body>
      <div className="staff-form">
        <h6>Pool maintenance checklist</h6>
        {checklistArray &&
          checklistArray.map((el, i) => (
            <div
              className="added-chemicals"
              onClick={(e) => handleChecked(e, i)}
            >
              <h6>
                <span>{i + 1}.</span> {el.name}
              </h6>
              <div className="open-date client-notes">
                {el.checked ? (
                  <div className="basic">
                    <Form.Check type="checkbox" checked={el.checked} />
                  </div>
                ) : (
                  <div className="basic cross-check">
                    <Form.Check type="checkbox" checked={el.tempChecked} />
                  </div>
                )}
              </div>
            </div>
          ))}
        <div className="take-photo">
          <div className="photo-icon">
            <h6>Take Photo</h6>
            <div className="upload-img">
              <Form.Group className="" controlId="exampleForm.ControlInput1">
                <Form.Label htmlFor="upload-image">
                  <i className="fa fa-camera" aria-hidden="true" />
                </Form.Label>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  capture="environment"
                  onChange={handleFileInputChange}
                  id="upload-image"
                  name="images"
                />
              </Form.Group>
            </div>
          </div>
          {image && image.length > 0 ? (
            <>
              {image &&
                image.map((el, i) => (
                  <>
                    <div className="show-img">
                      <img
                        src={window.URL.createObjectURL(el.data)}
                        alt="img"
                      />
                      <div className="replace-img">
                        <img
                          src={cross}
                          alt="cancel"
                          name="removePhoto"
                          onClick={(e) => removePhotos(e, i)}
                        />
                      </div>
                    </div>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="text"
                        placeholder="Enter Photo Caption"
                        value={el.caption}
                        onChange={(e) => AddCaptions(e, i)}
                        className={`${error === "caption" ? "error-text" : ""}`}
                      />
                      <img
                        src={cross}
                        alt="cancel"
                        name="removeCaption"
                        onClick={(e) => removePhotos(e, i)}
                      />
                    </Form.Group>
                  </>
                ))}
            </>
          ) : (
            <>
              <div className="show-img">
                <img src={showimg} alt="img" />
                <div className="replace-img">
                  <img src={cross} alt="cancel" name="removePhoto" />
                </div>
              </div>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control type="text" placeholder="Enter Photo Caption" />
                <img src={cross} alt="cancel" name="removeCaption" />
              </Form.Group>
            </>
          )}
        </div>
        <div className="footer-btn view-btn">
          <Button
            className="appointment-btn btn-save"
            onClick={handleMarkasComplete}
          >
            Next
          </Button>
        </div>
      </div>
    </Modal.Body>
  </Modal>
);
AppointmentCheckList.propTypes = {
  checkList: PropTypes.func.isRequired,
  handleCheckList: PropTypes.func.isRequired,
  handleMarkasComplete: PropTypes.func.isRequired,
  chemicalsAdded: PropTypes.bool.isRequired,
  handleFileInputChange: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  removePhotos: PropTypes.func.isRequired,
  AddCaptions: PropTypes.func.isRequired,
  checklistArray: PropTypes.arrayOf.isRequired,
  handleChecked: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
};
export default AppointmentCheckList;
