import React from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import PropTypes from "prop-types";

const ChemicalsDisplay = ({
  handleChecked,
  state,
  handleRequest,
  handleRevertViewUpdate,
  handleNewRevertDefaultUpdate,
  loading,
}) => (
  <div className="pool-setup pool-chemistry-box chemistry-target">
    <div className="target-heading">
      <h6>Calculator Chemicals to Display</h6>
    </div>
    <div className="chemical-display-section">
      <div
        className="flex chemical-list"
        // onClick={() => handleChecked('freeChlorine')}
      >
        <div className="team-member">
          <h6>Free Chlorine</h6>
        </div>
        <div className="open-date client-notes">
          <div className="basic">
            <Form.Check type="checkbox" checked={state?.freeChlorine} />
          </div>
        </div>
      </div>
      <div
        className="flex chemical-list"
        onClick={() => handleChecked("totalChlorine")}
        // onClick={() => handleChecked('totalChlorine')}
      >
        <div className="team-member">
          <h6>Total Chlorine</h6>
        </div>
        <div className="open-date client-notes">
          <div className="basic">
            <Form.Check type="checkbox" checked={state?.totalChlorine} />
          </div>
        </div>
      </div>
      <div
        className="flex chemical-list"
        // onClick={() => handleChecked('pH')}
      >
        <div className="team-member">
          <h6>pH</h6>
        </div>
        <div className="open-date client-notes">
          <div className="basic">
            <Form.Check type="checkbox" checked={state?.pH} />
          </div>
        </div>
      </div>
      <div
        className="flex chemical-list"
        // onClick={() => handleChecked('totalAlkalinity')}
      >
        <div className="team-member">
          <h6>Total Alkalinity (TA)</h6>
        </div>
        <div className="open-date client-notes">
          <div className="basic">
            <Form.Check type="checkbox" checked={state?.totalAlkalinity} />
          </div>
        </div>
      </div>
      <div
        className="flex chemical-list"
        onClick={() => handleChecked("calciumHardness")}
      >
        <div className="team-member">
          <h6>Total Hardness</h6>
        </div>
        <div className="open-date client-notes">
          <div className="basic">
            <Form.Check type="checkbox" checked={state?.calciumHardness} />
          </div>
        </div>
      </div>
      <div
        className="flex chemical-list"
        // onClick={() => handleChecked('')}
      >
        <div className="team-member">
          <h6>Stabiliser (CYA)</h6>
        </div>
        <div className="open-date client-notes">
          <div className="basic">
            <Form.Check type="checkbox" checked={state?.stabiliser} />
          </div>
        </div>
      </div>

      <div
        className="flex chemical-list"
        onClick={() => handleChecked("copper")}
      >
        <div className="team-member">
          <h6>Copper</h6>
        </div>
        <div className="open-date client-notes">
          <div className="basic">
            <Form.Check type="checkbox" checked={state?.copper} />
          </div>
        </div>
      </div>
      <div
        className="flex chemical-list"
        onClick={() => handleChecked("phosphates")}
      >
        <div className="team-member">
          <h6>Phosphates</h6>
        </div>
        <div className="open-date client-notes">
          <div className="basic">
            <Form.Check type="checkbox" checked={state?.phosphates} />
          </div>
        </div>
      </div>
      <div className="flex chemical-list" onClick={() => handleChecked("salt")}>
        <div className="team-member">
          <h6>Salt</h6>
        </div>
        <div className="open-date client-notes">
          <div className="basic">
            <Form.Check type="checkbox" checked={state?.salt} />
          </div>
        </div>
      </div>
      <div className="view-btn">
        {/* <Button type="submit" className="btn btn-edit"
        onClick={(e) => handleRevertViewUpdate(e, state)}>Revert To Default</Button> */}
        <div className="client-option service-edit-icon">
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              id="dropdown-basic"
              className="btn btn-edi"
            >
              View Other Settings
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => handleRevertViewUpdate(e, state)}>
                <div className="action">Restore Original Settings</div>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={(e) => handleNewRevertDefaultUpdate(e, state)}
              >
                <div className="action">Revert to Previous Saved Default</div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Button type="submit" className="btn btn-save" onClick={handleRequest}>
          Save as Default
        </Button>
      </div>
    </div>
  </div>
);

ChemicalsDisplay.propTypes = {
  handleChecked: PropTypes.func.isRequired,
  state: PropTypes.isRequired,
  handleRequest: PropTypes.func.isRequired,
  handleRevertViewUpdate: PropTypes.func.isRequired,
  handleNewRevertDefaultUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  //   change: PropTypes.func.isRequired,
};

export default ChemicalsDisplay;
