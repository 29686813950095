import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const HeaderContext = createContext();

const HeaderDataProvider = (props) => {
  const { children } = props;
  const [showHeader, setShowHeader] = useState(false);
  return (
    <HeaderContext.Provider value={{ showHeader, setShowHeader }}>
      {children}
    </HeaderContext.Provider>
  );
};
HeaderDataProvider.propTypes = {
  children: PropTypes.func.isRequired,
};
export default HeaderDataProvider;
