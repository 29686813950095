import React, { useState, useEffect } from "react";
import { Card, Accordion, Button, Modal, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { NotificationManager } from "react-notifications";
import whiteedit from "../../assets/home/white-edit.png";
import {
  errorMsgTime,
  successMsgTime,
  errorBorderTime,
} from "../../functions/intervalTime";
import { userInstance } from "../../config/axios";
import DeletePopUp from "../DeletePopUp";
import {
  showErrMsg,
  validateDataWithKey,
  addZero,
  getUnit,
} from "../../functions/index";
import ProductFormInventory from "./productFormInventory";
import DurationPicker from "../appointment/durationPicker";
import toastMessage from "../../functions/toastMessage";

const InventorySearchDetails = ({
  productList,
  setProductList,
  updateView,
  setSingleProduct,
  searchPopup,
  handleClose,
}) => {
  const [deletePopUp, setDeletePopUp] = useState(false);
  const paraText = "Click the “Yes” button to confirm deletion";
  const initialSubcategory = {
    name: "",
    serviceDescription: "",
    tax: "",
    duration: "5",
    cost: "",
    retailCost: "",
    productCode: "",
    soldPer: "Item",
    unit: "",
    symbol: "",
    trackedInventory: "Yes",
    barCode: "",
    supplier: "",
    supplyUnit: "",
    currentStockItems: "",
    s_uId: "",
    categoryId: "",
  };
  const [subcategory, setSubCategory] = useState(initialSubcategory);
  const [selectedLocationValue, setSelectedLocationValue] = useState([]);
  const [showSubCat, setShowCat] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [teamMemberData, setTeamMemberData] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [editData, setEditData] = useState({
    index: null,
    categoryId: "",
    isEdit: false,
  });
  const [category, setCategory] = useState("");
  const [sellsymbol, setSellSymbol] = useState(false);
  const [buysymbol, setBuySymbol] = useState(false);
  const [hideLocStaff, sethideLocStaff] = useState(false);
  const [durationData, setDuration] = useState("");
  const [durationPicker, setDurationPicker] = useState(false);
  const [errorValue, setErrorValue] = useState("");

  const getProduct = async () => {
    try {
      const res = await userInstance().get(
        `/inventory/getSepecifInventory/?inventoryStockId=${productList._id}`,
      );
      const { data } = res.data;
      setProductList(data);
    } catch (error) {
      console.log("error--", error);
    }
  };
  const handlePopUpChange = (val) => {
    if (val === "Yes") {
      handleDeleteSubcategory();
    }
    setDeletePopUp(false);
  };
  // For editing the product
  const handleEdit = () => {
    console.log("hello");
  };

  const getLocation = async () => {
    const response = await userInstance().get("/appointment/getLocationList");
    const { locationList, code } = response.data;
    locationList.sort((a, b) => a.locationName.localeCompare(b.locationName));
    if (code === 200) {
      setLocationData([...locationList]);
    }
  };
  const getTeam = async () => {
    const response = await userInstance().get("/appointment/getTeamMemberList");
    const { teamList, code } = response.data;
    teamList.sort((a, b) => a.firstname.localeCompare(b.firstname));
    if (code === 200) {
      setTeamMemberData(teamList);
    }
  };
  useEffect(() => {
    getLocation();
    getTeam();
  }, []);

  const handleEditSubcategory = async () => {
    try {
      const currentSubData = productList;
      setSubCategory({ ...subcategory, ...currentSubData });
      const { staffStockData } = currentSubData;
      const prevLocations = locationData.filter(({ _id: id1 }) =>
        staffStockData.some(({ location: id2 }) => id2 === id1),
      );
      setSelectedLocationValue(prevLocations);
      setSelectedStaff(staffStockData);
      setEditData({
        ...editData,
        index: 0,
        categoryId: productList.inventoryId,
        entryType: "product",
        isEdit: true,
      });
      setShowCat(true);
      setCategory(productList.inventoryId.category);
    } catch (error) {
      showErrMsg(error);
    }
  };

  const handleChange = (e) => {
    let isValid = true;
    if (
      e.target.name === "tax" ||
      e.target.name === "cost" ||
      e.target.name === "currentStockItems"
    ) {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(e.target.value.replace(/,/g, ""))) {
        isValid = false;
      }
    }
    if (isValid) {
      setSubCategory({ ...subcategory, [e.target.name]: e.target.value });
    }
    if (e.target.name === "cost" && e.target.value) setBuySymbol(true);
    else if (e.target.name === "cost" && e.target.value === null) {
      setBuySymbol(false);
    }
    if (e.target.name === "retailCost" && e.target.value) setSellSymbol(true);
    else if (e.target.name === "retailCost" && e.target.value === null) {
      setSellSymbol(false);
    }
    if (e.target.name === "trackedInventory" && e.target.value === "No") {
      sethideLocStaff(false);
    } else if (
      e.target.name === "trackedInventory" &&
      e.target.value === "Yes"
    ) {
      sethideLocStaff(true);
    }
  };
  const handleUpdateSubCategory = async (e) => {
    try {
      e.preventDefault();
      let type = "product";
      // console.log('this is editData.categoryId', editData.categoryId);
      const currentSubCat = [productList];
      if (currentSubCat.length) {
        currentSubCat[0] = subcategory;
        const subcategoryCopy = {
          ...currentSubCat[0],
        };
        const { serviceDescription, duration, cost, retailCost, tax, s_uId } =
          subcategoryCopy;
        const validationObj = {
          name: subcategoryCopy.name,
          description: serviceDescription,
          duration,
          tax,
        };
        if (!category.toLowerCase().includes("misc")) {
          validationObj[type === "product" ? "buy cost" : "sell price"] = cost;
        }
        if (type === "product") {
          if (!category.toLowerCase().includes("misc")) {
            validationObj["sell price"] = retailCost;
          }
        }
        let { isValid, emptyField } = validateDataWithKey(validationObj);
        if (isValid) {
          const { name } = currentSubCat[0];
          const updatedSubCat = {
            ...currentSubCat[0],
          };

          if (!category.toLowerCase().includes("misc")) {
            updatedSubCat.name = `${
              name.includes("$") ? name.split("$")[0].trim() : name
            } $${parseFloat(
              addZero(
                currentSubCat[0][
                  type === "product" ? "retailCost" : "cost"
                ].replace(/,/g, ""),
              ),
            ).toFixed(2)}`;
          }
          updatedSubCat.cost = parseFloat(
            updatedSubCat.cost.replace(/,/g, ""),
          ).toFixed(2);
          if (type === "product") {
            updatedSubCat.retailCost = parseFloat(
              updatedSubCat.retailCost.replace(/,/g, ""),
            ).toFixed(2);
          }
          currentSubCat[0] = updatedSubCat;
          // console.log('updatedSubCat', updatedSubCat);

          if (
            type === "product" &&
            parseFloat(updatedSubCat.cost) >
              parseFloat(updatedSubCat.retailCost)
          ) {
            isValid = false;
            toastMessage("error", "Sell price must be greater than buy cost");
          }
          updatedSubCat.tax = parseFloat(
            updatedSubCat.tax.replace(/,/g, ""),
          ).toFixed(2);
          if (isValid) {
            if (type === "product" && updatedSubCat.tax.length === 0) {
              isValid = false;
              toastMessage("error", "Please Enter tax details ");
            }
          }
          if (isValid) {
            const totalStock = selectedStaff.reduce(
              (acc, obj) => parseFloat(acc) + parseFloat(obj.stockValue),
              0,
            );
            const payload = {
              _id: productList.inventoryId._id,
              subCategory: [updatedSubCat],
              subcategoryId: updatedSubCat.s_uId,
              reqType: "edit",
              totalStock,
              stockData: selectedStaff,
            };
            const response = await userInstance().put(
              "/inventory/updateSubInventory",
              payload,
            );
            const { msg } = response.data;
            setShowCat(!showSubCat);
            if (msg) {
              if (editData.entryType === "product") {
                getProduct();
                toastMessage("success", "Product Updated Successfully");
              } else {
                toastMessage("success", "Service Updated Successfully");
              }
            }
          }
        } else {
          toastMessage("error", `Please enter ${emptyField} for product`);
          setErrorValue(emptyField);
        }
      }
      setTimeout(() => {
        setErrorValue("");
      }, errorBorderTime);
    } catch (error) {
      console.log(error);
      showErrMsg(error);
    }
  };
  // function for open deletePopup
  const handleDelete = () => {
    setDeletePopUp(true);
  };

  // For the deleteing the product
  const handleDeleteSubcategory = async () => {
    const response = await userInstance().put("/inventory/updateSubInventory", {
      _id: productList.inventoryId._id,
      subCategory: [productList],
      subcategoryId: productList.s_uId,
      reqType: "delete",
    });
    setSingleProduct(productList._id);
    updateView("InventorySearchResult");
    const { code } = response.data;
    if (code === 200) {
      toastMessage("success", "Product deleted successfully");
    } else {
      toastMessage("error", "Product cannot be deleted");
    }
  };
  const renderOptions = () => {
    let val = 5;
    const arr = [];
    while (val <= 960) {
      // console.log(val);
      const hours = Math.floor(val / 60);
      const minutes = val % 60;
      const msg =
        hours && minutes
          ? `${hours} hr ${minutes} min`
          : hours && !minutes
            ? `${hours} hr`
            : `${minutes} min`;
      arr.push({ value: val, data: msg });
      if (val >= 300) {
        val += 30;
      } else {
        val += 5;
      }
    }
    return arr;
  };

  const withValueLimitSellPrice = (inputObj) => {
    const { value } = inputObj;
    const toCheck = 9999999999.999;
    const splitD = value.toString().split(".");
    const isValid = splitD.length > 1 ? splitD[1].length <= 3 : true;
    if (value <= toCheck && isValid) return inputObj;
    toastMessage("error", "Enter an amount equal $9,999,999,999.999 or less");
    return "";
  };

  const handleDurationPicker = () => {
    setDuration(subcategory.duration);
    setDurationPicker(!durationPicker);
  };

  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msg = "";
    msg =
      hours && minutes
        ? `${hours}hr ${minutes} min`
        : hours && !minutes
          ? `${hours}hr`
          : `${minutes} min`;
    return msg;
  };

  const handleStock = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const stt = selectedLocation.slice();
    const fIndex = stt.indexOf(value);
    if (fIndex < 0) {
      stt.push(value);
    }
    setSelectedLocation(stt);
  };

  useEffect(() => {
    if (errorValue) {
      let element = document.querySelector(".error-text");
      if (element) {
        console.log("element");
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [errorValue]);

  const totalCost = productList?.staffStockData?.reduce(
    (preVal, CurVal) => preVal + CurVal?.stockValue * 1,
    0,
  );
  console.log("subcategory---", subcategory.calciName);
  return (
    <>
      <Modal
        size="lg"
        show={searchPopup}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        top
        centered
        className="staff-modal addClient-modal inventory-search-popup"
      >
        <div className="staff-close">
          <i className="fa fa-times" onClick={handleClose} aria-hidden="true" />
        </div>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Inventory Search Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="reports-page staff-container inventory-page">
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <div className="pool-setup pool-set-up inventory-search-details ">
                    <div className="search-result-box">
                      <div className="inner-accordion">
                        <h6>{productList?.renderName}</h6>
                        <ul className="grid-list">
                          <li className="grid-list-item">
                            <div className="grid-box">
                              <h3>Tracked Inventory Product</h3>
                              <div className="grid-content">
                                {productList?.trackedInventory}
                              </div>
                            </div>
                          </li>
                          {/* <li className="grid-list-item">
                <div className="grid-box">
                  <h3>
                    Supply Unit
                    {' '}
                  </h3>
                  <div className="grid-content">{productList?.supplyUnit}</div>
                </div>
              </li> */}
                          <li className="grid-list-item">
                            <div className="grid-box">
                              <h3>Duration</h3>
                              <div className="grid-content">
                                {convertToReadableDuration(
                                  productList?.duration,
                                ) || "0 min"}
                              </div>
                            </div>
                          </li>
                          {/* <li className="grid-list-item">
                <div className="grid-box">
                  <h3>Tax</h3>
                  <div className="grid-content">
                    {productList?.tax || 0}
                    %
                  </div>
                </div>
              </li> */}
                          <li className="grid-list-item">
                            <div className="grid-box">
                              <h3>Average Buy Cost(ex Tax)</h3>
                              <div className="grid-content">
                                ${productList?.cost || 0}
                              </div>
                            </div>
                          </li>
                          {/* <li className="grid-list-item">
                <div className="grid-box">
                  <h3>
                    {' '}
                    Average
                    {' '}
                    Sell Price(ex Tax)
                  </h3>
                  <div className="grid-content">
                    $
                    {productList?.retailCost || 0}
                  </div>
                </div>
              </li>
              <li className="grid-list-item">
                <div className="grid-box">
                  <h3>Current stock level</h3>
                  <div className="grid-content">
                    {totalCost}
                  </div>
                </div>
              </li> */}
                          <li className="grid-list-item">
                            <div className="predicative-stock">
                              <h4>Analytics</h4>
                              <h6>Historical Product Usage</h6>
                              <div className="stock-listing">
                                <p>Quantity Used Last 6 Months</p>
                                <p>
                                  {productList?.sixMonthsUsage || 0}{" "}
                                  {getUnit(
                                    productList?.unit,
                                    productList?.sixMonthsUsage,
                                  )}
                                </p>
                              </div>
                              <div className="stock-listing">
                                <p>Quantity Used Last 3 Months</p>
                                <p>
                                  {productList?.threeMonthsUsage || 0}{" "}
                                  {getUnit(
                                    productList?.unit,
                                    productList?.threeMonthsUsage,
                                  )}
                                </p>
                              </div>
                              <div className="stock-listing">
                                <p>Quantity Used Last 1 Month</p>
                                <p>
                                  {productList?.oneMonthUsage || 0}{" "}
                                  {getUnit(
                                    productList?.unit,
                                    productList?.oneMonthUsage,
                                  )}
                                </p>
                              </div>
                              {/* <div className="ordring-section">
                                <h6>Re-Ordering Forecast</h6>
                                <div className="stock-listing">
                                  <p>Stock Required Next 6 Months</p>
                                  <p>
                                    {' '}
                                    {productList?.foreCastSixMonths || 0}
                                    {' '}
                                    {getUnit(productList?.unit, productList?.foreCastSixMonths)}
                                  </p>
                                </div>
                                <div className="stock-listing">
                                  <p>Stock Required Next 3 Months</p>
                                  <p>
                                    {' '}
                                    {productList?.foreCastThreeMonths || 0}
                                    {' '}
                                    {getUnit(productList?.unit, productList?.foreCastThreeMonths)}
                                  </p>
                                </div>
                                <div className="stock-listing">
                                  <p>Stock Required Next 1 Month</p>
                                  <p>
                                    {' '}
                                    {productList?.foreCastPerMonth || 0}
                                    {' '}
                                    {getUnit(productList?.unit, productList?.foreCastPerMonth)}
                                  </p>
                                </div>
                              </div> */}
                            </div>
                          </li>
                          {!productList?.calciName ? (
                            <>
                              <li className="grid-list-item">
                                <div className="grid-box">
                                  <h3>Actions</h3>
                                  <div
                                    className="action-btn"
                                    onClick={handleEditSubcategory}
                                  >
                                    <Button className="edit-btn">
                                      <img src={whiteedit} alt="edit" />
                                    </Button>
                                  </div>

                                  <div
                                    className="action-btn"
                                    onClick={handleDelete}
                                  >
                                    <Button className="delete-btn">
                                      <i className="far fa-trash-alt" />
                                    </Button>
                                  </div>
                                </div>
                              </li>
                            </>
                          ) : null}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      {deletePopUp && (
        <DeletePopUp
          handlePopUpChange={handlePopUpChange}
          paraText={paraText}
          PopUpText="Delete Product?"
        />
      )}
      {showSubCat && (
        <ProductFormInventory
          showSubCat={showSubCat}
          setShowCat={setShowCat}
          subcategory={subcategory}
          handleChange={handleChange}
          handleUpdateSubCategory={handleUpdateSubCategory}
          editData={editData}
          renderOptions={renderOptions}
          type="product"
          withValueLimitSellPrice={withValueLimitSellPrice}
          handleDurationPicker={handleDurationPicker}
          convertToReadableDuration={convertToReadableDuration}
          buysymbol={buysymbol}
          sellsymbol={sellsymbol}
          category={category}
          handleEditSubcategory={handleEditSubcategory}
          catId={productList.inventoryId._id}
          locationData={locationData}
          teamMemberData={teamMemberData}
          selectedLocation={selectedLocation}
          handleStock={handleStock}
          hideLocStaff={hideLocStaff}
          selectedStaff={selectedStaff}
          setSelectedStaff={setSelectedStaff}
          setTeamMemberData={setTeamMemberData}
          setLocationData={setLocationData}
          selectedLocationValue={selectedLocationValue}
          setSelectedLocationValue={setSelectedLocationValue}
          error={errorValue}
        />
      )}

      {durationPicker && (
        <DurationPicker
          durationPicker={durationPicker}
          setDurationPicker={setDurationPicker}
          durationData={durationData}
          handleDurationPicker={handleDurationPicker}
          setDuration={setDuration}
          setBookingData={null}
          convertToReadableDuration={convertToReadableDuration}
          bookingData={null}
          subcategory={subcategory}
          setSubCategory={setSubCategory}
        />
      )}
    </>
  );
};

InventorySearchDetails.propTypes = {
  productList: PropTypes.objectOf.isRequired,
  setProductList: PropTypes.func.isRequired,
  updateView: PropTypes.func.isRequired,
  setSingleProduct: PropTypes.func.isRequired,
  searchPopup: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default InventorySearchDetails;
