/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable no-fallthrough */
/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Table,
  Button,
  Form,
  Dropdown,
  Accordion,
  Card,
} from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { confirmAlert } from "react-confirm-alert";
import Adminwrapper from "../admin/adminwrapper/wrapper";
// import checkimg from '../../assets/home/check.png';
import {
  queryString,
  showErrMsg,
  validateDataWithKey,
} from "../../functions/index";
import { userInstance } from "../../config/axios";
import {
  targetLevelState,
  chemicalCostState,
  drainageCostState,
  balanceWaterState,
  chlorinatorModelState,
  calciumAdjustmentState,
  targetLevelDefaultState,
  chemicalCostDefaultState,
  drainageCostDefaultState,
  chemicalBalanceWaterDefaultState,
  drainAcidDefaultState,
  drainAcidState,
  greenPoolCalculationDefaultState,
  greenPoolState,
  sandFilterMediaState,
  sandFilterMediaDefaultState,
  defaultSecondVisitState,
  secondVisitCostState,
  blackSpotAlgaeState,
  blackSpotAlgaeDefaultState,
  defaultChemicalDisplayState,
  chemicalDisplayState,
  taxApplicableState,
  defaultTaxApplicableState,
  poolFinishArray,
  chlorinationArray,
  poolTypeArray,
  convertPoolChlorineArray,
  poolDepthArray,
  userPoolState,
  defaultUserPoolState,
} from "./pool_state";
import greyplus from "../../assets/home/plus.png";
import showmore from "../../assets/home/showmoreblue.png";
import Chlorinator from "./chlorinator-popup";
import DrainageCost from "./dranage-cost";
import MiniralPool from "./miniralpool";
import MiniralPopup from "./miniral-popup";
import ChemistryTarget from "./chemistry-target";
import ChemicalCost from "./chemicalCost";
import ChlorinatorPage from "./chlorinator";
import "./poolsetup.css";
import BalanceWater from "./balance-water";
import ca from "../../assets/home/icon/ca.png";
import d from "../../assets/home/icon/d.png";
import chlorinatorimg from "../../assets/home/icon/chlorinator.png";
import percentage from "../../assets/home/icon/percentage.png";
import target from "../../assets/home/icon/target.png";
import GreenPoolCalculation from "./greenPool-calculation";
import AcidWashCalculation from "./acidwashCalculation";
import FilterReplacement from "./filterReplacement";
import SecondVisitCost from "./secondVisitCost";
import DurationPicker from "../appointment/durationPicker";
import BlackSpotAlgae from "./blackSpotAlgae";

import { AuthDataContext } from "../../contexts/AuthDataContext";
import {
  errorBorderTime,
  errorMsgTime,
  successMsgTime,
} from "../../functions/intervalTime";
import ChemicalsDisplay from "./chemicalsDisplay";
import "./webview.css";
import TaxApplicable from "./taxApplicable";
import Loader from "../loader/loader";
import toastMessage from "../../functions/toastMessage";
import UserPool from "./userPool";
import UserPoolPopup from "./addUserPool";
import DeletePopUp from "../DeletePopUp";
import { validateData } from "../calculator/calculatorUtils";

function PoolSetUp() {
  const { userData, setUserData } = useContext(AuthDataContext);

  const removeInCaseOfImperial = [
    "Magna Minerals (10 kg)",
    "Aquatherepe Minerals (10 kg)",
    "Aquatherepe Transform (10 kg)",
    "Fine Glass Media (15 kg)",
    "Coarse Glass Media (15 kg)",
  ];

  let history = useHistory();
  const [idealData, setIdealData] = useState({
    chlorine: "",
    phConcreteTilted: "",
    phfibre: "",
    phliner: "",
    alkalinity: "",
    stabiliser: "",
    calciumHardness: "",
    salt: "",
    chlorineLvl: "",
    phLvl: "",
    alkalinityLvl: "",
    stabiliserLvl: "",
    calciumHardnessLvl: "",
    saltLvl: "",
    magnaPoolAdjust: "",
    aquaAdjust: "",
    baseGranChlorine: "",
    baseSodiumThi: "",
    basePhSodaAsh: "",
    basePhAcid: "",
    baseAlkBuffer: "",
    baseCalciumRaiser: "",
    baseStabiliser: "",
    basePhosphates: "",
    baseSalt: "",
    liquidChlorine: "",
    baseConvert: "",
    baseMagna: "",
    baseAqua: "",
    baseSalt1: "",
    sellLiquid: "",
    sellGranular: "",
    sellSodium: "",
    sellPhAcid: "",
    sellSodaAsh: "",
    sellBuffer: "",
    sellCalciumHardness: "",
    sellStabiliser: "",
    sellPhosphates: "",
    sellConvert: "",
    sellMagna: "",
    sellAqua: "",
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [renderSort, setRenderSort] = useState(false);
  const [chlorinator, setChlorinator] = useState();
  const [miniralPopup, setMiniralPopup] = useState();
  const [view, setView] = useState("");
  const [state, setState] = useState([]);
  const [isUpdate, setUpdate] = useState(null);
  const [chlorinatorData, setChlData] = useState(chlorinatorModelState);
  const [calciumAdjustment, setCalciumAdjustment] = useState(
    calciumAdjustmentState,
  );
  const [selectedIndexModel, setIndex] = useState(null);
  const [calIndex, setCalIndex] = useState(null);
  const [orpProbe, setOrpProbe] = useState();
  const [orpMineralProbe, setOrpMineralProbe] = useState();
  const [chlorineValue, setChlorineValue] = useState("Granular Chlorine");
  const [greenPool, setGreenPool] = useState();
  const [acidWash, setAcidwash] = useState();

  const [defaultViewState, setDefaultViewState] = useState([]);
  const [change, setChange] = useState("newUpdate");
  const [newView, setNewView] = useState("");

  const [datass, setdata] = useState([]);
  const [count, setCount] = useState(1);
  const [managementPrevious, setManagementPrevious] = useState(false);
  const [newArrayStateGreen, setNewArrayStateGreen] = useState([]);
  const [newArrayStateBlack, setNewArrayStateBlack] = useState([]);

  const [durationPicker, setDurationPicker] = useState(false);
  const [durationData, setDuration] = useState("");
  const [granSelected, setGranSelected] = useState();
  // const [blackSelected, setBlackSelected] = useState();
  const [drainState, setDrainState] = useState();
  const [poolChlorineSize, setPoolChlorineSize] = useState();
  const [poolBlackSize, setPoolBlackSize] = useState();
  const [drainPoolSize, setDrainPoolSize] = useState();
  const [filterData, setFilterData] = useState([]);
  const [chemCost, setChemCost] = useState(null);
  const [activeLink, setActiveLink] = useState("chemicalTargetLevels");

  const isUsImperial = userData?.calendarSettings?.unit !== "metric";
  const [errorValue, setErrorValue] = useState("");
  const [taxStatus, setTaxStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saltChlorinator, setSaltChorinator] = useState([]);
  const [mineralChlorinator, setMineralChlorinator] = useState([]);

  const { view: poolView } = queryString();
  const [userPoolData, setUserPoolData] = useState(userPoolState);
  const [deletePopup, setDeletePopUp] = useState(false);
  const initialValidationData = {
    poolWidth: false,
    poolHeight: false,
    shalowEnd: false,
    deepEnd: false,
    diameter: false,
    originalSaltLvl: false,
    bagsSaltAdded: false,
    currentSaltLevel: false,
  };

  const [validationData, setValData] = useState(initialValidationData);

  const handleOrp = (value) => {
    // console.log('value', value, 'chlorinatorData', chlorinatorData.orp);
    const obj = { customCost: value };
    // console.log('obj', obj);
    let val = true;
    if (value === "Yes") {
      setOrpProbe(obj);
      setChlData({
        ...chlorinatorData,
        orp: value,
        recmndStabiliserLvl:
          state && selectedIndexModel
            ? state[selectedIndexModel].recmndStabiliserLvl
            : "",
      });
    } else if (value === "No") {
      setOrpProbe(false);
      val = false;
      setChlData({ ...chlorinatorData, orp: value, recmndStabiliserLvl: "" });
    }
  };

  const handleMineralOrp = (value) => {
    if (value === "Yes") {
      setCalciumAdjustment({
        ...calciumAdjustment,
        orp: value,
        stabiliserLevel:
          state && calIndex ? state[calIndex].stabiliserLevel : "",
      });
    } else if (value === "No") {
      setCalciumAdjustment({
        ...calciumAdjustment,
        orp: value,
        stabiliserLevel: "",
      });
    }
  };

  const handleChlorinator = () => {
    if (chlorinator) {
      setIndex(null);
      setChlData(chlorinatorModelState);
    }
    setChlorinator(!chlorinator);
  };

  async function chck() {
    if (change === "newUpdate") {
      const response = await userInstance().get(
        `/poolmineral/getPoolMineral/${view}`,
      );
      const { pooldata } = response.data;
      if (pooldata[view]) {
        if (view === "drainageSetup") {
          setdata({ ...pooldata[view] });
        } else if (view === "taxApplicable") {
          setdata({ ...pooldata[view] });
        } else if (view === "secondVisitCost") {
          setdata({ ...pooldata[view] });
        } else if (view === "chemicalsDisplay") {
          setdata({ ...pooldata[view] });
        } else {
          setdata([...pooldata[view]]);
        }
      }
    }
  }

  // section For SecondVisit Cost

  const handleDurationPicker = () => {
    setDuration(state.duration);
    setDurationPicker(!durationPicker);
  };
  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msg = "";
    msg =
      hours && minutes
        ? `${hours}hr ${minutes} min`
        : hours && !minutes
          ? `${hours}hr`
          : `${minutes} min`;
    return msg;
  };

  const handleMiniralPopup = () => {
    if (miniralPopup) {
      setCalIndex(null);
      setCalciumAdjustment(calciumAdjustmentState);
    }
    setMiniralPopup(!miniralPopup);
  };

  // const intialUserPoolObject = {
  //   poolDepth: 'Average Depth - Rectangular Pool', poolWidth: '', poolHeight: '', diameter: '', shallowEnd: '', deepEnd: '', poolFinish: 'Concrete Pool - Outdoor', chlorinationType: 'Salt Pool', chlorineModel: [], selectedModel: '', poolId: '', poolCapacity: '', poolMsg: '',
  // };

  const [actualState, setActualState] = useState([]);
  const getIdeals = async () => {
    try {
      if (view) {
        const response = await userInstance().get(
          `/poolmineral/getPoolMineral/${view}`,
        );
        const { pooldata } = response.data;
        console.log("triggered data", pooldata[view], pooldata);

        if (pooldata[view]) {
          if (
            [
              "drainageSetup",
              "taxApplicable",
              "secondVisitCost",
              "chemicalsDisplay",
            ].includes(view)
          ) {
            setState({ ...pooldata[view] });
            setActualState(JSON.parse(JSON.stringify(pooldata[view])));
            if (pooldata[`revert${view}`]) {
              setDefaultViewState({ ...pooldata[`revert${view}`] });
            }
          } else {
            setState([...pooldata[view]]);
            setActualState(JSON.parse(JSON.stringify(pooldata[view])));
            if (pooldata[`revert${view}`]) {
              setDefaultViewState([...pooldata[`revert${view}`]]);
            }
          }
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("==>>>>", error);
      //   console.log(error);
    }
  };

  async function getData() {
    try {
      const newresponse = await userInstance().get(
        "/poolmineral/getAllPoolMinerals?type=pool",
      );
      const respiData = newresponse?.data?.mineralList;
      const saltChorinatorModel = respiData?.chlorinatorModels;
      const mineralChlorinatorModel = respiData?.calciumAdjustments;

      console.log({ respiData });
      const { chemicalsTax, labourTax } = respiData?.taxApplicable || {};
      if (chemicalsTax && labourTax) {
        setTaxStatus(true);
      }
      const costObj = {};
      if (respiData) {
        respiData.chemicalCosts.forEach((el) => {
          if (el.name === "granularChlorine") {
            costObj.granBuy = el.buycost;
            costObj.granSell = el.sellprice;
          } else if (el.name === "liquidChlorine") {
            costObj.liqBuy = el.buycost;
            costObj.liqSell = el.sellprice;
          } else if (el.name === "blackSpotAlgae") {
            costObj.blackBuy = el.buycost;
            costObj.blackSell = el.sellprice;
          }
        });
        setChemCost(costObj);
        // console.log('respiData.chemicalCosts', respiData.chemicalCosts, 'costObj', costObj);
        setGranSelected(respiData.chemicalCosts);
        setDrainState(respiData.drainageSetup);
        setPoolBlackSize(respiData.blackSpotAlgae[15]?.dosageAndTime);
        setPoolChlorineSize(respiData.greenpoolcalculation[11]?.dosageAndTime);
        setDrainPoolSize(respiData.acidWashCalculation[6]?.dosageAndTime);
        setNewArrayStateGreen(respiData.greenpoolcalculation);
        setNewArrayStateBlack(respiData.blackSpotAlgae);
        setFilterData(respiData.sandFilterMedia);
        setSaltChorinator(saltChorinatorModel);
        setMineralChlorinator(mineralChlorinatorModel);
        // setLoading(false);
        if (poolView) {
          // console.log('111', poolView);
          setView(poolView);
          setNewView(`revert${poolView}`);
          return;
        }
        setView("chemicalTargetLevels");
        setNewView("revertchemicalTargetLevels");
        // console.log('222');
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const handleChange = (e, index) => {
    const copyState = state;
    const oldData = copyState.filter((el) => el);
    const selectedChemical = copyState[index];
    const { value, name } = e.target;
    // if (value.match(/^[+]?([1-9][0-9]*(?:[/.][0-9]*)?|0*\.0*[1-9][0-9]*)(?:[eE][+-][0-9]+)?$/)) { return; }
    if (value && !value.match(/^(\d{0,7})(\.[0-9]{0,2})?$/)) {
      return;
    }
    if (name === "okPositive" || name === "drainPoint") {
      let curWidth = Number(e.target.style.width.replace("px", ""));

      if (value.toString().length > selectedChemical[name].toString().length) {
        curWidth += 10;
      } else {
        curWidth -= 10;
      }
      e.target.style.width = `${curWidth}px`;
    }
    selectedChemical[name] = value;
    setState([...copyState]);
  };

  const handleFilterChange = (e, index) => {
    const copyState = state;
    const { value, name } = e.target;
    const selectedFilter = copyState[index];
    if (value && !value.match(/^(\d{0,7})(\.[0-9]{0,2})?$/)) {
      return;
    }
    if (name === "sandQuantity") {
      // if (e.target.value.match(/^(\d{0,7})(\.[0-9]{0,2})?$/)) {
      selectedFilter[name] = value;
      selectedFilter.bagsSand =
        parseFloat(
          selectedFilter.sandQuantity ? selectedFilter.sandQuantity : 0,
        ) / 20;
      // }
    } else {
      selectedFilter[name].quantity = value;
      selectedFilter.bagsGlass =
        parseFloat(
          selectedFilter.glassCoarseQuantity.quantity
            ? selectedFilter.glassCoarseQuantity.quantity
            : 0,
        ) +
        parseFloat(
          selectedFilter.glassFineQuantity.quantity
            ? selectedFilter.glassFineQuantity.quantity
            : 0,
        );
    }
    setState([...copyState]);
  };

  const handleChangeDrainageCost = (e) => {
    const { value, name } = e.target;
    if (value && !value.match(/^(\d{0,7})(\.[0-9]{0,2})?$/)) {
      return;
    }

    const obj = { [name]: value };
    let { poolsize, litresPerHour, drainagePercentage, hourlyRate, setupCost } =
      state;
    if (name === "poolsize") {
      poolsize = value;
    } else if (name === "litresPerHour") {
      litresPerHour = value;
    } else if (name === "drainagePercentage") {
      drainagePercentage = value;
    } else if (name === "hourlyRate") {
      hourlyRate = value;
    } else if (name === "setupCost") {
      setupCost = value;
    }
    let hoursToDrain = 0;
    let cost = 0;
    if (
      Number(poolsize) &&
      Number(litresPerHour) &&
      Number(drainagePercentage)
    ) {
      hoursToDrain =
        (Number(poolsize) / Number(litresPerHour)) *
        (Number(drainagePercentage) / 100);
    }
    cost =
      parseInt(Math.ceil((Number(hourlyRate) * Number(hoursToDrain)) / 5), 10) *
        5 +
      Number(setupCost);
    obj.hoursToDrain = hoursToDrain.toFixed(2);
    obj.cost = cost.toFixed(2);
    setState((prev) => ({ ...prev, ...obj }));
  };

  const moveArr = (oldIndex, newIndex) => {
    const arrCopy = [...state];
    let index = null;
    if (newIndex >= arrCopy.length) {
      let k = newIndex - arrCopy.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        arrCopy.push(undefined);
      }
    }
    arrCopy.splice(newIndex, 0, arrCopy.splice(oldIndex, 1)[0]);
    setState([...arrCopy]);
  };

  const handleSorting = () => {
    setUpdate(true);
    setRenderSort(false);
  };

  const [checkBlankFields, setCheckBlankFields] = useState([]);

  const chemicalTargetBlank = () => {
    let arr = [];
    state.forEach((el) => {
      if (
        !el?.target?.trim() ||
        !el?.drainPoint?.trim() ||
        !el?.okPositive?.trim() ||
        !el?.okNegative?.trim()
      ) {
        arr = [...arr, el.name];
      }
    });
    return arr;
  };

  const chemicalCostsBlank = () => {
    let arr = [];
    state.forEach((el) => {
      if (!el?.buycost) {
        arr = [...arr, el.name];
      }
    });
    return arr;
  };

  const acidDrainBlank = () => {
    let arr = [];
    state.forEach((el) => {
      if (el?.calciName !== "acidPoolSize") {
        if (
          ["chlorineWashQuantity", "acidWashQuantity"].includes(el?.calciName)
        ) {
          if (!el.dosageAndTime?.trim()) arr = [...arr, el.calciName];
        } else if (!el?.timeReq?.trim()) arr = [...arr, el.calciName];
      }
    });
    return arr;
  };

  const sandFilterBlank = () => {
    let arr = [];
    state.forEach((el) => {
      if (el?.unit === "hours") {
        if (!el?.sandQuantity?.trim()) arr = [...arr, el.mediaFilterSize];
      } else if (
        !el?.sandQuantity?.trim() ||
        !el?.glassFineQuantity?.quantity?.trim() ||
        !el?.glassCoarseQuantity?.quantity?.trim()
      )
        arr = [...arr, el.mediaFilterSize];
    });
    return arr;
  };

  const blackBlank = () => {
    let arr = [];
    state.forEach((el) => {
      if (!el.dosageAndTime?.trim()) {
        let name = !el.greenName ? el.chemicalName : el.greenName;
        arr = [...arr, name];
      }
    });
    return arr;
  };

  const drainageBlank = () => {
    let arr = [];
    Object.keys(state).forEach((el) => {
      if (el !== "cost" && !state[el]?.trim()) {
        arr = [...arr, el];
      }
    });
    return arr;
  };

  const checkInputFieldsBlanks = () => {
    let valid = true;
    let arr = [];
    switch (view) {
      case "chemicalTargetLevels":
        arr = chemicalTargetBlank();
        break;
      case "chemicalCosts":
        arr = chemicalCostsBlank();
        break;
      case "acidWashCalculation":
        arr = acidDrainBlank();
        break;
      case "sandFilterMedia":
        arr = sandFilterBlank();
        break;
      case "blackSpotAlgae":
      case "greenpoolcalculation":
        arr = blackBlank();
        break;
      case "drainageSetup":
        arr = drainageBlank();
        break;
      default:
        break;
    }

    if (arr?.length > 0) {
      toastMessage("error", "Fields cannot be blank");
      setCheckBlankFields(arr);
      setErrorValue(Math.floor(Math.random() * 100));
      valid = false;
    }
    return valid;
  };

  const handleRequest = async () => {
    setCount(count + 1);
    try {
      let isValid = true;
      isValid = checkInputFieldsBlanks();
      if (!isValid) {
        return;
      }

      if (newView) {
        // console.log('jaker', { datass });
        const response = await userInstance().put(
          "/poolmineral/updatePoolMineral",
          { key: newView, data: datass },
        );
        const { msg } = response.data;
        setDefaultViewState(state);
      }

      const response = await userInstance().put(
        "/poolmineral/updatePoolMineral",
        { key: view, data: state },
      );
      // console.log('maker', { state, actualState });
      const { msg } = response.data;
      setUpdate(false);
      getIdeals();
      getData();
      if (!managementPrevious) {
        chck();
      }
      setChange("newUpdate");
      setManagementPrevious(false);
      toastMessage("success", msg);
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const handleAdjust = (e) => {
    e.preventDefault();
    // console.log(state);
  };

  const updateView = (key) => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    setActiveLink(key);
    setView(key);
    // if (key === 'calciumAdjustments' || key === 'chlorinatorModels') {
    //   setNewView('');
    // } else {
    //   setNewView(`revert${key}`);
    // }
    history.push(`poolsetup?view=${key}`);
    // setState(null);
    setUpdate(null);
  };

  const handleChlModelData = (e) => {
    let value = e.target.value === "" ? 0.0 : parseFloat(e.target.value);
    if (e.target.name === "recmndStabiliserLvl") {
      if (value <= 150 && value >= 0) {
        setChlData({
          ...chlorinatorData,
          [e.target.name]: parseFloat(value).toFixed(),
        });
      }
    } else if (e.target.name === "saltLevel") {
      if (value <= 10000 && value >= 0) {
        setChlData({
          ...chlorinatorData,
          [e.target.name]: parseFloat(value).toFixed(),
        });
      }
    } else {
      setChlData({ ...chlorinatorData, [e.target.name]: e.target.value });
    }
  };

  const limitNumber = (inputObj) => {
    let { value } = inputObj;

    if (value && !value.match(/^(\d{0,7})(\.[0-9]{0,2})?$/)) {
      return;
    }

    if (value === "00" || value === "0.00") {
      return 0;
    }

    if (value.charAt(0) === ".") {
      value = `0${value}`;
    }
    const limitAmount = 100.0;
    const splitVal = value.toString().split(".");
    const isValid = splitVal.length > 1 ? splitVal[1].length <= 2 : true;
    if (value <= limitAmount && isValid) {
      return inputObj;
    }
  };
  const handleNumberChange = (e) => {
    let { value } = e.target;
    let val;
    if (value.charAt(0) === ".") {
      val = `0${value}`;
      value = val;
    }
    const copy = { ...calciumAdjustment };

    if (e.target.name === "magnesium") {
      let calciumValue = "";
      if (e.target.value !== "") {
        calciumValue = 100 - e.target.value;
      } else {
        calciumValue = "";
      }
      copy.calcium = calciumValue;
    }

    copy[e.target.name] = value;
    setCalciumAdjustment({ ...copy });
  };
  const handleChlorinatorNumberChange = (e) => {
    let { value } = e.target;
    let val;
    if (value.charAt(0) === ".") {
      val = `0${value}`;
      value = val;
    }

    setChlData({ ...chlorinatorData, [e.target.name]: value });
  };
  const handleCalciumModelData = (e) => {
    const value = e.target.value === "" ? 0.0 : parseFloat(e.target.value);
    if (e.target.name === "stabiliserLevel") {
      if (value <= 150 && value >= 0) {
        setCalciumAdjustment({
          ...calciumAdjustment,
          [e.target.name]: parseFloat(value).toFixed(),
        });
      }
    } else if (e.target.name === "saltLevel") {
      if (value <= 10000 && value >= 0) {
        setCalciumAdjustment({
          ...calciumAdjustment,
          [e.target.name]: parseFloat(value).toFixed(),
        });
      }
    } else {
      setCalciumAdjustment({
        ...calciumAdjustment,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleEditCalcium = (data, index) => {
    setCalciumAdjustment(data);
    setCalIndex(index);
    setMiniralPopup(!miniralPopup);
  };

  const handleSetEditChl = (data, index) => {
    setChlData(data);
    setIndex(index);
    setChlorinator(!chlorinator);
  };

  const handleDeleteModel = (index) => {
    const currentState = state;
    currentState.splice(index, 1);
    setState([...currentState]);
    setUpdate(true);
  };

  const handleCalciumDeleteModel = (index) => {
    const currentState = state;
    currentState.splice(index, 1);
    setState([...currentState]);
    setUpdate(true);
  };

  const submitRemove = (index) => {
    confirmAlert({
      title: "Delete Chlorinator Model?",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Cancel",
          onClick: () => " No ",
        },
        {
          label: "Yes",
          onClick: () => handleDeleteModel(index),
        },
      ],
    });
  };
  const removeCalcium = (index) => {
    confirmAlert({
      title: "Delete Calcium Model?",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleCalciumDeleteModel(index),
        },
        {
          label: "No",
          onClick: () => " No ",
        },
      ],
    });
  };

  // useEffect(() => {

  // }, [poolView]);

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  const [permission, setPermission] = useState(false);

  useEffect(() => {
    if (userData.accessLevel) {
      const filteredData = userData.permissions.find(
        (el) => el.action === "Chemicals Used to Balance Water",
      );
      // console.log('filteredData', filteredData, 'userData.accessLevel', userData.accessLevel);
      if (filteredData && filteredData.level.includes(userData.accessLevel)) {
        setPermission(true);
      } else {
        setPermission(false);
      }
    }
  }, []);

  const handleChangeBalance = (e, index) => {
    const old = [...state];
    if (
      e.target.value === "Dry Acid" ||
      e.target.value === "Hydrochloric Acid"
    ) {
      old.forEach((el) => {
        if (
          el.decreaseName === "Hydrochloric Acid" ||
          el.decreaseName === "Dry Acid"
        ) {
          el.selected = e.target.value;
        }
      });
    }

    old[index].selected = e.target.value;
    setState(old);
  };

  const handleChecked = (value) => {
    setState({ ...state, [value]: !state[value] });
  };

  const handleChangeTaxValues = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleChlorineModel = (value) => {
    let modelArr = saltChlorinator;
    if (
      value === "Mineral Pool" ||
      value === "Calcium Hardness Titration Test"
    ) {
      modelArr = mineralChlorinator;
    } else if (value === "Convert Salt Pool to Mineral Pool") {
      modelArr = convertPoolChlorineArray;
    } else if (value === "Manually Chlorinated") {
      modelArr = poolTypeArray;
    }
    return modelArr;
  };

  const calculationPoolLitres = (
    poolDepth,
    poolWidth,
    poolHeight,
    diameter,
    deepEnd,
    shallowEnd,
  ) => {
    let poolCapacity = 0;
    // console.log('cominginthissusiou', {
    //   poolDepth, poolWidth, poolHeight, diameter, deepEnd, shallowEnd,
    // });
    let poolMsg = "";
    if (poolDepth === "Average Depth - Rectangular Pool") {
      const calValue = !isUsImperial ? 1250 : 5.5 * 7.48;
      poolCapacity = poolWidth * poolHeight * calValue;
    } else if (poolDepth === "Average Depth - Circular pool") {
      const calValue = !isUsImperial ? 1250 * 0.78 : 5.5 * 7.48;
      poolCapacity = diameter * diameter * calValue;
    } else if (poolDepth === "Specific Depths - Rectangular Pool") {
      const calValue = !isUsImperial ? 1000 : 5.5 * 7.48;
      const shallowCalculation = (shallowEnd - deepEnd) / 2 + deepEnd;
      poolCapacity = poolWidth * poolHeight * shallowCalculation * calValue;
    } else if (poolDepth === "Specific Depths - Circular Pool") {
      const calValue = !isUsImperial ? 780 : 5.5 * 7.48;
      const shallowCalculation = (shallowEnd - deepEnd) / 2 + deepEnd;
      poolCapacity = diameter * diameter * shallowCalculation * calValue;
    }

    poolCapacity = !isUsImperial
      ? Math.floor(parseInt(poolCapacity, 10) / 1000) * 1000
      : Math.round(parseInt(poolCapacity, 10) / 100) * 100;

    const exceedLimitValue = !isUsImperial ? 2500000 : 660000;
    if (parseInt(poolCapacity, 10) >= exceedLimitValue) {
      poolMsg = "Exceeded Limit";
    }
    if (!(Number(poolCapacity) >= 0)) poolCapacity = 0;
    return { poolCapacity, poolMsg };
  };

  const handleUserPoolChange = (e) => {
    const { value, name } = e.target;
    // console.log("==>>>>>>>>>>", { value, name });
    const copyUserPoolData = { ...userPoolData };
    let { poolDepth, poolWidth, poolHeight, diameter, deepEnd, shallowEnd } =
      userPoolData;

    if (
      ["poolWidth", "poolHeight", "shallowEnd", "deepEnd", "diameter"].includes(
        name,
      )
    ) {
      if (value && !value.match(/^(\d{0,7})(\.[0-9]{0,2})?$/)) {
        return;
      }
      if (name === "poolWidth") {
        poolWidth = value;
      } else if (name === "poolHeight") {
        poolHeight = value;
      } else if (name === "shallowEnd") {
        shallowEnd = value;
      } else if (name === "deepEnd") {
        deepEnd = value;
      } else if (name === "diameter") {
        diameter = value;
      }

      const { poolMsg, poolCapacity } = calculationPoolLitres(
        poolDepth,
        Number(poolWidth),
        Number(poolHeight),
        Number(diameter),
        Number(deepEnd),
        Number(shallowEnd),
      );
      copyUserPoolData.poolMsg = poolMsg;
      copyUserPoolData.poolCapacity = poolCapacity;
    }

    if (name === "poolDepth") {
      const { poolMsg, poolCapacity } = calculationPoolLitres(
        value,
        Number(poolWidth),
        Number(poolHeight),
        Number(diameter),
        Number(deepEnd),
        Number(shallowEnd),
      );
      copyUserPoolData.poolMsg = poolMsg;
      copyUserPoolData.poolCapacity = poolCapacity;
    }

    copyUserPoolData[name] = value;

    if (name === "chlorinationType") {
      const modelArr = handleChlorineModel(value);
      console.log(modelArr, "modearray");
      copyUserPoolData.chlorineModel = modelArr;
      copyUserPoolData.selectedModel =
        modelArr[0].name || modelArr[0].brandName || modelArr[0];
    }
    // console.log(copyUserPoolData, 'naushadcopyuserpooldata');
    setUserPoolData(copyUserPoolData);
  };
  const addMoreUserPool = () => {
    const copyState = [...state];
    // let poolId = copyState?.length + 1;
    // let poolType = `Pool ${poolId}`;
    // copyState?.push({
    //   ...intialUserPoolObject, poolId, poolType, chlorineModel: saltChlorinator, selectedModel: saltChlorinator[0]?.name,
    // });
    // setState(copyState);
  };

  const handleRemoveUserPool = (poolNumber) => {
    const copyState = [...state];
    const fIndex = copyState.findIndex((el) => el?.poolId === poolNumber);
    if (fIndex >= 0) {
      copyState.splice(fIndex, 1);
      setState([...copyState]);
      setUpdate(true);
    }
  };

  // const handlePoolCapacity = () => {

  // };

  const handleUserPoolUpdate = async (e, poolId) => {
    try {
      const copyUserPoolData = { ...userPoolData };
      const {
        poolDepth,
        poolWidth,
        poolHeight,
        diameter,
        deepEnd,
        shallowEnd,
        poolCapacity,
      } = copyUserPoolData;
      if (!poolId) {
        let isValid = true;
        let validationObj = {};
        let emptyField = [];

        if (poolDepth === "Average Depth - Rectangular Pool") {
          validationObj.poolWidth = poolWidth;
          validationObj.poolHeight = poolHeight;
        } else if (poolDepth === "Average Depth - Circular pool") {
          validationObj.diameter = diameter;
        } else if (poolDepth === "Specific Depths - Rectangular Pool") {
          validationObj.poolWidth = poolWidth;
          validationObj.poolHeight = poolHeight;
          validationObj.shallowEnd = shallowEnd;
          validationObj.deepEnd = deepEnd;
        } else if (poolDepth === "Specific Depths - Circular Pool") {
          validationObj.shallowEnd = shallowEnd;
          validationObj.deepEnd = deepEnd;
          validationObj.diameter = diameter;
        }

        const returnedData = validateData(validationObj);
        isValid = returnedData.isValid;
        emptyField = returnedData.emptyField;
        console.log({ isValid, emptyField });
        setValData({ ...initialValidationData, ...emptyField });
        if (!isValid) return;
      }

      const exceedLimitValue = !isUsImperial ? 2500000 : 660000;
      if (parseInt(poolCapacity, 10) >= exceedLimitValue) {
        return toastMessage("error", "Pool Size Limit Exceeded");
      }
      if (
        userData?.accessLevel === "subscriber" &&
        state?.length >= userData?.numberOfPools &&
        !userPoolData?.poolId &&
        !poolId
      ) {
        toastMessage("error", "Cannot add enough pool & update your package");
        return;
      }

      delete copyUserPoolData?.chlorineModel;
      const payload = {};
      payload.key = view;
      if (!poolId) {
        payload.data = copyUserPoolData;
        payload.operator = "$push";
      } else {
        payload.data = {};
        payload.operator = "$pull";
        payload.data.poolId = poolId;
      }
      setIsDisabled(true);
      console.log({ payload });
      const response = await userInstance().put(
        "/poolmineral/updateUserPool",
        payload,
      );
      console.log("this is response for add pool ", response);
      toastMessage("success", response?.data?.msg);
      setIsDisabled(false);
      getIdeals();
      if (!poolId) {
        setUserPoolPopUp(!userPoolPopUp);
      }
    } catch (error) {
      if (error?.response?.data?.msg) {
        toastMessage("error", error?.response?.data?.msg);
      }
    }

    // }
  };

  const [userPoolPopUp, setUserPoolPopUp] = useState(false);

  const handleUserPoolPopUp = () => {
    // console.log('userPoolDataooooo', userPoolData);
    setUserPoolData({
      ...userPoolState,
      chlorineModel: saltChlorinator,
      selectedModel: saltChlorinator[0]?.name,
    });
    setUserPoolPopUp(!userPoolPopUp);
  };

  const handleEditUserPool = (poolId) => {
    // console.log("ppoll", poolId, state);
    const copyState = [...state];
    const fIndex = copyState.findIndex((el) => el?.poolId === poolId);
    if (fIndex >= 0) {
      const modelArr = handleChlorineModel(copyState[fIndex]?.chlorinationType);
      console.log("ModelArr", modelArr);

      setUserPoolData({ ...copyState[fIndex], chlorineModel: modelArr });
      setUserPoolPopUp(!userPoolPopUp);
    }
  };

  const [carryData, setCarryData] = useState(null);
  const handleDeletePopUp = (poolId) => {
    // console.log("Iddd==>>>", poolId);
    const fIndex = state.findIndex((el) => el?.poolId === poolId);
    if (fIndex >= 0) {
      setCarryData({ ...state[fIndex], poolType: `My Pool No. ${fIndex + 1}` });
      setDeletePopUp(true);
    }
  };

  const renderView = () => {
    // console.log('view', view);
    let componentToRender = null;
    switch (view) {
      case "chemicalsDisplay":
        componentToRender = (
          <ChemicalsDisplay
            handleChecked={handleChecked}
            state={state}
            handleRequest={handleRequest}
            handleRevertViewUpdate={handleRevertViewUpdate}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
            loading={loading}
          />
        );
        break;

      case "chemicalTargetLevels":
        componentToRender = (
          <ChemistryTarget
            handleChange={handleChange}
            handleAdjust={handleAdjust}
            state={state}
            handleRequest={handleRequest}
            handleRevertViewUpdate={handleRevertViewUpdate}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
            change={change}
            checkBlankFields={checkBlankFields}
            errorValue={errorValue}
          />
        );
        break;
      case "chemicalCosts":
        componentToRender = (
          <ChemicalCost
            idealData={idealData}
            handleChange={handleChange}
            handleAdjust={handleAdjust}
            state={state}
            handleRequest={handleRequest}
            handleRevertViewUpdate={handleRevertViewUpdate}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
            defaultViewState={defaultViewState}
            change={change}
            isUsImperial={isUsImperial}
            removeInCaseOfImperial={removeInCaseOfImperial}
            checkBlankFields={checkBlankFields}
          />
        );
        break;
      case "chlorinatorModels":
        componentToRender = (
          <ChlorinatorPage
            handleChlorinator={handleChlorinator}
            renderSort={renderSort}
            setRenderSort={setRenderSort}
            handleChlModelData={handleChlModelData}
            state={state}
            handleSetEditChl={handleSetEditChl}
            submitRemove={submitRemove}
            moveArr={moveArr}
            handleSorting={handleSorting}
          />
        );
        break;
      case "drainageSetup":
        componentToRender = (
          <div className="dranage-cost-setup">
            <DrainageCost
              handleChangeDrainageCost={handleChangeDrainageCost}
              handleRequest={handleRequest}
              state={state}
              handleRevertViewUpdate={handleRevertViewUpdate}
              handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
              change={change}
              isUsImperial={isUsImperial}
              checkBlankFields={checkBlankFields}
            />
          </div>
        );
        break;

      case "calciumAdjustments":
        componentToRender = (
          <MiniralPool
            renderSort={renderSort}
            setRenderSort={setRenderSort}
            handleMiniralPopup={handleMiniralPopup}
            state={state}
            handleEditCalcium={handleEditCalcium}
            removeCalcium={removeCalcium}
            handleCalciumModelData={handleCalciumModelData}
            moveArr={moveArr}
            handleSorting={handleSorting}
            handleMineralOrp={handleMineralOrp}
          />
        );
        break;
      case "chemicalBalanceWater":
        componentToRender = (
          <BalanceWater
            state={state}
            handleRevertViewUpdate={handleRevertViewUpdate}
            handleRequest={handleRequest}
            handleChangeBalance={handleChangeBalance}
            chlorineValue={chlorineValue}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
            change={change}
            // selected={selected}
          />
        );
        break;
      case "greenpoolcalculation":
        componentToRender = (
          <GreenPoolCalculation
            greenPool={greenPool}
            state={state}
            view={view}
            handleChange={handleChange}
            handleRequest={handleRequest}
            handleRevertViewUpdate={handleRevertViewUpdate}
            count={count}
            newArrayStateGreen={newArrayStateGreen}
            poolChlorineSize={poolChlorineSize}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
            change={change}
            chemCost={chemCost}
            isUsImperial={isUsImperial}
            checkBlankFields={checkBlankFields}
          />
        );
        break;
      case "blackSpotAlgae":
        componentToRender = (
          <BlackSpotAlgae
            greenPool={greenPool}
            state={state}
            view={view}
            handleChange={handleChange}
            handleRequest={handleRequest}
            handleRevertViewUpdate={handleRevertViewUpdate}
            count={count}
            newArrayStateGreen={newArrayStateBlack}
            poolChlorineSize={poolBlackSize}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
            change={change}
            chemCost={chemCost}
            isUsImperial={isUsImperial}
            checkBlankFields={checkBlankFields}
          />
        );
        break;
      case "acidWashCalculation":
        componentToRender = (
          <AcidWashCalculation
            drainState={drainState}
            acidWash={acidWash}
            state={state}
            handleChange={handleChange}
            handleRequest={handleRequest}
            handleRevertViewUpdate={handleRevertViewUpdate}
            drainPoolSize={drainPoolSize}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
            change={change}
            isUsImperial={isUsImperial}
            checkBlankFields={checkBlankFields}
          />
        );
        break;
      case "sandFilterMedia":
        componentToRender = (
          <FilterReplacement
            state={state}
            handleRevertViewUpdate={handleRevertViewUpdate}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
            handleFilterChange={handleFilterChange}
            handleRequest={handleRequest}
            change={change}
            filterData={filterData}
            granSelected={granSelected}
            isUsImperial={isUsImperial}
            checkBlankFields={checkBlankFields}
          />
        );
        break;

      default:
        break;
    }
    return componentToRender;
  };

  const updateState = () => {
    let stateName = null;
    switch (view) {
      case "taxApplicable":
        stateName = taxApplicableState;
        break;
      case "chemicalTargetLevels":
        stateName = targetLevelState;
        break;
      case "chemicalCosts":
        stateName = chemicalCostState;
        break;
      case "chlorinatorModels":
        stateName = [];
        break;
      case "drainageSetup":
        stateName = drainageCostState;
        break;
      case "calciumAdjustments":
        stateName = [];
        break;
      case "chemicalBalanceWater":
        stateName = balanceWaterState;
        break;
      case "greenpoolcalculation":
        stateName = greenPoolState;
        break;
      case "blackSpotAlgae":
        stateName = blackSpotAlgaeState;
      case "acidWashCalculation":
        stateName = drainAcidState;
        break;
      case "sandFilterMedia":
        stateName = sandFilterMediaState;
        break;
      case "secondVisitCost":
        stateName = secondVisitCostState;
        break;
      case "chemicalsDisplay":
        stateName = chemicalDisplayState;
        break;

      default:
        break;
    }
    setState(stateName);
  };

  const checkChlorinatorValues = () => {
    let isValid = true;
    const {
      name,
      saltLevel,
      okNegative,
      okPositive,
      drainPoint,
      orp,
      recmndStabiliserLvl,
    } = chlorinatorData;
    if (!name?.trim()) {
      isValid = false;
      toastMessage("error", "Name is required");
      setErrorValue("name");
    } else if (!saltLevel) {
      isValid = false;
      toastMessage("error", "Salt Level is required");
      setErrorValue("saltLevel");
    } else if (!okNegative) {
      isValid = false;
      toastMessage("error", "Ok Negative is required");
      setErrorValue("okNegative");
    } else if (!okPositive) {
      isValid = false;
      toastMessage("error", "Ok Positive is required");
      setErrorValue("okPositive");
    } else if (!drainPoint) {
      isValid = false;
      toastMessage("error", "Drain Point is required");
      setErrorValue("drainPoint");
    } else if (orp === "Yes" && !recmndStabiliserLvl?.trim()) {
      toastMessage("error", "Fill Manufacturer Recommended Stabiliser Level");
      isValid = false;
      setErrorValue("recmndStabiliserLvl");
    }

    setTimeout(() => {
      setErrorValue("");
    }, 1000);
    return isValid;
  };

  const handleAddModel = (e) => {
    const isValid = checkChlorinatorValues();
    e.preventDefault();
    if (isValid) {
      const currentState = state;
      currentState.push(chlorinatorData);
      setState([...currentState]);
      setUpdate(true);
      setCalciumAdjustment(chlorinatorModelState);
      handleChlorinator();
    }
  };

  const handleEditModel = (e) => {
    const isValid = checkChlorinatorValues();
    e.preventDefault();
    if (isValid) {
      const currentState = state;
      currentState[selectedIndexModel] = chlorinatorData;
      setState([...currentState]);
      setUpdate(true);
      setChlData(chlorinatorModelState);
      handleChlorinator();
    }
  };

  const checkCalciumValue = () => {
    const {
      okPositive,
      okNegative,
      saltLevel,
      drainPoint,
      orp,
      stabiliserLevel,
    } = calciumAdjustment;
    const validationObj = {
      saltLevel,
      okNegative,
      okPositive,
      drainPoint,
    };
    let { isValid, emptyField } = validateDataWithKey(validationObj);
    let message = "";
    if (!isValid) message = `${emptyField} is required`;

    if (isValid && orp === "Yes" && !stabiliserLevel?.trim()) {
      message = "Fill Manufacturer Recommended Stabiliser Level";
      emptyField = "stabiliserLevel";
      isValid = false;
    }
    if (message) {
      toastMessage("error", message);
      setErrorValue(emptyField);
      setTimeout(() => {
        setErrorValue("");
      }, 1000);
    }

    return isValid;
  };

  const handleAddCalciumModel = (e) => {
    e.preventDefault();
    const { brandName, magnesium, calcium } = calciumAdjustment;
    const isValid = checkCalciumValue();
    if (isValid) {
      if (parseFloat(magnesium) + parseFloat(calcium) === 100) {
        const currentState = state;
        currentState.push(calciumAdjustment);
        setState([...currentState]);
        setUpdate(true);
        setCalciumAdjustment(calciumAdjustmentState);
        setMiniralPopup(!miniralPopup);
      } else {
        toastMessage(
          "error",
          "Magnesium percentage & Calcium percentage must equal 100 percent",
        );
      }
    }
  };

  const handleEditCalciumModel = (e) => {
    e.preventDefault();
    const { brandName, magnesium, calcium } = calciumAdjustment;
    console.log({ calciumAdjustment });
    const isValid = checkCalciumValue();
    if (isValid) {
      if (
        parseFloat(calciumAdjustment.magnesium) +
          parseFloat(calciumAdjustment.calcium) ===
        100
      ) {
        const currentState = state;
        currentState[calIndex] = calciumAdjustment;
        setState([...currentState]);
        setUpdate(true);
        setCalciumAdjustment(calciumAdjustmentState);
        setMiniralPopup(!miniralPopup);
      } else {
        toastMessage(
          "error",
          "Magnesium percentage & Calcium percentage must equal 100 percent",
        );
      }
    }
  };

  const handleNewRevertDefaultUpdate = (e, data) => {
    let defaultValues = null;
    switch (view) {
      case "chemicalTargetLevels":
        defaultValues = defaultViewState;
        break;
      case "chemicalCosts":
        defaultValues = defaultViewState;
        break;
      case "drainageSetup":
        defaultValues = defaultViewState;
        break;
      case "chemicalBalanceWater":
        defaultValues = defaultViewState;
        break;
      case "greenpoolcalculation":
        defaultValues = defaultViewState;
        break;
      case "blackSpotAlgae":
        defaultValues = defaultViewState;
        break;
      case "acidWashCalculation":
        defaultValues = defaultViewState;
        break;
      case "sandFilterMedia":
        defaultValues = defaultViewState;
        break;
      case "secondVisitCost":
        defaultValues = defaultViewState;
        break;
      case "chemicalsDisplay":
        defaultValues = defaultViewState;
        break;

      default:
        break;
    }

    if (view === "drainageSetup") {
      setState(defaultValues);
    } else if (view === "secondVisitCost") {
      setState(defaultValues);
    } else if (view === "taxApplicable") {
      setState(defaultValues);
    } else if (view === "chemicalsDisplay") {
      setState(defaultValues);
    } else {
      setState([...defaultValues]);
    }
    if (view === "greenpoolcalculation") {
      getData();
    }
    setUpdate(true);
    setManagementPrevious(true);
  };

  const handleRevertViewUpdate = (e, data) => {
    setChange("");
    setdata(actualState);
    let defaultValues = null;
    switch (view) {
      case "taxApplicable":
        defaultValues = defaultTaxApplicableState;
        break;
      case "chemicalTargetLevels":
        defaultValues = targetLevelDefaultState;
        break;
      case "chemicalCosts":
        defaultValues = chemicalCostDefaultState;
        break;
      case "drainageSetup":
        defaultValues = drainageCostDefaultState;
        break;
      case "chemicalBalanceWater":
        defaultValues = chemicalBalanceWaterDefaultState;
        break;
      case "greenpoolcalculation":
        defaultValues = greenPoolCalculationDefaultState;
        break;
      case "blackSpotAlgae":
        defaultValues = blackSpotAlgaeDefaultState;
        break;
      case "acidWashCalculation":
        defaultValues = drainAcidDefaultState;
        break;
      case "sandFilterMedia":
        defaultValues = sandFilterMediaDefaultState;
        break;
      case "secondVisitCost":
        defaultValues = defaultSecondVisitState;
        break;
      case "chemicalsDisplay":
        defaultValues = defaultChemicalDisplayState;
        break;

      default:
        break;
    }

    if (view === "drainageSetup") {
      setState(defaultValues);
    } else if (view === "secondVisitCost") {
      setState(defaultValues);
    } else if (view === "taxApplicable") {
      setState(defaultValues);
    } else if (view === "chemicalsDisplay") {
      setState(defaultValues);
    } else {
      setState([...defaultValues]);
    }
    if (view === "greenpoolcalculation") {
      getData();
    }
    setUpdate(true);
    setManagementPrevious(true);
  };

  useEffect(() => {
    if (isUpdate === null) {
      updateState();
      if (view) {
        getIdeals();
      }
    }
    if (isUpdate === true) {
      handleRequest();
    }
  }, [view, isUpdate]);

  useEffect(() => {
    if (view) {
      chck();
    }
  }, [view]);

  useEffect(() => {
    if (errorValue) {
      // setErrorValue('');
      let element = document.querySelector(".error-text");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [errorValue]);

  const paraText = "Are you sure to Delete ? ";
  const PopUpText = carryData?.poolType;
  const handlePopUpChange = (val) => {
    if (val === "Yes") {
      handleUserPoolUpdate("", carryData?.poolId);
    }
    setDeletePopUp(false);
  };

  return (
    <>
      {!loading && view === "userPool" ? (
        <>
          <UserPool
            state={state}
            handleRequest={handleRequest}
            handleUserPoolChange={handleUserPoolChange}
            poolFinishArray={poolFinishArray}
            chlorinationArray={chlorinationArray}
            poolTypeArray={poolTypeArray}
            convertPoolChlorineArray={convertPoolChlorineArray}
            poolDepthArray={poolDepthArray}
            addMoreUserPool={addMoreUserPool}
            handleRemoveUserPool={handleUserPoolUpdate}
            handleUserPoolUpdate={handleUserPoolUpdate}
            handleUserPoolPopUp={handleUserPoolPopUp}
            handleEditUserPool={handleEditUserPool}
            handleDeletePopUp={handleDeletePopUp}
            isUsImperial={isUsImperial}
          />
          <UserPoolPopup
            handleUserPoolPopUp={handleUserPoolPopUp}
            userPoolPopUp={userPoolPopUp}
            poolFinishArray={poolFinishArray}
            chlorinationArray={chlorinationArray}
            poolTypeArray={poolTypeArray}
            convertPoolChlorineArray={convertPoolChlorineArray}
            poolDepthArray={poolDepthArray}
            userPoolData={userPoolData}
            handleUserPoolChange={handleUserPoolChange}
            handleUserPoolUpdate={handleUserPoolUpdate}
            validationData={validationData}
            isUsImperial={isUsImperial}
            isDisabled={isDisabled}
          />
          {deletePopup && (
            <DeletePopUp
              handlePopUpChange={handlePopUpChange}
              setDeletePopUp={setDeletePopUp}
              // deletePopUp={deletePopUp}
              PopUpText={PopUpText}
              paraText={paraText}
            />
          )}
        </>
      ) : (
        <Adminwrapper>
          {loading ? (
            <Loader />
          ) : (
            <div className=" pool-set-up">
              <div className="container">
                <div className="row webview-pool-section">
                  <div className="col-md-12">
                    <div className="top-heading">
                      <h1>Pool Setup</h1>
                    </div>
                    <div className="dropdown-option-section">
                      <div
                        className={`option-list ${
                          activeLink === "calciumAdjustments"
                            ? "active-menu"
                            : ""
                        }`}
                        onClick={() => updateView("calciumAdjustments")}
                      >
                        <img src={ca} alt="edit" />
                        Mineral Pool Calcium Adjustment
                      </div>
                      <div
                        className={`option-list ${
                          activeLink === "chemicalBalanceWater"
                            ? "active-menu"
                            : ""
                        }`}
                        onClick={() => updateView("chemicalBalanceWater")}
                      >
                        <img src={greyplus} alt="edit" />
                        Chemicals Used to Balance Water
                      </div>
                      <div
                        className={`option-list ${
                          activeLink === "greenpoolcalculation"
                            ? "active-menu"
                            : ""
                        }`}
                        onClick={() => updateView("greenpoolcalculation")}
                      >
                        <img src={target} alt="edit" />
                        Green Pool Calculations
                      </div>
                      <div
                        className={`option-list ${
                          activeLink === "blackSpotAlgae" ? "active-menu" : ""
                        }`}
                        onClick={() => updateView("blackSpotAlgae")}
                      >
                        <img src={target} alt="edit" />
                        Black-Spot Algae Calculations
                      </div>
                      <div
                        className={`option-list ${
                          activeLink === "acidWashCalculation"
                            ? "active-menu"
                            : ""
                        }`}
                        onClick={() => updateView("acidWashCalculation")}
                      >
                        <img src={target} alt="edit" />
                        Drain, Pressure Wash, Chlorine & Acid Wash Calculations
                      </div>
                      <div
                        className={`option-list ${
                          activeLink === "sandFilterMedia" ? "active-menu" : ""
                        }`}
                        onClick={() => updateView("sandFilterMedia")}
                      >
                        <img src={target} alt="edit" />
                        Sand Filter Media Replacement
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pool-bg">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="main-titles-mob">
                        <h3>Pool Set-up</h3>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">{renderView()}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Chlorinator
            chlorinator={chlorinator}
            setChlorinator={setChlorinator}
            handleChlorinator={handleChlorinator}
            handleChlModelData={handleChlModelData}
            chlorinatorData={chlorinatorData}
            handleAddModel={handleAddModel}
            selectedIndexModel={selectedIndexModel}
            handleEditModel={handleEditModel}
            orpProbe={orpProbe}
            setOrpProbe={setOrpProbe}
            handleOrp={handleOrp}
            handleChlorinatorNumberChange={handleChlorinatorNumberChange}
            limitNumber={limitNumber}
            error={errorValue}
          />
          <MiniralPopup
            limitNumber={limitNumber}
            handleNumberChange={handleNumberChange}
            miniralPopup={miniralPopup}
            setMiniralPopup={setMiniralPopup}
            handleMiniralPopup={handleMiniralPopup}
            calciumAdjustment={calciumAdjustment}
            handleEditCalciumModel={handleEditCalciumModel}
            handleAddCalciumModel={handleAddCalciumModel}
            handleCalciumModelData={handleCalciumModelData}
            calIndex={calIndex}
            handleMineralOrp={handleMineralOrp}
            error={errorValue}
          />
          {durationPicker && (
            <DurationPicker
              durationPicker={durationPicker}
              setDurationPicker={setDurationPicker}
              durationData={durationData}
              handleDurationPicker={handleDurationPicker}
              setDuration={setDuration}
              setBookingData={setState}
              convertToReadableDuration={convertToReadableDuration}
              bookingData={state}
              subcategory={null}
              setSubCategory={null}
            />
          )}
        </Adminwrapper>
      )}
    </>
  );
}
export default PoolSetUp;
