/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import { useHistory } from 'react-router';
import { Form, Button } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import logo from "../../assets/home/calculator-logo2.png";
import { withoutAuth } from "../../config/axios";
import { errorMsgTime, successMsgTime } from "../../functions/intervalTime";
import toastMessage from "../../functions/toastMessage";

const ResetPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  // const history = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!email) {
        toastMessage("error", "Please enter email");
      } else {
        console.log("email", email);
        const response = await withoutAuth().put("/user/forgotPassword", {
          email,
        });
        // console.log('response', response.data.msg);
        const { msg, token } = response.data;
        if (response.status === 200) {
          toastMessage("success", msg);
          // console.log('shshhshshshshsh');
          window.location.href = `/update-password?token=${token}`;
          // history.push('/update-password');
        } else {
          toastMessage("error", msg);
        }
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    history.push("/login");
  };

  return (
    <div className="login-container reset-password">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="login">
              <h3 className="login-title">Reset Password</h3>
              <div className="login-box">
                <div className="logo-login">
                  <img src={logo} alt="logo" />
                </div>
                <div className="login-form">
                  <Form>
                    <div className="row">
                      <div className="col-md-12">
                        <Form.Group controlId="formBasicEmail" required="">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your email"
                            name="email"
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>

                    <div className="reset-info">
                      A reset password link will be sent to your email. <br />
                      Please allow up to 15 minutes.
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="login-popup-btn new_forget_btn">
                          <Link to="/login">
                            <Button
                              type="button"
                              className="btn btn-danger btn-edit"
                              onClick={handleCancel}
                            >
                              {" "}
                              Cancel{" "}
                            </Button>
                          </Link>
                          <Link to="/update-password">
                            <Button type="submit" onClick={handleSubmit}>
                              {" "}
                              Submit{" "}
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="row" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
