import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { IoIosSave } from "react-icons/io";
import TextField from "../components/textField";
import Button from "../components/button";
import LoadingButton from "../components/loadingButton";
import TextArea from "../components/textarea";
import toastMessage from "../../../functions/toastMessage";
import { chatPath } from "../../../config/keys";
import { userInstance } from "../../../config/axios";

const AddTextModal = (props) => {
  const {
    show,
    handleClose,
    uploadedTexts,
    setUploadedTexts,
    getUploadedTexts,
    scope,
    isEditMode,
    selectedItem,
  } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const handleChangeTitle = (value) => {
    setTitle(value);
  };

  const handleChangeContent = (value) => {
    setContent(value);
  };

  const handleSaveText = async () => {
    if (title === "") {
      toastMessage("error", "Please input the title.");
      return;
    }

    if (content === "") {
      toastMessage("error", "Please input the content.");
      return;
    }

    try {
      setIsSaving(true);

      if (!isEditMode) {
        let response = await userInstance().post(
          `${chatPath}/chatbotSettings/uploadText`,
          { title, content, scope },
        );
        setIsSaving(false);
        const { code, msg } = response.data;
        if (code === 200) {
          const { uploadedText } = response.data;
          setUploadedTexts((prev) => [...prev, uploadedText]); // Inline return without curly braces
          toastMessage("success", msg);
        } else {
          toastMessage("error", msg);
        }

        initialize();
        handleClose();
      } else {
        let response = await userInstance().post(
          `${chatPath}/chatbotSettings/updateUploadedText`,
          {
            id: selectedItem._id,
            title,
            content,
            scope,
          },
        );
        const { code, msg } = response.data;
        setIsSaving(false);
        if (code === 200) {
          const { updatedUploadedText } = response.data;
          let _uploadedTexts = uploadedTexts.slice();
          let findIndex = _uploadedTexts.findIndex(
            (item) => item._id === updatedUploadedText._id,
          );
          if (findIndex !== -1) {
            _uploadedTexts[findIndex].title = updatedUploadedText.title;
            _uploadedTexts[findIndex].content = updatedUploadedText.content;
            setUploadedTexts(_uploadedTexts);
          }
          toastMessage("success", msg);
        } else {
          toastMessage("error", msg);
        }
        initialize();
        handleClose();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const initialize = () => {
    setTitle("");
    setContent("");
  };

  const handleCloseModal = () => {
    initialize();
    handleClose();
  };

  useEffect(() => {
    if (selectedItem !== null) {
      if (isEditMode) {
        setTitle(selectedItem.title);
        setContent(selectedItem.content);
      } else {
        initialize();
      }
    } else {
      initialize();
    }
  }, [selectedItem, isEditMode, show]);

  return (
    <>
      <Modal
        show={show}
        centered
        dialogClassName="chatbot-settings-modal-container modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {isEditMode ? "Edit Text" : "Add Text"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column gap-3">
            <TextField
              type="text"
              label="Title"
              placeholder=""
              value={title}
              handleChangeValue={handleChangeTitle}
            />
            <TextArea
              label="Content"
              placeholder=""
              value={content}
              row={10}
              handleChangeValue={handleChangeContent}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-2">
            <Button
              label="Cancel"
              handleClickBtn={handleCloseModal}
              disabled={isSaving}
            />
            <LoadingButton
              isLoading={isSaving}
              icon={<IoIosSave size={18} style={{ marginRight: "16px" }} />}
              label="Save"
              handleClickBtn={handleSaveText}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

AddTextModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  uploadedTexts: PropTypes.arrayOf.isRequired,
  setUploadedTexts: PropTypes.func.isRequired,
  getUploadedTexts: PropTypes.func.isRequired,
  scope: PropTypes.string.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  selectedItem: PropTypes.objectOf.isRequired,
};

export default AddTextModal;
