/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-quotes */
import React from 'react';
import { Accordion, Button, Card, Dropdown, Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import toastMessage from '../../functions/toastMessage';

const EditBillingDetail = ({
  selectedCountry,
  flag,
  us,
  subscriptionDetails,
  handleChange,
  handlePress,
  mobileFormat,
  editUserProfile,
  error,
  stateArray,
  countries,
}) => {
  const validateMobileNo = (inputObj) => {
    const { value } = inputObj;
    if (value) {
      if (
        (value.trim().charAt(0) === '6' && value.trim().charAt(1) === '1') ||
        value.trim().charAt(0) === '0' ||
        value.trim().charAt(0) === '+'
      ) {
        toastMessage(
          'error',
          `Please do not enter ${
            value.trim().charAt(0) === '0' || value.trim().charAt(0) === '+'
              ? value.charAt(0).trim()
              : '61'
          }  at start of mobile no`
        );
        return '';
      }
      return inputObj;
    }
    return inputObj;
  };

  console.log('subscriptionDetails==>', subscriptionDetails.mobileNo);

  function formatMobileNumber(mobileNumber) {
    let parts = mobileNumber.split(' ');

    if (parts.length > 1) {
      return parts.slice(1).join(' ');
    }
    return mobileNumber;
  }

  formatMobileNumber(subscriptionDetails.mobileNo);

  console.log(formatMobileNumber(subscriptionDetails.mobileNo));

  const handleCountryChange = (e) => {
    handleChange(e);
  };

  return (
    <div className='target-heading new-client-section flex-column'>
      {/* <h1 className="data_heading">Current Subscription</h1>/ */}
      <Accordion className='w-100'>
        <Card.Body>
          <ul className='subscription_billing staff-list top-client-details1 '>
            <div className='staff-form w-100'>
              <div className='row'>
                <div className='col-md-12'>
                  <Form.Group
                    controlId='formBasicInput-9'
                    className='italic-text'
                  >
                    <Form.Label>
                      {subscriptionDetails.customerType !== 'Householder'
                        ? 'Billing '
                        : ''}
                      Country
                    </Form.Label>
                    <div className='select-box'>
                      <Form.Control
                        as='select'
                        name='country'
                        onChange={handleCountryChange}
                        value={subscriptionDetails.country}
                        className={`${error === 'country' ? 'error-text' : ''}`}
                      >
                        {countries?.length > 0 &&
                          countries.map((el) => (
                            <option value={el.name}>{el.name}</option>
                          ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <Form.Group
                    controlId='formBasicInput-1'
                    className='italic-text'
                  >
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type='text'
                      onChange={handleChange}
                      name='firstname'
                      value={subscriptionDetails.firstname}
                      onKeyPress={handlePress}
                      placeholder='Enter First Name'
                    />
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <Form.Group
                    controlId='formBasicInput-1'
                    className='italic-text'
                  >
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type='text'
                      onChange={handleChange}
                      name='lastname'
                      value={subscriptionDetails.lastname}
                      onKeyPress={handlePress}
                      placeholder='Enter First Name'
                    />
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <Form.Group
                    controlId='formBasicInput-3'
                    className='number italic-text'
                  >
                    <Form.Label>
                      {subscriptionDetails.customerType !== 'Householder'
                        ? 'Main '
                        : ''}
                      Contact`s Mobile No.
                    </Form.Label>
                    <div className='number-field'>
                      <div className='number-box'>
                        <Dropdown id='mobile'>
                          <Dropdown.Toggle
                            variant='success'
                            id='dropdown-basic1'
                          >
                            <div className='flag-icon'>
                              <img
                                src={selectedCountry === '+61' ? flag : us}
                                alt='flag'
                              />
                            </div>
                            {selectedCountry === '+61' ? (
                              <span>
                                Australia
                                {selectedCountry}
                              </span>
                            ) : selectedCountry === '+1' ? (
                              <span>
                                United States
                                {selectedCountry}
                              </span>
                            ) : (
                              ''
                            )}
                          </Dropdown.Toggle>
                        </Dropdown>
                      </div>
                      <NumberFormat
                        className={`form-control mobile-num ${
                          error === 'mobileno' ? 'error-text' : ''
                        }`}
                        format={mobileFormat}
                        onChange={handleChange}
                        name='mobileNo'
                        value={formatMobileNumber(subscriptionDetails.mobileNo)}
                        placeholder='Enter Mobile Number'
                        autocomplete='off'
                        isAllowed={validateMobileNo}
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <Form.Group controlId='formBasicInput-34' className='number'>
                    <Form.Label>
                      {subscriptionDetails.customerType !== 'Householder'
                        ? 'Main '
                        : ''}
                      Contact s Landline No.
                    </Form.Label>
                    <div className='number-field'>
                      <div className='number-box'>
                        <Dropdown id='landline'>
                          <Dropdown.Toggle
                            variant='success'
                            id='dropdown-basic'
                          >
                            <div className='flag-icon'>
                              <img
                                src={selectedCountry === '+61' ? flag : us}
                                alt='flag'
                              />
                            </div>
                            {selectedCountry === '+61' ? (
                              <span>
                                Australia
                                {selectedCountry}
                              </span>
                            ) : selectedCountry === '+1' ? (
                              <span>
                                United States
                                {selectedCountry}
                              </span>
                            ) : (
                              ''
                            )}
                          </Dropdown.Toggle>
                        </Dropdown>
                      </div>
                      <NumberFormat
                        className={`form-control mobile-num ${
                          error === 'landlineno' ? 'error-text' : ''
                        }`}
                        format='# #### ####'
                        onChange={handleChange}
                        name='landlineNo'
                        value={subscriptionDetails.landlineNo}
                        autocomplete='off'
                      />
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <Form.Group
                    controlId='formBasicInput-1'
                    className='italic-text'
                  >
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type='email'
                      onChange={handleChange}
                      name='email'
                      value={subscriptionDetails.email}
                      placeholder='Enter Email Address'
                      className={`${error === 'email' ? 'error-text' : ''}`}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <Form.Group
                    controlId='formBasicInput-1'
                    className='italic-text'
                  >
                    <Form.Label>Street Address</Form.Label>
                    <Form.Control
                      type='text'
                      onChange={handleChange}
                      name='streetAddress'
                      value={subscriptionDetails.streetAddress}
                      placeholder='Enter Street Address'
                      className={`${
                        error === 'streetaddress' ? 'error-text' : ''
                      }`}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <Form.Group
                    controlId='formBasicInput-1'
                    className='italic-text'
                  >
                    <Form.Label>City/Town/Suburb</Form.Label>
                    <Form.Control
                      type='text'
                      onChange={handleChange}
                      name='suburb'
                      placeholder='Enter City/Town/Suburb'
                      value={subscriptionDetails.suburb}
                      className={`${error === 'suburb' ? 'error-text' : ''}`}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <Form.Group
                    controlId='formBasicInput-1'
                    className='italic-text'
                  >
                    <Form.Label>Postal / Zip Code</Form.Label>
                    <Form.Control
                      type='number'
                      onChange={handleChange}
                      name='postcode'
                      value={subscriptionDetails.postcode}
                      placeholder='Enter Postal/Zip Code'
                      className={`${
                        error === 'postal/zipcode' ? 'error-text' : ''
                      }`}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <Form.Group
                    controlId='formBasicInput-9'
                    className='italic-text'
                  >
                    <Form.Label>
                      {subscriptionDetails.customerType !== 'Householder'
                        ? 'Billing '
                        : ''}
                      State
                    </Form.Label>
                    <div className='select-box'>
                      <Form.Control
                        as='select'
                        onChange={handleChange}
                        name='state'
                        value={subscriptionDetails.state}
                        disabled
                        className={`${error === 'state' ? 'error-text' : ''}`}
                      >
                        <option value=''>Select</option>
                        {stateArray?.length > 0 &&
                          stateArray.map((el) => (
                            <option value={el.name}>
                              {el.isoCode} -{el.name}
                            </option>
                          ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <div className='view-btn staff-form-btn'>
                    <Button type='button' className='btn btn-edit'>
                      {' '}
                      Cancel{' '}
                    </Button>
                    <Button
                      type='Submit'
                      className='btn btn-save'
                      onClick={editUserProfile}
                    >
                      {' '}
                      Save/Update{' '}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </Card.Body>
      </Accordion>
    </div>
  );
};

export default EditBillingDetail;

EditBillingDetail.propTypes = {
  selectedCountry: PropTypes.func.isRequired,
  flag: PropTypes.bool.isRequired,
  us: PropTypes.bool.isRequired,
  subscriptionDetails: PropTypes.objectOf({}).isRequired,
  handleChange: PropTypes.func.isRequired,
  handlePress: PropTypes.func.isRequired,
  mobileFormat: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf.isRequired,
  stateArray: PropTypes.arrayOf.isRequired,
  editUserProfile: PropTypes.func.isRequired,
};
