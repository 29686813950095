/* eslint-disable no-return-assign */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React from "react";
import { Form, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import reload from "../../assets/home/refresh-icon.png";

const DrainageCost = ({
  drainState,
  state,
  handleChange,
  handleRequest,
  poolSize,
  isUsImperial,
}) => {
  const { setupCost, hourlyRate, litresPerHour } = drainState || {};

  const baseCost = parseFloat(setupCost);
  let litresHour = parseFloat(litresPerHour);
  let hourRate = parseFloat(hourlyRate);

  let hoursToDrain = (poolSize / litresHour) * 100;
  hoursToDrain /= 100;
  // drainingHours = hoursToDrain.toFixed(1);
  const subTotal = baseCost + hourRate * hoursToDrain;
  const roundUpTotal = (subTotal / 5) * 5;

  let drainCost = roundUpTotal;

  // console.log('drainCost', drainCost);

  let drainPressure = [parseFloat(drainCost)];
  let drainPresChlorine = [parseFloat(drainCost)];
  let drainPressureAcid = [parseFloat(drainCost)];
  let drainPresChlorineAcid = [parseFloat(drainCost)];
  let variable2 = "";
  let variable3 = "";
  let variable5 = "";
  let variable6 = "";

  function calcuDrainCosts(elname, type) {
    let result = "";
    let pooSizeForCalci = poolSize || 0;
    // console.log('pooooooooooooo', poolSize);

    Array.isArray(state)
      ? state.map((el, i) => {
          if (el.name === elname && type === "B") {
            result = (el.dosageAndTime / 40000) * pooSizeForCalci;
            variable2 = parseFloat(result).toFixed(2);
            drainPresChlorine.push(result);
            drainPresChlorineAcid.push(result);
          } else if (el.name === elname && type === "C") {
            result = (((35 / 15) * el.dosageAndTime) / 40000) * pooSizeForCalci;
            variable3 = parseFloat(result).toFixed(2);
            drainPresChlorine.push(result);
            drainPresChlorineAcid.push(result);
          } else if (el.name === elname && type === "E") {
            result = (el.dosageAndTime / 40000) * pooSizeForCalci;
            variable5 = parseFloat(result).toFixed(2);
            drainPressureAcid.push(result);
            drainPresChlorineAcid.push(result);
          } else if (el.name === elname && type === "F") {
            result = (((95 / 20) * el.dosageAndTime) / 40000) * pooSizeForCalci;
            variable6 = parseFloat(result).toFixed(2);
            drainPressureAcid.push(result);
            drainPresChlorineAcid.push(result);
          }
        })
      : null;

    // console.log(result);
    return parseFloat(result).toFixed(2);
  }
  function TotalCalculation(data) {
    // console.log('data', data);
    let res = data.reduce((pre, cur) => pre + cur);
    return parseFloat(res).toFixed(2);
  }

  return (
    <>
      <div className="search-pool-size acid-wash-page">
        <h6>Pool Size</h6>
        <Form.Group controlId="formBasicEmail">
          {Array.isArray(state) ? (
            <>
              {state.map((el, index) =>
                el.name === "Pool Size" ? (
                  <>
                    <Form.Control
                      type="number"
                      onKeyDown={(event) => {
                        if (
                          event.key === "+" ||
                          event.key === "-" ||
                          event.key === "e"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      name="dosageAndTime"
                      value={el.dosageAndTime}
                      onChange={(e) => handleChange(e, index)}
                    />
                    <span>{isUsImperial ? "gallons" : el.unit}</span>
                  </>
                ) : null,
              )}
            </>
          ) : null}
        </Form.Group>
        <Button
          type="submit"
          className="btn btn-primary reload-btn"
          onClick={handleRequest}
        >
          <img src={reload} alt="search" />
        </Button>
      </div>
      <div className="pool-chemistry-box green-pool-calculation drainage-wash-table acid-wash-page">
        {/* <div className="main-title">
      <h6>Green Pool Calculations</h6>
    </div> */}

        <div className="green-pool-table pool-cost-table">
          <h5>
            Cost Calculation for Drain, Pressure Wash Chlorine and Acid Wash
          </h5>
          <div className="pool-color">
            <div className="right-pool-table second-dosage">
              <h6 className="drain-wash-heading">Drain & Pressure Wash</h6>
              <h6 className="drain-wash-heading">
                Drain & Pressure Wash & Chlorine Wash
              </h6>
              <h6 className="drain-wash-heading">
                Drain & Pressure Wash & Acid Wash
              </h6>
              <h6 className="drain-wash-heading">
                Drain & Pressure Wash & Chlorine & Acid Wash
              </h6>
            </div>
          </div>
          <div className="main-table">
            <div className="dosage-part">
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Drain Cost from Pool set-up</p>
                </div>
                <div className="right-pool-table">
                  <p>${drainCost}</p>
                  <p>${drainCost}</p>
                  <p>${drainCost}</p>
                  <p>${drainCost}</p>
                </div>
              </div>
              <hr />
              {/* <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Pressure wash Labour</p>

                </div>
                <div className="right-pool-table">
                  {Array.isArray(state)
                    ? (

                      <>
                        {state.map((el) => (el.name === 'Pressure Wash Hourly Rate'
                          ? (
                            <>
                              <p>
                                $
                                { calcuDrainCosts(el.name, 'A')}
                              </p>
                              <p>
                                $
                                {variable1}
                              </p>
                              <p>
                                $
                                {variable1}
                              </p>
                              <p>
                                $
                                {variable1}
                              </p>
                            </>
                          ) : null))}
                      </>
                    ) : null}
                </div>
              </div>
              <hr /> */}
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>
                    Liquid Chlorine Quantity
                    <span>(litres)</span>
                  </p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state) ? (
                    <>
                      {state.map((el) =>
                        el.name === "Chlorine Wash - Quantity Needed" ? (
                          <>
                            <p>-</p>
                            <p>${calcuDrainCosts(el.name, "B")}</p>
                            <p>-</p>
                            <p>${variable2}</p>
                          </>
                        ) : null,
                      )}
                    </>
                  ) : null}
                </div>
              </div>
              <hr />
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Liquid Chlorine cost</p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state) ? (
                    <>
                      {state.map((el) =>
                        el.name === "Chlorine Wash - Quantity Needed" ? (
                          <>
                            <p>-</p>
                            <p>${calcuDrainCosts(el.name, "C")}</p>
                            <p>-</p>
                            <p>${variable3}</p>
                          </>
                        ) : null,
                      )}
                    </>
                  ) : null}
                </div>
              </div>
              <hr />
              {/* <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Chlorine Cost</p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state)
                    ? (
                      <>
                        {state.map((el) => (el.name === 'Acid Wash Hourly Rate'
                          ? (
                            <>
                              <p>-</p>
                              <p>{ calcuDrainCosts(el.name, 'D')}</p>
                              <p>-</p>
                              <p>{calcuDrainCosts(el.name, 'D')}</p>
                            </>
                          ) : null))}
                      </>
                    ) : null}
                </div>
              </div>
              <hr /> */}
              {/* <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Chlorine Labour</p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state)
                    ? (
                      <>
                        {state.map((el) => (el.name === 'Acid Wash Hourly Rate'
                          ? (

                            <>
                              <p>-</p>
                              <p>
                                $
                                {calcuDrainCosts(el.name, 'D')}
                              </p>
                              <p>-</p>
                              <p>
                                $
                                {variable4}
                              </p>
                            </>

                          ) : null))}
                      </>
                    ) : null}
                </div>
              </div>
              <hr /> */}
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>
                    Acid Quantity
                    <span>(litres)</span>
                  </p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state) ? (
                    <>
                      {state.map((el) =>
                        el.name === "Acid Wash - Quantity Needed" ? (
                          <>
                            <p>-</p>
                            <p>-</p>
                            <p>${calcuDrainCosts(el.name, "E")}</p>

                            <p>${variable5}</p>
                          </>
                        ) : null,
                      )}
                    </>
                  ) : null}
                </div>
              </div>
              <hr />
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Acid Cost</p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state) ? (
                    <>
                      {state.map((el) =>
                        el.name === "Acid Wash - Quantity Needed" ? (
                          <>
                            <p>-</p>
                            <p>-</p>
                            <p>${calcuDrainCosts(el.name, "F")}</p>

                            <p>${variable6}</p>
                          </>
                        ) : null,
                      )}
                    </>
                  ) : null}
                </div>
              </div>
              <hr />
              {/* <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Acid Labour</p>

                </div>
                <div className="right-pool-table">
                  {Array.isArray(state)
                    ? (
                      <>
                        {state.map((el) => (el.name === 'Chlorine Wash Hourly Rate'
                          ? (
                            <>
                              <p>-</p>
                              <p>-</p>
                              <p>
                                $
                                { calcuDrainCosts(el.name, 'G')}
                              </p>

                              <p>
                                $
                                {variable7}
                              </p>
                            </>
                          ) : null))}
                      </>
                    ) : null}
                </div>
              </div>
              <hr /> */}
              {/* <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Remove Debris Labour</p>

                </div>
                <div className="right-pool-table">
                  {Array.isArray(state)
                    ? (
                      <>
                        {state.map((el) => (el.name === 'Removing Debris Hourly Rate'
                          ? (
                            <>

                              <p>
                                $
                                { calcuDrainCosts(el.name, 'H')}
                              </p>

                              <p>
                                $
                                {variable8}
                              </p>
                              <p>
                                $
                                {variable8}
                              </p>
                              <p>
                                $
                                {variable8}
                              </p>
                            </>
                          ) : null))}
                      </>
                    ) : null}
                </div>
              </div> */}
              {/* <hr /> */}
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage bottom-cost-table">
                  <p>Total</p>
                </div>
                <div className="right-pool-table bottom-cost-table">
                  {/* {console.log('drainPressure', drainPressure)} */}
                  <p>${TotalCalculation(drainPressure)}</p>
                  <p>${TotalCalculation(drainPresChlorine)}</p>
                  <p>${TotalCalculation(drainPressureAcid)}</p>
                  <p>${TotalCalculation(drainPresChlorineAcid)}</p>
                </div>
              </div>
              {/* <div className="pool-table-content">
                <div className="left-pool-table second-dosage bottom-cost-table-new">

                  <p>Labour & Margin</p>
                </div>
                <div className="right-pool-table bottom-cost-table-new">
                  <p>
                    $
                    {TotalCalculation(drainPressure) }
                  </p>
                  <p>
                    $
                    {(TotalCalculation(drainPresChlorine) - variable2 - variable3).toFixed(2)}
                  </p>
                  <p>
                    $
                    {(TotalCalculation(drainPressureAcid) - variable5 - variable6).toFixed(2)}
                  </p>
                  <p>
                    $
                    {(TotalCalculation(drainPresChlorineAcid) - variable2 - variable3 - variable5 - variable6).toFixed(2)}
                  </p>
                </div>
              </div> */}

              <hr />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
DrainageCost.propTypes = {
  drainState: PropTypes.func.isRequired,
  state: PropTypes.func.isRequired,
  poolSize: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired,
  isUsImperial: PropTypes.bool.isRequired,
  // drainState: PropTypes.func.isRequired,
};
export default DrainageCost;
