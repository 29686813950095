/* eslint-disable no-restricted-globals */
import React from "react";
import { Modal, Dropdown, Button, Form } from "react-bootstrap";
import { PropTypes } from "prop-types";
import "./index.css";
// import { render } from '@testing-library/react';

function nth(n) {
  return n + ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || `${n}th`;
}

const MultiEditQuantityPopUp = ({
  multiEditQuantityStatus,
  handleChemicalQuantityPopup,
  chemicalArray,
  handleQuantityChange,
  handleSaveUpdate,
  handleReverQuantitytUpdate,
  totalBuyCost,
  totalSellCost,
  consumableMargin,
  levelData,
  checkingDrainStatus,
  pId,
  handleRemove,
  serviceAnalysis,
}) => (
  <Modal
    size="md"
    show={multiEditQuantityStatus}
    onHide={handleChemicalQuantityPopup}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="staff-modal view-appointment-popup edit-chemical-popup edit-chem-popup"
  >
    {/* {console.log('wwwwwww', multiEditQuantityStatus)} */}
    <div className="staff-close">
      <i
        className="fa fa-times"
        onClick={handleChemicalQuantityPopup}
        aria-hidden="true"
      />
    </div>
    <Modal.Header>{serviceAnalysis} Workings</Modal.Header>
    <Modal.Body>
      {chemicalArray
        .filter((ele) => ele?.service[0]?.category !== "drain")
        .map((elem, i) => {
          let whenNotTOShow = elem.service.length > 0;
          let displayMessage = `Edit Chemical Quantity - ${nth(i + 1)} Step`;
          if (chemicalArray.length === 1) {
            displayMessage = "Edit Chemical Quantity";
          }
          if (i === 0 && serviceAnalysis === "Sand Filter Media Replacement") {
            whenNotTOShow = false;
          }
          return whenNotTOShow ? (
            <>
              <h6>{displayMessage}</h6>
              <div className="chemical-table">
                <div className="chemical-table-head">
                  <h6>Quantity</h6>
                  <h6>Buy cost</h6>
                  {/* <h6>Ex Gst</h6> */}
                  <h6>
                    <span className="hide-text">hide text</span>
                  </h6>
                </div>
                {elem?.service?.map((el, index) => {
                  let unit = el.subcategory.split(" ")[2];
                  let chemicalName = el.subcategory.split("of")[1];
                  let showCond =
                    el.subcategory !== "" &&
                    el.type !== "service" &&
                    el.renderCost !== "N/A";
                  let showOf = "";
                  if (el?.subcategory?.includes(" of ")) {
                    showOf = "of";
                  }

                  return (
                    <div key={el.category} className="chemical-table-body">
                      {showCond && (
                        <>
                          <h6>
                            Add
                            <input
                              type="text"
                              value={el.quantity}
                              name="quantity"
                              onChange={(e) =>
                                handleQuantityChange(
                                  e,
                                  index,
                                  elem.numericValue,
                                )
                              }
                            />
                            {el?.chemicalUnits} {showOf}{" "}
                            {el?.chemicalDisplayName}
                          </h6>
                          <h6>
                            $<span>{el.buyCostProduct}</span>
                          </h6>
                          {/* <h6>
                        $
                        <span>{el.renderCost}</span>
                      </h6> */}
                          <h6 className="edit-chemical-action">
                            <i
                              className="fa fa-times"
                              onClick={() =>
                                handleRemove(index, elem.numericValue)
                              }
                            />
                          </h6>
                        </>
                      )}
                    </div>
                  );
                })}

                <div className="chemical-table-body subtotal">
                  <h6>Sub total</h6>
                  <h6>
                    $<span>{totalBuyCost(elem?.service, "multi")}</span>
                  </h6>
                  <h6>
                    <span className="hide-text">hide text</span>{" "}
                  </h6>
                </div>
              </div>
              {/* <div className="bottom-text">
              <p>{`Consumable Margin: $${consumableMargin(elem?.service, 'multi')}`}</p>
            </div> */}
            </>
          ) : null;
        })}
      <div className="view-btn">
        <Button
          type="Submit"
          className="btn btn-edit revert-desktop"
          onClick={handleReverQuantitytUpdate}
        >
          {" "}
          Revert to Original{" "}
        </Button>
        <Button
          type="Submit"
          className="btn btn-save revert-desktop"
          onClick={handleSaveUpdate}
        >
          {" "}
          Save/Update{" "}
        </Button>
      </div>
    </Modal.Body>
  </Modal>
);
MultiEditQuantityPopUp.propTypes = {
  multiEditQuantityStatus: PropTypes.func.isRequired,
  handleChemicalQuantityPopup: PropTypes.func.isRequired,
  chemicalArray: PropTypes.arrayOf.isRequired,
  handleQuantityChange: PropTypes.func.isRequired,
  handleReverQuantitytUpdate: PropTypes.func.isRequired,
  handleSaveUpdate: PropTypes.func.isRequired,
  totalBuyCost: PropTypes.func.isRequired,
  totalSellCost: PropTypes.func.isRequired,
  consumableMargin: PropTypes.func.isRequired,
  levelData: PropTypes.arrayOf.isRequired,
  checkingDrainStatus: PropTypes.func.isRequired,
  pId: PropTypes.number.isRequired,
  handleRemove: PropTypes.func.isRequired,
  serviceAnalysis: PropTypes.string.isRequired,
};

export default MultiEditQuantityPopUp;
