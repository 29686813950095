/* eslint-disable react/prop-types */
/* eslint-disable jsx-quotes */
import React from "react";
import { Modal } from "react-bootstrap";

const SuccessPopup = ({
  openPaymentForm,
  handleAction,
  getUserStatus,
  updatedInvoiceUrl,
}) => {
  console.log("sdd===>");
  return (
    <div>
      <div className="staff-modal1 BillSummary1 sucessPopup1">
        <Modal show={openPaymentForm} className="billSummary1 sucessPopup">
          <div className="back-popup crossBtn" onClick={handleAction}>
            <i className="las la-times" />
          </div>
          <Modal.Body>
            <div className="d-flex justify-content-center h-100 align-items-center">
              <div className="text-success text-center">
                <h6>Successfully Payment Updated</h6>
                <a
                  href={updatedInvoiceUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: "inline-block",
                    padding: "12px 24px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#fff",
                    backgroundColor: "#007bff",
                    border: "none",
                    borderRadius: "4px",
                    textAlign: "center",
                    textDecoration: "none",
                    transition:
                      "background-color 0.3s ease, transform 0.2s ease",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = "#0056b3"; // Darker on hover
                    e.currentTarget.style.transform = "translateY(-2px)"; // Lift effect
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "#007bff"; // Reset color
                    e.currentTarget.style.transform = "translateY(0)"; // Reset lift effect
                  }}
                  onMouseDown={(e) => {
                    e.currentTarget.style.backgroundColor = "#004085"; // Darker on click
                    e.currentTarget.style.transform = "translateY(0)"; // Reset lift effect
                  }}
                  onMouseUp={(e) => {
                    e.currentTarget.style.backgroundColor = "#0056b3"; // Reset hover color
                    e.currentTarget.style.transform = "translateY(-2px)"; // Reapply lift effect
                  }}
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default link behavior
                    window.open(updatedInvoiceUrl, "_blank"); // Open link in new tab
                    handleAction(); // Close the modal without redirecting the current page
                    getUserStatus();
                  }}
                >
                  Click here to complete your payment
                </a>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default SuccessPopup;
