import React from "react";
import "./noData.css";
import PropTypes from "prop-types";

const NoData = ({ heading }) => (
  <div className="no-data">
    <h6>{heading || "No Data"}</h6>
  </div>
);

NoData.propTypes = {
  heading: PropTypes.string.isRequired,
};
export default NoData;
