import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import moment from "../../functions/moment";
import { userInstance } from "../../config/axios";
import StartEndDate from "./startEndDate";

import { filterProductChemicals } from "../../functions/index";

const PurchasesSummary = () => {
  const [dateRange, setDateRange] = useState("today");
  const [customDateRange, setcustomDateRange] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [productPurchases, setproductPurchases] = useState("allProducts");
  const [inventoryProducts, setinventoryProducts] = useState(null);
  const [inventoryProductIds, setinventoryProductIds] = useState([]);
  const [listingData, setListingData] = useState({
    totalPrice: 0,
    totalQuantity: 0,
    inventoryHistoryArray: [],
  });
  const [purchaseummaryDate, setpurchaseummaryDate] = useState();

  const [singleProduct, setSingleProduct] = useState(null);
  const [datePopup, setDatePopup] = useState();

  const calculateDateToDisplay = (value) => {
    if (dateRange === "today") {
      return "Today";
    }
    if (dateRange === "yesterday") {
      return "Yesterday";
    }
    if (dateRange === "tomorrow") {
      return "Tomorrow";
    }

    return value;
  };

  const handlecustomDateRange = (start, end) => {
    setDatePopup(false);
    handleCustomDataFilter(start, end);
  };

  const handleCustomDataFilter = async (start, end) => {
    const payload = filterProductChemicals({
      datetype: dateRange,
      rangeStartDate: start,
      rangeEndDate: end,
    });
    setpurchaseummaryDate(payload?.summaryDate);
    let data = singleProduct === null ? inventoryProductIds : singleProduct;

    const response = await userInstance().post(
      "/inventory/getInventoryListHistoryonReports",
      { payload, idToSearch: data },
    );
    // console.log('this is the data', response);
    if (response.data.code === 200) {
      let {
        totalPrice = 0,
        totalQuantity = 0,
        inventoryHistoryArray = [],
      } = response.data.results || {};
      setListingData({ totalPrice, totalQuantity, inventoryHistoryArray });
    }
  };

  const handleDateRange = (e) => {
    setDateRange(e.target.value);
    let valDateRange = e.target.value;
    getSpecifcProductData(singleProduct, valDateRange);
  };
  const handleDatePopup = () => {
    setDatePopup(!datePopup);
  };
  const handleDateOnchange = (value, type) => {
    let copycustomDateRange = { ...customDateRange };
    if (type === "start" && new Date(value) >= customDateRange.end) {
      copycustomDateRange.end = new Date(value);
    }
    copycustomDateRange[type] = new Date(value);

    setcustomDateRange(copycustomDateRange);

    // console.log(customDateRange.start, customDateRange.end);
    // setcustomDateRange({ ...customDateRange, [type]: new Date(value) });
  };
  const dateSelection = [
    { key: "Today", value: "today" },
    { key: "Yesterday", value: "yesterday" },
    { key: "Tomorrow", value: "tomorrow" },
    { key: "This Week", value: "week" },
    { key: "This Month", value: "month" },
    { key: "This Year", value: "year" },
    { key: "Last Week", value: "lastweek" },
    { key: "Last Year", value: "lastyear" },
    { key: "Next Week", value: "nextweek" },
    { key: "Next Year", value: "nextyear" },
    { key: "Week to Date", value: "weekdate" },
    { key: "Month to Date", value: "monthdate" },
    { key: "Year to Date", value: "yeardate" },
    { key: "Date Range", value: "daterange" },
  ];

  const getSpecifcProductData = async (data, date_Range) => {
    try {
      let rangeStartDate = null;
      let rangeEndDate = null;
      if (date_Range === "daterange") {
        rangeStartDate = customDateRange.start;
        rangeEndDate = customDateRange.end;
      }
      const payload = filterProductChemicals({
        datetype: date_Range,
        rangeStartDate,
        rangeEndDate,
      });
      setpurchaseummaryDate(payload?.summaryDate);
      const response = await userInstance().post(
        "/inventory/getInventoryListHistoryonReports",
        { payload, idToSearch: data },
      );
      // console.log('this is the data', response);
      if (response.data.code === 200) {
        let {
          totalPrice = 0,
          totalQuantity = 0,
          inventoryHistoryArray = [],
        } = response.data.results || {};
        setListingData({ totalPrice, totalQuantity, inventoryHistoryArray });
      }
    } catch (error) {
      console.log("error=>", error);
    }
  };

  const selectedproductData = (e) => {
    let id = e.target.value;
    const findProduct = inventoryProducts.find((el) => el._id === id);
    setSingleProduct(findProduct?._id);
    getSpecifcProductData([findProduct?._id], dateRange);
  };

  const handleProductPurchases = (e) => {
    let { value } = e.target;
    if (value === "specificProduct") {
      setSingleProduct(inventoryProducts[0]?._id);
      setproductPurchases(value);
      getSpecifcProductData([inventoryProducts[0]?._id], dateRange);
    } else if (value === "allProducts") {
      setSingleProduct(null);
      setproductPurchases(value);
      getSpecifcProductData(inventoryProductIds, dateRange);
    }
  };

  const getProductListing = async () => {
    try {
      const payload = filterProductChemicals({
        datetype: dateRange,
        rangeStartDate: null,
        rangeEndDate: null,
      });
      setpurchaseummaryDate(payload?.summaryDate);
      const response = await userInstance().post(
        "/inventory/getInventoryListOnReports",
        { payload },
      );
      // console.log('this is the data', response.data);
      if (
        response.data.results.inventoryData.length > 0 &&
        response.data.code === 200
      ) {
        let {
          totalPrice = 0,
          totalQuantity = 0,
          inventoryHistoryArray = [],
        } = response.data.results || {};
        setListingData({ totalPrice, totalQuantity, inventoryHistoryArray });
        setinventoryProductIds(response.data.results.inventoryIds || []);
        setinventoryProducts(response.data.results.inventoryData || []);
      } else {
        setinventoryProducts([]);
        setinventoryProductIds([]);
      }
    } catch (error) {
      console.log("error=>", error);
    }
  };

  useEffect(() => {
    getProductListing();
  }, []);

  // console.log('this is the listing Data', listingData);
  // console.log('this is the single Product', singleProduct);
  // console.log('these are the prodcut purchases', productPurchases);
  // console.log('these are the inventoryProducts', inventoryProducts);
  // console.log('this is the inventory product ids', inventoryProductIds);
  return (
    <div className="appointment-summary">
      <div
        className={`${productPurchases === "specificProduct" ? "product-purchases" : ""}`}
      >
        <Form.Group controlId="formBasicCompanyType">
          <Form.Label>Product Purchases</Form.Label>
          <div className="select-box">
            <Form.Control
              as="select"
              value={productPurchases}
              onChange={(e) => handleProductPurchases(e)}
            >
              <option value="allProducts">All Products</option>
              <option value="specificProduct">Specific Products</option>
            </Form.Control>
          </div>
        </Form.Group>
        {productPurchases === "specificProduct" && (
          <Form.Group controlId="formBasicCompanyType">
            <div className="select-box">
              <Form.Control
                as="select"
                value={singleProduct}
                onChange={(e) => selectedproductData(e)}
              >
                {inventoryProducts.map((ele) => (
                  <option value={ele._id}>{ele.name}</option>
                ))}
              </Form.Control>
            </div>
          </Form.Group>
        )}
      </div>
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Date Range</Form.Label>
        <div className="select-box">
          <Form.Control
            as="select"
            value={dateRange}
            onChange={(e) => handleDateRange(e)}
          >
            {dateSelection.map((ele) => (
              <>
                <option value={ele.value}>{ele.key}</option>
              </>
            ))}
          </Form.Control>
        </div>
      </Form.Group>
      {dateRange === "daterange" ? (
        <div className="start-date-section">
          <Form.Group className="search-box">
            <div className="custom-datepicker" onClick={handleDatePopup}>
              {`${moment(customDateRange.start).format("ddd DD MMM yyyy")}`}
            </div>
          </Form.Group>
          <span>to</span>
          <Form.Group className="search-box">
            <div className="custom-datepicker">
              {" "}
              {`${moment(customDateRange.end).format("ddd DD MMM yyyy")}`}
            </div>
          </Form.Group>
        </div>
      ) : (
        ""
      )}

      <div className="appointment-summary-section">
        <p>Purchases Summary</p>
        <p>{calculateDateToDisplay(purchaseummaryDate)}</p>
        <hr />
        <div className="summary-table">
          <div className="summary-table-header">
            <h6>Name</h6>
            <h6>Price</h6>
            <h6>Quantity</h6>
            {/* <h6>Total Sales</h6> */}
          </div>
          {listingData.inventoryHistoryArray.length > 0 ? (
            listingData.inventoryHistoryArray.map((el, index) => {
              const { name, price, quantity } = el || {};
              return (
                <div className="summary-table-body">
                  <p> {name}</p>
                  <p>{price}</p>
                  <p>{quantity}</p>
                </div>
              );
            })
          ) : (
            <div>
              <p>No Records Found</p>
            </div>
          )}
          <hr />
          <div className="summary-table-body total-section">
            <p>Period Total:</p>
            <p />
            <p>{`$${listingData.totalPrice.toFixed(2)}`}</p>
            <p>{`$${listingData.totalQuantity.toFixed(2)}`}</p>
          </div>
        </div>
      </div>
      <StartEndDate
        datePopup={datePopup}
        setDatePopup={setDatePopup}
        handleDatePopup={handleDatePopup}
        handleDateOnchange={handleDateOnchange}
        dateRange={customDateRange}
        handleDateRange={handlecustomDateRange}
      />
    </div>
  );
};
export default PurchasesSummary;
