export const calculatingPoolWaterCapacity = (
  original,
  current,
  saltBags,
  type,
) => {
  let poolWaterCapacity = 0;
  const actualSaltLvel = current - original;
  poolWaterCapacity =
    actualSaltLvel && saltBags
      ? type === "Salt Pool"
        ? Math.floor(((40000 / actualSaltLvel) * saltBags * 500) / 1000) * 1000
        : Math.floor(((40000 / actualSaltLvel) * saltBags * 187.98) / 1000) *
          1000
      : 0;
  return poolWaterCapacity;
};

export const unitFinder = (quantity, unit) => {
  let chemicalUnit = "";
  if (unit === "kg") {
    chemicalUnit = quantity >= 1 ? unit : "grams";
  } else {
    chemicalUnit = quantity >= 1 ? unit : "ml";
  }
  return chemicalUnit;
};

const calciMM = (drPer, width, heigth, cap, diameter) => {
  console.log(drPer, width, heigth, cap);
  const convertIntoNormal = (drPer / 100) * cap;
  const vval = convertIntoNormal / ((heigth || diameter) * (width || diameter));
  console.log("vvvv", vval);
  return parseInt(vval, 10);
};

export const getUnit = (val) => {
  const inGrams = Math.ceil(Math.trunc(val * 1000) / 100) * 100;
  const data = val < 1 ? (inGrams === 1000 ? "kg" : "grams") : "kg";
  return data;
};

export const getBufferUnit = (val) => {
  const inGrams = (Math.trunc(val * 1000) / 100) * 100;
  const data = val < 1 ? (inGrams === 1000 ? "kg" : "grams") : "kg";
  return data;
};
export const chemicalValueAdjustment = (val) => {
  let dataChem = parseFloat(val);
  let actualOutput = val;

  if (dataChem < 1) {
    actualOutput = (Math.trunc(val * 1000) / 100) * 100;
  } else if (val.toString().includes(".")) {
    actualOutput = dataChem.toFixed(1);
  } else {
    actualOutput = val;
  }
  return actualOutput;
};

export const phosPhateVaueAdjustment = (val, unit) => {
  if (val >= 1 && unit === "litres") {
    return parseFloat(val).toFixed(1);
  }
  return val;
};

export const chemicalUnitAdjustment = (val, unit) => {
  if (parseFloat(val) === 1) {
    return "litre";
  }
  return unit;
};
export const chemicalUnitkilogramAdjustment = (val, unit) => {
  if (parseFloat(val) === 1) {
    return "kg";
  }
  return unit === "litres" ? "kg" : "grams";
};
const getDrainCalciumMessage = (sixPadStrip, disSalt, messg, calcInput) => {
  let msg = messg;
  if (!sixPadStrip && !disSalt) {
    msg = `Requires Salt ${!calcInput ? "& Calcium" : ""} reading to display drain %`;
  } else if (!sixPadStrip && disSalt && !calcInput) {
    msg = "Requires Calcium reading to display drain %";
  } else if (sixPadStrip && !disSalt) {
    msg = "Requires Salt reading to display drain %";
  }
  return msg;
};

export const flRd = (value, valueType) =>
  valueType ? value : Math.ceil(value);
const getUnitInImperial = (balName, unit) =>
  balName === "Dry Acid" ? (unit === "grams" ? "oz" : "lb") : "fl oz";

// stripSameFunction
export const stripMessageReturn = (
  chlorinationType,
  greenSplitName,
  sixPadStrip,
  disSalt,
  isDrainage,
  calcInput,
) => {
  let message = "";
  const statusResult = greenSplitName === "Part" || isDrainage;
  if (!statusResult) {
    return message;
  }

  if (
    chlorinationType.includes("Salt Pool") ||
    chlorinationType === "Mineral Pool"
  ) {
    let midMessage = !calcInput ? "Calcium" : "";
    midMessage = !disSalt
      ? midMessage
        ? `${midMessage} & Salt`
        : "Salt"
      : midMessage;

    if (!sixPadStrip && chlorinationType === "Mineral Pool") {
      midMessage = "";
    }

    if (midMessage)
      message = `Requires ${midMessage} reading to display dosage`;
    if (isDrainage)
      message = getDrainCalciumMessage(
        sixPadStrip,
        disSalt,
        message,
        calcInput,
      );
  } else if (
    chlorinationType === "Manually Chlorinated" &&
    !sixPadStrip &&
    greenSplitName === "Part" &&
    !calcInput
  )
    message = "Requires Calcium Reading to display dosage";
  return message;
};
export const getPhosphateMsg = (
  phosphateRemover,
  withDrainage,
  phosphateStatus,
  differences,
  balancing,
  valueType,
  phosUnit,
  calError,
  stripExist,
  sixPadStrip,
  isInput,
  chlorinationType,
  greenSplitName,
  disSalt,
  isDrainage,
) => {
  const {
    passDiffStab,
    passDiffHard,
    passDiffSalt,
    passDiffChlorine,
    passDiffCopper,
  } = differences;
  let phosphateMsg = "";

  let renderPhosphate = phosphateRemover;
  let renderPhosphateUnit = phosUnit;

  if (balancing) {
    phosphateMsg = "Not chemicals being added";
  } else if (phosphateStatus) {
    phosphateMsg = "Re-test 2 weeks after Copper treatment";
  } else if (phosphateRemover) {
    if (phosUnit === "ml" && valueType) {
      phosphateRemover *= 1000;
    }

    // phosphateRemover = flRd(phosphateRemover);

    const actualUnit = valueType ? phosUnit : "fl oz";
    renderPhosphate = phosPhateVaueAdjustment(phosphateRemover, actualUnit);
    renderPhosphateUnit = valueType
      ? chemicalUnitAdjustment(phosphateRemover, actualUnit)
      : "fl oz";
    phosphateMsg = `Add ${renderPhosphate} ${renderPhosphateUnit} of Zodiac Phosphate Remover`;
  } else {
    phosphateMsg = "OK";
  }

  if (stripExist && !isInput) {
    phosphateMsg = "Phosphates not tested";
  }

  if (sixPadStrip) {
    // phosphateMsg = stripMessageReturn(chlorinationType, greenSplitName, sixPadStrip, disSalt, phosphateMsg, isDrainage);
  }

  if (calError) {
    phosphateMsg = calError;
  }

  return {
    phosphateMsg,
    renderPhosphate: phosphateRemover,
    renderPhosphateUnit,
    renderPhosphateUnique: "Phosphates",
  };
};

export const maxAchieve = (drainObj) => {
  let flag = 0;
  let container = [];
  let topName = "";
  let drainSameMsg = "";
  let nameObj = {};
  nameObj["Combined Chlorine"] = drainObj.drainWaterChorine;
  nameObj["Calcium Hardness"] = drainObj.drainWaterHardness;
  nameObj.Stabiliser = drainObj.drainWater;
  nameObj.Copper = drainObj.drainWaterCopper;
  nameObj.Salt = drainObj.drainWaterSalt;

  Object.keys(nameObj).forEach((el) => {
    if (!nameObj[el]) {
      delete nameObj[el];
    } else if (flag === drainObj.maxDrain) {
      container.push(el); // container[salt]
      drainSameMsg = `Drain same % as ${topName} `;
    } else {
      flag = nameObj[el]; // container['']
      topName = el;
    }
    return false;
  });
  return { container, drainSameMsg };
};

// const getMaxValName = (calVariable) => {
//   let name = '';
//   , calVariable);
//   const {
//     maxDrain, drainWater, drainWaterChorine, drainWaterHardness, drainWaterSalt, drainWaterCopper,
//   } = calVariable;
//   console.log('maxDrain, drainWater, drainWaterChorine, drainWaterHardness, drainWaterSalt, drainWaterCopper,', maxDrain, drainWater, drainWaterChorine, drainWaterHardness, drainWaterSalt, drainWaterCopper);
//   if (maxDrain === drainWaterChorine) {
//     name = 'combined Chlorine';
//   } else if (maxDrain === drainWaterHardness
//     && maxDrain !== drainWaterChorine) {
//     name = 'Calcium Hardness';
//   } else if (
//     maxDrain === drainWater
//     && maxDrain !== drainWaterHardness
//   ) {
//     name = 'Stabiliser';
//   } else if (
//     maxDrain === drainWaterCopper
//       && maxDrain !== drainWater
//       && maxDrain !== drainWaterHardness
//   ) {
//     name = 'copper';
//   } else if (
//     maxDrain === drainWaterSalt
//     && maxDrain !== drainWaterHardness
//       && maxDrain !== drainWater
//       && maxDrain !== drainWaterCopper
//   ) {
//     name = 'Salt';
//   }
//   if (name) {
//     return `Drain same % as ${name}`;
//   }
//   return '';
// };

export const getCopperMsg = (
  copperQuantity,
  passDiffCopper,
  drainObj,
  container,
  drainSameMsg,
  valueType,
  copperUnit,
  calError,
  stripExist,
  isInput,
) => {
  const {
    maxDrain,
    drainWaterCopper,
    withDrainage,
    poolWidth,
    poolHeight,
    poolCapacity,
    diameter,
    balancing,
  } = drainObj;
  let copperMsg = "";
  let renderCopper = copperQuantity;
  let renderCopperUnit = copperUnit;
  if (balancing && !drainWaterCopper) {
    copperMsg = "Not chemicals being added";
  } else if (copperQuantity) {
    if (copperUnit === "ml" && valueType) {
      copperQuantity *= 1000;
    }

    const actualUnit = valueType ? copperUnit : "fl oz";
    renderCopper = chemicalValueAdjustment(copperQuantity);
    // renderCopper = flRd(renderCopper);
    renderCopperUnit = actualUnit;
    copperMsg = `Add ${renderCopper} ${actualUnit} of Zodiac Metal Reducer`;
  } else if (drainWaterCopper || maxDrain === drainWaterCopper) {
    // console.log('comingUnderDrainCondition');
    if (container.includes("Copper")) {
      copperMsg = withDrainage
        ? "Re-calculate to display drain % and cost"
        : drainSameMsg;
    } else if (passDiffCopper && maxDrain === drainWaterCopper) {
      const mm = calciMM(
        drainWaterCopper,
        poolWidth,
        poolHeight,
        poolCapacity,
        diameter,
      );
      let drMsg =
        withDrainage && !balancing
          ? "Re-calculate for drain % and cost"
          : `drain pool ${drainWaterCopper}% (= minus ${mm}${valueType ? "mm" : "inches"})`;
      copperMsg = `High Copper - ${drMsg}`;
    } else if (maxDrain !== drainWaterCopper && withDrainage) {
      copperMsg = "Re-calculate to display drain % and cost";
    } else {
      copperMsg = "OK";
    }
  } else {
    copperMsg = "OK";
  }

  // console.log('isInput', stripExist, isInput);
  if (stripExist && !isInput) {
    copperMsg = "Copper not tested";
  }

  // if (sixPadStrip) {
  //   // copperMsg = stripMessageReturn(chlorinationType, greenSplitName, sixPadStrip, disSalt, copperMsg, isDrainage);
  // }
  if (calError) {
    copperMsg = calError;
  }

  return { copperMsg, renderCopper, renderCopperUnit };
};

export const getCombinedChlorineMessage = (
  zodiacOxy,
  passDiffChlorine,
  drainObj,
  container,
  drainSameMsg,
  valueType,
  zodiacUnit,
  chlorinationType,
  stripExist,
  sixPadStrip,
  greenSplitName,
  disSalt,
  disCalcium,
  calError,
  isDrainage,
  isInput,
  combinedChlorineName,
) => {
  const {
    maxDrain,
    drainWaterChorine,
    withDrainage,
    poolWidth,
    poolHeight,
    poolCapacity,
    diameter,
    balancing,
  } = drainObj;

  let combinedChlorineMsg = "";
  let renderCombined = zodiacOxy;
  let renderCombinedUnit = zodiacUnit;
  if (balancing && !drainWaterChorine) {
    combinedChlorineMsg = "Not chemicals being added";
  } else if ((!drainWaterChorine || withDrainage) && zodiacOxy > 0) {
    if (zodiacUnit === "grams") {
      zodiacOxy *= 1000;
    }
    renderCombined = zodiacOxy;
    // const { unit, chemicalVal } = getInGrams(zodiacOxy);
    console.log("sadsadgffd==?>?????", valueType);
    combinedChlorineMsg = `Add ${zodiacOxy} ${zodiacUnit} of ${combinedChlorineName}`;
    if (!valueType) {
      renderCombinedUnit = zodiacUnit === "grams" ? "oz" : "lb";
      combinedChlorineMsg = `Add ${zodiacOxy} ${renderCombinedUnit} of ${combinedChlorineName}`;
      // if (zodiacOxy.toString().includes('.') && zodiacOxy > 1) {
      //   const splitValue = zodiacOxy.split('.');
      //   const childUnit = splitValue[1] !== '0' ? `${splitValue[1]} oz` : '';
      //   combinedChlorineMsg = `Add ${splitValue[0]} lb ${childUnit} of ${combinedChlorineName}`;
      //   renderCombinedUnit = 'lb';
      // }
    }
  } else if (drainWaterChorine || maxDrain === drainWaterChorine) {
    if (container.includes("Combined Chlorine")) {
      combinedChlorineMsg = withDrainage
        ? "Re-calculate to display drain % and cost"
        : drainSameMsg;
    } else if (passDiffChlorine && maxDrain === drainWaterChorine) {
      const mm = calciMM(
        drainWaterChorine,
        poolWidth,
        poolHeight,
        poolCapacity,
        diameter,
      );
      let drMsg =
        withDrainage && !balancing
          ? "Re-calculate for drain % and cost"
          : `drain pool ${drainWaterChorine}% (= minus ${mm}${valueType ? "mm" : "inches"})`;
      combinedChlorineMsg = `High Chlorine - ${drMsg}`;
    } else if (maxDrain !== drainWaterChorine && withDrainage) {
      combinedChlorineMsg = "Re-calculate to display drain % and cost";
    } else {
      combinedChlorineMsg = "OK";
    }
  } else {
    combinedChlorineMsg = "OK";
  }

  if (stripExist) {
    if (!isInput && !sixPadStrip) {
      combinedChlorineMsg =
        "Use Aquachek 7 test-strips to check Total Chlorine";
    }
  }

  if (calError) {
    combinedChlorineMsg = calError;
  }

  return {
    combinedChlorineMsg,
    renderCombined,
    renderCombinedUnit,
    renderCombinedUnique: "Potassium",
  };
};

export const getSaltMsg = (
  addSalt,
  differences,
  drainObj,
  selectedModel,
  bagsSalt,
  poolInputs,
  salt,
  container,
  drainSameMsg,
  valueType,
  chlorinationType,
  stripExist,
  sixPadStrip,
  greenSplitName,
  disSalt,
  calError,
  isDrainage,
) => {
  const {
    maxDrain,
    drainWater,
    drainWaterSalt,
    withDrainage,
    poolWidth,
    poolHeight,
    poolCapacity,
    diameter,
    balancing,
  } = drainObj;
  const { passDiffSalt } = differences;
  let saltMsg = "";
  let renderSalt = addSalt;
  let renderSaltName = "";
  let renderSaltUnit = "";
  if (balancing && !drainWaterSalt) {
    saltMsg = "Not chemicals being added";
  } else if (addSalt) {
    let addSaltVal = addSalt.toFixed(1);
    if ((addSalt * 10.0) % 10 === 0) {
      addSaltVal = parseInt(addSalt, 10);
      renderSalt = addSaltVal;
      renderSaltUnit = addSaltVal > 1 ? "bags" : "bag";
    }
    renderSaltName = `${bagsSalt} ${
      valueType
        ? selectedModel === "Aquatherepe Minerals" ||
          selectedModel === "Aquatherepe Minerals - With ORP Probe" ||
          selectedModel === "Magna Minerals" ||
          selectedModel === "Magna Minerals - With ORP Probe" ||
          selectedModel === "Aquatherepe Transform"
          ? "(10 kg)"
          : "(20 kg)"
        : "(40 lb)"
    }`;
    saltMsg = `Add ${addSaltVal} ${renderSaltUnit} of ${renderSaltName}`;
  } else if (drainWaterSalt || maxDrain === drainWaterSalt) {
    if (container.includes("Salt")) {
      saltMsg = withDrainage
        ? "Re-calculate to display drain % and cost"
        : drainSameMsg;
    } else if (
      passDiffSalt &&
      maxDrain === drainWaterSalt &&
      maxDrain !== drainWater
    ) {
      const mm = calciMM(
        parseInt(drainWaterSalt, 10),
        poolWidth,
        poolHeight,
        poolCapacity,
        diameter,
      );
      let drMsg =
        withDrainage && !balancing
          ? "Re-calculate for drain % and cost"
          : `drain pool ${parseInt(drainWaterSalt, 10)}% (= minus ${mm}${valueType ? "mm" : "inches"})`;
      saltMsg = `High Salt - ${drMsg}`;
    } else if (maxDrain !== drainWaterSalt && withDrainage) {
      saltMsg = "Re-calculate to display drain % and cost";
    } else {
      saltMsg = "OK";
    }
  } else if (
    salt === parseFloat(poolInputs.salt) ||
    (!drainWaterSalt && !addSalt)
  ) {
    saltMsg = "OK";
  }

  if (stripExist) {
    if (
      chlorinationType === "Salt Pool" ||
      chlorinationType === "Convert Salt Pool to Mineral Pool"
    ) {
      if (greenSplitName !== "Part") {
        if (!disSalt) {
          saltMsg = "Salt not tested";
        }
      } else if (greenSplitName === "Part") {
        if (!sixPadStrip) {
          if (!disSalt) {
            saltMsg = "Requires Calcium & Salt readings to display dosage";
          } else {
            saltMsg = "Requires Calcium reading to display dosage";
          }
        } else if (sixPadStrip) {
          if (!disSalt) {
            saltMsg = "Requires Salt reading to display dosage";
          }
        }
      }
      // if (isDrainage) {
      //   if (!sixPadStrip && !disSalt) {
      //     saltMsg = 'Requires Salt & Calcium reading to display drain %';
      //   } else if (!sixPadStrip && disSalt) {
      //     saltMsg = 'Requires Calcium reading to display drain %';
      //   } else if (sixPadStrip && !disSalt) {
      //     saltMsg = 'Requires Salt reading to display drain %';
      //   }
      // }
    } else if (chlorinationType === "Mineral Pool") {
      if (!disSalt) {
        saltMsg = "Mineral Salt not tested";
        if (isDrainage) {
          saltMsg = "Requires Salt & Calcium reading to display drain %";
        }
      }

      if (greenSplitName === "Part" || isDrainage) {
        if (sixPadStrip) {
          if (!disSalt) {
            saltMsg = "Requires Salt reading to display dosage";
            if (isDrainage) {
              saltMsg = "Requires Salt reading to display drain %";
            }
          }
        } else if (!sixPadStrip) {
          if (isDrainage && !disSalt) {
            saltMsg = "Requires Salt & Calcium reading to display drain %";
          }
        }
      }
      // if (isDrainage) {
      //   if (!sixPadStrip && !disSalt) {
      //     saltMsg = 'Requires Salt & Calcium reading to display drain %';
      //   } else if (!sixPadStrip && disSalt) {
      //     saltMsg = 'Requires Calcium reading to display drain %';
      //   } else if (sixPadStrip && !disSalt) {
      //     saltMsg = 'Requires Salt reading to display drain %';
      //   }
      // }
    } else if (chlorinationType === "Calcium Hardness Titration Test") {
      if (sixPadStrip) {
        if (!disSalt) {
          saltMsg = "Mineral Salt not tested";
        }
      } else if (!sixPadStrip) {
        if (!disSalt) {
          saltMsg = "Mineral Salt not tested";
        }
      }
    }
  }

  if (calError) {
    saltMsg = calError;
  }

  let renderSaltUnique = "";
  if (renderSaltName.includes("Salt")) {
    renderSaltUnique = "Salt";
  } else if (renderSaltName?.includes("Magna Minerals")) {
    renderSaltUnique = "Magna";
  } else if (renderSaltName?.includes("Aquatherepe Minerals")) {
    renderSaltUnique = "Aqua";
  } else if (renderSaltName?.includes("Aquatherepe Transform")) {
    renderSaltUnique = "Convert";
  }

  return {
    saltMsg,
    renderSalt,
    renderSaltName,
    renderSaltUnit,
    renderSaltUnique,
  };
};

export const getCalciumMsg = (
  calciumRaiser,
  differences,
  drainObj,
  poolInputs,
  calciumHardness,
  container,
  drainSameMsg,
  calciumReducer,
  chemicalBalanceWaterObj,
  valueType,
  calciumUnit,
  calReducerUnit,
  disSalt,
  chlorinationType,
  calciumMsgDisplayStatus,
  stripExist,
  sixPadStrip,
  greenSplitName,
  isDrainage,
  showFullHardness,
  blankInputFieldCalcium,
  showCalciumStatus,
  calError,
  manualValue,
  isInput,
) => {
  const {
    maxDrain,
    drainWaterHardness,
    withDrainage,
    poolWidth,
    poolHeight,
    poolCapacity,
    diameter,
    balancing,
  } = drainObj;

  const { passDiffHard } = differences;
  const drainStatus = passDiffHard && maxDrain === drainWaterHardness;
  let calciumHardnessMsg = "";
  let renderCalcium = calciumRaiser;
  let renderCalciumName = "";
  let renderCalciumUnit = calciumUnit;
  if (balancing && !drainWaterHardness) {
    calciumHardnessMsg = "Not chemicals being added";
  } else if (calciumRaiser) {
    if (calciumUnit === "grams") {
      calciumRaiser *= 1000;
    }
    renderCalcium = calciumRaiser;
    renderCalciumName = "Calcium Raiser";
    renderCalciumUnit = valueType ? calciumUnit : "oz";
    calciumHardnessMsg = `Add ${calciumRaiser} ${renderCalciumUnit} of Calcium Raiser`;
    if (!valueType) {
      renderCalciumUnit = calciumUnit === "grams" ? "oz" : "lb";
      calciumHardnessMsg = `Add ${calciumRaiser} ${renderCalciumUnit} of Calcium Raiser`;
      // if (calciumRaiser.toString().includes('.') && calciumRaiser > 1) {
      //   const splitValue = calciumRaiser.split('.');
      //   const childUnit = splitValue[1] !== '0' ? `${splitValue[1]} oz` : '';
      //   calciumHardnessMsg = `Add ${splitValue[0]} lb ${childUnit} of Calcium Raiser`;
      //   renderCalciumUnit = 'lb';
      // }
    }
  } else if (calciumReducer) {
    if (calReducerUnit === "ml" && valueType) {
      calciumReducer *= 1000;
    }
    renderCalcium = calciumReducer;
    renderCalciumName = chemicalBalanceWaterObj?.totalhardnessbalanceName;
    renderCalciumUnit = valueType ? calReducerUnit : "oz";
    calciumHardnessMsg = `Add ${calciumReducer} ${renderCalciumUnit} ${renderCalciumName}`;
  } else if (drainWaterHardness || maxDrain === drainWaterHardness) {
    if (container.includes("Calcium Raiser")) {
      calciumHardness = withDrainage
        ? "Re-calculate to display drain % and cost"
        : drainSameMsg;
    } else if (drainStatus) {
      const mm = calciMM(
        parseInt(drainWaterHardness, 10),
        poolWidth,
        poolHeight,
        poolCapacity,
        diameter,
      );
      let drMsg =
        withDrainage && !balancing
          ? "Re-calculate for drain % and cost"
          : `drain pool ${parseInt(
              drainWaterHardness,
              10,
            )}% (= minus ${mm}${valueType ? "mm" : "inches"})`;
      calciumHardnessMsg = `High Calcium - ${drMsg}`;
    } else if (maxDrain !== drainWaterHardness && withDrainage) {
      calciumHardnessMsg = "Re-calculate to display drain % and cost";
    } else {
      calciumHardnessMsg = "OK";
    }
  } else if (
    calciumHardness === parseFloat(poolInputs.calciumHardness) ||
    (!drainWaterHardness && !calciumRaiser)
  ) {
    calciumHardnessMsg = "OK";
  }

  if (stripExist) {
    if ((!sixPadStrip && !isInput && !showCalciumStatus) || !showCalciumStatus)
      calciumHardnessMsg = "Use Aquachek 7 test-strips to check Total Hardness";
    if (
      chlorinationType === "Salt Pool" ||
      chlorinationType === "Convert Salt Pool to Mineral Pool"
    ) {
      if (greenSplitName === "Part" && sixPadStrip && !disSalt)
        calciumHardnessMsg = "Requires Salt reading to display dosage";
      if (isDrainage)
        calciumHardnessMsg = getDrainCalciumMessage(
          sixPadStrip,
          disSalt,
          calciumHardnessMsg,
          isInput,
        );
    } else if (chlorinationType === "Mineral Pool") {
      if (sixPadStrip) {
        if (!disSalt)
          calciumHardnessMsg =
            "Requires Salt reading to display Calcium Hardness";
        if (!showCalciumStatus)
          calciumHardnessMsg = "Calcium Hardness not tested";
      }
      // if (drainStatus) calciumHardnessMsg = 'Requires Salt reading to display Calcium & drain %';
      if (greenSplitName === "Part" && sixPadStrip && !disSalt)
        calciumHardnessMsg = "Requires Salt reading to display dosage";
      if (isDrainage)
        calciumHardnessMsg = getDrainCalciumMessage(
          sixPadStrip,
          disSalt,
          calciumHardnessMsg,
          isInput,
        );
    }
  } else if (!showCalciumStatus && !isDrainage) {
    calciumHardnessMsg = "Calcium Hardness not tested";
  }

  if (calError) {
    calciumHardnessMsg = calError;
  }

  let renderCalciumUnique =
    renderCalciumName === "Calcium Raiser"
      ? "CalciumRaiser"
      : "Zodiac Calcium Down";
  return {
    calciumHardnessMsg,
    renderCalcium,
    renderCalciumName,
    renderCalciumUnit,
    renderCalciumUnique,
  };
};

export const getStabiliserMsg = (
  addStabiliser,
  differences,
  drainObj,
  poolInputs,
  stabiliser,
  container,
  drainSameMsg,
  valueType,
  stabUnit,
  chlorinationType,
  stripExist,
  sixPadStrip,
  greenSplitName,
  disSalt,
  disCalcium,
  displayName,
  calError,
  isDrainage,
  stabiliserShow,
) => {
  const {
    maxDrain,
    drainWater,
    drainWaterHardness,
    drainWaterChorine,
    withDrainage,
    balancing,
    poolWidth,
    poolHeight,
    poolCapacity,
    diameter,
  } = drainObj;

  const { passDiffStab } = differences;
  let stabiliserMsg = "";
  let renderStabiliser = addStabiliser;
  let renderStabName = "";
  let renderStabiliserUnit = stabUnit;
  if (balancing && !drainWater) {
    stabiliserMsg = "Not chemicals being added";
  } else if (addStabiliser) {
    if (stabUnit === "grams") {
      addStabiliser *= 1000;
    }
    renderStabiliser = addStabiliser;
    renderStabName = displayName;
    renderStabiliserUnit = valueType ? stabUnit : "oz";
    stabiliserMsg = `Add ${addStabiliser} ${renderStabiliserUnit} of ${displayName}`;
    if (!valueType) {
      renderStabName = "Cyanuric Acid";
      renderStabiliserUnit = stabUnit === "grams" ? "oz" : "lb";
      stabiliserMsg = `Add ${addStabiliser} ${renderStabiliserUnit} of Cyanuric Acid`;
      if (addStabiliser.toString().includes(".") && addStabiliser > 1) {
        const splitValue = addStabiliser.split(".");
        const childUnit = splitValue[1] !== "0" ? `${splitValue[1]} oz` : "";
        stabiliserMsg = `Add ${splitValue[0]} lb ${childUnit} of Cyanuric Acid`;
        renderStabiliserUnit = "lb";
      }
    }
  } else if (drainWater || maxDrain === drainWater) {
    if (container.includes("Stabiliser")) {
      stabiliserMsg = withDrainage
        ? "Re-calculate to display drain % and cost"
        : drainSameMsg;
    } else if (
      passDiffStab &&
      maxDrain === drainWater &&
      (maxDrain !== drainWaterHardness || maxDrain !== drainWaterChorine)
    ) {
      const mm = calciMM(
        parseInt(drainWater, 10),
        poolWidth,
        poolHeight,
        poolCapacity,
        diameter,
      );
      let drMsg =
        withDrainage && !balancing
          ? "Re-calculate for drain % and cost"
          : `drain pool ${parseInt(drainWater, 10)}% (= minus ${mm}${valueType ? "mm" : "inches"})`;
      stabiliserMsg = `High Stabiliser - ${drMsg}`;
    } else if (maxDrain !== drainWater && withDrainage) {
      stabiliserMsg = "Re-calculate to display drain % and cost";
    } else {
      stabiliserMsg = "OK";
    }
  } else if (
    stabiliser === parseFloat(poolInputs.stabiliser) ||
    (!drainWater && !addStabiliser)
  ) {
    stabiliserMsg = "OK";
  }
  if (stripExist) {
    if (!stabiliserShow) {
      stabiliserMsg = "Require pH of 7.9 or lower to show results";
    }
    // stabiliserMsg = stripMessageReturn(chlorinationType, greenSplitName, sixPadStrip, disSalt, stabiliserMsg, isDrainage);
  }

  if (calError) {
    stabiliserMsg = calError;
  }
  let renderStabUnique =
    displayName === "Granular Stabiliser" ? "Stabiliser" : "liquidStab";
  return {
    stabiliserMsg,
    renderStabiliser,
    renderStabName,
    renderStabiliserUnit,
    renderStabUnique,
  };
};

export const getUnitAndChemical = (chemicalValue) => {
  const inMl = Math.ceil(Math.trunc(chemicalValue * 1000) / 100) * 100;
  const chemicalVal =
    chemicalValue < 1
      ? inMl === 1000
        ? chemicalValue.toFixed(1)
        : inMl
      : chemicalValue.toFixed(1);
  const unit = chemicalValue < 1 ? (inMl === 1000 ? "litres" : "ml") : "litres";
  return { chemicalVal, unit };
};

export const getBufferMsg = (buffer) => {
  const inGrams = Math.ceil(Math.trunc(buffer * 1000) / 100) * 100;
  const lenVal = buffer.toString().split(".")[0].length >= 2 ? 4 : 3;
  const sodaAshVal =
    buffer < 1
      ? inGrams === 1000
        ? buffer.toString().substring(0, lenVal)
        : inGrams
      : buffer.toString().substring(0, lenVal);
  const unit = getBufferUnit(buffer);
  let alkMsg = `Add ${chemicalValueAdjustment(sodaAshVal)} ${unit} of Buffer`;
  return alkMsg;
};

export const getSodiumMSg = (sodiumThiosulphate) => {
  const { unit, chemicalVal } = getInGrams(sodiumThiosulphate);
  let chlorineMsg = `Add ${chemicalVal} ${unit} of Sodium Thiosulphate`;
  return chlorineMsg;
};

export const getInGrams = (chemical, valueType) => {
  const inGrams = Math.ceil(Math.trunc(chemical * 1000) / 100) * 100;
  let chemicalVal =
    chemical < 1
      ? inGrams === 1000
        ? chemical.toFixed(1)
        : inGrams
      : chemical.toFixed(1);

  let unit = getUnit(chemical);

  if (parseFloat(chemicalVal) < 1) {
    chemicalVal *= 1000;
    unit = "grams";
  }
  if (chemical > 0) {
    chemicalVal *= 1;
    chemicalVal = chemicalVal.toFixed(1);
  } else {
    chemicalVal = 0;
  }

  if (unit === "grams") {
    chemicalVal /= 1000;
  } else if (!valueType) chemicalVal = Math.ceil(chemicalVal);

  return { unit, chemicalVal };
};

export const getPhosValUnit = (chemical, valueType) => {
  const updatedPhosphates = Math.round(chemical);
  let litre = false;
  let val = Math.ceil(updatedPhosphates / 100) * 100;
  if (val >= 1000) {
    val /= 1000;
    litre = true;
  }
  const unit = litre ? "litres" : "ml";

  if (unit === "ml") {
    val /= 1000;
  }
  if (!valueType) val = Math.ceil(val);

  return { unit, chemicalVal: val };
};

export const getValUnitInLitres = (chemical, valueType) => {
  const inMl = Math.ceil(Math.trunc(chemical * 1000) / 100) * 100;
  let chemicalVal =
    chemical < 1
      ? inMl === 1000
        ? chemical.toFixed(1)
        : inMl
      : chemical.toFixed(1);
  const unit = chemical < 1 ? (inMl === 1000 ? "litres" : "ml") : "litres";

  if (unit === "ml") {
    chemicalVal /= 1000;
  }
  if (!valueType) chemicalVal = Math.ceil(chemicalVal);

  return { unit, chemicalVal };
};

export const getCopperValUnits = (chemical, valueType) => {
  const inMl = Math.ceil(Math.trunc(chemical * 1000) / 100) * 100;
  let copperVal =
    chemical < 1000
      ? inMl >= 1000
        ? chemical.toFixed(1)
        : inMl
      : chemical.toFixed(1);
  const unit =
    chemical < 1000
      ? inMl >= 1000
        ? inMl === 1000
          ? "litre"
          : "litres"
        : "ml"
      : "litres";
  const actualUnit =
    chemical < 1 && copperVal !== "1.0"
      ? "ml"
      : copperVal === "1.0"
        ? "litre"
        : unit;

  if (unit === "ml") {
    copperVal /= 1000;
  }

  if (!valueType) copperVal = Math.ceil(copperVal);

  return { chemicalVal: copperVal, unit: actualUnit };
};

export const algaeClariValUnits = (chemical, valueType) => {
  const inMl = Math.ceil(Math.trunc(chemical) / 100) * 100;
  let chemicalVal =
    chemical < 1000
      ? inMl === 1000
        ? (chemical / 1000).toFixed(1)
        : inMl
      : (chemical / 1000).toFixed(1);
  const unit = chemical < 1000 ? (inMl === 1000 ? "litres" : "ml") : "litres";
  console.log("chemicalVll", chemicalVal);
  if (unit === "ml") {
    chemicalVal /= 1000;
  }
  if (!valueType) chemicalVal = Math.ceil(chemicalVal);

  return { chemicalVal, unit };
};

export const flockValUnits = (chemical, valueType) => {
  const inGrams = Math.ceil(Math.trunc(chemical * 1000) / 100) * 100;
  let flockVal =
    chemical < 1
      ? inGrams === 1000
        ? chemical.toFixed(1)
        : inGrams
      : chemical.toFixed(1);

  let flockUnit = getUnit(chemical);

  if (flockUnit === "grams") {
    flockVal /= 1000;
  } else if (!valueType) flockVal = Math.ceil(flockVal);

  return { chemicalVal: flockVal, unit: flockUnit };
};

export const bufferUnitVal = (chemical, valueType) => {
  const inGrams = Math.ceil(Math.trunc(chemical * 1000) / 100) * 100;
  const lenVal = chemical.toString().split(".")[0].length >= 2 ? 4 : 3;
  const sodaAshVal =
    chemical < 1
      ? inGrams === 1000
        ? chemical.toString().substring(0, lenVal)
        : inGrams
      : chemical.toString().substring(0, lenVal);
  const unit = getBufferUnit(chemical);
  let sodaVal = chemicalValueAdjustment(sodaAshVal);

  if (unit === "grams") {
    sodaVal /= 1000;
  } else if (!valueType) sodaVal = Math.ceil(sodaVal);

  return { unit, chemicalVal: sodaVal };
};

export const getChlorineMsg = (
  granularChlorine,
  sodiumThiosulphate,
  chemicalBalanceValue,
  chlorine,
  poolInputs,
  calVariable,
  balancing,
  valueType,
  granUnit,
  sodiumUnit,
  chlorinationType,
  stripExist,
  sixPadStrip,
  greenSplitName,
  disSalt,
  disCalcium,
  calError,
  isDrainage,
  serviceAnalysis,
  copyPoolInputsChlorine,
) => {
  let chlorineMsg = "";
  let renderChlorine = granularChlorine;
  let renderChlorineName = "";
  let renderChlorineUnit = "";
  let renderChlorineUnique = "";
  if (balancing) {
    chlorineMsg = "Not chemicals being added";
  } else if (granularChlorine) {
    const gran = granularChlorine;
    if (granUnit === "grams") {
      granularChlorine *= 1000;
    }
    renderChlorine = granularChlorine;
    renderChlorineUnit = granUnit;
    // const { unit, chemicalVal } = getInGrams(granularChlorine);
    renderChlorineName = chemicalBalanceValue;
    if (chemicalBalanceValue === "Granular Chlorine") {
      renderChlorineUnique = "Granular";
      chlorineMsg = `Add ${granularChlorine} ${granUnit} of ${renderChlorineName} `;
      if (!valueType) {
        renderChlorineUnit = granUnit === "grams" ? "oz" : "lb";
        chlorineMsg = `Add ${granularChlorine} ${renderChlorineUnit} of ${renderChlorineName}`;
        // if (granularChlorine.toString().includes('.') && granularChlorine > 1) {
        //   const splitValue = granularChlorine.split('.');
        //   const childUnit = splitValue[1] !== '0' ? `${splitValue[1]} oz` : '';
        //   chlorineMsg = `Add ${splitValue[0]} lb ${childUnit} of ${renderChlorineName}`;
        //   renderChlorineUnit = 'lb';
        // }
      }
    } else if (chemicalBalanceValue === "Liquid Chlorine") {
      renderChlorineUnique = "Liquid";
      let calculation = gran;
      if (calculation?.toFixed(1)?.split(".")[1] !== "0") {
        calculation = calculation.toFixed(1);
      }
      let chlorUnit = "ml";
      if (Number(calculation) === 1) {
        chlorUnit = "litre";
      } else if (Number(calculation) > 1) {
        chlorUnit = "litres";
      }
      if (!valueType) {
        chlorUnit = "fl oz";
      }

      calculation = calculation < 1 ? calculation * 1000 : calculation;
      renderChlorine = calculation;
      renderChlorineUnit = chlorUnit;
      chlorineMsg = `Add ${calculation} ${chlorUnit} of ${renderChlorineName} `;
    }
  } else if (
    chlorine === parseFloat(copyPoolInputsChlorine) ||
    (copyPoolInputsChlorine >= calVariable.chlorNegative &&
      copyPoolInputsChlorine <= calVariable.chlorPositive)
  ) {
    chlorineMsg = "OK";
  } else if (parseFloat(sodiumThiosulphate) > 0) {
    if (sodiumUnit === "grams") {
      sodiumThiosulphate *= 1000;
    }
    renderChlorine = sodiumThiosulphate;
    renderChlorineName = "Sodium Thiosulphate";
    renderChlorineUnit = valueType ? sodiumUnit : "oz";
    renderChlorineUnique = "SodiumThi";
    chlorineMsg = `Add ${sodiumThiosulphate} ${renderChlorineUnit} of ${renderChlorineName}`;
    if (!valueType) {
      renderChlorineUnit = sodiumUnit === "grams" ? "oz" : "lb";
      chlorineMsg = `Add ${sodiumThiosulphate} ${renderChlorineUnit} of ${renderChlorineName}`;
      // if (sodiumThiosulphate.toString().includes('.') && sodiumThiosulphate > 1) {
      //   const splitValue = sodiumThiosulphate.split('.');
      //   const childUnit = splitValue[1] !== '0' ? `${splitValue[1]} oz` : '';
      //   chlorineMsg = `Add ${splitValue[0]} lb ${childUnit} of ${renderChlorineName}`;
      //   renderChlorineUnit = 'lb';
      // }
    }
  } else {
    chlorineMsg = "OK";
  }

  if (
    serviceAnalysis === "Green Pool" ||
    serviceAnalysis === "Black-Spot Algae"
  ) {
    chlorineMsg = "OK";
    renderChlorine = "";
    renderChlorineName = "";
    renderChlorineUnit = "";
    renderChlorineUnique = "";
  }

  if (stripExist) {
    // chlorineMsg = stripMessageReturn(chlorinationType, greenSplitName, sixPadStrip, disSalt, chlorineMsg, isDrainage);
  }

  // console.log('==>>>', calError);

  if (calError) {
    chlorineMsg = calError;
  }

  return {
    chlorineMsg,
    renderChlorine,
    renderChlorineUnit,
    renderChlorineName,
    renderChlorineUnique,
  };
};

export const getCalciumPhReducerMsg = (
  hydrochloricAcid,
  chemicalBalanceWaterObj,
  valueType,
  calPhReduUnit,
  calError,
) => {
  let calciumPhReducerMsg = "";
  let renderPhReducer = hydrochloricAcid;
  let renderPhReducerUnit = "";
  let renderPhReducerUnique = "";
  if (calPhReduUnit === "ml" && valueType) {
    hydrochloricAcid *= 1000;
  }
  renderPhReducer = hydrochloricAcid;
  if (hydrochloricAcid) {
    if (hydrochloricAcid) {
      renderPhReducerUnit = valueType
        ? chemicalBalanceWaterObj.phbalanceName === "Dry Acid"
          ? chemicalUnitkilogramAdjustment(hydrochloricAcid, calPhReduUnit)
          : chemicalUnitAdjustment(hydrochloricAcid, calPhReduUnit)
        : "oz";
      calciumPhReducerMsg = `Add ${hydrochloricAcid} ${renderPhReducerUnit} of ${chemicalBalanceWaterObj.phbalanceName}`;
    }
  }

  if (calError) {
    calciumPhReducerMsg = calError;
  }

  renderPhReducerUnique =
    chemicalBalanceWaterObj.phbalanceName === "Dry Acid" ? "DryAcid" : "PhAcid";

  return {
    calciumPhReducerMsg,
    renderPhReducer,
    renderPhReducerUnit,
    renderPhReducerUnique,
  };
};

export const getPhMsg = (
  aerateStatus,
  sodaAsh,
  hydrochloricAcidPh,
  ph,
  phContNegative,
  phContPositive,
  poolInputs,
  chemicalBalanceWaterObj,
  balancing,
  valueType,
  hydroUnit,
  sodaUnit,
  chlorinationType,
  stripExist,
  sixPadStrip,
  greenSplitName,
  disSalt,
  disCalcium,
  calError,
  isDrainage,
) => {
  let phMsg = "";
  let renderPh = sodaAsh;
  let renderPhName = "";
  let renderPhUnit = "";
  let renderPhUnique = "";
  if (balancing) {
    phMsg = "Not chemicals being added";
  } else if (aerateStatus) {
    phMsg = "Aerate pool for 24 hours, then re-test pH & Alkalinity";
  } else if (sodaAsh) {
    if (sodaUnit === "grams") {
      sodaAsh *= 1000;
      renderPh = sodaAsh;
    }
    renderPhName = "Soda Ash";
    renderPhUnit = sodaUnit;
    renderPhUnique = "PhSodaAsh";
    // const { unit, chemicalVal } = getInGrams(sodaAsh);
    phMsg = `Add ${sodaAsh} ${sodaUnit} of ${renderPhName}`;
    if (!valueType) {
      renderPhUnit = sodaUnit === "grams" ? "oz" : "lb";
      phMsg = `Add ${sodaAsh} ${renderPhUnit} of ${renderPhName}`;
      // if (sodaAsh.toString().includes('.') && sodaAsh > 1) {
      //   const splitValue = sodaAsh.split('.');
      //   const childUnit = splitValue[1] !== '0' ? `${splitValue[1]} oz` : '';
      //   phMsg = `Add ${splitValue[0]} lb ${childUnit} of ${renderPhName}`;
      //   renderPhUnit = 'lb';
      // }
    }
  } else if (ph === parseFloat(poolInputs.ph)) {
    phMsg = "OK";
  } else if (
    parseFloat(poolInputs.ph) >= phContNegative &&
    parseFloat(poolInputs.ph) <= phContPositive
  ) {
    phMsg = "OK";
  } else if (hydrochloricAcidPh) {
    if ((hydroUnit === "ml" && valueType) || hydroUnit === "grams") {
      hydrochloricAcidPh *= 1000;
    }
    // console.log({ hydrochloricAcidPh, hydroUnit });
    // hydrochloricAcidPh = flRd(hydrochloricAcidPh);

    renderPh = hydrochloricAcidPh;
    renderPhName = chemicalBalanceWaterObj?.phbalanceName;
    renderPhUnique = renderPhName === "Dry Acid" ? "DryAcid" : "PhAcid";
    renderPhUnit = valueType
      ? renderPhName === "Dry Acid"
        ? chemicalUnitkilogramAdjustment(hydrochloricAcidPh, hydroUnit)
        : chemicalUnitAdjustment(hydrochloricAcidPh, hydroUnit)
      : getUnitInImperial(chemicalBalanceWaterObj?.phbalanceName, hydroUnit);
    phMsg = `Add ${hydrochloricAcidPh} ${renderPhUnit} of ${renderPhName}`;
  } else {
    phMsg = "OK";
  }

  if (stripExist) {
    // phMsg = stripMessageReturn(chlorinationType, greenSplitName, sixPadStrip, disSalt, phMsg, isDrainage);
  }

  if (calError) {
    phMsg = calError;
  }

  return {
    phMsg,
    renderPh,
    renderPhName,
    renderPhUnit,
    renderPhUnique,
  };
};
export const getSaltConvertMsg = (
  convertPool,
  manualValue,
  manualAquaValue,
  balancing,
  valueType,
  calError,
) => {
  let saltMsgConvert = "";
  let secondSaltMsgConvert = "";
  let renderSaltConvert = convertPool;
  let renderSecondSaltValue = manualAquaValue;
  let renderSaltConvertName = "";
  let renderSecondSaltConvertName = "";
  if (balancing) {
    secondSaltMsgConvert = "Not chemicals being added";
  } else if (convertPool) {
    let convertPoolVal = convertPool.toFixed(1);
    if ((convertPool * 10.0) % 10 === 0) {
      convertPoolVal = parseInt(convertPool, 10);
    }
    renderSaltConvert = convertPoolVal;
    renderSaltConvertName = `Aquatherepe Transform (from ${convertPoolVal} x 10${valueType ? "kg" : "lb"} ${
      convertPoolVal > 1 ? "bags" : "bag"
    }`;
    if (manualValue.includes("Aquatherepe Transform Maintenance")) {
      saltMsgConvert = `Add ${manualAquaValue} ${valueType ? "kg" : "oz"} of ${
        renderSaltConvertName
      }`;
      const valueManual = (manualAquaValue / 6).toFixed(1);
      renderSecondSaltConvertName =
        "Aquatherepe Transform every 6 months to maintain";
      secondSaltMsgConvert = `add ${valueManual} ${valueType ? "kg" : "oz"} ${renderSecondSaltConvertName} `;
    } else {
      renderSaltConvertName = `Aquatherepe Transform (10 ${valueType ? "kg" : "lb"})`;
      saltMsgConvert = `Add ${convertPoolVal} ${
        convertPoolVal > 1 ? "bags" : "bag"
      } of ${renderSaltConvertName}`;
    }
    if (manualValue === "Convert Pool to Mineral Pool") {
      renderSecondSaltConvertName =
        "Aquatherepe Transform every 6 months to maintain";
      secondSaltMsgConvert = `add ${manualAquaValue}${valueType ? "kg" : "oz"} ${renderSecondSaltConvertName} `;
    }
    renderSecondSaltValue = manualAquaValue;
  }

  if (saltMsgConvert && calError) {
    saltMsgConvert = calError;
  }

  return {
    saltMsgConvert,
    secondSaltMsgConvert,
    renderSaltConvert,
    renderSecondSaltValue,
    renderSaltConvertName,
    renderSecondSaltConvertName,
    renderSaltConvertUnique: "Convert",
  };
};

export const getAlkMsg = (
  alkAerate,
  alkRetest,
  alkMsgSetOk,
  hydrochloricAcidAlk,
  buffer,
  chemicalBalanceWaterObj,
  balancing,
  valueType,
  hydroAlkUnit,
  bufferUnit,
  chlorinationType,
  stripExist,
  sixPadStrip,
  greenSplitName,
  disSalt,
  disCalcium,
  calError,
  isDrainage,
) => {
  let alkMsg = "";
  let renderAlkalinity = hydrochloricAcidAlk;
  let renderAlkName = "";
  let renderAlkMsgUnit = "";
  let renderAlkUnique = "";
  if (balancing) {
    alkMsg = "Not chemicals being added";
  } else if (alkAerate) {
    alkMsg = "Aerate pool for 24 hours, then re-test pH & Alkalinity";
  } else if (alkRetest) {
    alkMsg = "Do not adjust Alkalinity until after re-testing";
  } else if (hydrochloricAcidAlk) {
    if ((hydroAlkUnit === "ml" && valueType) || hydroAlkUnit === "grams") {
      hydrochloricAcidAlk *= 1000;
    }
    // hydrochloricAcidAlk = flRd(hydrochloricAcidAlk);
    renderAlkUnique =
      chemicalBalanceWaterObj?.phbalanceName === "Dry Acid"
        ? "DryAcid "
        : "PhAcid";
    renderAlkalinity = hydrochloricAcidAlk;
    renderAlkName = chemicalBalanceWaterObj?.totalalkalinitytabalanceName;
    renderAlkMsgUnit = valueType
      ? chemicalBalanceWaterObj?.phbalanceName === "Dry Acid"
        ? chemicalUnitkilogramAdjustment(hydrochloricAcidAlk, hydroAlkUnit)
        : chemicalUnitAdjustment(hydrochloricAcidAlk, hydroAlkUnit)
      : getUnitInImperial(chemicalBalanceWaterObj?.phbalanceName, hydroAlkUnit);
    alkMsg = `Add ${hydrochloricAcidAlk} ${renderAlkMsgUnit} of ${renderAlkName}`;
  } else if (alkMsgSetOk) {
    alkMsg = "OK";
  } else if (buffer) {
    if (bufferUnit === "grams") {
      buffer *= 1000;
    }
    renderAlkalinity = buffer;
    renderAlkName = "Buffer";
    renderAlkUnique = "AlkBuffer";
    renderAlkMsgUnit = valueType ? bufferUnit : "oz";
    alkMsg = `Add ${buffer} ${renderAlkMsgUnit} of ${renderAlkName}`;
    if (!valueType) {
      renderAlkMsgUnit = bufferUnit === "grams" ? "oz" : "lb";
      alkMsg = `Add ${buffer} ${renderAlkMsgUnit} of ${renderAlkName}`;
      // if (buffer.toString().includes('.') && buffer > 1) {
      //   const splitValue = buffer.split('.');
      //   const childUnit = splitValue[1] !== '0' ? `${splitValue[1]} oz` : '';
      //   alkMsg = `Add ${splitValue[0]} lb ${childUnit} of ${renderAlkName}`;
      //   renderAlkMsgUnit = 'lb';
      // }
    }
  } else {
    alkMsg = "OK";
  }

  if (stripExist) {
    // alkMsg = stripMessageReturn(chlorinationType, greenSplitName, sixPadStrip, disSalt, alkMsg, isDrainage);
  }

  if (calError) {
    alkMsg = calError;
  }

  return {
    alkMsg,
    renderAlkalinity,
    renderAlkName,
    renderAlkMsgUnit,
    renderAlkUnique,
  };
};

export const getClarifierMsg = (
  clarifierMaintainenceQuantity,
  valueType,
  clarUnits,
  chlorinationType,
  stripExist,
  sixPadStrip,
  greenSplitName,
  disSalt,
  disCalcium,
  calError,
  isDrainage,
) => {
  let clarifierMsg = "";
  let renderClarUnits = "";
  if (clarUnits === "ml" && valueType) {
    clarifierMaintainenceQuantity *= 1000;
  }
  // clarifierMaintainenceQuantity = flRd(clarifierMaintainenceQuantity);

  renderClarUnits = valueType
    ? chemicalUnitAdjustment(clarifierMaintainenceQuantity, clarUnits)
    : "fl oz";
  clarifierMsg = `Add ${clarifierMaintainenceQuantity} ${renderClarUnits} of Zodiac Clarifier`;

  if (stripExist) {
    // clarifierMsg = stripMessageReturn(chlorinationType, greenSplitName, sixPadStrip, disSalt, clarifierMsg, isDrainage);
  }

  if (calError) {
    clarifierMsg = calError;
  }

  return {
    clarMsg: clarifierMsg,
    renClarifier: clarifierMaintainenceQuantity,
    renClarUnits: renderClarUnits,
  };
};
export const getAlgaecideMsg = (
  algaecideQuantity,
  valueType,
  algaeUnits,
  chlorinationType,
  stripExist,
  sixPadStrip,
  greenSplitName,
  disSalt,
  disCalcium,
  calError,
  isDrainage,
) => {
  let algaecideMsg = "";
  let renderAlgaeUnits = "";
  if (algaeUnits === "ml" && valueType) {
    algaecideQuantity *= 1000;
  }
  // algaecideQuantity = flRd(algaecideQuantity);
  renderAlgaeUnits = valueType
    ? chemicalUnitAdjustment(algaecideQuantity, algaeUnits)
    : "fl oz";
  algaecideMsg = `Add ${algaecideQuantity} ${renderAlgaeUnits} of Zodiac Pool Long Life Algaecide`;

  if (stripExist) {
    // algaecideMsg = stripMessageReturn(chlorinationType, greenSplitName, sixPadStrip, disSalt, algaecideMsg, isDrainage);
  }

  if (calError) {
    algaecideMsg = calError;
  }

  return {
    algMsg: algaecideMsg,
    renAlgaecide: algaecideQuantity,
    renAlgaeUnits: renderAlgaeUnits,
  };
};
export const getFlockMsg = (
  flockQuantity,
  valueType,
  flockUnit,
  chlorinationType,
  stripExist,
  sixPadStrip,
  greenSplitName,
  disSalt,
  disCalcium,
  calError,
  isDrainage,
) => {
  let flockMsg = "";
  let renderFlockUnits = "";
  if (flockUnit === "grams") {
    flockQuantity *= 1000;
  }
  renderFlockUnits = flockUnit;
  flockMsg = `Add ${flockQuantity} ${flockUnit} of Flock - Aluminium Sulphate`;
  if (!valueType) {
    renderFlockUnits = flockUnit === "grams" ? "oz" : "lb";
    flockMsg = `Add ${flockQuantity} ${renderFlockUnits} of Flock - Aluminium Sulphate`;
    // if (flockQuantity.toString().includes('.') && flockQuantity > 1) {
    //   const splitValue = flockQuantity.split('.');
    //   const childUnit = splitValue[1] !== '0' ? `${splitValue[1]} oz` : '';
    //   flockMsg = `Add ${splitValue[0]} lb ${childUnit} of Flock - Aluminium Sulphate`;
    //   renderFlockUnits = 'lb';
    // }
  }

  if (stripExist) {
    // flockMsg = stripMessageReturn(chlorinationType, greenSplitName, sixPadStrip, disSalt, flockMsg, isDrainage);
  }

  if (calError) {
    flockMsg = calError;
  }

  return {
    flkMsg: flockMsg,
    renFlock: flockQuantity,
    renFlockUnits: renderFlockUnits,
  };
};

export const converChlorine = (type, quantity, valueType) => {
  if (type === "Liquid Chlorine") {
    quantity *= 5;
    if (!valueType) quantity = Math.ceil(quantity);
  }
  return quantity;
};
