/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { TimePicker } from "smart-webcomponents-react/timepicker";
import moment from "../../functions/moment";
import "smart-webcomponents-react/source/styles/smart.default.css";
import toastMessage from "../../functions/toastMessage";

const HourPicker = ({
  hourPicker,
  hourData,
  handleHourPicker,
  bookingData,
  setBookingData,
  setHourData,
  convertToReadableDuration,
  subcategory,
  setSubCategory,
  setHourPicker,
}) => {
  const timepickerRef = useRef(null);
  const [active, setActive] = useState("hour");

  const handleHourSet = () => {
    const { hour, minute } = getHourDuration();
    if (!(!parseInt(hour, 10) && parseFloat(minute) < 5)) {
      timepickerRef.current.value = `${hour}:${minute}`;
      if (bookingData && setBookingData) {
        setBookingData({
          ...bookingData,
          quantity: hourData,
          quantityChange: true,
        });
      } else {
        setSubCategory({ ...subcategory, quantity: hourData });
      }
      handleHourPicker();
    } else {
      toastMessage("error", "Minimum value for quantity is 5 minutes");
    }
  };

  const handleHourTime = (e) => {
    // console.log(e);
    const time = moment(new Date(e.detail.value).toISOString()).format("H:m");
    const splitData = time.split(":");
    console.log("splitData", splitData);
    let hour = splitData[0];
    if (hour === "0") {
      hour = "12";
    } else if (hour === "12") {
      hour = "0";
    }
    console.log("parseFloat(splitData[1])", parseFloat(splitData[1]));
    console.log("hour", hour);
    const inMinute = parseFloat(hour) * 60 + parseFloat(splitData[1]);
    console.log("inMinute", inMinute);
    setHourData(inMinute.toString());
  };
  const handleNewHourChange = (type) => {
    timepickerRef.current.selection = type;
    const fEl = document.getElementsByClassName(
      "smart-label smart-unselectable",
    );
    if (type === "hour") {
      console.log("fEl", fEl);
      fEl[0].innerHTML = "0";
      fEl[0].classList.add("zero-class");
      fEl[12].innerHTML = "12";
      fEl[0].setAttribute("x", 104);
      fEl[12].setAttribute("x", 102);
      fEl[12].classList.add("other-class");
    } else {
      fEl[0].innerHTML = "0";
      fEl[0].setAttribute("x", 104);
      fEl[0].classList.add("zero-class-min");
    }
    setActive(type);
  };

  const getHourDuration = () => {
    console.log("hourData", hourData);
    const convertedDuration = convertToReadableDuration(hourData);
    const splitData = convertedDuration.split("hr");
    const hour = convertedDuration.includes("hr")
      ? splitData[0].replace(/hr/g, "").trim()
      : "0";
    const minute = convertedDuration.includes("hr")
      ? splitData[1]
        ? splitData[1].replace(/min/g, "").trim()
        : "0"
      : convertedDuration.replace(/min/g, "").trim();
    return { hour, minute };
  };

  const { hour, minute } = getHourDuration();

  useEffect(() => {
    setTimeout(() => {
      // console.log('timepickerRef.current', timepickerRef.current);
      const fEl = document.getElementsByClassName(
        "smart-label smart-unselectable",
      );
      fEl[0].innerHTML = "0";
      fEl[0].classList.add("zero-class");
      fEl[12].innerHTML = "12";
      fEl[0].setAttribute("x", 104);
      fEl[12].setAttribute("x", 102);
      timepickerRef.current.value = `${hour === "12" ? "0" : hour === "0" ? "12" : hour}:${minute}`;
    }, 350);
  }, []);
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className === "duration-picker time-picker-modal"
          ) {
            setHourPicker(false);
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <div className="duration-picker time-picker-modal">
      <div>
        <div className="time-keeper-popup duration-popup" ref={wrapperRef}>
          <div className="custom-select-time">
            <h6
              className={`custom-hour ${active === "hour" ? "time-active" : ""}`}
              onClick={() => handleNewHourChange("hour")}
            >
              {hour}
              <span>hr</span>
            </h6>
            {/* <h6>:</h6> */}
            <h6
              className={`custom-min ${active === "minute" ? "time-active" : ""}`}
              onClick={() => handleNewHourChange("minute")}
            >
              {minute}
              <span>min</span>
            </h6>
          </div>
          {console.log("timepixkerRef", timepickerRef)}
          <TimePicker
            ref={timepickerRef}
            format="24-hour"
            onChange={handleHourTime}
            minuteInterval={5}
          />
          <div className="time-keeper-btn">
            <Button onClick={handleHourSet}>Save/Update</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
HourPicker.propTypes = {
  hourPicker: PropTypes.bool.isRequired,
  hourData: PropTypes.isRequired,
  handleHourPicker: PropTypes.func.isRequired,
  bookingData: PropTypes.func.isRequired,
  setHourData: PropTypes.func.isRequired,
  setBookingData: PropTypes.func.isRequired,
  convertToReadableDuration: PropTypes.func.isRequired,
  subcategory: PropTypes.func.isRequired,
  setSubCategory: PropTypes.func.isRequired,
  setHourPicker: PropTypes.func.isRequired,
};
export default HourPicker;
