import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "../../functions/moment";
import DatePopup from "../invoices/datePopup";
import { userInstance } from "../../config/axios";
// import {
//   getBlanceDue, cacluclateTotalAmount,
// } from '../../functions/index';

const AgeingSummary = ({ setLoderStatus, setAgingDataExcel }) => {
  const [dateStart, setDateStart] = useState(
    moment(new Date()).format("D MMM YYYY"),
  );
  const [datePopup, setDatePopup] = useState();
  const [customerData, setCustomerData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [teamMemberData, setTeamMemberData] = useState([]);
  const [filterStatus, setfilterStatus] = useState("");
  const [locationNameList, setLocationNameList] = useState();
  const [teamMemberName, setTeamMemberName] = useState();
  const [customerList, setCustomerList] = useState();
  const [allDataList, setAllDataList] = useState([]);
  const [totalsValue, setTotalsValue] = useState({
    allPeriodTotal: 0,
    firstBalnceDue: 0,
    secondBalnceDue: 0,
  });
  const initialfilterObj = {
    locationId: "",
    teammemberId: "",
    customerId: "",
    agingDate: new Date(),
  };
  const [check, setCheck] = useState({
    clientName: false,
    totalDue: false,
    firstBalnceDue: false,
    secondtBalnceDue: false,
  });
  const [filterObj, setFilterObj] = useState(initialfilterObj);
  const handleOnchange = (e) => {
    const { name, value } = e || {};
    setfilterStatus(value);
    if (name === "teammemberId" && value !== "") {
      const getLocationId = teamMemberData.filter((el) => el._id === value);
      let copyfilterObj = filterObj;
      copyfilterObj.teammemberId = value;
      copyfilterObj.locationId = getLocationId[0].location;
      setFilterObj(copyfilterObj);
    } else if (name === "locationId" && value === "") {
      let copyfilterObj = filterObj;
      copyfilterObj.teammemberId = "";
      copyfilterObj.locationId = value;
      setFilterObj(copyfilterObj);
    } else {
      let copyfilterObj = { ...filterObj, [name]: value };
      setFilterObj(copyfilterObj);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await userInstance().get("/appointment/getLocationList");
      const { locationList, code } = response.data;
      locationList.sort((a, b) => a.locationName.localeCompare(b.locationName));
      if (code === 200) {
        setLocationData([...locationList]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await userInstance().get(
        "/appointment/getTeamMemberList",
      );
      const { teamList, code } = response.data;
      teamList.sort((a, b) => a.firstname.localeCompare(b.firstname));
      if (code === 200) {
        setTeamMemberData(teamList);
        setfilterStatus(teamList);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await userInstance().get("/appointment/filterCustomer");
      const { customers, code } = response.data;
      const customerFilter = customers.filter((el) => el?.firstname);
      customerFilter.sort((a, b) => a?.firstname.localeCompare(b?.firstname));
      if (code === 200) {
        setCustomerData(customers);
      }
    })();
  }, []);

  useEffect(() => {
    if (filterObj.locationId === "") {
      setLocationNameList("All Locations");
    } else {
      const findLocation = locationData.find(
        (el) => el._id === filterObj.locationId,
      );
      if (findLocation) {
        setLocationNameList(findLocation.locationName);
      }
    }
    if (filterObj.teammemberId === "") {
      setTeamMemberName("All Team Member");
    } else {
      const findTeamMember = teamMemberData.find(
        (el) => el._id === filterObj.teammemberId,
      );
      if (findTeamMember) {
        setTeamMemberName(findTeamMember);
      }
    }
    if (filterObj.customerId === "") {
      setCustomerList("All Customers");
    } else {
      const findCustomer = customerData.find(
        (el) => el._id === filterObj.customerId,
      );
      setCustomerList(findCustomer);
    }
  }, [filterObj.locationId, filterObj.teammemberId, filterObj.customerId]);

  const isValidDate = (d) => d instanceof Date && !Number.isNaN(d);
  const handleDatePopup = (e) => {
    if (isValidDate(e)) {
      setDateStart(e);
      let copyfilterObj = filterObj;
      copyfilterObj.agingDate = new Date(e);
      setFilterObj(copyfilterObj);
      setfilterStatus(e);
    }
    setDatePopup(!datePopup);
  };

  const getBalanceDueData = async (e) => {
    const filterTeamMemberId =
      filterObj.teammemberId !== ""
        ? [filterObj.teammemberId]
        : filterObj.locationId === ""
          ? teamMemberData.map((el) => el._id)
          : teamMemberData
              .filter((el) => el.location === filterObj.locationId)
              .map((el) => el._id);
    const payload = {
      agingDate: filterObj.agingDate,
      customerId: filterObj.customerId,
      teamMemberIds: filterTeamMemberId,
    };
    const response = await userInstance().post(
      "/payment/getAgingSummary",
      payload,
    );
    const { allTotalDue, allPeriodTotal } = response.data;
    allTotalDue.sort((a, b) => a.firstname.localeCompare(b.firstname));
    setAllDataList(allTotalDue);
    setTotalsValue(allPeriodTotal);
    // setLoderStatus(false);
  };
  useEffect(() => {
    getBalanceDueData();
  }, [filterStatus]);
  const sortData = (type) => {
    const copyAllDataList = [...allDataList];
    if (type === "clientName") {
      if (!check.clientName) {
        copyAllDataList.sort((a, b) => b.firstname.localeCompare(a.firstname));
        setAllDataList(copyAllDataList);
        setCheck({ ...check, clientName: true });
      } else {
        copyAllDataList.sort((a, b) => a.firstname.localeCompare(b.firstname));
        setAllDataList(copyAllDataList);
        setCheck({ ...check, clientName: false });
      }
    } else if (type === "totalDue") {
      if (!check.totalDue) {
        copyAllDataList.sort((a, b) =>
          b.totalDue
            .toFixed(2)
            .localeCompare(a.totalDue.toFixed(2), undefined, { numeric: true }),
        );
        setAllDataList(copyAllDataList);
        setCheck({ ...check, totalDue: true });
      } else {
        copyAllDataList.sort((a, b) =>
          a.totalDue
            .toFixed(2)
            .localeCompare(b.totalDue.toFixed(2), undefined, { numeric: true }),
        );
        setAllDataList(copyAllDataList);
        setCheck({ ...check, totalDue: false });
      }
    } else if (type === "zeroThirty") {
      if (!check.firstBalnceDue) {
        copyAllDataList.sort((a, b) =>
          b.firstBalnceDue
            .toFixed(2)
            .localeCompare(a.firstBalnceDue.toFixed(2), undefined, {
              numeric: true,
            }),
        );
        setAllDataList(copyAllDataList);
        setCheck({ ...check, firstBalnceDue: true });
      } else {
        copyAllDataList.sort((a, b) =>
          a.firstBalnceDue
            .toFixed(2)
            .localeCompare(b.firstBalnceDue.toFixed(2), undefined, {
              numeric: true,
            }),
        );
        setAllDataList(copyAllDataList);
        setCheck({ ...check, firstBalnceDue: false });
      }
    } else if (type === "ThirtyPlus") {
      if (!check.secondtBalnceDue) {
        copyAllDataList.sort((a, b) =>
          b.secondtBalnceDue
            .toFixed(2)
            .localeCompare(a.secondtBalnceDue.toFixed(2), undefined, {
              numeric: true,
            }),
        );
        setAllDataList(copyAllDataList);
        setCheck({ ...check, secondtBalnceDue: true });
      } else {
        copyAllDataList.sort((a, b) =>
          a.secondtBalnceDue
            .toFixed(2)
            .localeCompare(b.secondtBalnceDue.toFixed(2), undefined, {
              numeric: true,
            }),
        );
        setAllDataList(copyAllDataList);
        setCheck({ ...check, secondtBalnceDue: false });
      }
    }
  };

  useEffect(() => {
    const newArray = [];
    if (allDataList.length > 0) {
      allDataList.forEach((el) => {
        const obj = {};
        obj["Client Name"] = `${el.firstname} ${el.lastname}`;
        obj["Total Due"] = `$${el.totalDue.toFixed(2)}`;
        obj["0-30 Days"] = `$${el.firstBalnceDue.toFixed(2)}`;
        obj["30+ Days"] = `$${el.secondtBalnceDue.toFixed(2)}`;
        newArray.push(obj);
      });
      if (totalsValue.allPeriodTotal > 0 && newArray.length > 0) {
        const objPeriodTotal = {};
        objPeriodTotal["Client Name"] = "Period Total:";
        objPeriodTotal["Total Due"] =
          `$${totalsValue.allPeriodTotal.toFixed(2)}`;
        objPeriodTotal["0-30 Days"] =
          `$${totalsValue.firstBalnceDue.toFixed(2)}`;
        objPeriodTotal["30+ Days"] =
          `$${totalsValue.secondBalnceDue.toFixed(2)}`;
        newArray.push(objPeriodTotal);
      }
    }
    setAgingDataExcel(newArray);
  }, [allDataList, totalsValue]);
  return (
    <>
      <div className="appointment-summary">
        <Form.Group controlId="formBasicCompanyType">
          <Form.Label>Customer</Form.Label>
          <div className="select-box">
            <Form.Control
              as="select"
              onChange={(e) => handleOnchange(e.target)}
              name="customerId"
            >
              <option value="">All Customers</option>
              {customerData.map((el) => (
                <option
                  value={el?._id}
                >{`${el?.firstname} ${el?.lastname}`}</option>
              ))}
            </Form.Control>
          </div>
        </Form.Group>

        <Form.Group controlId="formBasicCompanyType">
          <Form.Label>Location</Form.Label>
          <div className="select-box">
            <Form.Control
              as="select"
              onChange={(e) => handleOnchange(e.target)}
              name="locationId"
              value={filterObj.locationId}
            >
              <option value="">All Locations</option>
              {locationData.map((el) => (
                <option value={el._id}>{el?.locationName}</option>
              ))}
            </Form.Control>
          </div>
        </Form.Group>

        <Form.Group controlId="formBasicCompanyType">
          <Form.Label>Team Member</Form.Label>
          <div className="select-box">
            <Form.Control
              as="select"
              onChange={(e) => handleOnchange(e.target)}
              name="teammemberId"
              value={filterObj.teammemberId}
            >
              <option value="">All Team Members</option>
              {teamMemberData.map((el) => (
                <>
                  {filterObj.locationId === "" ? (
                    <option
                      value={el._id}
                    >{`${el?.firstname} ${el?.lastname}`}</option>
                  ) : (
                    filterObj?.locationId === el?.location && (
                      <option
                        value={el._id}
                      >{`${el?.firstname} ${el.lastname}`}</option>
                    )
                  )}
                </>
              ))}
            </Form.Control>
          </div>
        </Form.Group>
        <Form.Group controlId="formBasicCompanyType">
          <Form.Label>Ageing Date</Form.Label>
          <div
            className="custom-datepicker ageing-datepicker"
            onClick={handleDatePopup}
          >
            {moment(dateStart).format("ddd, D MMM YYYY")}
          </div>
        </Form.Group>
        <div className="appointment-summary-section">
          <p>Receivables Ageing Summary</p>
          <p>{moment(dateStart).format("ddd, D MMM YYYY")}</p>
          <hr />
          <div className="summary-table">
            <div className="summary-table-header">
              <h6 onClick={() => sortData("clientName")}>
                Client
                <span> Name</span>
              </h6>
              <h6 onClick={() => sortData("totalDue")}>Total Due</h6>
              <h6 onClick={() => sortData("zeroThirty")}>0-30 Days</h6>
              <h6 onClick={() => sortData("ThirtyPlus")}>30+ Days</h6>
            </div>

            {totalsValue.allPeriodTotal > 0 ? (
              <>
                {allDataList &&
                  allDataList?.length > 0 &&
                  allDataList.map((el, index) => (
                    <>
                      {el.totalDue > 0 && (
                        <div className="summary-table-body">
                          <p>
                            {el.firstname || ""} {el.lastname || ""}{" "}
                          </p>
                          <p>${el.totalDue.toFixed(2)}</p>
                          <p>${el.firstBalnceDue.toFixed(2)}</p>
                          <p>${el.secondtBalnceDue.toFixed(2)}</p>
                        </div>
                      )}
                    </>
                  ))}
                <hr />
                <div className="summary-table-body total-section">
                  <p>Period Total:</p>
                  <p>${totalsValue.allPeriodTotal.toFixed(2)}</p>
                  <p>${totalsValue.firstBalnceDue.toFixed(2)}</p>
                  <p>${totalsValue.secondBalnceDue.toFixed(2)}</p>
                </div>
              </>
            ) : (
              <>
                {" "}
                <div>
                  <p>No Records Found</p>
                </div>
                <hr />
              </>
            )}
          </div>
        </div>
        <DatePopup
          datePopup={datePopup}
          setDatePopup={setDatePopup}
          handleDatePopup={handleDatePopup}
          quoteDate={dateStart}
        />
      </div>
    </>
  );
};
AgeingSummary.propTypes = {
  setLoderStatus: PropTypes.func.isRequired,
  setAgingDataExcel: PropTypes.func.isRequired,
};
export default AgeingSummary;
