const quantityOfProduct = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "20",
  "25",
  "50",
  "100",
];
const weightOfProduct = ["1", "2", "4", "5", "10", "20", "25"];

const volumeOfProduct = [
  // 500,
  "1",
  "5",
  "10",
  "15",
  "20",
  "25",
];

const lengthOfProduct = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "20",
  "25",
  "50",
  "100",
];
const optionsDataDropdown = [
  { name: "Item", dropdownOptions: quantityOfProduct },
  { name: "Ounce", dropdownOptions: weightOfProduct },
  { name: "Pound", dropdownOptions: weightOfProduct },
  { name: "Stone", dropdownOptions: quantityOfProduct },
  { name: "Ton", dropdownOptions: weightOfProduct },
  { name: "Fluid Ounce", dropdownOptions: volumeOfProduct },
  { name: "Pint", dropdownOptions: volumeOfProduct },
  { name: "Quart", dropdownOptions: volumeOfProduct },
  { name: "Shot", dropdownOptions: quantityOfProduct },
  { name: "Foot", dropdownOptions: lengthOfProduct },
  { name: "Inch", dropdownOptions: lengthOfProduct },
  { name: "Yard", dropdownOptions: lengthOfProduct },
  { name: "Cubic Inch", dropdownOptions: lengthOfProduct },
  { name: "Cubic Foot", dropdownOptions: lengthOfProduct },
  { name: "Cu Yard", dropdownOptions: lengthOfProduct },
  { name: "Cup", dropdownOptions: lengthOfProduct },
  { name: "Sq Inch", dropdownOptions: lengthOfProduct },
  { name: "Square Foot", dropdownOptions: lengthOfProduct },
  { name: "Square Yard", dropdownOptions: lengthOfProduct },
  { name: "Square Mile", dropdownOptions: lengthOfProduct },
  { name: "Acre", dropdownOptions: lengthOfProduct },
  { name: "Gram", dropdownOptions: weightOfProduct },
  { name: "Kilogram", dropdownOptions: weightOfProduct },
  { name: "Tonne", dropdownOptions: weightOfProduct },
  { name: "Millilitre", dropdownOptions: volumeOfProduct },
  { name: "Litre", dropdownOptions: lengthOfProduct },
  { name: "Millimetre", dropdownOptions: lengthOfProduct },
  { name: "Centimetre", dropdownOptions: lengthOfProduct },
  { name: "Metre", dropdownOptions: lengthOfProduct },
  { name: "Kilometre", dropdownOptions: lengthOfProduct },
  { name: "Cubic Millimetre", dropdownOptions: volumeOfProduct },
  { name: "Cubic Centimetre", dropdownOptions: volumeOfProduct },
  { name: "Cubic Metre", dropdownOptions: volumeOfProduct },
  { name: "Square Millimetre", dropdownOptions: lengthOfProduct },
  { name: "Square Centimetre", dropdownOptions: lengthOfProduct },
  { name: "Square Metre", dropdownOptions: lengthOfProduct },
  { name: "Square Kilometre", dropdownOptions: lengthOfProduct },
  { name: "Hectare", dropdownOptions: lengthOfProduct },
  { name: "Bag", dropdownOptions: quantityOfProduct },
  { name: "Bottle", dropdownOptions: quantityOfProduct },
  { name: "Box", dropdownOptions: quantityOfProduct },
  { name: "Can", dropdownOptions: quantityOfProduct },
  { name: "Dozen", dropdownOptions: quantityOfProduct },
  { name: "Jar", dropdownOptions: quantityOfProduct },
  { name: "Packet", dropdownOptions: quantityOfProduct },
  { name: "Pair", dropdownOptions: quantityOfProduct },
  { name: "Pieces", dropdownOptions: quantityOfProduct },
  { name: "Roll", dropdownOptions: quantityOfProduct },
  { name: "Set", dropdownOptions: quantityOfProduct },
];

export default optionsDataDropdown;
