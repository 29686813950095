import React, { useEffect, useState } from "react";
import { IoIosSave } from "react-icons/io";
import PropTypes from "prop-types";
import { MARKETING_SCOPE, ModelList, WEB_APP_SCOPE } from "../contants";
import AvatarForm from "../components/avatarForm";
import TextField from "../components/textField";
import TextArea from "../components/textarea";
import SelectField from "../components/selectField";
import LoadingButton from "../components/loadingButton";
import toastMessage from "../../../functions/toastMessage";
import { chatPath } from "../../../config/keys";
import { userInstance } from "../../../config/axios";

const GeneralSettings = (props) => {
  const { generalData, setGeneralData, getGeneralData } = props;
  const [isUpLoading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [botName, setBotName] = useState("");
  const [openAIKey, setOpenAIKey] = useState("");
  const [selectedScope, setSelectedScope] = useState(MARKETING_SCOPE);
  const [aiModel, setAiModel] = useState("");
  const [greeting, setGreeting] = useState("");
  const [systemPrompt, setSystemPrompt] = useState("");

  const handleChangeAIModel = (model) => {
    setAiModel(model);
  };

  const handleChangeBotName = (value) => {
    setBotName(value);
  };

  const handleChangeGreeting = (value) => {
    setGreeting(value);
  };

  const handleChangeSystemPrompt = (value) => {
    setSystemPrompt(value);
  };

  const handleChangeOpenAIKey = (value) => {
    setOpenAIKey(value);
  };

  const handleChangeScope = (e) => {
    setSelectedScope(e.target.value);
  };

  const handleSave = async () => {
    if (botName === "") {
      toastMessage("error", "Please input the name of chatbot.");
      return;
    }
    if (openAIKey === "") {
      toastMessage("error", "Please input the OpenAI API Key.");
      return;
    }
    if (aiModel === "") {
      toastMessage("error", "Please select AI model.");
      return;
    }
    if (greeting === "") {
      toastMessage("error", "Please input the greeting message.");
      return;
    }
    if (systemPrompt === "") {
      toastMessage("error", "Please input the system prompt.");
      return;
    }
    try {
      setIsSaving(true);
      let _generalData = JSON.parse(JSON.stringify(generalData));
      _generalData.avatar = avatar;
      _generalData.name = botName;
      _generalData.openAIKey = openAIKey;
      let _selectedAiSettings = _generalData.aiSettings.find(
        (item) => item.scope === selectedScope,
      );
      _selectedAiSettings.aiModel = aiModel;
      _selectedAiSettings.greeting = greeting;
      _selectedAiSettings.systemPrompt = systemPrompt;

      let response = await userInstance().post(
        `${chatPath}/chatbotSettings/updateGeneralSettings`,
        _generalData,
      );
      const { msg, updatedGeneralSettings } = response.data;
      if (response.status === 200) {
        setGeneralData(updatedGeneralSettings);
        toastMessage("success", msg);
      } else {
        toastMessage("error", msg);
      }
      setIsSaving(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleUploadAvatar = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("avatar", file);
    formData.append("env", process.env.REACT_APP_ENV);
    try {
      setIsUploading(true);
      let response = await userInstance().post(
        `${chatPath}/chatbotSettings/uploadAvatar`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      const { msg, url } = response.data;
      if (response.status === 200) {
        setAvatar(url);
        toastMessage("success", msg);
      } else {
        toastMessage("error", msg);
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsUploading(false);
  };

  useEffect(() => {
    if (generalData !== null) {
      setAvatar(generalData.avatar);
      setBotName(generalData.name);
      setOpenAIKey(generalData.openAIKey);
      let selectedAiSettings = generalData.aiSettings.find(
        (item) => item.scope === selectedScope,
      );
      if (selectedAiSettings) {
        setAiModel(selectedAiSettings.aiModel);
        setGreeting(selectedAiSettings.greeting);
        setSystemPrompt(selectedAiSettings.systemPrompt);
      }
    }
  }, [generalData, selectedScope]);

  return (
    <div className="chatbot-settings-form-container">
      <div className="row gy-3">
        <div className="col-md-12 col-lg-3">
          <div className="chatbot-settings-form-sub-container">
            <AvatarForm
              isUpLoading={isUpLoading}
              label=""
              avatar={avatar}
              setAvatar={setAvatar}
              size={128}
              handleUploadFile={handleUploadAvatar}
            />
            <div
              className="d-flex align-self-center mt-4"
              style={{ width: "100%" }}
            >
              <div
                className="d-flex flex-column gap-3 align-self-center"
                style={{ width: "100%" }}
              >
                <TextField
                  type="text"
                  label="Name"
                  placeholder=""
                  value={botName}
                  handleChangeValue={handleChangeBotName}
                />
                <TextField
                  type="password"
                  label="OpenAI API Key"
                  placeholder=""
                  value={openAIKey}
                  handleChangeValue={handleChangeOpenAIKey}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-9">
          <div className="chatbot-settings-form-sub-container">
            <div className="row gy-4">
              <div className="col-12">
                <div className="d-flex gap-3">
                  <div className="d-flex align-self-center gap-2">
                    <input
                      type="radio"
                      name="chatbot-settings-options-radio-btn"
                      value={MARKETING_SCOPE}
                      checked={selectedScope === MARKETING_SCOPE}
                      onChange={handleChangeScope}
                    />
                    <span className="chatbot-settings-form-sub-title">
                      Marketing
                    </span>
                  </div>
                  <div className="d-flex align-self-center gap-2">
                    <input
                      type="radio"
                      name="chatbot-settings-options-radio-btn"
                      value={WEB_APP_SCOPE}
                      checked={selectedScope === WEB_APP_SCOPE}
                      onChange={handleChangeScope}
                    />
                    <span className="chatbot-settings-form-sub-title">
                      Web App
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <SelectField
                  label="AI Model"
                  data={ModelList}
                  selectedValue={aiModel}
                  handleChangeSelect={handleChangeAIModel}
                />
              </div>
              <div className="col-12">
                <TextArea
                  type="text"
                  label="Greeting Message"
                  placeholder=""
                  row={3}
                  value={greeting}
                  handleChangeValue={handleChangeGreeting}
                />
              </div>
              <div className="col-12">
                <TextArea
                  type="text"
                  label="System Prompt"
                  placeholder=""
                  row={8}
                  value={systemPrompt}
                  handleChangeValue={handleChangeSystemPrompt}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-3">
        <LoadingButton
          isLoading={isSaving}
          icon={<IoIosSave size={18} style={{ marginRight: "16px" }} />}
          label="Save"
          handleClickBtn={handleSave}
        />
      </div>
    </div>
  );
};

GeneralSettings.propTypes = {
  generalData: PropTypes.objectOf.isRequired,
  setGeneralData: PropTypes.func.isRequired,
  getGeneralData: PropTypes.func.isRequired,
};

export default GeneralSettings;
