import React from "react";
import PropTypes from "prop-types";
import UploadFiles from "./uploadFiles";
import UploadText from "./uploadTexts";
import Adverstiesment from "./advertisement";

const DataManagement = (props) => {
  const {
    uploadedFiles,
    setUploadedFiles,
    getUploadedFiles,
    uploadedTexts,
    setUploadedTexts,
    getUploadedTexts,
    advertisements,
    setAdvertisements,
    getAdvertiesments,
  } = props;

  return (
    <div className="chatbot-settings-form-container">
      <div className="row gy-3">
        <div className="col-md-12 col-lg-4">
          <UploadFiles
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            getUploadedFiles={getUploadedFiles}
          />
        </div>
        <div className="col-md-12 col-lg-4">
          <UploadText
            uploadedTexts={uploadedTexts}
            setUploadedTexts={setUploadedTexts}
            getUploadedTexts={getUploadedTexts}
          />
        </div>
        <div className="col-md-12 col-lg-4">
          <Adverstiesment
            advertisements={advertisements}
            setAdvertisements={setAdvertisements}
            getAdvertiesments={getAdvertiesments}
          />
        </div>
      </div>
    </div>
  );
};

DataManagement.propTypes = {
  uploadedFiles: PropTypes.arrayOf.isRequired,
  setUploadedFiles: PropTypes.func.isRequired,
  getUploadedFiles: PropTypes.func.isRequired,
  uploadedTexts: PropTypes.arrayOf.isRequired,
  setUploadedTexts: PropTypes.func.isRequired,
  getUploadedTexts: PropTypes.func.isRequired,
  advertisements: PropTypes.arrayOf.isRequired,
  setAdvertisements: PropTypes.func.isRequired,
  getAdvertiesments: PropTypes.func.isRequired,
};

export default DataManagement;
