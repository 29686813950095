import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Document, Page, pdfjs } from "react-pdf";
import moment from "../../functions/moment";
import StartEndDate from "./startEndDate";
import crosss from "../../assets/home/black-icon.png";
import search from "../../assets/home/search.png";
import { userInstance } from "../../config/axios";
import { filterAppointmentObj } from "../../functions/index";
import PreviewInvoice from "../appointment/previewInvoice";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import PreviewInvoiceDetails from "./previewInvoiceDetails";

const CustomerPaymentDetailsTwo = () => {
  const [dateRange, setDateRange] = useState({
    start: new Date(),
    end: new Date(),
  });

  const [datePopup, setDatePopup] = useState();
  const [allLocation, setlocationList] = useState([]);
  const [allteamList, setallteamList] = useState([]);
  const [filterStatus, setfilterStatus] = useState("");
  const [appsummaryDate, setappsummaryDate] = useState();
  const [allCustomer, setAllCustomer] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [periodTotal, setPeriodTotal] = useState();
  const [locationData, setLocationData] = useState([]);
  const [locationNameList, setLocationNameList] = useState();
  const [teamMemberName, setTeamMemberName] = useState();
  const [customerList, setCustomerList] = useState();
  const [customerData, setCustomerData] = useState([]);
  const [teamMemberData, setTeamMemberData] = useState([]);
  // const [previewUrl, setPreviewUrl] = useState();

  const initialfilterObj = {
    locationId: "alllocation",
    teammemberId: "allteam",
    locationStatus: false,
    teamStatus: false,
    datetype: "today",
    rangeStartDate: new Date(),
    rangeEndDate: new Date(),
    customerId: "allCustomer",
    payment: "all",
  };
  const [filterObj, setFilterObj] = useState(initialfilterObj);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectTemplate, setSelectTemplate] = useState(null);
  const [previewPopup, setPreviewPopup] = useState(false);
  const [check, setCheck] = useState({
    clientName: false,
    paymentDate: false,
    invoiceNo: false,
    paymentMethod: false,
    paymentAmount: false,
  });
  const handleDateRange = (start, end) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj.rangeStartDate = start;
    copyfilterObj.rangeEndDate = end;
    setFilterObj(copyfilterObj);
    setfilterStatus(start);
    setDatePopup(false);
  };
  const handleDatePopup = () => {
    setDatePopup(!datePopup);
  };
  const handleDateOnchange = (value, type) => {
    setDateRange({ ...dateRange, [type]: new Date(value) });
  };
  const handleOnchange = (e) => {
    const { name, value } = e || {};
    setfilterStatus(value);
    if (name === "teammemberId" && value !== "allteam") {
      const getLocationId = teamMemberData.filter((el) => el._id === value);
      let copyfilterObj = filterObj;
      copyfilterObj.teammemberId = value;
      copyfilterObj.locationId = getLocationId[0].location;
      setFilterObj(copyfilterObj);
    } else if (name === "locationId" && value === "alllocation") {
      let copyfilterObj = filterObj;
      copyfilterObj.teammemberId = "allteam";
      copyfilterObj.locationId = value;
      setFilterObj(copyfilterObj);
    } else {
      let copyfilterObj = { ...filterObj, [name]: value };
      setFilterObj(copyfilterObj);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await userInstance().get("/appointment/getLocationList");
      const { locationList, code } = response.data;
      locationList.sort((a, b) => a.locationName.localeCompare(b.locationName));
      if (code === 200) {
        setLocationData([...locationList]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await userInstance().get(
        "/appointment/getTeamMemberList",
      );
      const { teamList, code } = response.data;
      teamList.sort((a, b) => a.firstname.localeCompare(b.firstname));
      if (code === 200) {
        setTeamMemberData(teamList);
        setfilterStatus(teamList);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await userInstance().get("/appointment/filterCustomer");
      const { customers, code } = response.data;
      const customerFilter = customers.filter((el) => el?.firstname);
      customerFilter.sort((a, b) => a?.firstname.localeCompare(b?.firstname));
      if (code === 200) {
        setCustomerData(customers);
      }
    })();
  }, []);

  useEffect(() => {
    if (filterObj.locationId === "alllocation") {
      setLocationNameList("All Locations");
    } else {
      const findLocation = locationData.find(
        (el) => el._id === filterObj.locationId,
      );
      if (findLocation) {
        setLocationNameList(findLocation.locationName);
      }
    }
    if (filterObj.teammemberId === "allteam") {
      setTeamMemberName("All Team Member");
    } else {
      const findTeamMember = teamMemberData.find(
        (el) => el._id === filterObj.teammemberId,
      );
      if (findTeamMember) {
        setTeamMemberName(findTeamMember);
      }
    }
    if (filterObj.customerId === "allCustomer") {
      setCustomerList("All Customers");
    } else {
      const findCustomer = customerData.find(
        (el) => el._id === filterObj.customerId,
      );
      setCustomerList(findCustomer);
    }
  }, [filterObj.locationId, filterObj.teammemberId, filterObj.customerId]);

  const getPaymentFilter = async (e) => {
    const filterAppObj = filterAppointmentObj(filterObj);
    setappsummaryDate(filterAppObj.summaryDate);
    const filterTeamMemberId =
      filterObj.teammemberId !== "allteam"
        ? [filterObj.teammemberId]
        : filterObj.locationId === "alllocation"
          ? teamMemberData.map((el) => el._id)
          : teamMemberData
              .filter((el) => el.location === filterObj.locationId)
              .map((el) => el._id);
    const payload = {
      filterObj,
      filterAppObj,
      teamMemberIds: filterTeamMemberId,
    };
    const response = await userInstance().post(
      "/payment/getPaymentDetails",
      payload,
    );
    // console.log('response---', response.data);
    const { paymentData } = response.data;
    paymentData.sort((a, b) =>
      a.customer.firstname.localeCompare(b.customer.firstname),
    );
    setPaymentList(paymentData);
    let totalAmount = paymentData.map((el) => el.amount);
    var numberArray = totalAmount.map(Number);
    const sumAmount = numberArray
      .reduce((partial_sum, a) => partial_sum + a, 0)
      .toFixed(2);
    setPeriodTotal(sumAmount);
  };

  const sortData = (value) => {
    let copyList = [...paymentList];
    if (value === "clientName") {
      if (!check.clientName) {
        copyList.sort((a, b) =>
          b.customer.firstname.localeCompare(a.customer.firstname),
        );
        setPaymentList(copyList);
        setCheck({ ...check, clientName: true });
      } else {
        copyList.sort((a, b) =>
          a.customer.firstname.localeCompare(b.customer.firstname),
        );
        setPaymentList(copyList);
        setCheck({ ...check, clientName: false });
      }
    }

    if (value === "paymentDate") {
      if (!check.paymentDate) {
        copyList.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
        setPaymentList(copyList);
        setCheck({ ...check, paymentDate: true });
      } else {
        copyList.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
        setPaymentList(copyList);
        setCheck({ ...check, paymentDate: false });
      }
    }
    if (value === "invoiceNo") {
      if (!check.invoiceNo) {
        copyList.sort((a, b) =>
          b.invoice.invoiceNo.localeCompare(a.invoice.invoiceNo, undefined, {
            numeric: true,
          }),
        );
        setPaymentList(copyList);
        setCheck({ ...check, invoiceNo: true });
      } else {
        copyList.sort((a, b) =>
          a.invoice.invoiceNo.localeCompare(b.invoice.invoiceNo, undefined, {
            numeric: true,
          }),
        );
        setPaymentList(copyList);
        setCheck({ ...check, invoiceNo: false });
      }
    }
    if (value === "paymentMethod") {
      if (!check.paymentMethod) {
        copyList.sort((a, b) => b.paymentMethod.localeCompare(a.paymentMethod));
        setPaymentList(copyList);
        setCheck({ ...check, paymentMethod: true });
      } else {
        copyList.sort((a, b) => a.paymentMethod.localeCompare(b.paymentMethod));
        setPaymentList(copyList);
        setCheck({ ...check, paymentMethod: false });
      }
    }

    if (value === "paymentAmount") {
      if (!check.paymentAmount) {
        copyList.sort((a, b) =>
          b.amount.localeCompare(a.amount, undefined, { numeric: true }),
        );
        setPaymentList(copyList);
        setCheck({ ...check, paymentAmount: true });
      } else {
        copyList.sort((a, b) =>
          a.amount.localeCompare(b.amount, undefined, { numeric: true }),
        );
        setPaymentList(copyList);
        setCheck({ ...check, paymentAmount: false });
      }
    }
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setlocationList(null);
          let copyfilterObj = { ...filterObj };
          copyfilterObj.teamStatus = false;
          setFilterObj(copyfilterObj);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  useEffect(() => {
    getPaymentFilter(filterStatus);
  }, [filterStatus]);

  const getAllTemplate = async () => {
    try {
      const response = await userInstance().get(
        "/template/getAllTemplate?type=Invoice",
      );
      const { code, msg, temp } = response.data;
      if (msg === "Success") {
        const defaultTemp = temp.filter((el, i) => el.default);
        if (defaultTemp.length > 0) {
          setSelectTemplate(defaultTemp[0]._id);
        } else {
          setSelectTemplate(temp[0]._id);
        }
      }
      // setAllTemplates(temp);
      // handlePreviewPopup(temp[0]._id, 'detailed', true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTemplate();
  }, []);
  const handleInvoice = async (el, data) => {
    const info = selectTemplate;
    const response = await userInstance().get(
      `/template/previewTemplate?id=${info}&invoiceid=${el.invoice._id}&type=${data}`,
    );
    const { code, msg, tempId } = response.data;
    setPreviewUrl(tempId);
    setLoading(false);
    setPreviewPopup(true);
    // setInvoicePreview(true);
  };
  const handlePreviewPopup = () => {
    setPreviewPopup(!previewPopup);
  };

  return (
    <div className="appointment-summary  pageone-payment pagetwo-payment">
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Customer</Form.Label>
        <div className="select-box">
          <Form.Control
            as="select"
            onChange={(e) => handleOnchange(e.target)}
            name="customerId"
          >
            <option value="allCustomer">All Customers</option>
            {customerData.map((el) => (
              <option
                value={el?._id}
              >{`${el?.firstname} ${el?.lastname}`}</option>
            ))}
          </Form.Control>
        </div>
      </Form.Group>
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Payment Method</Form.Label>
        <div className="select-box">
          <Form.Control
            as="select"
            onChange={(e) => handleOnchange(e.target)}
            name="payment"
          >
            <option value="all">All Payment Methods</option>
            <option value="Visa/Mastercard">Visa/ Mastercard</option>
            <option value="EFT">EFT</option>
            <option value="Cash">Cash</option>
            <option value="Cheque">Cheque</option>
          </Form.Control>
        </div>
      </Form.Group>
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Location</Form.Label>
        <div className="select-box">
          <Form.Control
            as="select"
            onChange={(e) => handleOnchange(e.target)}
            name="locationId"
            value={filterObj.locationId}
          >
            <option value="alllocation">All Locations</option>
            {locationData.map((el) => (
              <option value={el._id}>{el?.locationName}</option>
            ))}
          </Form.Control>
        </div>
      </Form.Group>

      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Team Member</Form.Label>
        <div className="select-box">
          <Form.Control
            as="select"
            onChange={(e) => handleOnchange(e.target)}
            name="teammemberId"
            value={filterObj.teammemberId}
          >
            <option value="allteam">All Team Members</option>
            {teamMemberData.map((el) => (
              <>
                {filterObj.locationId === "alllocation" ? (
                  <option
                    value={el._id}
                  >{`${el?.firstname} ${el?.lastname}`}</option>
                ) : (
                  filterObj?.locationId === el?.location && (
                    <option
                      value={el._id}
                    >{`${el?.firstname} ${el.lastname}`}</option>
                  )
                )}
              </>
            ))}
          </Form.Control>
        </div>
      </Form.Group>

      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Date Range</Form.Label>
        <div className="select-box">
          <Form.Control
            as="select"
            value={filterObj.datetype}
            onChange={(e) => handleOnchange(e.target)}
            name="datetype"
          >
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="tomorrow">Tomorrow</option>
            <option value="week">This Week</option>
            <option value="month">This Month</option>
            <option value="year">This Year</option>
            <option value="lastweek">Last Week</option>
            <option value="lastyear">Last Year</option>
            <option value="nextweek">Next Week</option>
            <option value="nextyear">Next Year</option>
            <option value="weekdate">Week to Date</option>
            <option value="monthdate">Month to Date</option>
            <option value="yeardate">Year to Date</option>
            <option value="daterange">Date Range</option>
          </Form.Control>
        </div>
      </Form.Group>
      {filterObj.datetype === "daterange" ? (
        <div className="start-date-section">
          <Form.Group className="search-box">
            <div className="custom-datepicker" onClick={handleDatePopup}>
              {`${moment(filterObj?.rangeStartDate).format("ddd DD MMM yyyy")}`}
            </div>
          </Form.Group>
          <span>to</span>
          <Form.Group className="search-box">
            <div className="custom-datepicker">
              {" "}
              {`${moment(filterObj?.rangeEndDate).format("ddd DD MMM yyyy")}`}
            </div>
          </Form.Group>
        </div>
      ) : (
        ""
      )}

      <div className="appointment-summary-section">
        <div className="new-location">
          <p className="location-name">Customer Payment Details</p>
          <p className="location-light">
            {`${customerList === "All Customers" ? "All Customers" : `${customerList?.firstname} ${customerList?.lastname}`} | ${filterObj?.payment === "all" ? "All Payment Methods" : `${filterObj?.payment}`} | ${locationNameList}`}
          </p>
          <p className="location-light">
            {teamMemberName?.firstname
              ? `${teamMemberName?.firstname} ${teamMemberName?.lastname}`
              : "All Team Members"}
          </p>
          <p className="location-name">{appsummaryDate}</p>
        </div>

        <hr />
        <div className="details-body period-total">
          {periodTotal > 0.0 ? (
            <h6>
              {`Period Total: $${periodTotal.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ",",
              )}`}
            </h6>
          ) : (
            <h6>Period Total: $0.00</h6>
          )}
        </div>
        <hr />

        <div className="summary-table">
          <div className="summary-table-header">
            <h6 onClick={() => sortData("clientName")}>Client Name</h6>
            <h6 onClick={() => sortData("paymentDate")}>Payment Date</h6>
            <h6 onClick={() => sortData("invoiceNo")}>Invoice No.</h6>
            <h6 onClick={() => sortData("paymentMethod")}>Invoice Method</h6>
            <h6 onClick={() => sortData("paymentAmount")}>Payment Amount</h6>
          </div>

          {paymentList.length ? (
            paymentList?.map((el) => (
              <>
                <div className="summary-table-body">
                  {el && el?.customer && (
                    <p>
                      {el?.customer?.firstname} {el?.customer?.lastname}
                    </p>
                  )}
                  <p>{moment(el.createdAt).format("D MMM YYYY")}</p>
                  <p className="hyperLink-test">
                    <u onClick={() => handleInvoice(el, "detailed")}>
                      {el?.invoice?.invoiceNo}
                    </u>
                  </p>
                  <p>
                    {el?.paymentMethod === "Visa/Mastercard"
                      ? el?.paymentMethod.substring(5, 15)
                      : el?.paymentMethod}
                  </p>
                  <p>${parseFloat(el?.amount).toFixed(2)}</p>
                </div>
                <div className="location-text">
                  <p>
                    Payment Method: {el?.paymentMethod} | Location:{" "}
                    {el?.receivedBy?.location?.locationName} | Team Member:{" "}
                    {el?.paymentReceivedBy}
                  </p>
                </div>
                <hr />
              </>
            ))
          ) : (
            <>
              <div>
                <p>No Records Found</p>
              </div>
              <hr />
            </>
          )}
        </div>
      </div>
      <StartEndDate
        datePopup={datePopup}
        setDatePopup={setDatePopup}
        handleDatePopup={handleDatePopup}
        handleDateOnchange={handleDateOnchange}
        dateRange={dateRange}
        handleDateRange={handleDateRange}
      />

      <Modal
        size="md"
        show={previewPopup}
        onHide={handlePreviewPopup}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="search-modal new-search-popup1 preview-popup preview-pdf"
      >
        <Modal.Body>
          <div className="appointment-container preview-invoice preview-popup">
            <PreviewInvoiceDetails loading={loading} previewUrl={previewUrl} />
            <div className="close-btn">
              <Button className="btn btn-primary" onClick={handlePreviewPopup}>
                Close
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default CustomerPaymentDetailsTwo;
