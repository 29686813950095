import React, { useState, useEffect } from "react";
import { Button, Accordion, Card, Dropdown, Spinner } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "../../functions/moment";
import showmore from "../../assets/home/showmore.png";
import AddProductPopup from "./addProductPopup";
import { userInstance } from "../../config/axios";
import { currencyComma } from "../../functions";
import NoData from "../NoData/noData";

const Histories = () => {
  const [products, setProducts] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [paginationObj, setPaginationObj] = useState({ page: 1, limit: 10 });
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    const getHistoryData = async () => {
      try {
        const type = selectedTab !== "" ? `&type=${selectedTab}` : "";
        const response = await userInstance().get(
          `/inventory/inventory-history?limit=${paginationObj.limit}&page=${paginationObj.page}${type}`,
        );
        if (response.status === 200 && response.data.status === "success") {
          setProducts(response.data.data || []);
          setPageCount(response.data.totalPages);
        }
      } catch (error) {
        console.log("error---", error);
      }
    };
    getHistoryData();
  }, [selectedTab, paginationObj?.page]);

  const handleTabs = (tabs) => {
    setPaginationObj({ page: 1, limit: 10 });
    setSelectedTab(tabs);
  };

  const handlePageClick = ({ selected }) => {
    setPaginationObj({ page: selected + 1, limit: 10 });
  };

  return (
    <div className="product-service-container inventory-list add-product-page inventory-history-page">
      <div className="service-container">
        <div className="service-title">
          <h4>Services</h4>
          <span>
            <i className="fa fa-plus" />
          </span>
        </div>
      </div>
      <div className="table-data">
        <div className="service-mbl">
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <div className="service-container">
                  <div className="service-title title-service">
                    <h4>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Histories
                      </Accordion.Toggle>
                    </h4>
                    <div className="services-header">
                      <div className="content-dropdown">
                        <div className="client-option service-edit-icon">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              <img src={showmore} alt="show" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>
                                <div
                                  className="action"
                                  onClick={() => handleTabs("")}
                                >
                                  <span>All</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <div
                                  className="action"
                                  onClick={() => handleTabs("Add")}
                                >
                                  <span>Add</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <div
                                  className="action"
                                  onClick={() => handleTabs("Remove")}
                                >
                                  <span>Remove</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <div
                                  className="action"
                                  onClick={() => handleTabs("Order")}
                                >
                                  <span>Order</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <div
                                  className="action"
                                  onClick={() =>
                                    handleTabs("Adjustment-Increase")
                                  }
                                >
                                  <span>Adjustment Increase</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <div
                                  className="action"
                                  onClick={() =>
                                    handleTabs("Adjustment-Decrease")
                                  }
                                >
                                  <span>Adjustment Decrease</span>
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <div className="mob-table">
                  {products?.length > 0 ? (
                    <>
                      {products.map((el) => (
                        <Accordion className="mob-accordion outer-accordion">
                          <Card.Header className="sub-category">
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey="1"
                            >
                              <div className="product-service-list">
                                <i className="fas far fa-chevron-circle-up" />
                                <span>
                                  {el?.productId?.renderName ?? el?.name}
                                </span>
                              </div>
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="1">
                            <ul className="grid-list product-inventory-grid">
                              <li className="grid-list-item">
                                <div className="grid-box">
                                  <h3>Name </h3>
                                  <div className="grid-content">
                                    {" "}
                                    {el?.productId?.name}
                                  </div>
                                </div>
                              </li>
                              <li className="grid-list-item">
                                <div className="grid-box">
                                  <h3 className="">Price</h3>
                                  <div className="grid-content">
                                    $
                                    {currencyComma(
                                      parseFloat(el?.price).toFixed(2),
                                    )}
                                  </div>
                                </div>
                              </li>
                              <li className="grid-list-item">
                                <div className="grid-box">
                                  <h3 className="">Date</h3>
                                  <div className="grid-content">
                                    {" "}
                                    {moment(el?.date).format("D MMM, yyyy")}
                                  </div>
                                </div>
                              </li>
                              <li className="grid-list-item">
                                <div className="grid-box">
                                  <h3>Quantity</h3>
                                  <div className="grid-content">
                                    {el?.quantity}
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </Accordion.Collapse>
                        </Accordion>
                      ))}
                      {products?.length > 0 ? (
                        <>
                          <div className="user-pagination">
                            <ReactPaginate
                              previousLabel="← Previous"
                              nextLabel="Next →"
                              onPageChange={handlePageClick}
                              containerClassName="pagination-chk"
                              previousLinkClassName="pagination__link"
                              nextLinkClassName="pagination__link"
                              disabledClassName="pagination__link--disabled"
                              activeClassName="pagination__link--active"
                              breakLabel="..."
                              marginPagesDisplayed={1}
                              pageRangeDisplayed={0}
                              pageCount={pageCount}
                            />
                            <div className="no-of-page">
                              Page {paginationObj.page} of {pageCount}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : (
                    <NoData />
                  )}
                </div>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
    </div>
  );
};
export default Histories;
