import React from "react";
import { Form, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

const WaterVolumeSalinity = (props) => {
  const {
    poolInputs,
    handleInputs,
    result,
    chlorinationType,
    getValue,
    serviceAnalysis,
    selectedWaterLevelAmount,
    selectedWaterlevel,
    handleWaterLevelChange,
    handleAmountWaterLevel,
    waterPoolStatus,
    MaxLength,
    validationData,
    valueType,
  } = props;

  return (
    <>
      <Form.Group controlId="exampleForm.SelectCustom">
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>Original Salt Level</Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="text"
              className={`spec-depth ${validationData.originalSaltLvl && !poolInputs.originalSaltLvl ? "error-text" : ""} `}
              name="originalSaltLvl"
              value={poolInputs.originalSaltLvl}
              onChange={handleInputs}
              disabled={result}
              maxLength="5"
              placeholder={validationData.originalSaltLvl ? "Enter Data" : ""}
            />
          </Col>
        </Form.Row>
      </Form.Group>
      <Form.Group controlId="exampleForm.SelectCustom">
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label className="bags-label">{`Bags of ${chlorinationType === "Salt Pool" ? `Salt Added (${valueType ? "20 kg" : "40 lb"})` : getValue.includes("Magna") ? "Magna Minerals (10 kg)" : "Aquatherepe Minerals (10 kg)"}`}</Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="text"
              className={`spec-depth ${validationData.bagsSaltAdded && !poolInputs.bagsSaltAdded ? "error-text" : ""} `}
              name="bagsSaltAdded"
              value={poolInputs.bagsSaltAdded}
              onChange={handleInputs}
              disabled={result}
              maxLength="5"
              placeholder={validationData.bagsSaltAdded ? "Enter Data" : ""}
            />
          </Col>
        </Form.Row>
      </Form.Group>
      <Form.Group controlId="exampleForm.SelectCustom">
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>Current Salt Level</Form.Label>
          </Col>
          <Col>
            <Form.Control
              type="text"
              className={`spec-depth ${validationData.currentSaltLevel && !poolInputs.currentSaltLevel ? "error-text" : ""} `}
              name="currentSaltLevel"
              value={poolInputs.currentSaltLevel}
              onChange={handleInputs}
              disabled={result}
              maxLength="5"
              placeholder={validationData.currentSaltLevel ? "Enter Data" : ""}
            />
          </Col>
        </Form.Row>
      </Form.Group>
      <Form.Group
        controlId="exampleForm.SelectCustom"
        className={`${serviceAnalysis === "Pool Water Volume Verification via Salinity" && waterPoolStatus ? "delete-icon" : ""}`}
      >
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>Change in Water Level</Form.Label>
          </Col>

          <Col>
            <div className="select-box">
              <Form.Control
                as="select"
                custom
                onChange={handleWaterLevelChange}
                value={selectedWaterlevel}
                disabled={
                  serviceAnalysis ===
                    "Pool Water Volume Verification via Salinity" &&
                  waterPoolStatus
                }
              >
                <option value="Same Water Level in Pool">
                  Same Water Level in Pool
                </option>
                <option value="Higher Water Level in Pool">
                  Higher Water Level in Pool
                </option>
                <option value="Lower Water Level in Pool">
                  Lower Water Level in Pool
                </option>
              </Form.Control>
            </div>
          </Col>
        </Form.Row>
      </Form.Group>
      {selectedWaterlevel === "Same Water Level in Pool" ? null : (
        <Form.Group controlId="exampleForm.SelectCustom">
          <Form.Row>
            <Col column="xs" md={7} xs={5}>
              <Form.Label>
                {selectedWaterlevel === "Higher Water Level in Pool"
                  ? "Increased Amount"
                  : "Decreased Amount"}
              </Form.Label>
            </Col>

            <Col>
              {/* {serviceAnalysis === 'Pool Water Volume Verification via Salinity' && waterPoolStatus ? '' : (
                <i className="fas fa-sort-down" />
              )} */}
              {/* <Form.Control
                as="select"
                custom
                onChange={handleAmountWaterLevel}
                value={selectedWaterLevelAmount}
                disabled={serviceAnalysis === 'Pool Water Volume Verification via Salinity' && waterPoolStatus}
              >

                { Array.from({ length: MaxLength }, (_, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <option key={i} value={i + 1}>
                    {`${selectedWaterlevel === 'Higher Water Level in Pool' ? '+' : '-'}${i + 1} mm` }
                  </option>
                ))}

              </Form.Control> */}
              <Form.Group controlId="formBasicInput-6" className="custom-input">
                <span className="cholinator-text">{`${selectedWaterlevel === "Higher Water Level in Pool" ? "+" : "-"}`}</span>
                {/* <Form.Control type="number" name="okNegative" /> */}
                <Form.Control
                  type="text"
                  name="increaseDecreaseAmount"
                  className={`form-control spec-depth ${validationData.increaseDecreaseAmount && !poolInputs.increaseDecreaseAmount ? "redBorder" : ""}`}
                  maxLength="3"
                  onChange={handleInputs}
                  value={poolInputs.increaseDecreaseAmount}
                  disabled={result}
                />
                <span className="mm">mm</span>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form.Group>
      )}
      {!waterPoolStatus ? null : (
        <Form.Group>
          <Form.Row>
            <Col column="xs" md={7} xs={5}>
              <Form.Label>Actual Pool Size:</Form.Label>
            </Col>
            <Col>
              <Form.Label className="liters actual-size">
                {`${`${
                  poolInputs.poolWaterCapacity &&
                  poolInputs.poolWaterCapacity
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                } ${!valueType ? "gallons" : "litres"}`}`}
              </Form.Label>
            </Col>
          </Form.Row>
        </Form.Group>
      )}
      {waterPoolStatus ? null : (
        <div className="testing-method">
          <h6>Testing Method</h6>
          <p>
            <span>1.</span> Measure salt level of the pool prior to adding salt
            or minerals to the water.
          </p>
          <p>
            <span>2.</span> Take a photo or place tape at the pool wall that
            indicates the current water level in the pool.
          </p>
          <p>
            <span>3.</span> Run the filtration for 2 weeks x 8 hours per day
            (minimum) to circulate salt or minerals added. Do not backwash or
            drain water from the pool during this period
          </p>
          <p>
            <span>4.</span> Re-test for salt, input all fields above and click
            the calculate button. Page two will load to display the actual pool
            volume.
          </p>
          <p className="alert-text">
            <span>*</span> The Pool Water Volume via Salinity test also
            compensates for water loss or gain due to evaporation or rainfall.
            Do not top-up the pool with water within two 2 days of performing
            the original or final salt test.
          </p>
        </div>
      )}
    </>
  );
};

WaterVolumeSalinity.propTypes = {
  poolInputs: PropTypes.func.isRequired,
  handleInputs: PropTypes.func.isRequired,
  result: PropTypes.bool.isRequired,
  chlorinationType: PropTypes.string.isRequired,
  getValue: PropTypes.string.isRequired,
  serviceAnalysis: PropTypes.string.isRequired,
  selectedWaterLevelAmount: PropTypes.string.isRequired,
  selectedWaterlevel: PropTypes.string.isRequired,
  handleWaterLevelChange: PropTypes.func.isRequired,
  handleAmountWaterLevel: PropTypes.func.isRequired,
  waterPoolStatus: PropTypes.bool.isRequired,
  MaxLength: PropTypes.number.isRequired,
  validationData: PropTypes.func.isRequired,
  valueType: PropTypes.bool.isRequired,
};

export default WaterVolumeSalinity;
