import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars-2";
import moment from "moment";
import { SyncLoader } from "react-spinners";
import markdownit from "markdown-it";
import DOMPurify from "dompurify";
import { isMobile } from "react-device-detect";
import TextareaAutosize from "react-textarea-autosize";
import { FaArrowCircleUp } from "react-icons/fa";
import { VscDebugRestart } from "react-icons/vsc";
import { IoMdClose } from "react-icons/io";
import { chatPath } from "../../config/keys";
import "./index.css";

const md = markdownit({
  html: true,
  linkify: true,
  typographer: true,
});

const parseGreeting = (greeting) => {
  let result = "";
  if (isMobile) {
    result = greeting.replaceAll("#-w-b", "\n").replaceAll("#-m-b", "\n");
  } else {
    result = greeting.replaceAll("#-w-b", "\n").replaceAll("#-m-b", "");
  }
  return result;
};

const ChatBot = (props) => {
  const { handleClose, countryName, chatbotInfos } = props;
  const [messages, setMessages] = useState([
    {
      type: "bot",
      text: parseGreeting(chatbotInfos.greeting),
      timestamp: moment().format("hh:mm"),
    },
  ]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const [allContent, setAllContent] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const messagesEndRef = useRef(null);
  const scrollbarsRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollToBottom();
    }
  };

  const sendQuestion = () => {
    if (input.trim() === "" || isLoading) return;

    setIsChat(true);
    setIsLoading(true);
    setErrorMessage("");

    const newMessage = {
      type: "user",
      text: input,
      timestamp: moment().format("hh:mm"),
    };

    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);

    scrollToBottom();
    sendMessages(updatedMessages);
  };

  const sendMessages = async (updatedMessages) => {
    setIsLoading(true); // Start loading
    setInput("");
    try {
      const systemPrompt = `Here is User's information:\n${countryName}\n`;

      const response = await fetch(`${chatPath}/webapp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("s#@4f")
            ? `${"Bearer "}${localStorage.getItem("s#@4f")}`
            : "",
          authtoken: localStorage.getItem("f#@5e")
            ? `${"Bearer "}${localStorage.getItem("f#@5e")}`
            : "",
        },
        timeout: 1000 * 20,
        body: JSON.stringify({
          prefix: systemPrompt,
          messages: updatedMessages,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      let accumulatedText = ""; // Declare outside loop

      const processChunk = (chunk) => {
        accumulatedText += chunk;
        setMessages((prevMessages) => {
          const lastMessage = prevMessages[prevMessages.length - 1];
          if (lastMessage.type === "user") {
            return [
              ...prevMessages,
              {
                type: "bot",
                text: accumulatedText, // Use captured value
                timestamp: moment().format("hh:mm"),
              },
            ];
          }
          if (lastMessage.type === "bot") {
            const updatedMessagesList = [...prevMessages];
            updatedMessagesList[updatedMessagesList.length - 1].text =
              accumulatedText;
            return updatedMessagesList;
          }
          return prevMessages;
        });
      };
      setIsLoading(false);
      await (async () => {
        while (true) {
          // eslint-disable-line no-constant-condition
          const { done, value } = await reader.read(); // eslint-disable-line no-await-in-loop
          if (done) break;
          const chunk = decoder.decode(value, { stream: true });
          processChunk(chunk); // Call function instead of inline state update
        }
      })();
    } catch (error) {
      console.error("Chatbot Error:", error.message);
      setErrorMessage(
        "There was an error sending this message. If the issue persists, email the support team at service@poolwaterpro.com.",
      );
      setIsLoading(false);
    } finally {
      setIsChat(false);
    }
  };

  const handleKeyDown = (e) => {
    if (!isMobile) {
      if (e.key === "Enter" && e.shiftKey) {
        e.preventDefault();
        setInput(`${input}\n`);
      } else if (e.key === "Enter") {
        e.preventDefault();
        sendQuestion();
      }
    }
  };

  const handleClearChathistory = () => {
    setMessages([
      {
        type: "bot",
        text: parseGreeting(chatbotInfos.greeting),
        timestamp: moment().format("hh:mm"),
      },
    ]);
    setErrorMessage("");
  };

  return (
    <section className="avenue-messenger">
      <div className="menu">
        <VscDebugRestart
          className="chatbot-menu-button"
          onClick={handleClearChathistory}
          size={22}
          color="#7a7b7f"
        />
        <IoMdClose
          className="chatbot-menu-button"
          onClick={handleClose}
          size={24}
          color="#7a7b7f"
        />
      </div>
      <div className="agent-face">
        <div className="half">
          <img
            className="agent chatbot-circle"
            src={chatbotInfos.avatar}
            alt="Jesse Tino"
          />
        </div>
      </div>
      <div
        className="chat"
        style={{
          height: isMobile ? "calc( 100% - 33px )" : "calc( 100% - 15px )",
        }}
      >
        <div className="chat-title">
          <span>{chatbotInfos.name}</span>
        </div>
        <div className="messages">
          <Scrollbars
            ref={scrollbarsRef}
            className="messages-content"
            onScroll={(e) => {
              e.stopPropagation();
            }}
          >
            {messages.map((msg, index) => (
              <div
                key={msg.timestamp + index.toString()}
                className={`message ${msg.type === "user" ? "message-personal" : "new"}`}
              >
                {msg.type === "bot" && (
                  <figure className="avatar">
                    <img src={chatbotInfos.avatar} alt="Bot Avatar" />
                  </figure>
                )}
                <div
                  className="message-box-content"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(md.render(msg.text)),
                  }}
                />
              </div>
            ))}
            {isChat && isLoading && (
              <div className="message new">
                <figure className="avatar">
                  <img src={chatbotInfos.avatar} alt="Bot Avatar" />
                </figure>
                <SyncLoader
                  size={6}
                  color="grey"
                  loading={isLoading}
                  speedMultiplier={0.7}
                />
              </div>
            )}
            {errorMessage && (
              <div className="message new">
                <figure className="avatar">
                  <img src={chatbotInfos.avatar} alt="Bot Avatar" />
                </figure>
                <div
                  className="message-box-content"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(md.render(errorMessage)),
                  }}
                />
              </div>
            )}
            <div ref={messagesEndRef} />
          </Scrollbars>
        </div>
        <div className="message-box">
          <TextareaAutosize
            className="message-input"
            placeholder="Type your question here…"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            maxRows={10}
          />
          <button
            type="submit"
            aria-label="Send Message"
            className="message-submit"
            onClick={sendQuestion}
            disabled={isLoading}
          >
            <FaArrowCircleUp size={28} color="black" />
          </button>
        </div>
      </div>
    </section>
  );
};

ChatBot.propTypes = {
  handleClose: PropTypes.func.isRequired,
  countryName: PropTypes.string.isRequired,
  chatbotInfos: PropTypes.objectOf.isRequired, // Fixed typo: objectOf to object
};

export default ChatBot;
