/* eslint-disable consistent-return */
import React, { useState, useRef, useEffect, useContext } from "react";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import moment from "../../functions/moment";
import arrow from "../../assets/home/arrowdown.png";
import sort from "../../assets/home/sort.png";
import {
  showErrMsg,
  addZero,
  averageGstCalculation,
  removeComma,
} from "../../functions/index";
import { userInstance } from "../../config/axios";
import PreviewQuote from "./previewQuote";
import ServiceModal from "../appointment/servicemodal";
import TimePicker from "../appointment/timepicker";
import DateModal from "./datePopup";
import upsort from "../../assets/home/up-sort.png";
import downsort from "../../assets/home/down-sort.png";
import DurationPicker from "../appointment/durationPicker";
import HourPicker from "../appointment/hourPicker";
import { AuthDataContext } from "../../contexts/AuthDataContext";
import {
  errorBorderTime,
  errorMsgTime,
  successMsgTime,
} from "../../functions/intervalTime";
import SMSOption from "./smsOption";

const QuoteDetail = ({
  addClient,
  setQuoteType,
  saveQuoteType,
  showQuote,
  setAddClient,
  setSelectedClient,
  activeAdd,
  setActiveAdd,
  setsaveQuoteType,
}) => {
  const history = useHistory();
  const {
    locationsInfo,
    userData: { industryId },
  } = useContext(AuthDataContext);
  const { companyContactDetail, companyInformation } = locationsInfo || {};
  const { address, companyName, postcode } = companyInformation || {};
  let cstate = companyInformation ? companyInformation.state : "";
  const { mobile, email } = companyContactDetail || {};
  let companyaddress = `${address || ""} ${companyInformation?.suburb || ""} ${cstate || ""} ${postcode || ""}`;
  let companyLocations = companyaddress !== "  " ? companyaddress : "";

  const [preview, setPreview] = useState();
  const [datePopup, setDatePopup] = useState();
  const [gst, setGst] = useState("");
  const [quotesTotal, setQuotesTotal] = useState("");
  const [quoteSign, setQuoteSign] = useState("");
  const [templateArray, setTemplateArray] = useState([]);
  const [templateType, setTemplateType] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [customFeilds, setCustomFeilds] = useState();
  const [smsOption, setSMSOption] = useState(false);
  const dateFormat = "YYYY-MM-DDTHH:mm:ssZ";
  const timeFromDateFormat = "HH:mm";
  let setDefaultTime = "9:30";
  const initialBookingData = {
    frequency: "One-off appointment | Doesn't repeat",
    repeatEnd: "Ongoing",
    category: "",
    subcategory: "",
    startTime: setDefaultTime,
    duration: "5",
    staffId: "",
    staffName: "",
    clientId: "",
    cost: "",
    start: "",
    end: "",
    customCost: "default",
    type: "",
    buyCostProduct: "",
    laborMargin: "",
    quantity: "1",
    timeChange: false,
    customMisc: "",
    previousDuration: "",
    previousQuantity: "",
    pricingType: "fixedCost",
    previousCost: "",
    prevBuyCostProduct: "",
    renderCost: "",
    soldPer: "",
    unit: "",
    symbol: "",
    description: "",
    tax: "",
    s_uId: "",
    categoryId: "",
    supplier: "",
  };
  const otherBookingInitial = {
    category: "",
    subcategory: "",
    duration: "5",
    cost: "",
    start: "",
    end: "",
    customCost: "default",
    type: "",
    buyCostProduct: "",
    laborMargin: "",
    quantity: "1",
    customMisc: "",
    previousDuration: "",
    previousQuantity: "",
    pricingType: "fixedCost",
    soldPer: "",
    unit: "",
    symbol: "",
    tax: "",
    s_uId: "",
    categoryId: "",
    supplier: "",
  };
  const initialDatePopup = {
    interval_type: "weekly",
    interval_multiplier: "4",
  };
  const initialDateData = {
    date: moment().format(dateFormat),
    createdOn: new Date(),
    interval_multiplier: null,
    occurence: null,
    interval_type: null,
    endsAt: null,
    endType: null,
    msg: "One-off appointment | Doesn't repeat",
    frequencyMsg: "",
    occurenceMsg: "",
    specificDate: moment().format(dateFormat),
    requiredday: null,
    appointmenttype: "oneteam_oneday",
    service: [],
  };
  const multiNotesState = [{ notes: null }];
  const [multiNotes, setMultiNotes] = useState(multiNotesState);
  const [showService, setShowService] = useState(false);
  const [bookingData, setBookingData] = useState(initialBookingData);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState(null);
  const [staffList, setStaffList] = useState([]);
  const [addedServices, setServices] = useState([]);
  const [editData, setEditData] = useState({ edit: false, index: "" });
  const [notes, setNotes] = useState("");
  const [hourPicker, setHourPicker] = useState(false);
  const [hourData, setHourData] = useState("");
  const [summary, setSummary] = useState({
    totalCost: 0,
    totalDuration: "",
    totalLabourMargin: 0,
  });

  const [dateData, setDateData] = useState(initialDateData);
  const [renderSort, setRenderSort] = useState(false);
  const [misc, setMisc] = useState(false);
  const [extras, setExtras] = useState({ value: "", msg: "" });
  const [timepickerShow, setTimePickerShow] = useState(false);
  const [subcategoryTxt, setSearchTxt] = useState("");
  const [durationPicker, setDurationPicker] = useState(false);
  const [timeData, setTime] = useState("9:30");
  const [durationData, setDuration] = useState("");
  const [repeatFrequency, setRepeatFrequency] = useState("");
  const [datePopupData, setDatePopupData] = useState(initialDatePopup);
  const timepickerRef = useRef(null);

  const [newLength, setnewlength] = useState();
  const [staffIndex, setStaffIndex] = useState();
  const [quoteDate, setQuoteDate] = useState(new Date());
  const [appointmentType, setAppointmentType] = useState("oneteam_oneday");
  const [multiServices, setMultiServices] = useState([]);
  const [multiAppointment, setmultiAppointment] = useState(false);
  const [newAppointment, setNewAppointment] = useState([initialDateData]);
  const [appointmentIndex, setappointmentIndex] = useState("");
  const [quoteNumber, setQuoteNumber] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [previewPopup, setPreviewPopup] = useState();
  const [success, setSuccess] = useState(false);
  const [signatureList, setSignatureList] = useState([]);
  const [quoteurl, setquoteUrl] = useState("/quotes/addQuotes");
  const [erroValue, setErrorValue] = useState("");
  const loginStaff = localStorage.getItem("loginStaff");

  const [signStaff, setSignStaff] = useState({
    name: "",
    type: "",
    signature: "",
    complimentary: "",
  });
  const handleSaveDate = () => {
    updateDateData();
    handleFrequency();
  };

  const isValidDate = (d) => d instanceof Date && !Number.isNaN(d);
  const handleDatePopup = (e) => {
    if (isValidDate(e)) {
      setDateData({ ...dateData, date: e });
      setQuoteDate(e);
    }
    setDatePopup(!datePopup);
  };

  const setStaff = (staffName, staffId) => {
    const servicesListCopy = addedServices;
    servicesListCopy.forEach((el) => {
      el.staffId = staffId;
      el.staffName = staffName;
    });
    setServices([...servicesListCopy]);
  };

  const updateDateData = (isEndType) => {
    let { interval_type, interval_multiplier } = datePopupData;
    // console.log('interval_type', interval_type);
    const originalIntervalType = interval_type;
    let frequencyMsg = "";
    if (parseInt(interval_multiplier, 10) > 1) {
      switch (interval_type) {
        case "monthly":
          interval_type = "months";
          break;
        case "weekly":
          interval_type = "weeks";
          break;
        case "daily":
          interval_type = "days";
          break;

        default:
          break;
      }
    }
    frequencyMsg =
      parseInt(interval_multiplier, 10) > 1
        ? `Repeats every ${interval_multiplier} ${interval_type}`
        : interval_type;
    const updateObj = {
      ...dateData,
      interval_multiplier,
      interval_type: originalIntervalType,
      frequencyMsg,
    };
    if (isEndType) {
      updateObj.endType = "ongoing";
    }
    setDateData(updateObj);
  };

  const handleChangeDate = (type, value) => {
    setDatePopupData({ ...datePopupData, [type]: value });
  };

  const handleFrequency = () => {
    setRepeatFrequency(!repeatFrequency);
    if (!repeatFrequency) {
      setTimeout(() => {
        timepickerRef.current.value = `${datePopupData.interval_multiplier}:00`;
      }, 350);
    }
  };
  const handleTimePicker = () => {
    setTime(bookingData.startTime);
    setTimePickerShow(!timepickerShow);
  };

  const handleDurationPicker = () => {
    setDuration(bookingData.duration);
    setDurationPicker(!durationPicker);
  };
  const handleHourPicker = () => {
    setHourData(bookingData.quantity);
    setHourPicker(!hourPicker);
  };

  const moveArr = (oldIndex, newIndex, sign) => {
    const servicesCopy = [...addedServices];
    const { duration, start, startTime } = addedServices[newIndex];

    let newNextStartTime;
    let currentNewStartTime;

    if (sign === "plus") {
      // console.log('plus');
      const currentEntryStart = new Date(servicesCopy[oldIndex].start);
      const updatedNextEntryStart = moment(
        new Date(
          currentEntryStart.setMinutes(
            currentEntryStart.getMinutes() + parseFloat(duration),
          ),
        ),
      ).format(dateFormat);
      // console.log('updatedNextEntryStart', updatedNextEntryStart);
      newNextStartTime = moment(updatedNextEntryStart).format(
        timeFromDateFormat,
      );

      currentNewStartTime = servicesCopy[oldIndex].startTime;
    } else if (sign === "minus") {
      // console.log('minus');
      newNextStartTime = startTime;

      const updatedCurrentEntryStart = moment(
        new Date(
          new Date(start).setMinutes(
            new Date(start).getMinutes() +
              parseFloat(servicesCopy[oldIndex].duration),
          ),
        ),
      ).format(dateFormat);
      // console.log('updatedCurrentEntryStart', updatedCurrentEntryStart);

      currentNewStartTime = moment(updatedCurrentEntryStart).format(
        timeFromDateFormat,
      );
    }

    // console.log('nextEntryStartTime', newNextStartTime);
    // console.log('currentNewStartTime', currentNewStartTime);

    const updatedOldIndex = {
      ...addedServices[oldIndex],
      startTime: newNextStartTime,
    };
    const updatedNewIndex = {
      ...addedServices[newIndex],
      startTime: currentNewStartTime,
    };
    // console.log('updatedOldIndex', updatedOldIndex, 'updatedNewIndex', updatedNewIndex);
    servicesCopy[oldIndex] = { ...updatedOldIndex };
    servicesCopy[newIndex] = { ...updatedNewIndex };

    if (newIndex >= servicesCopy.length) {
      let k = newIndex - servicesCopy.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        servicesCopy.push(undefined);
      }
    }
    servicesCopy.splice(newIndex, 0, servicesCopy.splice(oldIndex, 1)[0]);
    // console.log('servicesCopy', servicesCopy);
    calculateDurations(servicesCopy);
    // setServices([...servicesCopy]);
  };
  const handleChangeCustom = (value) => {
    const obj = { ...bookingData, customCost: value };
    if (value === "default") {
      obj.cost = bookingData.previousCost;
      if (bookingData.type === "product") {
        obj.buyCostProduct = bookingData.prevBuyCostProduct;
      }
    } else if (value === "yes" || value === "sellPrice") {
      obj.cost = "";
      obj.buyCostProduct = obj.prevBuyCostProduct;
    } else if (value === "buyCost") {
      obj.buyCostProduct = "";
      obj.cost = obj.previousCost;
    } else if (value === "buySell") {
      obj.cost = "";
      obj.buyCostProduct = "";
    }
    setBookingData(obj);
  };
  const handleShowService = (index) => {
    // console.log('indexindex', index);
    setappointmentIndex(index);
    setStaffIndex(0);
    setnewlength(addedServices.length);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    setShowService(!showService);
    setEditData({ edit: false, index: "" });
    const lastService = addedServices[addedServices.length - 1];
    if (lastService) {
      // console.log('lastService', lastService);
      const startTime = moment(lastService.end).format(timeFromDateFormat);
      setBookingData({ ...bookingData, startTime, ...otherBookingInitial });
    } else {
      setBookingData({ ...bookingData, ...otherBookingInitial });
    }
    setSearchTxt("");
    setSubcategories(null);
    setMisc(false);
  };

  const handleBookingData = (e) => {
    const { name, value } = e.target;
    const obj = { ...bookingData, [name]: value };
    if (name === "customMisc") {
      obj.description = value;
    }
    if (name === "staffName") {
      const staffId = staffList[e.target.selectedIndex]._id;
      obj[name] = value;
      obj.staffId = staffId;
      if (
        appointmentType === "oneteam_oneday" ||
        appointmentType === "oneteam_multiday"
      ) {
        setStaff(value, staffId);
      }
    }
    if (name === "cost" && !bookingData.customCost) {
      const newval = addZero(value);
      obj[name] = newval;
    } else if (name === "startTime") {
      obj.timeChange = true;
    } else if (name === "duration") {
      obj.durationChange = true;
    } else if (name === "quantity") {
      obj.quantityChange = true;
    } else if (name === "pricingType") {
      obj.duration = bookingData.previousDuration;
      obj.quantity = "5";
      if (bookingData.customCost !== "default") {
        obj.cost = "";
      }
    } else if (name === "soldPer" && value !== "Add Custom Measure") {
      obj.unit = "";
      obj.symbol = "";
    }
    setBookingData(obj);
  };

  const addSubcategoryToService = (value) => {
    // console.log('kkkkkkkkkkkkkk', value);
    let kquantity = "1";
    const selectedCategory = categories.filter(
      (el) => el.subCategory.filter((sub) => sub.name === value).length,
    );
    const subcategoryData = selectedCategory[0].subCategory.filter(
      (el) => el.name === value,
    );
    // console.log('subcategoryData', subcategoryData[0]);
    const { type, category } = selectedCategory[0];
    const {
      duration,
      cost,
      soldPer,
      unit,
      symbol,
      serviceDescription,
      tax,
      s_uId,
      categoryId,
      supplier,
    } = subcategoryData[0];

    let customCostData = "default";
    let newCost = addZero(
      subcategoryData[0][type === "product" ? "retailCost" : "cost"],
    );
    let newBuyCost = type === "product" ? cost : "";

    if (selectedCategory[0].category.toLowerCase().includes("misc")) {
      setMisc(true);
      customCostData = type === "product" ? "buySell" : "yes";
      newCost = "";
      newBuyCost = "";
    }
    let soldPerValue = soldPer || "";
    if (type === "product") {
      if (soldPer === "Add Custom Measure") {
        soldPerValue = `${unit}(${symbol})`;
      }
    }
    if (value.toLowerCase().includes("misc")) {
      console.log(value);
      if (type === "product") {
        kquantity = "";
        bookingData.quantity = kquantity;
      }
      // setBookingData({ ...bookingData, quantity: '' });
    }
    setBookingData({
      ...bookingData,
      category,
      subcategory: value,
      cost: newCost,
      duration,
      type,
      quantity: kquantity,
      buyCostProduct: newBuyCost,
      customCost: customCostData,
      timeChange: false,
      customMisc: "",
      previousDuration: "",
      previousQuantity: "",
      pricingType: "fixedCost",
      previousCost: newCost,
      prevBuyCostProduct: newBuyCost,
      soldPer: soldPerValue,
      description: serviceDescription || value,
      tax,
      s_uId,
      categoryId,
      supplier,
    });
    setSearchTxt(value);
    setSubcategories(null);
  };

  const getCategories = async () => {
    try {
      const response = await userInstance().get("/service/getServices");
      const { services } = response.data;
      setCategories(services);
      const staffData = await getStaffList();
      const objToUpdate = {
        ...bookingData,
      };
      if (staffData !== null && staffData !== undefined) {
        objToUpdate.staffName = staffData.staffName;
        objToUpdate.staffId = staffData.staffId;
      }
      setBookingData(objToUpdate);
    } catch (error) {
      //   console.log(error);
    }
  };
  const getAllTemplate = async (info) => {
    try {
      const response = await userInstance().get(
        "/template/getAllTemplate?type=quote",
      );
      const { temp } = response.data;
      if (saveQuoteType !== "editquote") {
        const defaultTemp = temp.filter((el, i) => el.default);
        if (defaultTemp.length > 0) {
          setTemplateType(defaultTemp[0]._id);
          setSelectedTemplate(defaultTemp[0].currentTemplateData);
          setCustomFeilds(defaultTemp[0].customFeilds);
        } else {
          setTemplateType(temp[0]._id);
          setSelectedTemplate(temp[0].currentTemplateData);
          setCustomFeilds(temp[0].customFeilds);
        }
      } else {
        const activeTemp = temp.filter(
          (el) => el._id === showQuote.templateType,
        );
        if (activeTemp.length > 0) {
          setSelectedTemplate(activeTemp[0].currentTemplateData);
          setCustomFeilds(activeTemp[0].customFeilds);
        } else {
          const defaultTemp = temp.filter((el, i) => el.default);
          setTemplateType(defaultTemp[0]._id);
          setSelectedTemplate(defaultTemp[0].currentTemplateData);
          setCustomFeilds(defaultTemp[0].customFeilds);
        }
      }
      setTemplateArray(temp);
    } catch (error) {
      console.log(error);
    }
  };

  const getTimes = () => g();

  const g = () => {
    const x = {
      slotInterval: 5,
      openTime: "12:25 AM",
      closeTime: "23:30 PM",
    };

    // Format the time
    const startTime = moment(x.openTime, "h:mm A");

    // Format the end time and the next day to it
    const endTime = moment(x.closeTime, "h:mm A");

    // Times
    const allTimes = [];

    // Loop over the times - only pushes time with 30 minutes interval
    while (startTime <= endTime) {
      // Push times
      allTimes.push(startTime.format("h:mm A"));
      // Add interval of 30 minutes
      startTime.add(x.slotInterval, "minutes");
    }
    return allTimes;
  };

  const renderOptions = () => {
    let val = 5;
    const arr = [];
    while (val <= 960) {
      const hours = Math.floor(val / 60);
      const minutes = val % 60;
      const msg =
        hours && minutes
          ? `${hours}hr ${minutes} min`
          : hours && !minutes
            ? `${hours}hr`
            : `${minutes} min`;
      arr.push({ value: val, data: msg });
      val += 5;
    }
    return arr;
  };

  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msg = "";
    msg =
      hours && minutes
        ? `${hours}hr ${minutes} min`
        : hours && !minutes
          ? `${hours}hr`
          : `${minutes} min`;
    return msg;
  };

  const getStaffList = async () => {
    try {
      const response = await userInstance().get("/staff/getStaff");
      const { staff } = response.data;
      setStaffList(staff);
      if (saveQuoteType === "editquote") {
        const selectedStaff = staff.filter(
          (_el, i) => _el._id === showQuote.staff,
        );
        console.log("edit quote here...");
        return {
          staffName: `${selectedStaff[0].firstname} ${selectedStaff[0].lastname}`,
          staffId: selectedStaff[0]._id,
        };
      }
      if (staff.length) {
        const selectedStaff = staff.filter((_el, i) => _el._id === loginStaff);
        return {
          staffName: `${selectedStaff[0].firstname} ${selectedStaff[0].lastname}`,
          staffId: selectedStaff[0]._id,
        };
      }
    } catch (error) {
      //   console.log(error);
      return null;
    }
  };

  const getSignatureListList = async () => {
    try {
      const response = await userInstance().get("/staff/getAllSignatureList");
      const { list, code } = response.data;
      if (code === 200) {
        setSignatureList(list);
        if (list.length > 0) {
          console.log("==>", saveQuoteType);
          if (saveQuoteType === "editquote") {
            console.log("mmmmm");
            const activeStaff = list.filter(
              (el) => el._id === showQuote?.associateName,
            );
            const obj = {
              name: `${activeStaff[0].name}` || "",
              type: `${activeStaff[0].title}` || "",
              signature: activeStaff[0].image || "",
              complimentary: `${activeStaff[0].complimentary}` || "",
              mobileNo: `${activeStaff[0].mobileNo}` || "",
              email: `${activeStaff[0].email}` || "",
            };
            setSignStaff(obj);
            setQuoteSign(activeStaff[0]._id);
          } else {
            const obj = {
              name: `${list[0].name}` || "",
              type: `${list[0].title}` || "",
              signature: list[0].image || "",
              complimentary: `${list[0].complimentary}` || "",
              mobileNo: `${list[0].mobileNo}` || "",
              email: `${list[0].email}` || "",
            };
            setSignStaff(obj);
            setQuoteSign(list[0]._id);
          }
        }
      }
    } catch (error) {
      //   console.log(error);
      return null;
    }
  };
  const pad = (str, max) => {
    str = str.toString();
    return str.length < max ? pad(`0${str}`, max) : str;
  };

  const convertHours = (mins) => {
    const hour = Math.floor(mins / 60);
    var mins = mins % 60;
    const converted = `${pad(hour, 2)}:${pad(mins, 2)}`;
    return converted;
  };

  const calculate_time_slot = (start_time, end_time, interval = "30") => {
    var i;
    let formatted_time;
    const time_slots = new Array();
    for (var i = start_time; i <= end_time; i += interval) {
      formatted_time = convertHours(i);
      time_slots.push(formatted_time);
    }
    return time_slots;
  };

  const getHourWithQuantity = (duration, multiplier) => {
    // console.log('multiplier', multiplier);
    let hourPart = duration.includes("hr")
      ? parseFloat(duration.split("hr")[0].trim())
      : 0;
    let minutePart =
      duration.includes("hr") && duration.includes("min")
        ? parseFloat(duration.split("hr")[1].split("min")[0])
        : duration.includes("min")
          ? parseFloat(duration.split("min")[0])
          : 0;

    let hourWithQuantity = hourPart * multiplier;
    // console.log('hourWithQuantity', hourWithQuantity);
    return { hourWithQuantity, hourPart, minutePart };
  };

  const calculateServiceLabour = (bookingDataCopy) => {
    const convertedDuration =
      bookingDataCopy.pricingType === "hourly"
        ? bookingDataCopy.quantity
        : bookingDataCopy.duration;

    let hour = convertToReadableDuration(
      addQuantityDuration(convertedDuration, parseFloat("1")),
    );
    let { hourWithQuantity, minutePart } = getHourWithQuantity(
      hour,
      bookingDataCopy.cost,
    );

    minutePart = (minutePart / 60) * parseFloat(bookingDataCopy.cost);
    let finalDuration = parseFloat(hourWithQuantity + minutePart);

    // eslint-disable-next-line no-mixed-operators
    finalDuration = !Number.isInteger(finalDuration / 60)
      ? Math.ceil(finalDuration / 5) * 5
      : finalDuration;

    return parseFloat(finalDuration).toFixed(2);
  };

  const addQuantityDuration = (duration, quantity) => {
    const updatedDuration = convertToReadableDuration(duration.toString());
    // console.log('updatedDuration', updatedDuration);
    let { hourWithQuantity, hourPart, minutePart } = getHourWithQuantity(
      updatedDuration,
      quantity,
    );

    let multipleMinute = parseFloat(
      `0.${hourWithQuantity.toString().split(".")[1]}`,
    );

    const updatedMinute = hourPart ? multipleMinute * (60 / hourPart) : 0;
    minutePart *= quantity;

    let finalDuration =
      parseFloat(hourWithQuantity.toString().split(".")[0]) * 60 +
      updatedMinute +
      minutePart;
    finalDuration = !Number.isInteger(finalDuration / 60)
      ? Math.ceil(finalDuration / 5) * 5
      : finalDuration;

    return finalDuration.toString();
  };

  const calculateMarginLabour = (bookingDataCopy) => {
    // console.log('booking data copy', bookingDataCopy);
    // console.log('lenght', bookingData
    // .length);
    const { cost, buyCostProduct, quantity, type, renderCost } =
      bookingDataCopy;
    let totalBuy = 0;
    let totalSellPrice = 0;
    let laborMargin = 0;
    let totalLabour = 0;
    // console.log('type', type);

    if (type === "product") {
      totalBuy += parseFloat(buyCostProduct);
      totalSellPrice += parseFloat(renderCost);
      console.log("totalSellPrice", totalSellPrice, "total buy", totalBuy);
      const totalLabourProduct = (
        totalSellPrice -
        totalBuy * parseFloat(quantity)
      ).toFixed(2);
      laborMargin = totalLabourProduct.toString();
    }
    if (type === "service") {
      totalLabour =
        bookingDataCopy.pricingType === "hourly"
          ? calculateServiceLabour(bookingDataCopy)
          : cost;
    }
    return { totalLabour, laborMargin };
  };

  const renderCostCalculation = (data) => {
    let quot = data / 5;
    console.log("quot", quot);
    let str = quot.toString();
    let splitData = str.split(".");
    console.log("splitData", splitData);
    let value;
    if (splitData.length > 1 && splitData[1] !== "" && splitData[1] !== "0") {
      value = parseFloat(splitData[0]) + 1;
    } else {
      value = quot;
    }
    let result = value * 5;
    return result;
  };

  const validateService = () => {
    let isValid = true;
    let msg = "";
    let errValue = "";
    const { category, subcategory, staffName, type, customMisc, quantity } =
      bookingData;
    let { cost, buyCostProduct } = bookingData;

    cost = cost.replace(/,/g, "").replace("$", "");
    buyCostProduct = buyCostProduct.replace(/,/g, "").replace("$", "");
    // console.log('cost', cost, 'buy cost product', buyCostProduct);

    if (!category) {
      isValid = false;
      msg = "Please select service or product";
      errValue = "category";
    } else if (!subcategory) {
      isValid = false;
      msg = "Please select subcategory";
      errValue = "subcategory";
    } else if (!staffName) {
      isValid = false;
      msg = "Please select staff";
      errValue = "staffName";
    } else if (
      type === "product" &&
      parseFloat(cost) < parseFloat(buyCostProduct)
    ) {
      isValid = false;
      msg = "Sell price must be greater than buy cost";
      errValue = "sell price";
    } else if (!cost) {
      isValid = false;
      msg = "Enter Sell Price (ex tax)";
      errValue = "sell price";
    } else if (!buyCostProduct && type === "product") {
      isValid = false;
      msg = "Enter Buy Price (ex tax)";
      errValue = "buyCosrProduct";
    } else if (
      bookingData.category.toLowerCase().includes("misc") &&
      !customMisc
    ) {
      isValid = false;
      msg = "Enter New Name";
      errValue = "customMisc";
    } else if (!quantity) {
      isValid = false;
      msg = "Enter quantity";
      errValue = "quantity";
    }
    return { isValid, msg, errValue };
  };
  function groupBy(collection, property) {
    let i = 0;
    let val;
    let index;
    let values = [];
    let result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  const handleAddService = async () => {
    const {
      subcategory,
      type,
      duration,
      previousDuration,
      previousQuantity,
      pricingType,
      customMisc,
      description,
    } = bookingData;
    // console.log('bookingData in handleAddService', bookingData);
    let { cost, buyCostProduct, renderCost, quantity } = bookingData;
    let oldStateCopy = [...newAppointment];
    quantity = quantity.replace(/,/g, "");
    cost = parseFloat(cost.replace(/,/g, "").replace("$", "")).toFixed(2);
    buyCostProduct = parseFloat(
      buyCostProduct.replace(/,/g, "").replace("$", ""),
    ).toFixed(2);
    // console.log('parseFloat(cost)', parseFloat(cost), 'parseFloat(quantity)', parseFloat(quantity));
    renderCost =
      type === "service" && pricingType === "hourly"
        ? (parseFloat(cost) * parseFloat(quantity)) / 60
        : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);
    // const output = renderCostCalculation(15.00);
    if (type === "product") {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = (renderCost / quantity).toFixed(2).toString();
    }
    if (type === "service" || pricingType === "hourly") {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
    }
    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      let startTime = setDefaultTime; // changes by sanjana '9:30';
      let start = moment(dateData.date).toDate();
      let time = bookingData.startTime.split(":");
      const bookingDataCopy = { ...bookingData };

      // console.log('bookingData', bookingData);

      const lastService = addedServices[addedServices.length - 1];
      // console.log('lastService', lastService);
      if (lastService) {
        start = moment(lastService.end).toDate();
        time = moment(lastService.end).format(timeFromDateFormat).split(":");
      }
      // console.log('time', time);
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);
      // console.log('start', start);

      bookingDataCopy.cost = cost;
      bookingDataCopy.quantity = quantity;
      bookingDataCopy.renderCost = renderCost;
      bookingDataCopy.description = customMisc || description;

      if (bookingDataCopy.buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }
      const { totalLabour, laborMargin } =
        calculateMarginLabour(bookingDataCopy);
      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabour;
      let durationInMin = duration;
      if (type === "service") {
        durationInMin =
          parseFloat(quantity) > 1 || pricingType === "hourly"
            ? quantity
            : duration;
      }
      // console.log('gdydedeideoijd', durationInMin);
      // console.log('converted duration', convertToReadableDuration(durationInMin));
      if (!previousDuration) {
        // console.log('setting duration');
        bookingDataCopy.previousDuration = durationInMin;
      }
      // console.log('durationInMin', durationInMin);
      bookingDataCopy.duration = durationInMin;
      // if (parseFloat(durationInMin) > 960) {
      //   setExtras({ ...extras, value: durationInMin, data: convertToReadableDuration(durationInMin) });
      // }
      bookingDataCopy.start = moment(start).format(dateFormat);
      bookingDataCopy.end = moment(
        new Date(
          start.setMinutes(
            start.getMinutes() + parseFloat(bookingDataCopy.duration),
          ),
        ),
      ).format(dateFormat);

      startTime = moment(bookingDataCopy.start).format(timeFromDateFormat);
      // console.log('startTime', startTime);

      bookingDataCopy.startTime = startTime;
      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split("$")[1],
        renderCost.split(".")[1] === "00"
          ? addZero(renderCost)
          : addZero(renderCost),
      );

      bookingDataCopy.timeChange = false;
      if (!previousDuration) {
        // console.log('setting duration');
        bookingDataCopy.previousDuration = durationInMin;
      }
      if (!previousQuantity) {
        // console.log('setting quantity');
        bookingDataCopy.previousQuantity = quantity;
      }

      if (!editData.edit) {
        finalServices = [...addedServices, bookingDataCopy];
      }
      let obj = groupBy(finalServices, "staffName");
      obj.forEach((el, i) => {
        const total = el.reduce(
          (pre, next) =>
            parseFloat(pre || 0) +
            parseFloat(next.renderCost ? next.renderCost : 0),
          0,
        );
        const totalduration = el.reduce(
          (pre, next) =>
            parseFloat(pre || 0) +
            parseFloat(next.duration ? next.duration : 0),
          0,
        );
        obj[i].totalAmount = total;
        obj[i].totalduration = totalduration;
      });
      // let FillterServices = finalServices.sort((a, b) => ((a.staffName > b.staffName) ? 1 : -1));
      let oldServices = [...newAppointment[appointmentIndex].service];
      oldServices.push(bookingDataCopy);
      // let objnew = groupBy(oldServices, 'staffName');
      // objnew.forEach((el, i) => {
      //   const total = el.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.renderCost ? next.renderCost : 0), 0);
      //   const totalduration = el.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.duration ? next.duration : 0), 0);
      //   objnew[i].totalAmount = total;
      //   objnew[i].totalduration = totalduration;
      // });
      // console.log('objoneee', objnew);
      oldStateCopy[appointmentIndex].service = oldServices;
      handleShowService();
      setServices([...finalServices]);
      calculateSummary([...finalServices]);
      setMultiServices(obj);
      setNewAppointment(oldStateCopy);
    } else {
      setErrorValue(errValue);
      NotificationManager.error(msg, "Message", errorMsgTime);
    }
    setTimeout(() => {
      setErrorValue("");
    }, errorBorderTime);
  };
  const calculateGst = (info1, finalService) => {
    // This is custom app array for the gst function.
    const customAppointmentArr = [{ service: finalService }];
    const resultGst = averageGstCalculation(customAppointmentArr);
    const replaceComma = info1 ? info1.replace(",", "") : 0;
    let invoiceSubtotal = parseFloat(replaceComma || info1, 10);
    const { perfectGstPer, totalCost } = resultGst;
    const gstAmount = totalCost * (perfectGstPer / 100);
    const gstPercent = gstAmount;
    // const gstAmount = parseFloat(invoiceSubtotal, 10) * (gstPercent / 100);
    setGst(gstAmount.toFixed(2));
  };

  const calculateQuotesTotal = (info2, finalService) => {
    const customAppointmentArr = [{ service: finalService }];
    const replaceComma = info2 ? info2.replace(",", "") : 0;
    let invoiceSubtotal = parseFloat(replaceComma || info2, 10);
    const resultGst = averageGstCalculation(customAppointmentArr);
    const { perfectGstPer, totalCost } = resultGst;
    const gstAmount = parseFloat(info2, 10) * (perfectGstPer / 100);
    const totalAmount = parseFloat(invoiceSubtotal, 10) + gstAmount;
    console.log("totalAmount====>", totalAmount);
    setQuotesTotal(addZero(parseFloat(totalAmount).toFixed(2)));
  };
  const calculateSummary = (finalServices) => {
    let totalDuration = 0;
    let totalCost = 0;
    let totalCostWithoutProduct = 0;
    let totalBuy = 0;
    let totalSellPrice = 0;
    let laborMargin = 0;
    finalServices.forEach((el) => {
      totalDuration += parseFloat(el.duration);
      totalCost += parseFloat(el.renderCost);
      // totalCostWithoutProduct += parseFloat(el.cost);

      // totalBuy += parseFloat(el.buyCostProduct);
      // totalSellPrice += parseFloat(el.cost);
      laborMargin += parseFloat(el.laborMargin) + parseFloat(el.totalLabour);
    });
    const totalLabour = laborMargin;
    // console.log('laborMargin', laborMargin);
    calculateGst(addZero(parseFloat(totalCost).toFixed(2)), finalServices);
    calculateQuotesTotal(
      addZero(parseFloat(totalCost).toFixed(2)),
      finalServices,
    );
    setSummary({
      ...summary,
      totalCost: addZero(parseFloat(totalCost).toFixed(2)),
      totalDuration,
      totalLabour: addZero(parseFloat(totalLabour).toFixed(2)),
      multiTotal: totalCost,
    });
  };
  const editService = () => {
    const {
      start,
      startTime,
      subcategory,
      type,
      previousDuration,
      previousQuantity,
      duration,
      pricingType,
    } = bookingData;
    let { cost, buyCostProduct, renderCost, quantity } = bookingData;
    // console.log('booking data for edit', bookingData);
    // console.log('buyCostProduct', buyCostProduct.replace(/,/g, '').replace('$', ''));

    quantity = quantity.replace(/,/g, "");
    cost = parseFloat(cost.replace(/,/g, "").replace("$", "")).toFixed(2);
    buyCostProduct = parseFloat(
      buyCostProduct.replace(/,/g, "").replace("$", ""),
    ).toFixed(2);

    renderCost =
      type === "service" && pricingType === "hourly"
        ? (parseFloat(cost) * parseFloat(quantity)) / 60
        : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);
    if (type === "product") {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = (renderCost / quantity).toFixed(2).toString();
    }
    if (type === "service" || pricingType === "hourly") {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
    }

    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      const bookingDataCopy = { ...bookingData };

      const startDate = moment(start).toDate();
      const time = startTime.split(":");

      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split("$")[1],
        renderCost.split(".")[1] === "00"
          ? addZero(renderCost)
          : addZero(renderCost),
      );
      bookingDataCopy.cost = cost;
      bookingDataCopy.renderCost = renderCost;
      bookingDataCopy.quantity = quantity;

      if (buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }

      startDate.setHours(time[0]);
      startDate.setMinutes(time[1]);
      startDate.setSeconds(0);

      bookingDataCopy.start = moment(startDate).format(dateFormat);

      const { totalLabour, laborMargin } =
        calculateMarginLabour(bookingDataCopy);
      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabour;

      let durationInMin = 0;
      if (type === "service") {
        durationInMin =
          parseFloat(quantity) > 1 || pricingType === "hourly"
            ? quantity
            : duration;
        bookingDataCopy.duration = durationInMin;
      } else if (
        previousQuantity !== quantity ||
        previousDuration !== duration
      ) {
        // console.log('re calculating the duration');
        // durationInMin = parseFloat(quantity) > 1 ? addQuantityDuration(duration, parseFloat(quantity)) : duration;
        // bookingDataCopy.duration = durationInMin;
        bookingDataCopy.previousDuration = durationInMin;
        bookingDataCopy.previousQuantity = bookingDataCopy.quantity;
      }

      // if (parseFloat(durationInMin) > 960) {
      //   setExtras({ ...extras, value: durationInMin, data: convertToReadableDuration(durationInMin) });
      // }

      bookingDataCopy.end = moment(
        new Date(
          startDate.setMinutes(
            startDate.getMinutes() + parseFloat(bookingDataCopy.duration),
          ),
        ),
      ).format(dateFormat);

      const currentServices = [...addedServices];
      currentServices[editData.index] = bookingDataCopy;
      finalServices = currentServices;
      let obj = groupBy(finalServices, "staffName");
      obj.forEach((el, i) => {
        const total = el.reduce(
          (pre, next) =>
            parseFloat(pre || 0) +
            parseFloat(next.renderCost ? next.renderCost : 0),
          0,
        );
        const totalduration = el.reduce(
          (pre, next) =>
            parseFloat(pre || 0) +
            parseFloat(next.duration ? next.duration : 0),
          0,
        );
        obj[i].totalAmount = total;
        obj[i].totalduration = totalduration;
      });
      console.log("calling recalculate");
      calculateNewDurations(bookingDataCopy);
      bookingDataCopy.timeChange = false;

      setShowService(!showService);
      setServices([...finalServices]);
      calculateSummary([...finalServices]);
      setMultiServices(obj);
      console.log("updated booking data", bookingDataCopy);
    } else {
      setErrorValue(errValue);
      NotificationManager.error(msg, "Message", errorMsgTime);
    }
    setTimeout(() => {
      setErrorValue("");
    }, errorBorderTime);
  };
  const calculateDurations = (services) => {
    services.forEach((el) => {
      let start = moment(el.date).toDate();
      const time = el.startTime.split(":");
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);

      el.start = moment(start).format(dateFormat);
      el.end = moment(
        new Date(
          start.setMinutes(start.getMinutes() + parseFloat(el.duration)),
        ),
      ).format(dateFormat);
    });
    // console.log('services', services);
    setServices([...services]);
  };
  const handleDeleteService = () => {
    const currentServices = [...addedServices];
    currentServices.splice(editData.index, 1);
    if (editData.index === addedServices.length - 1) {
      console.log("last service/product got deleted");
      setBookingData({
        ...bookingData,
        startTime: addedServices[editData.index].startTime,
      });
      setServices([...currentServices]);
    } else {
      recalculateServices(addedServices[editData.index]);
    }
    calculateSummary([...currentServices]);
    setShowService(!showService);
  };
  const handleEditService = ({ service, index }) => {
    setStaffIndex(index);
    if (index === 0) {
      setnewlength(0);
    } else {
      setnewlength(addedServices.length);
    }
    console.log("editing");
    // console.log('service', service);
    const updateObj = { ...bookingData, ...service };
    setBookingData(updateObj);
    setShowService(!showService);
    setEditData({ edit: true, index });
    if (parseFloat(service.duration) > 960) {
      setExtras({
        ...extras,
        value: service.duration,
        data: convertToReadableDuration(service.duration),
      });
    }
    const subcategory = service.subcategory.replace(
      service.subcategory.split("$")[1],
      service.renderCost.split(".")[1] === "00"
        ? addZero(service.renderCost)
        : addZero(service.cost),
    );
    setSearchTxt(subcategory);
  };

  const handleSearchCategory = async (searchTxt) => {
    try {
      setSearchTxt(searchTxt);
      if (searchTxt) {
        const response = await userInstance().get(
          `/service/searchCategory?value=${searchTxt.replace("$", "").trim()}&industryId=${industryId._id}`,
        );
        const { services } = response.data;
        setCategories(services);
        if (services.length) {
          const subCat = [];
          services.forEach((service) => {
            const reg = new RegExp(`${searchTxt.replace("$", "").trim()}`, "i");
            const matchedSubcategories = service.subCategory.filter(
              (el) =>
                reg.test(el.cost) ||
                reg.test(el.name) ||
                reg.test(el.retailCost) ||
                reg.test(el.serviceDescription),
            );
            subCat.push(...matchedSubcategories);
          });
          if (subCat.length) {
            setSubcategories([...subCat]);
          } else {
            setSubcategories([]);
          }
        } else {
          setSubcategories([]);
        }
      }
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const handleAddQuotes = async (sendby) => {
    try {
      let isValid = true;
      let val = "";
      if (!addClient.length) {
        isValid = false;
        val = "clients";
      } else if (!addedServices.length) {
        isValid = false;
        val = "services";
      }
      if (isValid) {
        let data_array = [];
        let { date, createdOn, endsAt, requiredday, appointmenttype } =
          dateData || {};
        // eslint-disable-next-line no-undef
        const startDate = moment(date).format("YYYY-MM-DD");
        let { multiTotal, totalDuration, totalLabour } = summary || {};
        let totalSummary = {
          totalCost: addZero(
            (requiredday
              ? parseFloat(multiTotal) * parseInt(requiredday, 10)
              : parseFloat(multiTotal)
            ).toFixed(2),
          ),
          totalDuration: requiredday
            ? totalDuration * parseInt(requiredday.client, 10)
            : totalDuration,
          totalLabour,
          multiTotal: requiredday
            ? multiTotal * parseInt(requiredday, 10)
            : multiTotal,
        };
        for (let step = 0; step < newAppointment.length; step++) {
          let dateDataCopy = {
            ...dateData,
            date: new Date(startDate).toISOString(),
            createdOn: moment(createdOn).format(dateFormat),
            endsAt: new Date(startDate).toISOString(),
            teamnotes: multiNotes[step].notes,
            occurenceMsg:
              dateData.endType === "ongoing"
                ? "Ongoing"
                : dateData.occurenceMsg,
            service: addedServices || [],
            summary: totalSummary,
          };
          data_array.push(dateDataCopy);
        }
        let {
          firstname,
          lastname,
          streetAddress,
          suburb,
          state,
          _id,
          siteAddresses,
          mobileNo,
          note,
        } = addClient[0] ? addClient[0] : {};
        if (saveQuoteType === "editquote") {
          const response = await userInstance().post("/quotes/editQuotes", {
            services: addedServices,
            client: _id,
            notes,
            dateData: data_array,
            totalData: totalSummary,
            appointmenttype,
            requiredday: requiredday || "",
            gst,
            quoteDate: new Date(startDate).toISOString(),
            quotesTotal,
            quoteSign,
            templateType,
            quoteId: showQuote._id,
            previewUrl,
            sendby,
            activeAdd,
          });
          NotificationManager.success(
            response.data.msg,
            "Message",
            successMsgTime,
          );
        } else {
          const response = await userInstance().post("/quotes/addQuotes", {
            services: addedServices,
            client: _id,
            previewUrl,
            notes,
            dateData: data_array,
            totalData: totalSummary,
            appointmenttype,
            requiredday: requiredday || "",
            gst,
            quoteDate: new Date(startDate).toISOString(),
            quotesTotal,
            quoteSign,
            templateType,
            industryId: industryId?._id,
            sendby,
            activeAdd,
          });
          NotificationManager.success(
            response.data.msg,
            "Message",
            successMsgTime,
          );
        }
        setSelectedClient("");
        setQuoteType("Pending Quotes");
        setServices([]);
        setBookingData(initialBookingData);
        setNotes("");
        setAddClient([]);
        setSummary({ totalCost: 0, totalDuration: 0 });
        setDateData(initialDateData);
        setMultiNotes(multiNotesState);
        setmultiAppointment(false);
        setMultiServices([]);
        setquoteUrl("");
      } else {
        NotificationManager.error(`Please add ${val}`, "Message", errorMsgTime);
      }
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const calculateNewDurations = (data) => {
    // console.log('data in calculateNewDurations', data);
    // const selectedEntryIndex = addedServices.findIndex((x, i) => i === editData.index);
    const remainingServices = addedServices.filter(
      (_el, i) => i > editData.index,
    );
    for (let i = 0; i < remainingServices.length; i++) {
      const currentEl = remainingServices[i];
      let end = "";
      if (i === 0) {
        end = new Date(data.end);
      } else {
        end = new Date(remainingServices[i - 1].end);
      }
      const startTime = moment(end).format(timeFromDateFormat);
      currentEl.startTime = startTime;
      currentEl.start = moment(end).format(dateFormat);
      currentEl.end = moment(
        new Date(
          end.setMinutes(end.getMinutes() + parseFloat(currentEl.duration)),
        ),
      ).format(dateFormat);
    }
    setServices([addedServices[0], ...remainingServices]);
  };

  const recalculateServices = (data) => {
    const remainingServices = addedServices.filter(
      (_el, i) => i > editData.index,
    );
    const previousServices = addedServices.filter(
      (_el, i) => i < editData.index,
    );
    console.log("remainingServices", remainingServices);
    for (let i = 0; i < remainingServices.length; i++) {
      const currentEl = remainingServices[i];
      let end = "";
      if (i === 0) {
        end = new Date(data.start);
      } else {
        end = new Date(remainingServices[i - 1].end);
      }
      const startTime = moment(end).format(timeFromDateFormat);
      currentEl.startTime = startTime;
      currentEl.start = moment(end).format(dateFormat);
      currentEl.end = moment(
        new Date(
          end.setMinutes(end.getMinutes() + parseFloat(currentEl.duration)),
        ),
      ).format(dateFormat);
    }
    const arr = previousServices;
    const newArray = arr.concat(remainingServices);
    setServices([...newArray]);
  };
  const setEditQuoteData = () => {
    let copyAddClient = [...addClient];
    console.log("set edit data ==>", showQuote);
    // copyAddClient.streetAddress = showQuote.client[0].address;
    // copyAddClient.firstname = showQuote.client[0].name;
    // copyAddClient._id = showQuote.client[0]._id;
    // copyAddClient.mobileNo = showQuote.client[0].mobileNo;
    // copyAddClient.note = showQuote.client[0].note;
    const objToUpdate = {
      ...bookingData,
    };
    if (showQuote?.staff) {
      objToUpdate.staffName = showQuote?.services[0].staffName;
      objToUpdate.staffId = showQuote?.staff;
    }
    setBookingData(objToUpdate);
    setAddClient([showQuote.client]);
    setTemplateType(showQuote.templateType);
    setQuoteDate(new Date(showQuote.quoteDate));
    setQuoteSign(showQuote.associateName);
    setDateData(showQuote.dateData[0]);
    setSummary(showQuote.totalData);
    setServices(showQuote.services);
    setGst(addZero(parseFloat(showQuote.gst).toFixed(2)));
    const totals = removeComma(showQuote?.quotesTotal);
    setQuotesTotal(addZero(parseFloat(totals).toFixed(2)));
    console.log("totalAmount2====>", showQuote.quotesTotal);
    // setQuotesTotal(addZero(parseFloat(showQuote.quotesTotal).toFixed(2)));
    setQuoteNumber(showQuote.quoteNo);
    setActiveAdd(showQuote?.clientSiteAddressIndex || 0);
  };
  useEffect(() => {
    getCategories();
  }, [dateData]);
  useEffect(() => {
    if (saveQuoteType === "editquote") {
      setEditQuoteData();
    }
    getAllTemplate();
  }, []);
  const handlePreview = async () => {
    try {
      let isValid = true;
      let val = "";
      if (!addClient.length) {
        isValid = false;
        val = "client.";
      } else if (!addedServices.length) {
        isValid = false;
        val = "service.";
      } else if (quoteSign === "") {
        isValid = false;
        val = "Quote Sign-off.";
      }
      // console.log(moment(quoteDate).format('Do MMM YYYY'));
      if (isValid) {
        let {
          firstname,
          lastname,
          streetAddress,
          suburb,
          state,
          siteAddresses,
          mobileNo,
          note,
        } = addClient[0] ? addClient[0] : {};

        const selectedSiteAddres =
          siteAddresses && siteAddresses?.filter((el) => el.activeSiteAdd);
        const tempSiteAddress = selectedSiteAddres
          ? selectedSiteAddres[0]?.address
          : "";
        console.log("selectedSiteAddres===>", selectedSiteAddres);

        let copyTemplateData = [...selectedTemplate];
        console.log("addClient====>", addClient);
        console.log("copyTemplateData==>", copyTemplateData);
        console.log(
          "companyInformation===>",
          companyInformation,
          companyLocations,
          copyTemplateData[1].text,
        );
        console.log("signStaff==>", signStaff);

        // console.log('selectedTemplate====>', signStaff, signStaff.name);
        copyTemplateData[1].text = companyLocations || copyTemplateData[1].text;
        copyTemplateData[2].text = mobile || copyTemplateData[2].text;
        copyTemplateData[3].text = email || copyTemplateData[3].text;
        // copyTemplateData[4].text = companyName || copyTemplateData[4].text;
        copyTemplateData[4].text = companyName || "";
        copyTemplateData[10].text = `${"Quote # "}${quoteNumber || "N/A"}`;
        copyTemplateData[11].text = "";
        copyTemplateData[12].siteAddresses = tempSiteAddress || "";
        copyTemplateData[7].text = moment(quoteDate).format("Do MMM YYYY");
        copyTemplateData[16].text = `$${summary.totalCost}` || "$00.00";
        copyTemplateData[18].text = `$${gst}` || "$00.00";
        copyTemplateData[20].text = `$${quotesTotal}` || "$00.00";
        copyTemplateData[22].text = `${signStaff.complimentary}` || "";
        copyTemplateData[23].text = `${signStaff.signature.text}` || "";
        copyTemplateData[23].url = `${signStaff.signature.url}` || "";
        copyTemplateData[23].preview = `${signStaff.signature.url}` || "";
        copyTemplateData[23].style =
          signStaff.signature.style || copyTemplateData[23].style;
        // copyTemplateData[25].text = `${signStaff.type || 'Proprietor'}`;
        copyTemplateData[8].text = `Dear ${firstname || ""}` || "";
        copyTemplateData[5].text = `${firstname} ${lastname || ""}` || "";
        copyTemplateData[6].text = `${streetAddress}${suburb || state ? "," : ""} ${suburb || ""} ${state || ""} ${postcode}`;

        if (copyTemplateData?.length > 24) {
          copyTemplateData[24].text = `${signStaff.name || ""}`;
          copyTemplateData[25].text = `${signStaff.type || ""}`;
          copyTemplateData[25].email = `${signStaff.email || ""}`;
          copyTemplateData[25].mobileNo = `${signStaff.mobileNo || ""}`;
        }
        console.log("addedServices =====>", addedServices);
        setPreviewPopup(!previewPopup);
        setSuccess(false);
        setLoading(true);
        const payload = {
          templateData: copyTemplateData,
          customFeilds,
          templateDataOne: {
            services: addedServices,
          },
          type: "quote",
        };
        // console.log('payload', payload);
        setPreview(!preview);
        const response = await userInstance().post(
          "/template/previewTemplatePdf",
          payload,
        );
        const { code, msg, tempId } = response.data;
        console.log("tempId=>", tempId);
        setPreviewUrl(tempId);
        setLoading(false);
      } else {
        NotificationManager.error(`Please add ${val}`, "Message", errorMsgTime);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSignature = (e) => {
    setQuoteSign(e.target.value);
    const activeStaff = signatureList.filter((el) => el._id === e.target.value);
    console.log("activeStaff", activeStaff[0].image);
    const obj = {
      name: `${activeStaff[0].name}`,
      type: `${activeStaff[0].title}`,
      signature: activeStaff[0].image,
      complimentary: `${activeStaff[0].complimentary}`,
      mobileNo: `${activeStaff[0].mobileNo}` || "",
      email: `${activeStaff[0].email}` || "",
    };
    setSignStaff(obj);
  };
  const handleTemplate = (index) => {
    const { _id } = templateArray[index] || {};
    setTemplateType(_id);
    // console.log('id=>', templateArray[index].currentTemplateData);
    setSelectedTemplate(templateArray[index].currentTemplateData);
    setCustomFeilds(templateArray[index].customFeilds);
  };
  useEffect(() => {
    getSignatureListList();
  }, []);

  const handleSMS = () => {
    setSMSOption(!smsOption);
  };
  const handleReset = () => {
    setsaveQuoteType("");
    setQuoteNumber("");
    getAllTemplate();
    setTemplateType("");
    setQuoteSign("");
    setMultiNotes(multiNotesState);
    setShowService(false);
    setBookingData(initialBookingData);
    setDateData(initialDateData);
    setQuoteDate(new Date());
    setNewAppointment([initialDateData]);
    setServices([]);
    setAddClient([]);
    getSignatureListList();
    getCategories();
    setSummary({
      totalCost: 0,
      totalDuration: "",
      totalLabourMargin: 0,
    });
    history.push("/quotes");
  };
  return (
    <>
      <Form.Group controlId="formBasicHiddenEmployees">
        <Form.Label>Template to Use</Form.Label>
        <div className="select-box">
          <i className="fas fa-sort-down" />
          <Form.Control
            as="select"
            name="templatetype"
            // value={templateType}
            onChange={(e) => handleTemplate(e.target.value)}
          >
            {templateArray &&
              templateArray.map((temp, index) => (
                <option value={index} selected={temp._id === templateType}>
                  {temp.templateName}
                </option>
              ))}
          </Form.Control>
        </div>
      </Form.Group>
      <span className="sep" />
      <div
        className="appointment-date"
        role="presentation"
        onClick={() => handleDatePopup("")}
      >
        <div className="">
          <h3>Date: {`${moment(quoteDate).format("D MMMM YYYY")}`}</h3>
        </div>
        <div className="sorting-sub-services appointment-sort">
          <div className="content-dropdown">
            <img src={arrow} alt="arrow" />
          </div>
        </div>
      </div>
      <div className="appointment-date pending-quote" role="presentation">
        <div className="">
          {quoteNumber ? (
            <h3>Quote # {quoteNumber}</h3>
          ) : (
            <h3>Quote # &lt;Pending&gt;</h3>
          )}
        </div>
      </div>
      <div className="quotes-client-details">
        {addClient.length > 0 ? (
          <>
            {addClient &&
              addClient.map((cl) => (
                <>
                  <p>Dear {`${cl.firstname}`}</p>
                </>
              ))}
          </>
        ) : (
          <p>Dear &lt;fname&gt;,</p>
        )}
        <div className="client-quotes-notes">
          <span>
            {selectedTemplate && selectedTemplate?.length > 0
              ? `${selectedTemplate[9].text}.`
              : "Thank you for the time spent discussing your pool requirements. Please find our quote detailed below with our recommendations and costs."}
          </span>
        </div>
      </div>
      <span className="sep" />
      <div className="quotes-services">
        <div className="search-title service-head">
          <h3> Services or Products</h3>
          <div className="sorting-services">
            {addedServices.length ? (
              <div className="sorting">
                <img
                  src={sort}
                  alt="sorting"
                  onClick={() => setRenderSort(!renderSort)}
                />
              </div>
            ) : null}
            <span
              className="plus-icon"
              onClick={() => handleShowService(0)}
              role="presentation"
            >
              <i className="fas fa-plus" />
            </span>
          </div>
        </div>

        {addedServices &&
          addedServices.map((ser, i) => (
            <div className="search-title">
              <div
                className="client-profile"
                onClick={() => handleEditService({ service: ser, index: i })}
              >
                <h3>
                  {`${
                    ser.category.toLowerCase().includes("misc")
                      ? `${ser.customMisc} $${addZero(ser.renderCost)}`
                      : ser.subcategory
                  } ${
                    parseFloat(ser.quantity) > 1
                      ? `($${`${addZero(ser.cost)}${ser.soldPer === "Kilogram (kg)" ? " per kg" : ""}`} x ${`${
                          ser.type === "service" && ser.pricingType === "hourly"
                            ? convertToReadableDuration(ser.quantity)
                            : `${ser.soldPer === "Kilogram (kg)" ? `${ser.quantity}kg` : ser.quantity}`
                        }${ser.type === "service" ? " " : ""})`}`
                      : ""
                  }`}
                </h3>
                <p>
                  <span>
                    {moment(ser.startTime, [timeFromDateFormat]).format(
                      "h:mm a",
                    )}
                  </span>
                  <span> | </span>
                  <span>{` ${convertToReadableDuration(ser.duration)}`}</span>
                  <span> | </span>
                  <span>{` with ${ser.staffName}`}</span>
                  <span> | </span>
                </p>
                <p>
                  <span>
                    {`${
                      ser.type === "product"
                        ? `Margin $${addZero(ser.laborMargin)}`
                        : ser.type === "service"
                          ? `Labour $${`${addZero(ser.totalLabour)}`}`
                          : null
                    }`}
                  </span>
                </p>
              </div>
              <div className="sorting-sub-services appointment-sort">
                <div className="service-edit-icon">
                  {renderSort ? (
                    <div
                      className={`sorting up-down ${renderSort && "active-sort"}`}
                    >
                      {i !== 0 ? (
                        <img
                          src={upsort}
                          alt="sorting"
                          onClick={() => moveArr(i, i - 1, "minus")}
                        />
                      ) : null}
                      {i !== addedServices.length - 1 ? (
                        <img
                          src={downsort}
                          alt="sorting"
                          onClick={() => moveArr(i, i + 1, "plus")}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <div
                  className="content-dropdown"
                  onClick={() => handleEditService({ service: ser, index: i })}
                >
                  <Dropdown name="startingDate">
                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                      <img src={arrow} alt="arrow" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>Option 1</Dropdown.Item>
                      <Dropdown.Item>Option 2</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="client-quotes-notes">
        <span>
          {selectedTemplate && selectedTemplate?.length > 0
            ? `${selectedTemplate[21].text}.`
            : " I hope that the above pricing meets with your approval and look forward to being of service to you in the future. If we have misinterpreted your requirements or if you require any additional detail or ammendments, Please do not hesitate to contact me on 0409 774 687"}
        </span>
      </div>
      <div className="appointment-total">
        <h6> {`Quote Total: $${summary.totalCost}`}</h6>

        <p>Duration: {convertToReadableDuration(summary.totalDuration)}</p>
        <p>
          Labour & Margin:
          {`$${summary.totalLabour ? summary.totalLabour : 0}`}
        </p>
      </div>

      <Form.Group controlId="formBasicHiddenEmployees">
        <Form.Label>Quote Sign-off - Team Member Name & Mobile</Form.Label>
        <div className="select-box">
          <i className="fas fa-sort-down" />
          {signatureList.length > 0 && (
            <Form.Control
              as="select"
              name="quotesign"
              onChange={(e) => handleSignature(e)}
              value={quoteSign}
            >
              {signatureList &&
                signatureList.map((el, index) => {
                  const { name, mobileNo, _id, title } = el || {};
                  return (
                    <option
                      value={_id}
                      selected={_id === quoteSign ? true : ""}
                    >{`${name} |  ${title} | ${mobileNo} `}</option>
                  );
                })}
            </Form.Control>
          )}
          {signatureList.length === 0 && (
            <Form.Control as="select" name="quotesign">
              <option>No Quote Sign-off Available</option>
            </Form.Control>
          )}
        </div>
      </Form.Group>
      <div className="footer-btn view-btn">
        <Button className="btn-delete" onClick={() => handleReset()}>
          Cancel
        </Button>
        <Button className="appointment-btn btn-save" onClick={handlePreview}>
          Preview Quote
        </Button>
      </div>
      <DateModal
        datePopup={datePopup}
        setDatePopup={setDatePopup}
        handleDatePopup={handleDatePopup}
        quoteDate={quoteDate}
      />

      <ServiceModal
        showService={showService}
        setShowService={setShowService}
        handleShowService={handleShowService}
        newLength={newLength}
        categories={categories}
        subcategories={subcategories}
        handleBookingData={handleBookingData}
        getTimes={getTimes}
        bookingData={bookingData}
        renderOptions={renderOptions}
        staffList={staffList}
        handleAddService={handleAddService}
        editData={editData}
        handleDeleteService={handleDeleteService}
        handleSearchCategory={handleSearchCategory}
        setSubcategories={setSubcategories}
        addSubcategoryToService={addSubcategoryToService}
        editService={editService}
        setBookingData={setBookingData}
        staffIndex={staffIndex}
        misc={misc}
        addedServices={addedServices}
        servicesLength={addedServices.length}
        extras={extras}
        handleChangeCustom={handleChangeCustom}
        timepickerShow={timepickerShow}
        handleTimePicker={handleTimePicker}
        subcategoryTxt={subcategoryTxt}
        setSearchTxt={setSearchTxt}
        durationPicker={durationPicker}
        setDurationPicker={setDurationPicker}
        convertToReadableDuration={convertToReadableDuration}
        handleDurationPicker={handleDurationPicker}
        setnewlength={setnewlength}
        setHourPicker={setHourPicker}
        hourPicker={hourPicker}
        handleHourPicker={handleHourPicker}
        appointmentType={appointmentType}
        setDuration={setDuration}
        durationData={durationData}
        error={erroValue}
      />
      {timepickerShow ? (
        <TimePicker
          timepickerShow={timepickerShow}
          handleTimePicker={handleTimePicker}
          bookingData={bookingData}
          setBookingData={setBookingData}
          timeData={timeData}
          setTime={setTime}
        />
      ) : null}
      {durationPicker && (
        <DurationPicker
          durationPicker={durationPicker}
          setDurationPicker={setDurationPicker}
          durationData={durationData}
          handleDurationPicker={handleDurationPicker}
          setDuration={setDuration}
          setBookingData={setBookingData}
          convertToReadableDuration={convertToReadableDuration}
          bookingData={bookingData}
          subcategory={null}
          setSubCategory={null}
        />
      )}
      {hourPicker ? (
        <HourPicker
          setHourPicker={setHourPicker}
          handleHourPicker={handleHourPicker}
          hourPicker={hourPicker}
          hourData={hourData}
          handleDurationPicker={handleDurationPicker}
          setHourData={setHourData}
          setBookingData={setBookingData}
          convertToReadableDuration={convertToReadableDuration}
          bookingData={bookingData}
          subcategory={null}
          setSubCategory={null}
        />
      ) : null}
      {/* <PreviewQuote
        showquote={preview}
        handleQuote={handlePreview}
        addClient={addClient}
        addedServices={addedServices}
        quoteDate={quoteDate}
        summary={summary}
        quotesTotal={quotesTotal}
        gst={gst}
        handleAddQuotes={handleAddQuotes}
        selectedTemplate={selectedTemplate}
        quoteNumber={quoteNumber}
      /> */}
      <Modal
        size="md"
        show={previewPopup}
        onHide={handlePreview}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="search-modal new-search-popup1 preview-popup preview-pdf"
      >
        <Modal.Body>
          <div className="appointment-container preview-invoice preview-popup">
            <PreviewQuote
              // templateData={templateData}
              customFeilds={customFeilds}
              previewUrl={previewUrl}
              loading={loading}
              setSuccess={setSuccess}
            />
            {success && (
              <div className="footer-btn view-btn">
                <Button className="btn-delete" onClick={handlePreview}>
                  Cancel
                </Button>

                <Button
                  className="appointment-btn btn-save"
                  onClick={handleSMS}
                >
                  Next
                </Button>
                {/* handleSMS */}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      {smsOption && (
        <SMSOption setSMSOption={setSMSOption} handleSubmit={handleAddQuotes} />
      )}
    </>
  );
};
QuoteDetail.propTypes = {
  addClient: PropTypes.func.isRequired,
  setQuoteType: PropTypes.func.isRequired,
  saveQuoteType: PropTypes.string.isRequired,
  showQuote: PropTypes.objectOf.isRequired,
  setAddClient: PropTypes.func.isRequired,
  setSelectedClient: PropTypes.func.isRequired,
  activeAdd: PropTypes.string.isRequired,
  setActiveAdd: PropTypes.func.isRequired,
  setsaveQuoteType: PropTypes.func.isRequired,
};
export default QuoteDetail;
