import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Form, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import AdminWrapper from "../admin/adminwrapper/wrapper";
// import { inpImage } from './green_state';
import { errorMsgTime } from "../../functions/intervalTime";
import img1 from "../../assets/test-strip/ALK_0.jpg";
import draining from "../../assets/home/refresh-icon.png";
import { server } from "../../config/keys";
import { limitInputValues } from "./calculatorUtils";
import "./stripe.css";

const TestStripe = ({
  handleImageChange,
  // handleInputs,
  poolInputs,
  handleOk,
  AllArray,
  handleOriginalReadings,
  limitInp,
  handleBackStrip,
  errorValues,
  staticServerPathForImage,
  showbtn,
  handlClickOutside,
}) => (
  <>
    {/* {console.log('pooo', poolInputs)} */}
    <Helmet>
      <html className="calculator-main" lang="en" />
    </Helmet>
    <AdminWrapper>
      <div
        className={`strip-page content-container bg-lightblue calculator-container upload-img-section ${poolInputs?.BoxCount > 4 ? "" : "four-strip-page"}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title">
                <h3>View & Confirm</h3>
              </div>
            </div>
            <div className="col-md-12 strip-layout">
              <div className="strip-list strip-column">
                <div className="left-strip-column">
                  <div className="blank-box" style={{ background: "white" }} />
                  {poolInputs?.BoxCount > 4 && (
                    <>
                      <div
                        className="box box1"
                        style={{ background: poolInputs.Box1 }}
                      >
                        <img src={`${poolInputs.Box1}`} alt="img1" />
                      </div>
                      <div
                        className="blank-box"
                        style={{ background: "white" }}
                      />
                      <div
                        className="box box1"
                        style={{ background: poolInputs.Box2 }}
                      >
                        <img src={`${poolInputs.Box2}`} alt="img1" />
                      </div>
                      <div
                        className="blank-box"
                        style={{ background: "white" }}
                      />
                    </>
                  )}
                  <div
                    className="box box1"
                    style={{ background: poolInputs.Box3 }}
                  >
                    <img src={`${poolInputs.Box3}`} alt="img1" />
                  </div>
                  <div className="blank-box" style={{ background: "white" }} />
                  <div
                    className="box box1"
                    style={{ background: poolInputs.Box4 }}
                  >
                    <img src={`${poolInputs.Box4}`} alt="img1" />
                  </div>
                  <div className="blank-box" style={{ background: "white" }} />
                  <div
                    className="box box1"
                    style={{ background: poolInputs.Box5 }}
                  >
                    <img src={`${poolInputs.Box5}`} alt="img1" />
                  </div>
                  <div className="blank-box" style={{ background: "white" }} />
                  <div
                    className="box box1"
                    style={{ background: poolInputs.Box6 }}
                  >
                    <img src={`${poolInputs.Box6}`} alt="img1" />
                  </div>
                  <div className="blank-box" style={{ background: "white" }} />
                </div>
                <div className="right-strip-column">
                  {poolInputs?.BoxCount === 6 && (
                    <>
                      <div className="strip-list strip-list-one">
                        <h6>Total Hardness</h6>
                        {console.log(errorValues?.calciumHardness)}
                        <div className="strip-box-list">
                          <NumberFormat
                            className={`${
                              errorValues?.calciumHardness ? "error-text" : ""
                            }`}
                            type="text"
                            // onChange={(e) => handleInputs(e, 'strip')}
                            onBlur={handlClickOutside}
                            name="calciumHardness"
                            disabled
                            value={poolInputs.calciumHardness}
                            isAllowed={(inputObj) =>
                              limitInputValues(
                                inputObj,
                                limitInp.calciumHardness,
                              )
                            }
                          />

                          {AllArray.calciumHardness.map((el) => (
                            <div className="number-value">
                              <p>{el?.value}</p>
                              <div
                                className={`choose-box ${
                                  parseFloat(poolInputs?.calciumHardness) ===
                                  parseFloat(el?.value)
                                    ? "selected-box"
                                    : ""
                                }`}
                              >
                                <div
                                  className="box box2"
                                  style={{ background: el?.color }}
                                  onClick={el?.value}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="strip-list strip-list-two">
                        <h6>Total Chlorine</h6>
                        <div className="strip-box-list">
                          <NumberFormat
                            type="text"
                            className={`${
                              errorValues?.combinedChlorine ? "error-text" : ""
                            }`}
                            // onChange={(e) => handleInputs(e, 'strip')}
                            name="combinedChlorine"
                            onBlur={handlClickOutside}
                            disabled
                            value={poolInputs.combinedChlorine}
                            isAllowed={(inputObj) =>
                              limitInputValues(
                                inputObj,
                                limitInp.combinedChlorine,
                              )
                            }
                          />
                          {AllArray.combinedChlorine.map((el) => (
                            <div className="number-value">
                              {/* {console.log('el?.color', el?.color)} */}
                              <p>{el?.value}</p>
                              <div
                                className={`choose-box ${
                                  parseFloat(
                                    poolInputs?.combinedChlorine,
                                  )?.toFixed(1) ===
                                  parseFloat(el?.value)?.toFixed(1)
                                    ? "selected-box"
                                    : ""
                                }`}
                              >
                                <div
                                  className="box box2"
                                  style={{ background: el?.color }}
                                  onClick={el?.value}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="strip-list strip-list-three">
                        <h6>Free Chlorine</h6>
                        <div className="strip-box-list">
                          <NumberFormat
                            type="text"
                            className={`${
                              errorValues?.chlorine ? "error-text" : ""
                            }`}
                            // onChange={(e) => handleInputs(e, 'strip')}
                            name="chlorine"
                            onBlur={handlClickOutside}
                            value={poolInputs.chlorine}
                            disabled
                            isAllowed={(inputObj) =>
                              limitInputValues(inputObj, limitInp.chlorine)
                            }
                          />
                          {AllArray.chlorine.map((el) => (
                            <div className="number-value">
                              {/* {console.log('el?.color', el?.color)} */}
                              <p>{el?.value}</p>
                              <div
                                className={`choose-box ${
                                  parseFloat(poolInputs?.chlorine)?.toFixed(
                                    1,
                                  ) === parseFloat(el?.value)?.toFixed(1)
                                    ? "selected-box"
                                    : ""
                                }`}
                              >
                                <div
                                  className="box box2"
                                  style={{ background: el?.color }}
                                  onClick={el?.value}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="strip-list strip-list-four">
                        <h6>pH</h6>
                        <div className="strip-box-list">
                          <NumberFormat
                            type="text"
                            className={`${errorValues?.ph ? "error-text" : ""}`}
                            // onChange={(e) => handleInputs(e, 'strip')}
                            name="ph"
                            onBlur={handlClickOutside}
                            value={poolInputs.ph}
                            disabled
                            isAllowed={(inputObj) =>
                              limitInputValues(inputObj, limitInp.ph)
                            }
                          />
                          {AllArray.ph.map((el) => (
                            <div className="number-value">
                              {/* {console.log('el?.color', el?.color)} */}
                              <p>{el?.value}</p>
                              <div
                                className={`choose-box ${
                                  parseFloat(poolInputs?.ph) ===
                                  parseFloat(el?.value)
                                    ? "selected-box"
                                    : ""
                                }`}
                              >
                                <div
                                  className="box box2"
                                  style={{ background: el?.color }}
                                  onClick={el?.value}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="strip-list strip-list-five">
                        <h6>Total Alkalinity (TA)</h6>
                        <div className="strip-box-list">
                          <NumberFormat
                            type="text"
                            className={`${
                              errorValues?.alkalinity ? "error-text" : ""
                            }`}
                            // onChange={(e) => handleInputs(e, 'strip')}
                            name="alkalinity"
                            onBlur={handlClickOutside}
                            value={poolInputs.alkalinity}
                            disabled
                            isAllowed={(inputObj) =>
                              limitInputValues(inputObj, limitInp.alkalinity)
                            }
                          />
                          {AllArray.alkalinity.map((el) => (
                            <div className="number-value">
                              {/* {console.log('el?.color', el?.color)} */}
                              <p>{el?.value}</p>
                              <div
                                className={`choose-box ${
                                  parseFloat(poolInputs?.alkalinity) ===
                                  parseFloat(el?.value)
                                    ? "selected-box"
                                    : ""
                                }`}
                              >
                                <div
                                  className="box box2"
                                  style={{ background: el?.color }}
                                  onClick={el?.value}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="strip-list strip-list-six">
                        <h6>Stabiliser (CYA)</h6>
                        <div className="strip-box-list">
                          <NumberFormat
                            type="text"
                            className={`${
                              errorValues?.stabiliser ? "error-text" : ""
                            }`}
                            // onChange={(e) => handleInputs(e, 'strip')}
                            name="stabiliser"
                            onBlur={handlClickOutside}
                            value={poolInputs.stabiliser}
                            disabled
                            isAllowed={(inputObj) =>
                              limitInputValues(inputObj, limitInp.stabiliser)
                            }
                          />
                          {AllArray.stabiliser.map((el) => (
                            <div className="number-value">
                              {/* {console.log('el?.color', el?.color)} */}
                              <p>{el?.value}</p>
                              <div
                                className={`choose-box ${
                                  parseFloat(poolInputs?.stabiliser) ===
                                  parseFloat(el?.value)
                                    ? "selected-box"
                                    : ""
                                }`}
                              >
                                <div
                                  className="box box2"
                                  style={{ background: el?.color }}
                                  onClick={el?.value}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                  {poolInputs?.BoxCount === 4 && (
                    <>
                      <div className="strip-list strip-list-three">
                        <h6>pH</h6>
                        <div className="strip-box-list">
                          <NumberFormat
                            type="text"
                            className={`${errorValues?.ph ? "error-text" : ""}`}
                            // onChange={(e) => handleInputs(e, 'strip')}
                            name="ph"
                            onBlur={handlClickOutside}
                            value={poolInputs.ph}
                            disabled
                            isAllowed={(inputObj) =>
                              limitInputValues(inputObj, limitInp.ph)
                            }
                          />
                          {AllArray.ph.map((el) => (
                            <div className="number-value">
                              {/* {console.log('el?.color', el?.color)} */}
                              <p>{el?.value}</p>
                              <div
                                className={`choose-box ${
                                  parseFloat(poolInputs.ph) ===
                                  parseFloat(el?.value)
                                    ? "selected-box"
                                    : ""
                                }`}
                              >
                                <div
                                  className="box box2"
                                  style={{ background: el?.color }}
                                  onClick={el?.value}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="strip-list strip-list-four">
                        <h6>Free Chlorine</h6>
                        <div className="strip-box-list">
                          <NumberFormat
                            type="text"
                            className={`${
                              errorValues?.chlorine ? "error-text" : ""
                            }`}
                            // onChange={(e) => handleInputs(e, 'strip')}
                            name="chlorine"
                            onBlur={handlClickOutside}
                            value={poolInputs.chlorine}
                            disabled
                            isAllowed={(inputObj) =>
                              limitInputValues(inputObj, limitInp.chlorine)
                            }
                          />
                          {AllArray.chlorine.map((el) => (
                            <div className="number-value">
                              {/* {console.log('el?.color', el?.color)} */}
                              <p>{el?.value}</p>
                              <div
                                className={`choose-box ${
                                  parseFloat(poolInputs.chlorine) ===
                                  parseFloat(el?.value)
                                    ? "selected-box"
                                    : ""
                                }`}
                              >
                                <div
                                  className="box box2"
                                  style={{ background: el?.color }}
                                  onClick={el?.value}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="strip-list strip-list-five">
                        <h6>Total Alkalinity (TA)</h6>
                        <div className="strip-box-list">
                          <NumberFormat
                            type="text"
                            className={`${
                              errorValues?.alkalinity ? "error-text" : ""
                            }`}
                            // onChange={(e) => handleInputs(e, 'strip')}
                            name="alkalinity"
                            onBlur={handlClickOutside}
                            value={poolInputs.alkalinity}
                            disabled
                            isAllowed={(inputObj) =>
                              limitInputValues(inputObj, limitInp.alkalinity)
                            }
                          />
                          {AllArray.alkalinity.map((el) => (
                            <div className="number-value">
                              {/* {console.log('el?.color', el?.color)} */}
                              <p>{el?.value}</p>
                              <div
                                className={`choose-box ${
                                  parseFloat(poolInputs.alkalinity) ===
                                  parseFloat(el?.value)
                                    ? "selected-box"
                                    : ""
                                }`}
                              >
                                <div
                                  className="box box2"
                                  style={{ background: el?.color }}
                                  onClick={el?.value}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="strip-list strip-list-six">
                        <h6>Stabiliser (CYA)</h6>
                        <div className="strip-box-list">
                          <NumberFormat
                            type="text"
                            className={`${
                              errorValues?.stabiliser ? "error-text" : ""
                            }`}
                            // onChange={(e) => handleInputs(e, 'strip')}
                            name="stabiliser"
                            onBlur={handlClickOutside}
                            value={poolInputs.stabiliser}
                            disabled
                            isAllowed={(inputObj) =>
                              limitInputValues(inputObj, limitInp.stabiliser)
                            }
                          />
                          {AllArray.stabiliser.map((el) => (
                            <div className="number-value">
                              {/* {console.log('el?.color', el?.color)} */}
                              <p>{el?.value}</p>
                              <div
                                className={`choose-box ${
                                  parseFloat(poolInputs.stabiliser) ===
                                  parseFloat(el?.value)
                                    ? "selected-box"
                                    : ""
                                }`}
                              >
                                <div
                                  className="box box2"
                                  style={{ background: el?.color }}
                                  onClick={el?.value}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="calc-btn-bottom  form-row">
                <div className="col">
                  <div className="ml-auto calc-btn-reset desktop-bt-show">
                    <div className="back-btn">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleBackStrip}
                      >
                        <span>
                          <i className="fas fa-chevron-left" />
                        </span>
                        Back
                      </button>
                    </div>
                    <div className="calculate-btn draining-btn">
                      {showbtn && (
                        <Button
                          type="button"
                          className="btn btn-primary withoutdrainage"
                          onClick={handleOriginalReadings(poolInputs?.BoxCount)}
                        >
                          <span>
                            <img src={draining} alt="drain" />
                          </span>
                          Original Readings
                        </Button>
                      )}
                    </div>
                    <div className="calculate-btn">
                      <button
                        type="submit"
                        className="btn btn-primary btn-save btn btn-primary"
                        onClick={handleOk}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  </>
);

TestStripe.propTypes = {
  // handleInputs: PropTypes.bool.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  poolInputs: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
  AllArray: PropTypes.objectOf.isRequired,
  handleOriginalReadings: PropTypes.func.isRequired,
  limitInp: PropTypes.objectOf.isRequired,
  staticServerPathForImage: PropTypes.string.isRequired,
  handleBackStrip: PropTypes.func.isRequired,
  errorValues: PropTypes.objectOf.isRequired,
  showbtn: PropTypes.bool.isRequired,
  handlClickOutside: PropTypes.func.isRequired,
};
export default TestStripe;
