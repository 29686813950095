/* eslint-disable import/order */
/* eslint-disable jsx-quotes */
import React, { useState, useEffect } from "react";
import AdminWrapper from "../admin/adminwrapper/wrapper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function SubscriptionInformation() {
  const history = useHistory();
  const handleSubscriptionPage = () => {
    history.push("/subscription-management?popup=updateSubscription");
  };

  return (
    <AdminWrapper>
      <div className="d-flex flex-column justify-content-center align-items-center p-5 gap-4 h-100">
        <h1>Subcription has expired !! Please buy services again.</h1>
        <button
          type="button"
          onClick={handleSubscriptionPage}
          className="btn btn-save btn btn-primary"
        >
          Buy Subscription
        </button>
      </div>
    </AdminWrapper>
  );
}

export default SubscriptionInformation;
