/* eslint-disable indent */
/* eslint-disable consistent-return */
/* eslint-disable spaced-comment */
/* eslint-disable jsx-quotes */
/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/button-has-type */
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { Button, Form, Spinner } from "react-bootstrap";
import { appTokenInstance, userInstance } from "../../config/axios";
import toastMessage from "../../functions/toastMessage";
import SuccessPopup from "../subscriptionManagement/SuccessPopup";
import { queryString } from "../../functions";

function SubscribeCheckOutForm({
  returnPopUp,
  handleAction,
  handleFormModal,
  clientData,
  amountDisplay,
  poolData,
  UpdateSubscriptionPopup,
  specificDate,
  slectedItem,
  handleToggleSubscription,
  tokenState,
}) {
  const { popup } = queryString();
  // collect data from the user
  const [successPopupOpen, setSuccessPopopen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [cardholderName, setCardholderName] = useState("");
  const [updatedInvoiceUrl, setUpdatedInvoiceUrl] = useState("");
  // const navigate;
  // const SubscriptionPlanArr = [
  //   { price: '264.00', priceId: 'price_1Pt8EGJYCAG32lQBTIe9dOps' },
  //   { price: '462.00', priceId: 'price_1Prv6qP0R6JGt9iCdwgMvF5P' },
  //   { price: '594.00', priceId: 'price_1PrvIFP0R6JGt9iCYcXupjtT' },
  //   { price: '324.00', priceId: 'price_1PrwyaP0R6JGt9iCaHBVxx8U' },
  //   { price: '582.00', priceId: 'price_1Prx3QP0R6JGt9iCmxulgSxk' },
  //   { price: '774.00', priceId: 'price_1PrxDKP0R6JGt9iCHiVcQugc' },
  //   { price: '384.00', priceId: 'price_1PrxJAP0R6JGt9iCH9WReyHD' },
  //   { price: '702.00', priceId: 'price_1PrxLwP0R6JGt9iCJDmv8PEF' },
  //   { price: '954.00', priceId: 'price_1PrxO0P0R6JGt9iC31ngR6IR' },
  //   { price: '444.00', priceId: 'price_1PrxPWP0R6JGt9iCsDAweXdL' },
  //   { price: '822.00', priceId: 'price_1PrxSvP0R6JGt9iCzxGXZFQW' },
  //   { price: '1134.00', priceId: 'price_1PrxYAP0R6JGt9iChYlIospz' },
  //   { price: '504.00', priceId: 'price_1PrxZfP0R6JGt9iCje5vzgDH' },
  //   { price: '942.00', priceId: 'price_1PrxbrP0R6JGt9iC70cBMXdk' },
  //   { price: '1314.00', priceId: 'price_1Prxd8P0R6JGt9iCibdvsszB' },
  //   { price: '564.00', priceId: 'price_1Prxl7P0R6JGt9iCBEz1DrYO' },
  //   { price: '624.00', priceId: 'price_1Prxq9P0R6JGt9iCdrDLyhCI' },
  //   { price: '684.00', priceId: 'price_1PrxsPP0R6JGt9iC0D10rcaj' },
  //   { price: '1062.00', priceId: 'price_1Pry18P0R6JGt9iCqA48csMA' },
  //   { price: '1182.00', priceId: 'price_1Pry36P0R6JGt9iCtkmoQqLc' },
  //   { price: '1302.00', priceId: 'price_1Pry61P0R6JGt9iCQ3kUOwqW' },
  //   { price: '1,494.00', priceId: 'price_1PryJjP0R6JGt9iCJUjwT0WL' },
  //   { price: '1674.00', priceId: 'price_1PryOGP0R6JGt9iC4sExbBXU' },
  //   { price: '1,854.00', priceId: 'price_1PryQhP0R6JGt9iC6vwG5V0u' },
  // ];

  const SubscriptionPlanArr1 = [
    {
      price: "264.00",
      priceId: "price_1Pt8EGJYCAG32lQBTIe9dOps",
      paymentLink: "https://buy.stripe.com/test_fZe8AAfNYeSVdpu3cc",
    },
    {
      price: "462.00",
      priceId: "price_1PtRFmJYCAG32lQBNCZjVDPE",
      paymentLink: "",
    },
    {
      price: "594.00",
      priceId: "price_1PtRHCJYCAG32lQBlQ68Ulnk",
      paymentLink: "",
    },
    {
      price: "324.00",
      priceId: "price_1PtRIxJYCAG32lQBeASMee1D",
      paymentLink: "",
    },
    {
      price: "582.00",
      priceId: "price_1PtRKNJYCAG32lQBsKjLRXdn",
      paymentLink: "",
    },
    {
      price: "774.00",
      priceId: "price_1PtRL4JYCAG32lQBKrFE3m5e",
      paymentLink: "",
    },
    {
      price: "384.00",
      priceId: "price_1PtRMEJYCAG32lQBjlWi73Ne",
      paymentLink: "",
    },
    {
      price: "702.00",
      priceId: "price_1PtRN5JYCAG32lQBPfXG87kw",
      paymentLink: "",
    },
    {
      price: "954.00",
      priceId: "price_1PtRNvJYCAG32lQBmxcD52Df",
      paymentLink: "",
    },
    {
      price: "444.00",
      priceId: "price_1PtROxJYCAG32lQBo3a516Z3",
      paymentLink: "",
    },
    {
      price: "822.00",
      priceId: "price_1PtRQRJYCAG32lQBIa1JIigH",
      paymentLink: "",
    },
    {
      price: "1134.00",
      priceId: "price_1PtRQyJYCAG32lQBocjDN38S",
      paymentLink: "",
    },
    {
      price: "504.00",
      priceId: "price_1PtRS3JYCAG32lQBB6B6NbEc",
      paymentLink: "",
    },
    {
      price: "942.00",
      priceId: "price_1PtRT1JYCAG32lQBMZ6xlxHG",
      paymentLink: "",
    },
    {
      price: "1314.00",
      priceId: "price_1PtRTnJYCAG32lQBRmRw54bc",
      paymentLink: "",
    },
    {
      price: "564.00",
      priceId: "price_1PtRWyJYCAG32lQB5WHX1HN0",
      paymentLink: "",
    },
    {
      price: "624.00",
      priceId: "price_1PtRcpJYCAG32lQBnfptcjey",
      paymentLink: "",
    },
    {
      price: "684.00",
      priceId: "price_1PtRdNJYCAG32lQBBTbxBz2M",
      paymentLink: "",
    },
    {
      price: "1062.00",
      priceId: "price_1PtRgxJYCAG32lQBwjgFa5MY",
      paymentLink: "",
    },
    {
      price: "1182.00",
      priceId: "price_1PtRilJYCAG32lQBsDCoCuAZ",
      paymentLink: "",
    },
    {
      price: "1302.00",
      priceId: "price_1PtRjXJYCAG32lQBoROBrttf",
      paymentLink: "",
    },
    {
      price: "1,494.00",
      priceId: "price_1PtRkJJYCAG32lQBmQtfoBaS",
      paymentLink: "",
    },
    {
      price: "1674.00",
      priceId: "price_1PtRl8JYCAG32lQBsPGGmSjn",
      paymentLink: "",
    },
    {
      price: "1,854.00",
      priceId: "price_1PtRlyJYCAG32lQB4XyaiYVc",
      paymentLink: "",
    },
  ];

  const SubscriptionPlanArr = [
    {
      price: "0.01",
      priceId: "price_1Pzu5KJYCAG32lQBwAYG53fc",
      year: "1",
      paymentLink: "",
    },
    {
      price: "1",
      priceId: "price_1PzuvvJYCAG32lQBzVn4jXNc",
      year: "1",
      paymentLink: "",
    },
    {
      price: "0.10",
      priceId: "price_1PzuhsJYCAG32lQBO5RHvPjZ",
      year: "1",
      paymentLink: "",
    },
    {
      price: "264.00",
      priceId: "price_1PwiAPJYCAG32lQB8Z7mzHav",
      year: "1",
      paymentLink: "https://buy.stripe.com/8wM03N3Yqcxkdhe288",
    },
    {
      price: "179.52",
      priceId: "price_1PwiigJYCAG32lQBVrMH9K0Z",
      year: "1",
      paymentLink: "https://buy.stripe.com/14k4k3fH8gNAb967st",
    },
    {
      price: "462.00",
      priceId: "price_1PwieBJYCAG32lQBXpt2qauf",
      year: "2",
      paymentLink: "https://buy.stripe.com/7sI4k39iKbtg90Y3ce",
    },
    {
      price: "341.28",
      priceId: "price_1PwluHJYCAG32lQBco4aDs5q",
      year: "2",
      paymentLink: "https://buy.stripe.com/bIY8AjbqS0OC90Y147",
    },
    {
      price: "594.00",
      priceId: "price_1Pwm13JYCAG32lQB0c04n8XG",
      year: "3",
      paymentLink: "https://buy.stripe.com/14k8Aj1QigNAgtq28c",
    },
    {
      price: "484.92",
      priceId: "price_1Pwm3ZJYCAG32lQB5VIsxPsL",
      year: "3",
      paymentLink: "https://buy.stripe.com/3cs9EneD4eFscda5kp",
    },
    {
      price: "324.00",
      priceId: "price_1PwmIJJYCAG32lQB5TF7v2bb",
      year: "1",
      paymentLink: "https://buy.stripe.com/4gwcQzdz0fJwcda28e",
    },
    {
      price: "239.52",
      priceId: "price_1PwmIwJYCAG32lQBPczulacm",
      year: "1",
      paymentLink: "https://buy.stripe.com/eVa9En7aC2WK6SQ28f",
    },
    {
      price: "384.00",
      priceId: "price_1PwmUsJYCAG32lQB7IC8ZRQo",
      year: "1",
      paymentLink: "https://buy.stripe.com/7sI5o77aCeFsb96aEM",
    },
    {
      price: "299.52",
      priceId: "price_1PwmVhJYCAG32lQBRAS5OerS",
      year: "1",
      paymentLink: "https://buy.stripe.com/bIYcQz2Um0OCeli009",
    },
    {
      price: "444.00",
      priceId: "price_1PwmaSJYCAG32lQBX4RHn1lR",
      year: "1",
      paymentLink: "https://buy.stripe.com/eVacQz3Yq8h40us14e",
    },
    {
      price: "359.52",
      priceId: "price_1PwmbVJYCAG32lQBQBAFkBBQ",
      year: "1",
      paymentLink: "https://buy.stripe.com/eVa3fZ7aCcxkb968wH",
    },
    {
      price: "504.00",
      priceId: "price_1PwmczJYCAG32lQBoscVgS1y",
      year: "1",
      paymentLink: "https://buy.stripe.com/fZe17RgLc54SdheeV6",
    },
    {
      price: "419.52",
      priceId: "price_1PwmeQJYCAG32lQB0nvVNnxp",
      year: "1",
      paymentLink: "https://buy.stripe.com/fZeg2L1Qi0OC6SQeV7",
    },
    {
      price: "564.00",
      priceId: "price_1PwprZJYCAG32lQBtAWZT6fC",
      year: "1",
      paymentLink: "https://buy.stripe.com/eVabMv66y9l81yw6oC",
    },
    {
      price: "479.52",
      priceId: "price_1PwpwYJYCAG32lQBKBjGS1v0",
      year: "1",
      paymentLink: "https://buy.stripe.com/4gwdUDfH8apcgtqbIX",
    },
    {
      price: "624.00",
      priceId: "price_1PwpxMJYCAG32lQBXFcCz6Yn",
      year: "1",
      paymentLink: "https://buy.stripe.com/8wMbMvbqSeFs1yw6oE",
    },
    {
      price: "539.52",
      priceId: "price_1PwpyIJYCAG32lQBfB5FArla",
      year: "1",
      paymentLink: "https://buy.stripe.com/9AQ8AjbqSgNA2CAfZf",
    },
    {
      price: "684.00",
      priceId: "price_1PwpzxJYCAG32lQBnteUtObh",
      year: "1",
      paymentLink: "https://buy.stripe.com/eVa8Aj0Me8h45OMcN4",
    },
    {
      price: "599.52",
      priceId: "price_1Pwq1OJYCAG32lQBAdDndCjO",
      year: "1",
      paymentLink: "https://buy.stripe.com/cN24k30Me68Wdhe14n",
    },

    {
      price: "582.00",
      priceId: "price_1Pwmz0JYCAG32lQBmCCGele1",
      year: "2",
      paymentLink: "https://buy.stripe.com/6oEaIr7aC40Odhe3cw",
    },
    {
      price: "461.28",
      priceId: "price_1PwmzmJYCAG32lQBKStyE6ci",
      year: "2",
      paymentLink: "https://buy.stripe.com/14kaIr1Qi8h4fpm00l",
    },
    {
      price: "702.00",
      priceId: "price_1Pwn2TJYCAG32lQBZUv0ixfY",
      year: "2",
      paymentLink: "https://buy.stripe.com/6oEaIr8eGbtg7WU8wS",
    },
    {
      price: "581.28",
      priceId: "price_1Pwn2xJYCAG32lQBS28Dg3gr",
      year: "2",
      paymentLink: "https://buy.stripe.com/fZe4k32Um68WfpmcN9",
    },
    {
      price: "701.28",
      priceId: "price_1PwnaPJYCAG32lQBSU6hXrEw",
      year: "2",
      paymentLink: "https://buy.stripe.com/3cs03NbqS2WK2CA7sQ",
    },
    {
      price: "822.00",
      priceId: "price_1PwnZEJYCAG32lQBp8GGS7Pa",
      year: "2",
      paymentLink: "https://buy.stripe.com/9AQ9En2Um40OdhebJ7",
    },
    {
      price: "942.00",
      priceId: "price_1Pwo7pJYCAG32lQBHyVdiDBo",
      year: "2",
      paymentLink: "https://buy.stripe.com/cN2bMvfH88h4gtq3cC",
    },
    {
      price: "821.28",
      priceId: "price_1PwoBPJYCAG32lQBY7efwElH",
      year: "2",
      paymentLink: "https://buy.stripe.com/28o8Aj52u0OCdhe5kL",
    },
    {
      price: "1,062.00",
      priceId: "price_1PwonGJYCAG32lQBTMoNYGcP",
      year: "2",
      paymentLink: "https://buy.stripe.com/5kA9En1QigNAelibJa",
    },
    {
      price: "941.28",
      priceId: "price_1PwpNMJYCAG32lQBRWdBg6gy",
      year: "2",
      paymentLink: "https://buy.stripe.com/5kA03NgLccxkfpmcNf",
    },
    {
      price: "1,182.00",
      priceId: "price_1PwpYtJYCAG32lQBr8bl7hqt",
      year: "2",
      paymentLink: "https://buy.stripe.com/4gwbMveD440OcdaaF8",
    },
    {
      price: "1,061.28",
      priceId: "price_1PwpZMJYCAG32lQBs0qFwTOt",
      year: "2",
      paymentLink: "https://buy.stripe.com/5kA4k37aC68W0us7sX",
    },
    {
      price: "1302.00",
      priceId: "price_1PwpdMJYCAG32lQBraUDTb60",
      year: "2",
      paymentLink: "https://buy.stripe.com/eVa5o71Qi40Oa5214A",
    },
    {
      price: "1,181.28",
      priceId: "price_1PwpgFJYCAG32lQBxTVRtoga",
      year: "2",
      paymentLink: "https://buy.stripe.com/28odUDbqS9l8eli9B7",
    },
    {
      price: "774.00",
      priceId: "price_1PwoH8JYCAG32lQBDqyTmdas",
      year: "3",
      paymentLink: "https://buy.stripe.com/28oaIr7aC8h41ywbJg",
    },
    {
      price: "664.92",
      priceId: "price_1PwoHlJYCAG32lQBZDC2FNda",
      year: "3",
      paymentLink: "https://buy.stripe.com/00g6sbfH8btg3GEfZx",
    },
    {
      price: "954.00",
      priceId: "price_1PwoMUJYCAG32lQBSqsu0dfz",
      year: "3",
      paymentLink: "https://buy.stripe.com/bIY2bVdz0fJw0us28I",
    },
    {
      price: "844.92",
      priceId: "price_1PwoNeJYCAG32lQBgUmBg8cp",
      year: "3",
      paymentLink: "https://buy.stripe.com/14k17R7aCdBoa523cN",
    },
    {
      price: "1,134.00",
      priceId: "price_1PwoWhJYCAG32lQBYaCfngNa",
      year: "3",
      paymentLink: "https://buy.stripe.com/aEU5o7amO9l8b96cNo",
    },
    {
      price: "1,024.92",
      priceId: "price_1PwoYSJYCAG32lQB1EaFc8P4",
      year: "3",
      paymentLink: "https://buy.stripe.com/8wM17R9iK1SGfpm14H",
    },
    {
      price: "1,314.00",
      priceId: "price_1PwocqJYCAG32lQBXqUZz8kD",
      year: "3",
      paymentLink: "https://buy.stripe.com/6oE4k31Qicxka524gU",
    },
    {
      price: "1,204.92",
      priceId: "price_1PwodxJYCAG32lQBTuKgsRUg",
      year: "3",
      paymentLink: "https://buy.stripe.com/cN25o752ubtgelicNr",
    },
    {
      price: "1,494.00",
      priceId: "price_1PwojgJYCAG32lQB9hlq48OF",
      year: "3",
      paymentLink: "https://buy.stripe.com/7sI7wf9iK9l8fpmaFk",
    },
    {
      price: "1,384.92",
      priceId: "price_1Pwol6JYCAG32lQBG8mXleAm",
      year: "3",
      paymentLink: "https://buy.stripe.com/8wMg2Ldz02WKfpm00H",
    },
    {
      price: "1,674.00",
      priceId: "price_1Pwp3oJYCAG32lQB3se38QiJ",
      year: "3",
      paymentLink: "https://buy.stripe.com/eVadUDdz00OCgtq14M",
    },
    {
      price: "1,564.92",
      priceId: "price_1Pwp5LJYCAG32lQBrIltZxZ0",
      year: "3",
      paymentLink: "https://buy.stripe.com/14k8Aj3Yq1SG6SQ00J",
    },
    {
      price: "1,854.00",
      priceId: "price_1PwpCdJYCAG32lQBhdILp7vd",
      year: "3",
      paymentLink: "https://buy.stripe.com/eVacQzdz00OC1ywbJs",
    },
    {
      price: "1,744.92",
      priceId: "price_1PwpEgJYCAG32lQBHXwM5cSR",
      year: "3",
      paymentLink: "https://buy.stripe.com/14k7wf8eG9l8cda28T",
    },
  ];

  const matchingPlan = SubscriptionPlanArr.find(
    (plan1) => Number(plan1.price) === Number(amountDisplay),
  );

  const matchedpriceId = matchingPlan ? matchingPlan.priceId : null;

  const getInstance = () => {
    if (tokenState?.token) {
      return appTokenInstance(tokenState?.token, tokenState?.stafftoken);
    }
    return userInstance();
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Combine to get a consistent local date in 'YYYY-MM-DDT00:00:00' format
    const localDateString = `${year}-${month}-${day}`;

    // Convert to UTC without changing the day
    const localDate = new Date(localDateString);
    return localDate.toISOString().split("T")[0];
  };

  const upgradeSubscriptionPlan = async () => {
    try {
      if (slectedItem || specificDate) {
        const payload = {
          pId: matchedpriceId,
          uType: slectedItem,
          sDate: specificDate ? formatDate(specificDate) : null,
          nextSubscriptionPlanDetail: {
            numberUsers: clientData?.numberUsers,
            numberOfPools: clientData?.numberOfPools,
            duration: Number(clientData?.duration),
          },
        };
        const response = await getInstance().put(
          "/user/updatePlanSubscription",
          payload,
        );
        if (response?.status === 200) {
          if (response?.data?.isActive) {
            toastMessage(
              "success",
              "Your subscription plan has been updated successfully and will be reflected after 30 minutes.",
            );
            window.location.href = "/subscription-management";
          } else if (response?.data?.url) {
            setUpdatedInvoiceUrl(response?.data?.url);
            // eslint-disable-next-line no-undef
            setSuccessPopopen(true);
          } else {
            toastMessage(
              "success",
              "Your subscription plan update successfully",
            );
            window.location.href = "/subscription-management";
          }
        }
      } else {
        console.log("Either selected item or specific date is missing");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserStatus = async () => {
    const isStatus = await userInstance().get("/user/getUserStatus");
    console.log("isStatus===>", isStatus);
    // window.location.href = '/subscription-management';
    if (isStatus === true) {
      window.location.href = "/subscription-management";
    }
  };

  // const HandleSubscription=()=>{

  // }

  const handleCloseSuccessPopup = () => {
    setSuccessPopopen(false);
    handleAction();
    handleToggleSubscription("Current Subscription");
  };

  // stripe items

  const stripe = useStripe();
  const elements = useElements();

  let clientName = `${clientData.firstname} ${clientData.lastname}`;
  let clientEmail = clientData.email;

  // New Plan Subscription
  const newPlanCreation = async () => {
    try {
      setLoader(true);
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardNumberElement),
        billing_details: {
          name: cardholderName,
          email: clientEmail,
        },
      });
      const payload = {
        paymentMethod: paymentMethod?.paymentMethod?.id,
        name: clientName,
        email: clientEmail,
        priceId: matchedpriceId,
        nextSubscriptionPlanDetail: {
          numberUsers: clientData?.numberUsers,
          numberOfPools: clientData?.numberOfPools,
          duration: Number(clientData?.duration),
          totalCost: clientData?.totalCost,
        },
      };

      const response = await getInstance().post(
        "/user/new-subscription-create",
        payload,
      );
      if (response?.status === 200) {
        setLoader(false);
        toastMessage("success", response?.data?.msg);
        if (response?.data?.url) {
          setSuccessPopopen(true);
          setUpdatedInvoiceUrl(response?.data?.url);
        } else {
          window.location.href = "/";
        }
      }
    } catch (error) {
      setLoader(false);
      console.log("New Plan error ", error);
    }
  };

  //=================//

  // main function
  const createSubscription = async () => {
    try {
      // create a payment method
      setLoader(true);
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardNumberElement),
        billing_details: {
          name: cardholderName,
          email: clientEmail,
        },
      });
      // console.log('paymentMethod=>>>', paymentMethod?.paymentMethod.card.last4, 'CardElement===>', CardElement);

      const payload = {
        paymentMethod: paymentMethod?.paymentMethod?.id,
        name: clientName,
        email: clientEmail,
        priceId:
          matchedpriceId === null || clientData?.subscriptionStatus === "trial"
            ? "price_1Pt8EGJYCAG32lQBTIe9dOps"
            : matchedpriceId,
        subscriptionStatus: clientData?.subscriptionStatus,
      };
      const response = await getInstance().post(
        "/user/create-subscription",
        payload,
      );
      if (
        response.status === 200 &&
        clientData?.subscriptionStatus === "trial"
      ) {
        const responseUserTrail = await getInstance().post(
          "/user/registerSubscriber",
          {
            ...clientData,
            subscriptionId: response?.data?.subscriptionId,
            customerId: response?.data?.customerId,
            startsubscriptionDate: response?.data?.startDate,
            endsubscriptionDate: response?.data?.endDate,
            nextSubscriptionPlanDetail: {
              numberUsers: 2,
              numberOfPools: 1,
              duration: 12,
            },
          },
        );

        console.log("res", responseUserTrail);
        const { msg, code, userid } = responseUserTrail.data;
        toastMessage("success", "User Register Successfully");
        handleAction(returnPopUp);
        handleFormModal();
        delete poolData._id;
        delete poolData.userPool;
        delete poolData.taxApplicable;

        poolData?.calciumAdjustments?.forEach((elem) => {
          elem.okNegative = "0.1";
          elem.okPositive = "49";
          elem.drainPoint = "50";
        });
        console.log(
          "poolData?.calciumAdjustments======>>",
          poolData?.calciumAdjustments,
        );

        const addNewSubscriberPayload = {
          userid,
          poolData,
          role: "subscriber",
        };
        console.log(poolData, "pppppppp");

        await getInstance().post(
          "/subscribers/addNewSubscriberData",
          addNewSubscriberPayload,
        );
      }
      let confirmPayment;
      if (response.data?.clientSecret) {
        confirmPayment = await stripe?.confirmCardPayment(
          response.data.clientSecret,
        );
      }

      console.log("confirmPayment===>", confirmPayment);
      if (confirmPayment?.error) {
        console.log(confirmPayment.error.message);
      }
      console.log(
        "clientData?.subscriptionStatus===>API===>>",
        clientData?.subscriptionStatus,
      );

      if (clientData?.subscriptionStatus !== "trial") {
        console.log("Success! Check your email for the invoice.");
        const responseUser = await getInstance().post(
          "/user/registerSubscriber",
          {
            ...clientData,
            subscriptionId: response?.data?.subscriptionId,
            customerId: response?.data?.customerId,
            startsubscriptionDate: response?.data?.startDate,
            endsubscriptionDate: response?.data?.endDate,
          },
        );
        const { msg, code, userid } = responseUser.data;
        toastMessage("success", "User Register Successfully");
        setLoader(false);
        handleAction(returnPopUp);
        handleFormModal();
        delete poolData._id;
        delete poolData.userPool;
        delete poolData.taxApplicable;

        poolData?.calciumAdjustments?.forEach((elem) => {
          elem.okNegative = "0.1";
          elem.okPositive = "49";
          elem.drainPoint = "50";
        });
        console.log(
          "poolData?.calciumAdjustments======>>",
          poolData?.calciumAdjustments,
        );
        console.log(
          "poolData?.calciumAdjustments======>fdfdfkdf>",
          poolData?.calciumAdjustments,
        );

        const addNewSubscriberPayload = {
          userid,
          poolData,
          role: "subscriber",
        };
        console.log(poolData, "pppppppp");

        await getInstance().post(
          "/subscribers/addNewSubscriberData",
          addNewSubscriberPayload,
        );
        // history.push('/subscriptions');
      }
    } catch (error) {
      console.log("error", error);
      setLoader(false);
      toastMessage("error", "Please Enter the Valid Card Details.");
    }
  };

  const handleCardholderNameChange = (e) => {
    const regex = /^[a-zA-Z\s]*$/; // Regex to allow only letters and spaces
    if (regex.test(e.target.value)) {
      setCardholderName(e.target.value); // Update value only if it matches regex
    }
  };

  return (
    <div className="grid gap-4 m-auto mt-3">
      <Form.Label htmlFor="inputPassword5">Cardholder name</Form.Label>
      <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock"
        value={cardholderName}
        onChange={handleCardholderNameChange} // Apply the restriction
        placeholder="Enter cardholder's name"
      />

      <Form.Label htmlFor="inputPassword5">Card number</Form.Label>
      <div id="card-expiry-element" className="card_input">
        <CardNumberElement
          options={{
            style: {
              base: {
                color: "#555",
                fontFamily: "Montserrat, sans-serif",
                border: "1px solid red",
              },
              border: "1px solid #ccc",
            },
            invalid: {
              color: "#fa755a",
              iconColor: "#fa755a",
              borderColor: "#fa755a", // You can change border color when the input is invalid
            },
            complete: {
              borderColor: "#4caf50", // Optional: Border color when input is complete
            },
          }}
        />
      </div>
      <div className="d-flex gap-2">
        <div className="w-50">
          <Form.Label htmlFor="inputPassword5">Expiry Date</Form.Label>
          <div id="card-expiry-element" className="card_input">
            <CardExpiryElement
              options={{
                style: {
                  base: { color: "#555", fontFamily: "Montserrat, sans-serif" },
                },
              }}
            />
          </div>
        </div>
        <div className="w-50">
          <Form.Label htmlFor="inputPassword5">CVC</Form.Label>
          <div id="card-expiry-element" className="card_input">
            <CardCvcElement
              options={{
                style: {
                  base: { color: "#555", fontFamily: "Montserrat, sans-serif" },
                },
              }}
            />
          </div>
        </div>
      </div>
      {successPopupOpen && (
        <SuccessPopup
          openPaymentForm={successPopupOpen}
          getUserStatus={getUserStatus}
          handleAction={handleCloseSuccessPopup}
          updatedInvoiceUrl={updatedInvoiceUrl}
        />
      )}

      {/* <CardElement id="card-element" options={{ options }} /> */}
      {!loader ? (
        <Button
          className="btn-save mt-3"
          type="submit"
          onClick={
            UpdateSubscriptionPopup
              ? upgradeSubscriptionPlan
              : popup === "updateSubscription"
                ? newPlanCreation
                : createSubscription
          }
          disabled={!stripe}
        >
          Process Payment
        </Button>
      ) : (
        <Button
          className="btn-save pb-3"
          // type="submit"
        >
          {/* <div className="video-loader1 pb-5"> */}
          <Spinner animation="border" size="sm" />
          {/* </div> */}
        </Button>
      )}
    </div>
  );
}

export default SubscribeCheckOutForm;

SubscribeCheckOutForm.propTypes = {
  returnPopUp: PropTypes.objectOf.isRequired,
  handleAction: PropTypes.func.isRequired,
  handleFormModal: PropTypes.func.isRequired,
  clientData: PropTypes.objectOf.isRequired,
  amountDisplay: PropTypes.string.isRequired,
};
