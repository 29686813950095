import React from "react";
import "react-bootstrap";
import PropTypes from "prop-types";
// import flogo from '../../assets/home/logo-pool.png';
import flogo from "../../assets/home/footer-logo.png";
import "./footer.css";
import StickyFooter from "./stickyfooter";

const Footer = ({
  TodayEvents,
  handleNotify,
  count,
  setNotify,
  notify,
  checkNotification,
  setCheckNotification,
}) => (
  <>
    <div className="footer-container">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="footer-upper">
              <div className="footer-logo">
                <img src={flogo} alt="footer-logo" />
              </div>
            </div>
            <div className="footer-mid">
              <ul className="footer-nav">
                <li>
                  <a href="#/">Home</a>
                </li>
                <li>
                  <a href="#/">About</a>
                </li>
                <li>
                  <a href="#/">Online demo & product info pricing</a>
                </li>
                <li>
                  <a href="#/">buy now or renew access pool maintenance</a>
                </li>
                <li>
                  <a href="#/">contact</a>
                </li>
                <li>
                  <a href="#/">login to pool tester</a>
                </li>
              </ul>
            </div>
            <div className="footer-lower">
              <div className="copyright">POOL-TESTER @ 2019</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <StickyFooter
      TodayEvents={TodayEvents}
      handleNotify={handleNotify}
      count={count}
      setNotify={setNotify}
      notify={notify}
      checkNotification={checkNotification}
      setCheckNotification={setCheckNotification}
    />
  </>
);
Footer.defaultProps = {
  count: PropTypes.number,
};
Footer.propTypes = {
  TodayEvents: PropTypes.func.isRequired,
  handleNotify: PropTypes.func.isRequired,
  count: PropTypes.number,
  setNotify: PropTypes.func.isRequired,
  checkNotification: PropTypes.bool.isRequired,
  setCheckNotification: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
};
export default Footer;
