import { userInstance } from "../../config/axios";

const adjustInv = async (payload) => {
  console.log("payload>>>>>>>>>>>kgfhgfhgfkh", payload);
  try {
    const response = await userInstance().post(
      "/inventory/inventoryAdjustment",
      {
        payload,
      },
    );
    return response.data;
  } catch (error) {
    return { msg: error };
  }
};
export default adjustInv;
