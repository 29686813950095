/* eslint-disable no-empty */
import React, { useState, useContext, useRef, useEffect } from "react";
import { Button, Form, Dropdown } from "react-bootstrap";

import { PropTypes } from "prop-types";
import { useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import moment from "../../functions/moment";
import arrow from "../../assets/home/arrowdown.png";
import sort from "../../assets/home/sort.png";
import showmore from "../../assets/home/showmore.png";
import SearchModal from "./searchmodal";
import {
  showErrMsg,
  queryString,
  getCalendarPath,
  addZero,
  averageGstCalculation,
} from "../../functions/index";
import { userInstance } from "../../config/axios";
import {
  errorBorderTime,
  errorMsgTime,
  successMsgTime,
} from "../../functions/intervalTime";
import DateModal from "./datePopup";
import ServiceModal from "../appointment/servicemodal";
import TimePicker from "../appointment/timepicker";
import HourPicker from "../appointment/hourPicker";
import { AuthDataContext } from "../../contexts/AuthDataContext";
import DurationPicker from "../appointment/durationPicker";
import upsort from "../../assets/home/up-sort.png";
import downsort from "../../assets/home/down-sort.png";
import NewpreviewInvoicePopup from "./newpreviewInvoicePopup";
import PreviewInvoiceConfirm from "../appointment/previewInvoiceConfirm";
import greydelete from "../../assets/home/grey-delete.png";
import edit from "../../assets/home/grey-edit.png";
import clientimg from "../../assets/home/client.png";
import sortClientList from "../../functions/listSort";

const NewInvoice = ({ setView, updateData, clienttype }) => {
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const history = useHistory();
  const {
    locationsInfo,
    userData: { industryId },
    clientAllData,
  } = useContext(AuthDataContext);
  const dateFormat = "YYYY-MM-DDTHH:mm:ssZ";
  const timeFromDateFormat = "HH:mm";
  const { client: clientIdentity } = queryString();
  const [client, setclient] = useState();
  const [clientTxt, setClientTxt] = useState("");
  const [searchedClients, setClients] = useState([]);
  const [searchedClients2, setClients2] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [addClient, setAddClient] = useState([]);
  const [loader, setLoader] = useState(false);

  const [datePopup, setDatePopup] = useState();
  const [renderSort, setRenderSort] = useState(false);
  const [addedServices, setServices] = useState([]);
  const [appointmentIndex, setappointmentIndex] = useState("");
  const [staffIndex, setStaffIndex] = useState();
  const [newLength, setnewlength] = useState();
  const [showService, setShowService] = useState(false);
  const [editData, setEditData] = useState({ edit: false, index: "" });
  const [subcategoryTxt, setSearchTxt] = useState("");
  const [misc, setMisc] = useState(false);
  const [subcategories, setSubcategories] = useState(null);
  const [categories, setCategories] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [timepickerShow, setTimePickerShow] = useState(false);
  const [extras, setExtras] = useState({ value: "", msg: "" });
  const [durationPicker, setDurationPicker] = useState(false);
  const [notes, setNotes] = useState("");
  const [hourPicker, setHourPicker] = useState(false);
  const [hourData, setHourData] = useState("");
  const [appointmentType, setAppointmentType] = useState("oneteam_oneday");
  const [multiServices, setMultiServices] = useState([]);
  const [repeatFrequency, setRepeatFrequency] = useState("");
  const timepickerRef = useRef(null);
  const [quotesTotal, setQuotesTotal] = useState("");
  const [quoteDate, setQuoteDate] = useState(new Date());
  const [timeData, setTime] = useState("9:30");
  const [durationData, setDuration] = useState("");
  const [showInvoice, setShowInvoice] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState({});
  const [voidPermission, setVoidPermission] = useState(false);
  const [addappt, setAddAppt] = useState("defaultTemp");
  const [defaultTemp, setdefaultTemp] = useState();
  const [templateArray, setTemplateArray] = useState([]);
  const [errorValue, setErrorValue] = useState();
  const [isHideSearch, setHideSearch] = useState(false);
  const [activeAdd, setActiveAdd] = useState(0);

  const initialDateData = {
    date: moment().format(dateFormat),
    createdOn: new Date(),
    interval_multiplier: null,
    occurence: null,
    interval_type: null,
    endsAt: null,
    endType: null,
    msg: "One-off appointment | Doesn't repeat",
    frequencyMsg: "",
    occurenceMsg: "",
    specificDate: moment().format(dateFormat),
    requiredday: null,
    appointmenttype: "oneteam_oneday",
    service: [],
  };
  const [newAppointment, setNewAppointment] = useState([initialDateData]);
  const [dateData, setDateData] = useState(initialDateData);
  const initialBookingData = {
    frequency: "One-off appointment | Doesn't repeat",
    repeatEnd: "Ongoing",
    category: "",
    subcategory: "",
    startTime: "9:30",
    duration: "5",
    staffId: "",
    staffName: "",
    clientId: "",
    cost: "",
    start: "",
    end: "",
    customCost: "default",
    type: "",
    buyCostProduct: "",
    laborMargin: "",
    quantity: "1",
    timeChange: false,
    customMisc: "",
    previousDuration: "",
    previousQuantity: "",
    pricingType: "fixedCost",
    previousCost: "",
    prevBuyCostProduct: "",
    renderCost: "",
    soldPer: "",
    unit: "",
    symbol: "",
    description: "",
    tax: "",
    s_uId: "",
    categoryId: "",
    supplier: "",
  };
  const [bookingData, setBookingData] = useState(initialBookingData);
  const [summary, setSummary] = useState({
    totalCost: 0,
    totalDuration: "",
    totalLabourMargin: 0,
  });
  const initialDatePopup = {
    interval_type: "weekly",
    interval_multiplier: "4",
  };
  const [datePopupData, setDatePopupData] = useState(initialDatePopup);
  const handleClient = async () => {
    setclient(!client);
    if (client) {
      setClients(null);
      setClientTxt("");
    } else {
      const response = await userInstance().get("/customer/getCustomersAll2");
      if (response.status === 200) {
        const { customers } = response.data;
        const newList = sortClientList(customers);
        setClients(newList);
        setClients2(newList);
      }
    }
  };
  const searchClient = (searchTxt_) => {
    setClientTxt(searchTxt_);
  };
  useEffect(() => {
    let searchTimeOut = setTimeout(async () => {
      if (!clientTxt) {
        setClients(searchedClients2);
        return;
      }
      try {
        console.log("API CALEED HERE IN TIMEOUT", clientTxt);
        const response = await userInstance().get(
          `/customer/searchCustomer?value=${clientTxt}`,
        );
        let list = response.data.customers;
        if (clientTxt) {
          if (isHideSearch) {
            console.log("check3");
            list.forEach((client2) => {
              if (client2.siteAddressess) {
                client2.siteAddressess.forEach((addr) => {
                  addr.hide = false;
                });
              }
            });
          } else {
            console.log("wantConditions");
            list = list.filter((el) =>
              el.siteAddresses
                ? el.siteAddresses.filter((adr) => adr.hide).length === 0
                : el,
            );
          }
        }
        const newList = sortClientList(list);
        setClients(newList);
      } catch (error) {
        console.log(error);
      }
    }, 500);
    // console.log('intravl2');
    return () => clearInterval(searchTimeOut);
  }, [clientTxt]);

  /* eslint-disable consistent-return */
  const getStaffList = async () => {
    try {
      const response = await userInstance().get("/staff/getStaff");
      const { staff } = response.data;
      setStaffList(staff);
      if (staff.length) {
        return {
          staffName: `${staff[0].firstname} ${staff[0].lastname}`,
          staffId: staff[0]._id,
        };
      }
    } catch (error) {
      return null;
    }
  };

  async function getCategories() {
    try {
      const response = await userInstance().get(
        `/service/getServices?industryId=${industryId._id}`,
      );
      const { services } = response.data;
      setCategories(services);
      const staffData = await getStaffList();
      const objToUpdate = {
        ...bookingData,
      };
      if (staffData !== null && staffData !== undefined) {
        objToUpdate.staffName = staffData.staffName;
        objToUpdate.staffId = staffData.staffId;
      }
      setBookingData(objToUpdate);
    } catch (error) {}
  }

  useEffect(() => {
    getCategories();
  }, [dateData]);
  const handleAddClient = (info) => {
    const copy = { ...info };
    if (info.siteAddresses[activeAdd] || info.siteAddresses.length === 0) {
      let { activeSiteAdd } = info.siteAddresses[activeAdd]
        ? info.siteAddresses[activeAdd]
        : {};
      if (activeSiteAdd || info.siteAddresses.length === 0) {
        setSelectedClient(info._id);
        setAddClient([info]);
        handleClient();
        setClientTxt("");
      } else {
        NotificationManager.error(
          "Pleass select site address",
          "Message",
          errorMsgTime,
        );
      }
    } else {
      NotificationManager.error(
        "Pleass select site address",
        "Message",
        errorMsgTime,
      );
    }
  };
  const checkAddress = (index, id) => {
    setActiveAdd(index);
    let oldState = [...searchedClients];
    oldState.forEach((el) => {
      if (el._id === id) {
        el.siteAddresses?.forEach((el2, i) => {
          if (index === i) {
            el2.activeSiteAdd = true;
          } else {
            el2.activeSiteAdd = false;
          }
        });
      } else {
        el.siteAddresses.forEach((el2, i) => {
          el2.activeSiteAdd = false;
        });
      }
    });
    setClients(oldState);
  };

  const deleteClient = () => {
    setSelectedClient("");
    setAddClient([]);
    setClientTxt("");
  };
  const isValidDate = (d) => d instanceof Date && !Number.isNaN(d);
  const handleDatePopup = (e) => {
    if (isValidDate(e)) {
      setDateData({
        ...dateData,
        date: moment(e).format("YYYY-MM-DDTHH:mm:ssZ"),
      });
      setQuoteDate(e);
    }
    setDatePopup(!datePopup);
  };

  const handleShowService = (index) => {
    setappointmentIndex(index);
    setStaffIndex(0);
    setnewlength(addedServices.length);
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    setShowService(!showService);
    setEditData({ edit: false, index: "" });
    const lastService = addedServices[addedServices.length - 1];
    setSearchTxt("");
    setSubcategories(null);
    setMisc(false);
  };
  const handleBookingData = (e) => {
    const { name, value } = e.target;
    const obj = { ...bookingData, [name]: value };
    if (name === "staffName") {
      const staffId = staffList[e.target.selectedIndex]._id;
      obj[name] = value;
      obj.staffId = staffId;
      setStaff(value, staffId);
    }
    if (name === "cost" && !bookingData.customCost) {
      const newval = addZero(value);
      obj[name] = newval;
    } else if (name === "startTime") {
      obj.timeChange = true;
    } else if (name === "duration") {
      obj.durationChange = true;
    } else if (name === "quantity") {
      obj.quantityChange = true;
    } else if (name === "pricingType") {
      obj.duration = bookingData.previousDuration;
      obj.quantity = "5";
      if (bookingData.customCost !== "default") {
        obj.cost = "";
      }
    } else if (name === "soldPer" && value !== "Add Custom Measure") {
      obj.unit = "";
      obj.symbol = "";
    }
    setBookingData(obj);
  };

  const setStaff = (staffName, staffId) => {
    const servicesListCopy = addedServices;
    servicesListCopy.forEach((el) => {
      el.staffId = staffId;
      el.staffName = staffName;
    });
    setServices([...servicesListCopy]);
  };

  const g = () => {
    const x = {
      slotInterval: 5,
      openTime: "12:25 AM",
      closeTime: "23:30 PM",
    };
  };
  const getTimes = () => g();

  const handleBackPage = () => {
    if (clientIdentity) {
      history.push(`${getCalendarPath()}&clientid=${clientIdentity}`);
    }
  };
  const renderOptions = () => {
    let val = 5;
    const arr = [];
    while (val <= 960) {
      const hours = Math.floor(val / 60);
      const minutes = val % 60;
      const msg =
        hours && minutes
          ? `${hours}hr ${minutes} min`
          : hours && !minutes
            ? `${hours}hr`
            : `${minutes} min`;
      arr.push({ value: val, data: msg });
      val += 5;
    }
    return arr;
  };
  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msg = "";
    msg =
      hours && minutes
        ? `${hours}hr ${minutes} min`
        : hours && !minutes
          ? `${hours}hr`
          : `${minutes} min`;
    return msg;
  };
  const validateService = () => {
    let isValid = true;
    let msg = "";
    let errValue = "";
    const { category, subcategory, staffName, type, customMisc, quantity } =
      bookingData;
    let { cost, buyCostProduct } = bookingData;

    cost = cost.replace(/,/g, "").replace("$", "");
    buyCostProduct = buyCostProduct.replace(/,/g, "").replace("$", "");
    if (!category) {
      isValid = false;
      msg = "Please select service or product";
      errValue = "category";
    } else if (!subcategory) {
      isValid = false;
      msg = "Please select subcategory";
      errValue = "subcategory";
    } else if (!staffName) {
      isValid = false;
      msg = "Please select staff";
      errValue = "staffName";
    } else if (
      type === "product" &&
      parseFloat(cost) < parseFloat(buyCostProduct)
    ) {
      isValid = false;
      msg = "Sell price must be greater than buy cost";
      errValue = "sell price";
    } else if (!cost) {
      isValid = false;
      msg = "Enter Sell Price (ex tax)";
      errValue = "sell price";
    } else if (!buyCostProduct && type === "product") {
      isValid = false;
      msg = "Enter Buy Price (ex tax)";
      errValue = "buyCostProduct";
    } else if (
      bookingData.category.toLowerCase().includes("misc") &&
      !customMisc
    ) {
      isValid = false;
      msg = "Enter New Name";
      errValue = "customMisc";
    } else if (!quantity) {
      isValid = false;
      msg = "Enter quantity";
      errValue = "quantity";
    }
    return { isValid, msg, errValue };
  };
  function groupBy(collection, property) {
    let i = 0;
    let val;
    let index;
    let values = [];
    let result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }
  const handleAddService = async () => {
    const {
      subcategory,
      type,
      duration,
      previousDuration,
      previousQuantity,
      pricingType,
      customMisc,
      description,
    } = bookingData;
    let { cost, buyCostProduct, renderCost, quantity } = bookingData;
    let oldStateCopy = [...newAppointment];
    quantity = quantity.replace(/,/g, "");
    cost = parseFloat(cost.replace(/,/g, "").replace("$", "")).toFixed(2);
    buyCostProduct = parseFloat(
      buyCostProduct.replace(/,/g, "").replace("$", ""),
    ).toFixed(2);
    renderCost =
      type === "service" && pricingType === "hourly"
        ? (parseFloat(cost) * parseFloat(quantity)) / 60
        : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);
    if (type === "product") {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = (renderCost / quantity).toFixed(2).toString();
    }
    if (type === "service" || pricingType === "hourly") {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
    }
    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      let startTime = "9:30";
      let start = moment(dateData.date).toDate();
      let time = bookingData.startTime.split(":");
      const bookingDataCopy = { ...bookingData };

      const lastService = addedServices[addedServices.length - 1];

      if (lastService) {
        start = moment(lastService.end).toDate();
        time = moment(lastService.end).format(timeFromDateFormat).split(":");
      }
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);
      bookingDataCopy.cost = cost;
      bookingDataCopy.quantity = quantity;
      bookingDataCopy.renderCost = renderCost;
      bookingDataCopy.description = customMisc || description;

      if (bookingDataCopy.buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }
      const { totalLabour, laborMargin } =
        calculateMarginLabour(bookingDataCopy);
      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabour;
      let durationInMin = duration;
      if (type === "service") {
        durationInMin =
          parseFloat(quantity) > 1 || pricingType === "hourly"
            ? quantity
            : duration;
      }
      if (!previousDuration) {
        bookingDataCopy.previousDuration = durationInMin;
      }
      bookingDataCopy.duration = durationInMin;
      bookingDataCopy.start = moment(start).format(dateFormat);
      bookingDataCopy.end = moment(
        new Date(
          start.setMinutes(
            start.getMinutes() + parseFloat(bookingDataCopy.duration),
          ),
        ),
      ).format(dateFormat);

      startTime = moment(bookingDataCopy.start).format(timeFromDateFormat);

      bookingDataCopy.startTime = startTime;
      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split("$")[1],
        renderCost.split(".")[1] === "00"
          ? addZero(renderCost)
          : addZero(renderCost),
      );

      bookingDataCopy.timeChange = false;
      if (!previousDuration) {
        bookingDataCopy.previousDuration = durationInMin;
      }
      if (!previousQuantity) {
        bookingDataCopy.previousQuantity = quantity;
      }

      if (!editData.edit) {
        finalServices = [...addedServices, bookingDataCopy];
      }
      let obj = groupBy(finalServices, "staffName");
      obj.forEach((el, i) => {
        const total = el.reduce(
          (pre, next) =>
            parseFloat(pre || 0) +
            parseFloat(next.renderCost ? next.renderCost : 0),
          0,
        );
        const totalduration = el.reduce(
          (pre, next) =>
            parseFloat(pre || 0) +
            parseFloat(next.duration ? next.duration : 0),
          0,
        );
        obj[i].totalAmount = total;
        obj[i].totalduration = totalduration;
      });
      let oldServices = [...newAppointment[appointmentIndex].service];
      oldServices.push(bookingDataCopy);
      oldStateCopy[appointmentIndex].service = oldServices;
      handleShowService();
      setServices([...finalServices]);
      calculateSummary([...finalServices]);
      setNewAppointment(oldStateCopy);
    } else {
      setErrorValue(errValue);
      NotificationManager.error(msg, "Message", errorMsgTime);
    }
    setTimeout(() => {
      setErrorValue("");
    }, errorBorderTime);
  };

  const calculateSummary = (finalServices) => {
    let totalDuration = 0;
    let totalCost = 0;
    let totalCostWithoutProduct = 0;
    let totalBuy = 0;
    let totalSellPrice = 0;
    let laborMargin = 0;
    finalServices.forEach((el) => {
      totalDuration += parseFloat(el.duration);
      totalCost += parseFloat(el.renderCost);
      laborMargin += parseFloat(el.laborMargin) + parseFloat(el.totalLabour);
    });
    const totalLabour = laborMargin;
    calculateGst(addZero(parseFloat(totalCost).toFixed(2)), finalServices);
    calculateQuotesTotal(
      addZero(parseFloat(totalCost).toFixed(2)),
      finalServices,
    );
    setSummary({
      ...summary,
      totalCost: addZero(parseFloat(totalCost).toFixed(2)),
      totalDuration,
      totalLabour: addZero(parseFloat(totalLabour).toFixed(2)),
      multiTotal: totalCost,
    });
  };

  const calculateQuotesTotal = (info2, finalService) => {
    const customAppointmentArr = [{ service: finalService }];
    const replaceComma = info2 ? info2.replace(",", "") : 0;
    let invoiceSubtotal = parseFloat(replaceComma || info2, 10);
    const resultGst = averageGstCalculation(customAppointmentArr);
    const { perfectGstPer, totalCost } = resultGst;
    const gstAmount = parseFloat(info2, 10) * (perfectGstPer / 100);
    const totalAmount = parseFloat(invoiceSubtotal, 10) + gstAmount;
    setQuotesTotal(addZero(parseFloat(totalAmount).toFixed(2)));
  };
  const [gst, setGst] = useState("");
  const calculateGst = (info1, finalService) => {
    // This is custom app array for the gst function.
    const customAppointmentArr = [{ service: finalService }];
    const resultGst = averageGstCalculation(customAppointmentArr);
    const replaceComma = info1 ? info1.replace(",", "") : 0;
    let invoiceSubtotal = parseFloat(replaceComma || info1, 10);
    const { perfectGstPer, totalCost } = resultGst;
    const gstAmount = totalCost * (perfectGstPer / 100);
    const gstPercent = gstAmount;
    setGst(gstAmount.toFixed(2));
  };

  const calculateMarginLabour = (bookingDataCopy) => {
    const { cost, buyCostProduct, quantity, type, renderCost } =
      bookingDataCopy;
    let totalBuy = 0;
    let totalSellPrice = 0;
    let laborMargin = 0;
    let totalLabour = 0;
    if (type === "product") {
      totalBuy += parseFloat(buyCostProduct);
      totalSellPrice += parseFloat(renderCost);
      console.log("totalSellPrice", totalSellPrice, "total buy", totalBuy);
      const totalLabourProduct = (
        totalSellPrice -
        totalBuy * parseFloat(quantity)
      ).toFixed(2);
      laborMargin = totalLabourProduct.toString();
    }
    if (type === "service") {
      totalLabour =
        bookingDataCopy.pricingType === "hourly"
          ? calculateServiceLabour(bookingDataCopy)
          : cost;
    }
    return { totalLabour, laborMargin };
  };
  const recalculateServices = (data) => {
    const remainingServices = addedServices.filter(
      (_el, i) => i > editData.index,
    );
    const previousServices = addedServices.filter(
      (_el, i) => i < editData.index,
    );
    console.log("remainingServices", remainingServices);
    for (let i = 0; i < remainingServices.length; i++) {
      const currentEl = remainingServices[i];
      let end = "";
      if (i === 0) {
        end = new Date(data.start);
      } else {
        end = new Date(remainingServices[i - 1].end);
      }
      const startTime = moment(end).format(timeFromDateFormat);
      currentEl.startTime = startTime;
      currentEl.start = moment(end).format(dateFormat);
      currentEl.end = moment(
        new Date(
          end.setMinutes(end.getMinutes() + parseFloat(currentEl.duration)),
        ),
      ).format(dateFormat);
    }
    const arr = previousServices;
    const newArray = arr.concat(remainingServices);
    setServices([...newArray]);
  };
  const calculateServiceLabour = (bookingDataCopy) => {
    const convertedDuration =
      bookingDataCopy.pricingType === "hourly"
        ? bookingDataCopy.quantity
        : bookingDataCopy.duration;

    let hour = convertToReadableDuration(
      addQuantityDuration(convertedDuration, parseFloat("1")),
    );
    let { hourWithQuantity, minutePart } = getHourWithQuantity(
      hour,
      bookingDataCopy.cost,
    );

    minutePart = (minutePart / 60) * parseFloat(bookingDataCopy.cost);
    let finalDuration = parseFloat(hourWithQuantity + minutePart);

    // eslint-disable-next-line no-mixed-operators
    finalDuration = !Number.isInteger(finalDuration / 60)
      ? Math.ceil(finalDuration / 5) * 5
      : finalDuration;

    return parseFloat(finalDuration).toFixed(2);
  };
  const addQuantityDuration = (duration, quantity) => {
    const updatedDuration = convertToReadableDuration(duration.toString());
    let { hourWithQuantity, hourPart, minutePart } = getHourWithQuantity(
      updatedDuration,
      quantity,
    );

    let multipleMinute = parseFloat(
      `0.${hourWithQuantity.toString().split(".")[1]}`,
    );

    const updatedMinute = hourPart ? multipleMinute * (60 / hourPart) : 0;
    minutePart *= quantity;

    let finalDuration =
      parseFloat(hourWithQuantity.toString().split(".")[0]) * 60 +
      updatedMinute +
      minutePart;
    finalDuration = !Number.isInteger(finalDuration / 60)
      ? Math.ceil(finalDuration / 5) * 5
      : finalDuration;

    return finalDuration.toString();
  };
  const getHourWithQuantity = (duration, multiplier) => {
    let hourPart = duration.includes("hr")
      ? parseFloat(duration.split("hr")[0].trim())
      : 0;
    let minutePart =
      duration.includes("hr") && duration.includes("min")
        ? parseFloat(duration.split("hr")[1].split("min")[0])
        : duration.includes("min")
          ? parseFloat(duration.split("min")[0])
          : 0;

    let hourWithQuantity = hourPart * multiplier;
    return { hourWithQuantity, hourPart, minutePart };
  };
  const handleDeleteService = () => {
    const currentServices = [...addedServices];
    currentServices.splice(editData.index, 1);
    if (editData.index === addedServices.length - 1) {
      console.log("last service/product got deleted");
      setBookingData({
        ...bookingData,
        startTime: addedServices[editData.index].startTime,
      });
      setServices([...currentServices]);
    } else {
      recalculateServices(addedServices[editData.index]);
    }
    calculateSummary([...currentServices]);
    setShowService(!showService);
  };
  const handleSearchCategory = async (searchTxt) => {
    try {
      setSearchTxt(searchTxt);
      if (searchTxt) {
        const response = await userInstance().get(
          `/service/searchCategory?value=${searchTxt.replace("$", "").trim()}&industryId=${industryId._id}`,
        );
        const { services } = response.data;
        setCategories(services);
        if (services.length) {
          const subCat = [];
          services.forEach((service) => {
            const reg = new RegExp(`${searchTxt.replace("$", "").trim()}`, "i");
            const matchedSubcategories = service.subCategory.filter(
              (el) =>
                reg.test(el.cost) ||
                reg.test(el.name) ||
                reg.test(el.retailCost) ||
                reg.test(el.serviceDescription),
            );
            subCat.push(...matchedSubcategories);
          });
          if (subCat.length) {
            setSubcategories([...subCat]);
          } else {
            setSubcategories([]);
          }
        } else {
          setSubcategories([]);
        }
      }
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };
  const addSubcategoryToService = (value) => {
    let kquantity = "1";
    const selectedCategory = categories.filter(
      (el) => el.subCategory.filter((sub) => sub.name === value).length,
    );
    const subcategoryData = selectedCategory[0].subCategory.filter(
      (el) => el.name === value,
    );
    const { type, category } = selectedCategory[0];
    const {
      duration,
      cost,
      soldPer,
      unit,
      symbol,
      serviceDescription,
      tax,
      s_uId,
      categoryId,
      supplier,
    } = subcategoryData[0];

    let customCostData = "default";
    let newCost = addZero(
      subcategoryData[0][type === "product" ? "retailCost" : "cost"],
    );
    let newBuyCost = type === "product" ? cost : "";

    if (selectedCategory[0].category.toLowerCase().includes("misc")) {
      setMisc(true);
      customCostData = type === "product" ? "buySell" : "yes";
      newCost = "";
      newBuyCost = "";
    }
    let soldPerValue = soldPer || "";
    if (type === "product") {
      if (soldPer === "Add Custom Measure") {
        soldPerValue = `${unit}(${symbol})`;
      }
    }
    if (value.toLowerCase().includes("misc")) {
      console.log(value);
      if (type === "product") {
        kquantity = "";
        bookingData.quantity = kquantity;
      }
      // setBookingData({ ...bookingData, quantity: '' });
    }
    setBookingData({
      ...bookingData,
      category,
      subcategory: value,
      cost: newCost,
      duration,
      type,
      quantity: kquantity,
      buyCostProduct: newBuyCost,
      customCost: customCostData,
      timeChange: false,
      customMisc: "",
      previousDuration: "",
      previousQuantity: "",
      pricingType: "fixedCost",
      previousCost: newCost,
      prevBuyCostProduct: newBuyCost,
      soldPer: soldPerValue,
      description: serviceDescription || value,
      tax,
      s_uId,
      categoryId,
      supplier,
    });
    setSearchTxt(value);
    setSubcategories(null);
  };
  const editService = () => {
    const {
      start,
      startTime,
      subcategory,
      type,
      previousDuration,
      previousQuantity,
      duration,
      pricingType,
    } = bookingData;
    let { cost, buyCostProduct, renderCost, quantity } = bookingData;
    // console.log('booking data for edit', bookingData);
    // console.log('buyCostProduct', buyCostProduct.replace(/,/g, '').replace('$', ''));

    quantity = quantity.replace(/,/g, "");
    cost = parseFloat(cost.replace(/,/g, "").replace("$", "")).toFixed(2);
    buyCostProduct = parseFloat(
      buyCostProduct.replace(/,/g, "").replace("$", ""),
    ).toFixed(2);

    renderCost =
      type === "service" && pricingType === "hourly"
        ? (parseFloat(cost) * parseFloat(quantity)) / 60
        : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);
    if (type === "product") {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = (renderCost / quantity).toFixed(2).toString();
    }
    if (type === "service" || pricingType === "hourly") {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
    }

    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      const bookingDataCopy = { ...bookingData };

      const startDate = moment(start).toDate();
      const time = startTime.split(":");

      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split("$")[1],
        renderCost.split(".")[1] === "00"
          ? addZero(renderCost)
          : addZero(renderCost),
      );
      bookingDataCopy.cost = cost;
      bookingDataCopy.renderCost = renderCost;
      bookingDataCopy.quantity = quantity;

      if (buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }

      startDate.setHours(time[0]);
      startDate.setMinutes(time[1]);
      startDate.setSeconds(0);

      bookingDataCopy.start = moment(startDate).format(dateFormat);

      const { totalLabour, laborMargin } =
        calculateMarginLabour(bookingDataCopy);
      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabour;

      let durationInMin = 0;
      if (type === "service") {
        durationInMin =
          parseFloat(quantity) > 1 || pricingType === "hourly"
            ? quantity
            : duration;
        bookingDataCopy.duration = durationInMin;
      } else if (
        previousQuantity !== quantity ||
        previousDuration !== duration
      ) {
        // console.log('re calculating the duration');
        // durationInMin = parseFloat(quantity) > 1 ? addQuantityDuration(duration, parseFloat(quantity)) : duration;
        // bookingDataCopy.duration = durationInMin;
        bookingDataCopy.previousDuration = durationInMin;
        bookingDataCopy.previousQuantity = bookingDataCopy.quantity;
      }

      // if (parseFloat(durationInMin) > 960) {
      //   setExtras({ ...extras, value: durationInMin, data: convertToReadableDuration(durationInMin) });
      // }

      bookingDataCopy.end = moment(
        new Date(
          startDate.setMinutes(
            startDate.getMinutes() + parseFloat(bookingDataCopy.duration),
          ),
        ),
      ).format(dateFormat);

      const currentServices = [...addedServices];
      currentServices[editData.index] = bookingDataCopy;
      finalServices = currentServices;
      let obj = groupBy(finalServices, "staffName");
      obj.forEach((el, i) => {
        const total = el.reduce(
          (pre, next) =>
            parseFloat(pre || 0) +
            parseFloat(next.renderCost ? next.renderCost : 0),
          0,
        );
        const totalduration = el.reduce(
          (pre, next) =>
            parseFloat(pre || 0) +
            parseFloat(next.duration ? next.duration : 0),
          0,
        );
        obj[i].totalAmount = total;
        obj[i].totalduration = totalduration;
      });
      console.log("calling recalculate");
      calculateNewDurations(bookingDataCopy);
      bookingDataCopy.timeChange = false;

      setShowService(!showService);
      setServices([...finalServices]);
      calculateSummary([...finalServices]);
      setMultiServices(obj);
      console.log("updated booking data", bookingDataCopy);
    } else {
      setErrorValue(errValue);
      NotificationManager.error(msg, "Message", errorMsgTime);
    }
    setTimeout(() => {
      setErrorValue("");
    }, errorBorderTime);
  };
  const calculateNewDurations = (data) => {
    // console.log('data in calculateNewDurations', data);
    // const selectedEntryIndex = addedServices.findIndex((x, i) => i === editData.index);
    const remainingServices = addedServices.filter(
      (_el, i) => i > editData.index,
    );
    for (let i = 0; i < remainingServices.length; i++) {
      const currentEl = remainingServices[i];
      let end = "";
      if (i === 0) {
        end = new Date(data.end);
      } else {
        end = new Date(remainingServices[i - 1].end);
      }
      const startTime = moment(end).format(timeFromDateFormat);
      currentEl.startTime = startTime;
      currentEl.start = moment(end).format(dateFormat);
      currentEl.end = moment(
        new Date(
          end.setMinutes(end.getMinutes() + parseFloat(currentEl.duration)),
        ),
      ).format(dateFormat);
    }
    setServices([addedServices[0], ...remainingServices]);
  };
  const handleChangeCustom = (value) => {
    const obj = { ...bookingData, customCost: value };
    if (value === "default") {
      obj.cost = bookingData.previousCost;
      if (bookingData.type === "product") {
        obj.buyCostProduct = bookingData.prevBuyCostProduct;
      }
    } else if (value === "yes" || value === "sellPrice") {
      obj.cost = "";
      obj.buyCostProduct = obj.prevBuyCostProduct;
    } else if (value === "buyCost") {
      obj.buyCostProduct = "";
      obj.cost = obj.previousCost;
    } else if (value === "buySell") {
      obj.cost = "";
      obj.buyCostProduct = "";
    }
    setBookingData(obj);
  };
  const handleFrequency = () => {
    setRepeatFrequency(!repeatFrequency);
    if (!repeatFrequency) {
      setTimeout(() => {
        timepickerRef.current.value = `${datePopupData.interval_multiplier}:00`;
      }, 350);
    }
  };
  const handleTimePicker = () => {
    setTime(bookingData.startTime);
    setTimePickerShow(!timepickerShow);
  };

  const handleDurationPicker = () => {
    setDuration(bookingData.duration);
    setDurationPicker(!durationPicker);
  };
  const handleHourPicker = () => {
    setHourData(bookingData.quantity);
    setHourPicker(!hourPicker);
  };

  const moveArr = (oldIndex, newIndex, sign) => {
    const servicesCopy = [...addedServices];
    const { duration, start, startTime } = addedServices[newIndex];

    let newNextStartTime;
    let currentNewStartTime;

    if (sign === "plus") {
      // console.log('plus');
      const currentEntryStart = new Date(servicesCopy[oldIndex].start);
      const updatedNextEntryStart = moment(
        new Date(
          currentEntryStart.setMinutes(
            currentEntryStart.getMinutes() + parseFloat(duration),
          ),
        ),
      ).format(dateFormat);
      newNextStartTime = moment(updatedNextEntryStart).format(
        timeFromDateFormat,
      );
      currentNewStartTime = servicesCopy[oldIndex].startTime;
    } else if (sign === "minus") {
      newNextStartTime = startTime;
      const updatedCurrentEntryStart = moment(
        new Date(
          new Date(start).setMinutes(
            new Date(start).getMinutes() +
              parseFloat(servicesCopy[oldIndex].duration),
          ),
        ),
      ).format(dateFormat);
      currentNewStartTime = moment(updatedCurrentEntryStart).format(
        timeFromDateFormat,
      );
    }

    const updatedOldIndex = {
      ...addedServices[oldIndex],
      startTime: newNextStartTime,
    };
    const updatedNewIndex = {
      ...addedServices[newIndex],
      startTime: currentNewStartTime,
    };
    servicesCopy[oldIndex] = { ...updatedOldIndex };
    servicesCopy[newIndex] = { ...updatedNewIndex };

    if (newIndex >= servicesCopy.length) {
      let k = newIndex - servicesCopy.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        servicesCopy.push(undefined);
      }
    }
    servicesCopy.splice(newIndex, 0, servicesCopy.splice(oldIndex, 1)[0]);
    calculateDurations(servicesCopy);
  };
  const calculateDurations = (services) => {
    services.forEach((el) => {
      let start = moment(el.date).toDate();
      const time = el.startTime.split(":");
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);

      el.start = moment(start).format(dateFormat);
      el.end = moment(
        new Date(
          start.setMinutes(start.getMinutes() + parseFloat(el.duration)),
        ),
      ).format(dateFormat);
    });
    setServices([...services]);
  };

  const handleEditService = ({ service, index }) => {
    setStaffIndex(index);
    if (index === 0) {
      setnewlength(0);
    } else {
      setnewlength(addedServices.length);
    }
    console.log("editing");
    // console.log('service', service);
    const updateObj = { ...bookingData, ...service };
    setBookingData(updateObj);
    setShowService(!showService);
    setEditData({ edit: true, index });
    if (parseFloat(service.duration) > 960) {
      setExtras({
        ...extras,
        value: service.duration,
        data: convertToReadableDuration(service.duration),
      });
    }
    const subcategory = service.subcategory.replace(
      service.subcategory.split("$")[1],
      service.renderCost.split(".")[1] === "00"
        ? addZero(service.renderCost)
        : addZero(service.cost),
    );
    setSearchTxt(subcategory);
  };
  const handleChangeNotes = (val) => {
    setNotes(val);
  };

  const handleSendNewInvoice = async (tempId) => {
    try {
      let isValid = true;
      let val = "";
      if (!addClient.length) {
        isValid = false;
        val = "clients";
      } else if (!addedServices.length) {
        isValid = false;
        val = "services";
      }
      if (isValid) {
        if (tempId !== "send") {
          setShowInvoice(true);
        }
        let data_array = [];
        let { date, createdOn, endsAt, requiredday, appointmenttype } =
          dateData || {};
        // eslint-disable-next-line no-undef
        const startDate = moment(date).format("YYYY-MM-DD");
        let { multiTotal, totalDuration, totalLabour } = summary || {};
        let totalSummary = {
          totalCost: addZero(
            (requiredday
              ? parseFloat(multiTotal) * parseInt(requiredday, 10)
              : parseFloat(multiTotal)
            ).toFixed(2),
          ),
          totalDuration: requiredday
            ? totalDuration * parseInt(requiredday.client, 10)
            : totalDuration,
          totalLabour,
          multiTotal: requiredday
            ? multiTotal * parseInt(requiredday, 10)
            : multiTotal,
        };
        for (let step = 0; step < newAppointment.length; step++) {
          let dateDataCopy = {
            ...dateData,
            date: new Date(startDate).toISOString(),
            createdOn: moment(createdOn).format(dateFormat),
            endsAt: new Date(startDate).toISOString(),
            teamnotes: notes,
            occurenceMsg:
              dateData.endType === "ongoing"
                ? "Ongoing"
                : dateData.occurenceMsg,
            service: addedServices || [],
            summary: totalSummary,
          };
          data_array.push(dateDataCopy);
        }

        // console.log('addClient===>', addClient[0]);
        const payload = {
          services: addedServices,
          clientData: addClient[0],
          notes,
          dateData: data_array,
          totalData: totalSummary,
          appointmenttype,
          requiredday: requiredday || "",
          gst,
          quoteDate: new Date(startDate).toISOString(),
          quotesTotal,
          industryId: industryId?._id,
          tempId,
          clientSiteAddressIndex: 0,
          visitIndex: 0,
        };
        // console.log('all data test ==>', payload);
        const response = await userInstance().post(
          "/invoice/sendNewInvoice",
          payload,
        );
        const { msg, code, urlLink } = response.data;
        if (code === 200) {
          setLoader(false);
          setInvoiceDetail({ invoiceUrl: urlLink });
          if (tempId === "send") {
            NotificationManager.success(
              "New invoice sent succesfully",
              "Message",
              successMsgTime,
            );
            setInvoiceDetail({ invoiceUrl: "" });
            setDateData(initialDateData);
            setAddClient([]);
            setNewAppointment([initialDateData]);
            setBookingData(initialBookingData);
            setServices([]);
            setSummary({
              totalCost: 0,
              totalDuration: "",
              totalLabourMargin: 0,
            });
            setNotes("");
            setQuoteDate(new Date());
            setView("pendinginvoces");
            updateData();
          }
        }
      } else {
        NotificationManager.error(`Please add ${val}`, "Message", errorMsgTime);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInvoicePopup = async () => {
    setShowInvoice(true);
  };
  const handleClose = () => setShowInvoice(false);
  const [confirmPreview, setConfirmPreview] = useState(false);
  const handleSelect = async () => {
    setConfirmPreview(false);
    handleSendNewInvoice();
  };
  const handlePreviewConfirm = () => {
    let isValid = true;
    let val = "";
    if (!addClient.length) {
      isValid = false;
      val = "clients";
    } else if (!addedServices.length) {
      isValid = false;
      val = "services";
    }
    if (isValid) {
      setConfirmPreview(true);
    } else {
      NotificationManager.error(`Please add ${val}`, "Message", errorMsgTime);
    }
  };

  const getAllTemplate = async (info) => {
    try {
      const viewSection = info || "InvoiceTemplate";
      const response = await userInstance().get(
        "/template/getAllTemplate?type=Invoice",
      );
      const { code, msg, temp } = response.data;
      if (msg === "Success") {
        const defaultTemp1 = temp.find((el, i) => el.default);
        if (defaultTemp1) {
          setdefaultTemp(defaultTemp1._id);
        } else {
          setdefaultTemp(temp[0]._id);
        }
      }
      setTemplateArray(temp);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllTemplate();
  }, []);
  const changeTemplate = (value1) => {
    setdefaultTemp(value1);
    handleSendNewInvoice(value1);
    setInvoiceDetail({ invoiceUrl: "" });
  };
  const handleEditDetails = (data, data2) => {
    const url = `/client?clientid=${data}&name=${data2}&typee=edit&back=newinvoice`;
    history.push(url);
  };
  useEffect(() => {
    if (clienttype === "edit") {
      const clinfo = clientAllData?.clientAllDetails;
      setSelectedClient(clinfo._id);
      setAddClient([clinfo]);
    }
  }, [clienttype]);

  return (
    <div className="appointment-container finalize-cost-page new-invoice">
      <div className="add-appointment">
        <div className="row newinvoice-row">
          <div className="col-lg-12 col-md-4">
            <div className="search-container">
              {addClient.length === 0 ? (
                <div className="search-title">
                  <h3> Client </h3>
                  <span className="plus-icon" role="presentation">
                    <i className="fas fa-plus" onClick={handleClient} />
                  </span>
                </div>
              ) : (
                <>
                  {addClient &&
                    addClient.map((cl) => (
                      <>
                        <div className="search-title">
                          <div className="client-profile">
                            <div className="">
                              <div className="">
                                <h3>{`${cl.firstname} ${cl.lastname || ""}`}</h3>
                                <p>
                                  {`${cl.streetAddress}${cl.suburb ? cl.suburb : "" || cl.state ? "" : ""} ${
                                    cl.suburb || ""
                                  } ${cl.state || ""}`}
                                </p>
                                <p>{cl.mobileNo}</p>
                              </div>
                            </div>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="success"
                                id="dropdown-basic"
                              >
                                <img src={showmore} alt="show" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item>
                                  <div
                                    className="action client"
                                    onClick={() => {
                                      handleEditDetails(cl?._id, cl?.firstname);
                                    }}
                                  >
                                    <img src={edit} alt="edit" />
                                    Edit Client Details
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <div
                                    className="action client"
                                    onClick={handleClient}
                                  >
                                    <img src={clientimg} alt="edit" />
                                    Change Client
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <div
                                    className="action"
                                    onClick={() => deleteClient()}
                                  >
                                    <img src={greydelete} alt="edit" />
                                    Delete
                                  </div>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="client-profile">
                          <div className="client-name-contact site-addresses">
                            {activeAdd !== "" &&
                              activeAdd >= 0 &&
                              cl?.siteAddresses?.length > 0 && (
                                <div className="client-left-details">
                                  <h5 className="client-company-name">
                                    Site Address
                                  </h5>
                                  <p>
                                    {cl?.siteAddresses[activeAdd]
                                      .householderName
                                      ? cl?.siteAddresses[activeAdd]
                                          .householderName
                                      : ""}
                                  </p>
                                  <p>
                                    {cl?.siteAddresses[activeAdd].address
                                      ? cl?.siteAddresses[activeAdd].address
                                      : ""}{" "}
                                  </p>
                                  <p>
                                    <a
                                      className="mobile"
                                      href={`tel:${
                                        cl?.siteAddresses[activeAdd].mobileNo ||
                                        ""
                                      }`}
                                    >
                                      {cl?.siteAddresses[
                                        activeAdd
                                      ].mobileNo.includes("+61")
                                        ? cl?.siteAddresses[activeAdd].mobileNo
                                        : `+61 ${
                                            cl?.siteAddresses[
                                              activeAdd
                                            ].mobileNo.replace(
                                              phoneRegex,
                                              "$1 $2 $3",
                                            ) || ""
                                          }`}
                                    </a>
                                  </p>
                                </div>
                              )}
                          </div>
                        </div>
                      </>
                    ))}
                </>
              )}
            </div>
          </div>
          <div className="col-lg-12 col-md-8">
            <div className="appointment-view">
              <div
                className="appointment-date"
                role="presentation"
                onClick={() => handleDatePopup("")}
              >
                <div className="">
                  <h3> {`${moment(quoteDate).format("dddd, D MMMM YYYY")}`}</h3>
                </div>
                <div className="sorting-sub-services appointment-sort">
                  <div className="content-dropdown">
                    <img src={arrow} alt="arrow" />
                  </div>
                </div>
              </div>
              <span className="sep" />
              <div className="search-title service-head">
                <h3>Maintenance Required</h3>
                <div className="sorting-services">
                  {addedServices.length ? (
                    <div className="sorting">
                      <img
                        src={sort}
                        alt="sorting"
                        onClick={() => setRenderSort(!renderSort)}
                      />
                    </div>
                  ) : null}
                  <span
                    className="plus-icon"
                    onClick={() => handleShowService(0)}
                    role="presentation"
                  >
                    <i className="fas fa-plus" />
                  </span>
                </div>
              </div>
              {addedServices &&
                addedServices.map((ser, i) => (
                  <div className="search-title">
                    <div
                      className="client-profile"
                      onClick={() =>
                        handleEditService({ service: ser, index: i })
                      }
                    >
                      <h3>
                        {`${
                          ser.category.toLowerCase().includes("misc")
                            ? `${ser.customMisc} $${addZero(ser.renderCost)}`
                            : ser.subcategory
                        } ${
                          parseFloat(ser.quantity) > 1
                            ? `($${`${addZero(ser.cost)}${ser.soldPer === "Kilogram (kg)" ? " per kg" : ""}`} x ${`${
                                ser.type === "service" &&
                                ser.pricingType === "hourly"
                                  ? convertToReadableDuration(ser.quantity)
                                  : `${ser.soldPer === "Kilogram (kg)" ? `${ser.quantity}kg` : ser.quantity}`
                              }${ser.type === "service" ? " " : ""})`}`
                            : ""
                        }`}
                      </h3>
                      <p>
                        <span>
                          {moment(ser.startTime, [timeFromDateFormat]).format(
                            "h:mm a",
                          )}
                        </span>
                        <span> | </span>
                        <span>{` ${convertToReadableDuration(ser.duration)}`}</span>
                        <span> | </span>
                        <span>{` with ${ser.staffName}`}</span>
                        <span> | </span>
                      </p>
                      <p>
                        <span>
                          {`${
                            ser.type === "product"
                              ? `Margin $${addZero(ser.laborMargin)}`
                              : ser.type === "service"
                                ? `Labour $${`${addZero(ser.totalLabour)}`}`
                                : null
                          }`}
                        </span>
                      </p>
                    </div>
                    <div className="sorting-sub-services appointment-sort">
                      <div className="service-edit-icon">
                        {renderSort ? (
                          <div
                            className={`sorting up-down ${renderSort && "active-sort"}`}
                          >
                            {i !== 0 ? (
                              <img
                                src={upsort}
                                alt="sorting"
                                onClick={() => moveArr(i, i - 1, "minus")}
                              />
                            ) : null}
                            {i !== addedServices.length - 1 ? (
                              <img
                                src={downsort}
                                alt="sorting"
                                onClick={() => moveArr(i, i + 1, "plus")}
                              />
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                      <div
                        className="content-dropdown"
                        onClick={() =>
                          handleEditService({ service: ser, index: i })
                        }
                      >
                        <Dropdown name="startingDate">
                          <Dropdown.Toggle variant="link" id="dropdown-basic">
                            <img src={arrow} alt="arrow" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>Option 1</Dropdown.Item>
                            <Dropdown.Item>Option 2</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                ))}
              <div className="appointment-form">
                <Form>
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Invoice Notes</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Only Visible to Team Members"
                      name="notes"
                      onChange={(e) => handleChangeNotes(e.target.value)}
                    />
                  </Form.Group>
                </Form>
              </div>
              <span className="sep" />
              <div className="appointment-total">
                <h6 className="duration-time">
                  Sub Total:
                  <span>${summary.totalCost}</span>
                </h6>
                <h6 className="duration-time">
                  GST:
                  <span>${gst || 0}</span>
                </h6>
                <h6 className="duration-time">
                  Invoice Total:
                  <span>${quotesTotal || 0}</span>
                </h6>
                <p className="labour-margin">
                  Labour & Margin:
                  {`$${summary.totalLabour ? summary.totalLabour : 0}`}
                </p>
              </div>

              <div className="footer-btn view-btn">
                <Button
                  className="btn-delete"
                  onClick={() => handlePreviewConfirm()}
                >
                  Preview Invoice
                </Button>
                <Button
                  className="appointment-btn btn-save"
                  onClick={() => handleSendNewInvoice("send")}
                >
                  Save as Pending
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SearchModal
        showSearch={client}
        handleShowSearch={handleClient}
        searchClient={searchClient}
        searchedClients={searchedClients}
        clientTxt={clientTxt}
        handleAddClient={handleAddClient}
        setHideSearch={setHideSearch}
        isHideSearch={isHideSearch}
        checkAddress={checkAddress}
        activeAdd={activeAdd}
      />
      <DateModal
        datePopup={datePopup}
        setDatePopup={setDatePopup}
        handleDatePopup={handleDatePopup}
        quoteDate={quoteDate}
      />

      <ServiceModal
        showService={showService}
        setShowService={setShowService}
        handleShowService={handleShowService}
        newLength={newLength}
        categories={categories}
        subcategories={subcategories}
        handleBookingData={handleBookingData}
        getTimes={getTimes}
        bookingData={bookingData}
        renderOptions={renderOptions}
        staffList={staffList}
        handleAddService={handleAddService}
        editData={editData}
        handleDeleteService={handleDeleteService}
        handleSearchCategory={handleSearchCategory}
        setSubcategories={setSubcategories}
        addSubcategoryToService={addSubcategoryToService}
        editService={editService}
        setBookingData={setBookingData}
        staffIndex={staffIndex}
        misc={misc}
        addedServices={addedServices}
        servicesLength={addedServices.length}
        extras={extras}
        handleChangeCustom={handleChangeCustom}
        timepickerShow={timepickerShow}
        handleTimePicker={handleTimePicker}
        subcategoryTxt={subcategoryTxt}
        setSearchTxt={setSearchTxt}
        durationPicker={durationPicker}
        setDurationPicker={setDurationPicker}
        convertToReadableDuration={convertToReadableDuration}
        handleDurationPicker={handleDurationPicker}
        setnewlength={setnewlength}
        setHourPicker={setHourPicker}
        hourPicker={hourPicker}
        handleHourPicker={handleHourPicker}
        appointmentType={appointmentType}
        setDuration={setDuration}
        durationData={durationData}
        error={errorValue}
      />
      {timepickerShow ? (
        <TimePicker
          timepickerShow={timepickerShow}
          handleTimePicker={handleTimePicker}
          bookingData={bookingData}
          setBookingData={setBookingData}
          timeData={timeData}
          setTime={setTime}
        />
      ) : null}
      {durationPicker && (
        <DurationPicker
          durationPicker={durationPicker}
          setDurationPicker={setDurationPicker}
          durationData={durationData}
          handleDurationPicker={handleDurationPicker}
          setDuration={setDuration}
          setBookingData={setBookingData}
          convertToReadableDuration={convertToReadableDuration}
          bookingData={bookingData}
          subcategory={null}
          setSubCategory={null}
        />
      )}
      {hourPicker ? (
        <HourPicker
          setHourPicker={setHourPicker}
          handleHourPicker={handleHourPicker}
          hourPicker={hourPicker}
          hourData={hourData}
          handleDurationPicker={handleDurationPicker}
          setHourData={setHourData}
          setBookingData={setBookingData}
          convertToReadableDuration={convertToReadableDuration}
          bookingData={bookingData}
          subcategory={null}
          setSubCategory={null}
        />
      ) : null}

      {showInvoice && (
        <NewpreviewInvoicePopup
          handleInvoicePopup={handleInvoicePopup}
          showInvoice={showInvoice}
          invoiceData={invoiceDetail}
          handleClose={handleClose}
          VoidInvoice=""
          permission={voidPermission}
          userPermissions=""
          type="preview"
          addappt={addappt}
          setAddAppt={setAddAppt}
          defaultTemp={defaultTemp}
          templateArray={templateArray}
          changeTemplate={changeTemplate}
        />
      )}

      {confirmPreview && (
        <PreviewInvoiceConfirm
          confirmPreview={confirmPreview}
          setConfirmPreview={setConfirmPreview}
          handleSelect={handleSelect}
          addappt={addappt}
          setAddAppt={setAddAppt}
        />
      )}
    </div>
  );
};
NewInvoice.propTypes = {
  setView: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  clienttype: PropTypes.string.isRequired,
};

export default NewInvoice;
