import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Form, Button } from "react-bootstrap";

// mineral changes
const ChemicalSupplyPopup = ({
  setCalciumsupply,
  setChemicalAddSelected,
  setShowMaintenancePopup,
  setStatus,
  handleChemicalSupplyPopup,
  setShowOrderingSummary,
}) => {
  const [chemicalsPopupChangeValue, setchemicalsPopupChangeValue] =
    useState("addChemicals");

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className === "duration-picker time-picker-modal"
          ) {
            setCalciumsupply(false);
          }
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleChange = (e) => {
    setchemicalsPopupChangeValue(e.target.name);
  };
  const handleChange1 = (value) => {
    setchemicalsPopupChangeValue(value);
  };

  const handleClick = () => {
    handleChemicalSupplyPopup(chemicalsPopupChangeValue);
  };
  const handleClose = () => {
    setCalciumsupply(false);
    setShowOrderingSummary(true);
  };

  return (
    <div className="duration-picker time-picker-modal update-source-popup calcium--supply-popup">
      <div>
        <div
          className="time-keeper-popup duration-popup event-popup"
          ref={wrapperRef}
        >
          <div className="appointment-view">
            <h5>Your Chemical Supply is Low!</h5>
            <div
              className="pending-water-test"
              onClick={() => handleChange1("addChemicals")}
            >
              <h3>Add Chemicals Available</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="addChemicals"
                    onChange={handleChange}
                    checked={chemicalsPopupChangeValue === "addChemicals"}
                  />
                </div>
              </div>
            </div>
            <div
              className="pending-water-test"
              onClick={() => handleChange1("waitStocks")}
            >
              <h3>Wait Until You Have Stock</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="waitStocks"
                    onChange={handleChange}
                    checked={chemicalsPopupChangeValue === "waitStocks"}
                  />
                </div>
              </div>
            </div>
            <div className="event-popup-btn footer-btn view-btn">
              <Button
                className="appointment-btn btn-edit"
                type="button"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="appointment-btn btn-save"
                onClick={handleClick}
              >
                Select
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
ChemicalSupplyPopup.propTypes = {
  setCalciumsupply: PropTypes.func.isRequired,
  setChemicalAddSelected: PropTypes.func.isRequired,
  setShowMaintenancePopup: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  handleChemicalSupplyPopup: PropTypes.func.isRequired,
  setShowOrderingSummary: PropTypes.func.isRequired,
};
export default ChemicalSupplyPopup;
