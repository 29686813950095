/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Dropdown, Button, Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import RepeatModal from "./repeatmodal";
import DeleteModal from "./deletemodal";
import StaffTimePicker from "./timePicker";

const DorpdownModal = (props) => {
  const {
    showDropdownModal,
    getTimes,
    setRepeatDates,
    handleRepeatDropdown,
    handleEditWorking,
    setShowDropdown,
    staffData,
    getRepeatVal,
    selectedDay,
    renderSpecificDate,
    setRender,
    selectedShift,
    handleShiftChange,
    updateRepeatStaff,
    repeatStaff,
    updateCurrentShift,
    updateAllShift,
    setDelete,
    deleteStaff,
    handleDelete,
    deleteCurrentShift,
    deleteUpcomingShift,
    handleTimePicker,
    timepicker,
    type,
  } = props;
  const [timeData, setTime] = useState("9:30");
  return (
    <>
      <Modal
        size="lg"
        show={showDropdownModal}
        onHide={() => setShowDropdown(!showDropdownModal)}
        aria-labelledby="contained-modal-title-vcenter"
        top
        className="dropdown-modal edit-working-popup"
      >
        <div className="staff-close">
          <i
            className="fa fa-times"
            onClick={() => setShowDropdown(!showDropdownModal)}
            aria-hidden="true"
          />
        </div>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Working Hours
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="shift-container">
            <div className="row">
              <div className="col-md-6">
                <div className="table-dropdown datetime-table time">
                  {/* <span>{repeatVal !== 'Select' ? repeatVal : '-'}</span> */}
                  <span>Start Time</span>
                  <Dropdown name="startingDate">
                    <Dropdown.Toggle
                      variant="success"
                      onClick={() => handleTimePicker("startTime")}
                      id="dropdown-basic"
                    >
                      {selectedShift.startTime}
                    </Dropdown.Toggle>
                  </Dropdown>
                  {/* <Dropdown name="startingDate">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {selectedShift.startTime}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {getTimes().map((time) => (
                        <Dropdown.Item
                          key={time}
                          onClick={() => handleShiftChange({ name: 'startTime', data: time })}
                        >
                          {time}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown> */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="table-dropdown datetime-table time">
                  {/* <span>{repeatVal !== 'Select' ? repeatVal : '-'}</span> */}
                  <span>End Time</span>
                  <Dropdown name="startingDate">
                    <Dropdown.Toggle
                      variant="success"
                      onClick={() => handleTimePicker("endTime")}
                      id="dropdown-basic"
                    >
                      {selectedShift.endTime}
                    </Dropdown.Toggle>
                  </Dropdown>
                  {/* <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {selectedShift.endTime}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {getTimes().map((time) => (
                        <Dropdown.Item key={time} onClick={() => handleShiftChange({ name: 'endTime', data: time })}>
                          {time}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown> */}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="table-dropdown datetime-table">
                  {/* <span>{repeatVal !== 'Select' ? repeatVal : '-'}</span> */}
                  <span>Repeats</span>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {selectedShift.repeats}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() =>
                          handleShiftChange({
                            name: "repeats",
                            data: "Don't Repeat",
                          })
                        }
                      >
                        Don&lsquo;t Repeat
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          handleShiftChange({ name: "repeats", data: "Weekly" })
                        }
                      >
                        Weekly
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              {selectedShift.repeats === "Weekly" ? (
                <div className="col-md-6">
                  <div className="table-dropdown datetime-table">
                    {/* <span>{repeatVal !== 'Select' ? repeatVal : '-'}</span> */}
                    <span>End Repeat</span>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {selectedShift.endRepeat.includes(
                          "00:00:00 GMT+0530 (India Standard Time)",
                        )
                          ? new Date(
                              selectedShift.endRepeat,
                            ).toLocaleDateString()
                          : selectedShift.endRepeat}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() =>
                            handleShiftChange({
                              name: "endRepeat",
                              data: "Ongoing",
                            })
                          }
                        >
                          Ongoing (current date)
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handleShiftChange({
                              name: "endRepeat",
                              data: "Ongoing (Mon to Fri)",
                            })
                          }
                        >
                          Ongoing (Mon to Fri)
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handleShiftChange({
                              name: "endRepeat",
                              data: "Ongoing (Mon to Sat)",
                            })
                          }
                        >
                          Ongoing (Mon to Sat)
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handleShiftChange({
                              name: "endRepeat",
                              data: "Ongoing (Mon to Sun)",
                            })
                          }
                        >
                          Ongoing (Mon to Sun)
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            handleRepeatDropdown({
                              value: "Ends On Specific Date",
                            })
                          }
                        >
                          Specific Date
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="view-btn">
              <Button
                type="submit"
                className="btn btn-delete"
                onClick={handleDelete}
              >
                Delete
              </Button>
              <Button
                type="submit"
                className="btn btn-save"
                onClick={setRepeatDates}
              >
                Save/Update
              </Button>
              {/* <Button type="submit" className="btn btn-save" onClick={HandleReapeat}>Repeat modal</Button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <RepeatModal
        updateRepeatStaff={updateRepeatStaff}
        repeatStaff={repeatStaff}
        endRepeat={selectedShift.endRepeat}
        updateCurrentShift={updateCurrentShift}
        updateAllShift={updateAllShift}
      />
      <DeleteModal
        handleDelete={handleDelete}
        deleteStaff={deleteStaff}
        setDelete={setDelete}
        deleteCurrentShift={deleteCurrentShift}
        deleteUpcomingShift={deleteUpcomingShift}
      />
      {timepicker && (
        <StaffTimePicker
          timepicker={timepicker}
          handleTimePicker={handleTimePicker}
          timeData={timeData}
          handleShiftChange={handleShiftChange}
          type={type}
          selectedShift={selectedShift}
        />
      )}
    </>
  );
};
DorpdownModal.propTypes = {
  showDropdownModal: PropTypes.func.isRequired,
  setShowDropdown: PropTypes.func.isRequired,
  setRepeatDates: PropTypes.func.isRequired,
  getTimes: PropTypes.func.isRequired,
  handleRepeatDropdown: PropTypes.func.isRequired,
  handleEditWorking: PropTypes.func.isRequired,
  staffData: PropTypes.func.isRequired,
  getRepeatVal: PropTypes.func.isRequired,
  selectedDay: PropTypes.func.isRequired,
  renderSpecificDate: PropTypes.func.isRequired,
  setRender: PropTypes.func.isRequired,
  selectedShift: PropTypes.func.isRequired,
  handleShiftChange: PropTypes.func.isRequired,
  repeatStaff: PropTypes.func.isRequired,
  updateRepeatStaff: PropTypes.func.isRequired,
  updateCurrentShift: PropTypes.func.isRequired,
  updateAllShift: PropTypes.func.isRequired,
  setDelete: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  deleteStaff: PropTypes.func.isRequired,
  deleteCurrentShift: PropTypes.func.isRequired,
  deleteUpcomingShift: PropTypes.func.isRequired,
  handleTimePicker: PropTypes.func.isRequired,
  timepicker: PropTypes.func.isRequired,
  type: PropTypes.func.isRequired,
};
export default DorpdownModal;
