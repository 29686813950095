/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";
import { Form, Button, Modal, Dropdown } from "react-bootstrap";
import NumberFormat from "react-number-format";
import flag from "../../assets/admin/aus-flag.png";
import us from "../../assets/admin/us-flag.png";
import { handlePress, queryString } from "../../functions";

function StaffForm(props) {
  const {
    handleChange,
    staffData,
    handleAddStaff,
    showForm,
    handleFormModal,
    editStaff,
    selectedStaff,
    selectedCountry,
    handleCountry,
    compLocation,
    error,
    mobileFormat,
    countries,
    stateArray,
  } = props;
  const { id } = queryString();
  const prID = !id ? "" : id;
  console.log(prID, "userid", selectedStaff, "idss");
  return (
    <Modal
      size="lg"
      show={showForm}
      onHide={handleFormModal}
      aria-labelledby="contained-modal-title-vcenter"
      top
      className="staff-modal"
    >
      <div className="staff-close">
        <i
          className="fa fa-times"
          onClick={handleFormModal}
          aria-hidden="true"
        />
      </div>
      {/* <div className="adduser-heading">
        <div className="row">
          <h2 className="signup-popup-heading">Sign-up Now for Calculator-Pro</h2>
        </div>
        <div className="row">
          <p className="modal-description">
            Choose from four easy access options.
            {' '}
            <br />
            All choices have identical features, the
            {' '}
            <br />
            only difference is their duration period.
          </p>
          <p className="desc-head">Option 1</p>
          <p>
            10 Day Free Trial
            <span style={{ color: 'red' }}>&nbsp;*</span>
          </p>
          <p className="desc-head">Option 2</p>
          <p>
            $179.40
            <span className="option-desc">(= $14.95/month x 12 months)</span>
          </p>
          <p className="desc-head">Option 3</p>
          <p>
            $298.80
            <span className="option-desc">(= $12.45/month x 24 months)</span>
          </p>
          <p className="desc-head">Option 4</p>
          <p>
            $358.20
            <span className="option-desc">(= $9.95/month x 36 months)</span>
          </p>
          <p className="extra-comment">* Subscription automatically updates to Option 2 if not cancelled prior to the end of trial period.</p>
        </div>
      </div> */}
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {prID && selectedStaff
            ? "Update Subscriber User"
            : prID
              ? "Add Subscriber User"
              : selectedStaff
                ? "Update User"
                : "Add User"}{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="staff-form">
          <Form>
            <div className="row">
              <div className="col-md-6">
                <Form.Group
                  controlId="formBasicInput-9"
                  className="italic-text"
                >
                  <Form.Label>Country</Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      name="country"
                      onChange={handleChange}
                      value={staffData.country}
                      className={`${error === "country" ? "error-text" : ""}`}
                    >
                      {countries?.length > 0 &&
                        countries.map((el) => (
                          <option value={el.name}>{el.name}</option>
                        ))}
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group
                  controlId="formBasicInput-1"
                  className="italic-text"
                >
                  <Form.Label>
                    First name
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="firstname"
                    value={staffData.firstname}
                    // eslint-disable-next-line no-restricted-globals
                    onKeyPress={handlePress}
                    placeholder="Enter First Name"
                    className={`${error === "firstname" ? "error-text" : ""}`}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group
                  controlId="formBasicInput-2"
                  className="italic-text"
                >
                  <Form.Label>
                    Last name
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="lastname"
                    value={staffData.lastname}
                    onKeyPress={handlePress}
                    placeholder="Enter Last Name"
                    className={`${error === "lastname" ? "error-text" : ""}`}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group
                  controlId="formBasicInput-3"
                  className="number italic-text"
                >
                  <Form.Label>
                    Mobile No.
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <div className="number-field">
                    {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
                    <div className="number-box">
                      <Dropdown id="mobile">
                        <Dropdown.Toggle variant="success" id="dropdown-basic1">
                          <div className="flag-icon">
                            <img
                              src={selectedCountry === "+61" ? flag : us}
                              alt="flag"
                            />
                          </div>
                          {selectedCountry === "+61" ? (
                            <span>
                              Australia
                              {selectedCountry}
                            </span>
                          ) : selectedCountry === "+1" ? (
                            <span>
                              United States
                              {selectedCountry}
                            </span>
                          ) : (
                            ""
                          )}
                          {/* <span><i className="fas fa-caret-down" /></span> */}
                          {/* <div className="country-code">{selectedCountry}</div> */}
                        </Dropdown.Toggle>

                        {/* <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleCountry('+61')} id="12">
                            <div className="flag-icon">
                              <img src={flag} alt="flag" />
                            </div>
                            <div className="country-code">
                              <span>Australia</span>
                              +61
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleCountry('+1')} id="122">
                            <div className="flag-icon">
                              <img src={us} alt="flag" />
                            </div>
                            <div className="country-code">
                              {' '}
                              <span>United States</span>
                              +1
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu> */}
                      </Dropdown>
                    </div>
                    {console.log({ error })}
                    <NumberFormat
                      className={`form-control mobile-num ${
                        error === "mobileno" ? "error-text" : ""
                      }`}
                      format={mobileFormat}
                      onChange={handleChange}
                      name="mobileNo"
                      value={staffData.mobileNo}
                      placeholder="Enter Mobile Number"
                      autocomplete="off"
                    />
                  </div>
                </Form.Group>
                {/* <Form.Group controlId="formBasicInput-3" className="number">
                  <Form.Label>Mobile No.</Form.Label>
                  <div className="flag-icon">
                    <img src={flag} alt="flag" />
                  </div>
                  <NumberFormat
                    className="form-control"
                    format="+61 ### ### ###"
                    onChange={handleChange}
                    name="mobileNo"
                    value={staffData.mobileNo}
                  />
                </Form.Group> */}
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formBasicInput-34" className="number">
                  <Form.Label>Landline No.</Form.Label>
                  <div className="number-field">
                    {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
                    <div className="number-box">
                      <Dropdown id="landline">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <div className="flag-icon">
                            <img
                              src={selectedCountry === "+61" ? flag : us}
                              alt="flag"
                            />
                          </div>
                          {selectedCountry === "+61" ? (
                            <span>
                              Australia
                              {selectedCountry}
                            </span>
                          ) : selectedCountry === "+1" ? (
                            <span>
                              United States
                              {selectedCountry}
                            </span>
                          ) : (
                            ""
                          )}
                          {/* <span><i className="fas fa-caret-down" /></span> */}
                          {/* <div className="country-code">{}</div> */}
                        </Dropdown.Toggle>

                        {/* <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleCountry('+61')}>
                            <div className="flag-icon">
                              <img src={flag} alt="flag" />
                            </div>
                            <div className="country-code">
                              <span>Australia</span>
                              +61
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleCountry('+1')}>
                            <div className="flag-icon">
                              <img src={us} alt="flag" />
                            </div>
                            <div className="country-code">
                              {' '}
                              <span>United States</span>
                              +1
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu> */}
                      </Dropdown>
                    </div>
                    <NumberFormat
                      className={`form-control mobile-num ${
                        error === "landlineno" ? "error-text" : ""
                      }`}
                      format="# #### ####"
                      onChange={handleChange}
                      name="landlineNo"
                      value={staffData.landlineNo}
                      autocomplete="off"
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group
                  controlId="formBasicInput-5"
                  className="italic-text"
                >
                  <Form.Label>
                    Email Address
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="email"
                    onChange={handleChange}
                    name="email"
                    value={staffData.email}
                    placeholder="Enter Email Address"
                    className={`${error === "email" ? "error-text" : ""}`}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formBasicInput-6">
                  <Form.Label>Street Address </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="streetAddress"
                    value={staffData.streetAddress}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formBasicInput-7">
                  <Form.Label>City/Town/Suburb</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="suburb"
                    value={staffData.suburb}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formBasicInput-8">
                  <Form.Label>Postal/Zip Code</Form.Label>
                  <Form.Control
                    type="Number"
                    onChange={handleChange}
                    name="postcode"
                    value={staffData.postcode}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group
                  controlId="formBasicInput-9"
                  className="italic-text"
                >
                  <Form.Label>
                    State
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="state"
                      value={staffData.state}
                      className={`${error === "state" ? "error-text" : ""}`}
                    >
                      <option value="">Select</option>
                      {stateArray?.length > 0 &&
                        stateArray.map((el) => (
                          <option value={el.name}>
                            {el.isoCode} -{el.name}
                          </option>
                        ))}
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
              {/* <div className="row">
              <div className="col-md-6">
                <Form.Group className="for-disable" controlId="formBasicInput-9">
                  <Form.Label>Working Days & Hours</Form.Label>
                  <Form.Control type="text" placeholder="View" disabled />
                  <span className="angle-right">
                    <a
                      href="#/"
                      onClick={() => HandleOpenCloseTable('fromAddStaff')}
                    >
                      <i className="fas fa-angle-right" />
                    </a>
                  </span>
                </Form.Group>

              </div>
            </div> */}
              <div className="col-md-6">
                <Form.Group controlId="formBasicAccessLevel">
                  <Form.Label>
                    Access Level
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      value={staffData?.accessLevel}
                      onChange={handleChange}
                      name="accessLevel"
                      // placeholder="Select"
                      className={`${
                        error === "accesslevel" ? "error-text" : ""
                      }`}
                      disabled={staffData.accessLevel === "owner"}
                    >
                      <option value="">Select</option>
                      <option value="noAccess">No Access</option>
                      <option value="low">Low</option>
                      <option value="medium"> Medium</option>
                      <option value="high"> High</option>
                      {staffData.accessLevel === "owner" && (
                        <option value="owner">Owner</option>
                      )}
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
              {/* <div className="col-md-6">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>
                    Location
                    {' '}
                  </Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      value={staffData.location}
                      onChange={handleChange}
                      name="location"
                      className={`${
                        error === 'location'
                          ? 'error-text'
                          : ''
                      }`}
                    >
                      <option value="">Select</option>
                      {
                        compLocation.map((el) => (
                          <option key={el._id} value={el._id}>{el.locationName}</option>
                        ))
                      }

                    </Form.Control>
                  </div>
                </Form.Group>
              </div> */}
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group
                  className="form-radio"
                  onChange={handleChange}
                  name="gender"
                >
                  <Form.Check
                    type="radio"
                    label="Male"
                    name="gender"
                    id="male"
                    checked={staffData.gender === "male"}
                  />
                  <Form.Check
                    type="radio"
                    label="Female"
                    name="gender"
                    id="female"
                    checked={staffData.gender === "female"}
                  />
                </Form.Group>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicHiddenEmployees">
                  <Form.Label>Hide Employee</Form.Label>
                  <div className="select-box">
                    <i className="fas fa-sort-down" />
                    <Form.Control as="select" name="hide" onChange={handleChange}>
                      <option value={false}>No</option>
                      <option value selected={staffData.hide === true}> Yes </option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>
                    {prID ? "Subscriber Notes" : "Employee Notes"}
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={handleChange}
                    name="note"
                    value={staffData.note}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="view-btn staff-form-btn">
                  <Button
                    type="button"
                    className="btn btn-edit"
                    onClick={(e) => {
                      e.preventDefault();
                      handleFormModal();
                    }}
                  >
                    {" "}
                    Cancel{" "}
                  </Button>
                  <Button
                    type="Submit"
                    className="btn btn-save"
                    onClick={selectedStaff ? editStaff : handleAddStaff}
                  >
                    {" "}
                    Save/Update{" "}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
StaffForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  staffData: PropTypes.func.isRequired,
  handleAddStaff: PropTypes.func.isRequired,
  showForm: PropTypes.func.isRequired,
  handleFormModal: PropTypes.func.isRequired,
  selectedStaff: PropTypes.func.isRequired,
  editStaff: PropTypes.func.isRequired,
  selectedCountry: PropTypes.func.isRequired,
  handleCountry: PropTypes.func.isRequired,
  compLocation: PropTypes.arrayOf.isRequired,
  error: PropTypes.string.isRequired,
  mobileFormat: PropTypes.string.isRequired,
  stateArray: PropTypes.arrayOf.isRequired,
  countries: PropTypes.arrayOf.isRequired,
};

export default StaffForm;
